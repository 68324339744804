/**
 * @author adampryor
 * Adapted from THREE.EditorControls
 */
var ModulePlacementController = function (editor, viewport) {
  this.name = 'ModulePlacement'
  this._autoActivate = false

  let domElement = viewport.container.dom
  let scope = this
  let activationCleanup = []

  // Session Format:
  // {
  //   startingModule: {
  //     uuid: string,
  //     active: boolean,
  //     buildable: boolean
  //   }
  //   visited: Record<string, boolean>, // records the uuids of modules already touched by the mouse
  //   markModuleAsVisited: Function,
  //   hasVisitedModule: Function,
  //   cleanupFuncs: Function[],
  //   cleanup: Function
  // }
  //
  // the session is set whenever a mouse down / touch start event
  // hits a module (in any state) in the currently-selected panel group
  // is persisted while a mouse move / touch move event is happening
  // and then unset (set to null) during mouse up / touch end event
  let placementSession = null

  const PlacementModes = Object.freeze({
    AddRemoveModules: 'add_remove_modules',
    AddRemoveBuildables: 'add_remove_buildables',
  })

  const BuildableStates = Object.freeze({
    Buildable: true,
    NonBuildable: false,
  })

  const ActivationStates = Object.freeze({
    Active: true,
    Inactive: false,
  })

  const ActivationBuildableStates = Object.freeze({
    InactiveNonBuildable: 0b00,
    InactiveBuildable: 0b01,
    ActiveNonBuildable: 0b10,
    ActiveBuildable: 0b11,
  })

  const StateSetters = {
    // by default, setBuildable() does not redraw the module grid
    // BUT if there are cases when you have to call both setBuildable()
    // and setActive() in succession, you can save draw calls by calling
    // setBuildable() first THEN setActive(), since setActive() always re-draws
    // the module grid
    setBuildable: (module, value, opts = {}) => {
      const moduleGrid = module.getGrid()
      if (!moduleGrid) return
      if (moduleGrid.cellIsBuildable(module.cell) === value) return // no need to do anything
      if (value === true) {
        moduleGrid.updateBuildableCells([...moduleGrid.getBuildableCells(), module.cell], {
          redrawModuleGrid: opts.redrawModuleGrid,
        })
      } else {
        moduleGrid.updateBuildableCells(
          moduleGrid.getBuildableCells().filter((c) => c !== module.cell),
          { redrawModuleGrid: opts.redrawModuleGrid }
        )
      }
      if (opts.clearInactiveBuildableCellsCache) {
        moduleGrid.clearInactiveBuildableCellsCache()
      }
    },

    setActive: (module, value) => {
      const moduleGrid = module.getGrid()
      if (!moduleGrid) return
      // this command will also redraw the module grid
      editor.execute(
        new SetModuleGridActiveModulesCommand(moduleGrid, moduleGrid.getCellsActiveModified(module.cell, value))
      )
    },

    doNothing: (_module) => {},
  }

  const StateGetters = {
    isBuildable: (module) => {
      const moduleGrid = module.getGrid()
      if (!moduleGrid) return
      return moduleGrid.cellIsBuildable(module.cell)
    },

    isActive: (module) => {
      return module.active
    },

    getActivationBuildableFlags: (module) => {
      const activeBitFlag = StateGetters.isActive(module) ? 0b10 : 0b00
      const buildableBitFlag = StateGetters.isBuildable(module) ? 0b01 : 0b00
      return activeBitFlag + buildableBitFlag
    },
  }

  const StateDecisionTree = {
    // LEVELS:
    // - Placement Mode
    //    - Activation state of starting module
    //      - Activation state of visited module
    [PlacementModes.AddRemoveModules]: {
      [ActivationStates.Active]: {
        [ActivationStates.Inactive]: StateSetters.doNothing,
        [ActivationStates.Active]: (module) => {
          StateSetters.setBuildable(module, false, { clearInactiveBuildableCellsCache: true })
          StateSetters.setActive(module, false)
        },
      },
      [ActivationStates.Inactive]: {
        [ActivationStates.Inactive]: (module) => {
          StateSetters.setBuildable(module, true)
          StateSetters.setActive(module, true)
        },
        [ActivationStates.Active]: StateSetters.doNothing,
      },
    },

    // LEVELS:
    // - Placement Mode
    //    - Activation state of starting module
    //      - Buildable state of starting module
    //        - Activation+Buildable state of visited module
    [PlacementModes.AddRemoveBuildables]: {
      [ActivationStates.Active]: {
        [BuildableStates.NonBuildable]: {
          // we are not supposed to encounter a module that's active but not buildable
          // so we do nothing in any case
          [ActivationBuildableStates.InactiveNonBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.InactiveBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveNonBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveBuildable]: StateSetters.doNothing,
        },
        [BuildableStates.Buildable]: {
          [ActivationBuildableStates.InactiveNonBuildable]: (module) => {
            StateSetters.setBuildable(module, true, { redrawModuleGrid: true })
          },
          [ActivationBuildableStates.InactiveBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveNonBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveBuildable]: (module) => {
            StateSetters.setActive(module, false)
          },
        },
      },
      [ActivationStates.Inactive]: {
        [BuildableStates.NonBuildable]: {
          [ActivationBuildableStates.InactiveNonBuildable]: (module) => {
            StateSetters.setBuildable(module, true, { redrawModuleGrid: true })
          },
          [ActivationBuildableStates.InactiveBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveNonBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveBuildable]: (module) => {
            StateSetters.setActive(module, false)
          },
        },
        [BuildableStates.Buildable]: {
          [ActivationBuildableStates.InactiveNonBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.InactiveBuildable]: (module) => {
            StateSetters.setBuildable(module, false, { redrawModuleGrid: true })
          },
          [ActivationBuildableStates.ActiveNonBuildable]: StateSetters.doNothing,
          [ActivationBuildableStates.ActiveBuildable]: (module) => {
            StateSetters.setBuildable(module, false)
            StateSetters.setActive(module, false)
          },
        },
      },
    },

    // allows specifying the path that leads towards a state setter function
    // the path is a list of values that dictates where to go from each inner node
    // example: ['add_remove_panels', true, false]
    query: function (branches) {
      let currentNode = this
      branches.forEach((branch) => {
        currentNode = currentNode[branch]
      })
      return currentNode
    },
  }

  let placementMode = PlacementModes.AddRemoveModules // default
  let placementModeCleanup = []

  const hotkeys = new Hotkeys()

  hotkeys
    .onShift()
    .do(() => {
      setPlacementMode(PlacementModes.AddRemoveBuildables)
    })
    .onReleaseDo(() => {
      setDefaultPlacementMode()
    })

  function setPlacementMode(mode, opts = {}) {
    // no repetitive set
    if (placementMode === mode) return

    // do not allow changing placement mode during an active placement session
    // we defer the change to when the placement session ends instead
    if (!!placementSession && !opts.sessionCleanup) {
      placementSession.cleanupFuncs.push(() => {
        setPlacementMode(mode, { sessionCleanup: true })
      })
      return
    }

    placementModeCleanup.forEach((f) => f())
    placementModeCleanup.length = 0

    if (mode === PlacementModes.AddRemoveModules) {
      placementMode = mode
      editor.signals.modulePlacementStatusChanged.dispatch({ mode: placementMode, session: placementSession })
      return
    }

    if (mode === PlacementModes.AddRemoveBuildables) {
      editor.saveControllerState()
      editor.controllers.loadState({ General: false })
      placementModeCleanup.push(() => {
        editor.revertControllerState()
      })
      placementMode = mode
      editor.signals.modulePlacementStatusChanged.dispatch({ mode: placementMode, session: placementSession })
      return
    }
  }
  this.setPlacementMode = setPlacementMode

  function setDefaultPlacementMode() {
    setPlacementMode(PlacementModes.AddRemoveModules)
  }
  this.setDefaultPlacementMode = setDefaultPlacementMode

  function editorSelectionIsString() {
    return editor.selected?.type === 'OsString'
  }

  function onModuleVisit(module) {
    const canEditDesign = window.Designer?.permissions.canEdit()
    const canEditBuildablePanels = window.Designer?.permissions.canEdit('systems.buildablePanels')
    const moduleGrid = module.getGrid()
    const moduleCellIsBuildable = moduleGrid.cellIsBuildable(module.cell)

    if (placementMode === PlacementModes.AddRemoveModules) {
      // prevent addition / removal of active modules if user has NO permission to edit design
      // EXCEPT when the module is buildable and user has permission to edit buildable panels
      if (!(canEditDesign || (canEditBuildablePanels && moduleCellIsBuildable))) return

      const setModuleState = StateDecisionTree.query([
        PlacementModes.AddRemoveModules,
        StateGetters.isActive(placementSession.startingModule),
        StateGetters.isActive(module),
      ])
      setModuleState(module)

      return
    }

    if (placementMode === PlacementModes.AddRemoveBuildables) {
      // requires full edit permission
      if (!canEditDesign) return

      moduleGrid.clearInactiveBuildableCellsCache()
      const setModuleState = StateDecisionTree.query([
        PlacementModes.AddRemoveBuildables,
        placementSession.startingModule.active,
        placementSession.startingModule.buildable,
        StateGetters.getActivationBuildableFlags(module),
      ])
      setModuleState(module)

      return
    }
  }

  function getModuleUnderPointerEvent(event, isTouchEvent = false) {
    const object = viewport.spriteOrObjectUnderClick(
      isTouchEvent ? event.touches[0] : event,
      viewport.objectsSelectableAndModules(editor.selected, undefined, true)
    )
    if (object?.type === 'OsModule') {
      return object
    }
    return null
  }

  ///////////////////////////////////////////////
  // #region    Event Listeners
  ///////////////////////////////////////////////

  // the onPointer* functions are the common handlers
  // for each type of touch / mouse events
  // since the handler logic is very similar

  function onPointerDown(event, moduleAffected, isTouchEvent = false) {
    //Clicking on a helper above the module will set cancelBubble so we know to ignore it here
    if (event.cancelBubble == true) return

    //Do not exit early if moduleAffected is supplied
    if (!moduleAffected && !isTouchEvent) {
      if (event.button != 0) {
        return
      }
    }

    // moduleAffected can be passed directly for unit tests when DOM/clicks aren't available

    event.preventDefault()

    if (editorSelectionIsString()) return

    if (!moduleAffected) {
      moduleAffected = getModuleUnderPointerEvent(event, isTouchEvent)
    }

    if (!moduleAffected) {
      placementSession?.cleanup()
      placementSession = null
      editor.signals.modulePlacementStatusChanged.dispatch({ mode: placementMode, session: placementSession })
      return
    }

    const moduleGrid = moduleAffected.getGrid()

    // this session will be discarded once the pointer is released/up
    placementSession = {
      startingModule: {
        uuid: moduleAffected.uuid,
        active: StateGetters.isActive(moduleAffected),
        buildable: moduleGrid?.cellIsBuildable(moduleAffected.cell),
      },
      visited: {},
      hasVisitedModule: function (module) {
        return this.visited[module.uuid] === true
      },
      markModuleAsVisited: function (module) {
        this.visited[module.uuid] = true
      },
      cleanupFuncs: [],
      cleanup: function () {
        this.cleanupFuncs.forEach((f) => f())
        this.cleanupFuncs.length = 0
      },
    }

    onModuleVisit(moduleAffected)
    placementSession.markModuleAsVisited(moduleAffected)
    editor.signals.modulePlacementStatusChanged.dispatch({ mode: placementMode, session: placementSession })
  }

  function onPointerMove(event, moduleAffected, isTouchEvent = false) {
    event.preventDefault()

    //handleRollovers(event);

    if (!placementSession) return

    if (!moduleAffected) {
      moduleAffected = getModuleUnderPointerEvent(event, isTouchEvent)
    }

    if (!moduleAffected) return

    if (!placementSession.hasVisitedModule(moduleAffected)) {
      onModuleVisit(moduleAffected)
      placementSession.markModuleAsVisited(moduleAffected)
    }
  }

  function onPointerUp(event) {
    event.preventDefault()
    placementSession?.cleanup()
    placementSession = null
    editor.signals.modulePlacementStatusChanged.dispatch({ mode: placementMode, session: placementSession })
  }

  function touchStart(event, moduleAffected) {
    onPointerDown(event, moduleAffected, true)
  }

  function onMouseDown(event, moduleAffected) {
    onPointerDown(event, moduleAffected, false)
  }
  this.onMouseDown = onMouseDown

  function touchMove(event, moduleAffected) {
    onPointerMove(event, moduleAffected, true)
  }

  function onMouseMove(event, moduleAffected) {
    onPointerMove(event, moduleAffected, false)
  }
  this.onMouseMove = onMouseMove

  function touchEnd(event) {
    onPointerUp(event)
  }

  function onMouseUp(event) {
    onPointerUp(event)
  }
  this.onMouseUp = onMouseUp

  ///////////////////////////////////////////////
  // #endregion    Event Listeners
  ///////////////////////////////////////////////

  ///////////////////////////////////////////////
  // #region   Controller Activation APIs
  ///////////////////////////////////////////////

  this.activate = function () {
    if (this.active === true) {
      return
    }

    setDefaultPlacementMode()
    hotkeys.attach()

    activationCleanup.push(() => hotkeys.detach())

    domElement.addEventListener('mousedown', onMouseDown, false)
    domElement.addEventListener('mousemove', onMouseMove, false)
    domElement.addEventListener('mouseup', onMouseUp, false)

    domElement.addEventListener('touchstart', touchStart, false)
    domElement.addEventListener('touchmove', touchMove, false)
    domElement.addEventListener('touchend', touchEnd, false)

    activationCleanup.push(() => {
      domElement.removeEventListener('mousedown', onMouseDown, false)
      domElement.removeEventListener('mousemove', onMouseMove, false)
      domElement.removeEventListener('mouseup', onMouseUp, false)

      domElement.removeEventListener('touchstart', touchStart, false)
      domElement.removeEventListener('touchmove', touchMove, false)
      domElement.removeEventListener('touchend', touchEnd, false)
    })

    this.active = true
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    if (this.active === false) {
      return
    }

    activationCleanup.forEach((f) => f())
    activationCleanup.length = 0

    this.active = false
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.handleObjectSelectedForAutoActivate = function (object) {
    if (object && object.type === 'OsModuleGrid') {
      scope.activate()
    } else {
      scope.deactivate()
    }
  }

  this.autoActivateRefresh = function () {
    if (!scope.active && editor.selected?.type === 'OsModuleGrid') {
      scope.activate()
    }
  }

  this.autoActivate = function (value) {
    if (typeof value === 'undefined') {
      return this._autoActivate
    }

    if (value !== this._autoActivate) {
      this._autoActivate = value
      if (value) {
        editor.signals.objectSelected.add(this.handleObjectSelectedForAutoActivate)

        // Check if it should be immediately activated based on current selection
        scope.autoActivateRefresh()
      } else {
        editor.signals.objectSelected.remove(this.handleObjectSelectedForAutoActivate)
      }
    }
  }

  this.isPainting = function () {
    return !!placementSession
  }

  this.getPlacementMode = function () {
    return placementMode
  }

  this.isActive = function () {
    return this.active
  }

  ///////////////////////////////////////////////
  // #endregion   Controller Activation APIs
  ///////////////////////////////////////////////

  return this
}

ModulePlacementController.prototype = Object.create(THREE.EventDispatcher.prototype)
ModulePlacementController.prototype.constructor = ModulePlacementController
