/**
 * @author adampryor
 */
var CallbackStackController = function (editor, viewport) {
  this.name = 'CallbackStack'

  var getDomElement = function () {
    return viewport.container.dom
  }

  var scope = this

  this.maxCallbackDepth = 500
  this.maxAllowedOccurences = 10
  this.printStackOnError = false

  this.callbackStack = []

  this.activate = function () {
    this.active = true

    getDomElement().addEventListener('mousedown', this.clearCallbackStack, false)
    getDomElement().addEventListener('mousemove', this.clearCallbackStack, false)
    getDomElement().addEventListener('touchstart', this.clearCallbackStack, false)
    getDomElement().addEventListener('touchend', this.clearCallbackStack, false)
    getDomElement().addEventListener('touchmove', this.clearCallbackStack, false)
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    this.active = false

    getDomElement().removeEventListener('mousedown', this.clearCallbackStack, false)
    getDomElement().removeEventListener('mousemove', this.clearCallbackStack, false)
    getDomElement().removeEventListener('touchstart', this.clearCallbackStack, false)
    getDomElement().removeEventListener('touchend', this.clearCallbackStack, false)
    getDomElement().removeEventListener('touchmove', this.clearCallbackStack, false)
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.recordIfNewOrReturnFalse = function (action, maxAllowedOccurences) {
    if (typeof maxAllowedOccurences === 'undefined') {
      maxAllowedOccurences = this.maxAllowedOccurences
    }

    if (this.callbackStack.length > this.maxCallbackDepth) {
      console.log('Warning: stack too large (>' + this.maxCallbackDepth + '), we should abort callbacks')
      if (this.printStackOnError) {
        console.log(this.callbackStack)
      }
      return false
    }

    if (maxAllowedOccurences === 1 && this.isActionInStack(action)) {
      console.log('Warning: action found in stack', action)
      return false
    } else if (this.countOccurencesInStack(action) >= maxAllowedOccurences) {
      console.log('Warning: action already found max ' + maxAllowedOccurences + ' times in stack', action)
      if (this.printStackOnError) {
        console.log(this.callbackStack)
      }
      return false
    } else {
      this.recordAction(action)
      return true
    }
  }

  this.countOccurencesInStack = function (action) {
    return this.callbackStack.filter(function (_action) {
      return action == _action
    }).length
  }

  this.isActionInStack = function (action) {
    return this.callbackStack.indexOf(action) != -1
  }

  this.clearCallbackStack = function (event) {
    //console.log('Designer.clearCallbackStack')
    scope.callbackStack = []
  }

  this.printStack = function () {
    console.log(
      this.callbackStack.map(function (action) {
        return action.substr(0, 30)
      })
    )
  }

  this.recordAction = function (action) {
    this.callbackStack.push(action)
    // if(this.callbackStack.length > 10){
    //    this.printStack()
    // }
  }
}

CallbackStackController.prototype = Object.create(THREE.EventDispatcher.prototype)
CallbackStackController.prototype.constructor = CallbackStackController
