// Global Dependencies: THREE, Editor, OsFacet, OsModuleGrid
// Extension Dependencies: ModuleGridOutliner

// Extension Description:
// 1. When a roof facet is selected, highlights the module grids that are on that facet
// 2. When a module grid is selected and the module grid is on a non-spatial facet,
//   highlights the other module grids that are also on the facet
//
// A module grid that's highlighted will have an orange outline
class SharedFacetVfx {
  static name = 'SharedFacetVfx'

  isActive = false

  #editorInstance
  // #highlightSession = null
  #outlineSession = null
  #outlineSessionCleanup = []

  constructor(editorInstance) {
    if (!(editorInstance instanceof Editor)) {
      throw new Error('Cannot instantiate SharedFacetVfx extension: invalid Editor instance.')
    }
    this.#editorInstance = editorInstance
  }

  /////////////////////////////////////
  //        PUBLIC METHODS
  /////////////////////////////////////

  activate = () => {
    if (this.isActive) return // already active
    const outlinerExtension = this.#editorInstance.extensions.ModuleGridOutliner

    if (!outlinerExtension) {
      console.error('Cannot activate SharedFacetVfx extension: Dependency extension [ModuleGridOutliner] not found. ')
      return
    }

    if (!outlinerExtension.isActive) {
      console.error('Cannot activate SharedFacetVfx extension:  Dependency extension [ModuleGridOutliner] not active.')
      return
    }

    this.#editorInstance.signals.objectSelected.add(this.#onObjectSelected)
    this.#editorInstance.signals.objectDeselected.add(this.#onObjectDeselected)
    this.#editorInstance.signals.objectChanged.add(this.#onObjectChanged)
    this.isActive = true
  }

  deactivate = () => {
    if (!this.isActive) return // already inactive
    if (this.#hasOutlineSession) {
      this.#clearOutlineSession()
    }
    this.#editorInstance.signals.objectSelected.remove(this.#onObjectSelected)
    this.#editorInstance.signals.objectDeselected.remove(this.#onObjectDeselected)
    this.#editorInstance.signals.objectChanged.remove(this.#onObjectChanged)
    this.isActive = false
  }

  /////////////////////////////////////
  //        SIGNAL HANDLERS
  /////////////////////////////////////

  #startOutlineSession = (object) => {
    if (object instanceof OsFacet) {
      this.#startOutlineSessionForFacet(object)
      return
    }

    if (object instanceof OsModuleGrid) {
      this.#startOutlineSessionForModuleGrid(object)
      return
    }
  }

  #startOutlineSessionForFacet = (facet) => {
    this.#outlineSession = { trigger: facet }
    const moduleGridsOnFacet = this.#getModuleGridsOnFacet(facet)
    this.#showOutlines(moduleGridsOnFacet)
  }

  #startOutlineSessionForModuleGrid = (moduleGrid) => {
    this.#outlineSession = { trigger: moduleGrid }

    if (moduleGrid.facet?.isNonSpatial()) {
      const coFacetModuleGrids = this.#getCoFacetModuleGridsOfModuleGrid(moduleGrid)
      this.#showOutlines(coFacetModuleGrids)
    }

    const snapHandler = (newFacet, _prevFacet) => {
      this.#removeOutlines()
      if (newFacet?.isNonSpatial()) {
        this.#showOutlines(this.#getCoFacetModuleGridsOfModuleGrid(moduleGrid))
      }
    }

    const unsnapHandler = (_prevFacet, _newFacet) => this.#removeOutlines()

    moduleGrid.signals.snappedToFacet.add(snapHandler)
    moduleGrid.signals.unsnappedFromFacet.add(unsnapHandler)

    this.#outlineSessionCleanup.push(() => {
      moduleGrid.signals.snappedToFacet.remove(snapHandler)
      moduleGrid.signals.unsnappedFromFacet.remove(unsnapHandler)
    })
  }

  #hasOutlineSession = () => !!this.#outlineSession

  #clearOutlineSession = () => {
    this.#outlineSession = null
    this.#removeOutlines()
    this.#outlineSessionCleanup.forEach((f) => f())
    this.#outlineSessionCleanup = []
  }

  #getOutlineSession = () => this.#outlineSession

  #onObjectSelected = (object) => {
    if (object instanceof OsFacet) {
      this.#startOutlineSession(object)
      return
    }

    if (object instanceof OsModuleGrid) {
      this.#startOutlineSession(object)
      return
    }

    if (object instanceof OsGroup && this.#hasOutlineSession()) {
      this.#clearOutlineSession()
      return
    }
  }

  #onObjectDeselected = (object) => {
    if (this.#hasOutlineSession() && this.#getOutlineSession().trigger === object) {
      this.#clearOutlineSession()
    }
  }

  #onObjectChanged = (object) => {
    if (this.#hasOutlineSession() && this.#getOutlineSession().trigger === object) {
      this.#refreshOutlinesDebounced()
    }
  }

  #getCoFacetModuleGridsOfModuleGrid = (moduleGrid) => {
    const facet = moduleGrid.facet
    if (!facet) return []
    return this.#getModuleGridsOnFacet(facet).filter((obj) => obj !== moduleGrid)
  }

  #getModuleGridsOnFacet = (facet) => {
    if (!facet) return []
    return facet.objectsFloating.filter(
      (obj) => obj instanceof OsModuleGrid && obj.getSystem() === this.#editorInstance.selectedSystem
    )
  }

  #showOutlines = (moduleGrids) => {
    if (moduleGrids.length === 0) return
    const outlinerExtension = this.#editorInstance.extensions.ModuleGridOutliner
    const outlineGroupName = 'EditorExtension::SharedFacetVfx::ModuleGridOutlines'
    outlinerExtension.createGroup(outlineGroupName, moduleGrids).generate(0.15)
  }

  #removeOutlines = () => {
    const outlinerExtension = this.#editorInstance.extensions.ModuleGridOutliner
    const outlineGroupName = 'EditorExtension::SharedFacetVfx::ModuleGridOutlines'
    outlinerExtension.removeGroup(outlineGroupName)
  }

  #refreshOutlines = () => {
    const outlinerExtension = this.#editorInstance.extensions.ModuleGridOutliner
    outlinerExtension.refreshGroup('EditorExtension::SharedFacetVfx::ModuleGridOutlines', true)
  }

  #refreshOutlinesDebounced = window.Utils.debounce(this.#refreshOutlines, 100)
}
