/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var UpdateSystemCalculationCommand = function (
  systemOrSystemUuid,
  newSystemUserData,
  attributeName,
  invertersUserData
) {
  Command.call(this)

  this.type = 'UpdateSystemCalculationCommand'
  this.name = 'Update System Calculation Command'
  this.timeStamp = Date.now()

  this.PROPERTIES = [
    'attributeName',
    'newSystemUserData',
    'oldSystemUserData',
    'invertersUserData',
    'oldInvertersUserData',
  ]
  this.OBJECT_REFERENCES = ['system']

  this.oldSystemUserData = null
  this.oldInvertersUserData = null

  if (arguments.length) {
    this.systemOrSystemUuid = systemOrSystemUuid
    this.system =
      typeof systemOrSystemUuid === 'string'
        ? this.editor.scene.getObjectByProperty('uuid', systemOrSystemUuid)
        : systemOrSystemUuid
    this.newSystemUserData = newSystemUserData
    this.attributeName = attributeName
    this.invertersUserData = invertersUserData
  }
}

UpdateSystemCalculationCommand.prototype = {
  execute: function () {
    this.editor.signals.sceneGraphChanged.active = false

    this.oldSystemUserData = this.system.getAlterDataFromObject(this.newSystemUserData)
    this.oldInvertersUserData = this.system.inverters().map(function (i) {
      return i['userData']
    })
    this.system.populateWithDataFromObject(this.newSystemUserData, this.invertersUserData)
    //set attribute name to "output" to skip recalcs
    if (!this.editor.changingHistory) {
      this.system.awaitingCalcs = false
    }
    this.editor.signals.objectChanged.dispatch(this.system, 'output')
    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.systemCalculationsUpdated.dispatch()
  },

  undo: function () {
    this.editor.signals.sceneGraphChanged.active = false
    this.system.populateWithDataFromObject(this.oldSystemUserData, this.oldInvertersUserData)
    this.editor.signals.objectChanged.dispatch(this.system, 'output')

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.systemCalculationsUpdated.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
