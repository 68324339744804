class OpenLayers2DMapAdapter {
  constructor(map) {
    this.map = map
  }

  getZoom() {
    return this.map.getView().getZoom()
  }

  setZoom(zoom) {
    this.map.getView().setZoom(zoom)
  }
}
