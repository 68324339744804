/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @constructor
 */

var ZoomCameraByFactorCommand = function (zoomCameraFactor, undoZoomCameraFactor) {
  Command.call(this)
  this.type = 'ZoomCameraByFactorCommand'
  this.name = 'Zoom Camera By Factor Command'
  this.updatable = true
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['zoomCameraFactor', 'undoZoomCameraFactor']

  if (arguments.length) {
    this.zoomCameraFactor = zoomCameraFactor
    this.undoZoomCameraFactor = undoZoomCameraFactor
  }
}

ZoomCameraByFactorCommand.prototype = {
  execute: function () {
    if (this.zoomCameraFactorForExecuteOverride && !this.editor.changingHistory) {
      this.editor.controllers.Camera.zoomCameraByFactor(this.zoomCameraFactorForExecuteOverride)
    } else {
      this.editor.controllers.Camera.zoomCameraByFactor(this.zoomCameraFactor)
    }

    // Disable signal because otherwise when we do animated zoom it will be extremely slow since the signal
    // triggers an extra render AND a react redraw on panels
    var dispatchViewsChangedSignal = false
    window.MapHelper.matchScene()
    window.ViewHelper.saveView(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      dispatchViewsChangedSignal
    )
  },

  undo: function () {
    this.editor.controllers.Camera.zoomCameraByFactor(this.undoZoomCameraFactor)
    window.ViewHelper.saveView()
  },

  update: function (cmd) {
    this.zoomCameraFactorForExecuteOverride = cmd.zoomCameraFactor
    this.zoomCameraFactor = this.zoomCameraFactor * cmd.zoomCameraFactor
    this.undoZoomCameraFactor = this.undoZoomCameraFactor * cmd.undoZoomCameraFactor
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
