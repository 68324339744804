/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var UpdateViewCommand = function (newViewInstance, _ViewHelper, commandUUID) {
  Command.call(this)

  this.type = 'UpdateViewCommand'
  this.name = 'Update View Command'
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['viewUuid', 'newViewData', 'oldViewData']

  this.ViewHelper = _ViewHelper || window.ViewHelper

  if (arguments.length) {
    // keep view uuid unchaged
    this.oldViewInstance = _ViewHelper?.selectedView()
    this.viewUuid = this.oldViewInstance.uuid

    this.newViewInstance = newViewInstance

    // force uuid to stay unchanged
    this.newViewInstance.uuid = this.viewUuid

    this.newViewData = newViewInstance.toObject()
    this.oldViewData = _ViewHelper?.selectedView().toObject()
  }
}

UpdateViewCommand.prototype = {
  execute: function () {
    if (this.newViewInstance) {
      ViewHelper.updateView(this.newViewInstance)
    } else {
      ViewHelper.updateView(new ViewInstance(this.newViewData))
    }
    this.ViewHelper.loadViewByUuid(this.newViewData.uuid, this.editor)
  },

  undo: function () {
    if (this.oldViewInstance) {
      ViewHelper.updateView(this.oldViewInstance)
    } else {
      ViewHelper.updateView(new ViewInstance(this.oldViewData))
    }
    this.ViewHelper.loadViewByUuid(this.oldViewData.uuid, this.editor)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
