window.pluginSolariaAcPanelsUs = {
  id: 2600,
  countryCodes: ['US'],
  name: 'pluginSolariaAcPanelsUs',
  componentCodes: [
    'SOLARIA POWERXT-350R-AC',
    'SOLARIA POWERXT-355R-AC',
    'SOLARIA POWERXT-360R-AC',
    'SOLARIA POWERXT-365R-AC',
    'SOLARIA POWERXT-400R-PM-AC',
    'SOLARIA POWERXT®-400R-PM-AC',
  ],
  plugin: function () {
    var panelCodeToMicroCode = {
      'SOLARIA POWERXT-350R-AC': 'IQ7PLUS-72-2-US',
      'SOLARIA POWERXT-355R-AC': 'IQ7PLUS-72-2-US',
      'SOLARIA POWERXT-360R-AC': 'IQ7PLUS-72-2-US',
      'SOLARIA POWERXT-365R-AC': 'IQ7PLUS-72-2-US',
      'SOLARIA POWERXT-400R-PM-AC': 'IQ7A-72-2-US',
      'SOLARIA POWERXT®-400R-PM-AC': 'IQ7A-72-2-US',
    }

    var microCodes = Object.values(panelCodeToMicroCode).filter((v, i, a) => a.indexOf(v) === i)

    function upperCaseSafe(value) {
      return value && value.toUpperCase ? value.toUpperCase() : value
    }

    function indexOfCaseInsensitive(valuesArray, value) {
      var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))
      return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
    }

    function codesForSystem(system) {
      return [system.moduleType().code].concat(
        system.inverters().map((i) => i.code),
        system.batteries().map((b) => b.code),
        system.others().map((o) => o.code)
      )
    }

    function systemHasComponent(system, codes) {
      return countComponentsForSystem(system, codes.constructor === Array ? codes : [codes]) > 0
    }

    function countComponentsForSystem(system, codes) {
      return codesForSystem(system).filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
    }

    function getInverterComponentForCode(code) {
      var codeUpperCase = upperCaseSafe(code)
      return window.AccountHelper.getComponentInverterSpecsAvailable().filter(
        (component) => upperCaseSafe(component.code) === codeUpperCase
      )[0]
    }

    function syncMicroInverters(system, microInverterCode) {
      if (!systemHasComponent(system, microInverterCode)) {
        var componentType = getInverterComponentForCode(microInverterCode)

        if (componentType) {
          //@TODO: Handle undo/redo?
          window.editor.execute(
            new window.AddObjectCommand(
              new window.OsInverter({
                inverter_id: componentType.id,
                quantity: system.moduleQuantity(),
              }),
              system,
              false
            )
          )
          Designer.showNotification('Integrated micro-inverter added: ' + microInverterCode)
        } else {
          console.warn('Matching micro-inverter not enabled on org. Prompt to enable...')

          if (
            !window.AccountHelper.activateComponentsRequests.some(
              (componentRequest) => componentRequest.message && componentRequest.message.indexOf('Solaria') !== -1
            )
          ) {
            window.AccountHelper.activateComponentsRequests.push({
              message: 'Activate integrated inverters for Solaria AC Panels',
              codes: microCodes,
            })
            window.Designer.showNotification(
              'Integrated inverters for Solaria AC Panels are not yet enabled. Open the "System Summary" tab for easy activation shortcut.'
            )
          }

          // Force System panel to refresh
          window.editor.signals.sceneGraphChanged.dispatch()
        }
      }
    }

    function refresh(system) {
      if (window.WorkspaceHelper.getIsProjectLite()) {
        // Don't run on Lite projects
        return
      }
      for (var panelCode in panelCodeToMicroCode) {
        // Add micro if not enabled (quantity updates automatically so we don't need to set quantity in plugin)
        if (system.moduleQuantity() > 0 && systemHasComponent(system, panelCode)) {
          var microInverterCode = panelCodeToMicroCode[panelCode]
          syncMicroInverters(system, microInverterCode)
          break
        }
      }
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refresh(system)
        }
      }
    }

    function objectAdded(object) {
      if (window.studioDebug) {
        console.log('SolariaAcPanels objectAdded', object)
      }
      refreshSystemFromObject(object)
    }

    function objectChanged(object) {
      if (window.studioDebug) {
        console.log('SolariaAcPanels objectChanged', object)
      }
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (window.studioDebug) {
        console.log('SolariaAcPanels objectRemoved', object)
      }
      refreshSystemFromObject(object)
    }

    function sceneGraphChanged(system) {
      if (window.studioDebug) {
        console.log('SolariaAcPanels sceneGraphChanged')
      }
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      if (window.studioDebug) {
        console.log('SolariaAcPanels pluginLoaded')
        console.warn('Inspect Script Here for interactive debugging in javascript console...')
      }
      refreshAllSystems()
    }

    function pluginUnloaded() {
      window.AccountHelper.activateComponentsRequests = window.AccountHelper.activateComponentsRequests.filter(
        (request) => {
          return !request.message?.includes('Solaria')
        }
      )
      if (window.studioDebug) {
        console.log('SolariaAcPanels pluginUnloaded')
      }
      try {
        refreshAllSystems()
      } catch (error) {
        console.error(error)
      }
    }

    return {
      objectAdded: objectAdded,
      objectChanged: objectChanged,
      objectRemoved: objectRemoved,
      sceneGraphChanged: sceneGraphChanged,
      liteProjectUpgraded: liteProjectUpgraded,
      pluginLoaded: pluginLoaded,
      pluginUnloaded: pluginUnloaded,
    }
  },
}
