/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newUuid string
 * @constructor
 */

var SetUuidCommand = function (object, newUuid) {
  Command.call(this)

  this.type = 'SetUuidCommand'
  this.name = 'Update UUID'
  this.timeStamp = Date.now()

  this.PROPERTIES = ['newUuid', 'oldUuid']

  // Do not use standard "object" reference beacuse that requires looking up the object using UUID which will not work
  // in reverse because in reverse it must lookup the object using newUuid not oldUuid
  // this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.oldUuid = object.uuid
    this.newUuid = newUuid
  }
}

SetUuidCommand.prototype = {
  execute: function () {
    if (!this.oldUuid) {
      this.oldUuid = this.object.uuid
    }

    var object = this.editor.objectByUuid(this.oldUuid)
    object.uuid = this.newUuid
    this.editor.signals.objectChanged.dispatch(object)
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    var object = this.editor.objectByUuid(this.newUuid)
    object.uuid = this.oldUuid
    this.editor.signals.objectChanged.dispatch(object)
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
