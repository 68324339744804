/**
 * @author mrdoob / http://mrdoob.com/
 */

// function genBMPUri(width, pixels) {
//   let LE = n =>
//     (n + 2 ** 32)
//       .toString(16)
//       .match(/\B../g)
//       .reverse().join``
//   let wh = LE(width) + LE(pixels.length / width / 4)
//   let size = LE(109 + pixels.length)
//   let r = n => '0'.repeat(n)
//
//   let head = `424d${size}ZZ7BZ006CZ00${wh}01002Z3${r(50)}FFZFFZFFZZZFF${r(106)}`
//
//   return (
//     'data:image/bmp;base64,' +
//     btoa(
//       String.fromCharCode(
//         ...head
//           .replace(/Z/g, '0000')
//           .match(/../g)
//           .map(x => +`0x${x}`)
//       )
//     ) +
//     btoa(pixels.map(p => String.fromCharCode(p)).join``)
//   )
// }

var INTERSECTION_LINE_PRECISION = 0.5

function createGridForPlane(size, divisions, color1, color2, plane) {
  var _grid = new THREE.GridHelper(size, divisions, color1, color2)
  if (plane === 'xy') {
    _grid.rotateX(Math.PI / 2)
  }
  return _grid
}

var Viewport = function (editor, options) {
  var signals = editor.signals

  // object picking

  var raycaster = new THREE.Raycaster()
  var mouse = new THREE.Vector2()

  var container = new UI.Panel()
  container.setId('viewport')
  container.setPosition('absolute')
  this.container = container

  if (options && options['Info'] !== false) {
    container.add(new Viewport.Info(editor))
  }

  var scope = this
  editor.setViewport(this)

  //

  var renderer = null

  var camera = editor.camera
  var scene = editor.scene
  var sceneHelpers = editor.sceneHelpers

  var objects = []

  var groundPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0)
  var overlayPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), -50)

  //

  function getDepthMapSettings() {
    var SHADING_GRID_RESOLUTION = 400
    var SHADING_GRID_SIZE_METERS = {
      min: 100,
      max: 2000,
    }

    // Take the largest of a) scene bounding box (larger of either width or height) b) standard terrain size (100m)
    var sceneBoundingBoxSize = SceneHelper.getSceneBoundingBoxWithCache().getSize(new THREE.Vector3())
    var sizeMeters = Math.max(sceneBoundingBoxSize.x, sceneBoundingBoxSize.y, SHADING_GRID_SIZE_METERS.min)

    // Add maximum scene size of 1000 meters to protect against stray objects off in the distance which might
    // effectively destroy the shading calcs
    if (sizeMeters > SHADING_GRID_SIZE_METERS.max) {
      console.warn('Scene size larger SHADING_MAX_SIZE_METERS, using maximum size instead')
      sizeMeters = SHADING_GRID_SIZE_METERS.max
    }

    return {
      sizeMeters: sizeMeters,
      metersPerPixel: sizeMeters / SHADING_GRID_RESOLUTION,
      sizePixels: SHADING_GRID_RESOLUTION,
    }
  }
  this.getDepthMapSettings = getDepthMapSettings

  // helpers
  var grid
  var GROUND_PLANE_GRID_DIVISIONS = 10
  var GROUND_PLANE_GRID_SIZE = 100
  var GROUND_PLANE_GRID_CELL_SIZE = GROUND_PLANE_GRID_SIZE / GROUND_PLANE_GRID_DIVISIONS

  var gridPosition = function () {
    var focusGroundPoint = worldPositionAtViewFinderCenter()
    if (!focusGroundPoint) {
      focusGroundPoint = new THREE.Vector3()
    }
    var gridOrginSnapped = new THREE.Vector3(
      Math.floor(focusGroundPoint.x / GROUND_PLANE_GRID_CELL_SIZE) * GROUND_PLANE_GRID_CELL_SIZE,
      Math.floor(focusGroundPoint.y / GROUND_PLANE_GRID_CELL_SIZE) * GROUND_PLANE_GRID_CELL_SIZE,
      0.01 //show slightly above ground in case ground z == 0, and prevent z-fighting for anything at z=0
    )
    return gridOrginSnapped
  }

  var drawGrid = () => {
    // Ignore if editor.viewport is not fully configured. This causes all kinds of trouble for unit tests
    // so we can just ignore it.
    if (!editor.viewport.rect) {
      return
    }

    if (!grid) {
      grid = createGridForPlane(GROUND_PLANE_GRID_SIZE, GROUND_PLANE_GRID_DIVISIONS, 0x888888, 0x888888, 'xy')

      var arrowHelper = new THREE.ArrowHelper(
        new THREE.Vector3(0, 0, -1), //this gets rotated later to face up y axis
        new THREE.Vector3(0, 0, -50),
        10,
        0x888888,
        3,
        0.5
      )
      arrowHelper.visible = true
      arrowHelper.type = 'ArrowHelper'
      arrowHelper.userData.excludeFromExport = true
      arrowHelper.line.selectable = false
      arrowHelper.cone.selectable = false
      grid.add(arrowHelper)

      editor.grid = grid
      grid.visible = false
    }

    grid.position.copy(gridPosition())

    editor.grid = grid

    sceneHelpers.add(grid)
    return grid
  }

  if (options && options.grid !== false) {
    drawGrid()
  }

  //

  var box = new THREE.Box3()
  this.box = box
  var selectionBox = new OsBoxHelper()
  selectionBox.visible = false
  selectionBox._filterFunction = function (o) {
    return o.selectable !== false
  }
  sceneHelpers.add(selectionBox)
  this.selectionBox = selectionBox
  var objectPositionOnDown = null
  var objectRotationOnDown = null
  var objectScaleOnDown = null

  var selectableObjectTypes = [
    'OsFacet',
    'OsObstruction',
    'OsStructure',
    'OsClipper',
    'OsAnnotation',
    'OsModuleGrid',
    'OsModule',
    'OsNode',
    'OsEdge',
    'OsTree',
    'OsGroup',
  ]

  var updateFromTransformControls = function (object) {
    switch (transformControls.getMode()) {
      case 'translate':
        if (objectPositionOnDown && !objectPositionOnDown.equals(object.position)) {
          editor.execute(new SetPositionCommand(object, object.position, objectPositionOnDown))
        }

        break

      case 'rotate':
        if (objectRotationOnDown && !objectRotationOnDown.equals(object.rotation)) {
          editor.execute(new SetRotationCommand(object, object.rotation, objectRotationOnDown))
        }

        break

      case 'scale':
        if (objectScaleOnDown && !objectScaleOnDown.equals(object.scale)) {
          editor.execute(new SetScaleCommand(object, object.scale, objectScaleOnDown))
        }

        break

      default:
        break
    }
  }

  var transformControls = new THREE.TransformControls(camera, container.dom)
  this.transformControls = transformControls
  transformControls.size = 0.25
  transformControls.addEventListener('objectChange', function (data) {
    var object = transformControls.object

    if (object !== undefined) {
      updateFromTransformControls(object)
    }

    renderIfNotAnimating('transformControls objectChange')
  })

  transformControls.addEventListener('change', function (data) {
    //Change of the gizmo itself, changes to object handled separately in objectChange event

    var object = transformControls.object

    if (object !== undefined) {
      selectionBox.setFromObject(object, selectionBox._filterFunction)

      if (editor.helpers[object.id] !== undefined) {
        editor.helpers[object.id].update()
      }
    }

    renderIfNotAnimating('transformControls change')
  })
  transformControls.addEventListener('mouseDown', function () {
    var object = transformControls.object

    objectPositionOnDown = object.position.clone()
    objectRotationOnDown = object.rotation.clone()
    objectScaleOnDown = object.scale.clone()

    //controls.enabled = false;
  })

  sceneHelpers.add(transformControls)

  // events
  function getPositionInFrontOfCamera(screenFraction, distance) {
    if (!distance) {
      distance = 10
    }

    mouse.set(screenFraction.x * 2 - 1, -(screenFraction.y * 2) + 1)
    raycaster.setFromCamera(mouse, camera)

    return raycaster.ray.recast(distance).origin
  }
  this.getPositionInFrontOfCamera = getPositionInFrontOfCamera

  function getIntersects(screenFraction, _objects, linePrecision, recursive) {
    if (typeof linePrecision === 'undefined') {
      //@TODO: Require different scale on Tablet/Mobile???
      linePrecision = INTERSECTION_LINE_PRECISION
    }

    if (!recursive) recursive = false

    mouse.set(screenFraction.x * 2 - 1, -(screenFraction.y * 2) + 1)
    this.generateRaycasterFromMouseCoordAndCamera(mouse, camera, raycaster)

    raycaster.linePrecision = linePrecision

    var intersects = raycaster.intersectObjects(_objects, recursive)

    /*
    Handle problem with OsStructure, where it will often be selected in front of modules.
    We handle this by re-sorting any OsStructure intersections behind the last OsModule intersection
    */

    var LOWER_PRIORITY_OBJECTS_INCREMENT_DISTANCE = 0.5

    // @TODO: Can we ignore OsStructure from here now, because OsStructure is now excluded from selection raytracing
    // anyway, because we rely on selection of the structure's OsFacetMesh children instead?
    var LOWER_PRIORITY_OBJECTS = ['OsStructure', 'OsFacetMesh']

    // if any structures/facets are returned, modify the distance and re-sort
    if (intersects.some((intersection) => LOWER_PRIORITY_OBJECTS.indexOf(intersection?.object?.type) !== -1)) {
      intersects.forEach((intersection) => {
        intersection.distanceAdjusted =
          LOWER_PRIORITY_OBJECTS.indexOf(intersection.object.type) !== -1
            ? intersection.distance + LOWER_PRIORITY_OBJECTS_INCREMENT_DISTANCE
            : intersection.distance
      })
      intersects.sort((a, b) => (a.distanceAdjusted > b.distanceAdjusted ? 1 : -1))
    }

    return intersects
  }
  this.getIntersects = getIntersects

  function getMousePosition(x, y) {
    var rect = scope.rect()
    return [(x - rect.left) / rect.width, (y - rect.top) / rect.height]
  }
  this.getMousePosition = getMousePosition

  function getIntersectionWithPlane(point, plane) {
    mouse.set(point.x * 2 - 1, -(point.y * 2) + 1)
    raycaster.setFromCamera(mouse, camera)
    var result = raycaster.ray.intersectPlane(plane, new THREE.Vector3())
    if (!result) {
      // No intersection so try reversing the ray
      raycaster.ray.direction.negate()
      result = raycaster.ray.intersectPlane(plane, new THREE.Vector3())
    }
    return result
  }
  this.getIntersectionWithPlane = getIntersectionWithPlane

  function getIntersectionWithFlatPlaneAtElevation(point, elevation) {
    if (elevation === 0) {
      return getIntersectionWithGround(point)
    } else {
      return getIntersectionWithPlane(point, new THREE.Plane(new THREE.Vector3(0, 0, -1), elevation))
    }
  }
  this.getIntersectionWithFlatPlaneAtElevation = getIntersectionWithFlatPlaneAtElevation

  function generateRaycasterFromMouseCoordAndCamera(mouse, camera, raycaster) {
    if (!raycaster) {
      raycaster = new THREE.Raycaster()
    }
    raycaster.setFromCamera(mouse, camera)
    const cameraDirection = camera.getWorldDirection(new THREE.Vector3())
    raycaster.ray.origin.add(cameraDirection.negate().setLength(1000))
    return raycaster
  }
  this.generateRaycasterFromMouseCoordAndCamera = generateRaycasterFromMouseCoordAndCamera

  function getIntersectionWithGround(point) {
    return getIntersectionWithPlane(point, groundPlane)
  }
  this.getIntersectionWithGround = getIntersectionWithGround

  function getIntersectionWithOverlay(point) {
    return getIntersectionWithPlane(point, overlayPlane)
  }
  this.getIntersectionWithOverlay = getIntersectionWithOverlay

  function getIntersectionWithTerrainRaw(point) {
    var terrain = editor.getTerrain()
    if (!terrain) {
      return null
    }

    //@todo: Accelerate special case when camera is perfectly top-down, we can bypass the need to find intersections
    mouse.set(point.x * 2 - 1, -(point.y * 2) + 1)
    raycaster.setFromCamera(mouse, camera)

    var intersects = raycaster.intersectObject(terrain, false)
    if (intersects.length === 0) {
      return null
    } else {
      return intersects[0].point
    }
  }

  function getIntersectionWithTerrainOrGround(mousePosition) {
    var intersection = getIntersectionWithTerrainRaw(mousePosition)
    if (intersection) {
      return intersection
    } else {
      return getIntersectionWithGround(mousePosition)
    }
  }
  this.getIntersectionWithTerrainOrGround = getIntersectionWithTerrainOrGround

  // getIntersectionWithTerrainWithCache totally doesn't work because it assumes camera has not changed
  // @TODO: Consider fixing terrain caching by clearing cache whenever camera changes
  // var getIntersectionWithTerrainWithCache = window.Utils.cacheFunction(getIntersectionWithTerrainRaw, function(point) {
  //   var cacheResolutionPointsPerMeter = 200
  //   return (
  //     Math.round(point.x * cacheResolutionPointsPerMeter) + '_' + Math.round(point.y * cacheResolutionPointsPerMeter)
  //   )
  // })
  //
  // this.getIntersectionWithTerrain = getIntersectionWithTerrainWithCache
  this.getIntersectionWithTerrain = getIntersectionWithTerrainRaw

  function worldToScreenFraction(_position) {
    // map to normalized device coordinate (NDC) space
    var p = _position.clone()
    p = p.project(camera)
    p.x *= 0.5
    p.y *= 0.5
    p.add(new THREE.Vector3(0.5, 0.5, 0))
    p.y = 1 - p.y
    return p
  }
  this.worldToScreenFraction = worldToScreenFraction

  function screenFractionToWorld(screenFraction) {
    // unmap from normalized device coordinate (NDC) space
    var p = new THREE.Vector3(screenFraction.x, 1 - screenFraction.y, 0)
    p.sub(new THREE.Vector3(0.5, 0.5, 0))
    p.x *= 2.0
    p.y *= 2.0
    p = p.unproject(camera)
    return p
  }
  this.screenFractionToWorld = screenFractionToWorld

  function screenSize() {
    var rect = scope.rect()
    return new THREE.Vector2(rect.width, rect.height)
  }
  this.screenSize = screenSize

  function screenFractionToScreen(screenFraction) {
    var _screenSize = screenSize()
    return new THREE.Vector3(
      Math.round(screenFraction.x * _screenSize.x),
      Math.round(screenFraction.y * _screenSize.y),
      0
    )
  }
  this.screenFractionToScreen = screenFractionToScreen

  function screenToScreenFraction(screenCoordinates) {
    var _screenSize = screenSize()
    return new THREE.Vector3(screenCoordinates.x / _screenSize.x, screenCoordinates.y / _screenSize.y, 0)
  }
  this.screenToScreenFraction = screenToScreenFraction

  function worldToScreen(_position) {
    var screenFraction = worldToScreenFraction(_position)
    return screenFractionToScreen(screenFraction)
  }
  this.worldToScreen = worldToScreen

  function worldToScreenOffsetFromCenter(_position) {
    var screenPosition = worldToScreen(_position)
    var _screenSize = screenSize()
    return new THREE.Vector2(screenPosition.x - _screenSize.x / 2, screenPosition.y - _screenSize.y / 2)
  }
  this.worldToScreenOffsetFromCenter = worldToScreenOffsetFromCenter

  function objectsAll() {
    return objects
  }
  this.objectsAll = objectsAll

  function objectsSelectable() {
    //Exclude selectable objects belonging to hidden systems
    return objects
      .map(function (o) {
        return o.selectable !== false && (!o.getSystem || !o.getSystem() || o.getSystem().visible === true) ? o : null
      })
      .filter(Boolean)
  }
  this.objectsSelectable = objectsSelectable

  function objectsSelectableAndModules(restrictModulesWithinGrid, allowCreateSelectionDelegateGroup, includeHandles) {
    // Currently handles are not automatically selected before other objects in the scene, so when a single object is selected
    // it will be based on the closest object. Currently this seems to work fine because handles are positioned closer to the camera than
    // other scene objects, but if other objects are positioned closer than the handles this may block clicking the handles.
    // If this happens we may need to make changes to ensure that handles are always selected before other objects.
    // One possible implementation is to check for intersections with any handles first, and only check for intersections
    // with the scene if no handle intersections are found. But this could have performance implications so we will avoid
    // implementing it unless it becomes necessary. (Note this will actually improve performance when clicking on a handle, but
    // the expected improvement is minimal).
    var objectsToIntersect =
      includeHandles && editor?.controllers?.Handle?.handles
        ? objects.concat(Object.values(editor.controllers.Handle.handles))
        : objects

    //Now includes a non-selectable object if it is clickable. Handy for creating button-like objects which can be clicked but not selected
    return objectsToIntersect
      .map(function (o) {
        var targetObject = Designer.resolveSelectionDelegate(o, allowCreateSelectionDelegateGroup)
        // var targetObject = o.selectionDelegate ? o.selectionDelegate : o

        if (o.clickable === true) {
          return o
        }

        if (selectableObjectTypes.indexOf(targetObject.type) === -1) {
          if (
            Designer.ALLOW_SELECT_OTHER_COMPONENT_IN_VIEWPORT &&
            targetObject?.type === 'OsOther' &&
            targetObject.selectable
          ) {
            // continue
          } else {
            return null
          }
        }

        if (o.selectable === false) {
          return null
        } else if (o.type === 'OsStructure') {
          // Structure should not be selectable directly, it will be selected if its OsfacetMeshes are intersected
          return null
        } else if (o.type === 'OsTree') {
          return o
        } else if (o.type === 'OsModule') {
          if (
            o.getSystem() &&
            o.getSystem().visible === true &&
            //ensure grid matches restrictModulesWithinGrid if supplied
            (!restrictModulesWithinGrid || o.parent === restrictModulesWithinGrid)
          ) {
            //Must check that getSystem() actually doesn't return null
            return o
          } else {
            return false
          }
        } else if (!o.getSystem || !o.getSystem() || o.getSystem().visible === true) {
          return o
        } else {
          return false
        }
      })
      .filter(Boolean)
  }
  this.objectsSelectableAndModules = objectsSelectableAndModules

  function objectsWithContextMenu() {
    // The object (or it's selectionDelegate) must have a contextMenuItems function
    // The object does NOT need to be visible. e.g. You may select an invisible OsNode using context menu
    return objects
      .map(function (o) {
        return o.getContextMenuAction ||
          o.getContextMenuItems ||
          (o.selectionDelegate && o.selectionDelegate.getContextMenuItems)
          ? o
          : null
      })
      .filter(Boolean)
  }
  this.objectsWithContextMenu = objectsWithContextMenu

  function cleanObjectsWithNoParent() {
    window.studioDebug &&
      console.log(
        'cleanObjectsWithNoParent(): We should not need to do this...why are there orphan SystemModules/ModuleGrids?'
      )
    var countInitial = objects.length
    objects = objects.filter(function (o) {
      return o.type !== 'OsModule' || o.parent.parent
    })

    var countAfter = objects.length
    if (countInitial !== countAfter) {
      console.warn('Warning: ' + (countInitial - countAfter) + 'x Orphan SystemModules/ModuleGrids found!')
    }
  }
  this.cleanObjectsWithNoParent = cleanObjectsWithNoParent

  scope.rectCached = null
  function rect() {
    if (!scope.rectCached) {
      scope.rectCached = container.dom.getBoundingClientRect()
    }
    return scope.rectCached
  }
  this.rect = rect

  // function toScreenPosition(_position3, camera)
  // {
  // 	var position3 = _position3.clone();
  //
  //     var widthHalf = 0.5*renderer.getContext().canvas.width;
  //     var heightHalf = 0.5*renderer.getContext().canvas.height;
  //
  //     position3.project(camera);
  //
  //     position3.x = ( position3.x * widthHalf ) + widthHalf;
  //     position3.y = - ( position3.y * heightHalf ) + heightHalf;
  //
  //     return {
  //         x: position3.x,
  //         y: position3.y
  //     };
  //
  // };
  // this.toScreenPosition = toScreenPosition;

  function spriteOrObjectUnderClick(event, _objects) {
    /*
    If we intersect multiple objects, we will always return the sprite even if it is further away than other objects
    otherwise we return the closest object.
    */

    // First restrict objects to only include sprites and return first match.
    // If no sprites found then continue with other objects.
    var _sprites = _objects.filter((o) => o.type === 'Sprite')

    if (_sprites.length) {
      let spriteObjectClicked = objectUnderClick(event, _sprites)
      if (spriteObjectClicked) {
        return spriteObjectClicked
      }
    }

    return objectUnderClick(event, _objects)
  }
  this.spriteOrObjectUnderClick = spriteOrObjectUnderClick

  function objectUnderClick(event, _objects, returnAll, linePrecision, recursive) {
    var intersections = clickIntersection(event, _objects, returnAll, linePrecision, recursive)
    if (intersections) {
      if (returnAll) {
        return intersections.map((i) => i.object)
      } else {
        return intersections.object
      }
    }

    return intersections // [] or null
  }
  this.objectUnderClick = objectUnderClick

  const clickIntersection = (event, _objects, returnAll, linePrecision, recursive) => {
    if (!recursive) recursive = false

    var array = scope.getMousePosition(event.clientX, event.clientY)
    var clickPosition = new THREE.Vector2().fromArray(array)

    //Default to all objects in scene
    if (!_objects) {
      _objects = this.objectsAll()
    }

    var intersects = scope.getIntersects(clickPosition, _objects, linePrecision, recursive)

    if (intersects.length > 0) {
      if (returnAll) {
        return intersects.map(function (i) {
          return i
        })
      } else {
        return intersects[0]
      }
    }

    if (returnAll) {
      return []
    } else {
      return null
    }
  }
  this.clickIntersection = clickIntersection

  function refreshRendererPixelRatio() {
    if (renderer.setPixelRatio && renderer.getPixelRatio) {
      if (window.devicePixelRatio !== renderer.getPixelRatio()) {
        renderer.setPixelRatio(window.devicePixelRatio)
      }
    }
  }
  this.refreshRendererPixelRatio = refreshRendererPixelRatio

  // signals

  signals.editorCleared.add(() => {
    objects = []
    render(true)
  })

  signals.themeChanged.add((value) => {
    switch (value) {
      case '/static/css/light.css':
        sceneHelpers.remove(grid)
        grid = new THREE.GridHelper(60, 6, 0x444444, 0x888888, 'xy')
        grid.visible = false
        sceneHelpers.add(grid)
        break
      case '/static/css/dark.css':
        sceneHelpers.remove(grid)
        grid = new THREE.GridHelper(60, 6, 0xbbbbbb, 0x888888, 'xy')
        grid.visible = false
        sceneHelpers.add(grid)
        break
      default:
        break
    }

    render()
  })

  signals.transformModeChanged.add((mode) => {
    if (mode === 'translateZ') {
      transformControls.setMode('translate')
      transformControls.setTranslationConstraint('Z')
      transformControls.transformMode = mode
    } else if (mode === 'translateXY') {
      transformControls.setMode('translate')
      transformControls.setTranslationConstraint('XY')
      transformControls.transformMode = mode
    } else if (mode === 'translateX') {
      transformControls.setMode('translate')
      transformControls.setTranslationConstraint('X')
      transformControls.transformMode = mode
    } else {
      transformControls.setMode(mode)
      transformControls.setTranslationConstraint(null)
      transformControls.transformMode = mode
    }
    render()
  })

  signals.spaceChanged.add((space) => {
    transformControls.setSpace(space)
  })

  signals.rendererChanged.add((newRenderer) => {
    if (renderer !== null) {
      try {
        container.dom.removeChild(renderer.domElement)
      } catch (e) {
        // ignore error while removing child
      }
    }

    renderer = newRenderer

    renderer.autoClear = false
    renderer.autoUpdateScene = false

    refreshRendererPixelRatio()

    // Resolution Hack increases size of canvas then down-scales back to original size using CSS
    // Used for SoftwareRenderer which does not support renderer.setPixelRatio()
    // Should be paired with:
    // #DesignerRootDiv canvas {
    //   transform: scale(0.25);
    //   transform-origin: top left;
    // }
    var resolutionMagnification = Designer && Designer.resolutionMagnification ? Designer.resolutionMagnification : 1

    // Workaround SoftwareRenderer issue by using dummy height/width if viewport not yet created
    var w = container.dom.offsetWidth ? container.dom.offsetWidth : 100
    var h = container.dom.offsetHeight ? container.dom.offsetHeight : 100

    renderer.setSize(w * resolutionMagnification, h * resolutionMagnification)

    if (renderer.domElement.isMock) {
      window.studioDebug && console.log('Warning: renderer.domElement is not set, only acceptable during testing.')
    } else {
      container.dom.appendChild(renderer.domElement)
    }

    render()
  })

  signals.systemSelected.add(() => {
    // forceClear because render does not clear old system objects if no objects are visible
    // for the new system (i.e. if there is no roof model)
    render(true)
  })

  signals.sceneGraphChanged.add(() => {
    // setTimeout(function(){
    // render()
    // },1);

    renderIfNotAnimating('sceneGraphChanged')
  })

  signals.cameraChanged.add(() => {
    // Disable here because this now runs inside viewport.render() because we could not get it to
    // update fast enough in here. Perhaps cameraChange is not called for every update in the animation?
    // if (editor.viewWidget) {
    //   editor.viewWidget.refreshForCamera(editor.camera.position, editor.metersPerPixel())
    // }

    editor.filter('type', 'OsNode').forEach(function (n) {
      n.refreshForCamera(editor.camera.position, editor.metersPerPixel())
    })

    //Adjust scale for all helpers at screen pixel size, not world size
    // Note: It is very important that the changeEvent triggered by setSize is debouced because any change
    // triggers an extra render which can kill performance during animated zoom
    transformControls.setSize(5 * editor.metersPerPixel())

    if (editor.gridVisibility()) {
      editor.grid.position.copy(gridPosition())
    }

    // Beware: It is ok to call render in response to a camera update EXCEPT when the camera update is part of the
    // animation loop, which must not call render because then it is calling render twice per loop
    renderIfNotAnimating('cameraChanged')
  })

  function viewFinderCenterToScreenFraction() {
    var rect = editor.viewport.rect()
    return new THREE.Vector2((rect.width / 2 + editor.leftMarginPixels / 2) / rect.width, 0.5)
  }
  this.viewFinderCenterToScreenFraction = viewFinderCenterToScreenFraction

  function worldPositionAtViewFinderCenter() {
    return getIntersectionWithGround(viewFinderCenterToScreenFraction())
  }
  this.worldPositionAtViewFinderCenter = worldPositionAtViewFinderCenter

  function worldPositionAtViewportCenter() {
    return getIntersectionWithGround(new THREE.Vector2(0.5, 0.5))
  }
  this.worldPositionAtViewportCenter = worldPositionAtViewportCenter

  function lonLatAtAtViewportCenterFrom3DCameraAndSceneOrigin(sceneOrigin4326) {
    var pointOnGround = worldPositionAtViewportCenter()

    return pointOnGround ? lonLatFor3DPositionUsingSceneOrigin(pointOnGround, sceneOrigin4326) : null
  }
  this.lonLatAtAtViewportCenterFrom3DCameraAndSceneOrigin = lonLatAtAtViewportCenterFrom3DCameraAndSceneOrigin

  function lonLatFor3DPositionUsingSceneOrigin(position, sceneOrigin4326) {
    position = position.clone()

    // use sceneOrigin to calculate latlon of this 3D point

    //@todo: Hack! Why is this fudge needed? Corresponds to same factor applied in getZoomForResolution()
    var lat = sceneOrigin4326.y
    var scaleForLat = Math.cos((lat * Math.PI) / 180)
    position.x /= scaleForLat
    position.y /= scaleForLat

    var sceneOrigin3857 = ol.proj.transform(sceneOrigin4326.toArray(), 'EPSG:4326', 'EPSG:3857')
    var pointOnGround3857 = [sceneOrigin3857[0] + position.x, sceneOrigin3857[1] + position.y]

    var pointOnGround4326 = ol.proj.transform(pointOnGround3857, 'EPSG:3857', 'EPSG:4326')

    return new THREE.Vector2(pointOnGround4326[0], pointOnGround4326[1])
  }
  this.lonLatFor3DPositionUsingSceneOrigin = lonLatFor3DPositionUsingSceneOrigin

  var refreshSelectionBox = function (object, forceRender) {
    if (typeof object === 'undefined') {
      object = editor.selected
    }

    selectionBox.visible = false
    transformControls.detach()

    if (object !== null && object !== scene && object !== camera && object.type !== 'OsSystem') {
      if (object.getBoxHelperVisibility && object.getBoxHelperVisibility()) {
        box.setFromObject(object, selectionBox._filterFunction)
        if (box.isEmpty() === false) {
          selectionBox.setFromObject(object, selectionBox._filterFunction)
          selectionBox.visible = true
        }
      }

      if (object.transformable !== false) {
        transformControls.attach(object)
      }
    }

    if (forceRender === true) {
      render()
    }
  }
  this.refreshSelectionBox = refreshSelectionBox

  signals.geometryChanged.add((object) => {
    if (object !== undefined) {
      selectionBox.setFromObject(object, selectionBox._filterFunction)
    }

    render()
  })

  signals.objectAdded.add((object) => {
    object.traverse(function (child) {
      if (objects.indexOf(child) === -1) {
        objects.push(child)
        // }else{
        // 	console.log("Warning: Attempting to add child which is already a child");
      }
    })

    if (object.type === 'OsFacet') {
      if (
        editor.controllers.AddObject &&
        editor.controllers.AddObject.active &&
        editor.controllers.AddObject.objectType === 'OsEdge'
      ) {
        editor.select(object)
      }
    } else if (object.type !== 'OsFacetMesh' && object.getFacets && object.getFacets()) {
      object.getFacets().forEach(function (facet) {
        facet.onChange(editor)
      })
    }

    if (object.onAdd) {
      object.onAdd(editor)
    }

    render()
  })

  signals.objectChanged.add((object, prop, opts) => {
    if (editor.selected === object) {
      selectionBox.setFromObject(object, selectionBox._filterFunction)
      transformControls.update()
    }

    if (object instanceof THREE.PerspectiveCamera) {
      object.updateProjectionMatrix()
    }

    if (object && editor.helpers[object.id] !== undefined) {
      editor.helpers[object.id].update()
    }

    var dispatchSignalOnResume = false

    editor.uiPauseUntilComplete(
      function () {
        //DISABLING: This should happen through OsFacet.onChange instead!
        // //Used particularly for updating FacetMesh and floating nodes when a node is updated
        // if (object.type != 'OsFacetMesh' && object.getFacets && object.getFacets()) {
        //   object.getFacets().forEach(function (facet) {
        //     facet.refreshMesh(editor)
        //   })
        // }

        // Nest pausing of render updates too
        editor.uiPause('render', 'signals.objectChanged')

        if (object.onChange) {
          // TODO: remove this hack!
          // This has been added to preserve the existing behaviour
          // but there's probably no good reason to base
          // skipRemoveFloatingObject on the presence of prop
          const skipRemoveFloatingObject = !!opts?.skipRemoveFloatingObject || !!prop
          object.onChange(editor, skipRemoveFloatingObject, false, {
            skipAutoOrientation: opts?.skipAutoOrientation,
          })
        }

        editor.uiResume('render', 'signals.objectChanged')
      },
      this,
      'ui',
      'viewport::objectChanged.add()',
      dispatchSignalOnResume
    )

    if (!opts?.skipRender) render()
  })

  signals.objectRemoved.add((object, stopOnRemove) => {
    editor.uiPause('ui', 'signals.objectRemoved')

    object.traverse(function (child) {
      var index = objects.indexOf(child)

      if (index === -1) {
        //It's ok to try and remove an object which has already been removed
        //We cannot skip objects already removed because they may have children
        //that are still present in the scene.
        //console.log("Danger!!!!! child not found in objects... this will remove the wrong item!!!", child);
        //do nothing, child already removed
      } else {
        objects.splice(index, 1)
      }
    })

    if (object.dispose) {
      object.dispose()
    }

    //Used particularly for updating FacetMesh and floating nodes when a node is updated
    if (object.type !== 'OsFacetMesh' && object.getFacets && object.getFacets()) {
      object.getFacets().forEach(function (facet) {
        facet.refreshMesh(editor)
      })
    }

    if (object.onRemove && !stopOnRemove) {
      object.onRemove(editor)
    }

    editor.uiResume('ui', 'signals.objectRemoved')

    // Workaround scene not clearing by manually clearing when no geometry exists in scene
    // @todo: Try to fix this in ThreeJS rather than custom workaround
    // Performance hit not too bad because this only happens on object removal, not every frame
    //console.log('Warning: manual workaround needed to call viewport.render(forceClear==true)')
    render(true)

    //Original
    //render()
  })

  signals.helperAdded.add((object) => {
    var picker = object.getObjectByName('picker')
    if (picker) {
      objects.push(picker)
      // }else{
      // Danger, do not add a picker object to the scene if we could not find it
    }
  })

  signals.helperRemoved.add((object) => {
    var index = objects.indexOf(object.getObjectByName('picker'))
    if (index !== -1) {
      objects.splice(index, 1)
      // }else{
      // Danger, do not remove if object not found or we will corrupt the scene by removing some other arbitrary object!
    }
  })

  signals.materialChanged.add((material) => {
    render()
  })

  // fog

  signals.sceneBackgroundChanged.add((backgroundColor) => {
    scene.background.setHex(backgroundColor)

    render()
  })

  var currentFogType = null

  signals.sceneFogChanged.add((fogType, fogColor, fogNear, fogFar, fogDensity) => {
    if (currentFogType !== fogType) {
      switch (fogType) {
        case 'None':
          scene.fog = null
          break
        case 'Fog':
          scene.fog = new THREE.Fog()
          break
        case 'FogExp2':
          scene.fog = new THREE.FogExp2()
          break
        default:
          break
      }

      currentFogType = fogType
    }

    if (scene.fog instanceof THREE.Fog) {
      scene.fog.color.setHex(fogColor)
      scene.fog.near = fogNear
      scene.fog.far = fogFar
    } else if (scene.fog instanceof THREE.FogExp2) {
      scene.fog.color.setHex(fogColor)
      scene.fog.density = fogDensity
    }

    render()
  })

  //

  signals.windowResize.add(() => {
    if (scope.rectCached) {
      scope.rectCached = null
    }

    var resolutionMagnification = Designer && Designer.resolutionMagnification ? Designer.resolutionMagnification : 1

    renderer.setSize(
      container.dom.offsetWidth * resolutionMagnification,
      container.dom.offsetHeight * resolutionMagnification
    )

    refreshRendererPixelRatio()

    // MapHelper needs to be told to match the #viewport in html
    // This allows Studio viewport to match #viewport regardless of the DOM hierarchy
    MapHelper.refreshMapContainers()

    //For some reason for Google Obliques (but not top-down) this was jumping to a strange location
    //around 1000px below the correct location during window resizing, which fixed when panning the camera manually
    // editor.refreshCamera();

    //Beware: if no refreshCamera() call then resizing the browser will put aspect ratio out of wack!
    //This workaround doesn't fix the underlying issue (it still flashes to wrong location)
    //but it will fix after a short delay and flash to wrong location.
    editor.refreshCamera()
    signals.cameraChanged.dispatch()

    //render();
  })

  signals.showGridChanged.add((showGrid) => {
    if (!grid && showGrid === true) {
      drawGrid()
    }
    if (grid) {
      grid.visible = showGrid
      render()
    }
  })

  signals.viewLockedChanged.add((viewLocked) => {
    editor.controllers.Camera.enabled = !viewLocked
  })

  signals.objectSelected.add(
    function (object) {
      refreshSelectionBox(object)

      //Need to call render with forceClear=true to ensure selection is cleared/updated
      //in sceneHelpers

      // Hack: Deferring by 1 frame to ensure visibility updates are always applied on tablet
      // Without this desktop is updated ok but tablets need an extra render to clear unselected content
      // Why the difference? Sometimes safari-desktop requires a delay too, but only rarely. Most renders after object de-selection work fine.

      // setTimeout(function() {
      render(true)
      // }, 1)
    },
    this,
    -1
  )

  //

  var now, delta

  /*
  Do not aim for 60fps because that eventually thrashes the CPU and leads to  degraded long-term performance (and
  noisy fans) and a slower time to render each frame. Aim for 20fps which is still pretty nice but avoids hitting CPU
  resources so hard and will give better overall performance.
  */
  var fps = 30

  var then = Date.now()
  var interval = 1000 / fps

  var animationRequests = {
    tween: new Set(),
    camera: new Set(),
    turnTable: new Set(),
    measurement: new Set(),
  }

  this.animationRequests = animationRequests

  function isAnimating() {
    return Object.values(animationRequests).some((set) => set.size > 0)
  }
  this.isAnimating = isAnimating

  function animate() {
    if (!isAnimating()) {
      return
    }

    if (!editor.controllers.Camera) {
      // Prevent possibility of blocking the animation loop from restarting
      animationStopAll()
      return
    }

    //We determine whether to render BEFORE calling update because it may signal a finish event
    //which would prevent rendering this frame
    //REMOVED: We no longer update here... instead we respond to camera updates directly
    //Any FPS limiting will need to occur as part of the Camera animation
    //We now only render as part of animate() when on turntable
    //var doRender = editor.controllers.Camera.animating

    var isAnimatedTurntable = editor.controllers.Camera.isAnimatedTurntable()

    var doRender = true

    var deviceOrientationController = editor.controllers.DeviceOrientation

    if (deviceOrientationController && deviceOrientationController.active === true) {
      deviceOrientationController.update()
      //Force render if deviceOrientationController are enabled
      doRender = true
    }

    //Disable cameraChanged events because this forces an immediate re-render.
    //Since we are animating, we will let our FPS determine whether to render or not
    if (isAnimatedTurntable) {
      editor.signals.cameraChanged.active = false
    }

    if (!deviceOrientationController || deviceOrientationController.active === false) {
      // Prevent possibility of blocking the animation loop from restarting

      try {
        editor.controllers.Camera.update()
      } catch (e) {
        console.warn(e)

        // We failed to update the camera but ensure the animation loop is not broken
        // Return early beacuse we should not bother to try and re-render since the camera update failed.
        animationStopAll()
        return
      }
    }

    //Re-enable in case we stop animating
    //@TODO: Optimisation: We could actually disable/re-enable cameraChanged signals when starting/stopping animating
    if (isAnimatedTurntable) {
      editor.signals.cameraChanged.active = true
    }

    now = Date.now()
    delta = now - then

    if (delta > interval) {
      if (doRender) {
        //console.log('delta at render time: '+delta)
        // console.log('render in animate')
        render()
        // }else{
        // 	console.log('norender, update only')
      }

      then = now - (delta % interval)

      // }else{
      // 	console.log('Delay next frame, delta:'+delta)
    }

    requestAnimationFrame(animate)
  }

  function hasRenderableObject() {
    /*
      SceneHelpers only seem to update when certain kinds of objects are present in the scene
      If none of these are present we will forceClear when we render

      Hopefully for larger scenes this will exit before iterating over too many objects
      */
    for (var i = 0, l = objects.length; i < l; i++) {
      if (objects[i].type === 'OsNode' || objects[i].type === 'OsModule') {
        return true
      }
    }
    return false
  }

  // var renderCount = 0
  this._renderActive = true
  this.renderSkipped = false
  this.renderLocks = []

  const renderActive = (value) => {
    if (window.hasOwnProperty('FORCE_RENDER_ACTIVE')) {
      return window.FORCE_RENDER_ACTIVE
    }
    if (typeof value === 'undefined') {
      return this._renderActive
    } else {
      if (value !== this._renderActive) {
        this._renderActive = value

        // Ensure we re-enable _renderActive before calling the final render() or it will get blocked too :-p
        if (value === true && this.renderSkipped === true) {
          this.renderSkipped = false
          window.studioDebug && console.log('Render after resuming from renderActive false')

          this.render()
        }
      }
    }
  }
  this.renderActive = renderActive

  function getRenderer() {
    return renderer
  }
  this.getRenderer = getRenderer

  var buildGridParams = (centerWorldX, centerWorldY, w, h, resolution) => {
    if ((!centerWorldX && centerWorldX !== 0) || (!centerWorldY && centerWorldY !== 0)) {
      var sceneCentroid = SceneHelper.getSceneCentroid()
      centerWorldX = sceneCentroid.x
      centerWorldY = sceneCentroid.y
    }

    if (!w || !h || !resolution) {
      var depthMapSettings = this.getDepthMapSettings()
      w = w || depthMapSettings.sizeMeters
      h = h || depthMapSettings.sizeMeters
      resolution = resolution || depthMapSettings.sizePixels
    }

    return {
      min: new THREE.Vector2(centerWorldX + -w / 2, centerWorldY + -h / 2),
      max: new THREE.Vector2(centerWorldX + w / 2, centerWorldY + h / 2),
      center: new THREE.Vector2(centerWorldX, centerWorldY),
      size: new THREE.Vector2(w, h),
      metersPerCell: new THREE.Vector2(w / resolution, h / resolution),
      resolution: resolution,
    }
  }
  this.buildGridParams = buildGridParams

  function hideShowNonDepthObjectsAndSystemUuid(func, renderSystemUuid) {
    // Even if we are already viewing the correct system we may still need
    // to show/hide other objects during DSM creation
    var systemObjectsToHide = []
    var systemObjectsToShow = []

    var systemObjectTypes = ['OsSystem', 'OsModuleGrid', 'OsModule']

    systemObjectsToHide = editor.filterObjects(function (object) {
      // Beware: for some strange reason we can have modules with no system... perhaps ghost modules?
      // Ensure they don't crash this function.
      var system = object.getSystem ? object.getSystem() : null
      return (
        object.visible === true &&
        systemObjectTypes.indexOf(object.type) !== -1 &&
        system &&
        // Hide if object belongs to the selected system (which is the only way it would be visible)
        // but only hide if it does NOT belong to the system we are currently rendering
        system.uuid === editor.selectedSystem?.uuid &&
        system.uuid !== renderSystemUuid
      )
    })
    systemObjectsToShow = editor.filterObjects(function (object) {
      var system = object.getSystem ? object.getSystem() : null
      return (
        object.visible === false &&
        // Object belongs to this system
        ((systemObjectTypes.indexOf(object.type) !== -1 && system && system.uuid === renderSystemUuid) ||
          // OR object is terrain which affects all systems and may not be visible if not viewing a 3D view
          // when calcs are triggered
          object.type === 'OsTerrain')
      )
    })

    const treeObjectsToUseForShading = editor.filter('type', 'OsTree')

    // Toggle visibility by system
    systemObjectsToHide.forEach((object) => (object.visible = false))
    systemObjectsToShow.forEach((object) => (object.visible = true))

    // hide objects which should not affect depth map (helpers, etc)
    var occlusionObjects = editor.filterObjects(SceneHelper.objectOccludesTheSun)
    var occlusionObjectsHidden = []

    scene.traverse(function (child) {
      if (occlusionObjects.indexOf(child) === -1 && child.visible !== false) {
        child.visible = false
        occlusionObjectsHidden.push(child)
      }
    })

    // Make FacetMesh opaque.
    // Otherwise if we are not using roof textures it will not appear in the depth map
    // @todo: Do we really need to set on each individually, or are these sometimes the same material?
    var originalFacetMeshMaterialVisiblity
    var firstFacetMesh = editor.filter('type', 'OsFacetMesh')[0]
    if (firstFacetMesh) {
      originalFacetMeshMaterialVisiblity = firstFacetMesh.material.visible
      if (originalFacetMeshMaterialVisiblity === false) {
        editor.filter('type', 'OsFacetMesh').forEach((fm) => {
          fm.material.visible = true
        })
      }
    }

    // prevent light shining through tree texture alpha channels to improve shading accuracy
    treeObjectsToUseForShading.forEach((osTree) => osTree.transparentLeaves(false))

    func()

    // show again
    occlusionObjectsHidden.forEach((object) => (object.visible = true))

    // Undo toggle of visibility by system
    systemObjectsToHide.forEach((object) => (object.visible = true))
    systemObjectsToShow.forEach((object) => (object.visible = false))

    // reset facet mesh material visibility
    if (originalFacetMeshMaterialVisiblity === false) {
      editor.filter('type', 'OsFacetMesh').forEach((fm) => {
        fm.material.visible = false
      })
    }

    // allow light to shine through tree texture alpha channels again
    treeObjectsToUseForShading.forEach((osTree) => osTree.transparentLeaves(true))
  }

  function renderIfNotAnimating(identifier) {
    if (isAnimating()) {
      // console.log('renderIfNotAnimating ignore render', identifier)
    } else {
      render()
    }
  }
  this.renderIfNotAnimating = renderIfNotAnimating

  const render = (forceClear, forceRenderEvenWhenNotActive, createBitmap, _x, _y, exportFormat, renderSystemUuid) => {
    if (editor.viewport.renderActive() === false && forceRenderEvenWhenNotActive !== true) {
      // console.log('render skipped')
      // Record that render was skipped so we can trigger a render when resume rendering
      if (!this.renderSkipped) {
        this.renderSkipped = true
      }
      return
    }
    // console.log('render', ++renderCount)

    if (!renderer) {
      if (window.TESTING !== true) {
        console.error('Warning: render() called with no renderer, aborting.')
      }
      return
    }

    //Need to clear CanvasRenderer manually
    //Using renderer.autoClear==true on CanvasRenderer makes the geometry invisible (in both Chrome and Firefox)
    //SVG is broken too but we don't use it.

    if (
      forceClear === true ||
      (window['THREE.CanvasRenderer'] &&
        window['THREE.SoftwareRenderer'] &&
        (renderer instanceof THREE.CanvasRenderer || renderer instanceof THREE.SoftwareRenderer))
    ) {
      renderer.clear()
    } else if (hasRenderableObject() === false) {
      renderer.clear()
    }

    // @TODO: Remove from here. Ideally this would be re-aligned/re-positioned immediately
    // after camera is updated but for some reason the cameraUpdated signal cannot keep up
    if (editor && editor.viewWidget) {
      editor.viewWidget.refreshForCamera(editor.camera.position, editor.metersPerPixel())
    }

    sceneHelpers.updateMatrixWorld()
    scene.updateMatrixWorld()

    // Create a multi render target with Float buffers
    if (createBitmap) {
      var depthMapSettings = this.getDepthMapSettings()

      // @TODO: RenderTarget height and width seem to be screen pixels... hmm... can we ensure these cover the scene
      // at suitable resolution?

      if (window.depthRenderTarget) {
        // If depthRenderTarget already exists, just dispose it, do not create again
        window.depthRenderTarget.dispose()
      } else {
        window.depthRenderTarget = new THREE.WebGLRenderTarget(depthMapSettings.sizePixels, depthMapSettings.sizePixels)
        window.depthRenderTarget.texture.format = THREE.RGBFormat
        window.depthRenderTarget.texture.minFilter = THREE.NearestFilter
        window.depthRenderTarget.texture.magFilter = THREE.NearestFilter
        window.depthRenderTarget.texture.generateMipmaps = false
        window.depthRenderTarget.stencilBuffer = false
        window.depthRenderTarget.depthBuffer = true
        window.depthRenderTarget.depthTexture = new THREE.DepthTexture()
        window.depthRenderTarget.depthTexture.type = THREE.UnsignedShortType

        window.postTarget = new THREE.WebGLRenderTarget(depthMapSettings.sizePixels, depthMapSettings.sizePixels)

        // Setup post processing stage

        window.postCamera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1)
        window.postMaterial = new THREE.ShaderMaterial({
          vertexShader: `
varying vec2 vUv;
void main() {
  vUv = uv;
  gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
`,
          fragmentShader: `
#include <packing>
varying vec2 vUv;
uniform sampler2D tDiffuse;
uniform sampler2D tDepth;
uniform float cameraNear;
uniform float cameraFar;
float readDepth( sampler2D depthSampler, vec2 coord ) {
  float fragCoordZ = texture2D( depthSampler, coord ).x;
  float viewZ = orthographicDepthToViewZ( fragCoordZ, cameraNear, cameraFar );
  return viewZToOrthographicDepth( viewZ, cameraNear, cameraFar );

}
void main() {
  float depth = readDepth( tDepth, vUv );
  gl_FragColor.rgb = 1.0 - (vec3( depth ));
  gl_FragColor.a = 1.0;
}
`,
          uniforms: {
            cameraNear: { value: window.postCamera.near },
            cameraFar: { value: window.postCamera.far },
            tDiffuse: { value: window.depthRenderTarget.texture },
            tDepth: { value: window.depthRenderTarget.depthTexture },
          },
        })

        window.postPlane = new THREE.PlaneBufferGeometry(2, 2)
        window.postQuad = new THREE.Mesh(window.postPlane, window.postMaterial)
        window.postScene = new THREE.Scene()
        window.postScene.add(window.postQuad)

        window.screenPlane = new THREE.PlaneBufferGeometry(20, 20)
        window.screenMaterial = new THREE.MeshPhongMaterial({
          map: window.postTarget.texture,
        })

        window.screenQuad = new THREE.Mesh(window.screenPlane, window.screenMaterial)
        window.screenQuad.userData.excludeFromExport = true
        window.screenQuad.position.x = -80
        window.screenQuad.position.z = 0.0
        window.editor.addObject(window.screenQuad)
        window.screenQuad.visible = WorkspaceHelper.developerMode()
      }

      // Sense depth from a fixed top-down virtual camera to create a DSM
      var terrainBoundingBox = SceneHelper.getSceneBoundingBoxWithCache()

      // Ensure we never render anything more than 1000m above/below ground level.
      // Hide outlier objects so they do not break shading simulations. If the range between the nearest and furthers objects
      // is too great then shading calcs are broken. I suspect this is because the depths need to get "quantized" as a
      // fraction of the total depth, so small differences can get lost when the total range is very large.
      // e.g. If a tree is 0.5m above the roof surface, but the most distant object is 10,000m away then the elevation
      // difference between tree and roof is only a small fraction of the total range (1/20,000) and that can introduce
      // rounding/quantization errors.
      var maxElevation = Math.min(terrainBoundingBox.max.z, 500)
      var minElevation = Math.max(terrainBoundingBox.min.z, -100)

      var cameraNear = 0
      var cameraFar = maxElevation - minElevation

      var topDownElevationScannerCamera = new THREE.OrthographicCamera(
        -depthMapSettings.sizeMeters / 2,
        depthMapSettings.sizeMeters / 2,
        depthMapSettings.sizeMeters / 2,
        -depthMapSettings.sizeMeters / 2,
        cameraNear,
        cameraFar
      )

      // Centroid world X,Y coordinates for panel groups
      var sceneCentroid = SceneHelper.getSceneCentroid()

      topDownElevationScannerCamera.position.fromArray([sceneCentroid.x, sceneCentroid.y, maxElevation])
      topDownElevationScannerCamera.updateProjectionMatrix()

      renderer.setRenderTarget(window.depthRenderTarget)

      // Gotcha!!!
      // This is not required on most browsers (e.g. Mac-Chrome)
      // But it IS required on Mac-Safari and iOS
      // No idea why!!
      // If you don't call this the depth for the whole map will be equal
      // to 255, the maximum value.
      renderer.clear()

      // Depth Render call is wrapped in a function that will hide non depth-impacting objects
      // then re-show them when complete
      hideShowNonDepthObjectsAndSystemUuid(function () {
        renderer.render(scene, topDownElevationScannerCamera)
      }, renderSystemUuid)

      // render post FX
      renderer.setRenderTarget(window.postTarget)
      renderer.render(window.postScene, window.postCamera)

      // Extract depth data from postTarget
      // Depth values range between 0 and 255 as a fraction of the full depth range above
      // elevation = elevationAtOrigin + maxElevation * (depth / 255.0)

      // @TODO: Why can't we simply use buffer directly, why do we need to convert to Array?

      var buffer = new Uint8Array(depthMapSettings.sizePixels * depthMapSettings.sizePixels * 4)
      editor.viewport
        .getRenderer()
        .readRenderTargetPixels(
          window.postTarget,
          0,
          0,
          depthMapSettings.sizePixels,
          depthMapSettings.sizePixels,
          buffer
        )
      // console.log(buffer)

      // extract just a single color channel (they are all the same), instead of RGBA
      // save into arrays where depthCells[row][col] = elevation
      var elevationMultiplier = cameraFar / 255.0
      var elevationGrid = Array(depthMapSettings.sizePixels)
      for (var row = 0; row < depthMapSettings.sizePixels; row++) {
        // populate from bottom to top so the final grid origin is top left
        // i.e. top left = elevationGrid[0][0]
        // i.e. bottom right = elevationGrid[99][99]
        elevationGrid[row] = Array(depthMapSettings.sizePixels)
        for (var col = 0; col < depthMapSettings.sizePixels; col++) {
          elevationGrid[row][col] =
            buffer[(depthMapSettings.sizePixels - 1 - row) * depthMapSettings.sizePixels * 4 + col * 4] *
              elevationMultiplier +
            minElevation
        }
      }
      // console.log(elevationGrid)

      window.screenQuad.visible = WorkspaceHelper.developerMode()

      // Optionally generate an image to visualise/inspect depth values
      if (exportFormat === 'bitmap') {
        var pixels = new Array(depthMapSettings.sizePixels * depthMapSettings.sizePixels * 4)
        for (var i = 0; i < depthMapSettings.sizePixels * depthMapSettings.sizePixels * 4; i += 1) {
          pixels[i] = buffer[i]
        }
        // console.log(pixels)

        // 16x16 pixels - 1D Array of pixels (start from bottom-left corner)
        // where one pixel has 4 color component (each 0-255) RGBA
        var uri = genBMPUri(depthMapSettings.sizePixels, pixels)
        console.log(uri)
        Utils.saveAs(uri, 'depth.bmp')
      } else if (exportFormat === 'ascii') {
        var asciiTextContent = Utils.exportGridToAsc(elevationGrid)
        Utils.saveAs('data:text/plain;base64,' + btoa(asciiTextContent), 'depth.asc')
      }

      // render final scene including quad with depth texture
      renderer.setRenderTarget(null)
      renderer.render(scene, camera)

      return elevationGrid
    } else {
      renderer.render(scene, camera)

      if (Designer.rendererName && Designer.rendererName === 'SoftwareRenderer') {
        window.studioDebug && console.warn('Warn: calling render clears previous draw with softwareRenderer')
      } else {
        renderer.render(sceneHelpers, camera)
      }
    }
  }
  this.render = render

  this.useMockRenderer = function (devicePixelRatio) {
    //@TODO: Create tests for different devicePixelRatio
    if (typeof devicePixelRatio === 'undefined') {
      devicePixelRatio = 1
    }
    window.devicePixelRatio = devicePixelRatio

    editor.getWindowDimensions = function () {
      return [1000, 1000]
    }

    signals.rendererChanged.dispatch({
      autoClear: false,
      autoUpdateScene: false,
      setPixelRatio: function () {},
      setSize: function () {},
      domElement: {
        isMock: true,
        width: 1000,
        height: 1000,
      },
      render: function () {},
      renderLists: {
        dispose: function () {},
      },
      clear: function () {},
      getContext: function () {
        console.warn('WARNING: renderer.getContext() is dodgy because camera is not set to same dimensions')
        return {
          canvas: {
            //width: editor.camera.right - editor.camera.left,
            //height: editor.camera.top - editor.camera.bottom,
            width: 1000,
            height: 1000,
          },
        }
      },
    })

    container.dom.getBoundingClientRect = function () {
      return {
        bottom: 1000,
        height: 1000,
        left: 0,
        right: 1000,
        top: 0,
        width: 1000,
      }
    }

    //Now that we have a viewport and a fake container, ensure we rebuild the camera
    editor.refreshCamera()
  }

  function animationStartByType(type, key) {
    if (!animationRequests[type]) {
      animationRequests[type] = new Set()
    }
    if (animationRequests[type].has(key)) {
      //console.log("Warning: animation already running, ignored")
    } else {
      requestAnimationFrame(animate)
      animationRequests[type].add(key)
    }
  }

  function animationStopByType(type, key) {
    if (animationRequests[type]) {
      animationRequests[type].delete(key)
    }
  }

  function animationStopAll() {
    Object.keys(animationRequests).forEach((type) => {
      animationRequests[type] = new Set()
    })
  }

  function isAnimationPlaying(type) {
    return animationRequests[type] && animationRequests[type].size > 0
  }

  //Only trigger after a project is loaded
  //animationStart()

  signals.animationStart.add((type, key) => {
    animationStartByType(type, key)

    if (type === 'camera') {
      editor.uiPause('annotation', 'camera')
    }
  })

  signals.animationStop.add((type, key) => {
    SceneHelper.arrangeLights(editor) //@TODO: Is this really necessary
    if (type) {
      animationStopByType(type, key)
    } else {
      animationStopAll()
    }

    if (!isAnimationPlaying('camera')) {
      editor.uiResume('annotation', 'camera')
    }
  })

  var distanceFromTerrainCenter = (asFraction) => {
    var position = this.worldPositionAtViewFinderCenter()
    if (!position || !editor.scene.terrainPosition) {
      return null
    }
    position.sub(editor.scene.terrainPosition || new window.THREE.Vector3())
    position.z = 0
    var distance = position.length()
    if (asFraction) {
      return distance / (AccountHelper.terrainSize / 2)
    } else {
      return distance
    }
  }
  this.distanceFromTerrainCenter = distanceFromTerrainCenter

  return this
}
