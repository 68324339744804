const BatterySideEffects = (editorInstance) => {
  const handleHybridInverterDCConnection = (system) => {
    ComponentUtils.handleHybridBatteryConnectionState(editorInstance, system)
  }

  const handlecheckHybridClearWarning = (system) => {
    ComponentUtils.handlecheckHybridClearWarning(system)
  }

  const effects = new Map([
    [
      'hybridInverterDCConnection',
      {
        triggers: ['add', 'change'],
        handle: handleHybridInverterDCConnection,
      },
    ],
    [
      'checkClearWarning',
      {
        triggers: ['remove'],
        handle: handlecheckHybridClearWarning,
      },
    ],
  ])
  const batterySideEffects = ComponentSideEffects.create(editorInstance, 'Battery', {
    effects,
    isValidComponentType: (object) => object instanceof OsBattery,
    signals: [],
  })

  batterySideEffects.signals = [
    ['objectAdded', batterySideEffects.handleObjectAdded.bind(batterySideEffects)],
    ['objectRemoved', batterySideEffects.handleObjectRemoved.bind(batterySideEffects)],
    ['objectChanged', batterySideEffects.handleObjectChanged.bind(batterySideEffects)],
    ['sceneLoaded', batterySideEffects.handleSceneLoaded.bind(batterySideEffects)],
  ]

  return batterySideEffects
}
