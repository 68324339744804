/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newMaterial THREE.Material
 * @constructor
 */

var SetMaterialCommand = function(object, newMaterial) {
  Command.call(this)

  this.type = 'SetMaterialCommand'
  this.name = 'New Material'
  this.timeStamp = Date.now()
  this.object = object
  this.oldMaterial = object !== undefined ? object.material : undefined
  this.newMaterial = newMaterial
}

SetMaterialCommand.prototype = {
  execute: function() {
    this.object.material = this.newMaterial
    this.editor.signals.materialChanged.dispatch(this.newMaterial)
  },

  undo: function() {
    this.object.material = this.oldMaterial
    this.editor.signals.materialChanged.dispatch(this.oldMaterial)
  },

  toJSON: function() {
     if (this.loadFromJSON) {
      var output = Object.assign({}, this)
      delete output.editor
      return output
    }
    var output = Command.prototype.toJSON.call(this)

    output.objectUuid = this.object.uuid
    output.oldMaterial = this.oldMaterial.toJSON()
    output.newMaterial = this.newMaterial.toJSON()

    return output
  },

  fromJSON: function(json) {
    Command.prototype.fromJSON.call(this, json)

    this.object = this.editor.objectByUuid(json.objectUuid)
    this.oldMaterial = parseMaterial(json.oldMaterial)
    this.newMaterial = parseMaterial(json.newMaterial)

    function parseMaterial(json) {
      var loader = new THREE.ObjectLoader()
      var images = loader.parseImages(json.images)
      var textures = loader.parseTextures(json.textures, images)
      var materials = loader.parseMaterials([json], textures)
      return materials[json.uuid]
    }
  },
}
