/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var DuplicateViewCommand = function (viewUUID, ViewHelper) {
  Command.call(this)

  this.type = 'DuplicateViewCommand'
  this.name = 'Duplicate View Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['newViewData', 'oldViewData']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.viewUUID = viewUUID
  }
}

DuplicateViewCommand.prototype = {
  execute: function () {
    var selectedView = undefined
    this.ViewHelper.views.forEach(
      function (view) {
        if (this.viewUUID === view.uuid) selectedView = view
      }.bind(this)
    )

    if (selectedView) {
      // If already run we should use the uuid that was created the first time, but if we are running for the first time
      // then we will let the new view auto-generate a random uuid
      var newViewUUID = this.newViewData?.uuid || null

      // redo should reuse the same uuid generated by first time
      this.oldViewData = selectedView

      newViewUUID = this.ViewHelper.duplicateView(selectedView, newViewUUID)
      this.ViewHelper.loadViewByUuid(newViewUUID, this.editor)
      this.ViewHelper.selectViewByUUID(newViewUUID)

      this.newViewData = this.ViewHelper.getViewByUuid(newViewUUID)
    }
  },

  undo: function () {
    var selectedView = this.ViewHelper.getViewByUuid(this.newViewData.uuid)
    this.ViewHelper.deleteView(selectedView, this.editor)
    this.ViewHelper.loadViewByUuid(this.oldViewData.uuid, this.editor)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
