var ObjectBehaviors = {
  applyUserData: function (fields, userData) {
    fields.forEach(function (field) {
      if (userData.hasOwnProperty(field)) {
        //@TODO: Also check that this.hasOwnProperty(field) to avoid polluting unmatched variables??
        if (!this.hasOwnProperty(field)) {
          window.studioDebug &&
            console.log(
              'Warning: applyUserData() is populating this.' + field + ' which is not yet defined. Pollution?'
            )
        }
        this[field] = userData[field]
      }
    }, this)
  },

  refreshUserData: function (fields) {
    fields.forEach(function (field) {
      if (this.hasOwnProperty(field)) {
        this.userData[field] = this[field]
      }
    }, this)
  },

  belongsToGroup: function (group) {
    return group && group.type === 'OsGroup' && group.objects.some((object) => object.uuid === this.uuid)
  },

  floatingOnFacetOnChange: function (editor, skipRemoveFloatingObject) {
    //Search for a facet to snap to

    //Vertical ray shooting down onto objects xy position
    var raycaster = ObjectBehaviors.getRaycasterForFloat.call(this)

    if (this.facet) {
      if (!this.facet.mesh) {
        window.studioDebug && console.log('Facet requires mesh to be present. If not preset, create it')
        this.facet.refreshMesh(editor)
      }

      if (raycaster.intersectObject(this.facet).length > 0) {
        //If existing facet found, no need to check further, just leave it.
        this.facet.refloatObjects(editor) //First refloat
        return true
      } else {
        //No above the assigned facet. Remove it and search for an alternative below
        if (!skipRemoveFloatingObject) {
          this.facet.removeFloatingObject(this)
        }
      }
    }

    //Search for a new facet
    var facetUnderneath = ObjectBehaviors.findFacetUnderneath.call(this, raycaster)

    if (facetUnderneath) {
      this.facet = facetUnderneath
      this.facet.addFloatingObject(this)
      this.facet.refloatObjects(editor)
      return true
    } else if (skipRemoveFloatingObject && this.facet) {
      // no facet found underneath, but we can stay on an existing facet if already floating
      // simply return the current floating status
      return true
    } else {
      return false
    }
  },

  findFacetUnderneath: function (raycaster) {
    if (!raycaster) {
      raycaster = ObjectBehaviors.getRaycasterForFloat.call(this)
    }

    // Ignore any facets which are associated with the object itself (e.g. OsStructure.facets)
    var facetMeshes = editor
      .filter('type', 'OsFacetMesh')
      .filter((fm) => (this.type === 'OsStructure' ? this.facets.indexOf(fm.facet) === -1 : true))

    var facetMeshIntersections = raycaster.intersectObjects(facetMeshes)
    if (facetMeshIntersections.length && facetMeshIntersections[0].object) {
      return facetMeshIntersections[0].object.getFacets()[0]
    } else {
      // no facetMeshIntersections found
      return
    }
  },

  getRaycasterForFloat: function () {
    // Initially this.worldPositionForFindFacetUnderneath() is only supported by OsStructure but theoretically
    // any object could implement it.
    var worldPositionToSearchForFacet = this.worldPositionForFindFacetUnderneath
      ? this.worldPositionForFindFacetUnderneath().clone()
      : this.position.clone()

    //Vertical ray shooting down onto objects xy position
    var raycaster = new THREE.Raycaster(
      new THREE.Vector3(worldPositionToSearchForFacet.x, worldPositionToSearchForFacet.y, 1000),
      new THREE.Vector3(0, 0, -1)
    )
    return raycaster
  },

  handleMouseBehavior: function (isOver, force, intersection) {
    //Passing isOver=null and force=true re-applies the current setting
    if (isOver === null) {
      isOver = this._mouseOver
    }

    var changed = this._mouseOver != isOver || force

    if (changed) {
      if (this.handleMouse) {
        this.handleMouse(isOver, intersection)
      }

      this._mouseOver = Boolean(isOver)
    }

    return changed
  },

  handleGhostModeBehavior: function (value) {
    if (typeof value === 'undefined') {
      return this._ghostMode
    }

    if (value != this._ghostMode) {
      if (this.applyGhostMode) {
        this.applyGhostMode(value)
      }
      this._ghostMode = value
      return true
    } else {
      return false
    }
  },

  snap: function (editor, options = {}) {
    var returnAllSnapPoints = !!options?.returnAllSnapPoints

    var node = this
    var nodeEdges = node.getEdges()

    // Never use existing or intersection_rectified points when dragging.
    // This is handled by fusing points instead
    // Dragging only includes: midpoint_rectified, rectified, mid, perfection
    // UPDATE/TODO: we may be able to simplify this concept but omitting the edited edge(s)
    var mode = node.ghostMode() ? 'drawing' : 'dragging'

    // Ignore any points further away from node than this, otherwise they are just irrelevant
    // and will slow down
    var snapDistanceThreshold = 0.3

    var PERFECTION_LINE_LENGTH_MIN = 3

    //Find all edges in the scene and compare direction against this node's edges
    var allEdges = editor.filter('type', 'OsEdge')

    // Only include "contiguous" edges, i.e. edges that are connected to this node in some way?
    // This subset is important for cases where we don't want to be distracted by edges that are
    // not connected to the current structure.
    //
    // When dragging, ignore the node's own edges because they are being dragged and we don't want to snap to them
    //
    // We could probably avoid checking all these elements but at least this ensures we omit all
    // currently-edited nodes/edges
    var filterInteractiveEdges = (_edges) => {
      return _edges.filter(
        (e) => !e.ghostMode() && !e.nodes[0]?.ghostMode() && !e.nodes[1]?.ghostMode() && !e.nodes.includes(node)
      )
    }

    var allLinkedEdges = filterInteractiveEdges(
      !!options?.includeUnlinkedEdges ? allEdges : OsEdge.getAllLinkedEdges(node)
    )

    //@todo: When drawing first point, create temporary edges for all nearby points???
    //@todo: Instead of nearby points, perhaps search for points lying near the dominant planes?
    // if(nodeEdges.length==0){
    //
    //     var nearbyNodes = editor.filterObjects(function(o){ return o != node && o.position.distanceTo(node.position) < 5 })
    //     nodeEdges = nearbyNodes.map(function(nearbyNode){ return new OsEdge({nodes: [node, nearbyNode], linkNodes: false}) })
    //     allEdges = allEdges.concat(nodeEdges)
    //     console.log('Add synthetic edges, not added to editor/scene near position: ', node.position, nearbyNodes.length)
    // }

    // console.log('allEdges.length before cull', allEdges.length)
    //
    // //If many edges, cull edges shorter than 2m
    // if(allEdges.length > 10){
    //     allEdges = allEdges.filter(function(e){ return e.delta().length() > 2.0 })
    // }else if(allEdges.length > 5){
    //     allEdges = allEdges.filter(function(e){ return e.delta().length() > 1.0 })
    // }
    // console.log('allEdges.length after cull', allEdges.length)

    //Flag edges which are close to parallel

    //@todo: Cache normalized directions?

    var matchedEdges = []

    for (var i = 0; i < nodeEdges.length; i++) {
      matchedEdges.push({
        nodeEdgeIndex: i,
        allLinkedEdgesIndex: allLinkedEdges.indexOf(nodeEdges[i]),
        parallelishEdges: [],
        perpendicularishEdges: [],
        diagonalishEdges: [],
      })
    }

    // Allowed difference in dot product versus 0.7071067811865475 (i.e. 45 degrees)
    var DOT_PRODUCT_SIMILARITY_THRESHOLD = 0.1

    for (var i = 0; i < allLinkedEdges.length; i++) {
      var allEdgeDirection = allLinkedEdges[i].delta().normalize()

      for (var j = 0; j < nodeEdges.length; j++) {
        if (allLinkedEdges[i] == nodeEdges[j]) {
          //dont compare against self
          continue
        }

        var nodeEdgeDirection = nodeEdges[j].delta().normalize()

        var dot = allEdgeDirection.dot(nodeEdgeDirection)

        if (Math.abs(dot) > 1 - DOT_PRODUCT_SIMILARITY_THRESHOLD) {
          //Duplicates are allowed
          matchedEdges[j].parallelishEdges.push(i)
        } else if (Math.abs(dot) < DOT_PRODUCT_SIMILARITY_THRESHOLD) {
          //Duplicates are allowed
          matchedEdges[j].perpendicularishEdges.push(i)
        } else if (Math.abs(Math.abs(dot) - 0.707) < DOT_PRODUCT_SIMILARITY_THRESHOLD) {
          //Duplicates are allowed
          matchedEdges[j].diagonalishEdges.push(i)
        }
      }
    }

    // Previously we exited here if there were no matches edges, but consider the case where we are
    // placing a new point down and we want to snap to the midpoint of an existing edge, there will be
    // no matchedEdges but we still want to snap.
    // var numMatchedEdges = 0
    // matchedEdges.forEach(function (me) {
    //   numMatchedEdges += me.parallelishEdges.length
    //   numMatchedEdges += me.perpendicularishEdges.length
    //   numMatchedEdges += me.diagonalishEdges.length
    // })

    // if (numMatchedEdges == 0) {
    //   //console.log('Insufficient snap lines found');
    //   return false
    // }

    //There are many ways to adjust a point to become parallel or perpendicular.
    //For each node and edge, there is a locus of positions that would make it perfectly perpendicular or parallel.
    //After we have all the "perfection locusses" we can find the best intersection between them.
    //Two perfection locusses will resolve to a single point.
    //More than that requires finding the centroid of all intersections, then chosing a single point closest to the centroid.
    //
    //Simplification: For now, we will simply find the closest point on each perfection locus, then find the centroid of all perfection points.
    //Less precise but probably ok.

    //Find dominant directions and snap all synthetic lines to them if close
    var dominantDirections = Utils.getDominantDirections(
      allLinkedEdges.map(function (e) {
        return e.asLine3()
      })
    )

    var dominantDirectionsPerpendiculars = dominantDirections.map(function (dl) {
      return Utils.perpendicularLine(dl)
    })

    var dominantDirectionsDiagonals = []

    var nodeEdge = nodeEdges[0]
    var nextConnectedEdge = nodeEdge ? nodeEdge.adjacentEdges().find((e) => e !== nodeEdge) : null

    var dominantDirectionsForCurrentEdge = nextConnectedEdge
      ? Utils.getDominantDirections([nextConnectedEdge.asLine3()])
      : []

    // Add dominant directions for diagonals at +/-45 degrees to parallel edges

    if (dominantDirectionsForCurrentEdge.length) {
      dominantDirectionsForCurrentEdge.forEach((dominantDirection) => {
        // We only need to rotate each dominant direction once because we can rotate the other perpendicular
        // line in the same direction to get the other diagonal
        var directionRotated1 = dominantDirection
          .delta(new THREE.Vector3())
          .clone()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 4)
        var directionRotated2 = dominantDirection
          .delta(new THREE.Vector3())
          .clone()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), -Math.PI / 4)
        dominantDirectionsDiagonals.push(new THREE.Line3(new THREE.Vector3(), directionRotated1))
        dominantDirectionsDiagonals.push(new THREE.Line3(new THREE.Vector3(), directionRotated2))
      })
    }

    var snapPoints = {
      // For snapping onto an existing node.
      // This can snap to any node in the design, not limited to the current structure/facet we are editing.
      // Exclude ghost (which is being placed) and the node itself (if dragging existing node)
      existing: editor
        .filterObjects(function (o) {
          return o.type == 'OsNode' && o.ghostMode() != true && o != node
        })
        .map(function (n) {
          return n.position
        }),

      //require e.parent set which excludes midpoints from fake lines created for points during placement before adjacent edge is created
      // Note this checks ALL edges not just linked edges because we may want to connect to a non-connected edge.
      existing_mid: allEdges.map(function (e) {
        return e.parent && e.asLine3().getCenter(new THREE.Vector3())
      }),
      midpoint_rectified: [],
      intersection_rectified: [],
      intersection: [],
      rectified: [], //good but not as nice as perfection points
      mid: [],
      perfection: [],
    }

    var perfectionLines = []

    // Extend dragging/drawing line a little and if it intersects any edges create an intersection point there
    nodeEdges.forEach(function (draggingEdge) {
      //extend in both directions because I'm too lazy to figure out which way overshoots the dragged node
      var draggingLineExtended = Utils.extendLine3(draggingEdge.asLine3(), 2)

      //@todo: Currently extending the line does nothing... we are testing along the whole infinite line anyway

      //Optimization to speed up line intersection
      var draggingLineExtendedAsPlane = Utils.lineAsPlane(draggingLineExtended)

      //find all intersections with any edges, including non-connected edges
      allEdges.forEach(function (e) {
        if (e != draggingEdge) {
          var intersection = Utils.intersectLines(draggingLineExtendedAsPlane, e.asLine3())

          if (intersection) {
            if (intersection.manhattanDistanceTo(node.position) < snapDistanceThreshold) {
              // attach relevant objects into the intersection point
              // in future we should really nest the point inside a new object
              // but for now we will just attach an extra property right onto the Vector3
              intersection.objects = {
                edge: e,
              }

              snapPoints['intersection'].push(intersection)
            }
          }
        }
      })
    })

    // Generalised solution for adding either perpendicular or diagonal lines
    // - @TODO: Choose which facet? Up or down?
    // - rotation-to-apply (nothing or 45)
    // - snap lines e.g. dominantDirectionsDiagonals

    const snapPointsForPerpendicularOrDiagonal = (
      snapPoints,
      perfectionLines,
      nodeEdge,
      perpendicularishEdge,
      dominantDirectionsPerpendicularsOrDiagonals,
      lineType
    ) => {
      var facetPlane = node.facets[0] ? node.facets[0].plane : null

      //@todo: Use a flat plane passing through other point(s) of the facet
      //1 other point: use flat plane passing through other point
      //2 other points: use plane defined as line passing through other 2 points and ...
      if (!facetPlane) {
        //console.log('No plane found for perpendicularish edges, use ground plane')
        facetPlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0)
      }

      //"Perpendicular" needs to be further specified. It is really a Plane which needs to be converted to a single line
      //Synthesize a new line using orientation based on perpendicularish line in the current facet plane
      //but starting from other end of the current edge
      //Use start: distal point on original line, end: perfection point

      var distalPoint =
        nodeEdge.nodes[0].position.distanceTo(node.position) > nodeEdge.nodes[1].position.distanceTo(node.position)
          ? nodeEdge.nodes[0].position
          : nodeEdge.nodes[1].position
      //console.log('[perpendicular] distalPoint', distalPoint);
      // var proximalPoint = nodeEdge.nodes[0] !== distalPoint ? nodeEdge.nodes[0] : nodeEdge.nodes[1]

      //Perpendicular plane
      var planeNormal = perpendicularishEdge.delta()

      var planeOfSnapOrientation = new THREE.Plane().setFromNormalAndCoplanarPoint(planeNormal, distalPoint)

      //Draw line
      var perpendicularLine = planeOfSnapOrientation.intersectPlane(facetPlane, new THREE.Line3())

      if (perpendicularLine && dominantDirectionsPerpendicularsOrDiagonals.length) {
        //ignore if no perpendicular line found (e.g. when coplanar)
        var perpendicularLineSnapped = Utils.snapToDominantDirection(
          dominantDirectionsPerpendicularsOrDiagonals,
          perpendicularLine
        )

        var perpendicularLineSnappedDeltaScaled = perpendicularLineSnapped.delta(new THREE.Vector3())

        // Determine if we should `add` or `sub` to get the correct side of the line?
        var syntheticPerfectionLineEndPointCandidates = [
          distalPoint.clone().add(perpendicularLineSnappedDeltaScaled),
          distalPoint.clone().sub(perpendicularLineSnappedDeltaScaled),
        ]
        if (
          syntheticPerfectionLineEndPointCandidates[0].distanceTo(node.position) <
          syntheticPerfectionLineEndPointCandidates[1].distanceTo(node.position)
        ) {
          syntheticPerfectionLineEndPoint = syntheticPerfectionLineEndPointCandidates[0]
        } else {
          syntheticPerfectionLineEndPoint = syntheticPerfectionLineEndPointCandidates[1]
        }

        var syntheticPerfectionLine = new THREE.Line3(distalPoint, syntheticPerfectionLineEndPoint)

        // Also add the mirror image of the perfection point (in the other direction)
        var deltaforperpendicularLineSnapped = syntheticPerfectionLine
          .delta(new THREE.Vector3())
          .clone()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)

        var syntheticPerfectionLinePerpendicular = new THREE.Line3(
          distalPoint,
          distalPoint.clone().add(deltaforperpendicularLineSnapped)
        )

        //Find the point, useless alone
        var candidatesForRectified = [
          syntheticPerfectionLine.closestPointToPoint(node.position, false, new THREE.Vector3()),
          syntheticPerfectionLinePerpendicular.closestPointToPoint(node.position, false, new THREE.Vector3()),
        ]

        // Find closest point to current node and choose that as our rectified option
        if (
          candidatesForRectified[0].clone().sub(node.position).length() <
          candidatesForRectified[1].clone().sub(node.position).length()
        ) {
          snapPoints['perfection'].push(syntheticPerfectionLine.end)
          snapPoints['rectified'].push(candidatesForRectified[0])
          snapPoints['mid'].push(
            syntheticPerfectionLine.start
              .clone()
              .add(syntheticPerfectionLine.delta(new THREE.Vector3()).multiplyScalar(0.5))
          )

          var lineLength = Math.max(nodeEdge.delta().length(), PERFECTION_LINE_LENGTH_MIN)
          var syntheticPerfectionLineScaled = new THREE.Line3(
            syntheticPerfectionLine.start,
            new THREE.Vector3().addVectors(
              syntheticPerfectionLine.start,
              syntheticPerfectionLine.delta(new THREE.Vector3()).normalize().multiplyScalar(lineLength)
            )
          )
          syntheticPerfectionLineScaled.lineType = lineType
          perfectionLines.push(syntheticPerfectionLineScaled)
        } else {
          snapPoints['perfection'].push(syntheticPerfectionLinePerpendicular.end)
          snapPoints['rectified'].push(candidatesForRectified[1])
          snapPoints['mid'].push(
            syntheticPerfectionLinePerpendicular.start
              .clone()
              .add(syntheticPerfectionLinePerpendicular.delta(new THREE.Vector3()).multiplyScalar(0.5))
          )

          var lineLength = Math.max(nodeEdge.delta().length(), PERFECTION_LINE_LENGTH_MIN)
          var syntheticPerfectionLinePerpendicularScaled = new THREE.Line3(
            syntheticPerfectionLinePerpendicular.start,
            new THREE.Vector3().addVectors(
              syntheticPerfectionLinePerpendicular.start,
              syntheticPerfectionLinePerpendicular.delta(new THREE.Vector3()).normalize().multiplyScalar(lineLength)
            )
          )
          syntheticPerfectionLinePerpendicularScaled.lineType = lineType
          perfectionLines.push(syntheticPerfectionLinePerpendicularScaled)
        }
      }
    }

    matchedEdges.forEach(function (me) {
      var nodeEdge = nodeEdges[me.nodeEdgeIndex]

      /* For each parallelish / perpendicularish / diagonal edge of this edge:
      - add snapPoints['rectified']
      - add snapPoints['perfection']
      - add snapPoints['mid']

      For perpundicular and diagonal we need to choose the correct size of the line/facet.

      */

      me.parallelishEdges.forEach(function (peIndex) {
        var parallelishEdge = allLinkedEdges[peIndex]

        var snappedLine = Utils.snapToDominantDirection(dominantDirections, parallelishEdge.asLine3())

        //Synthesize a new line using orientation of parellel line but starting from other end of the current edge
        //Use start: distal point on original line, end: perfection point
        var distalPoint =
          nodeEdge.nodes[0].position.distanceTo(node.position) > nodeEdge.nodes[1].position.distanceTo(node.position)
            ? nodeEdge.nodes[0].position
            : nodeEdge.nodes[1].position
        //console.log('[parallel] distalPoint', distalPoint)

        //var syntheticPerfectionLine = new THREE.Line3(distalPoint, distalPoint.clone().sub(parallelishEdge.delta(new THREE.Vector3())) )

        var syntheticPerfectionLineEndPointCandidates = [
          distalPoint.clone().add(snappedLine.delta(new THREE.Vector3())),
          distalPoint.clone().sub(snappedLine.delta(new THREE.Vector3())),
        ]

        var syntheticPerfectionLine = new THREE.Line3(
          distalPoint,
          syntheticPerfectionLineEndPointCandidates[0].distanceTo(node.position) <
          syntheticPerfectionLineEndPointCandidates[1].distanceTo(node.position)
            ? syntheticPerfectionLineEndPointCandidates[0]
            : syntheticPerfectionLineEndPointCandidates[1]
        )

        //Find the point, useless alone
        snapPoints['rectified'].push(
          syntheticPerfectionLine.closestPointToPoint(node.position, false, new THREE.Vector3())
        )

        //Also add the end of the line itself... surely this is the best solution?
        snapPoints['perfection'].push(syntheticPerfectionLine.end)

        snapPoints['mid'].push(
          syntheticPerfectionLine.start
            .clone()
            .add(syntheticPerfectionLine.delta(new THREE.Vector3()).multiplyScalar(0.5))
        )

        var lineLength = Math.max(nodeEdge.delta().length(), PERFECTION_LINE_LENGTH_MIN)
        var syntheticPerfectionLineScaled = new THREE.Line3(
          syntheticPerfectionLine.start,
          new THREE.Vector3().addVectors(
            syntheticPerfectionLine.start,
            syntheticPerfectionLine.delta(new THREE.Vector3()).normalize().multiplyScalar(lineLength)
          )
        )

        syntheticPerfectionLineScaled.lineType = 'parallel'
        perfectionLines.push(syntheticPerfectionLineScaled)
      })

      me.perpendicularishEdges.forEach(function (peIndex) {
        var perpendicularishEdge = allLinkedEdges[peIndex]
        snapPointsForPerpendicularOrDiagonal(
          snapPoints,
          perfectionLines,
          nodeEdge,
          perpendicularishEdge,
          dominantDirectionsPerpendiculars,
          'perpendicular'
        )
      })

      me.diagonalishEdges.forEach(function (peIndex) {
        var diagonalishEdge = allLinkedEdges[peIndex]
        // if we just use diagonalishEdge it's basically random which direction is closer
        // so we check against the perpendicular to diagonalishEdge because that will more clearly
        // fall to the correct side of the line
        // This is not a problem for perpendicular because there is only one candidate line, but for diagonals
        // there are two candidate lines.
        var delta = diagonalishEdge.delta() //.normalize()
        var deltaPerp = delta.clone().applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)
        var diagonalishEdgeMadePerpendicular = new OsEdge({
          nodes: [
            new OsNode({ position: node.position }),
            new OsNode({ position: node.position.clone().add(deltaPerp) }),
          ],
        })

        snapPointsForPerpendicularOrDiagonal(
          snapPoints,
          perfectionLines,
          nodeEdge,
          diagonalishEdgeMadePerpendicular, //diagonalishEdge,
          dominantDirectionsDiagonals,
          'diagonal'
        )
      })
    })

    perfectionLines = Utils.filterDistinctLines(perfectionLines)

    //COMBINED POINTS

    //Find intersections with rectified lines
    //Find points which are both rectified & intersections
    //If any intersection points lie on the snappedLine then they are very enticing

    // if (mode == 'drawing') {
    allLinkedEdges.forEach(function (e) {
      var edgeLine = e.asLine3()

      perfectionLines.forEach(function (pl) {
        //ignore combinations which are near-colinear
        if (pl.delta(new THREE.Vector3()).normalize().dot(edgeLine.delta(new THREE.Vector3()).normalize()) < 0.9) {
          var intersection = Utils.intersectLines(pl, edgeLine)

          if (intersection) {
            if (intersection.manhattanDistanceTo(node.position) < snapDistanceThreshold) {
              snapPoints['intersection_rectified'].push(intersection)
            }
          }
        }
      })
    })
    // }

    //Midpoint + Rectified
    allLinkedEdges.forEach(function (e) {
      var edgeLine = e.asLine3()

      perfectionLines.forEach(function (pl) {
        snapPoints['mid'].forEach(function (mp) {
          var pt = pl.closestPointToPoint(mp, false, new THREE.Vector3())

          if (pt) {
            if (pt.manhattanDistanceTo(mp) < 0.1) {
              snapPoints['midpoint_rectified'].push(mp)
            }
          }
        })
      })
    })

    if (editor.snappingActive || WorkspaceHelper.developerMode()) {
      // console.log('existingPoints', snapPoints['existing'])
      // console.log('existingMidPoints', snapPoints['existing_mid'])
      // console.log('intersectionPoints', snapPoints['intersection'])
      // console.log('midPoints', snapPoints['mid'])
      // console.log('perfectionPoints', snapPoints['perfection'])
      // console.log('rectifiedPoints', snapPoints['rectified'])
      // console.log('perfectionLines', perfectionLines)

      if (window.hasOwnProperty('showParticles')) {
        window.clearParticles()
        window.showParticles(snapPoints['existing_mid'], 0xff00ff) //purple
        window.showParticles(snapPoints['intersection'], 0xffff00) //yellow
        window.showParticles(snapPoints['intersection_rectified'], 0x00ffff) //aqua
        window.showParticles(snapPoints['perfection'], 0xff0000) //red
        window.showParticles(snapPoints['rectified'], 0xffcccc) //pink
        window.showParticles(snapPoints['mid'], 0xccccff) //blue
      }

      if (window.showLineSegments) {
        var vertices
        ;['parallel', 'perpendicular', 'diagonal'].forEach((lineGroupName) => {
          vertices = []

          // Draw perfectionLines in both directions
          perfectionLines
            .filter((l) => l.lineType === lineGroupName)
            .forEach(function (l) {
              vertices.push(l.start)
              vertices.push(l.end)

              var otherEnd = new THREE.Vector3().subVectors(l.start, l.end).add(l.start)

              vertices.push(l.start)
              vertices.push(otherEnd)
            })

          window.showLineSegments(lineGroupName, vertices)
        })
      }
    }

    if (returnAllSnapPoints) {
      return snapPoints
    }

    var snappedPosition

    if (true) {
      // NEW METHOD: Scoring.
      // Each match adds a score. If the same point appears in multiple lists then it gets a bonus.
      // This allows interactions between lists. e.g. A point which is rectified AND an intersection gets strong preference over a plain intersection

      //Save the score in a dummy variable .score
      var scoredPoints = [] //THREE.Vector3

      var snapPointTypesAndScores = {
        existing: 100,
        existing_mid: 50,
        midpoint_rectified: 30,
        intersection_rectified: 20,
        mid: 1,
        rectified: 1,
        intersection: 1,
        perfection: 10,
      }

      for (var snapPointType in snapPointTypesAndScores) {
        snapPoints[snapPointType].forEach(function (p) {
          //Cull if not close enough to mouse
          if (node.position.manhattanDistanceTo(p) > snapDistanceThreshold) {
            //console.log('skip '+snapPointType+' point, too far from node')
            return
          }

          var scoredPoint

          // Match snapPoint to an any existing scoredPoints
          var matches = scoredPoints.filter(function (sp) {
            return sp.manhattanDistanceTo(p) < 0.01
          })

          if (matches.length > 1) {
            window.studioDebug && console.log('Warning: matches > 1, only first match is kept, others will be lost!')
            scoredPoint = matches[0]
          } else if (matches.length > 0) {
            scoredPoint = matches[0]
          } else {
            scoredPoint = p
            scoredPoint.score = 0
            scoredPoint.scoreTypes = []
            scoredPoint.scoreTypesDetails = []
            scoredPoint.points = []
            scoredPoints.push(scoredPoint)
          }

          scoredPoint.score += snapPointTypesAndScores[snapPointType]
          scoredPoint.scoreTypes.push(snapPointType)
          scoredPoint.scoreTypesDetails.push({
            type: snapPointType,
            point: p,
          })
        })
      }

      // console.log(
      //   'scoredPoints',
      //   scoredPoints.map(function (sp) {
      //     return [sp, sp.score, sp.snapPointType, sp.scoreTypesDetails]
      //   })
      // )

      var snappedPosition = scoredPoints.length
        ? _.maxBy(scoredPoints, function (sp) {
            return sp.score
          })
        : null
    }

    if (snappedPosition) {
      // console.log('Snap node position', snappedPosition)
      // console.log(
      //   'Snap position difference with raw position',
      //   new THREE.Vector3().subVectors(node.position, snappedPosition).toArray()
      // )
      node.position.x = snappedPosition.x
      node.position.y = snappedPosition.y
      //node.position.z = snappedPosition.z //Don't update z coordinate using snapping
      return true
    } else {
      // console.log('Snap position not found')
      return false
    }
  },

  changeDetection: {
    enabled: true,

    getHash: function () {
      return JSON.stringify(this.asObject())
    },

    saveHash: function () {
      if (!ObjectBehaviors.changeDetection.enabled) {
        return
      }
      return (this._hash = this.getHash())
    },

    clearHash: function () {
      this._hash = null
    },

    hasChanged: function (keys, excludeKeys) {
      if (!ObjectBehaviors.changeDetection.enabled) {
        return true
      }
      if (!this._hash) {
        return true
      } else if (keys) {
        var savedHashObject = JSON.parse(this._hash)
        var key
        for (var i = 0; i < keys.length; i++) {
          key = keys[i]
          if (savedHashObject[key] !== this.asObject()[key]) {
            //change detected in specified keys
            return true
          }
        }
        //no change detected, assume no change
        return false
      } else if (excludeKeys) {
        var savedHashObject = JSON.parse(this._hash)
        var hashObjectExcludingKeys = this.asObject()
        excludeKeys.forEach((key) => {
          delete savedHashObject[key]
          delete hashObjectExcludingKeys[key]
        })
        return JSON.stringify(savedHashObject) !== JSON.stringify(hashObjectExcludingKeys)
      } else {
        //by default check the whole object
        return this._hash != this.getHash()
      }
    },
  },

  reloadSpecs: function () {
    var specs = this.getComponentData()
    if (specs) {
      this.componentType(specs)
    }
  },

  getSystem: function () {
    if (!this.parent) {
      if (this.type !== 'OsNode' && this.type !== 'OsEdge') {
        console.log(
          'Warning this.parent is null, this should not be possible except for OsNode/OsEdge. Warning shown to aid debugging'
        )
      }
      return null
    } else if (this.type === 'OsSystem') {
      return this
    } else if (this.parent.getSystem) {
      return this.parent.getSystem()
    } else {
      return null
    }
  },
}
