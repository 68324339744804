function OsMppt(strings) {
  THREE.Object3D.call(this)
  this.type = 'OsMppt'
  this.output = null
  this.transformable = false

  // var label = TextLabel.createLabel('MPPT', new THREE.Vector3(), 0.005)
  // editor.addObject(label, this)
  // label.selectionDelegate = this
}

OsMppt.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsMppt,
  toolsActive: function () {
    return {
      translateXY: false,
      translateZ: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },
  add: function (child) {
    THREE.Object3D.prototype.add.call(this, child)

    var d = this.getMpptSettingsFromModule()
    this.azimuth = d.azimuth
    this.slope = d.slope
    this.modulesPerRow = d.modulesPerRow
    this.gcr = d.gcr
    this.bifacialTransmissionFactor = d.bifacialTransmissionFactor
    this.groundClearance = d.groundClearance
    this.diffuseShading = d.diffuseShading
    this.trackingMode = d.trackingMode
  },
  strings: function () {
    return this.children.filter(function (child) {
      return child.type == 'OsString'
    })
  },
  calculateDiffuseShadingWeightedAverage() {
    // Diffuse shading will be a weighted average across all panel groups
    // based on number of modules in each
    var countDiffuseShadingValuesSum = 0
    var countDiffuseShadingSamples = 0

    this.strings().forEach((s) => {
      s.modules.forEach((m) => {
        countDiffuseShadingValuesSum += m.getGrid().diffuseShading
        countDiffuseShadingSamples++
      })
    })
    if (countDiffuseShadingSamples) {
      return countDiffuseShadingValuesSum / countDiffuseShadingSamples
    } else {
      return 0
    }
  },
  refreshUserData: function () {
    var d = this.getMpptSettingsFromModule()
    this.azimuth = d.azimuth
    this.slope = d.slope
    this.modulesPerRow = d.modulesPerRow
    this.gcr = d.gcr
    this.bifacialTransmissionFactor = d.bifacialTransmissionFactor
    this.groundClearance = d.groundClearance
    this.trackingMode = d.trackingMode

    this.diffuseShading = this.calculateDiffuseShadingWeightedAverage()

    this.userData = {
      uuid: this.uuid,
      strings: this.strings().map(function (o) {
        return o.refreshUserData()
      }),
      azimuth: this.azimuth,
      slope: this.slope,
      modulesPerRow: this.modulesPerRow,
      gcr: this.gcr,
      bifacialTransmissionFactor: this.bifacialTransmissionFactor,
      groundClearance: this.groundClearance,
      diffuseShading: this.diffuseShading,
      trackingMode: this.trackingMode,
      electrical: this.electricalCalcs(),
    }
    return this.userData
  },
  applyUserData: function () {
    var fields = ['azimuth', 'slope']
    ObjectBehaviors.applyUserData.call(this, fields, this.userData)
  },
  getMpptSettingsFromModule: function () {
    var result = {
      azimuth: null,
      slope: null,
      modulesPerRow: 1,
      gcr: 0.3,
      bifacialTransmissionFactor: 0,
      groundClearance: 0,
      diffuseShading: 0,
      trackingMode: 0,
    }
    try {
      for (var i = 0; i < this.strings().length; i++) {
        var arbitrary_module = this.strings()[i].modules[0]
        if (!arbitrary_module) continue

        var grid = arbitrary_module.getGrid()
        if (grid) {
          result = {
            azimuth: grid.getAzimuth(),
            slope: grid.getPanelTilt(),
            modulesPerRow: grid.modulesPerRow(),
            gcr: grid.calculateGroundCoverageRatio(),
            bifacialTransmissionFactor: grid.calculateBifacialTransmissionFactor(),
            groundClearance: grid.groundClearance(),
            diffuseShading: grid.diffuseShading,
            trackingMode: grid.trackingMode(),
          }
          break
        }
      }
    } catch (err) {}
    return result
  },
  populateWithOutput: function (output_for_mppt) {
    this.output = output_for_mppt

    output_for_mppt.children.forEach(function (output_for_string) {
      var child = Utils.getChildByUuid.call(this, output_for_string.uuid)
      if (child) {
        child.populateWithOutput(output_for_string)
      } else {
        console.log('Warning: populateWithOutput() attemted child.populateWithOutput on empty child')
      }
    }, this)
  },
  moduleQuantity: function () {
    return this.strings()
      .map(function (s) {
        return s.moduleQuantity()
      })
      .reduce(function (a, b) {
        return a + b
      }, 0)
  },
  getSystem: ObjectBehaviors.getSystem,
  getSummary: function (showAsMicroinverter) {
    //String length x quantity
    //e.g. 6x1 or 8x2

    //@todo: Handle multiple strings connected in parallel, currently assume single string in series, or all same length
    if (this.strings().length) {
      //Microinverters always show the whole panel quantity individually, e.g. 1x50
      if (showAsMicroinverter) {
        return '1x' + this.moduleQuantity()
      } else {
        // Theoretically systems should not be designed with mis-matched strings in parallel but we must show
        // allow users to see the actual configuration so they can fix it
        var stringLengths = this.strings().map(function (s) {
          return s.modules.length
        })

        if (
          stringLengths.every(function (sl) {
            return sl === stringLengths[0]
          })
        ) {
          return this.strings()[0].modules.length + 'x' + this.strings().length
        } else {
          //String lengths no the same, show them individually
          return stringLengths.join('+')
        }
      }
    } else {
      return ''
    }
  },
  clearStringModuleReference: function (editor, deletedStrings) {
    this.getChildren().forEach(
      function (string) {
        if (deletedStrings && deletedStrings.indexOf(string) === -1) deletedStrings.push(string)
        string.clearStringModuleReference(editor)
      }.bind(this)
    )
  },
  getPanelsPower: function () {
    try {
      let moduleQuantity = this.moduleQuantity()
      if (
        this.getSystem()?.inverters()?.length === 1 &&
        this.moduleQuantity() === 0 &&
        this.parent?.mppts()?.length === 1 &&
        !this.getSystem()?.dcOptimizer()
      ) {
        //use unstrung module quantity if there is only one string inverter (with or without optimisers) AND there is no stringing done,
        moduleQuantity = this.getSystem()?.moduleQuantity()
      }
      return moduleQuantity * this.getSystem().moduleType().kw_stc
    } catch (e) {
      return 0
    }
  },
  getChildren: function () {
    return this.strings()
  },
  electricalCalcs: function () {
    try {
      // @TODO: Should we just omit this because it is is a duplicate, since it is included at the inverter level anyway?
      return this.parent.electricalCalcs()
    } catch (e) {
      console.warn(e)
      return {}
    }
  },
})
