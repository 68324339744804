/**
 * @description - based on requestAnimationFrame polyfill by Erik Möller. fixes from Paul Irish and Tino Zijdel
 * @todo - remove/replace this once we move away from gulp
 * @license MIT
 * @author Erik Möller, Paul Irish, Tino Zijdel
 * https://gist.github.com/paulirish/1579671
 */

;(function () {
  let lastTime = 0
  // if in a web worker, may be available inside DedicatedWorkerGlobalScope (self)
  if (!requestAnimationFrame && !document) {
    requestAnimationFrame = self.requestAnimationFrame
    cancelAnimationFrame = self.cancelAnimationFrame
  }

  // implement a replacement using setTimeOut() and date-diffing
  if (!requestAnimationFrame) {
    requestAnimationFrame = function (callback, _element) {
      let currTime = new Date().getTime()
      let timeToCall = Math.max(0, 16 - (currTime - lastTime))
      let id = window.setTimeout(function () {
        callback(currTime + timeToCall)
      }, timeToCall)
      lastTime = currTime + timeToCall
      return id
    }
    cancelAnimationFrame = function (id) {
      clearTimeout(id)
    }
  }
})()

var Debounce = {
  //private
  pending: [],

  //private
  tick: function () {
    requestAnimationFrame(Debounce.tick) // call forever

    // exit early if nothing in the queue
    if (Debounce.pending.length === 0) return

    const now = performance.now()
    let i = 0
    while (i < Debounce.pending.length) {
      const context = Debounce.pending[i]
      if (now >= context.callAt) {
        Debounce.callNow(context)
        Debounce.pending.splice(i, 1)
      } else {
        i++
      }
    }
  },

  //private
  callNow: function (context) {
    context.func.apply(context.caller, context.args)
    context.pending = false
    context.caller = null
    context.args = null
  },

  make: function (func, wait) {
    const context = {
      pending: false,
      caller: null,
      args: null,
      func: func,
      callAt: 0,
    }
    const wrapped = function () {
      if (typeof window !== 'undefined' && window.DEBOUNCE_DISABLE_FOR_TESTING === true) {
        console.debug('Skipping debounce due to window.DEBOUNCE_DISABLE_FOR_TESTING')
        return context.func.apply(this, arguments)
      } else {
        if (!context.pending) {
          // only add to the queue if not already there
          context.pending = true
          Debounce.pending.push(context)
        }
        context.caller = this
        context.args = arguments
        context.callAt = performance.now() + wait
      }
    }
    return wrapped
  },
}

if (typeof window !== 'undefined' && window.DEBOUNCE_DISABLE_FOR_TESTING !== true) {
  // begin frame loop
  requestAnimationFrame(Debounce.tick)
}
