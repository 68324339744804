class GeoJSONHelper {
  constructor() { }

  static getVersion = () => {
    return '1.0.0'
  }

  static getNearestAnnotation = (position) => {
    return editor.filter('type', 'OsAnnotation').sort((a, b) => {
      return a.position.distanceTo(position) - b.position.distanceTo(position)
    })[0]
  }

  static getObstructionType = (position, notifyErrors) => {

    var OBSTRUCTION_TYPES = ['solar', 'vegetation', 'misc', 'flush']
    var LABEL_TO_OBSTRUCTION_TYPE = {}

    // Accept labels with either:
    // - full name lowercase
    // - first 3 letters lowercase
    // - first letter uppercase
    // Beware: single character will work for some labels but not those that share the same first character
    OBSTRUCTION_TYPES.forEach((obstructionType) => {
      LABEL_TO_OBSTRUCTION_TYPE[obstructionType] = obstructionType
      LABEL_TO_OBSTRUCTION_TYPE[obstructionType.substring(0, 3)] = obstructionType
      LABEL_TO_OBSTRUCTION_TYPE[obstructionType.substring(0, 1).toUpperCase()] = obstructionType
    })

    var nearestAnnotation = GeoJSONHelper.getNearestAnnotation(position)
    if (nearestAnnotation) {
      var obstructionType = LABEL_TO_OBSTRUCTION_TYPE[nearestAnnotation.label]
      if (obstructionType) {
        return obstructionType
      }
    }

    if (notifyErrors) {
      var msg = `No obstruction type found for label "${nearestAnnotation?.label}", fallback to "misc"`
      window.Designer.showNotification(msg, 'error')
      console.error(msg)
    }
    return 'misc'
  }

  /**
   * Encodes a scene to GeoJSON data
   * This only encodes facets as GeoJSON features
   * while also taking into account obstructions
   *
   * @param {Editor} editor
   * @param {OsDesignerScene | undefined} scene - the scene to be encoded to GeoJSON
   * @returns {Object} - the GeoJSON object
   */
  static toGeoJSON = (editor, scene = undefined, options = {}) => {
    if (!editor) {
      throw 'Error in GeoJSONHelper.toGeoJSON(): editor param is invalid'
    }
    if (!scene) {
      scene = editor.scene
    }

    var epsgCode = options.hasOwnProperty('epsgCode') ? options.epsgCode : '3857'

    var applySetbacks = options.hasOwnProperty('applySetbacks') ? options.applySetbacks : true
    var removeObstructions = options.hasOwnProperty('removeObstructions') ? options.removeObstructions : true
    var includeEdges = options.hasOwnProperty('includeEdges') ? options.includeEdges : true
    var includeObstructions = options.hasOwnProperty('includeObstructions') ? options.includeObstructions : false
    var includeParcels = options.hasOwnProperty('includeParcels') ? options.includeParcels : false
    var notifyErrors = options.hasOwnProperty('notifyErrors') ? options.notifyErrors : false

    const GeoJSONWriter = new jsts.io.GeoJSONWriter()
    const sceneOrigin3857 = Utils.reprojectCoordinate(editor.scene.sceneOrigin4326, '4326', '3857')

    var realWorldMetersToWebMercatorMeters =
      1 / Utils.webMercatorMetersToRealWorldMeters(editor.scene.sceneOrigin4326[1])

    let GeoJSONFeatures = []

    let facetsBuildable = []
    let obstructions = editor.filter('type', 'OsObstruction')

    let facetsAsShapesWithSlopeAzimuth = []
    let obstructionsAsShapes = []

    editor.enumerateFacets({ includeNonSpatialFacets: false }).forEach((facet) => {
      facet.userData.obstruction ? obstructions.push(facet) : facetsBuildable.push(facet)
    })

    const terrainElevationZ = editor.getTerrain()?.position?.z || 0

    const planeRealTo3857 = (plane, azimuth) => {
      // Derive two points on the real-meters plane, then convert them to web mercator (3857) then return web-mercator plane coefficients

      var referencePoint = new THREE.Vector2(10, 10)

      var pointsReal = [
        plane.projectPoint(new THREE.Vector3(0, 0, 0), new THREE.Vector3()),
        plane.projectPoint(new THREE.Vector3(0, referencePoint.y, 0), new THREE.Vector3()),
        plane.projectPoint(new THREE.Vector3(referencePoint.x, referencePoint.y, 0), new THREE.Vector3()),
      ]
      var points3857 = pointsReal.map(
        (pointReal) =>
          new THREE.Vector3(
            realWorldMetersToWebMercatorMeters * pointReal.x + sceneOrigin3857[0],
            realWorldMetersToWebMercatorMeters * pointReal.y + sceneOrigin3857[1],
            realWorldMetersToWebMercatorMeters * (pointReal.z - terrainElevationZ)
          )
      )

      // console.log('x,y,z\n' + points3857.map((p) => p.toArray().join(',')).join('\n'))

      return new THREE.Plane().setFromCoplanarPoints(...points3857)
    }

    const planeToCofficientsArray = (plane) => [plane.normal.x, plane.normal.y, plane.normal.z, -1 * plane.constant]

    // convert all buildable facets to JSTS geometry
    // taking into account setbacks
    facetsBuildable.forEach((facet) => {
      try {
        let shapes = facet.shapesWithSetbacksJSTS(true)
        let shape = applySetbacks && !!shapes.facetClippedShape ? shapes.facetClippedShape : shapes.facetShape
        if (shape && shape.getArea() > 0 && !shape.isEmpty()) {
          // ignore any facets that yield invalid geometry
          // this can happen if a facet is completely consumed by setbacks
          // or obstructions
          facetsAsShapesWithSlopeAzimuth.push({
            // facet is only used for checking references later
            facet: facet,
            shape: shape,
            slope: facet.slope,
            azimuth: facet.azimuth,
            roof_plane_web_mercator: planeToCofficientsArray(planeRealTo3857(facet.plane, facet.azimuth)),
          })
        }
      } catch (error) {
        console.error('Error adding facet, skip and continue...', error)
      }
    })

    // convert all obstructions to JSTS geometry
    obstructions.forEach((obs) => {
      if (obs.type === 'OsObstruction') {
        // an instance of OsObstruction
        var shape = obs.toShapeUnprojected()
        shape.userData = { obstructionType: obs.obstructionType || GeoJSONHelper.getObstructionType(obs.position, notifyErrors) }
        obstructionsAsShapes.push(shape)
      }
      if (obs.type === 'OsFacet') {
        // a facet that's classified as an obstruction
        let shapes = obs.shapesWithSetbacksJSTS(false)
        let shape = shapes.facetShape
        shape.userData = { obstructionType: GeoJSONHelper.getObstructionType(obs.getCentroid(), notifyErrors) }
        if (shape && shape.getArea() > 0 && !shape.isEmpty()) {
          obstructionsAsShapes.push(shape)
        }
      }
    })

    // for each facet geometry, subtract the obstruction geometries
    // if an obstruction touches, or is within, a facet geometry
    // this operation will leave holes or will clip a facet geometry
    // in the old implementation, these operations happen after reprojection
    // in this one, we use the raw, unprojected shapes to preserve geometrical fidelity during topo operations
    // the reprojection happens after this
    if (removeObstructions) {
      facetsAsShapesWithSlopeAzimuth.forEach((fShapeSlopeAzimuth) => {
        obstructionsAsShapes.forEach((obsShape) => {
          fShapeSlopeAzimuth.shape = fShapeSlopeAzimuth.shape.difference(obsShape)
        })
      })
    }

    const transformCoordsFilter = {
      interfaces_: () => {
        // Hack to work around interface checks in jsts
        // See open question https://gis.stackexchange.com/questions/292726/how-to-transform-coordinates-for-a-geometry-in-javascript-topology-suite-jsts
        return { indexOf: () => true }
      },
      filter: (coord) => {
        let newCoord = Utils.reprojectCoordinate(
          [
            realWorldMetersToWebMercatorMeters * coord.x + sceneOrigin3857[0],
            realWorldMetersToWebMercatorMeters * coord.y + sceneOrigin3857[1],
          ],
          3857,
          epsgCode
        )
        coord.x = newCoord[0]
        coord.y = newCoord[1]
      },
    }

    const encodeShapeToGeoJSONFeature = (shape, properties) => {
      return {
        type: 'Feature',
        properties: properties,
        geometry: GeoJSONWriter.write(shape),
      }
    }

    // reproject the coordinates of the facet shapes to EPSG 3857
    // Note that this operation can invalidate some shapes because of precision issues
    // attempt to fix these shapes after reprojection
    facetsAsShapesWithSlopeAzimuth.forEach((fShapeSlopeAzimuth) => {
      fShapeSlopeAzimuth.shape.apply(transformCoordsFilter)
      fShapeSlopeAzimuth.shape.geometryChanged()

      if (fShapeSlopeAzimuth.shape.isSimple()) {
        GeoJSONFeatures.push(
          encodeShapeToGeoJSONFeature(
            fShapeSlopeAzimuth.shape,
            {
              type: 'facet',
              slope: fShapeSlopeAzimuth.slope,
              azimuth: fShapeSlopeAzimuth.azimuth,
              roof_plane_web_mercator: fShapeSlopeAzimuth.roof_plane_web_mercator
            }
          )
        )
      } else {
        // the shape is a complex geometry
        // probably because of self-intersection or precision issue or both
        // attempt to fix this shape using a JSTS' simplifier
        // the distance threshold dicatates how far the new vertices will stray
        // from the positions of the original vertices
        // closer to 0 means not to stray too far (we dont wan't to deform too much)
        // see docs: https://locationtech.github.io/jts/javadoc/org/locationtech/jts/simplify/TopologyPreservingSimplifier.html
        const SIMPLIFIER_DISTANCE_THRESHOLD = 0.1

        var newShape
        try {
          newShape = jsts.simplify.TopologyPreservingSimplifier.simplify(
            fShapeSlopeAzimuth.shape,
            SIMPLIFIER_DISTANCE_THRESHOLD
          )
        } catch (error) {
          console.warn('Error creating shape for facet, skip and continue...', fShapeSlopeAzimuth.facet, error)
        }

        // if the simplification succeeds, encode the simpplified shape
        // else, skip encoding the facet shape
        if (newShape?.isSimple()) {
          GeoJSONFeatures.push(
            encodeShapeToGeoJSONFeature(
              newShape,
              {
                type: 'facet',
                slope: fShapeSlopeAzimuth.slope,
                azimuth: fShapeSlopeAzimuth.azimuth,
                roof_plane_web_mercator: fShapeSlopeAzimuth.roof_plane_web_mercator
              }
            )
          )
        }
      }
    })


    // Add edges
    if (includeEdges) {
      let edges = editor.filter('type', 'OsEdge')

      OsEdge.edgesSubtractDormersAndRun(edges, (edge, lineString) => {

        lineString.apply(transformCoordsFilter)

        // @TODO: Determine which facet to use if there are multiple which is important for hips/valleys
        // to ensure the correct azimuth is used for foreshorterning the setbacks
        // For now just use the first facet.
        var facet = edge.getFacets()[0]

        if (facet) {
          GeoJSONFeatures.push(
            encodeShapeToGeoJSONFeature(
              lineString,
              { type: 'edge-' + edge.edgeType, slope: facet.slope, azimuth: facet.azimuth }
            )
          )
        }

      })

    }

    if (includeObstructions) {
      obstructionsAsShapes.forEach((obsShape) => {
        obsShape.apply(transformCoordsFilter)
        obsShape.geometryChanged()
        var obstructionType = obsShape.userData?.obstructionType || 'misc'
        GeoJSONFeatures.push(
          encodeShapeToGeoJSONFeature(
            obsShape,
            { type: `obstruction-${obstructionType}` }
          )
        )
      })
    }

    if (includeParcels) {
      // we are actually using these to define facets, so we force them to be polygons and automatically complete the
      // ring if it is incomplete.
      var wires = editor.filter('type', 'OsEdge').filter(e => e.isWire())

      var [wirePolygons, _] = OsEdge.findMissingFacetsInEdgeNetwork([], wires, [])
      var wirePolygonsTransformed = wirePolygons.map(wirePolygon => {
        wirePolygon.geometry.coordinates[0] = wirePolygon.geometry.coordinates[0].map(c => {
          var _c = { x: c[0], y: c[1] }
          transformCoordsFilter.filter(_c)
          return [_c.x, _c.y]
        })
        wirePolygon.properties.type = 'parcel'
        return wirePolygon
      })
      wirePolygonsTransformed.forEach((wirePolygonTransformed) => {
        GeoJSONFeatures.push(wirePolygonTransformed)
      })

    }

    const output = {
      type: 'FeatureCollection',
      crs: {
        type: 'name',
        properties: {
          name: `urn:ogc:def:crs:EPSG::${epsgCode}`,
        },
      },
      features: GeoJSONFeatures,
    }

    return output
  }

  /**
   * sends the GeoJSON data and other auto-layout params to the auto-layout API
   *
   * @param {Object} geojson - the GeoJSON object
   * @param {int} moduleId - the moduleId of the target system
   * @param {int} projectId - can be used to apply project-specific overrides, null is permitted
   * @param {number} tilt_rack_default_orientation
   * @param {number} tilt_rack_default_tilt
   * @param {Function} onSuccess - callback function for when the API call succeeds
   * @param {Function} onError - callback function for when the API call returns an error
   */
  static sendGeoJSON = (
    geojson,
    moduleId,
    projectId,
    tilt_rack_default_orientation,
    tilt_rack_default_tilt,
    onSuccess,
    onError
  ) => {
    $.ajax({
      type: 'POST',
      url: API_BASE_URL + 'orgs/' + window.getStorage().getItem('org_id') + '/design_for_project/',
      dataType: 'json',
      contentType: 'application/json',
      data: JSON.stringify({
        facets_geojson: geojson,
        module_id: moduleId,
        project_id: projectId,
        tilt_rack_default_orientation: tilt_rack_default_orientation,
        tilt_rack_default_tilt: tilt_rack_default_tilt,
      }),
      headers: Utils.tokenAuthHeaders({
        'X-CSRFToken': window.getCookie('csrftoken'),
      }), //cors for django
      success: (data) => onSuccess(data),
      error: (data) => onError(data),
    })
  }


  /**
   * Recursively subtracts polygons from an initial list of LineStrings.
   *
   * @param {jsts.geom.LineString[]} lineStrings - List of LineStrings to be processed.
   * @param {jsts.geom.Polygon[]} polygons - List of polygons to subtract from the LineStrings.
   * @returns {jsts.geom.LineString[]} - Resulting list of LineStrings after all subtractions.
   */
  static subtractPolygonsFromLineStrings = (lineStrings, polygons) => {
    // Base case: if there are no polygons left to process, return the lineStrings as-is
    if (polygons.length === 0) {
      return lineStrings;
    }

    // Take the first polygon and prepare to apply it to each LineString
    const [currentPolygon, ...remainingPolygons] = polygons;
    const newLineStrings = [];

    // Iterate over each LineString, subtracting the current polygon
    for (const lineString of lineStrings) {
      const result = lineString.difference(currentPolygon);

      // Handle case where result is MultiLineString or a single LineString
      if (result instanceof jsts.geom.MultiLineString) {
        for (let i = 0; i < result.getNumGeometries(); i++) {
          const subLineString = result.getGeometryN(i);
          if (subLineString instanceof jsts.geom.LineString) {
            newLineStrings.push(subLineString);
          }
        }
      } else if (result instanceof jsts.geom.LineString) {
        newLineStrings.push(result);
      }
    }

    // Recurse with the updated list of LineStrings and remaining polygons
    return OsEdge.subtractPolygonsFromLineStrings(newLineStrings, remainingPolygons);
  }

}
