/*
Allow attributeName to be supplied with a dot separating nested properties, up to 3 levels deep
e.g. userData.site.timezoneOffset would update object.userData.site.timezoneOffset
*/

var SetValueCommand = function (objectOrUuid, attributeName, newValue, commandUUID, updatable, valueFormat) {
  Command.call(this)

  this.type = 'SetValueCommand'
  this.name = 'Set Value Command'
  this.timeStamp = Date.now()
  this.updatable = updatable || false
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  // Exclude 'newValue' and 'oldValue' because we may transform data types using valueFormat
  this.PROPERTIES = ['attributeName']
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.valueFormat = valueFormat
    this.objectOrUuid = objectOrUuid
    this.object = typeof objectOrUuid === 'string' ? this.editor.objectByUuid(objectOrUuid) : objectOrUuid
    this.attributeName = attributeName
    this.oldValue = this.object[attributeName]
    this.newValue = newValue
  }
}

SetValueCommand.prototype = {
  execute: function () {
    if (this.attributeName.indexOf('.') !== -1) {
      var attributeParts = this.attributeName.split('.')
      if (attributeParts.length === 3) {
        this.object[attributeParts[0]][attributeParts[1]][attributeParts[2]] = this.newValue
      } else {
        this.object[attributeParts[0]][attributeParts[1]] = this.newValue
      }
    } else {
      this.object[this.attributeName] = this.newValue
    }
    if (this.attributeName === 'inverterRange' && !this.editor.changingHistory) {
      var system = this.object.getSystem()
      system.requireClusters = true
    }
    this.editor.signals.objectChanged.dispatch(this.object, this.attributeName)
  },

  undo: function () {
    if (this.attributeName.indexOf('.') !== -1) {
      var attributeParts = this.attributeName.split('.')
      if (attributeParts.length === 3) {
        this.object[attributeParts[0]][attributeParts[1]][attributeParts[2]] = this.oldValue
      } else {
        this.object[attributeParts[0]][attributeParts[1]] = this.oldValue
      }
    } else {
      this.object[this.attributeName] = this.oldValue
    }
    this.editor.signals.objectChanged.dispatch(this.object, this.attributeName)
  },

  update: function (cmd) {
    this.newValue = cmd.newValue
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    if (this.valueFormat === 'Vector3') {
      output.newValue = this.newValue ? this.newValue.toArray() : null
      output.oldValue = this.oldValue ? this.oldValue.toArray() : null
    } else {
      output.newValue = this.newValue
      output.oldValue = this.oldValue
    }
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    if (this.valueFormat === 'Vector3') {
      this.newValue = json.newValue ? new THREE.Vector3().fromArray(json.newValue) : null
      this.oldValue = json.oldValue ? new THREE.Vector3().fromArray(json.oldValue) : null
    } else {
      this.newValue = json.newValue
      this.oldValue = json.oldValue
    }
  },
}
