function upperCaseSafe(input) {
  if (input && input !== '') {
    return input.toUpperCase()
  } else {
    return input
  }
}

function loadPlugin(pluginConfig, editor) {
  var pluginConfigId = pluginConfig.id
  var importMethod = pluginConfig.importMethod

  var instance = {
    pluginConfigId: pluginConfigId,
    functions: {},
    scope: {},
    unload: null,
  }

  var signalTypes = [
    'objectAdded',
    'objectChanged',
    'objectRemoved',
    'objectSelected',
    'systemSelected',
    'systemCalcsPrepare',
    'systemCalcsError',
    'systemCalcsSuccess',
    'viewsChanged',
    'cameraChanged',
    'sceneGraphChanged',
    'liteProjectUpgraded',
  ]

  var eventTypes = [
    // Special handlers which are called on load/unload instead of being tied to signals
    'pluginLoaded',
    'pluginUnloaded',
  ]

  var functionTypes = [...signalTypes, ...eventTypes]

  if (pluginConfig.plugin) {
    // This plugin is a function that returns the plugin object, no need for function wrapping
    const functions = pluginConfig.plugin()
    for (var key in functions) {
      instance.functions[key] = functions[key].bind(instance.scope)
    }
  } else if (pluginConfig.payload) {
    // This plugin is a string that needs to be wrapped in a function
    var scriptWrapParams = 'scope,' + signalTypes.join(',')

    var scriptWrapResultObj = {}
    functionTypes.forEach((signalKey) => {
      if (importMethod === 'library') {
        scriptWrapResultObj[signalKey] = 'exports.library.' + signalKey
      } else {
        scriptWrapResultObj[signalKey] = signalKey
      }
    })

    var scriptWrapResult = JSON.stringify(scriptWrapResultObj).replace(/\"/g, '')

    let pluginPayloadWrapped = pluginConfig.payload + '\n return ' + scriptWrapResult + ';'
    if (importMethod === 'library') {
      pluginPayloadWrapped = 'var exports = {};\n' + pluginPayloadWrapped
    }

    var generatedFunctions = new Function(scriptWrapParams, pluginPayloadWrapped).bind(instance.scope)()

    // bind functions to scope
    for (var key in generatedFunctions) {
      if (generatedFunctions[key]) {
        // bind to same scope as original function so `this` is shared between plugin body and inside functions
        instance.functions[key] = generatedFunctions[key].bind(instance.scope)
      }
    }
  } else {
    throw new Error('Plugin config must have either plugin or payload')
  }

  if (window.studioDebug) {
    console.log(instance.functions, arguments)
  }

  if (window.studioDebug) {
    console.log('bind to signals')
  }
  for (var signalName in instance.functions) {
    if (signalTypes.indexOf(signalName) === -1) {
      //must be an event instead of a signal, no need to bind these to signals
      // e.g. pluginLoaded or pluginUnloaded
    } else if (editor.signals && editor.signals[signalName]) {
      // Handle plugin events after any other signal handlers have been processed
      // This ensures that the plugin runs based on the final state of the system
      // AFTER the action has occurred.
      // In future we may need to make this configurable if there are cases where the plugin
      // handler should fire first before built-in studio handlers, but we can defer for now.
      var priority = -100

      editor.signals[signalName].add(instance.functions[signalName], undefined, priority)
    } else {
      if (window.studioDebug) {
        console.error('editor.signals.' + signalName + ' not found, skip binding.')
      }
    }
  }

  instance.unload = function () {
    // Only try to bind signals, not lifecycle events
    Object.entries(instance.functions)
      .filter(([signalName, func]) => signalTypes.indexOf(signalName) !== -1)
      .forEach(([signalName, func]) => editor.signals[signalName].remove(func))

    if (instance.functions.pluginUnloaded) {
      instance.functions.pluginUnloaded()
    }
  }.bind(instance)

  if (instance.functions.pluginLoaded) {
    // @TODO: Undo setTimeout hack which prevents this from causing infinite loop due to triggering a signal
    // which results in plugins being loaded but at this time they have not actually been registered
    // so it will keep trying to load the plugin recursively to death.
    setTimeout(function () {
      instance.functions.pluginLoaded()
    }, 1)
  }

  return instance
}

var pluginInstances = {}
var pluginConfigs = []

var getPluginScripts = function () {
  var pluginScriptsJson = window.getStorage().getItem('pluginScripts')
  return pluginScriptsJson && pluginScriptsJson.length > 0 ? JSON.parse(pluginScriptsJson) : null
}

function mergeIntoUniqueArray(arrays) {
  var items = []
  arrays.forEach((array) => {
    items = items.concat(array)
  })
  return Utils.uniqueArray(items)
}

function getPluginIdsForComponentCode(componentCode, countryIso2) {
  var pluginsToActivate = []

  for (var i = 0; i < pluginConfigs.length; i++) {
    var pluginConfig = pluginConfigs[i]

    // Only load plugin if it matches a component code in the system (case insensitive)
    if (
      pluginConfig.componentCodes.indexOf(upperCaseSafe(componentCode)) !== -1 ||
      pluginConfig.componentCodes.indexOf('ALL') !== -1
    ) {
      // Only load plugin if it matches the project country code, if set on the plugin
      // or if countryCodes is not set or countryCodes is empty array
      if (
        !pluginConfig.countryCodes ||
        (pluginConfig.countryCodes.constructor === Array && pluginConfig.countryCodes.length === 0) ||
        pluginConfig.countryCodes.indexOf(upperCaseSafe(countryIso2)) !== -1
      ) {
        pluginsToActivate.push(pluginConfig.id)
      }
    }
  }
  return pluginsToActivate
}

function syncPluginsWithSelectedSystem() {
  return syncPluginsWithSystem(editor.selectedSystem)
}

function syncPluginsWithSystem(system) {
  if (!system) {
    if (editor.selectedSystem) {
      system = editor.selectedSystem
    } else {
      return
    }
  }

  if (window.studioDebug) {
    console.log('syncPluginsWithSystem')
  }

  var codes = []

  var componentsInSystem = Utils.uniqueArray(
    codes
      .concat(system.moduleType() ? [system.moduleType()] : [], system.inverters(), system.batteries(), system.others())
      .map((component) => upperCaseSafe(component.code))
      .filter(Boolean)
  )
  if (window.studioDebug) {
    console.log('componentsInSystem', componentsInSystem)
  }

  var countryIso2 = WorkspaceHelper && WorkspaceHelper.project ? WorkspaceHelper.project.country_iso2 : null

  var configPluginIdsRequiredForSystem = mergeIntoUniqueArray(
    componentsInSystem.map((componentCode) => getPluginIdsForComponentCode(componentCode, countryIso2)).filter(Boolean)
  )

  // disable any instances which are active but not required for system components
  Object.values(pluginInstances).forEach((pluginInstance) => {
    if (configPluginIdsRequiredForSystem.indexOf(pluginInstance.pluginConfigId) === -1) {
      if (window.studioDebug) {
        console.log('Remove unused pluginInstance for ' + pluginInstance.pluginConfigId)
      }
      unloadPluginInstance(pluginInstance)
    }
  })

  // add any missing plugin instances
  var pluginConfigsToActivate = pluginConfigs.filter(
    (pluginConfig) => configPluginIdsRequiredForSystem.indexOf(pluginConfig.id) !== -1
  )

  if (window.studioDebug) {
    console.log('pluginConfigsToActivate', pluginConfigsToActivate)
  }

  pluginConfigsToActivate.forEach((pluginConfig) => {
    if (pluginInstances[pluginConfig.id]) {
      if (window.studioDebug) {
        console.log('Plugin (' + pluginConfig.id + ') already loaded, ignore')
      }
    } else {
      if (window.studioDebug) {
        console.log('Plugin (' + pluginConfig.id + ') not loaded, load now')
      }
      loadPluginFromConfig(pluginConfig)
    }
  })
}

function setAutoLoadPlugins(active) {
  // @TODO: Handle module change or initial setup? Handle objects created before signals added?

  if (active) {
    editor.signals.sceneGraphChanged.add(syncPluginsWithSelectedSystem)
  } else {
    editor.signals.sceneGraphChanged.remove(syncPluginsWithSelectedSystem)

    // force unloading any plugins already loaded now that we are no longer listening
    Object.values(pluginInstances).forEach((pluginInstance) => unloadPluginInstance(pluginInstance))
  }
}

function loadPluginFromConfig(config) {
  pluginInstances[config.id] = loadPlugin(config, window.editor, config)
}

function unloadPluginInstance(pluginInstance) {
  pluginInstances[pluginInstance.pluginConfigId].unload()
  delete pluginInstances[pluginInstance.pluginConfigId]
}

function reloadPlugins() {
  if (!window.editor) {
    console.log('skip reloadPlugins, editor not yet ready')
    return
  }

  // Unload
  Object.values(pluginInstances).forEach((pluginInstance) => unloadPluginInstance(pluginInstance))

  var pluginScripts = getPluginScripts()

  // Rebuild pluginConfigs with cache-busted script URLs
  if (pluginScripts) {
    pluginConfigs = []
    Object.keys(pluginScripts).forEach((url) => {
      // Required so we keep this reference into the handler
      $.ajax({
        url: url + '?' + Math.random(),
        // Workaround ajax bug where error handler will throw sometimes even when result is 200 if dataType not matched
        dataType: 'text',
        context: this,
        success: function (data) {
          if (window.studioDebug) {
            console.log('reloadPlugs HTTP success for ' + url)
          }

          pluginConfigs.push({
            id: url,
            componentCodes: pluginScripts[url].map((code) => upperCaseSafe(code)),
            payload: data,
          })

          if (pluginConfigs.length === Object.values(pluginScripts).length) {
            if (window.studioDebug) {
              console.log('All scripts finished loading, now trigger syncPluginsWithSystem')
            }
            // Reload
            editor.getSystems().forEach((system) => syncPluginsWithSystem(system))
          }
        },
        error: function (response) {
          if (window.studioDebug) {
            console.log('reloadPlugs HTTP error', response)
          }
        },
      })
    })
  }
}

if (getPluginScripts()) {
  reloadPlugins()
} else {
  pluginConfigs = [
    pluginAlpha,
    pluginDesignAutoApply,
    pluginDesignValidation,
    pluginLGES,
    pluginMaxeonAcPanels,
    pluginSolarEdge,
    pluginSolariaAcPanelsAu,
    pluginSolariaAcPanelsUs,
    pluginViridian,
  ]
}

if (getPluginScripts()) {
  setTimeout(function () {
    setAutoLoadPlugins(true)
    // loadPluginFromConfig(helperConfigs[0])
  }, 3000)
}
