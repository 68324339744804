window.pluginMaxeonAcPanels = {
  id: 2000,
  countryCodes: [], // all
  name: 'pluginMaxeonAcPanels',
  componentCodes: [
    'SPR-MAX5-420-E3-AC',
    'SPR-MAX5-415-E3-AC',
    'SPR-MAX5-410-E3-AC',
    'SPR-MAX5-400-E3-AC',
    'SPR-P3-370-BLK-E3-AC',
    'SPR-P3-375-BLK-E3-AC',
    'SPR-P3-380-BLK-E3-AC',
    'SPR-P3-385-BLK-E3-AC',
    'SPR-MAX6-450-E3-AC',
    'SPR-MAX6-445-E3-AC',
    'SPR-MAX6-440-E3-AC',
    'SPR-MAX6-435-E3-AC',
    'SPR-MAX6-430-E3-AC',
    'SPR-MAX6-425-E3-AC',
    'SPR-MAX6-420-E3-AC',
    'SPR-MAX6-430-BLK-E3-AC',
    'SPR-MAX6-425-BLK-E3-AC',
    'SPR-MAX6-420-BLK-E3-AC',
    'SPR-MAX6-415-BLK-E3-AC',
    'SPR-MAX6-410-BLK-E3-AC',
    'SPR-MAX6-405-BLK-E3-AC',
    'SPR-MAX6-400-BLK-E3-AC',
    // Add panel codes for the 40 year warranty panels (no longer in use)
    'SPR-MAX5-400-E3-AC/40',
    'SPR-MAX5-410-E3-AC/40',
    'SPR-MAX5-415-E3-AC/40',
    'SPR-MAX5-420-E3-AC/40',
    // Add panel codes for the 25 year warranty panels
    'SPR-MAX5-400-E3-AC/25',
    'SPR-MAX5-410-E3-AC/25',
    'SPR-MAX5-415-E3-AC/25',
    'SPR-MAX5-420-E3-AC/25',
    // Request From Maxeon 2023/03/23
    'SPR-MAX6-420-E4-AC',
    'SPR-MAX6-425-E4-AC',
    'SPR-MAX6-435-E4-AC',
    'SPR-MAX6-425-BLK-E4-AC',
    'SPR-MAX6-415-BLK-E4-AC',
    'SPR-MAX6-410-BLK-E4-AC',
  ],
  plugin: function () {
    var panelCodeToMicroCode = {
      'SPR-MAX5-420-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX5-415-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX5-410-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX5-400-E3-AC': 'IQ7A-72-2-INT',
      'SPR-P3-370-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-P3-375-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-P3-380-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-P3-385-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-450-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-445-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-440-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-435-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-430-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-425-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-420-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-430-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-425-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-420-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-415-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-410-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-405-BLK-E3-AC': 'IQ7A-72-2-INT',
      'SPR-MAX6-400-BLK-E3-AC': 'IQ7A-72-2-INT',
      // Add panel codes for the 40 year warranty panels (no longer in use)
      'SPR-MAX5-400-E3-AC/40': 'IQ7A-72-2-INT',
      'SPR-MAX5-410-E3-AC/40': 'IQ7A-72-2-INT',
      'SPR-MAX5-415-E3-AC/40': 'IQ7A-72-2-INT',
      'SPR-MAX5-420-E3-AC/40': 'IQ7A-72-2-INT',
      // Add panel codes for the 25 year warranty
      'SPR-MAX5-400-E3-AC/25': 'IQ7A-72-2-INT',
      'SPR-MAX5-410-E3-AC/25': 'IQ7A-72-2-INT',
      'SPR-MAX5-415-E3-AC/25': 'IQ7A-72-2-INT',
      'SPR-MAX5-420-E3-AC/25': 'IQ7A-72-2-INT',
      // Request From Maxeon 2023/03/23
      'SPR-MAX6-420-E4-AC': 'IQ7A-72-M-ACM-INT',
      'SPR-MAX6-425-E4-AC': 'IQ7A-72-M-ACM-INT',
      'SPR-MAX6-435-E4-AC': 'IQ7A-72-M-ACM-INT',
      'SPR-MAX6-425-BLK-E4-AC': 'IQ7A-72-M-ACM-INT',
      'SPR-MAX6-415-BLK-E4-AC': 'IQ7A-72-M-ACM-INT',
      'SPR-MAX6-410-BLK-E4-AC': 'IQ7A-72-M-ACM-INT',
    }

    var microCodes = Object.values(panelCodeToMicroCode).filter((v, i, a) => a.indexOf(v) === i)

    function upperCaseSafe(value) {
      return value && value.toUpperCase ? value.toUpperCase() : value
    }

    function objectWithUpperCaseKeys(object) {
      var newObject = {}
      for (var key in object) {
        newObject[upperCaseSafe(key)] = newObject[key]
      }
      return newObject
    }

    function indexOfCaseInsensitive(valuesArray, value) {
      var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))
      return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
    }

    function systemHasComponent(system, codes) {
      return countComponentsForSystem(system, codes.constructor === Array ? codes : [codes]) > 0
    }

    function countComponentsForSystem(system, codes) {
      return system.componentCodes().filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
    }

    function getInverterComponentForCode(code) {
      var codeUpperCase = upperCaseSafe(code)
      return window.AccountHelper.getComponentInverterSpecsAvailable().filter(
        (component) => upperCaseSafe(component.code) === codeUpperCase
      )[0]
    }

    function syncMicroInverters(system, microInverterCode) {
      if (!systemHasComponent(system, microInverterCode)) {
        var componentType = getInverterComponentForCode(microInverterCode)

        if (componentType) {
          //@TODO: Handle undo/redo?
          window.editor.execute(
            new window.AddObjectCommand(
              new window.OsInverter({
                inverter_id: componentType.id,
                quantity: system.moduleQuantity(),
              }),
              system,
              false
            )
          )
          Designer.showNotification('Integrated micro-inverter added: ' + microInverterCode)

          // If COGS IS set on the set on the  AC modules and IS set on the micro-inverter too, then override
          // microinverter and set to zero.
          console.log(system, system.moduleType(), system.moduleType()?.cost)
          console.log(componentType, componentType?.cost)

          var acPanelHasCogs = system.moduleType()?.cost > 0
          var microInverterHasCogs = componentType?.cost > 0
          if (acPanelHasCogs && microInverterHasCogs) {
            system.setComponentCostOverride(componentType, 0)
            Designer.showNotification(
              'COGS override of $0 has been applied for integrated micro-inverters to avoid double-counting.'
            )
          }
        } else {
          console.warn('Matching micro-inverter not enabled on org. Prompt to enable...')

          if (
            !window.AccountHelper.activateComponentsRequests.some(
              (componentRequest) => componentRequest.message && componentRequest.message.indexOf('Maxeon') !== -1
            )
          ) {
            var callback = () => {
              //Automatically add component to selected system
              var componentType = getInverterComponentForCode(microInverterCode)
              if (componentType && !systemHasComponent(system, microInverterCode)) {
                //@TODO: Handle undo/redo?
                window.editor.execute(
                  new window.AddObjectCommand(
                    new window.OsInverter({
                      inverter_id: componentType.id,
                      quantity: system.moduleQuantity(),
                    }),
                    system,
                    false
                  )
                )
                Designer.showNotification('Integrated micro-inverter added: ' + microInverterCode)
              }
            }
            //Automatically activate the components when panel is added
            window.AccountHelper.debouncedActivateComponents(microCodes, callback)
          }

          // Force System panel to refresh
          window.editor.signals.sceneGraphChanged.dispatch()
        }
      }
    }

    function refresh(system) {
      if (!!window.WorkspaceHelper.getIsProjectLite()) {
        // Don't run on Lite projects
        return
      }

      for (var panelCode in panelCodeToMicroCode) {
        // Add micro if not enabled (quantity updates automatically so we don't need to set quantity in plugin)
        if (system.moduleQuantity() > 0 && systemHasComponent(system, panelCode)) {
          var microInverterCode = panelCodeToMicroCode[panelCode]
          syncMicroInverters(system, microInverterCode)
          break
        }
      }
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refresh(system)
        }
      }
    }

    function objectAdded(object) {
      if (window.studioDebug) {
        console.log('MaxeonAcPanels objectAdded', object)
      }
      refreshSystemFromObject(object)
    }

    function objectChanged(object) {
      if (window.studioDebug) {
        console.log('MaxeonAcPanels objectChanged', object)
      }
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (window.studioDebug) {
        console.log('MaxeonAcPanels objectRemoved', object)
      }
      refreshSystemFromObject(object)
    }

    function sceneGraphChanged(system) {
      if (window.studioDebug) {
        console.log('MaxeonAcPanels sceneGraphChanged')
      }
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      if (window.studioDebug) {
        console.log('MaxeonAcPanels pluginLoaded')
        console.warn('Inspect Script Here for interactive debugging in javascript console...')
      }
      refreshAllSystems()
    }

    function pluginUnloaded() {
      window.AccountHelper.activateComponentsRequests = window.AccountHelper.activateComponentsRequests.filter(
        (request) => {
          return !request.message?.includes('Maxeon')
        }
      )
      if (window.studioDebug) {
        console.log('MaxeonAcPanels pluginUnloaded')
      }
      try {
        refreshAllSystems()
      } catch (error) {
        console.error(error)
      }
    }

    return {
      objectAdded: objectAdded,
      objectChanged: objectChanged,
      objectRemoved: objectRemoved,
      sceneGraphChanged: sceneGraphChanged,
      liteProjectUpgraded: liteProjectUpgraded,
      pluginLoaded: pluginLoaded,
      pluginUnloaded: pluginUnloaded,
    }
  },
}
