function OsInverter(options) {
  THREE.Object3D.call(this)

  this.type = 'OsInverter'
  this.unshiftNewObject = true

  // Allow fetching id from either options or options.userData
  this.inverter_id = options?.inverter_id || options?.userData?.inverter_id || null

  this.code = options ? options.code : null
  this.manufacturer_name = options ? options.manufacturer_name : null
  this.microinverter = options ? options.microinverter : null
  this.hybrid = options ? options.hybrid : null
  this.max_power_rating = options ? options.max_power_rating : null
  this.max_dc_power = options ? options.max_dc_power : null
  this.voltage_max = options ? options.voltage_max : null
  this.voltage_nominal = options ? options.voltage_nominal : null
  this.nominal_input_voltage = options ? options.nominal_input_voltage : null
  this.voltage_minimum = options ? options.voltage_minimum : null
  this.current_isc_max = options ? options.current_isc_max : null
  this.mppt_voltage_max = options ? options.mppt_voltage_max : null
  this.power_consumption_at_night = options ? options.power_consumption_at_night : null
  this.mppt_quantity = options ? options.mppt_quantity : null
  this.phase_type = options ? options.phase_type : null
  this.string_isolation = options ? options.string_isolation : null
  this.shadingOverride = []
  this.output = null
  this.transformable = false

  if (options && typeof options.efficiency !== 'undefined' && options.efficiency !== null && options.efficiency > 0) {
    this.efficiency = options.efficiency
  } else {
    this.efficiency = 100
  }

  if (options?.userData) {
    this.userData = options.userData
    this.applyUserData()
  } else {
    this.reloadSpecs()
  }
}

OsInverter.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsInverter,
  fields: [
    'inverter_id',
    'code',
    'manufacturer_name',
    'efficiency',
    'microinverter',
    'hybrid',
    'max_power_rating',
    'max_dc_power',
    'voltage_max',
    'voltage_nominal',
    'nominal_input_voltage',
    'voltage_minimum',
    'mppt_voltage_max',
    'current_isc_max',
    'power_consumption_at_night',
    'mppt_quantity',
    'compatible_battery_codes',
    'output',
    'current_ac_max',
    'inbuilt_dc_isolator',
    'phase_type',
    'org_id',
  ],
  // These fields don't exist in DB entities, so would get wiped if they were in 'fields'
  non_component_fields: ['string_isolation'],

  add: function (child) {
    THREE.Object3D.prototype.add.call(this, child)
  },
  mppts: function () {
    return this.children.filter(function (child) {
      return child.type === 'OsMppt'
    })
  },
  getComponentData: function () {
    return AccountHelper.getInverterById(this.inverter_id)
  },
  componentType: function (_componentType) {
    if (typeof _componentType === 'undefined') {
      var result = {}
      this.fields.forEach((field) => {
        result[field] = this[field]
      })

      // Non-standard return, should this really just use inverter_id everywhere?
      result.id = this.inverter_id

      return result
    }

    // Check if this will result in a change from non-micro to microinverter
    // only clear children if changing to microinverter
    // Fix bug introduced in 27d42eaffb48c (which mis-used code first added in afd213e15cb377)
    if (
      this.microinverter !== _componentType.microinverter &&
      _componentType.microinverter &&
      !window.editor.changingHistory
    ) {
      this.children.forEach(function (child) {
        window.editor.execute(new window.RemoveObjectCommand(child))
      })
    }

    this.fields.forEach((field) => {
      this[field] = _componentType[field]
    })

    // Non-standard, nasty, confusing field mapping. We store component_activation_id in inverter_id
    // NOT the standard component inverter_id
    // This should be renamed to this.inverter_activation_id
    this.inverter_id = _componentType.id
  },
  onChange: function () {},
  reloadSpecs: ObjectBehaviors.reloadSpecs,
  refreshUserData: function () {
    if (AccountHelper.loadedDataReady.componentInverterSpecs) {
      this.userData = {}
      this.fields.forEach((field) => {
        this.userData[field] = this[field]
      })
      this.non_component_fields.forEach((field) => {
        this.userData[field] = this[field]
      })

      this.userData.mppts = this.mppts().map(function (o) {
        return o.refreshUserData()
      })
      this.userData.output = this.output
    } else if (window.studioDebug) {
      console.log('Skip OsInverter.refreshUserData... inverter specs not loaded')
    }

    this.userData.electrical = this.electricalCalcs()

    return this.userData
  },
  applyUserData: function () {
    ObjectBehaviors.applyUserData.call(this, this.fields, this.userData)
    ObjectBehaviors.applyUserData.call(this, this.non_component_fields, this.userData)
    this.reloadSpecs()
  },
  populateWithOutput: function (output_for_inverter) {
    this.output = output_for_inverter

    output_for_inverter.children.forEach(function (output_for_mppt) {
      Utils.getChildByUuid.call(this, output_for_mppt.uuid).populateWithOutput(output_for_mppt)
    }, this)
  },
  moduleQuantity: function () {
    return this.mppts()
      .map(function (m) {
        return m.moduleQuantity()
      })
      .reduce(function (a, b) {
        return a + b
      }, 0)
  },
  getSystem: ObjectBehaviors.getSystem,
  getMaxPowerRating: function () {
    var inverter = window.AccountHelper.getInverterById(this.inverter_id)
    return inverter ? inverter.max_power_rating : 0
  },
  getMaxDcPower: function () {
    var inverter = window.AccountHelper.getInverterById(this.inverter_id)
    return inverter ? inverter.max_dc_power : 0
  },
  isHybridInverter: function () {
    var inverter = window.AccountHelper.getInverterById(this.inverter_id)
    return inverter?.hybrid === true || inverter?.hybrid === 'Y'
  },
  getSummary: function (showManufacturer, skipStringSummary) {
    //If strung:
    //Inverter manufacturer_name plus code plus list of summaries of mppts in parentheses
    //e.g. INV109213 (6x2, 10x1)
    //
    //If modules not strung,
    //Inverter manufacturer_name plus code only
    //e.g. INV109213
    //
    //Warning: This should NOT run for microinverters because they can only be shown along with total module quantity
    //but this inverter does not know the total module quantity, render that at the system level instead.
    //
    //If system stringing is incomplete, we pass skipStringSummary==true which hides stringing
    if (this.microinverter) {
      return (showManufacturer ? this.manufacturer_name + ' ' : '') + this.code
    }

    var stringSummary =
      skipStringSummary === true
        ? ''
        : this.moduleQuantity() > 0
        ? ' (' +
          this.mppts()
            .map(function (m) {
              return m.getSummary(this.microinverter)
            }, this)
            .join(', ') +
          ')'
        : ''

    return (showManufacturer ? this.manufacturer_name + ' ' : '') + this.code + stringSummary
  },
  clearStringModuleReference: function (editor, deletedStrings) {
    this.getChildren().forEach((mppt) => {
      mppt.strings().forEach((string) => {
        if (deletedStrings && deletedStrings.indexOf(string) === -1) deletedStrings.push(string)
        string.clearStringModuleReference(editor)
      })
    })
  },
  getChildren: function () {
    return this.mppts()
  },
  getPanelsPower: function () {
    try {
      let moduleQuantity = 0
      if (this.microinverter) {
        // We currently assume that the number of panels that should be assigned to a single microinverter is the
        // same as it's MPPT quantity. This is because we are using the MPPT quantity to due how many modules a
        // single microinverter can support (i.e. a microinverter that can support 2 panels will have 2 MPPTs)
        moduleQuantity = this.mppt_quantity
      } else if (this.moduleQuantity()) {
        moduleQuantity = this.moduleQuantity()
      } else if (
        this.moduleQuantity() === 0 &&
        this.getSystem()
          ?.inverters()
          ?.filter((i) => !i.microinverter).length === 1
      ) {
        //use unstrung module quantity if there is only one string inverter (with or without optimisers) AND there is no stringing done,
        moduleQuantity = this.getSystem()?.getUnstrungModules()?.length || 0
      }

      return moduleQuantity * this.getSystem().moduleType().kw_stc
    } catch (e) {
      return 0
    }
  },
  calculateDcAcRatio: function () {
    try {
      return this.getPanelsPower() / this.getMaxPowerRating()
    } catch (e) {
      return 0
    }
  },
  hasMaxDCPower: function () {
    return this.max_dc_power !== 0 && this.max_dc_power
  },
  calculateMaxDcAcRatio: function () {
    try {
      return this.getMaxDcPower() / this.getMaxPowerRating()
    } catch (e) {
      return 0
    }
  },
  electricalCalcs: function () {
    try {
      const round2dp = (value) => roundToDecimalPlacesPrecise(value, 2)
      return {
        inverterVoltageRange: [this.voltage_minimum, this.voltage_max].map(round2dp),
        inverterIscRange: [0, this.current_isc_max].map(round2dp),
        mpptVoltageRange: [this.mppt_voltage_min || this.voltage_minimum, this.mppt_voltage_max].map(round2dp),
      }
    } catch (e) {
      console.warn(e)
      return {}
    }
  },
  collectSlots: function (slots) {
    slots.push({
      type: 'ac_isolator',
      attachToUuid: this.uuid,
    })
    slots.push({
      type: 'ac_cable',
      attachToUuid: this.uuid,
    })

    if (this.microinverter) {
      // Microinverters have very small sections of dc cabling which are usually specced by the manufacturer, so calcs aren't necessary
      this.mppts().forEach((mppt) => {
        slots.push({
          type: 'ac_isolator',
          attachToUuid: mppt.uuid,
        })
        slots.push({
          type: 'ac_cable',
          attachToUuid: mppt.uuid,
        })

        mppt.strings().forEach((electricalString) => {
          slots.push({
            type: 'trunk_cable',
            attachToUuid: electricalString.uuid,
          })
        })
      })
    } else {
      this.mppts().forEach((mppt) => {
        slots.push({
          type: 'dc_isolator',
          attachToUuid: mppt.uuid,
        })
        slots.push({
          type: 'dc_cable',
          attachToUuid: mppt.uuid,
        })

        mppt.strings().forEach((electricalString) => {
          slots.push({
            type: 'dc_isolator',
            attachToUuid: electricalString.uuid,
          })
          slots.push({
            type: 'dc_cable',
            attachToUuid: electricalString.uuid,
          })
        })
      })
    }
  },
})
