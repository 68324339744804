class Hotkeys {
  static A = 'a'
  static B = 'b'
  static C = 'c'
  static D = 'd'
  static E = 'e'
  static F = 'f'
  static G = 'g'
  static H = 'h'
  static I = 'i'
  static J = 'j'
  static K = 'k'
  static L = 'l'
  static M = 'm'
  static N = 'n'
  static O = 'o'
  static P = 'p'
  static Q = 'q'
  static R = 'r'
  static S = 's'
  static T = 't'
  static U = 'u'
  static V = 'v'
  static W = 'w'
  static X = 'x'
  static Y = 'y'
  static Z = 'z'

  static ONE = '1'
  static TWO = '2'
  static THREE = '3'
  static FOUR = '4'
  static FIVE = '5'
  static SIX = '6'
  static SEVEN = '7'
  static EIGHT = '8'
  static NINE = '9'
  static ZERO = '0'

  static ESCAPE = 'Escape'

  static ARROW_UP = 'ArrowUp'
  static ARROW_DOWN = 'ArrowDown'
  static ARROW_LEFT = 'ArrowLeft'
  static ARROW_RIGHT = 'ArrowRight'

  static DELETE = 'Delete'
  static BACKSPACE = 'Backspace'

  static CTRL = 'Control'
  static SHIFT = 'Shift'
  static ALT = 'Alt'

  #ALPHA_KEYS = [
    Hotkeys.A,
    Hotkeys.B,
    Hotkeys.C,
    Hotkeys.D,
    Hotkeys.E,
    Hotkeys.F,
    Hotkeys.G,
    Hotkeys.H,
    Hotkeys.I,
    Hotkeys.J,
    Hotkeys.K,
    Hotkeys.L,
    Hotkeys.M,
    Hotkeys.N,
    Hotkeys.O,
    Hotkeys.P,
    Hotkeys.Q,
    Hotkeys.R,
    Hotkeys.S,
    Hotkeys.T,
    Hotkeys.U,
    Hotkeys.V,
    Hotkeys.W,
    Hotkeys.X,
    Hotkeys.Y,
    Hotkeys.Z,
  ]

  #ARROW_KEYS = [Hotkeys.ARROW_UP, Hotkeys.ARROW_DOWN, Hotkeys.ARROW_LEFT, Hotkeys.ARROW_RIGHT]

  #MODIFIER_COMPATIBLE_KEYS = [Hotkeys.CTRL, Hotkeys.SHIFT, Hotkeys.ALT, ...this.#ALPHA_KEYS, ...this.#ARROW_KEYS]

  #MODIFIER_BIT_FLAGS = {
    Control: 0b100,
    Shift: 0b010,
    Alt: 0b001,
  }

  // indicates whether this Hotkeys instance has attached the keydown and keyup event listeners on the document
  #hasAttached = false

  // list of hotkey bindings currently active (being held down)
  // if just ONE of the keys for a hotkey binding is released, it's removed from here
  // example: if you release the CTRL key after activating the CTRL+Shift+Z hotkey
  #activeBindings = []

  // the data structure for mapping a key (or series of keys) to a callback, called a "binding"
  // format:
  //    { 0: {}, 1: {} ... 7: {}, init: function... }
  //  the numerical keys represent the unique states of the modifier keys pressed
  //  from 0 (binary 000 : no modifiers pressed) to 7 (binary 111 : all modifiers pressed)
  #bindings = {
    init: function () {
      if (this[0b000]) return // already initialized
      const modifierComboPermCount = 0b111
      for (let i = 0; i <= modifierComboPermCount; i++) {
        this[i] = {}
      }
    },
  }

  #shouldDispatch = (_rawEvent) => {
    return true
  }

  constructor(reference) {
    this.#bindings.init()

    // Used to debug which hotkey instance is handling the event
    this.reference = reference
  }

  attach = () => {
    if (this.#hasAttached || !document) return
    document.addEventListener('keydown', this.#onKeyDown)
    document.addEventListener('keyup', this.#onKeyUp)
    this.#hasAttached = true
  }

  detach = () => {
    if (!this.#hasAttached) return
    document.removeEventListener('keydown', this.#onKeyDown)
    document.removeEventListener('keyup', this.#onKeyUp)
    this.#hasAttached = false
  }

  on = (key) => this.#createKeyChain([key])

  // In theory this should be a static method but current implementation would not support that due to
  // use of private properties in #normalizeEventKey()
  normalizeEventKey = (eventKeyraw) => this.#normalizeEventKey(eventKeyraw)

  // the function param is run every time a keydown event happens
  // if the function returns true, the keydown event is processed and the hotkeys will work
  // otherwise, key events are ignored and no hotkeys will be activated
  dispatchWhen = (dispatchFunc) => {
    this.#shouldDispatch = dispatchFunc
  }

  /////////////////////////////////////////////////////
  //       MODIFIER COMBOS SHORTHAND METHODS
  /////////////////////////////////////////////////////

  onCtrl = () => this.on(Hotkeys.CTRL)

  onShift = () => this.on(Hotkeys.SHIFT)

  onAlt = () => this.on(Hotkeys.ALT)

  onCtrlShift = () => this.on(Hotkeys.CTRL).plus(Hotkeys.SHIFT)

  onCtrlAlt = () => this.on(Hotkeys.CTRL).plus(Hotkeys.ALT)

  onShiftAlt = () => this.on(Hotkeys.SHIFT).plus(Hotkeys.ALT)

  onCtrlShiftAlt = () => this.on(Hotkeys.CTRL).plus(Hotkeys.SHIFT).plus(Hotkeys.ALT)

  //////////////////////////////////////////////////////
  //               PRIVATE METHODS
  //////////////////////////////////////////////////////

  #onKeyDown = (event) => {
    if (!this.#shouldDispatch(event)) return
    if (event.repeat) return
    if (this.#eventIsInputRelated(event)) return

    let modifierKeysStatus = 0b000
    modifierKeysStatus += event.ctrlKey || event.metaKey ? this.#MODIFIER_BIT_FLAGS['Control'] : 0b000
    modifierKeysStatus += event.shiftKey ? this.#MODIFIER_BIT_FLAGS['Shift'] : 0b000
    modifierKeysStatus += event.altKey ? this.#MODIFIER_BIT_FLAGS['Alt'] : 0b000

    const normalizedEventKey = this.#normalizeEventKey(event.key)

    const binding = this.#bindings[modifierKeysStatus][
      this.#isModifierKey(normalizedEventKey) ? 'none' : normalizedEventKey
    ]
    if (!binding) return

    if (modifierKeysStatus >= 0) event.preventDefault()

    this.#activeBindings.push(binding)
    if (binding.dispatchIf) {
      const continueDispatch = !!binding.dispatchIf(event)
      if (!continueDispatch) {
        return
      }
    }
    binding.onDown(event)
  }

  #onKeyUp = (event) => {
    if (event.repeat) return
    if (this.#eventIsInputRelated(event)) return
    if (!this.#activeBindings.length === 0) return

    const stillRelevantBindings = []
    this.#activeBindings.forEach((binding) => {
      if (binding.keys.indexOf(this.#normalizeEventKey(event.key)) >= 0) {
        binding.onUp && binding.onUp(event)
      } else {
        stillRelevantBindings.push(binding)
      }
    })

    this.#activeBindings = stillRelevantBindings
  }

  #eventIsInputRelated = (event) => {
    return ['INPUT', 'SELECT', 'OPTION', 'TEXTAREA'].indexOf(event.target.tagName) >= 0
  }

  #normalizeEventKey = (eventKey) => {
    const eventKeyLowerCase = eventKey.toLowerCase()
    if (this.#ALPHA_KEYS.indexOf(eventKeyLowerCase) >= 0) return eventKeyLowerCase
    return eventKey
  }

  #createKeyChain = (keys) => {
    const objectCtx = this

    const canBeChainedWithModifiers = (key) => objectCtx.#MODIFIER_COMPATIBLE_KEYS.indexOf(key) >= 0
    const keyAlreadyChained = (key) => keys.indexOf(key) >= 0

    const keyChain = {
      keys: keys,
      dispatchIf: function (callbackFn) {
        const keyChainCtx = this
        keyChainCtx.dispatchIfCallback = callbackFn
        return keyChainCtx
      },
      do: function (callbackFn) {
        this.callback = callbackFn
        return objectCtx.#bindKeyChain(this)
      },
      plus: function (nextKey) {
        if (keyAlreadyChained(nextKey)) throw new Error(`Hotkeys Error: ${nextKey} is a duplicate key in the keychain.`)
        if (!canBeChainedWithModifiers(nextKey))
          throw new Error(`Hotkeys Error: Key ${nextKey} cannot be combined with modifier keys.`)
        return objectCtx.#createKeyChain([...keys, nextKey])
      },
    }

    if (!objectCtx.#keyChainEndsWithModifierKey(keyChain)) delete keyChain.plus

    return keyChain
  }

  #bindKeyChain = (keyChain) => {
    const objectCtx = this
    let modifierFlags = 0b000

    let keysToParse = [...keyChain.keys]
    if (objectCtx.#keyChainEndsWithModifierKey(keyChain)) {
      keysToParse.push('none')
    }

    for (let i = 0; i < keysToParse.length; i++) {
      const key = keysToParse[i]

      if (objectCtx.#isModifierKey(key)) {
        modifierFlags += objectCtx.#MODIFIER_BIT_FLAGS[key]
        continue
      }

      if (objectCtx.#bindings[modifierFlags][key]) {
        throw `Hotkeys Error: A binding for ${keyChain.keys.join('+')} already exists.`
      }

      const binding = {
        keys: keyChain.keys,
        onDown: keyChain.callback,
        dispatchIf: keyChain.dispatchIfCallback,
      }

      objectCtx.#bindings[modifierFlags][key] = binding

      const bindingHandle = {
        keys: [...keyChain.keys],
        unbind: function () {
          delete objectCtx.#bindings[modifierFlags][key]
        },
        onReleaseDo: function (callbackFn) {
          objectCtx.#bindings[modifierFlags][key].onUp = callbackFn
          delete this.onReleaseDo
          return this
        },
        dispatchIf: function (callbackFn) {
          objectCtx.#bindings[modifierFlags][key].dispatchIf = callbackFn
          return this
        },
      }

      return bindingHandle
    }
  }

  #keyChainEndsWithModifierKey = (keyChain) => {
    return this.#isModifierKey(keyChain.keys.slice().reverse()[0])
  }

  #isModifierKey = (key) => {
    return key === Hotkeys.CTRL || key === Hotkeys.SHIFT || key === Hotkeys.ALT
  }
}
