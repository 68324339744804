class OsBoxHelper extends THREE.Group {
  object
  name = 'OsBoxHelper'
  type = 'OsBoxHelper'

  constructor(object) {
    super()
    this.object = object

    //         5____4
    //       1/___0/|
    //       | 6__|_7
    //       2/___3/

    const boxPoints = object ? this.getBoundingBoxPoints(this.getObjectBoundingBox(object)) : this.getUnitCubePoints()

    const lineMaterial = new MeshLineMaterial({
      color: new THREE.Color(0xffda00),
      transparent: true,
      opacity: 0.9,
      lineWidth: 5,
      resolution: new THREE.Vector2(600, 600), // will be updated automatically
      sizeAttenuation: 0,
      depthTest: false,
    })

    const linePointPairs = [0, 1, 1, 2, 2, 3, 3, 0, 0, 4, 4, 7, 7, 3, 4, 5, 5, 6, 5, 1, 6, 2, 7, 6]
    for (let i = 0; i < linePointPairs.length; i += 2) {
      const pointA = boxPoints[linePointPairs[i]]
      const pointB = boxPoints[linePointPairs[i + 1]]
      const lineGeometry = new MeshLine()
      lineGeometry.setPoints([pointA, pointB])
      this.add(new THREE.Mesh(lineGeometry, lineMaterial))
    }
  }

  getObjectBoundingBox = (object) => {
    const box = new THREE.Box3()
    if (object) {
      box.setFromObject(this.object)
    }
    return box
  }

  getBoundingBoxPoints = (box) => {
    if (!box) return []

    const min = box.min
    const max = box.max

    return [
      new THREE.Vector3(max.x, max.y, max.z),
      new THREE.Vector3(min.x, max.y, max.z),
      new THREE.Vector3(min.x, min.y, max.z),
      new THREE.Vector3(max.x, min.y, max.z),
      new THREE.Vector3(max.x, max.y, min.z),
      new THREE.Vector3(min.x, max.y, min.z),
      new THREE.Vector3(min.x, min.y, min.z),
      new THREE.Vector3(max.x, min.y, min.z),
    ]
  }

  getUnitCubePoints = () => {
    return [
      new THREE.Vector3(0.5, 0.5, 0.5),
      new THREE.Vector3(-0.5, 0.5, 0.5),
      new THREE.Vector3(-0.5, -0.5, 0.5),
      new THREE.Vector3(0.5, -0.5, 0.5),
      new THREE.Vector3(0.5, 0.5, -0.5),
      new THREE.Vector3(-0.5, 0.5, -0.5),
      new THREE.Vector3(-0.5, -0.5, -0.5),
      new THREE.Vector3(0.5, -0.5, -0.5),
    ]
  }

  update = () => {
    if (!this.object) return

    const box = this.getObjectBoundingBox(this.object)

    if (box.isEmpty()) return

    const boxPoints = this.getBoundingBoxPoints(box)

    //         5____4
    //       1/___0/|
    //       | 6__|_7
    //       2/___3/

    // morph the selection box geometry to match the bounding box
    // of the object currently referenced
    const linePointPairs = [0, 1, 1, 2, 2, 3, 3, 0, 0, 4, 4, 7, 7, 3, 4, 5, 5, 6, 5, 1, 6, 2, 7, 6]
    for (let p = 0, e = 0; p < linePointPairs.length; p += 2, e++) {
      const pointA = boxPoints[linePointPairs[p]]
      const pointB = boxPoints[linePointPairs[p + 1]]
      this.children[e].geometry.setPoints([pointA, pointB])
    }
  }

  setFromObject = (object) => {
    this.object = object
    this.update()
  }

  // not actually being used anywhere, but whatever...
  clone = () => {
    return new OsBoxHelper(this.object)
  }
}
