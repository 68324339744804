/*
This command should be renamed to UpdateSceneOriginForMapDataCommand to make it clear that it operates
on a particular map instance.

It should also be modified to remove references to MapHelper.activeMapInstance because this requires
activeMapInstance to reference the correct mapInstance/mapData which adds a lot of complexity.
Perhaps it could locate the relevant mapInstance/mapData by specifying a viewUuid instead?
*/
var UpdateSceneOriginCommand = function (newSceneOrigin, oldSceneOrigin, MapHelper) {
  Command.call(this)
  this.type = 'UpdateSceneOriginCommand'
  this.name = 'Update Scene Origin Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['newSceneOrigin', 'oldSceneOrigin']

  this.MapHelper = MapHelper || window.MapHelper

  if (arguments.length) {
    this.newSceneOrigin = newSceneOrigin
    this.oldSceneOrigin = oldSceneOrigin
  }
}

UpdateSceneOriginCommand.prototype = {
  execute: function () {
    this.MapHelper.activeMapInstance.mapData._sceneOrigin(this.newSceneOrigin)
    MapHelper.setMapPositionFromWorldPositionAtViewportCenter()
    window.ViewHelper.saveView()
  },

  undo: function () {
    this.MapHelper.activeMapInstance.mapData._sceneOrigin(this.oldSceneOrigin)
    MapHelper.setMapPositionFromWorldPositionAtViewportCenter()
    window.ViewHelper.saveView()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
