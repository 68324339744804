/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var SystemClearElectricalsCommand = function(system, moduleGrids) {
  Command.call(this)

  this.type = 'SystemClearElectricalsCommand'
  this.name = 'System Clear Electricals Command'
  this.system = system
  this.moduleGrids = moduleGrids
  this.deletedModuleGrids = []
  this.timeStamp = Date.now()
  this.removedElectricals = []
  this.commandUUID = window.globalCommandUUID
}

SystemClearElectricalsCommand.prototype = {
  execute: function() {
    var deletedStrings = this.system.getStrings()
    for (var i = 0; i < deletedStrings.length; i++) {
      deletedStrings[i].clearStringModuleReference(this.editor)
    }
    if (this.moduleGrids) {
      this.system.clearModules(this.editor)
    }
    this.removedElectricals = this.system.clearElectricals(this.editor)
  },

  undo: function() {
    if (this.loadFromJSON) {
      var loader = new THREE.ObjectLoader()
      this.system = this.system && this.editor.scene.getObjectByProperty('uuid', this.system)
      this.moduleGrids = this.moduleGrids && this.editor.scene.getObjectByProperty('uuid', this.moduleGrids)
      var removedElectricalsArray = []
      for (var i = 0; i < this.removedElectricals.length; i++) {
        var electrical = loader.parse(this.removedElectricals[i])
        electrical.applyUserData()
        removedElectricalsArray.push(electrical)
      }
      this.removedElectricals = removedElectricalsArray
      this.loadFromJSON = false
    }
    for (var i = 0; i < this.removedElectricals.length; i++) {
      this.editor.addObject(this.removedElectricals[i], this.system)
      this.removedElectricals[i].traverse(function(child) {
        if (child.applyUserData) child.applyUserData()
      })
    }
    if (this.moduleGrids) {
      this.moduleGrids.forEach(
        function(moduleGrid) {
          this.system.children.splice(0, 0, moduleGrid)
          moduleGrid.parent = this.system
          moduleGrid.applyUserData()
        }.bind(this)
      )
    }
    this.editor.signals.objectChanged.dispatch(this.system, 'output')
  },

  toJSON: function() {
    if (this.loadFromJSON) {
      return Command.prototype.toObject.call(this)
    }
    var output = Command.prototype.toJSON.call(this)

    var removedElectricals = []
    for (var i = 0; i < this.removedElectricals.length; i++) {
      removedElectricals.push(this.removedElectricals[i].toJSON())
    }
    output.removedElectricals = removedElectricals
    output.system = this.system && this.system.uuid
    output.moduleGrids = this.moduleGrids && this.moduleGrids.uuid
    output.select = this.select
    return output
  },

  fromJSON: function(parsedCmd) {
    Command.prototype.fromJSON.call(this, parsedCmd)

    this.removedElectricals = parsedCmd.removedElectricals
    this.system = parsedCmd.system
    this.moduleGrids = parsedCmd.moduleGrids
    this.select = parsedCmd.select
  },
}
