window.pluginAlpha = {
  id: 6000,
  countryCodes: ['US'],
  componentCodes: ['ALPHA-ESS-BATT-EMS'],
  name: 'pluginAlpha',
  plugin: function () {
    function upperCaseSafe(value) {
      return value && value.toUpperCase ? value.toUpperCase() : value
    }

    var refreshDebouncedInner = Utils.debounce((system) => refresh(system), 1000)
    var refreshDebounced = function (system) {
      //system is required
      if (!system?.uuid) return
      refreshDebouncedInner(system)
    }

    function indexOfCaseInsensitive(valuesArray, value) {
      var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))

      return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
    }

    // This is a list of codes that are restricted to a certain number of batteries
    // Codes must be uppercase here

    var batteryArray = ['ALPHA-ESS-BATT-EMS']

    function updateWarningsBatteries(system, isActive) {
      var message
      var maxBatteries = 6
      if (system.batteries().length > maxBatteries && isActive) {
        system.batteries().forEach(function (battery) {
          if (upperCaseSafe(battery.code) === 'ALPHA-ESS-BATT-EMS') {
            message =
              'To ensure that your design is valid, please do not use more than 6 ALPHA-ESS-BATT-EMS batteries at once.'
          }
        })
      }
      var messageKey = 'ALPHA-BATTERY-CHECK'
      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function systemHasComponent(system, codes) {
      return countComponentsForSystem(system, codes) > 0
    }

    function setSystemMessage(system, message, messageKey, messageType, category = 'system') {
      const severity = messageType || 'warning'
      if (message) {
        window.WorkspaceHelper?.addProjectErrorToReduxStore({
          message,
          key: messageKey,
          severity,
          systemId: system.uuid,
          source: 'plugin',
          category,
        })
      } else {
        window.WorkspaceHelper?.removeProjectErrorFromReduxStore(messageKey, system.uuid, 'plugin')
      }
    }

    function countComponentsForSystem(system, codes) {
      return system.componentCodes().filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
    }

    function refresh(system) {
      if (window.WorkspaceHelper.getIsProjectLite()) {
        // Don't run on Lite projects
        return
      }

      if (system.isDuplicating) {
        // skip refresh during duplication because we do not want to trigger signals
        // for each child added to the system
        return
      }

      if (system) {
        var isActive = systemHasComponent(system, batteryArray)

        updateWarningsBatteries(system, isActive)
      }
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refreshDebounced(system)
        }
      }
    }

    function objectAdded(object) {
      if (window.studioDebug) {
        console.log('Alpha objectAdded', object)
      }
      refreshSystemFromObject(object)
    }

    function objectChanged(object) {
      if (window.studioDebug) {
        console.log('Alpha objectChanged', object)
      }
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (window.studioDebug) {
        console.log('Alpha objectRemoved', object)
      }
      if (object?.type === 'OsSystem') {
        const filter = (error) => error.systemId === object.uuid
        window.WorkspaceHelper?.clearProjectError(filter)
      } else {
        refreshSystemFromObject(object)
      }
    }

    function sceneGraphChanged(system) {
      if (window.studioDebug) {
        console.log('Alpha sceneGraphChanged')
      }
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      if (window.studioDebug) {
        console.log('Alpha pluginLoaded')
      }
      refreshAllSystems()
    }

    function pluginUnloaded() {
      if (window.studioDebug) {
        console.log('Alpha pluginUnloaded')
      }
      refreshAllSystems()
    }

    return {
      objectAdded,
      objectChanged,
      objectRemoved,
      sceneGraphChanged,
      liteProjectUpgraded,
      pluginLoaded,
      pluginUnloaded,
    }
  },
}
