/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var StringModuleArrayAssignmentCommand = function (string, modulesArray) {
  Command.call(this)

  this.type = 'StringModuleArrayAssignmentCommand'
  this.name = 'String Module Array Assignment Comannd'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.OBJECT_REFERENCES = ['string']

  this.modulesAlreadyAdded = []

  if (arguments.length) {
    this.string = string
    this.modulesArray = modulesArray || []
  }
}

StringModuleArrayAssignmentCommand.prototype = {
  execute: function () {
    this.editor.signals.sceneGraphChanged.active = false
    var isObjectChanged = false

    for (var i = 0; i < this.modulesArray.length; i++) {
      var moduleWasAdded = this.string.addModule(this.modulesArray[i])
      if (moduleWasAdded) {
        isObjectChanged = true
      } else {
        this.modulesAlreadyAdded.push(this.modulesArray[i])
      }
    }
    this.string.refreshUserData()
    if (isObjectChanged) {
      this.editor.signals.objectChanged.dispatch(this.string)
    }
    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    this.editor.signals.sceneGraphChanged.active = false
    var isObjectChanged = false

    for (var i = 0; i < this.modulesArray.length; i++) {
      if (!this.modulesAlreadyAdded.includes(this.modulesArray[i])) {
        this.string.removeModule(this.modulesArray[i])
        isObjectChanged = true
      }
    }

    this.string.refreshUserData()
    if (isObjectChanged) {
      this.editor.signals.objectChanged.dispatch(this.string)
    }
    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    this.modulesArrayUuids = this.modulesArray.map((m) => m.uuid)
    this.modulesAlreadyAddedUuids = this.modulesAlreadyAdded.map((m) => m.uuid)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.modulesArray = json.modulesArrayUuids.map((uuid) => this.editor.objectByUuid(uuid))
    this.modulesAlreadyAdded = json.modulesAlreadyAddedUuids.map((uuid) => this.editor.objectByUuid(uuid))
  },
}
