/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var NodeMergeCommand = function (survivingNode, removedNodes) {
  Command.call(this)
  this.type = 'NodeMergeCommand'
  this.name = 'Node Merge Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.UNDO_NOT_SUPPORTED = true

  if (arguments.length) {
    this.object = survivingNode
    this.removedNodes = removedNodes
  }
}

NodeMergeCommand.prototype = {
  execute: function () {
    var newSelection = null

    this.removedNodes.forEach(function (removedNode) {
      removedNode.fuseAndDispose(this.object)
      this.editor.removeObject(removedNode)
    }, this)

    this.editor.signals.objectChanged.dispatch(this.object)
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    /*
    We could easily undo simple merging of two isolated notes which do not belong to any facets or edges, but if they
    belong to facets and/or edges it gets super complicated so until this is propertly implemented, we will simply
    disable undo for this command. Saved replays should still work, so long as they are only played forwards because
    that only requires toJSON() and execute() to be implemented.
    */
    throw new Error('Undo not implemented for Merge Node')

    this.removedNodes.forEach(function (removedNode) {
      this.editor.addObject(removedNode)
      this.editor.signals.objectAdded.dispatch(removedNode)
    })

    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    output.objectUuid = this.object.uuid
    var removedNodesAsJson = []
    this.removedNodes.forEach(function (node) {
      removedNodesAsJson.push(node.toJSON())
    })
    output.removedNodesAsJson = removedNodesAsJson
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.object = json.objectUuid
    this.removedNodes = []
    json.removedNodesAsJson.forEach((removedNodeAsJson) => {
      var removedNode = this.editor.objectByUuid(removedNodeAsJson.object.uuid)
      if (!removedNode) {
        var loader = new THREE.ObjectLoader()
        removedNode = loader.parse(removedNodeAsJson)
        this.removedNodes.push(removedNode)
      }
    })
  },
}
