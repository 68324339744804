/**
 * Manages side effects for components in the Editor
 * Handles registration, activation, and execution of effects based on component lifecycle events.
 * Note: This is a base class and should not be used directly.
 *
 * Current implementations:
 * - BatterySideEffects.js
 * - InverterSideEffects.js
 *
 * Current Signals Supported:
 * - objectAdded
 * - objectRemoved
 * - objectChanged
 * - sceneLoaded
 *
 * See BatterySideEffects for example of implementation
 */
const ComponentSideEffects = {
  /**
   * Creates a new component side effects manager
   * @param {Editor} editorInstance - The Editor instance to attach effects to
   * @param {string} componentType - The type of component these effects apply to
   * @param {Object} config - Configuration object for the side effects
   * @param {Array} [config.signals] - Signal-handler pairs to register
   * @param {Array} [config.effects] - Effects to register
   *
   * Function to validate component types...
   * This function is used to ensure the component is valid for these effects.
   * E.g, InverterSideEffects.js only applies to Inverter components.
   *
   * @param {Function} config.isValidComponentType
   * @returns {Object}
   */
  create(editorInstance, componentType, config) {
    if (!(editorInstance instanceof Editor)) {
      throw new Error(`Cannot create side effects: invalid Editor instance.`)
    }

    let isActive = false
    let cleanup = []
    const effects = new Map()
    const processingObjects = new Set()
    const MAX_RECURSION_DEPTH = 5
    let recursionDepth = 0

    // Handler for registering effects from config
    if (config.effects) {
      config.effects.forEach((effect, key) => {
        effects.set(key, effect)
      })
    }

    /**
     * Activates all registered side effects and signal handlers
     *
     * Side effects are defined in the config object passed into the create function config.effects
     */
    function activate() {
      if (isActive) return

      // Use the signals that were set after creation
      const signalsToUse = this.signals || config.signals || []
      signalsToUse.forEach(([signal, handler]) => {
        editorInstance.signals[signal].add(handler)
        cleanup.push(() => editorInstance.signals[signal].remove(handler))
      })

      isActive = true
    }

    /**
     * Deactivates all side effects and cleans up signal handlers
     *
     * It is essential that all component side effects are dormant when deactivated (No programmatic overhead)
     */
    function deactivate() {
      if (!isActive) return
      cleanup.forEach((cleanup) => cleanup())
      cleanup = []
      isActive = false
    }

    /**
     * Handles when a new object is added to the system
     * @param {Object} object - The object that was added
     */
    function handleObjectAdded(object) {
      if (!config.isValidComponentType(object)) return
      const system = getSystemFromComponent(object)

      effects.forEach((effect) => {
        if (effect.triggers.includes('add')) {
          effect.handle(system, object)
        }
      })
    }

    /**
     * Handles when an object is removed from the system
     * @param {Object} object - The object that was added
     */
    function handleObjectRemoved(object) {
      if (!config.isValidComponentType(object)) return

      const system = object.parentBeforeRemoval

      if (!system) {
        console.warn('No system found for removed object')
        return
      }

      effects.forEach((effect) => {
        if (effect.triggers.includes('remove')) {
          effect.handle(system, object)
        }
      })
    }

    /**
     * Handles when an existing object is changed
     *
     * For example, when you change your inverter rather than adding a new one
     * @param {Object} object - The object that was changed
     */
    function handleObjectChanged(object) {
      if (processingObjects.has(object.uuid)) {
        console.debug(`Preventing recursive processing of object ${object.uuid}`)
        return
      }

      if (recursionDepth >= MAX_RECURSION_DEPTH) {
        console.warn(`Max recursion depth reached for object ${object.uuid}`)
        return
      }

      try {
        // Track that we're processing this object
        processingObjects.add(object.uuid)
        recursionDepth++

        if (!config.isValidComponentType(object)) return
        const system = getSystemFromComponent(object)

        effects.forEach((effect) => {
          if (effect.triggers.includes('change')) {
            effect.handle(system, object)
          }
        })
      } finally {
        // Cleanup tracking
        processingObjects.delete(object.uuid)
        recursionDepth--
      }
    }

    /**
     * Handles when the scene is loaded
     * Runs effects with 'load' trigger for all relevant systems
     */
    function handleSceneLoaded() {
      const systems = editorInstance.getSystems()
      systems.forEach((system) => {
        effects.forEach((effect) => {
          if (effect.triggers.includes('load')) {
            effect.handle(system)
          }
        })
      })
    }

    /**
     * Gets the system associated with a given component
     * @param {Object} object - The component object
     * @returns {Object} The associated system
     * @throws {Error} If no system is found for the component ->
     * This means something went catastrophically wrong, Sentry should be aware of this.
     */
    function getSystemFromComponent(object) {
      const system = object.getSystem()
      if (!system) {
        throw new Error(`${componentType} has no associated system`)
      }
      return system
    }

    return {
      activate,
      deactivate,
      handleObjectAdded,
      handleObjectChanged,
      handleObjectRemoved,
      handleSceneLoaded,
      getSystemFromComponent,
      signals: [],
    }
  },
}
