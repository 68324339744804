/**
 * @author adampryor
 */

var OsObstructionCache = {}

function OsObstruction(options) {
  THREE.Mesh.call(this)

  if (!OsObstructionCache.geometry) {
    OsObstructionCache.geometry = {
      sphere: new THREE.SphereBufferGeometry(0.5).translate(0, 0, 0.25),
      cube: new THREE.BoxBufferGeometry(0.5, 0.5, 0.5).translate(0, 0, 0.25),
      cylinder: new THREE.CylinderBufferGeometry(0.5, 0.5, 0.5, 12).rotateX(-Math.PI * 0.5).translate(0, 0, 0.25),
    }
    OsObstructionCache.geometry.sphere.excludeFromExport = true
    OsObstructionCache.geometry.cube.excludeFromExport = true
    OsObstructionCache.geometry.cylinder.excludeFromExport = true
  }

  if (!OsObstructionCache.material) {
    OsObstructionCache.material = new THREE.MeshStandardMaterial({
      color: 0xff0000,
      opacity: 0.5,
      transparent: true,
    })
    OsObstructionCache.materialOnSelect = new THREE.MeshStandardMaterial({
      color: 0xff9c00,
      opacity: 0.7,
      transparent: true,
    })
    OsObstructionCache.materialGhostMode = new THREE.MeshStandardMaterial({
      color: 0xff0000,
      opacity: 0.3,
      transparent: true,
    })
  }
  //do we need this???
  this.override_show_customer =
    options && options.userData && typeof options.userData.override_show_customer === 'boolean'
      ? options.userData.override_show_customer
      : null
  this._shapeType = null
  this.shapeType(options && options?.shapeType ? options.shapeType : 'sphere')
  //this.geometry = OsObstructionCache.geometry;
  this.obstructionType = options?.obstructionType ? options.obstructionType : null

  this.material = OsObstructionCache.material

  this.castShadow = true

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsObstruction'
  this.name = 'OsObstruction'

  this._ghostMode = options && options.hasOwnProperty('ghostMode') ? options.ghostMode : false

  this.floatAppliesOrientation = false

  this.lookAt(new THREE.Vector3(0, 0, 1000))

  if (options?.scale) {
    this.scale.copy(options.scale)
  } else if (this.obstructionType) {
    this.applyGeometryAndScaleForObstructionType(this.obstructionType)
  }
}

OsObstruction.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsObstruction,
  getName: function () {
    return 'Obstruction'
  },
  toolsActive: function () {
    return {
      translateXY: true,
      translateZ: true,
      translateX: false,
      rotate: true,
      scaleXY: true,
      scaleZ: true,
      scale: true, //legacy
    }
  },
  transformWithLocalCoordinates: function () {
    return true
  },
  belongsToGroup: ObjectBehaviors.belongsToGroup,
  onChange: ObjectBehaviors.floatingOnFacetOnChange,
  onRemove: function (editor) {
    if (this.facet) {
      this.facet.removeFloatingObject(this)
    }
  },
  toShapeUnprojected: function () {
    const gf = new jsts.geom.GeometryFactory();
    const scale = this.scale || new THREE.Vector3(1, 1, 1);
    const rotation = this.rotation ? this.rotation.z : 0; // Assuming rotation around the Z-axis
    const center = this.position;

    if (this.shapeType() === 'sphere' || this.shapeType() === 'cylinder') {
      // Create an elliptical shape directly with scale and rotation applied
      const radiusX = scale.x / 2;
      const radiusY = scale.y / 2;
      const numSegments = 32; // Increase for a smoother ellipse
      const angleIncrement = (2 * Math.PI) / numSegments;
      let coordinates = [];

      for (let i = 0; i < numSegments; i++) {
        const angle = i * angleIncrement;
        const x = radiusX * Math.cos(angle);
        const y = radiusY * Math.sin(angle);

        // Apply rotation around the Z-axis
        const rotatedX = x * Math.cos(rotation) - y * Math.sin(rotation);
        const rotatedY = x * Math.sin(rotation) + y * Math.cos(rotation);

        coordinates.push([center.x + rotatedX, center.y + rotatedY]);
      }
      coordinates.push(coordinates[0]); // Close the loop

      const jstsCoordinates = coordinates.map(pt => new jsts.geom.Coordinate(pt[0], pt[1]));
      const linearRing = gf.createLinearRing(jstsCoordinates);
      return gf.createPolygon(linearRing, []);

    } else {
      // Create a rotated rectangle (for cube or other shapes) directly from the local scale and rotation
      const halfWidth = scale.x / 2;
      const halfHeight = scale.y / 2;

      const corners = [
        new THREE.Vector3(halfWidth, halfHeight, 0),
        new THREE.Vector3(halfWidth, -halfHeight, 0),
        new THREE.Vector3(-halfWidth, -halfHeight, 0),
        new THREE.Vector3(-halfWidth, halfHeight, 0),
        new THREE.Vector3(halfWidth, halfHeight, 0) // Close the shape
      ].map(corner => {
        // Apply rotation around the Z-axis
        const rotatedX = corner.x * Math.cos(rotation) - corner.y * Math.sin(rotation);
        const rotatedY = corner.x * Math.sin(rotation) + corner.y * Math.cos(rotation);

        // Translate to the obstruction's position
        return new THREE.Vector2(center.x + rotatedX, center.y + rotatedY);
      });

      const jstsCoordinates = corners.map(pt => new jsts.geom.Coordinate(pt.x, pt.y));
      const linearRing = gf.createLinearRing(jstsCoordinates);
      return gf.createPolygon(linearRing, []);
    }
  },
  toGeoJSONFeature: function (sceneOrigin4326, toEpsg) {
    if (!toEpsg) toEpsg = '4326'

    //@todo: Since 3857 is kinda dodgy, we should probably use something different
    //for adding scene positions in meters to scene origin
    //e.g. UTM? Annoying with different zones...

    var sceneOrigin3857 = ol.proj.transform([sceneOrigin4326[0], sceneOrigin4326[1]], 'EPSG:4326', 'EPSG:3857')

    var reprojectCoordinate = function (coordinate) {
      return ol.proj.transform([coordinate[0], coordinate[1]], 'EPSG:3857', 'EPSG:' + toEpsg)
    }

    var boundingBox = new THREE.Box3().setFromObject(this) //in world coordinates, not local coordinates

    var coordinates = [
      reprojectCoordinate([boundingBox.min.x + sceneOrigin3857[0], boundingBox.min.y + sceneOrigin3857[1]]),
      reprojectCoordinate([boundingBox.max.x + sceneOrigin3857[0], boundingBox.min.y + sceneOrigin3857[1]]),
      reprojectCoordinate([boundingBox.max.x + sceneOrigin3857[0], boundingBox.max.y + sceneOrigin3857[1]]),
      reprojectCoordinate([boundingBox.min.x + sceneOrigin3857[0], boundingBox.max.y + sceneOrigin3857[1]]),
    ]
    coordinates.push(coordinates[0]) //complete ring by adding first coordinate to end

    /*
    this.geometry.vertices.forEach(function(v) {
            this.push(reprojectCoordinate([v.position.x + sceneOrigin3857[0], node.position.y + sceneOrigin3857[1]]));
        }, coordinates);
        coordinates.push(coordinates[0]); //complete ring by adding first coordinate to end
    */
    return {
      crs: {
        type: 'name',
        properties: {
          name: 'urn:ogc:def:crs:EPSG::' + toEpsg,
        },
      },
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
    }
  },
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,
  applyGhostMode: function (value) {
    if (value) {
      this.material = OsObstructionCache.materialGhostMode
    } else {
      this.material = OsObstructionCache.material
    }
  },

  shapeType: function (value) {
    if (typeof value === 'undefined') {
      return this._shapeType
    }

    if (this._shapeType != value) {
      //update
      this.geometry = OsObstructionCache.geometry[value]
      this._shapeType = value
      this.refreshUserData()
    }
  },

  setObstructionType: function (obstructionType) {

    var oldScale = this.scale.clone()
    var obstructionDefinition = OsObstruction.OBSTRUCTION_TYPES.find((obstruction) => obstruction.type === obstructionType)
    this.applyGeometryAndScaleForObstructionType(obstructionType)

    let commandUUID = Utils.generateCommandUUIDOrUseGlobal()

    editor.execute(new SetObjectTypeCommand(this, 'shapeType', obstructionDefinition.shape, commandUUID))
    editor.execute(new SetValueCommand(this, 'obstructionType', obstructionType, commandUUID))
    editor.execute(new SetScaleCommand(this, this.scale, oldScale, commandUUID))
  },

  applyGeometryAndScaleForObstructionType: function (obstructionType) {
    var obstructionDefinition = OsObstruction.OBSTRUCTION_TYPES.find((obstruction) => obstruction.type === obstructionType)

    this.shapeType(obstructionDefinition.shape)

    var shapeGeometry = OsObstructionCache.geometry[obstructionDefinition.shape]
    if (!shapeGeometry.boundingBox) {
      shapeGeometry.computeBoundingBox()
    }
    var shapeGeometrySize = shapeGeometry.boundingBox.getSize(new THREE.Vector3())
    var scale = new THREE.Vector3(
      obstructionDefinition.size.x / shapeGeometrySize.x,
      obstructionDefinition.size.y / shapeGeometrySize.y,
      obstructionDefinition.size.z / shapeGeometrySize.z
    )
    this.scale.copy(scale)
  },

  getContextMenuItems: function (position) {
    var _this = this

    var menuItems = Object.keys(OsObstructionCache.geometry).map(function (key) {
      return {
        label: window.translate('Shape') + ': ' + window.translate(key),
        onClick: function () {
          editor.execute(new SetObjectTypeCommand(_this, 'shapeType', key))
          editor.execute(new SetValueCommand(_this, 'obstructionType', null))
        },
      }
    })

    // Add presets. This is experimental so leave the regular shape types above
    OsObstruction.OBSTRUCTION_TYPES.forEach(function (obstructionDefinition) {
      var obstructionType = obstructionDefinition.type
      var hotkeyIfAvailable = OsObstruction.obstructionTypeToHotkey[obstructionType] ? '<span style="min-width: 18px; height: 18px; display: inline-block; line-height: 18px; border: 1px solid #929292; color: #929292; text-align: center;">⇧</span><span style="margin-left: 3px; min-width: 18px; height: 18px; display: inline-block; line-height: 18px; border: 1px solid #929292; color: #929292; text-align: center;">' + OsObstruction.obstructionTypeToHotkey[obstructionType] + '</span>' : ''

      menuItems.push({
        label: `Type: ${obstructionDefinition.title} ${hotkeyIfAvailable}`,
        useHTML: true,
        selected: obstructionType == _this.obstructionType,
        onClick: function () {
          _this.setObstructionType(obstructionDefinition.type)
        },
      })

    })

    menuItems.push({
      label: window.translate(`Select ${this.getName()}`),
      useHTML: false,
      selected: false,
      onClick: function () {
        if (editor) {
          editor.select(_this)
        }
      },
    })

    return menuItems
  },

  onSelect: function () {
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    var newMaterial = isSelected ? OsObstructionCache.materialOnSelect : OsObstructionCache.material
    if (this.material != newMaterial) {
      this.material = newMaterial
    }
  },

  onDeselect: function () {
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    var newMaterial = isSelected ? OsObstructionCache.materialOnSelect : OsObstructionCache.material
    if (this.material != newMaterial) {
      this.material = newMaterial
    }
  },

  applyUserData: function () {
    // we must set obstructionType before calling shapeType() due to a quirk that would otherwise reset userData before we
    // have a chance to apply this.userData.obstructionType
    this.obstructionType = this.userData.obstructionType
    this.shapeType(this.userData.shapeType)
    this.override_show_customer =
      typeof this.userData.override_show_customer === 'boolean' ? this.userData.override_show_customer : null
  },

  refreshUserData: function () {
    this.userData.shapeType = this.shapeType()
    this.userData.override_show_customer =
      typeof this.override_show_customer === 'boolean' ? this.override_show_customer : null
    this.userData.obstructionType = this.obstructionType
  },

  duplicate: function (options) {
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    var newObstruction = new OsObstruction()
    newObstruction.userData = JSON.parse(JSON.stringify(this.userData))
    newObstruction.applyUserData()
    newObstruction.position.copy(this.position).add(positionOffset)
    newObstruction.scale.copy(this.scale)
    newObstruction.rotation.copy(this.rotation)
    editor.execute(new AddObjectCommand(newObstruction, null, true))
  },
})

/**
 * size is set in meters, but since geometry is different for each shape it is not copied directly into scale
 */
OsObstruction.OBSTRUCTION_TYPES = [
  {
    // Plumbing pipe
    type: 'pipe',
    title: 'Pipe',
    shape: 'cylinder',
    size: new THREE.Vector3(0.3, 0.3, 0.3),
    hotkey: 'P',
  },
  {
    // Box vent, rectangular, low-profile
    type: 'boxvent',
    title: 'Box Vent',
    shape: 'cube',
    size: new THREE.Vector3(0.6, 0.4, 0.3),
    hotkey: 'B',
  },
  {
    // Powered fan-vent, cylindrical, low-profile
    type: 'fanvent',
    title: 'Fan Vent',
    shape: 'cylinder',
    size: new THREE.Vector3(0.5, 0.5, 0.3),
    hotkey: 'F',
  },
  {
    // Whirlybird cylindrical vent
    type: 'turbine',
    title: 'Turbine Vent',
    shape: 'cylinder',
    size: new THREE.Vector3(0.6, 0.6, 0.6),
    hotkey: 'T',
  },
  {
    // Chimney, rectangular
    type: 'chimney',
    title: 'Chimney',
    shape: 'cube',
    size: new THREE.Vector3(1.5, 1.0, 1),
    hotkey: 'C',
  },
  {
    // Skylight, rectangular
    type: 'skylight',
    title: 'Skylight',
    shape: 'cube',
    size: new THREE.Vector3(0.6, 0.6, 0.3),
    hotkey: 'S',
  },
  {
    // AC Unit
    type: 'hvac',
    title: 'HVAC Unit',
    shape: 'cube',
    size: new THREE.Vector3(2.0, 1.0, 1.0),
    hotkey: 'H',
  },
  {
    // Any other obstruction that is non-buildable
    type: 'misc',
    title: 'Miscellaneous',
    shape: 'cylinder',
    size: new THREE.Vector3(1.0, 1.0, 0.3),
    hotkey: 'M',
  },
]

OsObstruction.hotkeyToObstructionType = (hotkeyString) => {
  return OsObstruction.OBSTRUCTION_TYPES.find((obstructionType) => obstructionType.hotkey === hotkeyString)?.type
}

OsObstruction.obstructionTypeToHotkey = {}
OsObstruction.OBSTRUCTION_TYPES.forEach((obstructionType) => {
  OsObstruction.obstructionTypeToHotkey[obstructionType.type] = obstructionType.hotkey
})
