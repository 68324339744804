/**
 * Base class for System-level side effects
 * Extends ComponentSideEffects with system-specific property tracking
 *
 * Current implementations:
 * - SystemSideEffects.js
 */
const SystemSideEffectsBase = {
  /**
   * Creates a new component side effects manager
   * @param {Editor} editorInstance - The Editor instance to attach effects to
   * @param {string} componentType - The type of component these effects apply to
   * @param {Object} config - Configuration object for the side effects
   * @param {Array} [config.signals] - Signal-handler pairs to register
   * @param {Array} [config.effects] - Effects to register
   *
   * Function to validate component types...
   * This function is used to ensure the component is valid for these effects.
   * E.g, InverterSideEffects.js only applies to Inverter components.
   *
   * @param {Function} config.isValidComponentType
   * @returns {Object}
   */
  create(editorInstance, componentType, config) {
    if (!(editorInstance instanceof Editor)) {
      throw new Error(`Cannot create side effects: invalid Editor instance.`)
    }

    let isUndoInProgress = false
    let isActive = false
    let cleanup = []
    const effects = new Map()

    // Handler for registering effects from config
    if (config.effects) {
      config.effects.forEach((effect, key) => {
        effects.set(key, effect)
      })
    }

    const setUndoInProgress = (value) => {
      isUndoInProgress = value
    }

    /**
     * Activates all registered side effects and signal handlers
     *
     * Side effects are defined in the config object passed into the create function config.effects
     */
    function activate() {
      if (isActive) return

      // Use the signals that were set after creation
      const signalsToUse = this.signals || config.signals || []
      signalsToUse.forEach(([signal, handler]) => {
        editorInstance.signals[signal].add(handler)
        cleanup.push(() => editorInstance.signals[signal].remove(handler))
      })

      isActive = true
    }

    /**
     * Deactivates all side effects and cleans up signal handlers
     *
     * It is essential that all component side effects are dormant when deactivated (No programmatic overhead)
     */
    function deactivate() {
      if (!isActive) return
      cleanup.forEach((cleanup) => cleanup())
      cleanup = []
      isActive = false
    }

    /**
     * Handles when a new object is added to the system
     * @param {Object} object - The object that was added
     */
    function handleObjectAdded(object) {
      if (!config.isValidComponentType(object)) return
      const system = object

      effects.forEach((effect) => {
        if (effect.triggers.includes('add')) {
          effect.handle(system, object)
        }
      })
    }

    /**
     * Handles when an existing System is changed, this will trigger on any system change
     * This is why the state map is needed
     *
     * @param {Object} object - The object that was changed
     */
    function handleObjectChanged(object, prop) {
      if (!config.isValidComponentType(object)) return
      const system = object
      if (!isUndoInProgress) {
        effects.forEach((effect) => {
          if (effect.triggers.includes(`change.${prop}`)) {
            effect.handle(system, object)
          }
        })
      }
    }

    /**
     * Handles when the scene is loaded
     * Runs effects with 'load' trigger for all relevant systems
     */
    function handleSceneLoaded() {
      const systems = editorInstance.getSystems()
      systems.forEach((system) => {
        effects.forEach((effect) => {
          if (effect.triggers.includes('load')) {
            effect.handle(system)
          }
        })
      })
    }

    return {
      activate,
      deactivate,
      handleObjectAdded,
      handleObjectChanged,
      handleSceneLoaded,
      setUndoInProgress,
      signals: [],
    }
  },
}
