/**
 * @author adampryor
 */

var OsHouseCache = {}

function OsHouse() {
  THREE.Mesh.call(this)

  if (!OsHouseCache.geometry) {
    OsHouseCache.geometry = {
      sphere: new THREE.SphereBufferGeometry(1.0),
    }
  }

  if (!OsHouseCache.material) {
    OsHouseCache.material = new THREE.MeshStandardMaterial({
      color: 0x666699,
      transparent: false,
      roughness: 0.1,
      shading: THREE.FlatShading,
    })
    // OsHouseCache.materialGhostMode = new THREE.MeshStandardMaterial({opacity: 0.3, transparent: true});
  }

  this._shapeType = null
  this.shapeType('sphere')

  this.geometry = OsHouseCache.geometry[this._shapeType]

  //this.material = OsHouseCache.material;

  //Let the model cast the shadown, not the sphere geometry
  this.castShadow = false

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsHouse'
  this.name = 'OsHouse'

  this._ghostMode = false

  //this.lookAt(new THREE.Vector3(0,0,1000))
  this.model = null

  this.refreshModel()

  var _this = this

  var useModel = true

  if (useModel) {
    var manager = new THREE.LoadingManager()
    manager.onProgress = function (item, loaded, total) {
      console.log(item, loaded, total)
    }

    // var textureLoader = new THREE.TextureLoader( manager );
    // var texture = textureLoader.load( './img/UV_Grid_Sm.jpg' );

    // model
    var onProgress = function (xhr) {
      if (xhr.lengthComputable) {
        var percentComplete = (xhr.loaded / xhr.total) * 100
        console.log(Math.round(percentComplete, 2) + '% downloaded')
      }
    }
    var onError = function (xhr) {}
    var loader = new OBJLoader(manager)
    loader.load(
      window.Designer.prepareFilePathForLoad('/House.obj'),
      function (object) {
        object.userData.excludeFromExport = true
        object.selectionDelegate = this

        object.castShadow = true
        object.children[0].castShadow = true

        //Hack to make Z up for tre model
        // object.children[0].geometry.rotateX(Math.PI/2);
        // object.children[0].geometry.verticesNeedUpdate = true;

        object.traverse(function (child) {
          if (child instanceof THREE.Mesh) {
            //child.material.map = texture;
            // child.material = new THREE.MeshStandardMaterial({color:0xCCCCFF});
            child.material = OsHouseCache.material
            child.userData.excludeFromExport = true
            child.selectionDelegate = _this
          }
        })

        var box = new THREE.Box3().setFromObject(object)
        var size = box.getSize(new THREE.Vector3())

        object.position.fromArray([0, 0, 0])

        //not sure why X and Y are out by factor of 2...
        var scale = 4
        object.scale.fromArray([(scale * 2) / size.x, (scale * 2) / size.y, (scale * 1) / size.z])

        _this.add(object)
        _this.model = object
        _this.refreshModel()

        if (editor) {
          editor.signals.geometryChanged.dispatch()
        }
      },
      onProgress,
      onError
    )
  }
}

OsHouse.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsHouse,
  transformWithLocalCoordinates: function () {
    return true
  },
  onChange: function (editor) {
    this.refreshModel()
  },
  getGroundHeight: function () {
    return -1 * editor.getGroundElevation()
  },
  refreshModel: function () {
    if (this.model) {
      // var modelHeight = 10;
      // var box = new THREE.Box3().setFromObject( this.model );
      this.model.position.fromArray([0, 0, (-this.position.z - this.getGroundHeight()) / this.scale.z])
      // this.model.scale.z = modelHeight/this.scale.z;
    }
  },
  applyGhostMode: function (value) {
    return
    // if(false && value){
    //     this.material = OsHouseCache.materialGhostMode;
    // }else{
    //     this.material = OsHouseCache.material;
    // }
  },

  handleGhostModeBehavior: ObjectBehaviors.handleGhostModeBehavior,

  shapeType: function (value) {
    if (typeof value === 'undefined') {
      return this._shapeType
    }

    if (this._shapeType != value) {
      //update
      this.geometry = OsHouseCache.geometry[value]
      this._shapeType = value
      this.refreshUserData()
    }
  },

  getContextMenuItems: function (position) {
    var _this = this

    var menuItems = Object.keys(OsHouseCache.geometry).map(function (key) {
      return {
        label: 'Shape: ' + key,
        onClick: function () {
          _this.shapeType(key)
        },
      }
    })

    menuItems.push({
      label: 'Select Tree',
      useHTML: false,
      selected: false,
      onClick: function () {
        if (editor) {
          editor.select(_this)
        }
      },
    })

    return menuItems
  },

  applyUserData: function () {
    this.shapeType(this.userData.shapeType)
  },

  refreshUserData: function () {
    this.userData.shapeType = this.shapeType()
  },
})
