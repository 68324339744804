/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @constructor
 */

var AddViewCommand = function (newViewInstance, ViewHelper, select, insertAtIndex, commandUUID) {
  Command.call(this)

  this.type = 'AddViewCommand'
  this.name = 'Add View Command'
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['newViewData', 'oldViewUuid', 'select', 'insertAtIndex']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.newViewInstance = newViewInstance
    this.newViewData = newViewInstance.toObject()
    this.oldViewUuid = this.ViewHelper.selectedView()?.uuid
    this.select = select
    this.insertAtIndex = insertAtIndex
  }
}

AddViewCommand.prototype = {
  execute: function () {
    var viewInstance = this.viewInstance || new ViewInstance(this.newViewData)
    ViewHelper.addView(
      viewInstance,
      Boolean(this.select),
      typeof this.insertAtIndex !== 'undefined' ? this.insertAtIndex : undefined
    )
  },

  undo: function () {
    var viewIndex = this.ViewHelper.getIndexForView(this.newViewData)
    this.ViewHelper.deleteView(this.newViewData, this.editor, true)
  },

  toJSON: function () {
    return Command.prototype.toJSON.call(this)
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
