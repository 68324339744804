/**
 * @author adampryor
 */

function OsDesignerScene() {
  THREE.Scene.call(this)

  this.keysFromUserData = [
    'wallTypeId',
    'roofTypeId',
    'country',
    'timezoneOffset',
    'selectedViewUuid',
    'views',
    'projectConfigurationTitle',
    'terrainProvider',
    'dsmUrl',
    'orthoUrl',
    'terrainPosition',
    'terrainRotationZ',
    'horizon',
    'groundVariationData',
    'preGeneratedRaytraceResults',
    'autoDesignGeoJson',
    'autoFacetsGeoJson',
  ]

  this.types = {
    terrainPosition: 'Vector3',
  }

  this.type = 'OsDesignerScene'
  this.name = 'OsDesignerScene'
  this.background = null
  this.fog = null
  this.views = []
  this.terrainProvider = null
  this.dsmUrl = null
  this.orthoUrl = null
  this.terrainPosition = null
  this.terrainRotationZ = null
  this.terrain = null
  this.timezoneOffset = null

  //If set, this contains 360 elevation values for each degree bearing, starting from North
  this.horizon = null

  this.sceneOrigin4326 = [0, 0]

  this.reset()
}

OsDesignerScene.prototype = Object.assign(Object.create(THREE.Scene.prototype), {
  constructor: OsDesignerScene,
  reset: function () {
    this.terrainProvider = null
    this.dsmUrl = undefined
    this.orthoUrl = undefined
    this.terrainPosition = null
    this.terrainRotationZ = null
    this.dsmUrlLoadRequested = null
    this.orthoUrlLoadRequested = null

    this._wallTypeId = null
    this._roofTypeId = null
    this.projectConfigurationTitle = undefined
    this.views = []
    this.selectedViewUuid = undefined
    this.country = undefined

    this.terrain = undefined
    this.horizon = undefined
    this.preGeneratedRaytraceResults = undefined
    this.autoDesignGeoJson = undefined
    this.autoFacetsGeoJson = undefined

    this.groundVariationData({})
  },
  applyUserData: function () {
    window.studioDebug && console.log('Scene::applyUserData')

    this.keysFromUserData.forEach(function (key) {
      if (this.userData.hasOwnProperty(key)) {
        if (typeof this[key] === 'function') {
          //use setter
          this[key](this.userData[key])
        } else if (this.types[key] == 'Vector3') {
          // if null/undefined/etc then save raw value
          this[key] = this.userData[key] ? new THREE.Vector3().fromArray(this.userData[key]) : this.userData[key]
        } else {
          this[key] = this.userData[key]
        }
      }
    }, this)

    if (this.userData.hasOwnProperty('sceneOrigin4326')) {
      this.sceneOrigin4326 = this.userData['sceneOrigin4326']
    }

    //Convert raw mapData dicts into MapData classes
    if (this.userData.views && this.userData.views.length) {
      this.views = this.userData.views.map(function (viewInstanceData) {
        return new ViewInstance(viewInstanceData)
      })
    }
  },
  refreshUserData: function () {
    console.log('Scene::refreshUserData')

    this.userData['sceneOrigin4326'] = this.sceneOrigin4326

    // Quick migration for old data
    if (this.userData.terrainProvider) {
      if (this.userData.terrainProvider === 'google') {
        this.userData.terrainProvider = 'Google'
      } else if (this.userData.terrainProvider === 'nearmap') {
        this.userData.terrainProvider = 'Nearmap'
      }
    }

    if (window.saveUndoHistory) {
      try {
        //Max 100 commands will be saved
        var maxUndoCommand = 100
        window.editor.history.undos = window.editor.history.undos.slice(-maxUndoCommand)
        this.userData['historyData'] = window.editor.history.toJSON()
      } catch (e) {
        console.warn('Unable to save history: ' + e)
      }
    } else {
      this.userData['historyData'] = {}
    }
    this.keysFromUserData.forEach(function (key) {
      if (typeof this[key] === 'function') {
        //use getter
        this.userData[key] = this[key]()
      } else if (this.types[key] == 'Vector3') {
        // if null/undefined/etc then save raw value
        this.userData[key] = this[key] ? this[key].toArray() : this[key]
      } else {
        this.userData[key] = this[key]
      }
    }, this)
  },
  roofTypeId: function (value) {
    if (typeof value === 'undefined') {
      return this._roofTypeId
    }

    if (this._roofTypeId !== value) {
      this._roofTypeId = value

      //update facet.roofTexture for all facets
      window.editor.filter('type', 'OsFacet').map(function (facet) {
        if (!facet.roofTypeId()) {
          // these are not using roofType override so refresh them to automatically pickup the new default
          facet.refreshMesh(window.editor)
        }
      })
      window.editor.signals.objectChanged.dispatch(this)
    }
  },
  wallTypeId: function (value) {
    if (typeof value === 'undefined') {
      return this._wallTypeId
    }

    if (this._wallTypeId !== value) {
      this._wallTypeId = value

      //update facet.wallTexture for all facets
      window.editor.filter('type', 'OsFacet').map(function (facet) {
        if (!facet.wallTypeId()) {
          // these are not using wallType override so refresh them to automatically pickup the new default
          facet.refreshMesh(window.editor)
        }
      })

      window.editor.signals.objectChanged.dispatch(this)
    }
  },
  groundVariationData: function (value) {
    if (typeof value === 'undefined') {
      return this._groundVariationData
    }

    if (value === null) {
      value = {}
    }

    if (this._groundVariationData !== value) {
      this._groundVariationData = value

      //update all facets in case they need to be redrawn
      if (window.editor.scene) {
        window.editor.filter('type', 'OsFacet').map(function (facet) {
          facet.refreshMesh(window.editor)
        })
      }
    }
  },
  hasGroundImagery: function () {
    return this.groundVariationData() && this.groundVariationData().url
  },
  raytracedShadingAvailable: function () {
    // a) if terrain is loaded or
    // b) at least one 3D viewport
    //
    // There is no point in checking for the presence of a shading obstruction loaded (i.e. tree, obstruction, facet, panels)
    // because all systems will have at least one panel, so this check would always return true
    //
    // We only skip raytraced shading if all systems are using a % shading override
    return !!(
      editor.getTerrain() ||
      editor.scene.views.some(
        (view) => (view.mapData && MapData.mapTypeIs3D(view.mapData.mapType)) || view.mapData.mapType === 'None'
      )
    )
  },
})
