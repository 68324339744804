function OsString(options) {
  THREE.Object3D.call(this)
  this.type = 'OsString'
  this.modules = options && options.modules ? options.modules : []
  this.moduleUuids = []
  this.output = null
  this.transformable = false

  //Don't allow selection from viewport, only from SystemProperties panel
  this.selectable = false

  this.markers = []
  this.line = null

  // var label = TextLabel.createLabel('STR', new THREE.Vector3(), 0.005)
  // label.selectionDelegate = this
  // editor.addObject(label, this)
}

//Just in case visibility is set before material cache is created
OsString._visible = false //this sets initial state upon loading. If false it must be manually toggled on
OsString.materialCache = null

OsString.visible = function (value) {
  if (typeof value === 'undefined') {
    return OsString._visible
  }

  if (value !== OsString._visible) {
    OsString._visible = value
    if (OsString.materialCache) {
      Object.keys(OsString.materialCache).forEach((key) => {
        OsString.materialCache[key].visible = value
      })
    }
    if (editor) editor.signals.materialChanged.dispatch()
  }
}

OsString.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsString,
  toolsActive: function () {
    return {
      translateXY: false,
      translateZ: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },
  belongsToGroup: ObjectBehaviors.belongsToGroup,
  onChange: function (editor) {
    this.refreshLine(editor)
  },
  onRemove: function (editor) {
    this.clearLine(editor)
  },
  onSelect: function (editor) {
    this.refreshLine(editor)
  },
  onDeselect: function () {
    this.refreshLine(editor)
  },
  refreshUserData: function () {
    this.userData = {
      uuid: this.uuid,
      moduleUuids: this.modules.map(function (o) {
        return o.uuid
      }),
      modules: this.modules.map(function (o) {
        return o.refreshUserData()
      }),
      output: this.output,
      electrical: this.electricalCalcs(),
    }
    return this.userData
  },
  applyUserData: function () {
    this.moduleUuids =
      this.userData && this.userData.moduleUuids && this.userData.moduleUuids.length > 0
        ? this.userData.moduleUuids.slice()
        : []

    if (this.moduleUuids && this.moduleUuids.length) {
      this.moduleUuids.forEach(function (uuid) {
        var module = editor.objectByUuid(uuid)
        if (module) {
          this.addModule(module)
        } else {
          console.log('Error: module was not found with uuid: ' + uuid)
        }
      }, this)
    }

    this.output = this.userData.output
  },
  clearStringModuleReference: function (editor) {
    while (this.modules.length > 0) {
      this.removeModule(this.modules[0])
    }
    this.onChange(editor)
  },
  addModule: function (module, alterReference) {
    // ignore inactive modules
    if (!module.active) {
      console.warn('Attempted to add inactive module to string')
      return false
    }
    if (this.modules.length > 0 && this.modules.indexOf(module) === this.modules.length - 1) {
      //no change, already the last module in the string
      return false
    } else {
      //remove from any other string
      //this can even be the same string which is fine, it will be re-added at the end
      var returnValue = true
      if (module.assignedOsString) {
        if (alterReference) alterReference[module.uuid] = module.assignedOsString.uuid
        module.assignedOsString.removeModule(module)
      }

      this.modules.push(module)

      module.assignedOsString = this
      if (this.moduleUuids.indexOf(module.uuid) === -1) this.moduleUuids.push(module.uuid)
      return returnValue
    }
  },
  removeModule: function (module) {
    if (this.modules.indexOf(module) != -1) {
      this.modules.splice(this.modules.indexOf(module), 1)
    }
    module.assignedOsString = null

    if (this.moduleUuids.indexOf(module.uuid) !== -1) {
      this.moduleUuids.splice(this.moduleUuids.indexOf(module.uuid), 1)
    }

    if (editor) editor.signals.objectChanged.dispatch(this)
  },
  replaceModule: function (oldSystemModule, newSystemModule) {
    if (this.modules.indexOf(oldSystemModule) != -1) {
      this.modules.splice(this.modules.indexOf(oldSystemModule), 1, newSystemModule)
      if (oldSystemModule.assignedOsString) {
        newSystemModule.assignedOsString = oldSystemModule.assignedOsString
        oldSystemModule.assignedOsString = null
      }
    } else {
      console.log('Warning: replaceModule() failed because oldSystemModule not found in this.modules')
    }
  },
  addModuleMultiple: function (module) {
    //if module is on same row as last module, add all modules between the last module added to the string and this module
    //but skip any inactive modules
    var fromModule = this.modules[this.modules.length - 1]
    if (!fromModule) {
      editor.execute(new StringModuleAssignmentCommand(this, [module]))
      //return this.addModule(module)
    }
    var fromCell = this.modules[this.modules.length - 1].getCell()
    var toCell = module.getCell()

    if (
      this.modules[this.modules.length - 1].getGrid() === module.getGrid() &&
      fromCell[0] !== toCell[0] &&
      fromCell[1] === toCell[1]
    ) {
      var row = fromCell[1]
      var fromX = fromCell[0]
      var toX = toCell[0]
      var grid = module.getGrid()
      var x
      var m

      var modulesToAdd = []

      if (toX > fromX) {
        for (x = fromX + 1; x < toX + 1; x++) {
          m = grid.getModuleForCell(x, row)
          if (m && m.active) {
            if (this.modules.indexOf(m) == -1) {
              modulesToAdd.push(m)
            }
          }
        }
      } else {
        for (x = fromX - 1; x > toX - 1; x--) {
          m = grid.getModuleForCell(x, row)
          if (m && m.active) {
            if (this.modules.indexOf(m) == -1) {
              modulesToAdd.push(m)
            }
          }
        }
      }
      if (modulesToAdd.length) {
        editor.uiPause('ui', 'signals.objectChanged')
        editor.execute(new StringModuleAssignmentCommand(this, modulesToAdd))
        editor.uiResume('ui', 'signals.objectChanged')
      }
      return true
    } else {
      if (this.modules.indexOf(module) == -1) {
        editor.execute(new StringModuleAssignmentCommand(this, [module]))
      }
    }
  },
  populateWithOutput: function (output_for_string) {
    this.output = output_for_string
  },
  moduleQuantity: function () {
    return this.modules.length
  },
  getSystem: ObjectBehaviors.getSystem,
  getSummary: function () {
    return this.modules.length === 1 ? '1 Panel' : `${this.modules.length} Panels`
  },
  isMicroinverter: function () {
    return !!this.parent?.parent?.microinverter
  },
  getChildren: function () {
    return this.modules
  },
  clearLine: function (editor) {
    if (this.line) {
      if (editor) editor.removeObject(this.line, false)
      this.line = null
    }
    if (this.markers && this.markers.length > 0) {
      this.markers.forEach(function (marker) {
        if (editor) editor.removeObject(marker, false)
      })
      this.markers = []
    }
  },

  refreshLine: function (editor) {
    this.clearLine(editor)

    if (!OsString._visible) {
      return
    }

    var geometry = new THREE.Geometry()
    geometry.vertices = this.modules.map(function (m) {
      if (!m.parent) {
        console.log('WARNING: Error in OsString.refreshLine()... this.module[n].parent is not set. Fixme')
        return new THREE.Vector3()
      }
      m.parent.updateMatrixWorld()
      return m.parent.localToWorld(m.position.clone())
    })

    //@TODO: Re-implement dashed line
    var meshLine = new MeshLine()
    meshLine.setGeometry(geometry)

    if (!OsString.materialCache) {
      OsString.materialCache = {
        materialOnSelect: new MeshLineMaterial({
          //depthTest: false,
          color: new THREE.Color(0xffda01),
          sizeAttenuation: 0,
          lineWidth: 5,
          resolution: new THREE.Vector3(600, 600),
          visible: OsString._visible,
        }),
        defaultMaterial: new MeshLineMaterial({
          //depthTest: false,
          color: new THREE.Color(0xff00ff),
          sizeAttenuation: 0,
          lineWidth: 5,
          resolution: new THREE.Vector3(600, 600),
          visible: OsString._visible,
        }),
      }
    }
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    var material = isSelected ? OsString.materialCache.materialOnSelect : OsString.materialCache.defaultMaterial

    this.line = new THREE.Mesh(meshLine.geometry, material)
    this.line.position.fromArray([0, 0, 0.1])

    this.line.userData.excludeFromExport = true
    this.line.selectionDelegate = this

    if (!OsString.sphereGeometryCache) {
      OsString.sphereGeometryCache = new THREE.SphereBufferGeometry(0.2, 4, 4)
    }

    geometry.vertices.forEach(function (vertex) {
      var marker = new THREE.Mesh(OsString.sphereGeometryCache, material)
      marker.position.copy(vertex)
      marker.userData.excludeFromExport = true
      marker.selectionDelegate = this
      if (editor) editor.addObject(marker, this, false)
      this.markers.push(marker)
    }, this)
    if (editor) editor.addObject(this.line, this, false)
    this.getSystem()?.refreshUnstrungModulesMarker()
  },
  getPanelsPower: function () {
    try {
      let moduleQuantity = this.moduleQuantity()
      return moduleQuantity * this.getSystem().moduleType().kw_stc
    } catch (e) {
      return 0
    }
  },
  electricalCalcs: function () {
    try {
      const [minTemp, maxTemp] = Utils.getMinMaxTemperature(window.WorkspaceHelper?.project)
      const maxPanelOperatingTemp = maxTemp + window.Utils.AVERAGE_PANEL_TEMP_INCREASE

      const moduleData = this.getSystem().moduleType()

      const maxPanelVoc = Utils.getPanelSpecAtTemp(moduleData.voc, moduleData.temp_coefficient_voc, minTemp)
      const maxPanelVmp = Utils.getPanelSpecAtTemp(
        moduleData.max_power_voltage,
        moduleData.temp_coefficient_voc,
        minTemp
      )
      const minPanelIsc = Utils.getPanelSpecAtTemp(moduleData.isc, moduleData.temp_coefficient_isc, minTemp)

      const minPanelVoc = Utils.getPanelSpecAtTemp(
        moduleData.voc,
        moduleData.temp_coefficient_voc,
        maxPanelOperatingTemp
      )
      const minPanelVmp = Utils.getPanelSpecAtTemp(
        moduleData.max_power_voltage,
        moduleData.temp_coefficient_voc,
        maxPanelOperatingTemp
      )
      const maxPanelIsc = Utils.getPanelSpecAtTemp(
        moduleData.isc,
        moduleData.temp_coefficient_isc,
        maxPanelOperatingTemp
      )

      const moduleQuantityMultiplier = this.isMicroinverter() ? 1 : this.moduleQuantity()
      const round2dp = (value) => roundToDecimalPlacesPrecise(value, 2)

      return {
        voc: [maxPanelVoc * moduleQuantityMultiplier, minPanelVoc * moduleQuantityMultiplier].map(round2dp),
        vmp: [maxPanelVmp * moduleQuantityMultiplier, minPanelVmp * moduleQuantityMultiplier].map(round2dp),
        isc: [minPanelIsc, maxPanelIsc].map(round2dp),
      }
    } catch (e) {
      console.warn(e)
      return {}
    }
  },
})
