/**
 * A class that manages the controllers for an Editor instance
 * It handles the lifecycle, activation, and deactivation of controllers
 * according to a registry (see below)
 *
 * NOTE: auto-activation is an experimental feature of controllers
 * introduced by Adam, which enables a controller to manage its own activation state
 * depending on the state of the editor / viewport
 * such as when a particular type of object in the editor is selected
 * Seems like this is currently only used by ModulePlacementController
 * we can probably find a better way to handle this ?
 */
class ControlPanel {
  #registry = {
    General: {
      constructor: window.GeneralController,
      activateOnInit: true,
      autoActivate: false,
      in: {
        activation: 'General.inputs.activation',
      },
      out: {
        activation: 'General.outputs.activation',
      },
    },

    Camera: {
      constructor: window.CameraController,
      activateOnInit: true,
      autoActivate: false,
      in: {
        activation: 'Camera.inputs.activation',
      },
      out: {
        activation: 'Camera.outputs.activation',
      },
    },

    DeviceOrientation: {
      constructor: window.DeviceOrientationController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'DeviceOrientation.inputs.activation',
      },
      out: {
        activation: 'DeviceOrientation.outputs.activation',
      },
    },

    ModulePlacement: {
      constructor: window.ModulePlacementController,
      activateOnInit: false,
      autoActivate: true,
      in: {
        activation: 'ModulePlacement.inputs.activation',
      },
      out: {
        activation: 'ModulePlacement.outputs.activation',
      },
    },

    FingerPaint: {
      constructor: window.FingerPaintController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'FingerPaint.inputs.activation',
      },
      out: {
        activation: 'FingerPaint.outputs.activation',
      },
    },

    StringModuleAssignment: {
      constructor: window.StringModuleAssignmentController,
      activateOnInit: true,
      autoActivate: false,
      in: {
        activation: 'StringModuleAssignment.inputs.activation',
      },
      out: {
        activation: 'StringModuleAssignment.outputs.activation',
      },
    },

    ContextMenu: {
      constructor: window.ContextMenuController,
      activateOnInit: true,
      autoActivate: false,
      in: {
        activation: 'ContextMenu.inputs.activation',
      },
      out: {
        activation: 'ContextMenu.outputs.activation',
      },
    },

    AddObject: {
      constructor: window.AddObjectController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'AddObject.inputs.activation',
      },
      out: {
        activation: 'AddObject.outputs.activation',
      },
    },

    CallbackStack: {
      constructor: window.CallbackStackController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'CallbackStack.inputs.activation',
      },
      out: {
        activation: 'CallbackStack.outputs.activation',
      },
    },

    Annotation: {
      constructor: window.AnnotationController,
      activateOnInit: true,
      autoActivate: false,
      in: {
        activation: 'Annotation.inputs.activation',
      },
      out: {
        activation: 'Annotation.outputs.activation',
      },
    },

    MeasureHeight: {
      constructor: window.MeasureHeightController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'MeasureHeight.inputs.activation',
      },
      out: {
        activation: 'MeasureHeight.outputs.activation',
      },
    },

    Handle: {
      constructor: window.HandleController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'Handle.inputs.activation',
      },
      out: {
        activation: 'Handle.outputs.activation',
      },
    },

    Sequence: {
      constructor: window.SequenceController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'Sequence.inputs.activation',
      },
      out: {
        activation: 'Sequence.outputs.activation',
      },
    },

    SelectionBox: {
      constructor: window.SelectionBoxController,
      activateOnInit: false,
      autoActivate: false,
      in: {
        activation: 'SelectionBox.inputs.activation',
      },
      out: {
        activation: 'SelectionBox.outputs.activation',
      },
    },

    Measurement: {
      constructor: window.MeasurementController,
      activateOnInit: false,
      autoActivate: false,
    },

    // helper methods DO NOT TOUCH!
    getControllerNames: function () {
      return Object.keys(this).filter((key) => typeof this[key] === 'object')
    },

    hasControllerName: function (name) {
      return this[name] && typeof this[name] === 'object'
    },
  }

  // this architecture of connecting controllers needs rethinking
  // this does not scale well and can lead to race conditions
  // need to refactor into something that activates/deactivates multiple controllers
  // based on pre-defined control panel "modes"
  // this currently does not cover all controller interactions
  #wirings = [
    {
      // SelectionBoxController active -> deactivate CameraController
      from: this.#registry.SelectionBox.out.activation,
      to: (state) => this.loadState({ Camera: !state }),
    },
  ]

  ////////////////////////////////////////////////////////////////
  // this is to maintain backwards compatibility with the legacy method of
  // activating/deactivating controllers outside of studio
  // example: there are cases in the UI layer where
  // editor.controllers.Fingerpaint.start()
  // is called directly; need to refactor those
  ////////////////////////////////////////////////////////////////
  General = null
  Camera = null
  DeviceOrientation = null
  FingerPaint = null
  ModulePlacement = null
  StringModuleAssignment = null
  ContextMenu = null
  AddObject = null
  CallbackStack = null
  Annotation = null
  MeasureHeight = null
  Handle = null
  Sequence = null
  SelectionBox = null
  ///////////////////////////////////////////////////////////////

  #controllers = {
    has: function (controllerName) {
      return !!this[controllerName]
    },
    get: function (controllerName) {
      return this[controllerName]
    },
  }

  #hotkeys = new Hotkeys()
  #hotkeysInitialized = false
  #hotkeysSuspended = false

  init = (editor, controllersList) => {
    if (!editor) {
      throw new Error('ControlPanel.init() called without a valid Editor instance.')
    }

    // Store a reference now that `this` is available
    this.#hotkeys.reference = this

    if (!Array.isArray(controllersList)) {
      controllersList = this.#registry.getControllerNames()
    }

    this.#registry.getControllerNames().forEach((controllerName) => {
      if (controllersList.indexOf(controllerName) === -1) return
      // create instance of controller
      const controller = new this.#registry[controllerName].constructor(editor, editor.viewport)

      // store instance of controller
      this.#controllers[controllerName] = controller
      this[controllerName] = controller // for backwards compatibility

      // set the initial activation state of the controller
      this.setControllerState(
        controllerName,
        this.#registry[controllerName].activateOnInit,
        this.#registry[controllerName].autoActivate ? true : null
      )
    })

    if (controllersList.length > 0) {
      this.#wireUpControllers()
    }

    if (!this.#hotkeysInitialized) {
      this.#initHotkeys(editor)
      this.#hotkeysInitialized = true
      this.#hotkeys.attach()
    }
  }

  // returns an object that represents the activation state of all the *instantiated* controllers
  // format:
  // { AddObject: <boolean>, Annotation: <boolean>, ... }
  getState = () => {
    const state = {}
    Object.keys(this.#controllers)
      .filter((key) => !!this.#controllers[key] && this.#registry.hasControllerName(key))
      .forEach((controllerName) => {
        state[controllerName] = this.#controllers[controllerName].active
      })

    return state
  }

  // sets the activation state of the controllers based on a given state object
  // the state object follows the format of getState(), but allows for partial definition
  // example, you can pass { Annotation: false } as state
  // this means the AnnotationController must be deactivated
  // but all other controllers must remain as they are
  loadState = (state) => {
    const stateIsValid = (state) => {
      if (typeof state !== 'object') return

      let count = 0
      for (let key in state) {
        if (this.#registry.hasControllerName(key)) {
          count++
        } else {
          // must not contain any invalid key (controller name)
          return false
        }
      }
      return count > 0
    }

    if (!stateIsValid(state)) {
      throw new Error('ControlPanel.loadState() supplied with invalid state object.')
    }

    const targetCPanelState = { ...this.getState(), ...state }

    Object.keys(this.#controllers)
      .filter((key) => !!this.#controllers[key] && this.#registry.hasControllerName(key))
      .forEach((controllerName) => {
        const controller = this.#controllers.get(controllerName)
        if (controller._autoActivate) return

        const currentCtrlrState = controller.active
        const targetCtrlrState = targetCPanelState[controllerName]

        if (currentCtrlrState === targetCtrlrState) return
        this.setControllerState(controllerName, targetCtrlrState)
      })

    return this.getState()
  }

  setControllerState = (controllerName, activationState, autoActivate) => {
    if (!this.#controllers.has(controllerName)) {
      // this.controllers[controllerName] = new this.#registry[controllerName].constructor()
      return
    }
    // this is where we resolve/handle controller conflicts and exclusive controls
    if (activationState === true) {
      this.#controllers.get(controllerName).activate()
    } else {
      this.#controllers.get(controllerName).deactivate()
    }

    if ((autoActivate === true || autoActivate === false) && this.#controllers[controllerName].autoActivate) {
      this.#controllers[controllerName].autoActivate(autoActivate)
    }
  }

  // this is a temporary solution allow us to suspend the hotkeys indefinitely
  // and any attempts to toggle the Hotkeys state via loadState() will be ignored
  suspendHotkeys = () => {
    this.#hotkeysSuspended = true
  }

  restoreHotkeys = () => {
    this.#hotkeysSuspended = false
  }

  #wireUpControllers = () => {
    this.#wirings.forEach((wiring) => {
      const srcTokens = wiring.from.split('.')
      const srcControllerName = srcTokens[0]
      if (!this.#controllers.has(srcControllerName)) return
      // wire up from source to destination
      this.#controllers[srcControllerName][srcTokens[1]][srcTokens[2]].add(wiring.to)
    })
  }

  #checkPermissionAndNotify = (specificPermissionKey) => {
    const hasPermission = specificPermissionKey
      ? window.Designer.permissions.canEdit(specificPermissionKey)
      : window.Designer.permissions.canEdit()
    if (hasPermission) {
      return true
    } else {
      window.Designer.showNotification(window.translate('No permission to edit design.'), 'danger')
      return false
    }
  }

  #initHotkeys = (editor) => {
    this.#hotkeys
      .on(Hotkeys.W)
      .do(() => {
        window.ViewHelper.enableDrawingTools() && Designer.startPlacementMode('Wire (W)')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.O)
      .do(() => {
        if (window.ViewHelper.enableDrawingTools()) {
          // Init variable to track selected obstruction preset
          if (!window.lastObstructionPresetIndex && window.lastObstructionPresetIndex !== 0) {
            window.lastObstructionPresetIndex = 0
          }

          if (editor.controllers.AddObject.active) {
            if (editor.controllers.AddObject.objectType === 'OsObstruction') {
              // Increment the selected obstruction preset when we press hotkey again while AddObject is active
              // and placing an obstruction.
              window.lastObstructionPresetIndex =
                (window.lastObstructionPresetIndex + 1) % OsObstruction.OBSTRUCTION_TYPES.length
            }

            editor.controllers.AddObject.abort()
          }
          var obstructionTypeDefinition = OsObstruction.OBSTRUCTION_TYPES[window.lastObstructionPresetIndex]
          var options = { obstructionType: obstructionTypeDefinition.type }

          Designer.startPlacementMode('Obstruction (O)', options)

          // Each time we cycle to the next obstruction, we need to clear out any existing notifications otherwise the message
          // will not show until the old message disappears, which is much too slow to be useful here.
          // We do not have a great way to handle this, so we will just call hideNotification() immediately before which will clear
          // out the last notification. There may be some cases where this doesn't work great, such as when there are multiple notifications
          // queued up, but at least it should work pretty well when cycling through the obstruction options quickly.
          Designer.hideNotification()
          Designer.showNotification(window.translate(`Placing ${obstructionTypeDefinition.title}...`))
        }
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.E)
      .do(() => {
        editor.setDesignGuidesVisibility({
          visible: !editor.getDesignGuidesVisibility(),
          renderWhenDone: true,
        })
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.P)
      .dispatchIf(() => {
        return this.#checkPermissionAndNotify('systems.panels')
      })
      .do(() => {
        window.Designer.startPlacementMode('Paint Modules (P)')
      })

    this.#hotkeys
      .on(Hotkeys.R)
      .do(() => {
        window.ViewHelper.enableDrawingTools() && Designer.startPlacementMode('Roof (R)')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.I)
      .do(() => {
        window.ViewHelper.enableDrawingTools() && Designer.startPlacementMode('Tree Trimmer (I)')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.N)
      .do(() => {
        window.ViewHelper.enableDrawingTools() && Designer.startPlacementMode('Annotation (N)')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.T)
      .do(() => {
        window.ViewHelper.enableDrawingTools() && Designer.startPlacementMode('Tree (T)')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.Q)
      .do(() => {
        // start in aframe but after being enabled on the first step, Q will toggle the mode
        editor.controllers.Sequence.activate('aframe')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.D)
      .do(() => {
        // start in aframe but after being enabled on the first step, Q will toggle the mode
        editor.controllers.Sequence.activate('aframe_dormer')
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .onCtrlShift()
      .plus(Hotkeys.L)
      .do((event) => {
        event.preventDefault()
        editor.controllers.Measurement.start(MeasurementController.Types.Length, { segmentsLimit: 100 }).catch((e) =>
          console.warn(e)
        )
      })

    this.#hotkeys
      .onCtrlShift()
      .plus(Hotkeys.H)
      .do((event) => {
        event.preventDefault()
        editor.controllers.Measurement.start(MeasurementController.Types.Height, { segmentsLimit: 100 }).catch((e) =>
          console.warn(e)
        )
      })

    this.#hotkeys
      .on(Hotkeys.A)
      .do(() => {
        const isAlignMapAllowed = window.MapData?.is2D(window.MapHelper?.activeMapInstance?.mapData)
        if (isAlignMapAllowed) {
          editor.signals.cycleNextMapSceneControl.dispatch()
        }
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys.on(Hotkeys.ESCAPE).do(() => {
      // cannot remove this completely yet as MapHelper uses this somehow
      // @TODO: migrate MapHelper.showCyclomediaTopDownImageSelector
      // to the new Hotkeys system
      editor.unlockSelection()
      editor.select(null)
      editor.signals.escapePressed.dispatch()
    })

    this.#hotkeys
      .onCtrl()
      .plus(Hotkeys.Z)
      .do(() => {
        if (editor.history.canUndo()) {
          editor.undo()
        }
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .onCtrlShift()
      .plus(Hotkeys.Z)
      .do(() => {
        window.shiftIsDown = false
        if (editor.history.canRedo()) {
          editor.redo()
        }
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.CTRL)
      .do(() => {
        editor.startMultiSelectMode()
      })
      .onReleaseDo(() => {
        editor.endMultiSelectMode()
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.SHIFT)
      .do(() => {
        window.shiftIsDown = true

        if (window.STUDIO_FEATURE_FLAGS?.STUDIO_NEXT) {
          editor.snappingActive = true
        } else {
          console.info('Snapping disable by feature flag: studio_next')
        }
      })
      .onReleaseDo(() => {
        window.shiftIsDown = false
        editor.snappingActive = false
        window.clearSnapGuides()
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.BACKSPACE)
      .do((_rawEvent) => {
        _rawEvent.preventDefault()
        editor.deleteSelection()
      })
      .dispatchIf(() => {
        // Continue and Permission check done at lower level
        return true
      })

    this.#hotkeys
      .on(Hotkeys.DELETE)
      .do((_rawEvent) => {
        _rawEvent.preventDefault()
        editor.deleteSelection()
      })
      .dispatchIf(() => {
        // Continue and Permission check done at lower level
        return true
      })

    this.#hotkeys
      .on(Hotkeys.ONE)
      .do(() => {
        window.ViewHelper.selectViewByIndex(0)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.TWO)
      .do(() => {
        window.ViewHelper.selectViewByIndex(1)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.THREE)
      .do(() => {
        window.ViewHelper.selectViewByIndex(2)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.FOUR)
      .do(() => {
        window.ViewHelper.selectViewByIndex(3)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.FIVE)
      .do(() => {
        window.ViewHelper.selectViewByIndex(4)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.SIX)
      .do(() => {
        window.ViewHelper.selectViewByIndex(5)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.SEVEN)
      .do(() => {
        window.ViewHelper.selectViewByIndex(6)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.EIGHT)
      .do(() => {
        window.ViewHelper.selectViewByIndex(7)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.NINE)
      .do(() => {
        window.ViewHelper.selectViewByIndex(8)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.ZERO)
      .do(() => {
        window.ViewHelper.selectViewByIndex(9)
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.ARROW_UP)
      .do(() => {
        if (editor.selected.type === 'OsNode') {
          var needChanged = Boolean(!editor.selected.floatingOnFacet)
          if (needChanged) {
            editor.execute(new window.SetFloatingNodeCommand(editor.selected))
            Designer.showNotification(window.translate('Node floating enabled'))
          }
        }
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    this.#hotkeys
      .on(Hotkeys.ARROW_DOWN)
      .do(() => {
        if (editor.selected.type === 'OsNode') {
          var needChanged = Boolean(editor.selected.floatingOnFacet)
          if (needChanged) {
            editor.execute(new window.SetFloatingNodeCommand(editor.selected))
            Designer.showNotification(window.translate('Node floating disabled'))
          }
        }
      })
      .dispatchIf(() => this.#checkPermissionAndNotify())

    // Duplicate selected object Ctrl + V (similar to the common "paste" shortcut)
    this.#hotkeys
      .onCtrl()
      .plus(Hotkeys.V)
      .do(() => {
        if (editor.selected?.duplicate) {
          editor.selected?.duplicate({ keepPosition: false })
          if (editor.selected?.type === 'OsAnnotation') {
            Designer.showNotification(window.translate('Place duplicate...'))
          } else {
            Designer.showNotification(window.translate('Duplicated'))
          }
        }
      })

    const setObjectTypeUnderMouse = (hotkeyString) => {
      // This relies on GeneralController updating lastMouseClientPosition on mousemove
      let lastMouseClientPosition = editor.controllers?.General?.lastMouseClientPosition
      if (!lastMouseClientPosition) {
        Designer.showNotification(
          window.translate('Error setting edge type. Mouse cursor position was not tracked.'),
          'danger'
        )
        return
      }

      let syntheticEvent = {
        clientX: lastMouseClientPosition.x,
        clientY: lastMouseClientPosition.y,
      }

      var edgeType = OsEdge.hotkeyToEdgeType(hotkeyString)
      if (edgeType) {
        let edges = editor.filter('type', 'OsEdge')
        let osEdge = editor.viewport.clickIntersection(syntheticEvent, edges, false, undefined, false)?.object
        if (osEdge) {
          editor.execute(new window.SetEdgeTypeCommand(osEdge, edgeType))
          return
        }
      }

      // No edge found, we may be setting an obstruction type under the mouse
      var obstructionType = OsObstruction.hotkeyToObstructionType(hotkeyString)
      if (obstructionType) {
        let obstructions = editor.filter('type', 'OsObstruction')
        let osObstruction = editor.viewport.clickIntersection(syntheticEvent, obstructions, false, undefined, false)
          ?.object
        if (osObstruction) {
          osObstruction.setObstructionType(obstructionType)
          return
        }
      }

      Designer.showNotification(
        window.translate(`Unable to set edge/obstruction type, no edge or obstruction found under mouse cursor.`)
      )
    }

    let hotkeysCombined = new Set([
      ...Object.values(OsEdge.edgeTypeToHotkey),
      ...Object.values(OsObstruction.obstructionTypeToHotkey),
    ])
    hotkeysCombined.forEach((hotkeyString) => {
      // Convert string to hotkey constant
      var hotkey = Hotkeys[hotkeyString]
      this.#hotkeys
        .onShift()
        .plus(hotkey)
        .do(() => {
          setObjectTypeUnderMouse(hotkeyString)
        })
    })

    this.#hotkeys.dispatchWhen((rawEvent) => {
      if (!window.Designer.listeningForEvents('key') || window.Utils.isKeyboardInput(rawEvent.target)) return false

      // this is a temporary solution that enables us to suspend the hotkeys indefinitely
      // based on a flag or on the activation state of a particular controller
      if (this.#hotkeysSuspended) {
        return false
      }

      const eventKeyNormalized = this.#hotkeys.normalizeEventKey(rawEvent.key)

      const controllerNamesToCheck = ['FingerPaint', 'AddObject', 'Handle', 'Sequence']

      for (let i = 0; i < controllerNamesToCheck.length; i++) {
        var controllerName = controllerNamesToCheck[i]
        if (
          this.#controllers.has(controllerName) &&
          this.#controllers.get(controllerName).active &&
          this.#controllers.get(controllerName)?.overrides?.(eventKeyNormalized)
        ) {
          return false
        }
      }

      return true
    })
  }
}

window.ControlPanel = ControlPanel
