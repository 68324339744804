/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var SetFloatingNodeCommand = function (object, newValue) {
  Command.call(this)
  this.timeStamp = Date.now()
  this.type = 'SetFloatingNodeCommand'
  this.name = 'Set Floating Node Command'
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  if (arguments.length) {
    this.object = object
    this.newValue = typeof newValue === 'undefined' ? !object.floatingOnFacet : newValue
    this.newPosition = null
    this.oldPosition = null
  }
}

SetFloatingNodeCommand.prototype = {
  execute: function () {
    if (!this.oldPosition) {
      this.oldPosition = this.object.position.toArray()
    }

    if (this.newValue === false) {
      this.object.stopFloating()
    } else {
      this.object.startFloating()
    }

    if (this.newPosition) {
      this.object.position.fromArray(this.newPosition)
    } else {
      this.newPosition = this.object.position.toArray()
    }

    this.editor.signals.objectChanged.dispatch(this.object)
  },

  undo: function () {
    if (this.newValue === true) {
      this.object.stopFloating()
    } else {
      this.object.startFloating()
    }

    if (this.oldPosition) {
      this.object.position.fromArray(this.oldPosition)
    }

    this.editor.signals.objectChanged.dispatch(this.object)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
