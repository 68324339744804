function SetbacksHelperClass() {
  this.setbackDistances = this.setbackDistancesDefaults
}

SetbacksHelperClass.prototype = Object.assign({
  clear: function () {
    //Dodgy...
    this.setbackDistances.warning_location_specific_setbacks_not_loaded = true
  },

  setbackDistances: {},

  tiltRackSettings: {
    tilt_rack_default_tilt: 13.0,
    tilt_rack_default_orientation: 'landscape',
    apply_tilt_racks_below_slope: 10.0,
  },

  setbackDistancesDefaults: {
    warning_location_specific_setbacks_not_loaded: true,
    default: 0.3,
    gutter: 0.3,
    ridge: 0.3,
    valley: 0.3,
    hip: 0.3,
    rake: 0.3,
    shared: 0.3,
    flat_gutter: 0.3,
    arrays: 0,
  },

  getSetbackDistances: function () {
    var o = {}
    for (var key in this.setbackDistances) {
      if (key != 'warning_location_specific_setbacks_not_loaded') {
        o[key] = this.setbackDistances[key]
      }
    }
    return o
  },

  inverterModellingAutomation: true,

  edgeColors: {
    default: 0xffffff, //white
    gutter: 0x11ddaa, //teal
    ridge: 0xffff00, //yellow
    valley: 0x1daeeb, //blue
    hip: 0x1daeeb, //blue
    rake: 0x1daeeb, //blue
    shared: 0x1daeeb, //blue
    flat_gutter: 0x11ddaa, //teal
    incomplete: 0xaaaaaa, //grey
    black: 0x000000,
    red: 0xcc0000,
    blue: 0x33aaff,
    white: 0xffffff,
    green: 0x00cc00,
  },

  edgeTypesWire: [
    'black',
    'red',
    'blue',
    'white',
    'green',
  ],

  getEdgeType: function (edgeType) {
    try {
      return {
        color: this.edgeColors[edgeType],
        colorHex: this.edgeColors[edgeType].toString(16).padStart(6, '0'),
        setbackDistance: this.setbackDistancesDefaults[edgeType],
      }
    } catch (err) {
      return {
        color: this.edgeColors['default'],
        colorHex: this.edgeColors['default'].toString(16).padStart(6, '0'),
        setbackDistance: this.setbackDistancesDefaults['default'],
      }
    }
  },

  isLoaded: function () {
    //Returns true when all the following are populated:
    //  this.org is populated
    //  this.componentModuleSpecs
    //  this.componentInverterSpecs
    return !this.setbackDistances.warning_location_specific_setbacks_not_loaded
  },

  edgeTypeIsFlat: function (edgeType) {
    switch (edgeType) {
      case 'flat_gutter':
      case 'gutter':
      case 'ridge':
        return true
    }
    return false
  },

  loadFromProjectConfiguration: function (projectConfiguration) {
    //set projectConfiguration=false to use default setbacks

    if (projectConfiguration) {
      // Setbacks
      var keys = Object.keys(this.setbackDistances)

      keys.forEach(function (key) {
        var databaseFieldName = 'setbacks_' + key

        if (databaseFieldName in projectConfiguration) {
          this.setbackDistances[key] = parseFloat(projectConfiguration[databaseFieldName])
        }
      }, this)

      // Tilt rack settings
      this.tilt_rack_default_tilt = parseFloat(projectConfiguration.tilt_rack_default_tilt)
      this.tilt_rack_default_orientation = projectConfiguration.tilt_rack_default_orientation
      this.apply_tilt_racks_below_slope = parseFloat(projectConfiguration.apply_tilt_racks_below_slope)
      this.inverterModellingAutomation = Boolean(projectConfiguration.inverter_modelling_automation)
    }

    delete this.setbackDistances.warning_location_specific_setbacks_not_loaded
  },
})

var SetbacksHelper = new SetbacksHelperClass()
