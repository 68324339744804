const InverterSideEffects = (editorInstance) => {
  const handleHybridInverterDCConnection = (system) => {
    ComponentUtils.handleHybridBatteryConnectionState(editorInstance, system)
  }
  const handlecheckHybridClearWarning = (system) => {
    ComponentUtils.handlecheckHybridClearWarning(system)
  }

  const effects = new Map([
    [
      'hybridBatteryConnection',
      {
        triggers: ['add', 'change', 'remove'],
        handle: handleHybridInverterDCConnection,
      },
    ],
    [
      'checkClearWarning',
      {
        triggers: ['remove'],
        handle: handlecheckHybridClearWarning,
      },
    ],
  ])

  const inverterSideEffects = ComponentSideEffects.create(editorInstance, 'Inverter', {
    effects,
    isValidComponentType: (object) => object instanceof OsInverter,
    signals: [],
  })

  inverterSideEffects.signals = [
    ['objectAdded', inverterSideEffects.handleObjectAdded.bind(inverterSideEffects)],
    ['objectRemoved', inverterSideEffects.handleObjectRemoved.bind(inverterSideEffects)],
    ['objectChanged', inverterSideEffects.handleObjectChanged.bind(inverterSideEffects)],
    ['sceneLoaded', inverterSideEffects.handleSceneLoaded.bind(inverterSideEffects)],
  ]

  return inverterSideEffects
}
