// @deprecated in favor of MeasurementController
var MeasureHeightController = function (editor, viewport) {
  this.name = 'MeasureHeight'

  var _this = this
  var STATE = { NONE: -1, TOUCH_MEASURE: 1, MOUSE_MEASURE: 2 }

  var getDomElement = function () {
    return viewport.container.dom
  }

  // API

  this.enabled = true

  var _state = STATE.NONE,
    _prevState = STATE.NONE

  var startPosition = new THREE.Vector2()
  var endPosition = new THREE.Vector2()
  var startPosition3 = new THREE.Vector3()
  var endPosition3 = new THREE.Vector3()
  var startNode = null
  var endNode = null
  var edge = null

  // events

  var changeEvent = { type: 'change' }
  var finishEvent = { type: 'finished' }

  var screen = { width: 0, height: 0, offsetLeft: 0, offsetTop: 0 }

  // methods

  function start() {
    editor.saveControllerState()

    editor.controllers.General.deactivate()
    editor.controllers.Camera.deactivate()

    // //must call before 'activate' so the signal will allow overlay to refresh
    // window.setOverlay('SolarTouch')
    //
    // //hack!!! signal fires immediately but the setOverlay call goes via component state
    // setTimeout(function() {
    //   editor.signals.windowResize.dispatch()
    // }, 100)

    _this.activate()

    //return the function for cancelling the mode
    return function () {
      _this.finish()
    }
  }
  this.start = start

  function finish() {
    editor.revertControllerState()
    _this.deactivate()
  }
  this.finish = finish

  function handleResize() {
    if (viewport) {
      var viewportRect = viewport.rect()

      screen.width = viewportRect.width
      screen.height = viewportRect.height
      screen.offsetLeft = viewportRect.left
      screen.offsetTop = viewportRect.top
    }
  }

  function getMouseOnScreen(clientX, clientY) {
    return new THREE.Vector2(
      ((clientX - screen.offsetLeft) / _this.radius) * 0.5,
      ((clientY - screen.offsetTop) / _this.radius) * 0.5
    )
  }

  function getWorldPositionFromMouse(clientX, clientY) {
    var mousePosition = new THREE.Vector3().fromArray(viewport.getMousePosition(clientX, clientY))

    return viewport.getIntersectionWithGround(mousePosition)
  }

  this.update = function () {}

  this.reset = function (_target0, _position0, _up0) {
    _state = STATE.NONE
    _prevState = STATE.NONE

    if (startNode) {
      editor.removeObject(startNode)
      startNode = null
    }
    if (endNode) {
      editor.removeObject(endNode)
      endNode = null
    }
    if (edge) {
      editor.removeObject(edge)
      edge = null
    }

    _this.dispatchEvent(changeEvent)
  }
  function refreshHeightMeasurement() {
    var height = MapHelper.NearmapOblique.heightBetweenScreenPoints(
      MapHelper.activeMapInstance.mapData.oblique,
      startPosition,
      endPosition
    )

    startPosition3d = getWorldPositionFromMouse(startPosition.x, startPosition.y)
    endPosition3d = getWorldPositionFromMouse(endPosition.x, endPosition.y)

    if (!startNode) {
      startNode = new OsNode({ position: startPosition3d })
      startNode.userData.excludeFromExport = true
      startNode.refreshForCamera(startPosition3d, editor.metersPerPixel() * 0.5) //Hack size reduction
      editor.addObject(startNode)
    } else {
      startNode.position.copy(startPosition3d)
    }

    if (!endNode) {
      endNode = new OsNode({ position: endPosition3d })
      endNode.userData.excludeFromExport = true
      endNode.refreshForCamera(endPosition3d, editor.metersPerPixel() * 0.5) //Hack size reduction
      editor.addObject(endNode)
    } else {
      endNode.position.copy(endPosition3d)
    }

    if (!edge) {
      edge = new OsEdge({ nodes: [startNode, endNode], linkNodes: true })
      edge.userData.excludeFromExport = true
      editor.addObject(edge)
    }
    edge.forceAnnotationInMeters = height
    edge.refreshLine()
    editor.controllers.Annotation.handleObjectSelected(null, edge)

    var changed = true
    if (changed) editor.render()
  }

  // listeners

  function mousedown(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    if (_state === STATE.NONE) {
      _state = STATE.MOUSE_MEASURE
    }

    startPosition.fromArray([event.clientX, event.clientY])
    endPosition.copy(startPosition)

    document.addEventListener('mousemove', mousemove, false)
    document.addEventListener('mouseup', mouseup, false)
  }

  function mousemove(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    if (_state === STATE.MOUSE_MEASURE) {
      endPosition.fromArray([startPosition.x, event.clientY])
      refreshHeightMeasurement()
    }
  }

  function mouseup(event) {
    if (_this.enabled === false) return

    if (event) event.preventDefault()
    //event.stopPropagation()

    if (_state !== STATE.NONE) {
      _state = STATE.NONE
    }

    document.removeEventListener('mousemove', mousemove)
    document.removeEventListener('mouseup', mouseup)

    finish()
  }

  function touchstart(event) {
    if (_this.enabled === false) return

    switch (event.touches.length) {
      case 1:
        _state = STATE.TOUCH_MEASURE
        startPosition.fromArray([event.touches[0].clientX, event.touches[0].clientY])
        endPosition.copy(startPosition)
        break

      default:
        _state = STATE.NONE
    }
  }

  function touchmove(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    switch (event.touches.length) {
      case 1:
        _state = STATE.TOUCH_MEASURE
        endPosition.fromArray([event.touches[0].clientX, event.touches[0].clientY])
        refreshHeightMeasurement()
        break

      default:
        _state = STATE.NONE
    }
  }

  function touchend(event) {
    if (_this.enabled === false) return

    _state = STATE.NONE

    finish()
  }

  this.addEventListener('change', function () {
    //viewport.transformControls.update();
    //editor.signals.cameraChanged.dispatch(editor.camera);
  })

  this.activate = function () {
    this.active = true

    getDomElement().addEventListener('mousedown', mousedown, false)
    getDomElement().addEventListener('touchstart', touchstart, false)
    getDomElement().addEventListener('touchend', touchend, false)
    getDomElement().addEventListener('touchmove', touchmove, false)

    editor.signals.windowResize.add(handleResize)
    editor.signals.controlModeChanged.dispatch()
    this.refreshMouseCursor()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    this.active = false

    getDomElement().removeEventListener('mousedown', mousedown, false)
    getDomElement().removeEventListener('touchstart', touchstart, false)
    getDomElement().removeEventListener('touchend', touchend, false)
    getDomElement().removeEventListener('touchmove', touchmove, false)

    this.reset()

    // window.Designer.callUi('ToolbarDrawingTools', 'close', [true])
    // if (Designer.uiRefs['ToolbarDrawingTools']) {
    //   Designer.uiRefs['ToolbarDrawingTools'].close(true)
    // }

    editor.signals.windowResize.remove(handleResize)
    editor.signals.controlModeChanged.dispatch()
    this.refreshMouseCursor()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.refreshMouseCursor = function () {
    $('#DesignerContainer').css('cursor', this.active === true ? 'row-resize' : 'auto')
  }

  handleResize()
}

MeasureHeightController.prototype = Object.create(THREE.EventDispatcher.prototype)
MeasureHeightController.prototype.constructor = MeasureHeightController
