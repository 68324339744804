/**
 * @author adampryor
 */
var GeneralController = function (editor, viewport) {
  this.name = 'General'

  const hotkeys = new Hotkeys()
  hotkeys
    .onShift()
    .do(() => {
      window.shiftIsDown = true
    })
    .onReleaseDo(() => {
      window.shiftIsDown = false
    })

  var container = viewport.container

  var onDownPosition = new THREE.Vector2()
  var onUpPosition = new THREE.Vector2()

  var mouseDownTimestamp = null

  function longPressTimerStart() {
    mouseDownTimestamp = new Date().getTime()
  }

  function longPressTimerStop() {
    mouseDownTimestamp = null
  }

  function isLongPress() {
    if (!mouseDownTimestamp) {
      return false
    }

    var durationMs = new Date().getTime() - mouseDownTimestamp
    window.studioDebug && console.log('durationMs', durationMs)
    return durationMs > 500
  }

  //Record which node was set to interactive so we can disable it on roll-out.
  var objectWithMouseOver = null

  this.active = false

  this.activate = function () {
    container.dom.addEventListener('mousedown', onMouseDown, false)
    container.dom.addEventListener('touchstart', onTouchStart, false)
    container.dom.addEventListener('mousemove', onMouseMove, false)
    this.active = true
    hotkeys.attach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    container.dom.removeEventListener('mousedown', onMouseDown, false)
    container.dom.removeEventListener('touchstart', onTouchStart, false)
    container.dom.removeEventListener('mousemove', onMouseMove, false)
    this.active = false
    hotkeys.detach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  function handleClick() {
    var selectionUpdated = false

    //console.log('onDownPosition.distanceTo(onUpPosition)', onDownPosition.distanceTo(onUpPosition))

    //Needs to be greated than zero for Apple Pencil which always returns a non-zero value
    if (onDownPosition.distanceTo(onUpPosition) < 0.02) {
      var objects = viewport.objectsSelectableAndModules()

      // This is required for registering clicks on a glb loaded into another Object3D but will it cause
      // other problems like performance isuses or errors?
      var recursiveObjectUnderClick = true

      var intersects = viewport.getIntersects(onUpPosition, objects, undefined, recursiveObjectUnderClick)

      var object

      // seems a bit awkward, but I can't think of a more elegant way
      // to ignore module outlines, atm. This is due to the intersection detection being recursive
      // (see viewport.getIntersects() call above)
      intersects = intersects.filter((i) => i.object.name !== 'ModuleOutline')

      if (intersects.length > 0) {
        //Clickable objects always processed first if found
        //Otherwise select closest
        var clickableObjectsUnderClick = intersects.filter(function (i) {
          return i.object && i.object.clickable
        })

        if (clickableObjectsUnderClick.length > 0) {
          object = clickableObjectsUnderClick[0].object
        } else {
          object = intersects[0].object
        }

        // Removed: We now accept all clicks on modules
        // Modules which can be activated/deactivated are marked as not-selectable anyway
        if (object.type == 'OsModule' || (object.selectionDelegate && object.selectionDelegate.type == 'OsModule')) {
          if (editor.selected?.type === 'OsString') {
            //Allow us to retain selection of OsString when interacting with OsModules (i.e. when clicking strings)
            return
          } else if (object.parent && object.parent.selected() === false) {
            // Don't deselect, just don't update selection
            // If we already have an object selected it will remain selected
            //allow selection for inactive grids
          } else if (editor.selected?.type === 'OsGroup') {
          } else {
            //clicking on a module in the active grid, ignore selection (defer to modulePlacement controller)
            return
          }
        }

        if (object.userData.object !== undefined) {
          // helper

          editor.select(object.userData.object)
          selectionUpdated = true
        } else {
          //If clickable do not select it too
          if (object && object.onClick) {
            object.onClick(editor)
          } else if (object.selectable !== false) {
            editor.select(object)
            selectionUpdated = true
          }
        }
      } else {
        editor.select(null)
      }

      // Removed explicit render call - let other methods perform the render
      // viewport.render()
    }

    return selectionUpdated
  }

  function onMouseDown(event) {
    event.preventDefault()

    // Focus on the iframe every time we click on inside, otherwise focus can be lost anytime you click outside the iframe
    // and currently it will not be regained until they interact with a DOM element - interacting with the map or ThreeJS
    // does not regain focus unless we do it manually here.
    if (window.parent !== window) {
      window.focus()
    }

    // Quick hack to diable selection in MyE even when editor.interactive() is true
    if (editor.displayMode === 'presentation') {
      return
    }

    // @TODO: Remove the need for this hack, which fixes shift being stuck down when shift is pressed in studio
    // but released elsewhere.
    if (!event.shiftKey && window.shiftIsDown) {
      window.shiftIsDown = false
      console.log('window.shiftIsDown cleared')
    }

    if (event.button != 0) return

    var array = viewport.getMousePosition(event.clientX, event.clientY)
    onDownPosition.fromArray(array)

    document.addEventListener('mouseup', onMouseUp, false)

    longPressTimerStart()
  }

  function onMouseUp(event) {
    if (event.button != 0) return

    // Quick hack to diable selection in MyE even when editor.interactive() is true

    if (editor.displayMode === 'presentation') {
      return
    }

    var array = viewport.getMousePosition(event.clientX, event.clientY)
    onUpPosition.fromArray(array)

    var selectionUpdated = handleClick()

    document.removeEventListener('mouseup', onMouseUp, false)

    if (selectionUpdated && isLongPress() && window.Utils?.isTouchDevice()) {
      var array = viewport.getMousePosition(event.clientX, event.clientY)
      var onUpPositionLongPress = new THREE.Vector3().fromArray(array)

      var screenPosition = { x: event.clientX, y: event.clientY }
      editor.controllers.ContextMenu.handleRightClick(onUpPositionLongPress, screenPosition)
    }

    longPressTimerStop()
  }

  var _this = this

  function onMouseMove(event) {
    handleRollovers(event)
    _this.lastMouseClientPosition = { x: event.clientX, y: event.clientY }
  }

  function onTouchStart(event) {
    var touch = event.changedTouches[0]

    var array = viewport.getMousePosition(touch.clientX, touch.clientY)
    onDownPosition.fromArray(array)

    document.addEventListener('touchend', onTouchEnd, false)

    longPressTimerStart()
  }

  function onTouchEnd(event) {
    var touch = event.changedTouches[0]

    var array = viewport.getMousePosition(touch.clientX, touch.clientY)
    onUpPosition.fromArray(array)

    var selectionUpdated = handleClick()
    if (onDownPosition.distanceTo(onUpPosition) < 0.02 && isLongPress() && window.Utils?.isTouchDevice()) {
      var screenPosition = { x: touch.clientX, y: touch.clientY }
      editor.controllers.ContextMenu.handleRightClick(onUpPosition, screenPosition)
    }

    longPressTimerStop()

    document.removeEventListener('touchend', onTouchEnd, false)
  }

  function handleRollovers(event) {
    var changed = false

    var clickIntersection

    // While animations are running, we only allow handling this to clear a rollover to avoid major performance hit
    // which may try to re-rerender
    if (editor.viewport.isAnimating()) {
      clickIntersection = null
    } else {
      clickIntersection = viewport.clickIntersection(
        event,
        editor.filterObjects(function (o) {
          return o.handleMouseBehavior ? o : null
        }),
        false
      )
    }

    var objectUnderMouse = clickIntersection && clickIntersection.object

    if (objectUnderMouse == objectWithMouseOver && objectUnderMouse && !objectUnderMouse.forceRolloverUpdate) {
      //do nothing, alerady interactive
    } else if (objectUnderMouse) {
      objectUnderMouse.handleMouseBehavior(true, objectUnderMouse.forceRolloverUpdate, clickIntersection)

      if (objectWithMouseOver && objectUnderMouse != objectWithMouseOver) {
        objectWithMouseOver.handleMouseBehavior(false, undefined, clickIntersection)
      }

      objectWithMouseOver = objectUnderMouse

      changed = true
    } else if (objectWithMouseOver) {
      objectWithMouseOver.handleMouseBehavior(false)
      objectWithMouseOver = null
      changed = true
    }

    if (changed) {
      editor.render()
    }
  }

  return this
}
