/*
Specify the object, the method name to call on the object, and the (single) argument to inject into the method
We can only support simple string/number arguments, nothing too complicated.
The idea for this command is to make it generalizable to lots of object types and methods.
The method must be a getter-setter so we can retrieve the value by calling the method with no arguments
 */

var SetObjectTypeCommand = function (object, methodName, newMethodArgument, commandUUID) {
  Command.call(this)

  this.type = 'SetObjectTypeCommand'
  this.name = 'Set Object Type'
  // Unfortunately we cannot update this command because the same command could be used with different methods
  // In future, perhaps we could add a method which allows the command to dynamically determine whether it should update
  // based on compatibility with the previous command
  this.updatable = false
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['methodName', 'newMethodArgument', 'oldMethodArgument']
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.object = object
    this.methodName = methodName
    this.newMethodArgument = newMethodArgument
  }
}
SetObjectTypeCommand.prototype = {
  execute: function () {
    this.oldMethodArgument = this.object[this.methodName]()
    this.object[this.methodName](this.newMethodArgument)
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  undo: function () {
    this.object[this.methodName](this.oldMethodArgument)
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  // Update not enabled, see above for why
  // update: function (command) {
  //   this.newMethodArgument = command.newMethodArgument
  // },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
