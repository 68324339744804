/**
 * @param moduleGridUuid string
 * @param cellsActive Array of csv string coorinates in the form: ["col,row","col,row",...]
 * @constructor
 */

var SetModuleGridActiveModulesCommand = function (object, newCellsActive) {
  Command.call(this)

  this.type = 'SetModuleGridActiveModulesCommand'
  this.name = 'Set Active Modules for Grid'
  this.updatable = true
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()
  this.object = object
  this.stringModuleMapper = {}
  this.timeStamp = Date.now()
  if (object !== undefined && newCellsActive !== undefined) {
    this.oldCellsActive = this.object.cellsActive
    this.newCellsActive = newCellsActive
  }
  this.cellToUuid = {}
  this.prevElevation = object.position.z

  if (this.oldCellsActive) {
    this.oldCellsActive.forEach((cell) => {
      this.cellToUuid[cell] = object.moduleObjects[cell].uuid
    }, true)
  }

  // also inject uuids now instead of waiting for them to be created because it's easier to just do it deterministically
  // upfront rather than detecting after they have been created
  if (this.newCellsActive) {
    this.newCellsActive.forEach((cell) => {
      // only inject a new uuid if not already used
      if (!this.cellToUuid[cell]) {
        this.cellToUuid[cell] = THREE.Math.generateUUID()
      }
    }, this)
  }
}
SetModuleGridActiveModulesCommand.prototype = {
  execute: function () {
    editor.uiPause('render', 'SetModuleGridActiveModulesCommand')
    editor.uiPause('annotation', 'SetModuleGridActiveModulesCommand')
    editor.uiPause('ui', 'SetModuleGridActiveModulesCommand')
    editor.uiPause('calcs', 'SetModuleGridActiveModulesCommand')

    this.object.updateCellToUuid(this.cellToUuid)

    var forceClear = false

    this.oldCellsActive.forEach(
      function (cell) {
        if (this.newCellsActive.indexOf(cell) === -1 && !this.stringModuleMapper[cell]) {
          this.stringModuleMapper[cell] =
            this.object.moduleObjects[cell] &&
            this.object.moduleObjects[cell].assignedOsString &&
            this.object.moduleObjects[cell].assignedOsString.uuid
        }
      }.bind(this)
    )

    this.object.populate(this.newCellsActive, forceClear)
    window.SceneHelper.snapModuleGridToGroundLevel(this.object)

    this.object.children
      .filter(function (a) {
        return a.children.length === 0 && a.type === 'OsModule'
      })
      .forEach(function (a) {
        a.setGeometryForSize()
      })
    if (!this.editor.changingHistory) {
      let system = this.object.getSystem()
      if (system) system.requireClusters = true
    }
    this.object.refreshUserData()

    editor.uiResume('calcs', 'SetModuleGridActiveModulesCommand')

    if (!this.editor.changingHistory) {
      let system = this.object.getSystem()
      if (system) {
        Designer.requestSystemCalculations(system)
      }
    }

    // will call objectChanged.dispatch() --> editor.render()
    this.object.onModuleActivationChange(true)

    editor.uiResume('ui', 'SetModuleGridActiveModulesCommand')
    editor.uiResume('render', 'SetModuleGridActiveModulesCommand')
    editor.uiResume('annotation', 'SetModuleGridActiveModulesCommand')
  },

  undo: function () {
    if (this.loadFromJSON) {
      this.object = this.object && this.editor.scene.getObjectByProperty('uuid', this.object.object.uuid)
      this.loadFromJSON = false
    }
    var forceClear = false

    this.object.updateCellToUuid(this.cellToUuid)

    this.object.populate(this.oldCellsActive, forceClear)

    if (this.prevElevation !== undefined) {
      this.object.position.z = this.prevElevation
    }

    for (var cell in this.stringModuleMapper) {
      if (this.stringModuleMapper[cell]) {
        var string = this.editor.scene.getObjectByProperty('uuid', this.stringModuleMapper[cell])
        string && string.addModule(this.object.moduleObjects[cell])
      }
    }
    this.object.children
      .filter(function (a) {
        return a.children.length === 0 && a.type === 'OsModule'
      })
      .forEach(function (a) {
        a.setGeometryForSize()
      })
    this.object.refreshUserData()
    this.editor.deselect()
    this.editor.select(this.object)

    // will call objectChanged.dispatch() --> editor.render()
    this.object.onModuleActivationChange(true)
  },

  update: function (command) {
    /*
    Update newCellsActive from the new command but do not change oldCellsActive

    We do not need to worrry about updating stringModuleMapper because no newly added modules
    will be assigned to a string anyway.
    */
    this.newCellsActive = command.newCellsActive
    this.json = null
    this.json = this.toJSON()
  },

  toJSON: function () {
    if (this.json) {
      return this.json
    }
    var output = Command.prototype.toJSON.call(this)
    output.oldCellsActive = this.oldCellsActive
    output.newCellsActive = this.newCellsActive
    output.objectUuid = this.object.uuid
    output.cellToUuid = this.cellToUuid
    if (this.prevElevation !== undefined) {
      output.prevElevation = this.prevElevation
    }
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.object = this.editor.objectByUuid(json.objectUuid)
    this.newCellsActive = json.newCellsActive
    this.oldCellsActive = json.oldCellsActive
    this.stringModuleMapper = {}
    this.cellToUuid = json.cellToUuid
    this.oldPosition = json.oldPosition
    if (this.prevElevation !== undefined) {
      this.prevElevation = json.prevElevation
    }
  },
}
