/**
 * @author mrdoob / http://mrdoob.com/
 */

var Config = function (name) {
  var storage = {
    autosave: true,
    theme: '/static/css/light.css',

    'project/renderer': 'WebGLRenderer',
    'project/renderer/antialias': true,
    'project/renderer/gammaInput': false,
    'project/renderer/gammaOutput': false,
    'project/renderer/shadows': true,
    'project/vr': false,

    'settings/history': false,
  }

  if (window.getStorage()[name] === undefined) {
    window.getStorage()[name] = JSON.stringify(storage)
  } else {
    var data = JSON.parse(window.getStorage()[name])

    for (var key in data) {
      storage[key] = data[key]
    }
  }

  return {
    getKey: function (key) {
      return storage[key]
    },

    setKey: function () {
      console.log('Warning: Config.setKey() disabled until implemented properly')
      return

      // key, value, key, value ...

      for (var i = 0, l = arguments.length; i < l; i += 2) {
        storage[arguments[i]] = arguments[i + 1]
      }

      window.getStorage()[name] = JSON.stringify(storage)

      console.log('[' + /\d\d\:\d\d\:\d\d/.exec(new Date())[0] + ']', 'Saved config to LocalStorage.')
    },

    clear: function () {
      delete window.getStorage()[name]
    },
  }
}
