class ContextMenu {
  #OFFSET_X_AXIS = 5
  #OFFSET_Y_AXIS = 0

  // a reference to the root dom element of this context menu
  #domElement
  // an optional reference to a callback function
  // that's called when a choice in the menu is selected
  #onSelectCallback
  // for cancelling the delay timeout, if needed
  #delayTimer

  constructor(menu) {
    this.#domElement = this.#generateDomElements(menu)
  }

  ////////////////////////////////////////////////
  //            PUBLIC METHODS
  ////////////////////////////////////////////////

  show = ({ position, viewportDomElement, delay, onSelectCallback }) => {
    this.#delayTimer = setTimeout(() => {
      this.#render(position, viewportDomElement)
    }, delay)
    this.#onSelectCallback = onSelectCallback
  }

  hide = () => {
    clearTimeout(this.#delayTimer)
    const rows = this.#domElement.getElementsByClassName('studio-cmenu-row')
    for (let i = 0; i < rows.length; i++) {
      rows[i].classList.remove('animate-in')
    }
    this.#domElement.remove()
  }

  ////////////////////////////////////////////////
  //            PRIVATE METHODS
  ////////////////////////////////////////////////

  #render = (position, viewportDomElement) => {
    const viewportSize = viewportDomElement.getBoundingClientRect()

    // IMPORTANT: the context menu DOM element must be attached first to the DOM
    // so that any DOM manipulations and queries (like getBoundingClientRect()) works
    viewportDomElement.appendChild(this.#domElement)

    const menuDomSize = this.#domElement.getBoundingClientRect()

    // calculate if the context menu should unfold left of the target position
    // to avoid being clipped by the right edge of the viewport
    const unfoldLeftwards = position.x + this.#OFFSET_X_AXIS + menuDomSize.width > viewportSize.width
    let startX = unfoldLeftwards
      ? position.x - menuDomSize.width - this.#OFFSET_X_AXIS
      : position.x + this.#OFFSET_X_AXIS

    // calculate if the context menu should unfold above the target position
    // to avoid being clipped by the bottom edge of the viewport
    const unfoldUpwards = position.y + this.#OFFSET_Y_AXIS + menuDomSize.height > viewportSize.height
    let startY = unfoldUpwards
      ? position.y - menuDomSize.height - this.#OFFSET_Y_AXIS
      : position.y + this.#OFFSET_Y_AXIS

    if (startX < 0) {
      startX = 0 + this.#OFFSET_X_AXIS
    }
    if (startY < 0) {
      startY = 0 + this.#OFFSET_Y_AXIS
    }

    // position the context menu dom element
    // relative to the top and left of the viewport
    this.#domElement.style.top = `${startY}px`
    this.#domElement.style.left = `${startX}px`

    // animate rows
    const rows = this.#domElement.getElementsByClassName('studio-cmenu-row')
    for (let i = 0; i < rows.length; i++) {
      rows[i].classList.add('animate-in')
    }
  }

  #onChoiceSelect = (choice) => {
    if (choice.onClick) choice.onClick()
    // do this at the very last in case the callback involves disposing/dereferencing this instance
    // which might dispose the orphaned DOM element
    if (this.#onSelectCallback) this.#onSelectCallback(choice)
  }

  #generateDomElements = (menu) => {
    const rootDomElement = document.createElement('div')
    rootDomElement.classList.add('studio-cmenu')

    menu.forEach((choice, index) => {
      const row = document.createElement('div')

      row.addEventListener('mousedown', (event) => {
        event.stopPropagation()
        this.#onChoiceSelect(choice)
      })

      row.addEventListener('touchstart', (event) => {
        event.stopImmediatePropagation()
        event.stopPropagation()
        this.#onChoiceSelect(choice)
      })

      row.classList.add('studio-cmenu-row')
      choice.selected && row.classList.add('selected')
      row.style.animationDelay = `${0.01 * index}s`
      row.innerHTML = choice.label

      rootDomElement.appendChild(row)
    })

    return rootDomElement
  }
}

/**
 * @author adampryor
 */
const ContextMenuController = function (editor, viewport) {
  this.name = 'ContextMenu'

  var container = viewport.container

  var onDownPosition = new THREE.Vector2()
  var onUpPosition = new THREE.Vector2()

  let contextMenu = null

  //@TODO: Require different scale on Tablet/Mobile???
  var linePrecision = 0.5

  this.activate = function () {
    container.dom.addEventListener('mousedown', onMouseDown, false)
    container.dom.addEventListener('touchstart', onMouseDown, false)
    container.dom.addEventListener('contextmenu', onContextMenu, false)
    this.active = true
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    container.dom.removeEventListener('mousedown', onMouseDown, false)
    container.dom.removeEventListener('touchstart', onMouseDown, false)
    container.dom.removeEventListener('contextmenu', onContextMenu, false)
    this.active = false
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  function handleRightClickDefault(position, screenPosition) {
    const objectsRightClicked = viewport.getIntersects(position, viewport.objectsWithContextMenu(), linePrecision)
    if (objectsRightClicked.length === 0) return // no object to generate context menu, early exit

    // mulitple objects may return the same contextMenuDelegator
    // ensure we only reference each object once
    const contextMenuGeneratorsProcessed = []

    // We removed an extra check which attempted to prevent edges from being processed twice but this was
    // redundant because we already check to ensure the same object does not get processed twice.
    // But this requires that we omit extra non-editable ghost/wall edges which get added to the scene
    // automatically. We omit edges where !!this.isWallCorner || !!this.ghostMode().

    let contextMenuItems = []

    objectsRightClicked.forEach(function (intersection) {
      if (intersection.object.getContextMenuAction) {
        intersection.object.getContextMenuAction(editor)
        return
      }

      // Check whether menu is generated by object itself, or its selection delegate
      const contextMenuGeneratorObject = intersection.object.getContextMenuItems
        ? intersection.object
        : intersection.object.selectionDelegate

      if (!contextMenuGeneratorObject) return

      const hasProcessedGenObject = contextMenuGeneratorsProcessed.indexOf(contextMenuGeneratorObject) >= 0
      if (hasProcessedGenObject) return

      // Note: we removed a restriction of only allowing one of each object type to be processed which is
      // not appropriate for most object types. There may be some that have been overlooked and should be 
      // have this restriction re-applied. This restriction was particularly bad because it did not even
      // automatically select the object closest to the click point, so it was arbitrary and often
      // made it impossible to select certain objects.

      const hasPermission = contextMenuGeneratorObject.getPermissionCheck
        ? contextMenuGeneratorObject.getPermissionCheck()
        : window.Designer.permissions.canEdit()

      if (!hasPermission) return

      const additionalItems = contextMenuGeneratorObject.getContextMenuItems(intersection.point)
      contextMenuItems.push(...additionalItems)

      contextMenuGeneratorsProcessed.push(contextMenuGeneratorObject)
    })

    const extensionOptions = []
    contextMenuGeneratorsProcessed.forEach((object) => {
      Object.keys(editor.extensions).forEach((extKey) => {
        const extension = editor.extensions[extKey]
        if (!extension.isActive) return
        if (!extension.getContextMenuItemsForObject) return
        extensionOptions.push(...extension.getContextMenuItemsForObject(object))
      })
    })

    // move "Select" type options to bottom
    const nonSelectOptions = contextMenuItems.filter((i) => !i.label.startsWith('Select'))

    // sort selectOptions alphabetically which groups each type together
    const selectOptions = contextMenuItems.filter((i) => i.label.startsWith('Select')).sort((a, b) => {
      return (a.label < b.label) ? -1 : 1
    })

    contextMenuItems = [...nonSelectOptions, ...selectOptions, ...extensionOptions]

    // if more than one edge is intersected, exclude all edgeType menu items
    if (
      contextMenuItems.filter(function (i) {
        return i.label.toLowerCase().indexOf('edge type: default') !== -1
      }).length > 1
    ) {
      contextMenuItems = contextMenuItems.filter(function (i) {
        return i.label.toLowerCase().indexOf('edge type:') === -1
      })
    }

    if (contextMenuItems.length === 0) return // no context menu items to draw
    drawContextMenu(screenPosition, contextMenuItems)
  }

  function handleRightClickGroup(position, screenPosition, group) {
    if (!window.Designer.permissions.canEdit()) {
      // brutely disabled context menu for non-editors.
      return false
    }
    const objectsRightClicked = viewport.getIntersects(position, [...group.objects], linePrecision, true)
    if (objectsRightClicked.length === 0) return // no object to generate context menu, early exit

    let contextMenuItems = group.getContextMenuItems()

    Object.keys(editor.extensions).forEach((extKey) => {
      const extension = editor.extensions[extKey]
      if (!extension.isActive) return
      if (!extension.getContextMenuItemsForObject) return
      contextMenuItems.push(...extension.getContextMenuItemsForObject(group))
    })

    if (contextMenuItems.length === 0) return // no context menu items to draw
    drawContextMenu(screenPosition, contextMenuItems)
  }

  function handleRightClick(position, screenPosition) {
    if (editor.selected?.type === 'OsGroup') {
      handleRightClickGroup(position, screenPosition, editor.selected)
    } else {
      handleRightClickDefault(position, screenPosition)
    }
  }
  this.handleRightClick = handleRightClick

  function drawContextMenu(screenPosition, contextMenuItems) {
    contextMenu = new ContextMenu(contextMenuItems)
    const viewportDomElement = viewport.container.dom
    contextMenu.show({
      position: screenPosition,
      viewportDomElement: viewportDomElement,
      delay: 0,
      onSelectCallback: (_choice) => {
        removeContextMenu()
      },
    })
  }
  this.drawContextMenu = drawContextMenu

  function removeContextMenu() {
    if (contextMenu) {
      contextMenu.hide()
      contextMenu = null
    }
  }

  function onMouseDown(event) {
    //Clear menu on ANY mouseDown, any button type, regardless of any intersections
    removeContextMenu()

    if (event.button !== 2) {
      return
    }

    event.preventDefault()

    var array = viewport.getMousePosition(event.clientX, event.clientY)
    onDownPosition.fromArray(array)

    document.addEventListener('mouseup', onMouseUp, false)
  }

  function onMouseUp(event) {
    if (event.button !== 2) {
      return
    }

    var array = viewport.getMousePosition(event.clientX, event.clientY)
    onUpPosition.fromArray(array)

    document.removeEventListener('mouseup', onMouseUp, false)

    const viewportRect = viewport.container.dom.getBoundingClientRect()
    const screenPosition = { x: event.clientX - viewportRect.left, y: event.clientY - viewportRect.top }

    if (onUpPosition.distanceTo(onDownPosition) === 0) {
      handleRightClick(onUpPosition, screenPosition)
    }
  }

  function onContextMenu(event) {
    // Disable default browser behavior of contextmenu event
    event.preventDefault()
  }

  return this
}

window.ContextMenuController = ContextMenuController
