/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var SaveViewCommand = function (ViewHelper, viewIndex) {
  Command.call(this)

  this.type = 'SaveViewCommand'
  this.name = 'Save View Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['viewIndex', 'newViewData', 'oldViewData']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.viewIndex = viewIndex
    this.newViewData = null
    this.oldViewData = null
  }
}

SaveViewCommand.prototype = {
  execute: function () {
    // Only populate this.oldViewData during first execution
    // If we redo the action then use the saved this.oldViewData instead
    if (!this.oldViewData) {
      this.oldViewData = this.ViewHelper.views[this.viewIndex].toObject()
      this.ViewHelper.saveView(this.viewIndex)
      this.newViewData = this.ViewHelper.views[this.viewIndex].toObject()
    } else {
      const newViewInstance = new window.ViewInstance(this.newViewData)
      this.ViewHelper.views[this.viewIndex] = newViewInstance
      this.ViewHelper.storeViews(this.ViewHelper.views)
      this.ViewHelper.loadView(newViewInstance, this.editor)
    }
  },

  undo: function () {
    const oldViewInstance = new window.ViewInstance(this.oldViewData)
    this.ViewHelper.views[this.viewIndex] = oldViewInstance
    this.ViewHelper.storeViews(this.ViewHelper.views)
    this.ViewHelper.loadView(oldViewInstance, this.editor)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
