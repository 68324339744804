/**
 * @author adampryor
 */

var OsAnnotationCache = {}

var ANNOTATION_COLORS = [
  '#D0021B',
  '#F5A623',
  '#F2E100',
  '#8B572A',
  '#7ED321',
  '#BD10E0',
  '#4A90E2',
  '#50E3C2',
  '#B8E986',
  '#417505',
  '#9013FE',
]

var DEBUG = false
var THREEClass = DEBUG ? THREE.Mesh : THREE.Object3D

function OsAnnotation(options) {
  THREEClass.call(this)

  if (DEBUG) {
    // Useful for debugging the location of the 3D object versus the location of the HTML annotation
    this.geometry = new THREE.SphereBufferGeometry(0.5)
    this.material = new THREE.MeshStandardMaterial({
      color: 0xff0000,
    })
  }

  this.override_show_customer =
    options && options.hasOwnProperty('override_show_customer') && typeof options.override_show_customer === 'boolean'
      ? options.override_show_customer
      : false

  this.castShadow = true
  this.timestamp = Date.now()

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  // this.updateMorphTargets()

  this.type = 'OsAnnotation'
  this.name = 'OsAnnotation'
  this.description = options && options.hasOwnProperty('description') ? options.description : ''
  this._ghostMode = options && options.hasOwnProperty('ghostMode') ? options.ghostMode : false
  this.label = options && options.hasOwnProperty('label') ? options.label : ''
  this.floatAppliesOrientation = false
  this.ephemeral = false

  this.lookAt(new THREE.Vector3(0, 0, 1000))
}

OsAnnotation.prototype = Object.assign(Object.create(THREEClass.prototype), {
  constructor: OsAnnotation,
  getName: function () {
    return 'Annotation'
  },
  toolsActive: function () {
    return {
      translateXY: true,
      translateZ: true,
      translateX: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },
  confirmBeforeDelete: function () {
    return 'Delete Annotation?'
  },
  transformWithLocalCoordinates: function () {
    return false
  },
  belongsToGroup: ObjectBehaviors.belongsToGroup,
  onChange: ObjectBehaviors.floatingOnFacetOnChange,
  onRemove: function (editor) {
    if (this.facet) {
      this.facet.removeFloatingObject(this)
    }
  },
  onMouseDown: function (e) {
    e.stopPropagation()
    if (this.ghostMode()) {
      window.editor.controllers.AddObject.onMouseDown(e)
    } else {
      window.editor.selectByUuid(this.uuid)
    }
  },
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,
  applyGhostMode: function (value) {
    // if (value) {
    //   this.material = OsAnnotationCache.materialGhostMode
    // } else {
    //   this.material = OsAnnotationCache.material
    // }
  },

  getContextMenuItems: function (position) {
    var _this = this

    var menuItems = [
      {
        label: window.translate(`Select ${this.getName()}`),
        useHTML: false,
        selected: false,
        onClick: function () {
          if (editor) {
            editor.select(_this)
          }
        },
      },
    ]

    return menuItems
  },

  applyUserData: function () {
    this.override_show_customer =
      typeof this.userData.override_show_customer === 'boolean' ? this.userData.override_show_customer : false
    this.label = this.userData.label
    this.description = this.userData.description
    this.timestamp = this.userData.timestamp
  },

  refreshUserData: function () {
    this.userData.override_show_customer =
      typeof this.override_show_customer === 'boolean' ? this.override_show_customer : false
    this.userData.label = this.label
    this.userData.description = this.description
    this.userData.timestamp = this.timestamp
    return this.userData
  },

  getAnnotation: function () {
    var colorForBackground
    if (editor.selected === this || this.belongsToGroup(editor.selected)) {
      colorForBackground = '#ffda00'
    } else if (window.annotationDisplayMode === 'pro' && window.editor.displayMode === 'presentation') {
      colorForBackground = ANNOTATION_COLORS[(this.number - 1) % ANNOTATION_COLORS.length]
    } else {
      colorForBackground = '#ffffff'
    }

    var annotationStyle = `style="background: ${colorForBackground}"`

    const annotationClass = this.ephemeral ? 'annotation-ephemeral' : 'clickable-annotation'

    return {
      position: this.position,
      content: `<div class="${annotationClass}" ${annotationStyle} onmousewheel="window.editor.controllers.Camera&&window.editor.controllers.Camera.domElement.dispatchEvent(new WheelEvent('mousewheel',event))" onclick="window.editor.objectByUuid('${
        this.uuid
      }').onMouseDown(event)"><span>${this.label || this.number}</span></div>`,
    }
  },

  duplicate: function () {
    editor.controllers['AddObject'].start('OsAnnotation', false, this)
  },
})

OsAnnotation.getCompareString = function (annotation) {
  if (!!annotation.compareString) {
    return annotation.compareString
  }
  // We must append description to the end of label when sorting to ensure annotations with the same
  // label are sorted in the same order when descriptions may differ
  var compareString = ''
  if (annotation['label'] && !/[A-Za-z0-9]/.test(annotation['label'].charAt(0))) {
    // handle special characters
    compareString += 'zzz'
  }

  if (annotation['label']) {
    compareString += 'label'
    compareString += annotation['label']

    if (annotation['description']) {
      // Add 0 suffix to ensure a label which is a substring of another longer label
      // always goes first regardless of the first letter of description
      compareString += '0' + annotation['description']
    }
  }

  compareString += annotation['timestamp']

  return compareString
}

OsAnnotation.uniqueSortedWithAutoLabels = function (annotations) {
  var annotationHashesAlreadyRendered = []

  return annotations
    .filter((annotation) => window.annotationDisplayMode === 'pro' || Boolean(annotation.override_show_customer))
    .sort((a, b) => (OsAnnotation.getCompareString(a) > OsAnnotation.getCompareString(b) ? 1 : -1))
    .filter((annotation) => {
      var hash = annotation.label + '0' + annotation.description
      if (!annotation.label) {
        //show annotation as an individual paragraph
        //use index from sorted list as label
        return true
      }
      if (annotationHashesAlreadyRendered.indexOf(hash) === -1) {
        annotationHashesAlreadyRendered.push(hash)
        return true
      } else {
        return false
      }
    })
}
