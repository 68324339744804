/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param editorRef pointer to main editor object used to initialize
 *        each command object with a reference to the editor
 * @constructor
 */

var Command = function (editorRef) {
  this.id = -1
  this.inMemory = false
  this.updatable = false
  this.type = ''
  this.name = ''

  if (editorRef !== undefined) {
    Command.editor = editorRef
  }
  this.editor = Command.editor

  /*
  List of simple property names that will automatically be copied in toJSON/fromJSON
  */
  this.PROPERTIES = []

  /*
  List of properties which represent an object in the scene. toJSON will store a reference to the object uuid and
  fromJSON will store a reference to the object by looking it up using the serialized object uuid.
  e.g. 'node' will create a property this.node and will serialize with json.nodeUuid = this.node.uuid

  */
  this.OBJECT_REFERENCES = []
}

Command.prototype.toJSON = function () {
  var output = {}
  output.timeStamp = this.timeStamp
  output.type = this.type
  output.commandUUID = this.commandUUID
  output.id = this.id
  output.name = this.name
  output.inMemory = this.inMemory
  output.updatable = this.updatable

  // If this.PROPERTIES is set, these properties will be copied automatically to avoid needing custom code
  if (this.PROPERTIES) {
    this.PROPERTIES.forEach((property) => {
      output[property] = this[property]
    }, this)
  }

  if (this.OBJECT_REFERENCES) {
    this.OBJECT_REFERENCES.forEach((objectReference) => {
      output[objectReference + 'Uuid'] = this[objectReference].uuid
    }, this)
  }

  return output
}

Command.prototype.fromJSON = function (json) {
  this.inMemory = true
  this.type = json.type
  this.timeStamp = json.timeStamp
  this.id = json.id
  this.name = json.name

  // If this.PROPERTIES is set, these properties will be copied automatically to avoid needing custom code
  if (this.PROPERTIES) {
    this.PROPERTIES.forEach((property) => {
      this[property] = json[property]
    }, this)
  }

  if (this.OBJECT_REFERENCES) {
    this.OBJECT_REFERENCES.forEach((objectReference) => {
      this[objectReference] = this.editor.objectByUuid(json[objectReference + 'Uuid'])
    }, this)
  }
}

Command.prototype.toObject = function () {
  var output = Object.assign({}, this)
  delete output.editor
  for (var key in output) {
    if (Utils.isObject(this[key])) {
      output[key] = Object.assign({}, this[key])
    }
  }
  return output
}
