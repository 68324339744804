/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newPosition THREE.Vector3
 * @param optionalOldPosition THREE.Vector3
 * @constructor
 */

var SetPositionCommand = function (object, newPosition, optionalOldPosition, commandUUID, opts = {}) {
  Command.call(this)

  const DEFAULT_OPTS = {
    skipAutoOrientation: false,
  }
  opts = typeof opts === 'object' ? { ...DEFAULT_OPTS, ...opts } : DEFAULT_OPTS

  this.timeStamp = Date.now()
  this.type = 'SetPositionCommand'
  this.name = 'Set Position'
  this.updatable = true
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()
  this.skipAutoOrientation = opts.skipAutoOrientation

  this.PROPERTIES = []
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.object = object
    this.oldPosition = object.position.clone()
    this.newPosition = newPosition.clone()
    if (optionalOldPosition) {
      this.oldPosition = optionalOldPosition.clone()
    }
  }
}
SetPositionCommand.prototype = {
  execute: function () {
    this.object.position.copy(this.newPosition)
    this.object.updateMatrixWorld(true)
    this.editor.signals.objectChanged.dispatch(this.object, 'setPosition', {
      skipAutoOrientation: this.skipAutoOrientation,
    })
    if (this.object.type === 'OsModuleGrid') {
      this.object.onChange(this.editor, false, false, { skipAutoOrientation: this.skipAutoOrientation })
    }
  },

  undo: function () {
    this.object.position.copy(this.oldPosition)
    this.object.updateMatrixWorld(true)
    this.editor.signals.objectChanged.dispatch(this.object, 'setPosition', {
      skipAutoOrientation: this.skipAutoOrientation,
    })
    if (this.object.type === 'OsModuleGrid') {
      this.object.onChange(this.editor, false, false, { skipAutoOrientation: this.skipAutoOrientation })
    }
  },

  update: function (command) {
    this.newPosition.copy(command.newPosition)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    output.oldPosition = this.oldPosition.toArray()
    output.newPosition = this.newPosition.toArray()
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.oldPosition = new THREE.Vector3().fromArray(json.oldPosition)
    this.newPosition = new THREE.Vector3().fromArray(json.newPosition)
  },
}
