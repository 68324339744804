/**
 * GLOBAL DEPENDENCIES: _ (lodash), THREE, ol (OpenLayers)
 *
 * This extension adds the ability to take "snapshots" of systems currently
 * displayed in studio
 * Also built-in is a simple method of tracking visual changes to the
 * systems, the environment around the systems (facets, obstructions, etc)
 * and the view settings (camera zoom, position, etc), which can be used to
 * invalidate previously-taken snapshots
 */
class SnapshotGenerator {
  #MIN_SNAPSHOT_SIZE = [32, 32]
  #DEFAULT_SNAPSHOT_SIZE = [550, 330]

  isActive = false

  #editor = null
  #editorVisualState = null
  #cleanup = []

  constructor(editor) {
    if (!(editor instanceof Editor)) {
      throw new Error('Cannot instantiate SnapshotGenerator extension: Invalid Editor instance!')
    }
    if (!document) {
      throw new Error('instantiate SnapshotGenerator extension: No access to the DOM!')
    }
    this.#editor = editor
  }

  // Whether now is a good time to take a snapshot
  isAvailable = () => {
    return !this.#editor.sceneIsLoading && !!window.ViewHelper.selectedView()
  }
  compatibleMapImagery = () => {
    if (!window.ViewHelper.selectedView()) return false
    const mapType = window.ViewHelper.selectedView().mapData.mapType
    return mapType !== 'GoogleTop' && mapType !== 'GoogleRoadMap'
  }

  activate = () => {
    this.isActive = true
    if (this.isAvailable()) {
      this.updateEditorVisualState() // record the visual state if a scene has already been loaded
    }
    // if a new scene is loaded, overwrite the visual state
    this.#editor.signals.sceneLoaded.add(this.updateEditorVisualState)
    this.#cleanup.push(() => {
      this.#editor.signals.sceneLoaded.remove(this.updateEditorVisualState)
      this.#editorVisualState = null
    })
  }

  deactivate = () => {
    this.isActive = false
    this.#cleanup.forEach((f) => f())
  }

  /**
   * Generates snapshots of the currently-selected view
   * one snapshot is for one system (as defined by the 'systemUuids' options param)
   * @param {{ size?: [int, int], viewUuid?: string, systemUuids?: string[], showDesignGuides?: boolean, backgroundFill?: string, scale?: number }} options
   * @returns {Promise<HTMLCanvasElement[]>}
   */
  generate = async (options = {}) => {
    const currentView = window.ViewHelper.selectedView()

    const DEFAULT_OPTIONS = {
      viewUuid: currentView.uuid,
      size: this.#DEFAULT_SNAPSHOT_SIZE.slice(),
      systemUuids: this.#editor.getSystems().map((s) => s.uuid),
      showDesignGuides: false,
      scale: 1,
    }

    options = { ...DEFAULT_OPTIONS, ...options }

    if (options.viewUuid !== currentView.uuid) {
      throw new Error('SnapshotGenerator Error: taking snapshots of a non-selected view is currently not supported!')
    }

    if (!Array.isArray(options.size) || options.size.length !== 2) {
      throw new Error("SnapshotGenerator Error: 'size' must be an array of two numbers.")
    }

    if (!Array.isArray(options.systemUuids) && options.systemUuids !== null) {
      throw new Error("SnapshotGenerator Error: 'systemUuids' must be an array or null.")
    }

    const widthPx = Math.floor(options.size[0])
    const heightPx = Math.floor(options.size[1])
    options.compressionRatio = Math.clamp(options.compressionRatio, 0, 1)

    if (widthPx < this.#MIN_SNAPSHOT_SIZE[0] || heightPx < this.#MIN_SNAPSHOT_SIZE[1]) {
      throw new Error(`SnapshotGenerator Error: Snapshot size must be at least ${this.#MIN_SNAPSHOT_SIZE.toString()}.`)
    }

    const isTaintedCanvas = (canvas) => {
      try {
        canvas.getContext('2d').getImageData(0, 0, 1, 1)
        return false
      } catch (e) {
        return true
      }
    }

    const mapWidthPx = Math.floor(options.size[0] / options.scale)
    const mapHeightPx = Math.floor(options.size[1] / options.scale)

    let mapCanvases = await this.#captureCurrentMapLayers(mapWidthPx, mapHeightPx, options)
    let systemCanvases = this.#captureCurrentStudioScene(widthPx, heightPx, options)

    let unfilteredCount = mapCanvases.length
    mapCanvases = mapCanvases.filter((mc) => !isTaintedCanvas(mc))
    if (mapCanvases.length < unfilteredCount) {
      console.warn('A map layer was skipped because it was tainted (CORS error).')
    }

    const tempCanvas = document.createElement('canvas')
    const tempCanvasCtx = tempCanvas.getContext('2d')
    tempCanvas.width = widthPx
    tempCanvas.height = heightPx

    systemCanvases.forEach((systemCanvas) => {
      if (options.backgroundFill) {
        tempCanvasCtx.fillStyle = options.backgroundFill
        tempCanvasCtx.fillRect(0, 0, widthPx, heightPx)
      }
      // draw the map captures to the temp canvas
      mapCanvases.forEach((mc) => tempCanvasCtx.drawImage(mc, 0, 0, mapWidthPx, mapHeightPx, 0, 0, widthPx, heightPx))
      // then draw the webgl capture
      tempCanvasCtx.drawImage(systemCanvas, 0, 0)
      // overwrite the system canvas with the temp canvas data
      systemCanvas.getContext('2d').drawImage(tempCanvas, 0, 0)
      // clear the temp canvas for the next system
      tempCanvasCtx.clearRect(0, 0, widthPx, heightPx)
    })

    return Promise.resolve(systemCanvases)
  }

  /**
   * Returns an object that summarizes the "equality" between the
   * recorded editor visual state and the state at the time of invocation
   * Example result:
   * {
   *    view: true, // the view settings (camera zoom, position, etc) hasn't changed
   *    env: true, // the environment (facets, obstructions, etc) hasn't changed
   *    systems: {
   *      'E346AA60-950F-4A99-9180-49F0C468D7EA': false, // this system is either newly-added or was visually modified
   *      'A98373C0-45D8-4DC8-9997-9CE5955EB867': true // this system hasn't changed
   *    }
   * }
   * @returns {{ view: boolean, env: boolean, systems: {[uuid: string]: boolean} }}
   */
  getEditorVisualStateDiffFlags = () => {
    if (!this.#editorVisualState) {
      throw new Error('SnapshotGenerator Error: invalid initial editor visual state!')
    }

    const prevState = this.#editorVisualState
    const currentState = this.#getEditorVisualState()
    const prevAndCurrentViewIsEqual = _.isEqual(prevState.view, currentState.view)
    const prevAndCurrentEnvIsEqual = _.isEqual(prevState.env, currentState.env)

    const systemsDiffResults = {}
    for (let suuid in currentState.systems) {
      systemsDiffResults[suuid] = prevState.systems[suuid]
        ? _.isEqual(prevState.systems[suuid], currentState.systems[suuid])
        : false
    }
    return {
      view: prevAndCurrentViewIsEqual,
      env: prevAndCurrentEnvIsEqual,
      systems: systemsDiffResults,
    }
  }

  /**
   * Updates the stored editor visual state with the state at the time of invocation
   */
  updateEditorVisualState = (systemsUuids) => {
    if (!this.isAvailable()) {
      this.#editorVisualState = null
      return
    }
    let visualState = this.#getEditorVisualState()
    if (systemsUuids) {
      for (const systemUuid in Object.keys(this.#editorVisualState.systems)) {
        if (!systemsUuids[systemUuid] && visualState.systems[systemUuid]) {
          visualState.systems[systemUuid] = this.#editorVisualState.systems[systemUuid]
        }
      }
    }
    this.#editorVisualState = visualState
    return this.#editorVisualState
  }

  #previousRenderer = null

  /**
   * Capture the ThreeJS scene referenced by the Editor instance
   * using the current camera settings (except viewport size and aspect ratio)
   * The capture will have a completely-transparent background color
   * further processing will be required to have an opaque background color
   * @param {int} widthPx
   * @param {int} heightPx
   * @param {{ systemUuids?: string[] | null, showDesignGuides?: boolean }} options
   * @returns {HTMLCanvasElement[]}
   */
  #captureCurrentStudioScene = (widthPx, heightPx, options = {}) => {
    const afterCaptureCleanup = []

    let renderer = this.#previousRenderer
    if (!renderer) {
      if (renderer) {
        // Dispose of old renderer
        renderer.dispose()
      }

      // create the webgl canvas
      const canvas = document.createElement('canvas')
      canvas.width = widthPx
      canvas.height = heightPx

      // create the webgl renderer
      // @TODO: add support for software rendering for environments
      // that don't have access to WebGL
      renderer = new THREE.WebGLRenderer({
        alpha: true, // must be transparent like studio canvas
        antialias: false, // probably not necessary for a thumbnail
        preserveDrawingBuffer: true,
        canvas,
      })

      // Disable shader error checking (improves perf by about 15%)
      renderer.debug.checkShaderErrors = false

      renderer.setSize(widthPx, heightPx)
      renderer.setClearColor(new THREE.Color(1, 1, 1), 0) // transparent clear color
      this.#previousRenderer = renderer
    } else {
      renderer.clear()
      renderer.setSize(widthPx, heightPx)
      renderer.domElement.width = widthPx
      renderer.domElement.height = heightPx
    }

    // initialize the capture camera
    const viewSize = this.#editor.metersPerPixel() * widthPx * this.#editor.camera.zoom
    const aspectRatio = widthPx / heightPx

    const dupCamera = new THREE.OrthographicCamera(
      -viewSize / 2,
      viewSize / 2,
      viewSize / aspectRatio / 2,
      -viewSize / aspectRatio / 2,
      this.#editor.camera.near,
      this.#editor.camera.far
    )

    dupCamera.zoom = this.#editor.camera.zoom
    dupCamera.position.copy(this.#editor.camera.position)

    dupCamera.up.copy(this.#editor.camera.up)
    window.Utils.lookAtSafe(dupCamera, this.#editor.cameraCenter)
    dupCamera.updateMatrixWorld()
    dupCamera.updateProjectionMatrix()
    if (options.scale) dupCamera.scale.multiplyScalar(1 / options.scale)

    // temporarily hide compass and other studio widgets
    const widgets = this.#editor.filter('type', 'OsViewWidget')[0]
    if (widgets?.visible) {
      widgets.visible = false
      afterCaptureCleanup.push(() => {
        widgets.visible = true
      })
    }

    // temporarily hide visible OsNode instances
    const nodes = this.#editor.filter('type', 'OsNode').filter((n) => n.visible)
    nodes.forEach((n) => {
      n.visible = false
    })
    afterCaptureCleanup.push(() => {
      nodes.forEach((n) => {
        n.visible = true
      })
    })

    // temporarily hide or show design guides
    if (this.#editor.getDesignGuidesVisibility() !== options.showDesignGuides) {
      const currentDesignGuidesViz = this.#editor.getDesignGuidesVisibility()
      const measurements = this.#editor.filter('type', 'Measurements')

      afterCaptureCleanup.push(() => {
        this.#editor.setDesignGuidesVisibility({
          visible: currentDesignGuidesViz,
          showNotifWhenDone: false,
          renderWhenDone: false,
          includeAnnotations: false,
          includeMeasurements: false,
        })
        measurements.forEach((m) => {
          m.visible = currentDesignGuidesViz
        })
      })

      this.#editor.setDesignGuidesVisibility({
        visible: options.showDesignGuides,
        showNotifWhenDone: false,
        renderWhenDone: false,
        includeAnnotations: false,
        includeMeasurements: false,
      })
      measurements.forEach((m) => {
        m.visible = false
      })
    }

    const systemsInScene = this.#editor.getSystems()
    const selectedSystem = this.#editor.selectedSystem

    afterCaptureCleanup.push(() => {
      systemsInScene.forEach((system) => {
        system.visible = system === selectedSystem
      })
    })

    const createCanvas = (id) => {
      const canvas = document.createElement('canvas')
      canvas.width = widthPx
      canvas.height = heightPx
      canvas.id = id
      return canvas
    }

    const systemUuids = options.systemUuids || ['none'] // this fake array will render a single canvas with no system

    const outputCanvases = systemUuids.map(createCanvas)
    // render scene + camera for every system
    systemUuids.forEach((systemUuid, index) => {
      const outputCanvas = outputCanvases[index]
      systemsInScene.forEach((system) => {
        system.visible = system.uuid === systemUuid
      })
      renderer.render(this.#editor.scene, dupCamera)
      const outputCanvasCtx = outputCanvas.getContext('2d')
      outputCanvasCtx.drawImage(renderer.domElement, 0, 0)
    })

    afterCaptureCleanup.forEach((f) => f())
    return outputCanvases
  }

  /**
   *
   * @param {int} widthPx
   * @param {int} heightPx
   * @returns {Promise<HTMLCanvasElement[]>}
   */
  #captureCurrentMapLayers = (widthPx, heightPx, options = {}) => {
    const currentMap = window.MapHelper.activeMapInstance
    const currentView = window.ViewHelper.selectedView()

    if (currentMap && !(currentMap.dom instanceof window.ol.Map)) {
      // only 2D imagery rendered via OpenLayers can be captured
      return []
    }

    const afterCaptureCleanup = []

    const mapViewport = document.createElement('div')
    mapViewport.id = 'snapshotMapViewport'
    mapViewport.style.width = `${widthPx}px`
    mapViewport.style.height = `${heightPx}px`
    mapViewport.style.visibility = 'hidden'
    mapViewport.style.position = 'fixed'
    mapViewport.style.zIndex = -1000
    document.body.appendChild(mapViewport)
    afterCaptureCleanup.push(() => document.body.removeChild(mapViewport))

    const map = new ol.Map({
      layers: currentMap.dom.getLayers(),
      pixelRatio: 1.0,
      controls: [],
      interactions: [],
    })

    const currentMapData = currentMap.toMapData()
    if (currentView.mapData.mapType === 'Image') {
      // we handle uploaded 2D imagery separately
      // since it behaves like an oblique imagery instead of a top-down imagery
      map.setView(
        window.buildView(
          lonlatToFrac(
            currentMapData.center,
            currentMapData.oblique.epsg,
            currentMapData.oblique.extent,
            currentMapData.oblique.heading
          ),
          currentMapData.oblique,
          currentMapData.zoomTarget + currentMapData.zoomDelta
        )
      )
    } else {
      map.setView(
        new window.ol.View({
          center: ol.proj.transform(currentMapData.center.slice(), 'EPSG:4326', 'EPSG:3857'),
          resolution: currentMap.dom.getView().getResolution(),
          rotation: currentMap.dom.getView().getRotation(),
        })
      )
    }

    const capturePromise = Promise.withResolvers()

    const onMapRenderComplete = () => {
      const canvasOpenLayers = []
      map
        .getViewport()
        .querySelectorAll('canvas')
        .forEach((c) => {
          canvasOpenLayers.push(c)
        })
      afterCaptureCleanup.forEach((f) => f())
      capturePromise.resolve(canvasOpenLayers)
    }

    // @TODO: find a way to detect that:
    // - the 'rendercomplete' event of the map never fired OR
    // - the 'rendercomplete' event of the map didn't fire within a certain time window
    // in these cases, we should abort the capture and reject the promise

    map.once('rendercomplete', onMapRenderComplete)
    map.setTarget(mapViewport)
    afterCaptureCleanup.push(() => map.setTarget(null))
    map.renderSync()

    return capturePromise.promise
  }

  #getEditorVisualState = () => {
    const getSceneObjectsByType = (type, customFilterFunction, customMapFunction) => {
      const objects = editor
        .filter('type', type)
        .filter(customFilterFunction ? customFilterFunction : (_s) => true)
        .map(
          customMapFunction
            ? customMapFunction
            : (o) => {
                o.refreshUserData()
                o.updateMatrixWorld()
                return { uuid: o.uuid, matrixWorld: o.matrixWorld.elements.slice(), ...o.userData }
              }
        )
      const serialized = {}
      objects.forEach((o) => {
        serialized[o.uuid] = o
        delete o.uuid
      })
      return serialized
    }

    const systems = {}
    editor.getSystems().forEach((s) => {
      const modules = s.getModules()
      systems[s.uuid] = { modules: {} }
      modules.forEach((m) => {
        m.updateMatrixWorld()
        systems[s.uuid]['modules'][m.uuid] = {
          matrixWorld: m.matrixWorld.elements,
          size: m.size,
          texture: m.moduleTexture(),
        }
      })
    })

    const currentViewSettings = window.ViewHelper.selectedView().toObject()

    return {
      view: {
        uuid: currentViewSettings.uuid,
        cameraParams: currentViewSettings.cameraParams,
        showTextures: currentViewSettings.showTextures,
        facetDisplayModeOverride: currentViewSettings.facetDisplayModeOverride,
        style: currentViewSettings.style,
      },
      env: {
        spatialFacets: getSceneObjectsByType(
          'OsFacet',
          (facet) => {
            return !facet.isNonSpatial()
          },
          (facet) => {
            facet.refreshUserData()
            const userData = facet.userData
            const vertices = facet.vertices
              .filter((v) => !!v)
              .map((v) => {
                const position = new THREE.Vector3()
                const rotation = new THREE.Euler()
                const scale = new THREE.Vector3()
                v.updateMatrixWorld()
                v.matrixWorld.decompose(position, rotation, scale)
                return { x: position.x, y: position.y, z: position.z }
              })
            return { uuid: facet.uuid, vertices, wallTexture: userData.wallTexture, roofTexture: userData.roofTexture }
          }
        ),
        obstructions: getSceneObjectsByType('OsObstruction'),
        trees: getSceneObjectsByType('OsTree'),
        trimmers: getSceneObjectsByType('OsClipper'),
      },
      systems,
    }
  }
}
