window.pluginDesignValidation = {
  id: 1500,
  componentCodes: ['ALL'],
  name: 'pluginDesignValidation',
  plugin: function () {
    var refreshDebouncedInner = Utils.debounce((system) => refresh(system), 1000)
    var refreshDebounced = function (system) {
      //system is required
      if (!system?.uuid) return
      refreshDebouncedInner(system)
    }

    function refresh(system) {
      validateDesign(system)
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (editor.changingHistory) {
        console.log('Skip refreshSystemFromObject while editor.changingHistory with undo/redo')
        return
      }

      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refreshDebounced(system)
        }
      }
    }

    function updateWarnings(error, result) {
      if (!!error) {
        result.push(error)
      }
    }

    function checkMicroInverterCircuits(system) {
      var hasError = false
      var unstrungInverters = system.getUnstrungInverters()
      var hasunstrungMicroInverter = unstrungInverters.some(function (inverter) {
        return inverter.microinverter
      })
      if (unstrungInverters.length > 1 && hasunstrungMicroInverter) {
        hasError = true
      }
      if (hasError) {
        return {
          category: 'inverter',
          key: 'MICRO-INVERTER-WITHOUT-CIRCUITS',
          severity: 'warning',
          message:
            'A microinverter with no circuits is only allowed if all other inverters & microinverters have circuits/strings.',
        }
      }
    }

    function checkSystemPaymentOptions(system) {
      var hasError = false
      if (system.show_customer) {
        var isPaymentOptionsEmpty = !Boolean(system.payment_options && system.payment_options.length)
        var hasRunCalculations = system.version !== null
        var isPaymentOptionsOverrideEmpty =
          system.payment_options_override === null ||
          !Boolean(system.payment_options_override && system.payment_options_override.length)
        if (isPaymentOptionsEmpty && isPaymentOptionsOverrideEmpty && hasRunCalculations) {
          hasError = true
        }
      }
      if (hasError) {
        return {
          category: 'payment_option',
          key: 'SYSTEM-WITHOUT-PAYMENT-OPTION',
          severity: 'error',
          message:
            'No auto applied payment option detected. Please select a payment option manually in studio or update a payment option to auto apply in Control.',
        }
      }
    }

    function warnAboutPVWattsV6Deprecation(system) {
      const calculator = getCurrentSystemCalculatorOrDefault(system)
      if (calculator?.id === 1) {
        return {
          category: 'system',
          key: 'DEPRECATED-PVWATTS-V6-CALCULATOR',
          severity: 'warning',
          message:
            'PVWatts v6 has been replaced by PVWatts v8 and will be decommissioned in early 2024. If you are using PVWatts v6, please ensure you switch to PVWatts v8 as your default Energy Production Calculator in Control > Design & Hardware > Setbacks & Design Settings.',
        }
      }
    }

    function checkRayTracing(system) {
      const calculator = getCurrentSystemCalculatorOrDefault(system)
      const hasError = window.ViewHelper.has3DView() && calculator && calculator.supports_automated_shading !== true
      if (hasError) {
        return {
          category: 'system',
          key: 'CALCULATOR-RAY-TRACE-WARNING',
          severity: 'info',
          messageParams: {
            calculatorName: calculator.name,
          },
          message:
            'Automated shading is not supported by %{calculatorName}. Switch to SAM to enable automated shading simulations.',
        }
      }
    }

    function checkPriceChangedWithPriceOverrides(system) {
      // When we modify the system price override for a payment option, record what the non-overridden system price is.
      // Here we check if the difference between system price and payment_option override price has changed
      // // Any time
      var hasError = system.priceHasChangedSinceOverrideApplied()
      if (hasError) {
        return {
          category: ['inverter', 'system'],
          key: 'PRICE-CHANGED-WHILE-OVERRIDE-IS-SET',
          severity: 'warning',
          message:
            'Calculated price has changed while price overrides are set. Are you sure price overrides are correct?',
        }
      }
    }

    function checkIfUsingPriceAdjustmentsInUk(system) {
      /*
Tax calculations may have some inaccuracy when using price adjustments. Please ensure calculations are
sufficiently accurate if you plan to use price adjustments.
*/
      var isUk = window.WorkspaceHelper?.project?.country_iso2 === 'GB'
      if (!isUk) {
        return
      }

      var hasPriceAdjustment = system.componentsUnique().some((c) => c.price_adjustment > 0)
      var hasPriceAdderOrDiscount = system.line_items?.some(
        (line_item) => !isNaN(parseFloat(line_item.value)) && parseFloat(line_item.value) !== 0
      )

      if (hasPriceAdjustment || hasPriceAdderOrDiscount) {
        return {
          category: 'price',
          key: 'PRICE-ADJUSTMENT-IN-UK',
          severity: 'info',
          // Do not translate the message below since it ONLY applies to the UK.
          message:
            'Tax calculations may be inaccurate when using price adjustments, price adders or discounts. Please verify tax calculations.',
        }
      }
    }

    const getMinMaxTemperature = (formValues) => {
      const [default_min_temp, default_max_temp] = formValues?.temperature_min_max || []
      const min_temp_override = formValues?.temperature_min_override
      const max_temp_override = formValues?.temperature_max_override
      const min_temp = min_temp_override || default_min_temp
      const max_temp = max_temp_override || default_max_temp
      return [min_temp, max_temp]
    }

    function updateWarningsVoltageCheck(system, errors) {
      const [minTemp, maxTemp] = getMinMaxTemperature(window.WorkspaceHelper?.project)
      system.inverters().forEach((inverter) => {
        const moduleData = inverter.getSystem().moduleType()
        const maxPanelVoltageVocAfterTemp =
          moduleData.voc * (1 + ((minTemp - 25) * moduleData.temp_coefficient_voc) / 100)
        //const minPanelVoltageVocAfterTemp = moduleData.voc * (1 + (maxTemp * moduleData.temp_coefficient_voc) / 100)
        // Assume 25˚C temp increase due to avg panel NOCT = ~45˚C and assume a insolation level of 800W/m2 https://www.pveducation.org/pvcdrom/modules-and-arrays/nominal-operating-cell-temperature

        const maxPanelVoltageVmpAfterTemp =
          moduleData.max_power_voltage * (1 + ((minTemp - 25) * moduleData.temp_coefficient_voc) / 100)
        const minPanelVoltageVmpAfterTemp =
          moduleData.max_power_voltage * (1 + (maxTemp * moduleData.temp_coefficient_voc) / 100)
        // Assume 25˚C temp increase due to avg panel NOCT = ~45˚C and assume a insolation level of 800W/m2 https://www.pveducation.org/pvcdrom/modules-and-arrays/nominal-operating-cell-temperature

        if (inverter.microinverter) {
          if (maxPanelVoltageVocAfterTemp > inverter.voltage_max) {
            errors.push({
              category: 'inverter',
              key: 'MICRO-INVERTER-MAX-VOC-EXCEED',
              severity: 'warning',
              message:
                'Maximum panel Voc exceeds maximum microinverter input DC voltage. Please consider using a different microinverter or PV panel.',
            })
          }

          if (minPanelVoltageVmpAfterTemp < inverter.voltage_minimum) {
            errors.push({
              category: 'inverter',
              key: 'MICRO-INVERTER-MIN-VMP-TOO-LOW',
              severity: 'warning',
              message:
                'Minimum panel Vmp is below the minimum microinverter input DC voltage. Please consider using a different microinverter or PV panel.',
            })
          }
        } else if (!inverter.getSystem()?.dcOptimizer()) {
          //string inverter with no dc optimizer
          const allStrings = inverter
            .mppts()
            .flatMap((mppt) => mppt.strings())
            .map((s) => s.moduleQuantity())

          const maxNumberPanelsInStrings = allStrings.length > 0 ? Math.max(...allStrings) : 0
          const minNumberPanelsInStrings = allStrings.length > 0 ? Math.min(...allStrings) : 0

          const overSizingRatio = 0.75
          const componentData = inverter?.getComponentData()
          const maxPanelsForInverter = inverter.hasMaxDCPower()
            ? Math.floor(componentData?.max_dc_power / moduleData.kw_stc)
            : Math.floor(componentData?.max_power_rating / overSizingRatio / moduleData.kw_stc)

          const maxPanelsInMaxDcRange = Math.floor(inverter.voltage_max / maxPanelVoltageVocAfterTemp)
          const maxPanelsInMpptRange = Math.floor(inverter.mppt_voltage_max / maxPanelVoltageVmpAfterTemp)

          const rangeOfPanelsPerString = [
            Math.ceil(inverter.voltage_minimum / minPanelVoltageVmpAfterTemp),
            Math.min(maxPanelsInMaxDcRange, maxPanelsInMpptRange, maxPanelsForInverter),
          ]
          if (maxPanelVoltageVocAfterTemp * maxNumberPanelsInStrings > inverter.voltage_max) {
            errors.push({
              category: 'inverter',
              key: 'STRING-INVERTER-MAX-VOC-EXCEED',
              severity: 'warning',
              message:
                'Maximum array Voc exceeds maximum inverter input DC voltage. Please consider reducing the number of panels in the string(s) to be between %{minPanelsPerString} and %{maxPanelsPerString}.',
              messageParams: {
                minPanelsPerString: rangeOfPanelsPerString[0],
                maxPanelsPerString: rangeOfPanelsPerString[1],
              },
            })
          }

          if (
            minPanelVoltageVmpAfterTemp * minNumberPanelsInStrings < inverter.voltage_minimum &&
            allStrings.length > 0
          ) {
            errors.push({
              category: 'inverter',
              key: 'STRING-INVERTER-MIN-VMP-TOO-LOW',
              severity: 'warning',
              message:
                'Minimum array Vmp is below the minimum inverter input DC voltage. Please consider increasing the number of panels in the string(s) to be between %{minPanelsPerString} and %{maxPanelsPerString}.',
              messageParams: {
                minPanelsPerString: rangeOfPanelsPerString[0],
                maxPanelsPerString: rangeOfPanelsPerString[1],
              },
            })
          }

          if (maxPanelVoltageVmpAfterTemp * maxNumberPanelsInStrings > inverter.mppt_voltage_max) {
            errors.push({
              category: 'inverter',
              key: 'STRING-INVERTER-MAX-VMP-EXCEED',
              severity: 'warning',
              message:
                'Maximum array Vmp exceeds maximum inverter MPPT voltage range. Please consider reducing the number of panels in the string(s) to be between %{minPanelsPerString} and %{maxPanelsPerString}.',
              messageParams: {
                minPanelsPerString: rangeOfPanelsPerString[0],
                maxPanelsPerString: rangeOfPanelsPerString[1],
              },
            })
          }
        }
      })
    }

    const trimDecimalPlaces = (v, places) => {
      if (isNaN(v)) {
        return v
      } else {
        return parseFloat(v.toFixed(places).toString())
      }
    }

    function checkInverterLoadingRatio(system) {
      let errorMessage = ''
      let warningSeverity = 'info'
      system.inverters().forEach((inverter) => {
        const max_dc_power_ratio = trimDecimalPlaces(inverter.calculateMaxDcAcRatio(), 2)
        const ratio = trimDecimalPlaces(inverter.calculateDcAcRatio(), 2)

        if (!ratio) {
          return // ratio not available
        }

        switch (true) {
          case ratio < 0.75:
            warningSeverity = 'info'
            errorMessage =
              'An inverter is oversized (DC/AC ratio < 75%). Please consider adding more panels to the inverter, ' +
              'choosing a smaller inverter, or consider adding a DC-connected battery to allow for PV oversizing.'
            break

          case inverter.hasMaxDCPower() && ratio > max_dc_power_ratio:
            warningSeverity = 'warning'
            errorMessage =
              `An inverter is undersized (DC/AC ratio > ${max_dc_power_ratio * 100}%). Please consider reducing ` +
              'the number/size of panels connected to the inverter, or choosing a larger inverter, as this is not ' +
              'recommended by the inverter manufacturer.'
            break

          case !inverter.hasMaxDCPower() && ratio > 1.5:
            warningSeverity = 'info'
            errorMessage =
              'An inverter is highly undersized (DC/AC ratio > 150%). Please consider reducing the number/size ' +
              'of panels connected to the inverter, choosing a larger inverter, or consider adding a DC-connected battery ' +
              'to allow for PV oversizing.'
            break

          case !inverter.hasMaxDCPower() && ratio > 1.33:
            warningSeverity = 'info'
            errorMessage =
              'An inverter is undersized (DC/AC ratio > 133%). Please consider reducing the number/size of ' +
              'panels connected to the inverter, choosing a larger inverter, or consider adding a DC-connected battery ' +
              'to allow for PV oversizing.'
            break

          default:
            warningSeverity = 'info'
            errorMessage = ''
            break
        }
      })

      if (errorMessage) {
        return {
          category: 'inverter',
          key: 'DC-AC-RATIO-CHECK',
          severity: warningSeverity,
          message: errorMessage,
        }
      }
    }

    function checkUnusedMicroInputs(system) {
      let errorMessage = ''
      system.inverters().forEach((inverter) => {
        if (inverter.microinverter) {
          const allStrings = inverter
            .mppts()
            .flatMap((mppt) => mppt.strings())
            .map((s) => s.moduleQuantity())

          const totalNumberPanelsInStrings =
            allStrings.length > 0 ? allStrings.reduce((a, b) => a + b, 0) : system.getUnstrungModules().length

          const panelsToInputs = totalNumberPanelsInStrings / inverter.mppt_quantity

          if (panelsToInputs === Math.round(panelsToInputs) && inverter.microinverter) {
          } else if (inverter.microinverter) {
            errorMessage =
              'One or more micro inverters may have unused inputs as the number of modules used is not divisible by the number of micro inverter inputs.'
          }
        }
      })
      if (errorMessage) {
        return {
          category: 'inverter',
          key: 'UNUSED-MICRO-INPUTS-CHECK',
          severity: 'warning',
          message: errorMessage,
        }
      }
    }

    function checkInverterLoadingRatioOverallWithoutStringing(system) {
      let errorMessage = ''
      //there are more than 1 string inverter
      if (system.inverters().filter((i) => !i.microinverter).length > 1) {
        //no stringing on any inverters
        if (system.moduleQuantity() - system.getUnstrungModules().length === 0) {
          //total kW size of panels / total kW size of inverters
          const ratio =
            (system.getSystem()?.moduleType()?.kw_stc * system.moduleQuantity()) /
            system.inverters().reduce((sum, i) => sum + i.max_power_rating, 0)
          if (ratio < 0.75) {
            errorMessage =
              'An inverter is oversized (DC/AC ratio < 75%). Please consider adding more panels to the inverter, or choosing a smaller inverter.'
          } else if (ratio > 1.5) {
            errorMessage =
              'An inverter is highly undersized (DC/AC ratio > 150%) and there may be significant clipping losses. Please consider reducing the number/size of panels to the inverter, choosing a larger inverter, or consider adding a DC-connected battery to allow for PV oversizing.'
          } else if (ratio > 1.33) {
            errorMessage =
              'An inverter is undersized (DC/AC ratio > 133%). Please consider reducing the number/size of panels to the inverter, choosing a larger inverter, or consider adding a DC-connected battery to allow for PV oversizing.'
          }
        }
      }
      if (errorMessage) {
        return {
          category: 'inverter',
          key: 'OVERALL-DC-AC-RATIO-WITHOUT-STRINGING',
          severity: 'warning',
          message: errorMessage,
        }
      }
    }

    /*function checkViewWithAligningMap(ViewHelper) {
      var hasAtLeastOneAlignedView =
        ViewHelper.views.filter(function (view) {
          return Boolean(view.isAligned)
        }).length > 0
      var hasViewWithoutAligningMap =
        ViewHelper.views.filter(function (view) {
          return Boolean(view.isAligned) === false && view.show_customer === true
        }).length > 0

      return hasViewWithoutAligningMap && hasAtLeastOneAlignedView && ViewHelper.hasDesign()
    }*/

    function refreshErrorMessage(system, problems) {
      //clear previous design error
      const filter = (error) => error.systemId === system.uuid && error.source === 'design'
      window.WorkspaceHelper?.clearProjectError(filter)

      if (!window.editor?.objectByUuid(system.uuid)) {
        //return if system not longer exist in the scene
        return
      }

      //add new design error
      problems.forEach((error) => {
        window.WorkspaceHelper?.addProjectErrorToReduxStore({
          message: error.message,
          key: error.key,
          severity: error.severity,
          systemId: system.uuid,
          source: 'design',
          category: error.category,
          messageParams: error.messageParams,
        })
      })
    }

    const PERFORMANCE_CALCULATORS = [
      // There may be other calculators but we do not need to worry about them unless they need
      // some specific functionality.
      { id: 1, name: 'PVWatts v6 (Deprecated)', supports_automated_shading: false },
      { id: 2, name: 'System Advisor Model 2020.02.29.r2', supports_automated_shading: true },
      { id: 3, name: 'MCS (Fixed)', restrict_to_country: ['GB'], supports_automated_shading: true },
      { id: 4, name: 'PVWatts v8', supports_automated_shading: false },
      { id: 5, name: 'Import 3rd Party', supports_automated_shading: true },
      {
        id: 6,
        name: 'MCS + SAM (Auto)',
        restrict_to_country: ['GB'],
        supports_automated_shading: true,
      },
    ]

    function getCurrentSystemCalculatorOrDefault(system) {
      var systems = window.editor && window.editor.getSystems ? window.editor.getSystems() : null
      if (!system) {
        system = systems[0]
      }
      return PERFORMANCE_CALCULATORS.filter((c) => {
        if (system) {
          return c.id === system.calculator
        } else {
          return c.id === window.WorkspaceHelper.getDefaultPerformanceCalculator()
        }
      })[0]
    }

    const validateDesign = function (system) {
      if (!!window.WorkspaceHelper.getIsProjectLite()) {
        // Don't run on Lite projects
        return
      }

      var result = []
      updateWarnings(warnAboutPVWattsV6Deprecation(system), result)
      updateWarnings(checkMicroInverterCircuits(system), result)
      updateWarnings(checkSystemPaymentOptions(system), result)
      updateWarnings(checkRayTracing(system), result)
      updateWarnings(checkPriceChangedWithPriceOverrides(system), result)
      updateWarnings(checkInverterLoadingRatioOverallWithoutStringing(system), result)
      updateWarnings(checkUnusedMicroInputs(system), result)
      updateWarnings(checkInverterLoadingRatio(system), result)
      updateWarnings(checkIfUsingPriceAdjustmentsInUk(system), result)
      updateWarningsVoltageCheck(system, result)

      //???????
      if (system.pricing?.warnings?.length > 0) {
        result.push({
          severity: 'warning',
          category: ['pricing'],
          message: system.pricing?.warnings.join('\\n'),
          key: 'pricing',
        })
      }

      refreshErrorMessage(system, result)
    }

    function objectAdded(object) {
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (object?.type === 'OsSystem') {
        const filter = (error) => error.systemId === object.uuid
        window.WorkspaceHelper?.clearProjectError(filter)
      } else {
        refreshSystemFromObject(object)
      }
    }

    function objectChanged(object) {
      refreshSystemFromObject(object)
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      console.log('pluginLoaded')
      refreshAllSystems()
    }

    function pluginUnloaded() {
      console.log(' pluginUnloaded')
      const filter = (error) => error.source === 'design'
      window.WorkspaceHelper?.clearProjectError(filter)
    }

    return {
      objectAdded,
      objectChanged,
      objectRemoved,
      liteProjectUpgraded,
      pluginLoaded,
      pluginUnloaded,
    }
  },
}
