/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param attributeName string
 * @param newValue number, string, boolean or object
 * @constructor
 */

var SetMaterialValueCommand = function(object, attributeName, newValue) {
  Command.call(this)

  this.type = 'SetMaterialValueCommand'
  this.name = 'Set Material.' + attributeName
  this.updatable = true
  this.timeStamp = Date.now()
  this.object = object
  this.oldValue = object !== undefined ? object.material[attributeName] : undefined
  this.newValue = newValue
  this.attributeName = attributeName
}

SetMaterialValueCommand.prototype = {
  execute: function() {
    this.object.material[this.attributeName] = this.newValue
    this.object.material.needsUpdate = true
    this.editor.signals.objectChanged.dispatch(this.object)
    this.editor.signals.materialChanged.dispatch(this.object.material)
  },

  undo: function() {
    if (this.loadFromJSON) {
      this.object = this.object && this.editor.scene.getObjectByProperty('uuid', this.object.object.uuid)
      this.loadFromJSON = false
    }
    this.object.material[this.attributeName] = this.oldValue
    this.object.material.needsUpdate = true
    this.editor.signals.objectChanged.dispatch(this.object)
    this.editor.signals.materialChanged.dispatch(this.object.material)
  },

  update: function(cmd) {
    this.newValue = cmd.newValue
  },

  toJSON: function() {
    if (this.loadFromJSON) {
      return Command.prototype.toObject.call(this)
    }
    var output = Command.prototype.toJSON.call(this)

    output.object = this.object.uuid
    output.attributeName = this.attributeName
    output.oldValue = this.oldValue
    output.newValue = this.newValue

    return output
  },

  fromJSON: function(json) {
    Command.prototype.fromJSON.call(this, parsedCmd)

    this.object = parsedCmd.object
    this.attributeName = parsedCmd.attributeName
    this.oldValue = parsedCmd.oldValue
    this.newValue = parsedCmd.newValue
  },
}
