// dependencies THREE, Editor, OsFacet, OsModuleGrid, OsGroup, OsModule

/**
 * This editor extension adds the ability to manually/automatically
 * add non-spatial facets in the scene for module grids NOT on spatial facets
 * This is a stop-gap solution to the problem of module grids
 * not being associated with roof facets, especially when the system was designed in 3D or 2D mode
 * Some downstream functionalities require these associations
 * This solution will be used until studio's roof-facet modeling
 * (either automated via AI or modelled manually) becomes robust enough
 */
class NonSpatialFacets {
  static name = 'NonSpatialFacets'

  // FLAGS
  isActive = false
  autoGenerate = false
  autoDispose = true
  debug = false

  #editorInstance
  #facetsGroup
  #outlinerExtension
  #dragAndDropSession = null
  #ignoreModuleGridChanges = false

  #NSF_POSITION_OFFSET_Z = -0.05
  #SNAPPING_TIME_MS = 1700
  #SNAP_TIMER_PIXEL_OFFSET_X = 0
  #SNAP_TIMER_PIXEL_OFFSET_Y = 90
  #FLAT_SLOPE_THRESHOLD_DEGREES = 10

  #cleanup = []

  constructor(editorInstance) {
    if (!editorInstance instanceof Editor) {
      throw new Error('Cannot instantiate NonSpatialFacets extension: invalid Editor instance.')
    }
    this.#editorInstance = editorInstance
  }

  /////////////////////////////////////
  //        PUBLIC METHODS
  /////////////////////////////////////

  activate = () => {
    if (this.isActive) return // already active

    const editorScene = this.#editorInstance.scene

    if (editorScene) {
      this.#facetsGroup = this.#getSavedFacetsGroupInScene(editorScene)
    }

    if (!this.#facetsGroup) {
      // didn't find any existing group in scene, create new one
      this.#facetsGroup = new window.THREE.Group()
      this.#facetsGroup.name = 'NonSpatialFacetsGroup'
      editorScene.add(this.#facetsGroup)
    }

    this.#cleanup.push(() => {
      if (this.#facetsGroup.children.length === 0) {
        // if there aren't any spatial facets, remove the container from the scene tree
        editorScene.remove(this.#facetsGroup)
      }
    })

    const moduleGridOutlinerExtension = this.#editorInstance.extensions.ModuleGridOutliner
    const sharedFacetVfxExtension = this.#editorInstance.extensions.SharedFacetVfx

    if (!moduleGridOutlinerExtension.isActive) {
      moduleGridOutlinerExtension.activate()
      // @TODO: add a way for an extension (or studio itself) to record a list of "users"
      // if that list of users goes down to 0, that's the only time the extension gets
      // deactivated. Otherwise, we might deactivate when it's still used somewhere else
      this.#cleanup.push(() => this.#editorInstance.extensions.ModuleGridOutliner.deactivate())
    }

    if (!sharedFacetVfxExtension.isActive) {
      sharedFacetVfxExtension.activate()
      // @TODO: add a way for an extension (or studio itself) to record a list of "users"
      // if that list of users goes down to 0, that's the only time the extension gets
      // deactivated. Otherwise, we might deactivate when it's still used somewhere else
      this.#cleanup.push(() => this.#editorInstance.extensions.SharedFacetVfx.deactivate())
    }

    if (!this.#outlinerExtension) {
      this.#outlinerExtension = this.#editorInstance.extensions.ModuleGridOutliner
    }

    // attach unsnappedFromFacet signal handler for each moduleGrid
    this.#getAllModuleGrids().forEach((moduleGrid) => {
      const handler = (facet, newFacet) => {
        this.#onModuleGridUnsnappedFromFacet(moduleGrid, facet, newFacet)
      }
      moduleGrid.signals.unsnappedFromFacet.add(handler)
      this.#cleanup.push(() => moduleGrid.signals.unsnappedFromFacet.remove(handler))
    })

    this.#editorInstance.signals.objectChanged.add(this.#onEditorObjectChanged)
    this.#editorInstance.signals.objectAdded.add(this.#onEditorObjectAdded)

    this.#cleanup.push(() => {
      this.#editorInstance.signals.objectChanged.remove(this.#onEditorObjectChanged)
      this.#editorInstance.signals.objectAdded.remove(this.#onEditorObjectAdded)
    })

    if (this.#editorInstance.controllers.Handle) {
      const handleControllerOutputs = this.#editorInstance.controllers.Handle.outputs

      handleControllerOutputs.transformStart.add(this.#onObjectTransformStart)
      handleControllerOutputs.transformEnd.add(this.#onObjectTransformEnd)
      handleControllerOutputs.translateXY.add(this.#onObjectTranslateXY)

      this.#cleanup.push(() => {
        handleControllerOutputs.transformStart.remove(this.#onObjectTransformStart)
        handleControllerOutputs.transformEnd.remove(this.#onObjectTransformEnd)
        handleControllerOutputs.translateXY.remove(this.#onObjectTranslateXY)
      })
    }

    this.#cleanup.push(() => {
      // reset flags
      this.autoGenerate = false
      this.autoDispose = true
      this.debug = false
    })

    this.isActive = true
  }

  deactivate = () => {
    if (!this.isActive) return // already inactive

    this.#cleanup.forEach((f) => f())
    this.#cleanup = []

    this.isActive = false
  }

  generateNonSpatialFacets = () => {
    if (!this.isActive) return

    const systems = this.#editorInstance.getSystems()
    const moduleGrids = []
    systems.forEach((system) => {
      moduleGrids.push(...system.moduleGrids())
    })

    let count = 0
    moduleGrids.forEach((moduleGrid) => {
      if (moduleGrid.facet) return
      const nonSpatialFacet = this.#createNonSpatialFacetFromModuleGrid(moduleGrid)
      this.#snapModuleGridsToNonSpatialFacet([moduleGrid], nonSpatialFacet, {
        reorientModuleGrids: false,
      })
      this.#facetsGroup.add(nonSpatialFacet)
      this.#logNonSpatialFacetCreatedForModuleGrid(nonSpatialFacet, moduleGrid)
      count += 1
    })
  }

  removeAllNonSpatialFacets = () => {
    if (!this.isActive) return

    // remove all nonspatial facets used in design
    this.#getAllModuleGrids().forEach((moduleGrid) => {
      if (moduleGrid.facet?.isNonSpatial()) {
        moduleGrid.unsnapFromCurrentFacet({ newFacet: undefined })
      }
    })
  }

  getContextMenuItemsForObject = (object) => {
    if (!this.isActive) return

    if (object instanceof window.OsModule && object.parent instanceof window.OsModuleGrid) {
      const moduleGrid = object.parent

      const menuItems = []

      if (!moduleGrid.facet) {
        menuItems.push({
          label: window.translate('Create Facet'),
          selected: false,
          onClick: () => {
            const facet = this.#createNonSpatialFacetFromModuleGrid(moduleGrid)
            this.#snapModuleGridsToNonSpatialFacet([moduleGrid], facet, { reorientModuleGrids: false })
            this.#logNonSpatialFacetCreatedForModuleGrid(facet, moduleGrid)
            this.#facetsGroup.add(facet)
            this.#editorInstance.select(facet, true)
          },
        })

        return menuItems
      }

      if (
        moduleGrid.facet?.isNonSpatial() &&
        moduleGrid.facet?.objectsFloating.filter((object) => object instanceof OsModuleGrid).length > 0
      ) {
        menuItems.push({
          label: window.translate('Detach Facet'),
          selected: false,
          onClick: () => {
            moduleGrid.unsnapFromCurrentFacet({ newFacet: undefined })
            this.#editorInstance.signals.objectChanged.dispatch(moduleGrid, 'facet')
          },
        })
      }

      return menuItems
    }

    if (object instanceof OsGroup) {
      const objectsInGroup = object.objects
      const menuItems = []
      const allSelectedAreModuleGrids = objectsInGroup.every((object) => object instanceof OsModuleGrid)
      const allSelectedAreNotOnSpatialFacets = objectsInGroup
        .map((object) => object.facet)
        .every((facet) => !facet || facet.isNonSpatial())
      let facetToShare = objectsInGroup.find((moduleGrid) => !!moduleGrid.facet)?.facet

      if (allSelectedAreModuleGrids && allSelectedAreNotOnSpatialFacets) {
        menuItems.push({
          label: window.translate(facetToShare ? 'Group to Facet' : 'Create Facet'),
          useHTML: false,
          selected: false,
          onClick: () => {
            // get the facet of the first module grid in group that's on a facet
            if (!facetToShare) {
              facetToShare = this.#createNonSpatialFacetFromModuleGrid(objectsInGroup[0])
            }
            // except for the module grid already on the facet,
            // snap all other module grids to the facet
            const moduleGridsToSnap = []
            objectsInGroup.forEach((moduleGrid) => {
              if (moduleGrid.facet !== facetToShare) {
                const previousFacet = moduleGrid.facet
                if (previousFacet) {
                  moduleGrid.unsnapFromCurrentFacet({ newFacet: facetToShare })
                }
                moduleGridsToSnap.push(moduleGrid)
              }
            })
            this.#snapModuleGridsToNonSpatialFacet(moduleGridsToSnap, facetToShare, {
              reorientModuleGrids: true,
            })
            this.#editorInstance.select(facetToShare, true)
          },
        })
      }

      return menuItems
    }

    return []
  }

  /////////////////////////////////////
  //        SIGNAL HANDLERS
  /////////////////////////////////////

  #onEditorObjectChanged = (object, attributeName) => {
    if (this.#ignoreModuleGridChanges) return
    if (!(object instanceof OsModuleGrid)) return
    if (!object.facet?.isNonSpatial()) return
    if (!(attributeName === 'azimuth' || attributeName === 'slope' || attributeName === 'setPosition')) return

    const facet = object.facet
    this.#updateFacetDataFromModuleGrid(object.facet, object)
    // we skip slope and azimuth syncing of module grids in non spatial facet
    // if the non-spatial facet is flat or flat-ish
    if (facet.slope > this.#FLAT_SLOPE_THRESHOLD_DEGREES) {
      facet.objectsFloating.forEach((obj) => {
        if (obj instanceof OsModuleGrid) {
          this.#orientModuleGridTo3DPlane(obj, facet.plane)
        }
      })
    }
    this.#editorInstance.render()
    this.#logNonSpatialFacetDataUpdated(object.facet)
  }

  #onEditorObjectAdded = (object) => {
    if (object instanceof OsModuleGrid && !object.ghostMode()) {
      if (this.autoGenerate && !object.floatingOnFacetOnChange()) {
        this.#generateNonSpatialFacetForModuleGrid(object)
      }

      const moduleGrid = object
      moduleGrid.signals.unsnappedFromFacet.add((facet, newFacet) => {
        this.#onModuleGridUnsnappedFromFacet(moduleGrid, facet, newFacet)
      })
    }
  }

  #onModuleGridUnsnappedFromFacet = (moduleGrid, facet, newFacet) => {
    this.#logModuleGridUnsnappedFromFacet(moduleGrid, facet, newFacet)

    if (this.autoGenerate && !newFacet) {
      // the module grid doesn't have a new spatial facet to snap to
      // we'll create  a non-spatial facet for it instead
      this.#generateNonSpatialFacetForModuleGrid(moduleGrid)
    }

    if (this.autoDispose && facet.isNonSpatial() && facet.objectsFloating.length === 0) {
      // check if non-spatial facet still has anything on it
      // if not, dispose the non-spatial facet
      this.#logDisposeEmptyNonSpatialFacet(facet)
      this.#facetsGroup.remove(facet)
    }
  }

  #onObjectTransformStart = (object, transformType) => {
    if (transformType !== 'translateXY') return
    if (!(object instanceof OsModuleGrid)) return
    if (object.facet && !object.facet.isNonSpatial()) return

    // start drag-and-drop session
    this.#createNewDragAndDropSessionForModuleGrid(object)
    this.#refreshDragAndDropSession()
  }

  #onObjectTransformEnd = (_object, transformType) => {
    this.#ignoreModuleGridChanges = false
    if (!transformType === 'translateXY') return

    this.#removeOutlineGroup('CreateFacetModeOutlines', true)
    this.#removeOutlineGroup('SnapToFacetModeOutlines', true)
    this.#removeOutlineGroup('SnapCompleteOutline', true)

    if (this.#dragAndDropSession) {
      // finish / cancel drag-and-drop session
      this.#clearDragAndDropSession()
    }
  }

  #onObjectTranslateXY = (object, _position) => {
    if (!(object instanceof OsModuleGrid)) return

    this.#outlinerExtension.refreshGroup('SnapCompleteOutline')

    if (!this.#dragAndDropSession) {
      if (!object.facet || object.facet.isNonSpatial()) {
        // it's possible that when a module grid was first dragged, it was on a spatial facet
        // but was later detached, we compensate for this by starting a new session
        this.#createNewDragAndDropSessionForModuleGrid(object)
      } else return
    }

    // continuously refresh to check for intersection
    this.#refreshDragAndDropSession()
    this.#editorInstance.extensions.ModuleGridOutliner.refreshGroup('EditorExtensions::NonSpatialFacets::Outlines')
  }

  /////////////////////////////////////
  //      PRIVATE METHODS
  /////////////////////////////////////

  #getAllModuleGrids = () => {
    // query all module grids in all systems
    const systems = this.#editorInstance.getSystems()
    const moduleGrids = []
    systems.forEach((system) => {
      moduleGrids.push(...system.moduleGrids())
    })
    return moduleGrids
  }

  #getModuleGridsOnFacet = (facet) => {
    if (!facet) return []
    return facet.objectsFloating.filter((obj) => obj instanceof window.OsModuleGrid)
  }

  #generateNonSpatialFacetForModuleGrid = (moduleGrid) => {
    const nonSpatialFacet = this.#createNonSpatialFacetFromModuleGrid(moduleGrid)
    this.#snapModuleGridsToNonSpatialFacet([moduleGrid], nonSpatialFacet, {
      reorientModuleGrids: false, // no need, because it's already at the correct orientation
    })
    this.#facetsGroup.add(nonSpatialFacet)
    this.#logNonSpatialFacetCreatedForModuleGrid(nonSpatialFacet, moduleGrid)
  }

  #createNonSpatialFacetFromModuleGrid = (moduleGrid) => {
    const facet = new window.OsFacet({
      roofTypeId: window.editor?.scene?.roofTypeId() || null,
      wallTypeId: window.editor?.scene?.wallTypeId() || null,
      isManaged: true,
    })

    return this.#updateFacetDataFromModuleGrid(facet, moduleGrid)
  }

  #updateFacetDataFromModuleGrid = (facet, moduleGrid) => {
    // offset the plane position slightly below the module grid
    const position = moduleGrid.position.clone().add(new THREE.Vector3(0, 0, this.#NSF_POSITION_OFFSET_Z))

    const plane = new window.THREE.Plane().setFromNormalAndCoplanarPoint(
      window.Utils.normalFromSlopeAzimuth(moduleGrid.getSlope(), moduleGrid.getAzimuth()),
      position
    )

    facet.slope = window.OsFacet.slopeForNormal(plane.normal)
    facet.azimuth = window.OsFacet.azimuthForNormal(plane.normal)
    facet.slopeOverride = facet.slope
    facet.azimuthOverride = facet.azimuth
    facet.plane = plane

    // @TODO: double-check if we actually need to set these...
    // since they kinda contradict with the whole 'non-spatial' concept
    facet.centroid = position.clone()
    facet.position.copy(facet.centroid)

    return facet
  }

  #snapModuleGridsToNonSpatialFacet = (moduleGrids, facet, options) => {
    moduleGrids.forEach((moduleGrid) => {
      moduleGrid.snapToFacet({ facet, editor: this.#editorInstance })
      if (options.reorientModuleGrids === true) {
        // adjust the slope, azimuth, and position of module grid
        // so that it's on the same 3D plane as that of the non-spatial facet
        // maybe we also need to save the plane of the OsFacet in the userData??
        this.#orientModuleGridTo3DPlane(moduleGrid, facet.plane)
      }
    })
    this.#editorInstance.render()
  }

  #orientModuleGridTo3DPlane = (moduleGrid, plane) => {
    moduleGrid.setSlope(window.OsFacet.slopeForNormal(plane.normal))
    moduleGrid.setAzimuth(window.OsFacet.azimuthForNormal(plane.normal))
    const raycaster = new window.THREE.Raycaster(
      new window.THREE.Vector3(moduleGrid.position.x, moduleGrid.position.y, 1000),
      new window.THREE.Vector3(0, 0, -1)
    )
    const planeRayIntersection = raycaster.ray.intersectPlane(plane, new THREE.Vector3())
    moduleGrid.position.copy(planeRayIntersection).add(new window.THREE.Vector3(0, 0, -this.#NSF_POSITION_OFFSET_Z))
  }

  #getSavedFacetsGroupInScene = () => {
    return this.#editorInstance.filter('name', 'NonSpatialFacetsGroup')[0]
  }

  // drag-and-drop related methods

  #createNewDragAndDropSessionForModuleGrid = (moduleGrid) => {
    const moduleGridWorldPosition = moduleGrid.getWorldPosition(new THREE.Vector3())
    const cameraNormal = this.#editorInstance.camera.getWorldDirection(new THREE.Vector3())

    const currentSystem = this.#editorInstance.selectedSystem
    const otherModuleGrids = currentSystem
      .moduleGrids()
      .filter((mg) => mg !== moduleGrid)
      .filter((mg) => (!mg.facet || mg.facet.isNonSpatial()) && !this.#moduleGridSharesFacet(mg, moduleGrid))
    const modulesFromOtherModuleGrids = []

    otherModuleGrids.forEach((mg) => {
      modulesFromOtherModuleGrids.push(...mg.getModules())
    })

    const timer = new VisualTimer(this.#editorInstance.DesignerRootDomElement)
    // return new drag-and-drop session handle
    this.#dragAndDropSession = {
      moduleGrid: moduleGrid,
      modulesInside: moduleGrid.getModules(),
      modulesOutside: modulesFromOtherModuleGrids,
      raycaster: new window.THREE.Raycaster(
        moduleGridWorldPosition.add(cameraNormal.clone().setLength(1000).negate()),
        cameraNormal
      ),
      intersectedModuleGrid: null,
      intersectedFacet: null,
      timer,
    }
  }

  #clearDragAndDropSession = () => {
    if (this.#dragAndDropSession.timer.isCounting()) {
      this.#dragAndDropSession.timer.stop()
    }
    this.#dragAndDropSession = null
  }

  #refreshDragAndDropSession = () => {
    if (!this.#dragAndDropSession) return

    // check if the current module grid being dragged visually intersects any of the module grids
    // we do this by shooting rays from each of the modules listed in .modulesInside
    // and check if any of the rays intersect any of the modules listed in .modulesOutside
    const intersections = []
    const raycaster = this.#dragAndDropSession.raycaster
    const modulesInside = this.#dragAndDropSession.modulesInside
    const modulesOutside = this.#dragAndDropSession.modulesOutside
    const cameraNormal = this.#editorInstance.camera.getWorldDirection(new THREE.Vector3())

    modulesInside.forEach((module) => {
      if (intersections.length > 0) return // no need to check further, we'll just take the first ones we find
      const moduleWorldPosition = module.getWorldPosition(new THREE.Vector3())
      raycaster.set(moduleWorldPosition.add(cameraNormal.clone().setLength(1000).negate()), cameraNormal)
      intersections.push(...raycaster.intersectObjects(modulesOutside, false))
    })

    const firstIntersection = intersections[0]

    if (!firstIntersection) {
      // no intersection was detected
      // 1. cancel timer
      if (this.#dragAndDropSession.timer.isCounting()) {
        this.#dragAndDropSession.timer.stop()
      }
      // 2. nullify intersected facet and module grid
      this.#dragAndDropSession.intersectedFacet = null
      this.#dragAndDropSession.intersectedModuleGrid = null
      // 3. remove highlights if previously shown
      this.#removeOutlineGroup('CreateFacetModeOutlines', false)
      this.#removeOutlineGroup('SnapToFacetModeOutlines', false)
      return
    }

    // an intersection was detected
    // 1. determine which module grid the intersected module is in
    const intersectedModuleGrid = firstIntersection.object.parent
    // 2. determine which facet the intersected module grid is on
    const intersectedFacet = intersectedModuleGrid.facet
    // 3. determine if we either: have to create a new non-spatial facet
    // or snap the dragged module grid to an existing non-spatial facet
    const snappingMode = intersectedFacet ? 'snap' : 'create'
    const sameFacetAsBefore = intersectedFacet === this.#dragAndDropSession.intersectedFacet
    const sameModuleGridAsBefore = intersectedModuleGrid === this.#dragAndDropSession.intersectedModuleGrid

    this.#dragAndDropSession.intersectedModuleGrid = intersectedModuleGrid
    this.#dragAndDropSession.intersectedFacet = intersectedFacet

    // update the position of the snap timer so it will always be
    // at approx. the dragged module grid's position when the module grid moves
    const newTimerPosition = this.#computeScreenPositionOf3DObject(this.#dragAndDropSession.moduleGrid)
    newTimerPosition.x += this.#SNAP_TIMER_PIXEL_OFFSET_X
    newTimerPosition.y += this.#SNAP_TIMER_PIXEL_OFFSET_Y
    this.#dragAndDropSession.timer.setPosition(newTimerPosition.x, newTimerPosition.y)

    if (snappingMode === 'create' && !sameModuleGridAsBefore) {
      // we first check if we're intersecting the same module grid as before
      // if not, we reset the snapping timer
      this.#dragAndDropSession.timer.setText('Grouping')
      this.#dragAndDropSession.timer.start(
        this.#SNAPPING_TIME_MS,
        () => {
          const moduleGridToCreateFacetFrom = this.#dragAndDropSession.intersectedModuleGrid
          const facet = this.#createNonSpatialFacetFromModuleGrid(moduleGridToCreateFacetFrom)
          const moduleGridToSnap = this.#dragAndDropSession.moduleGrid
          this.#clearDragAndDropSession()
          if (moduleGridToSnap.facet) {
            moduleGridToSnap.unsnapFromCurrentFacet({ newFacet: facet })
          }
          moduleGridToCreateFacetFrom.snapToFacet({ facet, editor: this.#editorInstance })
          moduleGridToSnap.snapToFacet({ facet, editor: this.#editorInstance })
          this.#facetsGroup.add(facet)
          moduleGridToSnap.hideSetbacks()
          this.#removeOutlineGroup('CreateFacetModeOutlines', false)
          this.#createOutlineGroup('SnapCompleteOutline', [moduleGridToSnap], 0.25, true)
        },
        0.4 // the visualizer of the snap timer will be hidden for the first 2/5 of the duration
      )
      this.#createOutlineGroup('CreateFacetModeOutlines', [intersectedModuleGrid], 0.25, false)
      return
    }

    if (snappingMode === 'snap' && !sameFacetAsBefore) {
      // we first check if we're intersecting the same facet as before
      // if not, we reset the snapping timer
      this.#dragAndDropSession.timer.setText('Grouping')
      this.#dragAndDropSession.timer.start(
        this.#SNAPPING_TIME_MS,
        () => {
          const facet = this.#dragAndDropSession.intersectedFacet || null
          const moduleGridToSnap = this.#dragAndDropSession.moduleGrid
          this.#clearDragAndDropSession()
          if (moduleGridToSnap.facet) {
            moduleGridToSnap.unsnapFromCurrentFacet({ newFacet: facet })
          }
          facet && moduleGridToSnap.snapToFacet({ facet, editor: this.#editorInstance })
          moduleGridToSnap.hideSetbacks()
          this.#removeOutlineGroup('SnapToFacetModeOutlines', false)
          this.#createOutlineGroup('SnapCompleteOutline', [moduleGridToSnap], 0.25, true)
        },
        0.4 // the visualizer of the snap timer will be hidden for the first 2/5 of the duration
      )
      this.#createOutlineGroup('SnapToFacetModeOutlines', this.#getModuleGridsOnFacet(intersectedFacet), 0.25, false)
      return
    }
  }

  #computeScreenPositionOf3DObject = (object) => {
    const positionNDC = object.position.clone().project(this.#editorInstance.camera)
    const viewportClientRect = this.#editorInstance.viewport.container.dom.getBoundingClientRect()
    return new window.THREE.Vector2(
      Math.round(viewportClientRect.width * ((positionNDC.x + 1) / 2)),
      Math.round(viewportClientRect.height * ((1 - positionNDC.y) / 2))
    )
  }

  #moduleGridSharesFacet = (moduleGrid1, moduleGrid2) => {
    if (moduleGrid1.facet === null || moduleGrid2.facet === null) return false
    return moduleGrid1.facet === moduleGrid2.facet
  }

  #createOutlineGroup = (groupName, moduleGrids, outlineWidth = 0.15, render = false) => {
    this.#outlinerExtension.createGroup(groupName, moduleGrids).generate(outlineWidth)
    if (render) this.#editorInstance.render()
  }

  #removeOutlineGroup = (groupName, render = false) => {
    this.#outlinerExtension.removeGroup(groupName)
    if (render) this.#editorInstance.render()
  }

  /////////////////////////////////////
  //      DEBUG METHODS
  /////////////////////////////////////

  #log = (message) => {
    console.debug('[DEBUG] NonSpatialFacets: ' + message)
  }

  #trimmUuid = (uuid) => {
    return uuid.substring(0, 7) // only the first 8 chars
  }

  #logModuleGridUnsnappedFromFacet = (moduleGrid, facet, newFacet) => {
    if (!this.debug) return

    this.#log(
      `module grid ${this.#trimmUuid(moduleGrid.uuid)} \nunsnapped from facet ${this.#trimmUuid(facet.uuid)} ${
        facet.isNonSpatial() ? '(non-spatial)' : '(spatial)'
      }, \nnew facet: ${newFacet ? this.#trimmUuid(newFacet.uuid) : 'null'} ${
        newFacet ? (newFacet.isNonSpatial() ? '(non-spatial)' : '(spatial)') : ''
      }`
    )
  }

  #logNonSpatialFacetCreatedForModuleGrid = (nonSpatialFacet, moduleGrid) => {
    if (!this.debug) return

    this.#log(
      `created non-spatial facet ${this.#trimmUuid(nonSpatialFacet.uuid)} for module grid ${this.#trimmUuid(
        moduleGrid.uuid
      )}`
    )
  }

  #logDisposeEmptyNonSpatialFacet = (nonSpatialFacet) => {
    if (!this.debug) return

    this.#log(`non spatial facet ${nonSpatialFacet.uuid.substring(0, 7)} empty, disposing...`)
  }

  #logNonSpatialFacetDataUpdated = (nonSpatialFacet) => {
    if (!this.debug) return

    this.#log(
      `Facet ${this.#trimmUuid(nonSpatialFacet.uuid)} data updated - azimuth: ${nonSpatialFacet.azimuth}, slope: ${
        nonSpatialFacet.slope
      }, position: ${nonSpatialFacet.position.toArray().toString()}`
    )
  }
}

class VisualTimer {
  #DEFAULT_TIME_SPENT_COLOR = '#FCBE85'
  #DEFAULT_TIME_REMAINING_COLOR = '#FFFFFF'
  #DEFAULT_TIMER_TEXT = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'

  #timeSpentColor = this.#DEFAULT_TIME_SPENT_COLOR
  #timeRemainingColor = this.#DEFAULT_TIME_REMAINING_COLOR
  #timerText = this.#DEFAULT_TIMER_TEXT

  #visualizerDomElement
  #viewport
  #currentTween = null

  #isCounting = false

  constructor(viewport) {
    this.#viewport = viewport
    if (document) this.#createVisualizerDomElement()
  }

  start = (durationMs, finishCallback, visualizerDelayPercent = 0) => {
    this.stop()

    this.#viewport.appendChild(this.#visualizerDomElement)

    let visualizerHidden = false
    if (visualizerDelayPercent > 0) {
      this.#visualizerDomElement.style.visibility = 'hidden'
      visualizerHidden = true
    }

    const _this = this
    _this.#isCounting = true
    this.#currentTween = createjs.Tween.get({})
      .to({}, durationMs, createjs.Ease.linear)
      .call(function handleComplete() {
        _this.stop()
        _this.#isCounting = false
        if (finishCallback) finishCallback()
      })
      .on('change', function () {
        const progressFraction = this.position / this.duration
        // this delay computation is not really precise,
        // because this callback is frame-bound
        // but this will suffice in most cases
        if (visualizerHidden && progressFraction > visualizerDelayPercent) {
          _this.#visualizerDomElement.style.visibility = 'visible'
          visualizerHidden = false
        }
        _this.#visualizerDomElement.style.background = _this.#generateCSSBackgroundForFraction(progressFraction)
      })
  }

  stop = () => {
    this.#isCounting = false
    if (this.#currentTween) {
      window.createjs.Tween.removeAllTweens()
      this.#currentTween = null
    }
    if (this.#viewport.contains(this.#visualizerDomElement)) {
      this.#viewport.removeChild(this.#visualizerDomElement)
    }
  }

  isCounting = () => this.#isCounting

  setPosition = (positionInViewportLeft = 0, positionInViewportTop = 0) => {
    let { width, height } = this.#visualizerDomElement.getBoundingClientRect()
    this.#visualizerDomElement.style.left = `${positionInViewportLeft - Math.floor(width / 2)}px`
    this.#visualizerDomElement.style.top = `${positionInViewportTop - Math.floor(height / 2)}px`
  }

  setTimeSpentColor = (colorCode) => {
    this.#timeSpentColor = colorCode
  }

  setTimeRemainingColor = (colorCode) => {
    this.#timeRemainingColor = colorCode
  }

  setText = (text) => {
    this.#timerText = text
    this.#visualizerDomElement.innerHTML = this.#timerText
  }

  resetTimeSpentColor = () => {
    this.#timeSpentColor = this.#DEFAULT_TIME_SPENT_COLOR
  }

  resetTimeRemainingColor = () => {
    this.#timeRemainingColor = this.#DEFAULT_TIME_REMAINING_COLOR
  }

  resetText = () => {
    this.#timerText = this.#DEFAULT_TIMER_TEXT
    this.#visualizerDomElement.innerHTML = this.#timerText
  }

  #createVisualizerDomElement = () => {
    const container = document.createElement('div')

    container.style.position = 'absolute'
    container.style.backgroundColor = 'white'
    container.style.borderRadius = '5px'
    container.innerHTML = this.#timerText
    container.style.padding = '5px 20px 5px 20px'
    container.style.opacity = 0.9
    container.style.fontSize = '0.9em'
    container.style.pointerEvents = 'none'
    container.style.background = `linear-gradient(90deg, ${this.#timeSpentColor} 0%, ${this.#timeRemainingColor} 0%)`

    this.#visualizerDomElement = container
  }

  #generateCSSBackgroundForFraction = (fraction) => {
    return `linear-gradient(90deg, ${this.#timeSpentColor} ${fraction * 100}%, ${this.#timeRemainingColor} 0%)`
  }
}
