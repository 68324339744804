/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @constructor
 */

var SetZoomCommand = function (newValue, oldValue, MapHelper) {
  Command.call(this)
  this.type = 'SetZoomCommand'
  this.name = 'Set Zoom Command'
  this.updatable = true
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['newValue', 'oldValue']

  this.MapHelper = MapHelper || window.MapHelper

  if (arguments.length) {
    this.newValue = newValue
    this.oldValue = oldValue
  }
}

SetZoomCommand.prototype = {
  execute: function () {
    // TODO: Remove these comments after PR gets approved
    // Leaving these here for discussion reference and to ease reverting to old approach
    // this.MapHelper.activeMapInstance.dom.getView
    //   ? this.MapHelper.activeMapInstance.dom.getView().setZoom(this.newValue)
    //   : this.MapHelper.activeMapInstance.dom.setZoom(this.newValue)
    this.MapHelper.activeMapInstance.dom.setZoom(this.newValue)
  },

  undo: function () {
    // TODO: Remove these comments after PR gets approved
    // Leaving these here for discussion reference and to ease reverting to old approach
    // this.MapHelper.activeMapInstance.dom.getView
    //   ? this.MapHelper.activeMapInstance.dom.getView().setZoom(this.oldValue)
    //   : this.MapHelper.activeMapInstance.dom.setZoom(this.oldValue)
    this.MapHelper.activeMapInstance.dom.setZoom(this.oldValue)
  },

  update: function (cmd) {
    this.newValue = cmd.newValue
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
