var OsOtherCache = { geometry: {}, materialsByColor: {} }

function OsOther(options) {
  THREE.Object3D.call(this)
  this.type = 'OsOther'
  this.unshiftNewObject = true
  this.transformable = false
  this.unshiftNewObject = true
  this.selectable = false

  // Allow fetching id from either options or options.userData
  this.other_id = options?.other_id || options?.userData?.other_id || null

  this.other_component_type = options && options.other_component_type ? options.other_component_type : null
  this.code = options && options.code ? options.code : null
  this.title = options && options.title ? options.title : null
  this.manufacturer_name = options && options.manufacturer_name ? options.manufacturer_name : null
  this.external_data = options && options.external_data ? options.external_data : null
  this.description = options && options.description ? options.description : null
  this.show_customer = options && options.hasOwnProperty('show_customer') ? options.show_customer : true
  this.show_in_your_solution =
    options && options.hasOwnProperty('show_in_your_solution') ? options.show_in_your_solution : true
  this.show_in_quotation_table =
    options && options.hasOwnProperty('show_in_quotation_table') ? options.show_in_quotation_table : true
  this.dc_optimizer_efficiency = options && options.dc_optimizer_efficiency ? options.dc_optimizer_efficiency : true
  this.dc_optimizer_max_input_power =
    options && options.dc_optimizer_max_input_power ? options.dc_optimizer_max_input_power : null
  this.dc_optimizer_max_input_voltage =
    options && options.dc_optimizer_max_input_voltage ? options.dc_optimizer_max_input_voltage : null
  this.dc_optimizer_max_input_current =
    options && options.dc_optimizer_max_input_current ? options.dc_optimizer_max_input_current : null
  this.dc_optimizer_max_output_voltage =
    options && options.dc_optimizer_max_output_voltage ? options.dc_optimizer_max_output_voltage : null
  this.dc_optimizer_max_output_current =
    options && options.dc_optimizer_max_output_current ? options.dc_optimizer_max_output_current : null
  this.current_type = options && options.current_type ? options.current_type : null
  this.current_rating = options && options.current_rating ? options.current_rating : null
  this.voltage_to_current_rating =
    options && options.voltage_to_current_rating ? options.voltage_to_current_rating : null
  this.voltage_rating = options && options.voltage_rating ? options.voltage_rating : null
  this.cable_thickness = options && options.cable_thickness ? options.cable_thickness : null
  this.cable_length = options && options.cable_length ? options.cable_length : null
  this.phase_type = options && options.phase_type ? options.phase_type : null
  this.weight = options && options.weight ? options.weight : null

  this.quantity = options && (options.quantity || options.quantity === 0) ? options.quantity : 1
  this.add_mode = options && options.add_mode ? options.add_mode : 'manual'

  // We need to inject this straight into userData beacuse applyUserData is called immediately after constructure
  // in AddObjectCommand. This is strange and confusing.
  this.userData.quantity = this.quantity

  // This is a little ambiguous but it currently allows us to automatically apply/reload userData.slotKey
  // simply by including slotKey in this.fields
  this.slotKey = options && options.slotKey ? options.slotKey : null
  this.userData.slotKey = this.slotKey

  if (options?.userData) {
    this.userData = options.userData
    this.applyUserData()
  } else {
    this.reloadSpecs()
  }

  this.modelLoadedForCheckingChanges = null
  if (this.model) {
    // set from options.userData.model
    this.refreshRenderableObject()
  }

  if (options?.scale) {
    this.scale.copy(options.scale)
  }
}

OsOther.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsOther,
  fields: [
    'other_id',
    'external_data',
    'other_component_type',
    'code',
    'title',
    'manufacturer_name',
    'description',

    // Optimizer fields
    'dc_optimizer_efficiency',
    'dc_optimizer_max_input_power',
    'dc_optimizer_max_input_voltage',
    'dc_optimizer_max_input_current',
    'dc_opimizer_max_output_voltage',
    'dc_optimizer_max_output_current',
    'current_type',
    'current_rating',
    'voltage_rating',
    'cable_thickness',
    'cable_length',
    'phase_type',

    // Isolator fields
    'current_type',
    'current_rating',
    'voltage_to_current_rating',
    'voltage_rating',

    // Cable fields
    'cable_thickness',
    'cable_length',

    // Meter fields
    'phase_type', // Also used for AC isolators

    //Mounting fields
    'weight',

    'show_customer',
    'show_in_your_solution',
    'show_in_quotation_table',
    'compatible_battery_codes',

    // Do not apply quantity from component specs because that is a totally different field (inventory on hand)
    // 'quantity',

    // Model can be either a string that matches a supported model, or an absolute URL for a glb file
    'model',
    'org_id',

    // Slot
    'slotKey',
  ],
  componentType: function (_componentType) {
    if (typeof _componentType === 'undefined') {
      var result = {}
      this.fields.forEach((field) => {
        result[field] = this[field]
      })
      return result
    }

    this.fields.forEach((field) => {
      if (_componentType.hasOwnProperty(field)) {
        this[field] = _componentType[field]
      }
    })

    // Non-standard, nasty, confusing field mapping. We store component_activation_id in other_id
    // NOT the standard component other_id
    // This should be renamed to this.other_activation_id
    this.other_id = _componentType.id

    // @TODO: Add support for absolute URLs for glb models
    // We just need to add a new "model" field to the component type and it will automatically be
    // copied from _componentType.model into this.model

    this.refreshRenderableObject()
  },

  refreshRenderableObject: function () {
    // We only need to refresh if a) we already have a model OR b) we need to load a model

    if (this.model === this.modelLoadedForCheckingChanges) {
      // skip this if we detect the model string is identical to the previously loaded string
      // the new model is already loaded, ignore.
    } else if (this.model || this.object) {
      var _this = this
      var setupModelObject = function (_object) {
        _object.userData.excludeFromExport = true
        _object.selectionDelegate = _this
        _object.castShadow = true

        // traverse the grandchildren and also set selectionDelegate
        if (_object.children) {
          _object.children.forEach((c) => {
            c.selectionDelegate = _this
          })
        }

        _this.transformable = true

        // Do not make selectable by default because otherwise this will block selecting panel grid
        // and painting panels. When we open the "Other Components" tab we will make all OsOther
        // components which have a child object selectable.
        //
        // Annoying hack required if the "others" tab is already open, it should be created as selectable initially
        var selectable = false

        if (
          Designer.ALLOW_SELECT_OTHER_COMPONENT_IN_VIEWPORT &&
          window.reduxStore.getState().designer?.view?.selectedTab === 'mounting'
        ) {
          selectable = true
        }

        _this.selectable = selectable

        _this.object = _object
      }

      // If object already loaded, unload it first
      if (this.object) {
        if (this.modelInstance) {
          this.unloadModel()
        } else {
          this.remove(this.object)
        }
      }

      if (this.model) {
        // old model is loaded but it is being removed

        // Parse model json string to object
        var modelData = {}
        try {
          modelData = JSON.parse(this.model)
        } catch (e) {
          console.warn(e)
        }

        if (modelData.shape && ['cube', 'sphere'].includes(modelData.shape)) {
          // Setup Material
          var colorHexString = modelData.color || 'CCCCCC'
          var opacity = modelData.opacity || 1
          var materialIdentifier = colorHexString + '_' + opacity

          if (!OsOtherCache.materialsByColor[materialIdentifier]) {
            OsOtherCache.materialsByColor[materialIdentifier] = new THREE.MeshStandardMaterial({
              color: parseInt(materialIdentifier, 16),
              opacity: opacity,
              transparent: opacity !== 1 ? true : false,
              metalness: 0,
            })
          }

          // Setup Geometry
          var size3 = new THREE.Vector3(modelData.size_x || 1, modelData.size_y || 1, modelData.size_z || 1)
          var geometryIdentifier = modelData.shape + '_' + size3.toArray().join('_')

          if (!OsOtherCache.geometry[geometryIdentifier]) {
            if (modelData.shape === 'cube') {
              OsOtherCache.geometry[geometryIdentifier] = new THREE.BoxBufferGeometry(
                size3.x,
                size3.y,
                size3.z
              ).translate(0, 0, size3.z / 2)
            } else if (modelData.shape === 'sphere') {
              OsOtherCache.geometry[geometryIdentifier] = new THREE.SphereBufferGeometry(size3.x / 2).translate(
                0,
                0,
                size3.z / 2
              )
            } else {
              throw new Error('unknown shape')
            }
            OsOtherCache.geometry[geometryIdentifier].excludeFromExport = true
          }

          // Create Mesh
          var object = new THREE.Mesh(
            OsOtherCache.geometry[geometryIdentifier],
            OsOtherCache.materialsByColor[materialIdentifier]
          )
          this.add(object)
          setupModelObject(object)
        } else if (modelData.url) {
          this.loadModel(modelData.url, setupModelObject)
        } else {
          console.warn('Unable to determine model to add')
        }
      }

      // Store so we avoid refreshing the model if it has not changed
      this.modelLoadedForCheckingChanges = this.model
    }
  },
  loadModel: function (modelUrl, setupModelObject) {
    var _this = this

    SceneHelper.loadGlbIntoTarget(
      modelUrl,
      this,
      {},
      function (object) {
        setupModelObject(object)
        _this.modelInstance = object
        window.editor.signals.objectChanged.dispatch(_this)
      },
      function () {}
    )
  },
  unloadModel: function () {
    this.remove(this.object)
    this.modelInstance = null
  },
  toolsActive: function () {
    var transformable = this.object ? true : false
    return {
      translateXY: transformable,
      translateZ: transformable,
      translateX: false,
      rotate: transformable,
      scaleXY: transformable,
      scaleZ: transformable,
      scale: transformable, //legacy
    }
  },
  transformWithLocalCoordinates: function () {
    return this.object ? true : false
  },
  getComponentData: function () {
    return AccountHelper.getOtherById(this.other_id)
  },
  refreshUserData: function () {
    if (AccountHelper.loadedDataReady.componentOtherSpecs) {
      this.userData = {}
      this.fields.forEach((field) => {
        this.userData[field] = this[field]
      })
    } else if (window.studioDebug) {
      console.log('Skip OsOther.refreshUserData... other specs not loaded')
    }
    this.userData.quantity = this.quantity
    this.userData.model = this.model
    return this.userData
  },
  reloadSpecs: ObjectBehaviors.reloadSpecs,
  applyUserData: function () {
    ObjectBehaviors.applyUserData.call(this, this.fields, this.userData)
    this.reloadSpecs()
    this.quantity = this.userData.quantity
    this.model = this.userData.model
  },
  getSystem: ObjectBehaviors.getSystem,
})

OsOther.getMountingVisibility = function (panelExpanded) {
  var hasViridian = !!editor.selectedSystem?.integration_json?.viridian
  var hasMountingComponentsWithModel = !!editor.selectedSystem
    ?.others()
    .find((o) => o.other_component_type?.includes('mounting_') && !!o.object)
  return hasViridian && hasMountingComponentsWithModel && panelExpanded === 'mounting'
}

OsOther.refreshVisibility = function (others, panelExpanded) {
  // Hack because we do not actually store state somewhere sensible like redux
  // Required when we need to detect which panel is expanded but not during panel expansion
  if (!panelExpanded) {
    panelExpanded = Designer.panelExpanded
  }
  var visible = OsOther.getMountingVisibility(panelExpanded)
  var hasChanged = false

  others
    .filter((c) => !!c.object)
    .forEach((c) => {
      if (c.visible !== visible) {
        c.visible = visible
        hasChanged = true
      }
    })

  if (hasChanged) {
    // Avoid annoying timeout
    setTimeout(function () {
      editor.render()
    }, 10)
  }
}
