/**
 * @author adampryor
 */

let HandleController = function (editor, viewport) {
  this.name = 'Handle'

  const inputs = {
    activation: (activationState) => {
      if (activationState === this.active) return
      if (activationState === true) {
        this.activate()
      } else {
        this.deactivate()
      }
    },
  }
  this.inputs = inputs

  const outputs = {
    activation: new window.Signal(),
    translateXY: new window.Signal(),
    transformStart: new window.Signal(),
    transformEnd: new window.Signal(),
  }
  this.outputs = outputs

  const hotkeys = new Hotkeys(this)
  hotkeys.on(Hotkeys.ESCAPE).do(() => {
    editor.unlockSelection()
    editor.select(null)
  })

  hotkeys.on(Hotkeys.DELETE).do(() => editor.deleteSelection())
  hotkeys.on(Hotkeys.BACKSPACE).do(() => editor.deleteSelection())

  // var container = viewport.container
  var domElement = viewport.container.dom

  var texturesLoading = 0
  const textureLoader = new THREE.TextureLoader()
  const onTextureLoaded = function () {
    texturesLoading--
    if (texturesLoading === 0) {
      renderThrottled()
    }
  }

  this.active = false

  /*
  Allow quickly disabling refresh without adding/removing handles or triggering an extra refresh
  */
  this.refreshPaused = false

  var handles = {}
  this.handles = handles

  var iconColor = 0xffffff
  var iconColorRollover = 0xffda00

  var handlesConfig = {
    translateXY: {
      icons: ['/images/iconTranslateXY.png'],
      color: 0xffffff,
    },
    rotate: {
      icons: ['/images/iconRotateZ.png'],
      color: 0xffffff,
    },
    translateZ: {
      icons: ['/images/iconTranslateZ.png'],
      color: 0xffffff,
    },
    scaleX1: {
      icons: ['/images/iconScaleXY.png', '/images/iconScaleUniform.png'],
      color: 0xffffff,
    },
    scaleX2: {
      icons: ['/images/iconScaleXY.png', '/images/iconScaleUniform.png'],
      color: 0xffffff,
    },
    scaleY1: {
      icons: ['/images/iconScaleXY.png', '/images/iconScaleUniform.png'],
      color: 0xffffff,
    },
    scaleY2: {
      icons: ['/images/iconScaleXY.png', '/images/iconScaleUniform.png'],
      color: 0xffffff,
    },
    scaleZ2: {
      icons: ['/images/iconScaleZ.png', '/images/iconScaleUniform.png'],
      color: 0xffffff,
    },
  }

  var scaleHandleParams = {
    scaleX1: {
      lineLocusDelta: new THREE.Vector3(-1, 0, 0),
      //Avoid 0, -1, 0 because perfect top-down camera will not intersect
      intersectionPlaneNormal: new THREE.Vector3(0, -1, -1),
      axis: 'x',
      boundingBoxPosition: [0, 0.5, 0.5],
    },
    scaleX2: {
      lineLocusDelta: new THREE.Vector3(-1, 0, 0),
      intersectionPlaneNormal: new THREE.Vector3(0, -1, -1),
      axis: 'x',
      boundingBoxPosition: [1, 0.5, 0.5],
    },
    scaleY1: {
      lineLocusDelta: new THREE.Vector3(0, -1, 0),
      intersectionPlaneNormal: new THREE.Vector3(-1, 0, -1),
      axis: 'y',
      boundingBoxPosition: [0.5, 0, 0.5],
    },
    scaleY2: {
      lineLocusDelta: new THREE.Vector3(0, -1, 0),
      intersectionPlaneNormal: new THREE.Vector3(-1, 0, -1),
      axis: 'y',
      boundingBoxPosition: [0.5, 1, 0.5],
    },
    scaleZ2: {
      lineLocusDelta: new THREE.Vector3(0, 0, 1),
      intersectionPlaneNormal: new THREE.Vector3(-1, 0, 0),
      axis: 'z',
      boundingBoxPosition: [0.5, 0.5, 1],
    },
  }

  var scaleConfigs = {
    scaleInPlace: false,
    scaleLocks: [],
  }

  var emptyHandleData = {
    position: null,
    rotation: null,
    scale: null,
    azimuth: null,
  }
  var iconSizePixels = 20
  var iconSizePixelsHalf = iconSizePixels / 2 //optimization
  var iconPaddingPixels = 40
  var iconPaddingPixelsHalf = iconPaddingPixels / 2 //optimization

  var _this = this
  var _dragging = false
  var _mode = null
  var _handleDataOnPointerDown = emptyHandleData
  var handleRolledOver = false

  var materials = {}

  const pointer = new THREE.Vector2()
  const pointerRaycaster = new THREE.Raycaster()
  const selectedObjectPlane = new THREE.Plane()
  var selectedObjectScreenPoint = new THREE.Vector2()
  var selectedObjectPointer = new THREE.Vector2()

  this.selectedObject = new THREE.Object3D()
  this.selectedObject.position.fromArray([1, 2, 10])

  // Prevent controller state from being overridden by editor.revertControllerState()
  this._autoActivate = true

  // Update plane for various types of intersections
  var planeForIntersection = new THREE.Plane()

  function screenFractionToPointer(screenFraction) {
    return [screenFraction[0] * 2 - 1, -1 * (screenFraction[1] * 2 - 1)]
  }

  // Construct a vertical plane with alignment based on the camera normal so its robust, not aligned with pointer ray
  // Refresh when pointer direction changes
  var upPlaneNormalPerpendicularToRayCaster = new THREE.Vector3(-1, 0, 0)

  function updatePointerRaycasterPlane(event) {
    if (event) {
      updatePointer(event)
      updateRaycaster(event)

      upPlaneNormalPerpendicularToRayCaster.x = pointerRaycaster.ray.direction.x
      upPlaneNormalPerpendicularToRayCaster.y = pointerRaycaster.ray.direction.y
      // upPlaneNormalPerpendicularToRayCaster.normalize() // required???
      // upPlaneNormalPerpendicularToRayCaster.z = 0 // unchanged
    }

    selectedObjectPlane.setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), _this.selectedObject.position)

    selectedObjectScreenPoint.copy(editor.viewport.worldToScreenFraction(_this.selectedObject.position))
    selectedObjectPointer.x = selectedObjectScreenPoint.x * 2 - 1
    selectedObjectPointer.y = -1 * (selectedObjectScreenPoint.y * 2 - 1)
  }

  function updatePointer(event) {
    var eventForPointer = event.changedTouches ? event.changedTouches[0] : event
    var mouseScreenFraction = editor.viewport.getMousePosition(eventForPointer.clientX, eventForPointer.clientY)
    pointer.fromArray(screenFractionToPointer(mouseScreenFraction))
  }

  function getPointer(event) {
    var eventForPointer = event.changedTouches ? event.changedTouches[0] : event
    var mouseScreenFraction = editor.viewport.getMousePosition(eventForPointer.clientX, eventForPointer.clientY)
    return new THREE.Vector3().fromArray(screenFractionToPointer(mouseScreenFraction))
  }

  function updateRaycaster(event) {
    return viewport.generateRaycasterFromMouseCoordAndCamera(pointer, editor.camera, pointerRaycaster)
  }

  function getSpriteUnderMouse(event) {
    // only includes sprites where sprite.interactive is true
    const intersects = pointerRaycaster.intersectObjects(
      Object.values(handles).filter((handle) => handle.interactive),
      true
    )
    return intersects[0] ? intersects[0].object : null
  }

  function raycasterForEvent(event) {
    return viewport.generateRaycasterFromMouseCoordAndCamera(getPointer(event), editor.camera)
  }

  function getPlaneIntersection(event, plane) {
    return raycasterForEvent(event).ray.intersectPlane(plane, new THREE.Vector3())
  }

  function render() {
    editor.render()
  }

  const renderThrottled = window.Utils.throttle(render)

  function dispatchObjectChanged() {
    // ignore if _this.selectedObject is no longer populated
    if (_this.selectedObject) {
      editor.signals.objectChanged.dispatch(_this.selectedObject)
    }
  }

  const dispatchObjectChangedThrottled = window.Utils.throttle(dispatchObjectChanged)

  function refreshHandleData(mode) {
    if (mode) {
      _handleDataOnPointerDown = {
        position: _this.selectedObject?.position?.clone(),
        rotation: _this.selectedObject?.rotation?.clone(),
        azimuth: _this.selectedObject?.getAzimuth && _this.selectedObject.getAzimuth(),
        scale: _this.selectedObject?.scale?.clone(),
      }
    } else {
      _handleDataOnPointerDown = emptyHandleData
    }
  }

  function getRelatedFacetsForObject(obj) {
    if (obj.type === 'OsFacet') {
      return [obj]
    } else if (obj.type === 'OsNode') {
      return obj.facets
    } else if (obj.type === 'OsEdge') {
      return obj.getFacets()
    } else if (obj.type === 'OsGroup') {
      return obj.objects.filter((object) => object.type === 'OsFacet')
    } else {
      return []
    }
  }

  function updateUndoHistory(mode) {
    const cmds = []
    if (mode === 'translateXY' || mode === 'translateZ') {
      let commandUUID = Utils.generateCommandUUIDOrUseGlobal()

      cmds.push(
        new SetPositionCommand(
          _this.selectedObject,
          _this.selectedObject.position.clone(),
          _handleDataOnPointerDown.position,
          commandUUID
        )
      )

      // if this is an object attached to a facet then we should also
      let relatedFacets = getRelatedFacetsForObject(_this.selectedObject)

      relatedFacets.forEach((relatedOsFacet) => {
        let extraCommand = relatedOsFacet.refreshMaxImageryPerspectivesWhenUpdated(commandUUID)
        if (extraCommand) {
          cmds.push(extraCommand)
        }
      })
    } else if (mode === 'rotate') {
      if (_this.selectedObject.type === 'OsModuleGrid') {
        cmds.push(
          new SetPanelConfigurationCommand(
            _this.selectedObject,
            'azimuth',
            _this.selectedObject?.getAzimuth(),
            undefined,
            _handleDataOnPointerDown.azimuth
          )
        )
      } else {
        cmds.push(
          new SetRotationCommand(
            _this.selectedObject,
            _this.selectedObject.rotation.clone(),
            _handleDataOnPointerDown.rotation
          )
        )
      }
    } else if (scaleHandleParams[_mode]) {
      cmds.push(
        new SetScaleCommand(_this.selectedObject, _this.selectedObject.scale.clone(), _handleDataOnPointerDown.scale)
      )
      cmds.push(
        new SetPositionCommand(
          _this.selectedObject,
          _this.selectedObject.position.clone(),
          _handleDataOnPointerDown.position
        )
      )
    }

    // inject commands to undo history
    cmds.forEach((cmd) => {
      editor.execute(cmd)
    })
  }

  function isDragging() {
    return _dragging
  }
  this.isDragging = isDragging

  function onDragStart() {
    // Do not disable annotations if selected object is actually OsAnnotation
    if (_this.selectedObject?.type !== 'OsAnnotation') {
      editor.uiPause('annotation', 'HandleDrag')
      editor.uiPause('ui', 'HandleDrag')
    }
  }

  function onDragEnd() {
    if (_this.selectedObject?.type !== 'OsAnnotation') {
      var dispatchSignalOnResume = false
      editor.uiResume('annotation', 'HandleDrag', dispatchSignalOnResume)
      editor.uiResume('ui', 'HandleDrag')
    }

    if (editor.controllers?.ModulePlacement) {
      editor.controllers?.ModulePlacement.autoActivateRefresh()
    }

    if (_this.selectedObject.onDragEnd) {
      _this.selectedObject.onDragEnd()
    }
  }

  function onPointerDown(event) {
    var eventForPointer = event.changedTouches ? event.changedTouches[0] : event

    updatePointerRaycasterPlane(eventForPointer)

    var sprite = getSpriteUnderMouse(eventForPointer, pointerRaycaster)

    if (sprite) {
      if (!_dragging) {
        _dragging = true
        _mode = sprite.mode
        onDragStart()
        editor.saveControllerState()
        editor.manageController('Camera', false)

        // Deactivate. We do not disable autoActivate because it is slightly safer to leave it enabled.
        // We will manually refresh the auto-Activate state when we finish but we will not remove/re-add the events
        // for managing auto-Activation.
        editor.manageController('ModulePlacement', false)
      }

      // Ensure matrix is correct before attempting to interact
      if (_this.selectedObject) {
        _this.selectedObject.updateMatrixWorld()
      }

      if (_this.selectedObject && _mode) {
        _this.outputs.transformStart.dispatch(_this.selectedObject, _mode)
      }

      refreshHandleData(_mode)
      event.preventDefault()
      event.stopPropagation()
    }
  }

  function onPointerUp(event) {
    if (_dragging) {
      editor.revertControllerState()
      updateUndoHistory(_mode)
      refreshHandleData()
      onDragEnd()
      _dragging = false
    }
    if (_this.selectedObject && _mode) {
      _this.outputs.transformEnd.dispatch(_this.selectedObject, _mode)
    }
    if (_mode === 'translateZ' && _this.selectedObject?.type === 'OsModuleGrid') {
      // just finished moving a module grid along the z axis (up/down)
      // check if module grid did not sink below ground level
      // and raise it to ground level if necessary
      window.SceneHelper.snapModuleGridToGroundLevel(_this.selectedObject)
    }
    if (_mode === 'translateXY' && _this.selectedObject?.type === 'OsModuleGrid') {
      _this.selectedObject.facet ? _this.selectedObject.hideSetbacks() : _this.selectedObject.showSetbacks()
    }
    _mode = null
  }

  function eventWithOffsetRemoved(event, screenOffsetPixels) {
    var eventForPointer = event.changedTouches ? event.changedTouches[0] : event
    return {
      clientX: eventForPointer.clientX - screenOffsetPixels.x / 2,
      clientY: eventForPointer.clientY + screenOffsetPixels.y / 2,
    }
  }

  function scaleObject(object, params, handle, event) {
    var axis = params.axis
    var axisIndex = ['x', 'y', 'z'].indexOf(axis)

    // Keeping this in case we get problems due to using handles position instead of selectedObject.position
    // var startPosition = object.position

    var bbLocal = Utils.getBoundingBoxLocal(object)
    var bbCenterWorld = bbLocal.getCenter(new THREE.Vector3()).applyMatrix4(object.matrixWorld)

    var lineLocus = new THREE.Line3(
      bbCenterWorld,

      // rotate the locus based on the rotation of the object itself
      bbCenterWorld
        .clone()
        .add(params.lineLocusDelta.clone().applyAxisAngle(new THREE.Vector3(0, 0, 1), object.rotation.z))
    )

    // z azis can use upPlaneNormalPerpendicularToRayCaster, others will calculate dynamically
    var intersectionPlaneNormalRotated =
      axis === 'z'
        ? upPlaneNormalPerpendicularToRayCaster
        : params.intersectionPlaneNormal.clone().applyAxisAngle(new THREE.Vector3(0, 0, 1), object.rotation.z)

    // Plane which contains the vertical line up from object position
    // Just about any orientation will do so long as camera is not looking directly along the plane
    planeForIntersection.setFromNormalAndCoplanarPoint(intersectionPlaneNormalRotated, bbCenterWorld)

    var pointOnPlane = getPlaneIntersection(event, planeForIntersection)

    if (!pointOnPlane) {
      console.warn('not found pointOnPlane')
      return
    }

    var closestPointToLine = lineLocus.closestPointToPoint(pointOnPlane, false, new THREE.Vector3())

    // Distance comparison can happen in either world or local units. We need to convert between them because
    // handle position is in world space but boundingBox is in local space

    ////////////////////////////////////////////
    // Calculate & Apply Scale
    ////////////////////////////////////////////

    // Tricky: Old distance is from handle to center of bounding box
    // But new distance is from point on locus to the object start position
    // We could probably try to untangle this but this is how it currently works.
    // It's because the locus goes from the object world position rather than the center of bounding box
    var distanceToCenterOld = handle.position.clone().sub(bbCenterWorld).length()
    var distanceToCenterNew = closestPointToLine.clone().sub(bbCenterWorld).length()

    var isMin = params.boundingBoxPosition[axisIndex] < 0.01

    var distanceChangeTarget = (distanceToCenterNew - distanceToCenterOld) * (isMin ? -1 : 1)

    // @TODO: Intersection with the lineLocus seems to be happening in screen coordinates instead of in the
    // rotated coordinates. Therefore, if your mose stays near the handle the scale is very accurate but
    // if the mouse drifts away from the handle the scale becomes unaccurate and non-intuitive.

    var relativeScaleToApply = (distanceToCenterNew + distanceToCenterOld) / (distanceToCenterOld * 2)
    // var oldScale = object.scale[axis]
    var newScale = object.scale[axis] * relativeScaleToApply

    // var deltaFromObjectPositionToBoundingBoxCenter = bbCenterWorld.clone().sub(object.position)
    var deltaFromObjectPositionToHandle = lineLocus
      .closestPointToPoint(handle.position, false, new THREE.Vector3())
      .sub(lineLocus.closestPointToPoint(object.position, false, new THREE.Vector3()))

    var deltaFromObjectPositionToHandleScaled = deltaFromObjectPositionToHandle
      .clone()
      .multiplyScalar(relativeScaleToApply)
      .sub(deltaFromObjectPositionToHandle)

    var sizeOffsetLocal = new THREE.Vector3()
    sizeOffsetLocal[axis] = distanceChangeTarget

    var sizeOffsetWorld = sizeOffsetLocal
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), object.rotation.z)
      .sub(deltaFromObjectPositionToHandleScaled)

    // finally apply the scale/position updates to the object
    object.scale[axis] = newScale
    if (scaleConfigs.scaleLocks.length > 0 && scaleConfigs.scaleLocks.indexOf(axis) !== -1) {
      scaleConfigs.scaleLocks
        .filter((lockAxis) => {
          return lockAxis !== axis && (lockAxis === 'x' || lockAxis === 'y' || lockAxis === 'z')
        })
        .forEach((tiedAxis) => {
          object.scale[tiedAxis] = newScale
        })
    }
    if (!scaleConfigs.scaleInPlace) {
      object.position.add(sizeOffsetWorld)
    }
  }
  this.scaleObject = scaleObject

  function rotateObject(object, params, handle, event) {
    // Keeping this in case we get problems due to using handles position instead of selectedObject.position
    var startPosition = object.position

    // Calculate and remove the delta between mouseDown pointer and the

    var selectedObjectScreenPoint = editor.viewport.worldToScreenFraction(startPosition)
    var selectedObjectPointer = new THREE.Vector2(
      selectedObjectScreenPoint.x * 2 - 1,
      -1 * (selectedObjectScreenPoint.y * 2 - 1)
    )

    // This would be neater but it doesn't work for some reason...
    //var pointerWithScreenOffsetXRemoved = getPointer(eventWithOffsetRemoved(event, handle.screenOffsetPixels))

    var pointerWithScreenOffsetXRemoved = pointer
      .clone()
      .sub(
        new THREE.Vector2(
          (0.5 * handle.screenOffsetPixels.x) / editor.viewport.rect().width,
          (0.5 * handle.screenOffsetPixels.y) / editor.viewport.rect().height
        )
      )

    var bearing = Utils.bearing(selectedObjectPointer, pointerWithScreenOffsetXRemoved)
    var startRotationDegrees = -_handleDataOnPointerDown.rotation.z * THREE.Math.RAD2DEG
    if (object?.type === 'OsModuleGrid') {
      var azimuthNew = (startRotationDegrees + bearing + 360) % 360
      object.setAzimuth(azimuthNew)
    } else {
      var bearingDelta = bearing - 180
      var bearingDegreesNew = (startRotationDegrees + bearingDelta + 360) % 360
      object.rotation.z = -bearingDegreesNew * THREE.Math.DEG2RAD
    }
  }
  this.rotateObject = rotateObject

  function onPointerMove(event) {
    updatePointerRaycasterPlane(event)

    var rolloverHasChanged = false

    // If dragging, highlighted the handle for the active mode, regardless of where the mouse is
    var sprite = _mode ? handles[_mode] : getSpriteUnderMouse(event, pointerRaycaster)

    Object.values(handles).forEach((handle) => {
      if (handle === sprite) {
        //highlighted
        if (!handle.isHighlighted) {
          handle.material.color = new THREE.Color(iconColorRollover)
          handle.isHighlighted = true
          rolloverHasChanged = true
        }
      } else {
        //not-highlighted
        if (handle.isHighlighted) {
          handle.material.color = new THREE.Color(iconColor)
          handle.isHighlighted = false
          rolloverHasChanged = true
        }
      }
    })

    // Beware: We cannot rely on disabling ModulePlacement controller when rolling over a handle
    // because touch devices will never handle rollover events.
    if (Object.values(handles).some((handle) => handle.isHighlighted)) {
      if (!handleRolledOver) {
        // process newly rolled-over handle
        handleRolledOver = true
      }
    } else if (handleRolledOver) {
      // highlight removed
      handleRolledOver = null
    }

    if (_dragging === false || (event.button !== undefined && event.button !== 0)) {
      // not dragging
    }

    if (_mode === 'translateXY') {
      planeForIntersection.setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), _this.selectedObject.position)
      var planeIntersection = getPlaneIntersection(
        eventWithOffsetRemoved(event, handles.translateXY.screenOffsetPixels),
        planeForIntersection
      )
      _this.selectedObject.position.copy(planeIntersection)
      _this.outputs.translateXY.dispatch(_this.selectedObject, planeIntersection)
    } else if (_mode === 'translateZ') {
      // Keeping this in case we get problems due to using handles position instead of selectedObject.position
      var startPosition = _this.selectedObject.position
      var lineLocus = new THREE.Line3(startPosition, startPosition.clone().add(new THREE.Vector3(0, 0, 10)))

      // var rect = editor.viewport.rect()

      // Plane which contains the vertical line up from object position
      // Just about any orientation will do so long as camera is not looking directly along the plane
      // EEK This happens for North and South Google Oblique views
      // Handle Google North & South Google Oblique views by making it slightly non-EastWest and non-NorthSouth
      // Do not use exactly -1, 0, 0 for intersection plane because it gives degenerate results for preset
      // views like North oblique. It is virtually impossible to get a perfect alignment through changing views.
      planeForIntersection.setFromNormalAndCoplanarPoint(upPlaneNormalPerpendicularToRayCaster, startPosition)
      var pointOnPlane = getPlaneIntersection(
        eventWithOffsetRemoved(event, handles.translateZ.screenOffsetPixels),
        planeForIntersection
      )

      var closestPointToLine = lineLocus.closestPointToPoint(pointOnPlane, false, new THREE.Vector3())
      var newZ = closestPointToLine.z
      var maxHeight = _this.selectedObject.MAX_HEIGHT
      if (typeof maxHeight === 'number' && newZ > maxHeight) {
        _this.selectedObject.position.z = maxHeight
      } else {
        _this.selectedObject.position.z = newZ
      }
    } else if (scaleHandleParams[_mode]) {
      var params = scaleHandleParams[_mode]
      var handle = handles[_mode]
      scaleObject(_this.selectedObject, params, handle, event)
    } else if (_mode === 'rotate') {
      rotateObject(_this.selectedObject, null, handles.rotate, event)
    }

    if (_mode || rolloverHasChanged) {
      refreshHandles()
      renderThrottled()
    }

    if (_mode) {
      event.preventDefault()
      event.stopPropagation()

      dispatchObjectChangedThrottled()
    }
  }

  this.activate = function () {
    if (!this.active) {
      domElement.addEventListener('mousedown', onPointerDown, false)
      domElement.addEventListener('touchstart', onPointerDown, false)
      domElement.addEventListener('mousemove', onPointerMove, false)
      domElement.addEventListener('touchmove', onPointerMove, false)
      domElement.addEventListener('mouseup', onPointerUp, false)
      domElement.addEventListener('mouseout', onPointerUp, false)
      domElement.addEventListener('touchend', onPointerUp, false)
      domElement.addEventListener('touchcancel', onPointerUp, false)
      domElement.addEventListener('touchleave', onPointerUp, false)
    }

    this.active = true

    editor.signals.objectSelected.add(this.refresh)
    editor.signals.objectChanged.add(this.refresh)
    editor.signals.objectAdded.add(this.refresh)
    editor.signals.objectRemoved.add(this.refresh)
    editor.signals.cameraChanged.add(this.refresh)
    editor.signals.sceneLoaded.add(this.refresh)
    editor.signals.controlModeChanged.add(this.refresh)
    editor.signals.displayModeChanged.add(this.refresh)
    editor.signals.sceneGraphChanged.add(this.refresh)
    editor.signals.transformModeChanged.add(this.refresh)
    editor.signals.editorCleared.add(this.refresh)
    editor.signals.modulePlacementStatusChanged.add(this.refresh)

    this.refresh()
    outputs.activation.dispatch(this.active)
    hotkeys.attach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    if (this.active) {
      domElement.removeEventListener('mousedown', onPointerDown, false)
      domElement.removeEventListener('touchstart', onPointerDown, false)
      domElement.removeEventListener('mousemove', onPointerMove, false)
      domElement.removeEventListener('touchmove', onPointerMove, false)
      domElement.removeEventListener('mouseup', onPointerUp, false)
      domElement.removeEventListener('mouseout', onPointerUp, false)
      domElement.removeEventListener('touchend', onPointerUp, false)
      domElement.removeEventListener('touchcancel', onPointerUp, false)
      domElement.removeEventListener('touchleave', onPointerUp, false)
    }

    this.active = false

    editor.signals.objectSelected.remove(this.refresh)
    editor.signals.objectChanged.remove(this.refresh)
    editor.signals.objectAdded.remove(this.refresh)
    editor.signals.objectRemoved.remove(this.refresh)
    editor.signals.cameraChanged.remove(this.refresh)
    editor.signals.sceneLoaded.remove(this.refresh)
    editor.signals.controlModeChanged.remove(this.refresh)
    editor.signals.displayModeChanged.remove(this.refresh)
    editor.signals.sceneGraphChanged.remove(this.refresh)
    editor.signals.transformModeChanged.remove(this.refresh)
    editor.signals.editorCleared.remove(this.refresh)
    editor.signals.modulePlacementStatusChanged.remove(this.refresh)

    _this.selectedObject = null
    refreshHandles()
    outputs.activation.dispatch(this.active)
    hotkeys.detach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  function getHandlePosition(screenOffsetFraction) {
    var _raycaster = viewport.generateRaycasterFromMouseCoordAndCamera(
      selectedObjectPointer.clone().add(screenOffsetFraction),
      editor.camera
    )
    var planeIntersection = _raycaster.ray.intersectPlane(selectedObjectPlane, new THREE.Vector3())

    // In some strange cases, the ray may start below ground and point downwards, so it never intersects the plane.
    // If this happens, invert the ray direction
    if (!planeIntersection) {
      console.warn('Ray did not intersect plane, use negated ray. This should not really be required')
      _raycaster.ray.set(_raycaster.ray.origin, _raycaster.ray.direction.negate())
      planeIntersection = _raycaster.ray.intersectPlane(selectedObjectPlane, new THREE.Vector3())
    }

    return planeIntersection
  }

  function screenOffsetPixelsToFraction(screenOffsetPixels) {
    var rect = editor.viewport.rect()
    return new THREE.Vector2(screenOffsetPixels.x / rect.width, screenOffsetPixels.y / rect.height)
  }

  function hideHandles() {
    Object.values(handles).forEach((handle) => (handle.visible = false))
  }

  function disableHandle(handle) {
    handle.interactive = false
    handle.material.opacity = 0.3
  }

  function enableHandle(handle) {
    handle.interactive = true
    handle.material.opacity = 1
  }

  function refreshHandles() {
    if (
      !_this.selectedObject ||
      _this.selectedObject.type === 'Object3D' ||
      _this.selectedObject.transformable === false
    ) {
      hideHandles()
      return
    } else if (
      editor?.controllers?.FingerPaint?.active &&
      _this.selectedObject.ghostMode &&
      _this.selectedObject.ghostMode()
    ) {
      // Disable handles during SolarTouch
      hideHandles()
      return
    } else {
      var toolsActive = _this.selectedObject.toolsActive ? _this.selectedObject.toolsActive() : {}

      handles.translateXY.visible = toolsActive.translateXY
      handles.translateZ.visible = toolsActive.translateZ
      handles.scaleZ2.visible = toolsActive.scaleZ
      handles.rotate.visible = toolsActive.rotate

      scaleConfigs.scaleInPlace = toolsActive.scaleInPlace ? toolsActive.scaleInPlace : false
      scaleConfigs.scaleLocks = toolsActive.scaleLocks ? toolsActive.scaleLocks : []

      // Scale handle visibility is governed by two checks 1) enabled in toolsActive and 2) scaled in close enough
      var scalingActiveByHandle = {
        scaleX1: toolsActive.scaleXY,
        scaleX2: toolsActive.scaleXY,
        scaleY1: toolsActive.scaleXY,
        scaleY2: toolsActive.scaleXY,
        scaleZ2: toolsActive.scaleZ,
      }
    }

    updatePointerRaycasterPlane()

    ////////////////////////////////////////////////////////////////////////
    // Position handles (with fixed locations) based on how many are visible
    ////////////////////////////////////////////////////////////////////////

    var fixedHandles = []
    if (handles.translateXY.visible) {
      fixedHandles.push(handles.translateXY)
    }
    if (handles.translateZ.visible) {
      fixedHandles.push(handles.translateZ)
    }
    if (handles.rotate.visible) {
      fixedHandles.push(handles.rotate)
    }

    if (fixedHandles.length === 1) {
      fixedHandles[0].screenOffsetPixels.set(0, -100)
    } else if (fixedHandles.length === 2) {
      fixedHandles[0].screenOffsetPixels.set(-iconSizePixelsHalf - iconPaddingPixelsHalf, -100)
      fixedHandles[1].screenOffsetPixels.set(iconSizePixelsHalf + iconPaddingPixelsHalf, -100)
    } else if (fixedHandles.length === 3) {
      fixedHandles[0].screenOffsetPixels.set(-iconSizePixels - iconPaddingPixels, -100)
      fixedHandles[1].screenOffsetPixels.set(0, -100)
      fixedHandles[2].screenOffsetPixels.set(iconSizePixels + iconPaddingPixels, -100)
    }

    // We should probly use the fancy method from TransformController if we need to support more general cases
    // Old method using delta from camera position to selected object position. Fails when the camera is vertical
    // but the selected object is near the edge of the viewport, not near the center
    // var eye = editor.camera.position.clone().sub(_this.selectedObject.position).normalize()
    //
    // New method which compares camera position to the intersection with the camera ray and the ground plane.
    var eye = editor.camera.position.clone().sub(viewport.worldPositionAtViewFinderCenter()).normalize()

    var metersPerPixel = editor.metersPerPixel()
    var spriteSizePixels = iconSizePixels
    var spriteSizeMeters = spriteSizePixels * metersPerPixel

    if (Math.abs(eye.dot(new THREE.Vector3(0, 0, 1))) < 0.01) {
      disableHandle(handles.translateXY)
    } else {
      enableHandle(handles.translateXY)
    }
    handles.translateXY.scale.set(spriteSizeMeters, spriteSizeMeters, spriteSizeMeters)
    handles.translateXY.position.copy(
      getHandlePosition(screenOffsetPixelsToFraction(handles.translateXY.screenOffsetPixels))
    )

    if (Math.abs(eye.dot(new THREE.Vector3(0, 0, 1))) > 0.99) {
      disableHandle(handles.translateZ)
      disableHandle(handles.scaleZ2)
    } else {
      enableHandle(handles.translateZ)
      enableHandle(handles.scaleZ2)
    }
    handles.translateZ.scale.set(spriteSizeMeters, spriteSizeMeters, spriteSizeMeters)
    handles.translateZ.position.copy(
      getHandlePosition(screenOffsetPixelsToFraction(handles.translateZ.screenOffsetPixels))
    )

    if (Math.abs(eye.dot(new THREE.Vector3(0, 0, 1))) < 0.01) {
      disableHandle(handles.rotate)
    } else {
      enableHandle(handles.rotate)
    }
    handles.rotate.scale.set(spriteSizeMeters, spriteSizeMeters, spriteSizeMeters)
    handles.rotate.position.copy(getHandlePosition(screenOffsetPixelsToFraction(handles.rotate.screenOffsetPixels)))

    // Scale handles
    var bbLocal = Utils.getBoundingBoxLocal(_this.selectedObject)
    var bbSizeLocal = bbLocal.getSize(new THREE.Vector3())
    var bbSize = new THREE.Vector3(
      bbSizeLocal.x * _this.selectedObject.scale.x,
      bbSizeLocal.y * _this.selectedObject.scale.y,
      bbSizeLocal.z * _this.selectedObject.scale.z
    )
    var bbSizePixels = bbSize.clone().multiplyScalar(1 / metersPerPixel)

    var bbSizePixelsMin = 20

    var scalingZoomedInEnoughByAxes = {
      x: bbSizePixels.x > bbSizePixelsMin,
      y: bbSizePixels.y > bbSizePixelsMin,
      z: bbSizePixels.z > bbSizePixelsMin,
    }

    // Removed: Instead of spreading out the scaling controls when zoomed out too far, simply hide them instead
    // var bbSizePixelsForShortestAxis = Math.min(bbSizePixels.x, bbSizePixels.y)
    // var bbSizePixelsMin = 50
    // var mid
    // if (bbSizePixelsForShortestAxis < bbSizePixelsMin) {
    //   var bbResizeFactor = bbSizePixelsMin / bbSizePixelsForShortestAxis
    //   var center = bbLocal.getCenter(new THREE.Vector3()).applyMatrix4(_this.selectedObject.matrixWorld)
    //   bbLocal.setFromCenterAndSize(center, bbSize.multiplyScalar(bbResizeFactor))
    //   mid = bbLocal.getCenter(new THREE.Vector3())
    // } else {
    //   mid = bbLocal.getCenter(new THREE.Vector3()).applyMatrix4(_this.selectedObject.matrixWorld)
    // }
    let mid = bbLocal.getCenter(new THREE.Vector3()).applyMatrix4(_this.selectedObject.matrixWorld)

    // Plot midpoint to screen first, which is used to get direction for all handles
    var screenMid = editor.viewport.worldToScreenFraction(mid)

    Object.entries(scaleHandleParams).forEach(([handleName, params]) => {
      // hide if object too small on the screen and handles are too close to interact
      // user can zoom closer to enable scaling handles
      handles[handleName].visible = scalingActiveByHandle[handleName] && scalingZoomedInEnoughByAxes[params.axis]

      // Different handling of Vertical scale compared to others
      if (handleName === 'scaleZ2') {
        if (Math.abs(eye.dot(new THREE.Vector3(0, 0, 1))) > 0.99) {
          disableHandle(handles[handleName])
        } else {
          enableHandle(handles[handleName])
        }
      } else {
        if (Math.abs(eye.dot(new THREE.Vector3(0, 0, 1))) < 0.01) {
          // hide if aligned too closely with camera eye
          disableHandle(handles[handleName])
        } else {
          enableHandle(handles[handleName])
        }
      }

      handles[handleName].scale.set(spriteSizeMeters, spriteSizeMeters, spriteSizeMeters)

      handles[handleName].position.copy(
        new THREE.Vector3(
          (params.boundingBoxPosition[0] - 0.5) * bbSize.x,
          (params.boundingBoxPosition[1] - 0.5) * bbSize.y,
          (params.boundingBoxPosition[2] - 0.5) * bbSize.z
        )
          // rotate vector to apply rotation
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), _this.selectedObject.rotation.z)

          // offset from the bounding box center (which is not affected by rotation)
          .add(mid)
      )

      // rotation: project 3D direction out from bounding box origin in direction of scale
      // calculate in 3D (easy) then project into screen space to get direction vector.
      // calculate bearing of direction vector then voila!
      var screenVectorForDirectionOfScaling = editor.viewport.worldToScreenFraction(handles[handleName].position)
      var bearing = Utils.bearing(screenMid, screenVectorForDirectionOfScaling)
      handles[handleName].material.rotation = bearing * THREE.Math.DEG2RAD
    })
  }

  function createHandles() {
    createHandle('translateXY')
    createHandle('translateZ')
    createHandle('rotate')
    createHandle('scaleX1')
    createHandle('scaleX2')
    createHandle('scaleY1')
    createHandle('scaleY2')
    createHandle('scaleZ2')
  }

  function createHandle(name) {
    if (!handles[name]) {
      var handleConfig = handlesConfig[name]

      if (!materials[name]) {
        texturesLoading += handleConfig.icons.length
        materials[name] = []
        handleConfig.icons.forEach((iconPath) => {
          materials[name].push(
            new THREE.SpriteMaterial({
              map: textureLoader.load(window.Designer.prepareFilePathForLoad(iconPath), onTextureLoaded),
              color: handleConfig.color,
              depthTest: false,
            })
          )
        })
      }
      var initialIconIndex = scaleConfigs.scaleLocks.length > 0 && handleConfig.icons.length > 1 ? 1 : 0
      var handle = new THREE.Sprite(materials[name][initialIconIndex])
      handle.screenOffsetPixels = new THREE.Vector2()
      handle.center = new THREE.Vector2(0.5, 0.5)
      handle.userData.excludeFromExport = true
      handle.clickable = true
      handle.transformable = false
      handle.mode = name
      handle.handleConfig = handleConfig
      handle.currentIconIndex = initialIconIndex
      handle.scaleHandleParams = scaleHandleParams
      editor.sceneHelpers.add(handle)
      handles[name] = handle
    } else {
      var fittingIconSet =
        scaleHandleParams[name] && scaleConfigs.scaleLocks.indexOf(scaleHandleParams[name].axis) !== -1 ? 1 : 0
      if (fittingIconSet !== handles[name].currentIconIndex && materials[name].length > fittingIconSet) {
        handles[name].material = materials[name][fittingIconSet]
        handles[name].material.needsUpdate = true
        handles[name].currentIconIndex = fittingIconSet
      }
    }
  }

  this.handleObjectSelected = function (selectedObject) {
    // This fires even when the controller is not active
    // Do no show handles while objects are being placed (determined by ghostMode())
    if (selectedObject && selectedObject.type !== 'OsSystem' && editor.selected?.ghostMode?.() !== true) {
      this.selectedObject = selectedObject
      if (selectedObject.toolsActive) {
        var toolsActive = selectedObject.toolsActive()
        scaleConfigs.scaleLocks = toolsActive.scaleLocks && toolsActive.scaleLocks.length ? toolsActive.scaleLocks : []
        scaleConfigs.scaleInPlace = toolsActive.scaleInPlace ? toolsActive.scaleInPlace : false
      }
      this.activate()
    } else {
      this.deactivate()
    }
  }

  this.refreshHack = function () {
    _this.selectedObject = editor.selected
    createHandles()
    refreshHandles()
    hideHandles()
  }

  this.refresh = function (objectOverride, attributeName) {
    if (_this.refreshPaused) {
      return
    } else if (objectOverride?.transformable === false) {
      return
    }

    // This can receive all kinds of arguments because it attaches to many signals.
    // We only use objectOverride during testing, so we make a special, arbirary format to ensure we don't accidentally
    // apply this override when responding to normal signals
    if (objectOverride && objectOverride.selectedObjectOverride) {
      _this.selectedObject = objectOverride.selectedObjectOverride
    } else {
      _this.selectedObject = editor.selected
    }

    if (!_this.selectedObject || _this.selectedObject.type === 'OsSystem') {
      // no object selected, hide handles
      hideHandles()
      if (this.active) {
        _this.deactivate()
      }
      return true
    } else if (
      editor.controllers.FingerPaint?.active ||
      editor.controllers.ModulePlacement?.isPainting() ||
      (_this.selectedObject.ghostMode && _this.selectedObject.ghostMode())
    ) {
      /*
      While a) placing/painting modules or b) placing a ghost object hide the handles but do not disable because we need to listen for when
      placing/painting is finshed so we can reveal them again
      */
      hideHandles()
      return true
    }

    createHandles()
    refreshHandles()
    return true
  }
}

window.HandleController = HandleController
