class MeasurementController {
  static name = 'Measurement'

  // @TODO: Maybe add a 'Distance' option that constrains the next point
  // to the same XY plane as the previous measuring point?
  // because the 'Length' offers the full 3D freedom when placing points
  // so may not be accurate in measuring "ground" distance
  static Types = Object.freeze({
    Length: 'length',
    Height: 'height',
  })

  active = false

  #editor
  #viewport
  #container
  #activationCleanup = []

  #session = null
  #segmentUuidsToLabels = {}

  constructor(editor, viewport) {
    this.#editor = editor
    this.#viewport = viewport
    this.#container = new THREE.Group()
    this.#container.name = 'MeasurementController::Container'
    this.#container.userData.excludeFromExport = true
    this.#editor.signals.editorCleared.add(this.clear)
  }

  activate = () => {
    if (this.hasActiveSession()) {
      this.finish()
    }

    this.#editor.scene.add(this.#container)
    this.#activationCleanup.push(() => {
      this.#editor.scene.remove(this.#container)
    })

    this.#showSegmentLabels()
    this.#activationCleanup.push(() => {
      this.#hideSegmentLabels()
    })

    this.#editor.signals.cameraChanged.add(this.#refreshSegmentLabels)
    this.#activationCleanup.push(() => {
      this.#editor.signals.cameraChanged.remove(this.#refreshSegmentLabels)
    })

    this.active = true
  }

  deactivate = () => {
    if (this.hasActiveSession()) {
      this.finish()
      return
    }
    this.#activationCleanup.forEach((f) => f())
    this.#activationCleanup.length = 0
    this.active = false
  }

  isActive = () => this.active

  hasActiveSession = () => !!this.#session

  getMeasurements = () => {
    return this.#container.children.filter((c) => c.type === 'Measurements')
  }

  start = (type = MeasurementController.Types.Length, opts = {}) => {
    const currentView = window.ViewHelper.selectedView()
    if (!currentView) return

    if (window.MapData.is2D(currentView.mapData) && type === MeasurementController.Types.Height) {
      window.Designer.showNotification('Measure Height tool is not available when using 2D imagery.', 'danger')
      return Promise.reject('Measure Height tool aborted, current view is using 2D imagery.')
    }

    this.#editor.deselect()

    const DEFAULT_OPTS = {
      segmentsLimit: 1,
    }

    opts = typeof opts === 'object' ? { ...DEFAULT_OPTS, ...opts } : DEFAULT_OPTS

    if (this.hasActiveSession()) {
      this.finish()
    }

    if (!this.isActive()) {
      this.activate()
    }

    const createDummyLine = () => {
      const geometry = new window.MeshLine()
      geometry.setPoints([new THREE.Vector3(), new THREE.Vector3(0, 0, 0.01)])
      const material = new window.MeshLineMaterial({
        lineWidth: 3,
        color: new THREE.Color(0.9, 0.9, 0.13),
        resolution: new THREE.Vector2(600, 600),
        sizeAttenuation: 0,
        depthTest: false,
      })
      const line = new THREE.Mesh(geometry, material)
      return line
    }

    const createDummyNode = () => {
      const node = new window.OsNode({ position: new THREE.Vector3() })
      node.userData.positionSet = false
      return node
    }

    const createDummyLabel = () => {
      return this.#createSegmentLabel()
    }

    const session = {
      type,
      measurement: new Measurements(),
      segmentsLimit: opts.segmentsLimit,
      promiseWithResolvers: Promise.withResolvers(),

      // snappables is a lit of studio objects that the dummy node can
      // "snap" or attach to as you it gets close to them
      snappables: this.#getSnappableObjectsFromScene(),

      // dummy node is the little sphere that follows the mouse pointer
      dummyNode: createDummyNode(),
      // dummy line connects a previously-placed measuring point
      // to the dummy node that follows the mouse pointer
      dummyLine: createDummyLine(),
      // dummy label shows the measurement value while the mouse pointer moves
      // in real time
      dummyLabel: createDummyLabel(),

      cleanup: [],
    }

    const viewportElement = this.#viewport.container.dom

    viewportElement.style.cursor = 'pointer'
    session.cleanup.push(() => (viewportElement.style.cursor = 'auto'))

    viewportElement.addEventListener('mousedown', this.#onMouseDown)
    session.cleanup.push(() => viewportElement.removeEventListener('mousedown', this.#onMouseDown))

    viewportElement.addEventListener('mousemove', this.#onMouseMove)
    session.cleanup.push(() => viewportElement.removeEventListener('mousemove', this.#onMouseMove))

    viewportElement.addEventListener('touchstart', this.#onTouchStart)
    session.cleanup.push(() => viewportElement.removeEventListener('touchstart', this.#onTouchStart))

    this.#container.add(session.measurement)
    session.cleanup.push(() => {
      if (session.measurement.getNumSegments() === 0) {
        this.#container.remove(session.measurement)
      }
    })

    this.#editor.signals.escapePressed.add(this.finish)
    session.cleanup.push(() => this.#editor.signals.escapePressed.remove(this.finish))

    // finish a session immediately if the current view is changed
    this.#editor.signals.viewsChanged.add(this.finish)
    session.cleanup.push(() => this.#editor.signals.viewsChanged.remove(this.finish))

    this.#editor.controllers.General?.deactivate()
    session.cleanup.push(() => this.#editor.controllers.General?.activate())

    this.#editor.signals.animationStart.dispatch('measurement', session.type)
    session.cleanup.push(() => this.#editor.signals.animationStop.dispatch('measurement', session.type))

    this.#session = session

    return this.#session.promiseWithResolvers.promise
  }

  finish = () => {
    this.#session.cleanup.forEach((func) => func())
    this.#editor.render(true, true)

    if (this.#session.measurement.getNumSegments() === 0) {
      this.#session.promiseWithResolvers.reject('Measuring session cancelled')
    } else {
      const measurementsData = this.#session.measurement.getSegments().map((segment) => segment.serialize())
      this.#session.promiseWithResolvers.resolve(measurementsData)
    }

    this.#session = null
  }

  clear = (opts = {}) => {
    if (!(this.getMeasurements().length > 0)) return

    if (this.isActive()) {
      this.deactivate()
    }

    // delete all segments within all measurements
    const measurements = this.getMeasurements()
    measurements.forEach((measurement) => {
      const segments = measurement.getSegments()
      segments.forEach((s) => {
        this.#deleteSegment(s)
      })
    })

    if (opts.render) {
      this.#editor.render()
    }
  }

  // @TODO: Add more snappable objects to improve snapping logic
  #getSnappableObjectsFromScene = () => {
    const edges = this.#editor.filter('type', 'OsEdge')
    const facets = this.#editor.filterObjects((o) => o.type === 'OsFacetMesh')
    const walls = facets.reduce((accumulator, currentFacet) => {
      accumulator.push(...currentFacet.children.filter((c) => c.name === 'wallMesh'))
      return accumulator
    }, [])
    const setbacksOutline = this.#editor.filter('name', 'SetbacksOutline')
    const activePanels = this.#editor.filter('type', 'OsModule').filter((m) => m.active === true)
    const activePanelOutlines = activePanels.reduce((accumulator, activePanel) => {
      if (!!activePanel.moduleOutline) {
        accumulator.push(activePanel.moduleOutline)
      }
      return accumulator
    }, [])
    return [...edges, ...facets, ...walls, ...setbacksOutline, ...activePanelOutlines]
  }

  #getSceneIntersectionForMouseEvent = (event) => {
    const session = this.#session
    const eventNormalized = Utils.normalizeClientPositionForEvent(event)
    const mousePosition = new THREE.Vector2().fromArray(
      viewport.getMousePosition(eventNormalized.clientX, eventNormalized.clientY)
    )
    const intersection = new THREE.Vector3()
    let intersectionFound = false

    if (session.snappables.length > 0) {
      const facetMeshIntersections = this.#viewport.getIntersects(mousePosition, session.snappables, 0.5)
      if (facetMeshIntersections.length > 0) {
        // Greatly prefer an existing node to a point on an edge or a facet mesh
        // We use the FacetMesh or Edge to determine if an intersection has occurred, but then we will try to
        // upgrade that to a directly node click if possible
        var nodesToCheck = []

        facetMeshIntersections.forEach((fmi) => {
          if (fmi.object.vertices) {
            fmi.object.vertices.forEach((n) => {
              if (n.ghostMode()) return
              nodesToCheck.push(n)
            })
          } else if (fmi.object.nodes) {
            fmi.object.nodes.forEach((n) => {
              if (n.ghostMode()) return
              nodesToCheck.push(n)
            })
          }
        })

        const nodeIntersections = window.OsNode.getIntersectsIncludingInvisibleNodes(
          nodesToCheck,
          this.#viewport,
          mousePosition,
          1.0
        )

        // If we intersected a node then use its position, otherwise use the closest facet mesh intersection
        if (nodeIntersections.length !== 0) {
          intersection.copy(nodeIntersections[0].object.position)
        } else {
          intersection.copy(facetMeshIntersections[0].point)
        }

        intersectionFound = true
      }
    }

    if (!intersectionFound) {
      const terrainPosition = this.#viewport.getIntersectionWithTerrainOrGround(mousePosition)
      if (terrainPosition) {
        intersection.copy(terrainPosition)
        intersectionFound = true
      }
    }

    return intersection
  }

  #calculateZDriftToMouseEvent = (refPosition, mouseEvent) => {
    // STEP 1: create a 3D plane that's facing towards the XY position of the camera
    // we want the plane to always be perpendicular to the XY plane (a.k.a ground plane)
    // let's call this the "z-plane"
    // anchor that plane to the reference position
    const cameraDirection = this.#editor.camera.getWorldDirection(new THREE.Vector3())
    cameraDirection.z = 0
    cameraDirection.negate() // this is not strictly necessary, actually, the plane can face the other way. it's fine :)
    cameraDirection.normalize()
    const planeToCameraAlongXY = new THREE.Plane().setFromNormalAndCoplanarPoint(cameraDirection, refPosition.clone())

    // STEP 2: calculate the Normalized Device Coordinates (NDC) corresponding
    // to the screen position of the mouse pointer
    const eventNormalized = Utils.normalizeClientPositionForEvent(mouseEvent)
    const eventNormalizedToNDC = new THREE.Vector2().fromArray(
      this.#viewport.getMousePosition(eventNormalized.clientX, eventNormalized.clientY)
    )

    // STEP 3: using the NDC of the mouse pointer, we create a ray that "shoots"
    // from the mouse pointer towards the scene
    const raycaster = new THREE.Raycaster()
    raycaster.setFromCamera(
      new THREE.Vector2(eventNormalizedToNDC.x * 2 - 1, -(eventNormalizedToNDC.y * 2) + 1),
      this.#editor.camera
    )

    // STEP 4: get the point where the ray intersects with the z-plane
    // get the difference between the z-coordinates of the reference position to the intersection point
    // the difference is the "z-drift"
    const rayPlaneIntersection = raycaster.ray.intersectPlane(planeToCameraAlongXY, new THREE.Vector3())

    // NOTE that when the camera is perfectly overhead, there won't be an intersection
    // it's not mathematically possible, in this case we want to prevent a crash
    // so we just return 0 as the z-drift to be safe
    return rayPlaneIntersection ? rayPlaneIntersection.z - refPosition.z : 0
  }

  #onMouseMove = (event) => {
    const session = this.#session
    const sessionType = session.type

    const dummyNewPosition = new THREE.Vector3()
    const previousNode = session.measurement.getTailNode()

    if (sessionType === MeasurementController.Types.Height && !!previousNode) {
      const previousPosition = previousNode.position
      const zDrift = this.#calculateZDriftToMouseEvent(previousPosition, event)
      dummyNewPosition.set(previousPosition.x, previousPosition.y, previousPosition.z + zDrift)
    } else {
      dummyNewPosition.copy(this.#getSceneIntersectionForMouseEvent(event))
    }

    const dummyNode = session.dummyNode
    const dummyLine = session.dummyLine
    const dummyLabel = session.dummyLabel

    dummyNode.position.copy(dummyNewPosition)
    if (!dummyNode.userData.positionSet) {
      dummyNode.userData.positionSet = true
    }

    if (!!previousNode) {
      dummyLine.geometry.setPoints([previousNode.position, dummyNode.position])
      dummyLine.geometry.needsUpdate = true
      const currentLength = previousNode.position.distanceTo(dummyNode.position)
      const midpoint = previousNode.position.clone().add(dummyNode.position.clone()).divideScalar(2)
      if (currentLength > 0.05) {
        dummyLabel.setText(`${Utils.trimDecimalPlaces(currentLength, 2)}m`)
        const viewportPosition = this.#viewport.worldToScreen(midpoint)
        if (!this.#viewport.container.dom.contains(dummyLabel)) {
          this.#viewport.container.dom.appendChild(dummyLabel)
          session.cleanup.push(() => {
            if (this.#viewport.container.dom.contains(dummyLabel)) {
              this.#viewport.container.dom.removeChild(dummyLabel)
            }
          })
        }
        let { width, height } = dummyLabel.getBoundingClientRect()
        dummyLabel.style.left = `${viewportPosition.x - Math.floor(width / 2)}px`
        dummyLabel.style.top = `${viewportPosition.y - Math.floor(height / 2)}px`
      }
    }

    if (!dummyNode.parent) {
      this.#container.add(dummyNode)
      dummyNode.refreshForCamera()
      session.cleanup.push(() => this.#container.remove(dummyNode))
    }

    if (!dummyLine.parent && !!previousNode) {
      this.#container.add(dummyLine)
      session.cleanup.push(() => this.#container.remove(dummyLine))
    }
  }

  #onTouchStart = (event) => {
    this.#onMouseDown(event, { isTouchEvent: true })
  }

  #onMouseDown = (event, opts = { isTouchEvent: false }) => {
    if (event.button !== 0 && !opts.isTouchEvent) return // only left click button is allowed

    event.preventDefault()

    const session = this.#session
    const sessionType = this.#session.type
    const dummyNode = session.dummyNode
    const dummyLine = session.dummyLine
    const dummyLabel = session.dummyLabel

    const mousePositionToWorldPosition = dummyNode.position.clone()

    if (!dummyNode.userData.positionSet) {
      const previousNode = session.measurement.getTailNode()
      if (sessionType === MeasurementController.Types.Height && !!previousNode) {
        const previousPosition = previousNode.position
        const zDrift = this.#calculateZDriftToMouseEvent(previousPosition, event)
        mousePositionToWorldPosition.set(previousPosition.x, previousPosition.y, previousPosition.z + zDrift)
      } else {
        mousePositionToWorldPosition.copy(this.#getSceneIntersectionForMouseEvent(event))
      }
    }

    session.measurement.push(mousePositionToWorldPosition)
    session.measurement.refreshNodes()

    const segments = session.measurement.getSegments()
    segments.forEach((segment) => {
      const segmentLabel = this.#segmentUuidsToLabels[segment.uuid]
      if (!segmentLabel) {
        const label = this.#createSegmentLabel({
          onDelete: () => this.#deleteSegment(segment),
        })

        label.setId(segment.uuid)

        const segmentLength = window.Utils.trimDecimalPlaces(segment.getLength(), 2)
        const labelViewportPosition = this.#viewport.worldToScreen(segment.getMidPoint())

        label.setText(`${segmentLength}m`)

        this.#segmentUuidsToLabels[segment.uuid] = label
        this.#viewport.container.dom.appendChild(label)

        let { width, height } = label.getBoundingClientRect()
        label.style.left = `${labelViewportPosition.x - Math.floor(width / 2)}px`
        label.style.top = `${labelViewportPosition.y - Math.floor(height / 2)}px`

        // cache bounding client rect
        segment.userData.labelBoundingRect = { width, height }
        return
      }
    })

    dummyNode.userData.positionSet = false
    dummyLine.geometry.setPoints([new THREE.Vector3(), new THREE.Vector3(0, 0, 0.01)])

    if (this.#viewport.container.dom.contains(dummyLabel)) {
      // temporarily remove the dummy label after placing a point
      // we show it again when the mouse moves for the next segment
      this.#viewport.container.dom.removeChild(dummyLabel)
    }

    if (segments.length === session.segmentsLimit) {
      this.finish()
    }
  }

  #refreshSegmentLabels = () => {
    const segments = this.getMeasurements().reduce((accum, measurements) => {
      accum.push(...measurements.getSegments())
      return accum
    }, [])

    segments.forEach((segment) => {
      const segmentLabel = this.#segmentUuidsToLabels[segment.uuid]
      if (!segmentLabel) return

      const viewportPosition = this.#viewport.worldToScreen(segment.getMidPoint())
      // calculating for the bounding rect can be optimized
      // if the length of the segment hasn't changed, then it's
      // safe to assume the label dimensions hasn't also changed
      let { width, height } = segment.userData.labelBoundingRect || segmentLabel.getBoundingClientRect()
      segmentLabel.style.left = `${viewportPosition.x - Math.floor(width / 2)}px`
      segmentLabel.style.top = `${viewportPosition.y - Math.floor(height / 2)}px`
    })
  }

  #hideSegmentLabels = () => {
    const labelDomElements = Object.values(this.#segmentUuidsToLabels)
    labelDomElements.forEach((l) => {
      if (this.#viewport.container.dom.contains(l)) {
        this.#viewport.container.dom.removeChild(l)
      }
    })
  }

  #showSegmentLabels = () => {
    const labelDomElements = Object.values(this.#segmentUuidsToLabels)
    labelDomElements.forEach((l) => {
      this.#viewport.container.dom.appendChild(l)
    })
    this.#refreshSegmentLabels()
  }

  #deleteSegment = (segment) => {
    if (this.hasActiveSession()) {
      // do not allow deletion to avoid messing with the current session
      return
    }

    const parent = segment.parent
    parent.remove(segment)
    segment.geometry.dispose()

    const label = this.#segmentUuidsToLabels[segment.uuid]
    if (this.#viewport.container.dom.contains(label)) {
      this.#viewport.container.dom.removeChild(label)
    }
    delete this.#segmentUuidsToLabels[segment.uuid]

    const [nodeA, nodeB] = segment.nodes
    const segmentsInParent = parent.getSegments()
    const canDeleteNodeA = !segmentsInParent.find((s) => s.nodes[0] === nodeA || s.nodes[1] === nodeA)
    const canDeleteNodeB = !segmentsInParent.find((s) => s.nodes[0] === nodeB || s.nodes[1] === nodeB)

    if (canDeleteNodeA) {
      parent.remove(nodeA)
    }

    if (canDeleteNodeB) {
      parent.remove(nodeB)
    }

    if (parent.isEmpty()) {
      this.#container.remove(parent)
    }

    this.#editor.render(true, true)
  }

  #createSegmentLabel = (opts = {}) => {
    const container = document.createElement('div')

    container.style.position = 'absolute'
    container.style.color = 'white'
    container.style.width = 'fit-content'
    container.style.borderRadius = '5px'
    container.style.pointerEvents = 'none'
    container.style.display = 'flex'
    container.style.alignItems = 'center'
    container.style.backgroundColor = 'rgba(100, 100, 100, 0.7)'

    if (opts.onDelete) {
      const lengthText = document.createElement('div')
      lengthText.innerHTML = ''
      lengthText.style.padding = '5px 5px 5px 8px'

      const deleteButton = document.createElement('div')
      deleteButton.id = 'delete-button'
      deleteButton.innerHTML = '<div>×</div>'
      deleteButton.style.display = 'flex'
      deleteButton.style.justifyContent = 'center'
      deleteButton.style.alignItems = 'center'
      deleteButton.style.width = '20px'
      deleteButton.style.height = '20px'
      deleteButton.style.borderRadius = '10px'
      deleteButton.style.marginRight = '5px'
      deleteButton.style.backgroundColor = 'rgba(80, 80, 80, 0.7)'
      deleteButton.style.cursor = 'pointer'
      deleteButton.style.pointerEvents = 'auto'
      deleteButton.addEventListener('click', (event) => {
        opts.onDelete()
      })

      // prevent mouse events from propagating upwards
      // when clicking the "x" button to remove the measurement
      // to avoid triggering click-to-select interaction in studio
      container.addEventListener('mouseup', (event) => {
        event.stopPropagation()
      })
      container.addEventListener('mousedown', (event) => {
        event.stopPropagation()
      })
      container.addEventListener('click', (event) => {
        event.stopPropagation()
      })

      container.appendChild(lengthText)
      container.appendChild(deleteButton)

      container.setText = (text) => {
        lengthText.innerHTML = text
      }
    } else {
      container.style.padding = '5px'
      container.innerHTML = 'Segment Label'
      container.setText = (text) => {
        container.innerHTML = text
      }
    }

    container.setId = (id) => {
      container.id = id
    }

    return container
  }
}

class Measurements extends THREE.Group {
  #meshLineMaterial = new MeshLineMaterial({
    lineWidth: 3,
    color: new THREE.Color(0.93, 0.76, 0.13),
    resolution: new THREE.Vector2(600, 600),
    sizeAttenuation: 0,
    depthTest: false,
  })

  constructor() {
    super()
    this.type = 'Measurements'
    this.userData.excludeFromExport = true
  }

  push = (position) => {
    const node = new OsNode({ position })
    node.selectable = true
    this.add(node)
    const nodes = this.getNodes()

    if (nodes.length > 1) {
      const pointA = nodes[nodes.length - 2]
      const pointB = nodes[nodes.length - 1]
      const meshLineGeometry = new window.MeshLine()
      meshLineGeometry.setPoints([pointA.position, pointB.position])
      const meshLine = new window.THREE.Mesh(meshLineGeometry, this.#meshLineMaterial)
      meshLine.nodes = [pointA, pointB]
      meshLine.name = 'Segment'

      meshLine.getLength = () => pointA.position.distanceTo(pointB.position)
      meshLine.getMidPoint = () => pointA.position.clone().add(pointB.position.clone()).divideScalar(2)
      meshLine.serialize = () => {
        return {
          length: meshLine.getLength(),
          points: [pointA.position.toArray(), pointB.position.toArray()],
        }
      }

      this.add(meshLine)
    }
  }

  getNumPoints = () => {
    return this.children.filter((c) => c.type === 'OsNode').length
  }

  getNodes = () => {
    return this.children.filter((c) => c.type === 'OsNode')
  }

  getTailNode = () => {
    const nodes = this.getNodes()
    return nodes.length > 0 ? nodes[nodes.length - 1] : null
  }

  getNumSegments = () => {
    return this.getSegments().length
  }

  getSegments = () => {
    return this.children.filter((c) => c.name === 'Segment')
  }

  getPoints = () => {
    this.getNodes().map((n) => n.position)
  }

  refreshNodes = () => {
    this.getNodes().forEach((n) => n.refreshForCamera())
  }

  isEmpty = () => {
    return this.getNumSegments() === 0
  }
}

window.MeasurementController = MeasurementController
