function ViewBoxHelperClass() {
  // CONSTANTS
  this.RESIZE_HANDLES_SIZE_RADIUS = 8
  this.RESIZE_HANDLES_SCALING_DIR = [
    new THREE.Vector2(-1, -1), // top left
    new THREE.Vector2(1, -1), // top right
    new THREE.Vector2(1, 1), // bottom right
    new THREE.Vector2(-1, 1), // bottom left
  ]
  this.RESIZE_HANDLES_CURSORS = ['nwse-resize', 'nesw-resize', 'nwse-resize', 'nesw-resize']
  // this.MIN_VIEWBOX_SIZE_PX = [200, 200]
  this.DEFAULT_VIEWBOX_SIZE_PX = [550, 330]
  this.VIEWBOX_LINE_WIDTH_FACTOR = 3.5
  this.Y_AXIS_INVERTER = new THREE.Vector3(1, -1, 1)

  this.eventState = {
    activeResizeHandleIndex: -1,
    mouseDownOverVBox: false,
    onMouseDownPos: null,
    viewBoxRefPos: null,
    viewBoxRefScale: null,
    onViewBoxChangeCallback: null,
  }
  this._isEditing = false
  this.storedViewBoxParams = null
  this.viewBox = this._createViewBox()
  this.resizeHandles = this._createResizeHandles()
}

ViewBoxHelperClass.prototype = Object.assign({
  constructor: ViewBoxHelperClass,

  ///////////////////////////////////////////////////////
  //           PUBLIC FUNCTIONS (API)
  // You CAN call these functions from outside
  ///////////////////////////////////////////////////////

  show: function (viewBoxParams) {
    this.endEdit()
    this.viewBox.position
      .fromArray(viewBoxParams.cameraParams.position)
      .setComponent(2, viewBoxParams.cameraParams.position[2] - 1)
    this.viewBox.scale.fromArray(viewBoxParams.size)
    this.viewBox.children[1].material.resolution.set(1200, 1200)
    this.viewBox.children[0].visible = false
    this.viewBox.children[1].visible = true
    this.resizeHandles.visible = false

    this._dashedBounds()
    window.editor.addObject(this.viewBox)
    window.editor.addObject(this.resizeHandles)
    window.editor.render()
    window.editor.signals.viewBoxStatusChanged.dispatch('show')
  },

  startEdit: function (viewBoxChangeCallback) {
    this._isEditing = true
    this.resizeHandles.visible = true
    this.viewBox.children[0].visible = true
    this.viewBox.children[1].visible = true

    this._refreshResizeHandles()
    this._solidBounds()
    // window.editor.setLeftMarginPixels(0, true, true)
    window.editor.render()

    this._resetEventStates()
    this.eventState.onViewBoxChangeCallback = viewBoxChangeCallback
    window.editor.signals.cameraChanged.add(this._onCameraChanged, this)
    this._onMouseDown = this._onMouseDown.bind(this)
    this._onMouseUp = this._onMouseUp.bind(this)
    this._onMouseMove = this._onMouseMove.bind(this)
    document.addEventListener('mousedown', this._onMouseDown)
    document.addEventListener('mousemove', this._onMouseMove)
    document.addEventListener('mouseup', this._onMouseUp)
    document.addEventListener('touchstart', this._onMouseDown)
    document.addEventListener('touchmove', this._onMouseMove)
    document.addEventListener('touchend', this._onMouseUp)
    window.editor.signals.viewBoxStatusChanged.dispatch('edit')
  },

  endEdit: function () {
    this._isEditing = false
    this.resizeHandles.visible = false
    this.viewBox.children[0].visible = false
    this.viewBox.children[1].visible = true

    this._dashedBounds()
    window.editor.render()

    window.editor.signals.cameraChanged.remove(this._onCameraChanged, this)
    document.removeEventListener('mousedown', this._onMouseDown)
    document.removeEventListener('mousemove', this._onMouseMove)
    document.removeEventListener('mouseup', this._onMouseUp)
    document.removeEventListener('touchstart', this._onMouseDown)
    document.removeEventListener('touchmove', this._onMouseMove)
    document.removeEventListener('touchend', this._onMouseUp)
    window.editor.signals.viewBoxStatusChanged.dispatch('lock')
  },

  isEditing: function () {
    return this._isEditing
  },

  getViewBoxParams: function () {
    if (this.viewBox.parent === window.editor.scene) {
      var cameraParams = window.ViewHelper.getCameraParams(editor.camera, editor)
      cameraParams.center = this.viewBox.position.clone().setComponent(2, cameraParams.center[2]).toArray()
      cameraParams.position = this.viewBox.position.clone().setComponent(2, cameraParams.position[2]).toArray()

      return {
        size: this.viewBox.scale.toArray(),
        cameraParams: cameraParams,
      }
    } else {
      return null
    }
  },

  clear: function () {
    this.endEdit()
    this._resetEventStates()
    window.editor.removeObject(this.viewBox)
    window.editor.removeObject(this.resizeHandles)
    editor.render()
    window.editor.signals.viewBoxStatusChanged.dispatch('delete')
  },

  store: function () {
    this.storedViewBoxParams = this.getViewBoxParams()
  },

  getStored: function () {
    return this.storedViewBoxParams
  },

  clearStore: function () {
    this.storedViewBoxParams = null
  },

  ///////////////////////////////////////////////////////
  //           PRIVATE FUNCTIONS
  // Avoid calling these functions directly from outside!
  ///////////////////////////////////////////////////////

  _createResizeHandles: function () {
    const group = new THREE.Group()
    const geometry = new THREE.CircleGeometry(1, 15)
    const material = new THREE.MeshStandardMaterial({ color: 0xffffff })
    group.add(new THREE.Mesh(geometry, material)) // top left
    group.add(new THREE.Mesh(geometry, material)) // top right
    group.add(new THREE.Mesh(geometry, material)) // bottom right
    group.add(new THREE.Mesh(geometry, material)) // bottom left
    group.userData.excludeFromExport = true
    return group
  },

  _createViewBox: function () {
    const vboxGeometry = new THREE.PlaneGeometry(1, 1)
    const vboxMaterial = new THREE.MeshStandardMaterial({ color: 0xffffff, opacity: 0.4, transparent: true })
    const vbox = new THREE.Mesh(vboxGeometry, vboxMaterial)
    vbox.userData.excludeFromExport = true
    vbox.selectable = false

    const boundsGeometry = new MeshLine()
    boundsGeometry.setPoints([
      new THREE.Vector3(-0.5, 0.5),
      new THREE.Vector3(0.5, 0.5),
      new THREE.Vector3(0.5, -0.5),
      new THREE.Vector3(-0.5, -0.5),
      new THREE.Vector3(-0.5, 0.5),
    ])
    const boundsMaterial = new MeshLineMaterial({
      lineWidth: 7,
      color: new THREE.Color(1, 1, 1),
      resolution: new THREE.Vector2(600, 600),
      sizeAttenuation: 0,
    })
    const bounds = new THREE.Mesh(boundsGeometry, boundsMaterial)
    bounds.userData.excludeFromExport = true
    bounds.selectable = false

    var group = new THREE.Group()
    group.add(vbox)
    group.add(bounds)
    return group
  },

  _refreshResizeHandles: function () {
    this.resizeHandles.children[0].position.set(
      this.viewBox.position.x - this.viewBox.scale.x / 2,
      this.viewBox.position.y + this.viewBox.scale.y / 2,
      this.viewBox.position.z
    )
    this.resizeHandles.children[1].position.set(
      this.viewBox.position.x + this.viewBox.scale.x / 2,
      this.viewBox.position.y + this.viewBox.scale.y / 2,
      this.viewBox.position.z
    )
    this.resizeHandles.children[2].position.set(
      this.viewBox.position.x + this.viewBox.scale.x / 2,
      this.viewBox.position.y - this.viewBox.scale.y / 2,
      this.viewBox.position.z
    )
    this.resizeHandles.children[3].position.set(
      this.viewBox.position.x - this.viewBox.scale.x / 2,
      this.viewBox.position.y - this.viewBox.scale.y / 2,
      this.viewBox.position.z
    )
    // get the mpp of the current selected view
    var mpp = window.editor.metersPerPixel()
    // ensure that at any zoom level, the resize handles maintain their pixel size
    this.resizeHandles.children.forEach((handle) => {
      handle.scale.set(mpp * this.RESIZE_HANDLES_SIZE_RADIUS, mpp * this.RESIZE_HANDLES_SIZE_RADIUS, 1)
    })
  },

  _resetEventStates: function () {
    this.eventState.activeResizeHandleIndex = -1
    this.eventState.mouseDownOverVBox = false
    this.eventState.onMouseDownPos = null
    this.eventState.viewBoxRefPos = null
    this.eventState.viewBoxRefScale = null
    this.eventState.onViewBoxChangeCallback = null
  },

  _onCameraChanged: function () {
    var mpp = window.editor.metersPerPixel()
    this.resizeHandles.children.forEach((handle) => {
      handle.scale.set(mpp * this.RESIZE_HANDLES_SIZE_RADIUS, mpp * this.RESIZE_HANDLES_SIZE_RADIUS, 1)
    })
  },

  _onMouseDown: function (event) {
    var eventCoord = this._getEventCoord(event)
    var mpp = window.editor.metersPerPixel()
    var canvasRect = window.viewport.getRenderer().domElement.getBoundingClientRect()
    var canvasCenter = new THREE.Vector2(canvasRect.width / 2, canvasRect.height / 2)
    var mousePosition = new THREE.Vector2(eventCoord.x - canvasRect.left, eventCoord.y - canvasRect.top)
    var mousePxPosFromVportCenter = mousePosition.clone().sub(canvasCenter).multiply(this.Y_AXIS_INVERTER)
    var cameraCenter = new THREE.Vector3().fromArray(
      window.ViewHelper.getCameraParams(window.editor.camera, window.editor).center
    )
    var rHandlesPxPosFromVportCenter = []
    var rHandlesMouseIntersect = []

    this.resizeHandles.children.forEach((handle) => {
      rHandlesPxPosFromVportCenter.push(
        handle.position
          .clone()
          .sub(cameraCenter)
          .divideScalar(mpp)
          .add(new THREE.Vector2(editor.leftMarginPixels / 2, 0))
      )
    })
    rHandlesPxPosFromVportCenter.forEach((resizeHandlePos) => {
      rHandlesMouseIntersect.push(
        mousePxPosFromVportCenter.distanceTo(resizeHandlePos) <= this.RESIZE_HANDLES_SIZE_RADIUS
      )
    })

    this.eventState.activeResizeHandleIndex = rHandlesMouseIntersect.indexOf(true)
    if (this.eventState.activeResizeHandleIndex === -1) {
      var vBoxSizePx = this.viewBox.scale.clone().divideScalar(mpp)
      var vBoxPxPosFromVportCenter = this.viewBox.position
        .clone()
        .sub(cameraCenter)
        .divideScalar(mpp)
        .add(new THREE.Vector2(editor.leftMarginPixels / 2, 0))
      var mousePosVBoxPosDiff = vBoxPxPosFromVportCenter.sub(mousePxPosFromVportCenter)
      this.eventState.mouseDownOverVBox =
        Math.abs(mousePosVBoxPosDiff.x) <= vBoxSizePx.x / 2 && Math.abs(mousePosVBoxPosDiff.y) <= vBoxSizePx.y / 2
    }
    this.eventState.onMouseDownPos = mousePosition
    this.eventState.viewBoxRefScale = this.viewBox.scale.clone()
    this.eventState.viewBoxRefPos = this.viewBox.position.clone()
    if (this.eventState.activeResizeHandleIndex !== -1) {
      window.viewport.container.dom.style.cursor = this.RESIZE_HANDLES_CURSORS[this.eventState.activeResizeHandleIndex]
    }
  },

  _onMouseMove: function (event) {
    if (this.eventState.activeResizeHandleIndex !== -1) {
      // prevent the camera from panning while the mouse is dragging the resize handles
      editor.controllers.Camera.noPan = true
      let eventCoord = this._getEventCoord(event)
      let canvasRect = window.viewport.getRenderer().domElement.getBoundingClientRect()
      let mousePosition = new THREE.Vector2(eventCoord.x - canvasRect.left, eventCoord.y - canvasRect.top)
      let mouseDrift = mousePosition.sub(this.eventState.onMouseDownPos)
      let mpp = window.editor.metersPerPixel()
      // determine the intended scaling direction for both axes (either increase or decrease)
      // based on the what resize handle is being dragged and to which direction
      let viewBoxScaleDiff = mouseDrift.multiply(
        this.RESIZE_HANDLES_SCALING_DIR[this.eventState.activeResizeHandleIndex]
      )
      let viewBoxScaleDiffInWorldUnits = viewBoxScaleDiff.multiplyScalar(mpp)

      // calculate the new scaling factor for the viewbox based on how far the mouse pointer has drifted
      // from its original position
      let newScaleX = this.eventState.viewBoxRefScale.x + viewBoxScaleDiffInWorldUnits.x
      let newScaleY = this.eventState.viewBoxRefScale.y + viewBoxScaleDiffInWorldUnits.y

      // ignore any attempts to resize the viewbox to less than the minimum size in pixels

      if (newScaleX / mpp >= 0) {
        this.viewBox.scale.x = newScaleX
        let posDirX = [-1, 1, 1, -1]
        this.viewBox.position.x =
          this.eventState.viewBoxRefPos.x +
          (viewBoxScaleDiffInWorldUnits.x / 2) * posDirX[this.eventState.activeResizeHandleIndex]
      }
      if (newScaleY / mpp >= 0) {
        this.viewBox.scale.y = newScaleY
        let posDirY = [1, 1, -1, -1]
        this.viewBox.position.y =
          this.eventState.viewBoxRefPos.y +
          (viewBoxScaleDiffInWorldUnits.y / 2) * posDirY[this.eventState.activeResizeHandleIndex]
      }
      this.viewBox.children[1].material.resolution.set(canvasRect.width, canvasRect.height)
      this._refreshResizeHandles()
      return
    }

    if (this.eventState.mouseDownOverVBox) {
      editor.controllers.Camera.noPan = true
      let eventCoord = this._getEventCoord(event)
      let canvasRect = window.viewport.getRenderer().domElement.getBoundingClientRect()
      let mousePosition = new THREE.Vector2(eventCoord.x - canvasRect.left, eventCoord.y - canvasRect.top)
      let mouseDrift = mousePosition.sub(this.eventState.onMouseDownPos)
      let mpp = window.editor.metersPerPixel()
      let mouseDriftInWorldUnits = mouseDrift.multiplyScalar(mpp).multiply(this.Y_AXIS_INVERTER)
      this.viewBox.position.x = this.eventState.viewBoxRefPos.x + mouseDriftInWorldUnits.x
      this.viewBox.position.y = this.eventState.viewBoxRefPos.y + mouseDriftInWorldUnits.y
      this.viewBox.children[1].material.resolution.set(canvasRect.width, canvasRect.height)
      this._refreshResizeHandles()
      return
    }
  },

  _onMouseUp: function (_event) {
    this.eventState.activeResizeHandleIndex = -1
    this.eventState.mouseDownOverVBox = false
    this.eventState.onMouseDownPos = null
    window.viewport.container.dom.style.cursor = 'auto'
    // once the mouse button has been lifted up, restore the ability to pan the camera (if it has been disabled)
    editor.controllers.Camera.noPan = false
    this.eventState.onViewBoxChangeCallback(this.getViewBoxParams())
  },

  _getEventCoord: function (e) {
    let x
    let y
    if (e.type === 'touchstart' || e.type === 'touchmove' || e.type === 'touchend' || e.type === 'touchcancel') {
      var touch = e.touches[0] || e.changedTouches[0]
      x = touch.pageX
      y = touch.pageY
    } else if (e.type === 'mousedown' || e.type === 'mouseup' || e.type === 'mousemove') {
      x = e.clientX
      y = e.clientY
    }
    return { x, y }
  },

  _dashedBounds: function () {
    this.viewBox.children[1].material.dashArray = 0.004
    this.viewBox.children[1].material.dashOffset = 0
    this.viewBox.children[1].material.dashRatio = 0.4
  },

  _solidBounds: function () {
    this.viewBox.children[1].material.dashArray = 0
    this.viewBox.children[1].material.dashOffset = 0
    this.viewBox.children[1].material.dashRatio = 0
  },
})
