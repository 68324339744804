function ShadeHelperClass() {}

ShadeHelperClass.prototype = Object.assign({
  AUTO_TRIGGER_SHADING_MAX_PANELS: 50,
  debugShading: false,
  nextShadeWorkerIterations: 0,
  maxShadeWorkers: Math.min(navigator.hardwareConcurrency || 2, 4),
  moduleShadingInProgress: 0,
  shadeWorkers: [],
  cachebusterKey: Math.random(),
  shadeWorkerAsUrl: null,

  terrainShadingEnv: {
    scene: null,
    camera: null,
    renderer: null,
    canvas: null,
    sun: null,
    isAvailable: true,
  },

  terrainShadingConfig: {
    shadingMonths: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
    rasterSize: 400, // each snapshot will be 400x400 pixels in size
    sunDistance: 1200, // the minimum distance the sun must keep for any given altitude+azimuth
    showCanvas: false,
  },

  /**
   * Simulates terrain shading in a separate 3D environment using:
   *  - a copy of the terrain mesh
   *  - an artificial sun
   *  - an orthographic camera
   *  - a hidden canvas where the simulation is run
   *  returns a collection of snapshots of the canvas
   *  each snapshot corresponds to one sun position
   * @param {OsTerrain} - the terrain to be shaded
   * @param {Array} sunPositions - collection of sun positions to simulate
   *  example: [{ azimuth: Math.PI, altitude, Math.PI / 2 }, { azimuth: Math.PI, altitude, Math.PI / 4 }, ...]
   *
   * @returns {Array<Uint8Array>} - collection of snapshots of the canvas
   */
  simulateTerrainShadingUsingGPU: function (terrain, sunPositions) {
    this.terrainShadingEnv.isAvailable = false

    const TERRAIN_SIZE = AccountHelper.terrainSize

    // create the 3D scene if it doesn't exist yet
    if (!this.terrainShadingEnv.scene) {
      this.terrainShadingEnv.scene = new THREE.Scene()
    }

    // create the camera if it doesn't exist yet
    // use Orthographic Projection so the terrain looks perfectly flat and square from above
    if (!this.terrainShadingEnv.camera) {
      this.terrainShadingEnv.camera = new THREE.OrthographicCamera(
        -(TERRAIN_SIZE / 2), // left
        TERRAIN_SIZE / 2, // right
        TERRAIN_SIZE / 2, // top
        -(TERRAIN_SIZE / 2), // bottom
        0, // near
        1000 // far
      )
    }

    // create the WebGL Renderer if it doesn't exist yet
    if (!this.terrainShadingEnv.renderer) {
      this.terrainShadingEnv.renderer = new THREE.WebGLRenderer({ alpha: false, antialias: true })
      this.terrainShadingEnv.renderer.setSize(
        this.terrainShadingConfig.rasterSize,
        this.terrainShadingConfig.rasterSize
      )
      this.terrainShadingEnv.renderer.shadowMap.enabled = true

      this.terrainShadingEnv.canvas = this.terrainShadingEnv.renderer.domElement
      // configure the CSS of the canvas (useful when the canvas needs to be shown in debug mode)
      this.terrainShadingEnv.canvas.style.position = 'absolute'
      this.terrainShadingEnv.canvas.style.right = '0'
      this.terrainShadingEnv.canvas.style.bottom = '0'
      this.terrainShadingEnv.canvas.style.height = this.terrainShadingConfig.rasterSize + 'px'
      this.terrainShadingEnv.canvas.style.width = this.terrainShadingConfig.rasterSize + 'px'
      this.terrainShadingEnv.canvas.style.zIndex = '10000'
      this.terrainShadingEnv.canvas.style.display = 'none'
    }

    // create the artificial sun if it doesn't exist yet
    if (!this.terrainShadingEnv.sun) {
      // for some reason, DirectionalLight doesn't work properly
      // We'll use PointLight instead
      // we just need to make sure that for any given sun position (azimuth and altitude), it is far
      // enough away to cast near-parallel shadows on the terrain
      this.terrainShadingEnv.sun = new THREE.PointLight(0xffffff, 3)
      this.terrainShadingEnv.sun.distance = this.terrainShadingConfig.sunDistance
      // @TODO disable light decay, for now
      // model the change of the sun's irradiance as it moves from horizon to horizon
      // using a scientifically-correct equation
      this.terrainShadingEnv.sun.decay = 0
      this.terrainShadingEnv.sun.castShadow = true
      this.terrainShadingEnv.sun.shadow.camera.far = this.terrainShadingConfig.sunDistance + 200
      this.terrainShadingEnv.sun.shadow.mapSize.set(1024, 1024)
    }

    // create a mesh that's an exact geometrical copy of the terrain to be shaded
    // IMPORTANT: Do not use/modify the original terrain!
    let geometry = terrain.geometry.clone()
    let maxElevation = geometry.boundingBox.max.z

    let material = new THREE.MeshStandardMaterial({
      metalness: 0.0,
      roughness: 1.0,
      color: 0xffffff,
      opacity: 1.0,
      flatShading: true,
    })
    let terrainMesh = new THREE.Mesh(geometry, material)

    // position the terrain at the center of the scene and configure its lighting properties
    terrainMesh.position.set(0, 0, -maxElevation) // pull the terrain down so that the highest elevation is at z = 0
    terrainMesh.receiveShadow = true
    terrainMesh.castShadow = true

    // set the camera to be at the top of the terrain looking down dead center
    this.terrainShadingEnv.camera.position.set(0, 0, 500)
    this.terrainShadingEnv.camera.lookAt(0, 0, 0)

    // setup the scene
    this.terrainShadingEnv.scene.add(terrainMesh)
    this.terrainShadingEnv.scene.add(this.terrainShadingEnv.sun)

    // helper function to set sun position in the scene
    function setSunPosition(sun, alt, azi, distance) {
      // starting sun position is alt = 0, azi = 0
      // the sun is at the north horizon
      sun.position.set(0, distance, 0)
      let pos = sun.position
      // rotate along X axis for altitude
      sun.position.set(
        pos.x,
        Math.cos(alt) * pos.y - Math.sin(alt) * pos.z,
        Math.sin(alt) * pos.y + Math.cos(alt) * pos.z
      )
      // rotate along Z axis for azimuth
      sun.position.set(
        Math.cos(-azi) * pos.x - Math.sin(-azi) * pos.y,
        Math.sin(-azi) * pos.x + Math.cos(-azi) * pos.y,
        pos.z
      )
    }

    let snapshots = []
    let gl = this.terrainShadingEnv.canvas.getContext('webgl2')

    // create the snapshots buffers beforehand, not within the render loop
    // to avoid stalling the render loop when JS's runtime requests for memory
    for (let i = 0; i < sunPositions.length; i++) {
      snapshots.push(new Uint8Array(this.terrainShadingConfig.rasterSize * this.terrainShadingConfig.rasterSize * 4))
    }

    // finite render loop
    // for each render, capture the pixel values of the canvas and store it as a snapshot
    for (let i = 0; i < sunPositions.length; i++) {
      setSunPosition(
        this.terrainShadingEnv.sun,
        sunPositions[i].altitude,
        sunPositions[i].azimuth,
        this.terrainShadingConfig.sunDistance
      )
      this.terrainShadingEnv.renderer.render(this.terrainShadingEnv.scene, this.terrainShadingEnv.camera)
      gl.readPixels(
        0,
        0,
        this.terrainShadingConfig.rasterSize,
        this.terrainShadingConfig.rasterSize,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        snapshots[i]
      )
    }

    // remove and dispose the terrain mesh from the scene to allow for another terrain to be simulated in the same 3D environment
    // for example, when switching to another project
    this.terrainShadingEnv.scene.remove(terrainMesh)
    terrainMesh.geometry.dispose()
    terrainMesh.material.dispose()
    terrainMesh = undefined

    this.terrainShadingEnv.isAvailable = true

    return snapshots
  },

  /**
   * Creates a data texture from the average of all the snapshots from the terrain shading simulation
   * @param {Array<Uint8Array>} snapshots - canvas snapshots generated by the function simulateTerrainShadingUsingGPU()
   * @returns {DataTexture} - a ThreeJS Data texture that's the average of all the snapshots
   */
  createHeatmapFromTerrainShadingSnapshots: function (snapshots) {
    let rasterSizeSq = this.terrainShadingConfig.rasterSize * this.terrainShadingConfig.rasterSize
    let stride = 4

    // STEP 1: average the pixel values from all snapshots
    // NOTE: A snapshot is in RGBA format, but it's essentially grayscale data
    // So, for each pixel of a snapshot, we only read one subpixel value (Either of the R,G,B values)

    let pixelSum = 0 // stores the current sum of pixel values in the loop
    let pixelAvg = 0 // stores the current average of pixel values in the loop
    let pixelMin = 255 // set to highest possible value initially
    let pixelMax = 0 // set to lowest possible value initially
    let pixelAvgValues = [] // stores all the average pixel values

    for (let pixel = 0; pixel < rasterSizeSq; pixel++) {
      pixelSum = 0
      // sum up values of this pixel from all snapshots
      for (let snap = 0; snap < snapshots.length; snap++) {
        pixelSum += snapshots[snap][pixel * stride]
      }
      // record average pixel value
      pixelAvg = parseInt(pixelSum / snapshots.length)
      pixelAvgValues.push(pixelAvg)

      // record min and max
      if (pixelAvg < pixelMin) {
        pixelMin = pixelAvg
      } else if (pixelAvg > pixelMax) {
        pixelMax = pixelAvg
      }
    }

    // STEP 2: Normalize the average pixel values
    let pixelMeanValuesNormalized = pixelAvgValues.map((value) => (value - pixelMin) / (pixelMax - pixelMin))

    // STEP 3: Map normalized pixel values to the heatmap color scheme
    // Use this mapping to create a new raster data
    let color = null
    let meanRaster = new Uint8Array(this.terrainShadingConfig.rasterSize * this.terrainShadingConfig.rasterSize * 4)

    pixelMeanValuesNormalized.forEach((pixelMeanValueNormalized, pixelIndex) => {
      color = Designer.colorForHeatmap(pixelMeanValueNormalized)
      meanRaster[pixelIndex * stride] = color[0] * 255 // RED
      meanRaster[pixelIndex * stride + 1] = color[1] * 255 // GREEN
      meanRaster[pixelIndex * stride + 2] = color[2] * 255 // BLUE
      meanRaster[pixelIndex * stride + 3] = 255 // ALPHA
    })

    // STEP 4: Use the raster data to create a data texture
    // which can be used to "skin" the terrain
    let texture = new THREE.DataTexture(
      meanRaster,
      this.terrainShadingConfig.rasterSize,
      this.terrainShadingConfig.rasterSize,
      THREE.RGBAFormat
    )
    texture.minFilter = THREE.LinearFilter
    texture.magFilter = THREE.LinearFilter
    return texture
  },

  buildShadeWorker: function () {
    // Old version loaded the file from the actual filesystem
    // Only reload once per page load. Ideally we could cache this but this is better than nothing.
    // var shadeWorker = new Worker('./shade_worker.js?cachebuster=' + this.cachebusterKey)

    // New version builds dynamically
    // Just build the blob url once then reuse for each worker we create
    if (!this.shadeWorkerAsUrl) {
      //@TODO: Beware this will not work when deployed as a Mobile App
      var URL_PARTS = new URL(window.location.href)
      var APP_URL

      if (window.RUNNING_AS_APP_DEVICE) {
        // strip trailing slash
        APP_URL = window.location.origin + window.location.pathname + 'build'
      } else {
        APP_URL = URL_PARTS.protocol + '//' + URL_PARTS.hostname + (URL_PARTS.port ? ':' + URL_PARTS.port : '')
      }

      // var shadeWorkerContentsWithDomain = SHADE_WORKER_CONTENTS.split('__APP_URL__').join(APP_URL)
      // var shadeWorkerAsBlob = new Blob([shadeWorkerContentsWithDomain], { type: 'text/javascript' })
      // this.shadeWorkerAsUrl = URL.createObjectURL(shadeWorkerAsBlob)
      this.shadeWorkerAsUrl = new URL(APP_URL + '/js/shade_worker.js')
    }

    var shadeWorker = new Worker(this.shadeWorkerAsUrl)

    // listen to message event of worker
    shadeWorker.onmessage = function (event) {
      if (this.debugShading) console.log('shadeWorker manager: message received => ' + event.data)

      var m = editor.objectByUuid(event.data.uuid)
      if (m) {
        m.shadingOverride = event.data.shadingOverride
        m.shadingOverrideRaw = event.data.raytraceResults
        m.clearAnnotationCache()
      } else {
        if (this.debugShading) console.info('shade worker: Unable to update shading, module uuid not found')
      }

      ShadeHelper.moduleShadingInProgress--

      if (this.debugShading) console.log('Shading in progress: ' + ShadeHelper.moduleShadingInProgress)

      if (ShadeHelper.moduleShadingInProgress === 0) {
        if (editor.selected && editor.selected.type === 'OsModuleGrid') {
          // Do not do anything that may force a floating panel group to reposition on terrain
          editor.signals.objectSelected.dispatch(editor.selected, 'skipRecalc')
        }

        // resolve any systems with shadingOnReady
        // it is unsufficient to only resolve the system that this module belongs to, because there may have been
        // many systems in the batch
        window.editor.getSystems().forEach((system) => {
          if (!system.shadingOnReady) {
            console.error(
              'shadeWorker manager: Error: system or system.shadingOnReady not found, no promise found to resolve'
            )
          } else if (system.shadingOnReady) {
            if (this.debugShading) console.log('shadeWorker manager: window.shadingOnReady.resolve()')
            system.shadingOnReady.resolve(true)
          } else {
            console.error('shadeWorker manager: Error: Shading finished but no promise found to resolve')
            system.shadingOnReady.reject(true)
          }
        })
      }
    }

    // listen to error event of worker
    shadeWorker.onerror = function (event) {
      console.error('shadeWorker manager: error received from shadeWorker => ', event)
      ShadeHelper.moduleShadingInProgress--
    }

    return shadeWorker
  },

  calculateShadingForModule: function (data) {
    // Add another shadeWorker if necessary
    var shadeWorkerIndex = ShadeHelper.nextShadeWorkerIterations % this.maxShadeWorkers

    if (!this.shadeWorkers[shadeWorkerIndex]) {
      this.shadeWorkers.push(this.buildShadeWorker())
    }

    this.shadeWorkers[shadeWorkerIndex].postMessage(data)

    ShadeHelper.nextShadeWorkerIterations += 1
    ShadeHelper.moduleShadingInProgress++
  },

  clearModuleShading: function (system_uuid) {
    editor
      .filterObjects(function (o) {
        return o.active === true && (!system_uuid || o.getSystem().uuid === system_uuid)
      })
      .forEach(function (m) {
        m.shadingOverride = []
        m.clearAnnotationCache()
      })
  },

  hasShadingCalcsAwaitingTrigger: function (systemUuid) {
    if (systemUuid) {
      // check specified system
      return this.systemsAwaitingTrigger()
        .map((s) => s.uuid)
        .includes(systemUuid)
    } else {
      // check all systems
      return this.systemsAwaitingTrigger().length > 0
    }
  },

  systemsAwaitingTrigger: function () {
    return window.editor?.getSystems().filter((system) => system.shadingCalcsAwaitingTrigger) || []
  },

  systemUuidsAwaitingTrigger: function () {
    return editor
      .getSystems()
      .filter((system) => system.shadingCalcsAwaitingTrigger)
      .map((s) => s.uuid)
      .join(',')
  },

  calculateShadingBlockedAllSystemsAwaitingTrigger: function () {
    this.systemsAwaitingTrigger().forEach((system) => {
      var requireRaytracedShading = system.raytracedShadingAvailable()
      var requireRawShadingData = system.raytracedShadingAvailable()
      return this.calculateShadingBlocked(system, requireRaytracedShading, requireRawShadingData)
    }, this)
  },

  calculateShadingBlocked: function (system, requireRaytracedShading, requireRawShadingData) {
    if (!system) {
      system = editor.selectedSystem
    }

    this.populateShadingWhereOverridesAvailable(system)

    var panelsMissingShading = this.panelsMissingShading(system, requireRaytracedShading, requireRawShadingData)

    if (panelsMissingShading.length > 0) {
      this.calculateShadingOnPanels(panelsMissingShading)
    }
    system.shadingCalcsAwaitingTrigger = false
    editor.signals.viewsChanged.dispatch()
  },

  panelsMissingShading: function (system, requireRaytracedShading, requireRawShadingData) {
    return system.getModules().filter(
      (m) =>
        !m.shadingOverride ||
        (requireRaytracedShading && m.shadingOverride.length !== 288) ||
        // We do not require raw shading data if shading overrides set on the system or the parent grid
        (requireRawShadingData === true &&
          system.shadingOverride.length === 0 &&
          m.getGrid().shadingOverride.length === 0 &&
          (!m.shadingOverrideRaw || m.shadingOverrideRaw.length === 0))
    )
  },

  calculateShadingOnPanels: async function (panels) {
    if (editor.sceneIsLoading || editor.waiting?.terrainFirstRender) {
      console.warn('calculateShadingOnPanels() skipped because scene or terrain is loading.')
      return false
    }

    if (!panels || panels.length === 0) {
      console.warn('calculateShadingOnPanels() called with no panels')
      return
    }
    var lon = SceneHelper.getLongitude()
    var lat = SceneHelper.getLatitude()

    // Standard ray-tracing using elevation grid
    var elevationGrid = window.viewport.render(null, null, true, 0, 0, null, panels[0].getSystem().uuid)
    var elevationGridMax = Math.max(...elevationGrid.map((row) => Math.max(...row)))
    var gridParams = window.viewport.buildGridParams()
    panels.forEach((m) =>
      m.calculateShading(
        elevationGrid,
        elevationGridMax,
        lon,
        lat,
        gridParams,
        editor.scene.preGeneratedRaytraceResults
      )
    )
  },

  getOrBuildCacheSunPositionAtDateTimeUTC: function (lon, lat) {
    //Store as an array instead of object for speed. Ensure the inner loop is hours, outer loop is days
    var keyForLonLat = lon + '_' + lat

    if (!this.cacheSunPositionAtDateTimeUTC) {
      this.cacheSunPositionAtDateTimeUTC = {}
    }

    if (!this.cacheSunPositionAtDateTimeUTC[keyForLonLat]) {
      var data = []
      var day = 0
      Utils.daysInMonth.forEach(function (daysInMonth) {
        for (var hourUTC = 0; hourUTC < 24; hourUTC++) {
          data.push(Utils.sunPositionAtDateTimeUTC(day, hourUTC, lon, lat))
        }
        day += daysInMonth
      })
      this.cacheSunPositionAtDateTimeUTC[keyForLonLat] = data
    }

    return this.cacheSunPositionAtDateTimeUTC[keyForLonLat]
  },

  getOrBuildCacheSunAlignmentDateTimeUTC: function (lon, lat, slope, azimuth) {
    // 1 = sun shining directly onto panel.
    // 0.01 = sun almost coplanar with panel, power approaching zero.
    // 0 = sun behind panel (or coplanar), power = 0
    //Store as an array instead of object for speed. Ensure the inner loop is hours, outer loop is days
    var keyForLonLatSlopeAzimuth = lon + '_' + lat + slope + '_' + azimuth

    if (!this.cacheSunAlignmentDateTimeUTC) {
      this.cacheSunAlignmentDateTimeUTC = {}
    }

    if (!this.cacheSunAlignmentDateTimeUTC[keyForLonLatSlopeAzimuth]) {
      var panelNormal = Utils.normalFromSlopeAzimuth(slope, azimuth)

      var sunPositions = this.getOrBuildCacheSunPositionAtDateTimeUTC(lon, lat)

      var sunAlignments = []

      sunPositions.forEach((sp) => {
        // Calculate direction of ray to determine whether it is behind the plane of the panel
        // This is not considered "shaded" because there is no way for the beam to directly hit the panel anyway
        var inclination = Math.PI / 2 - sp.altitude

        var sunRayDirection = new THREE.Vector3(0, 0, 1)
        sunRayDirection.applyAxisAngle(new THREE.Vector3(1, 0, 0), inclination)
        sunRayDirection.applyAxisAngle(new THREE.Vector3(0, 0, 1), -(sp.azimuth + Math.PI))

        sunAlignments.push(Math.max(sunRayDirection.dot(panelNormal), 0))
      })

      this.cacheSunAlignmentDateTimeUTC[keyForLonLatSlopeAzimuth] = sunAlignments
    }

    return this.cacheSunAlignmentDateTimeUTC[keyForLonLatSlopeAzimuth]
  },

  populateShadingWhereOverridesAvailable: function (system) {
    //@TODO: Can we skip any grids/panels where we already have populated shading data?

    if (!system.raytracedShadingAvailable()) {
      // PVWatts
      // clear if raytraced shading previously set

      // Is this true? Can we remove?
      // don't bother clearing diffuse shading values, we won't use them anyway
      system.moduleGrids().forEach((mg) => {
        // null indicates diffuseShading not populated
        // value of 0 indicates no diffuseShading
        mg.diffuseShading = null
        mg.diffuseShadingBack = null
        mg.beamAccess = null
        mg.beamAccessBack = null
      })

      system.getModules().forEach((m) => {
        // this will use system shading override if set, otherwise module grid shading if set.
        if (system.hasShadingOverride()) {
          m.shadingOverride = system.shadingOverride
          m.shadingOverrideRaw = null
        } else {
          m.shadingOverride = m.getGrid().shadingOverride
          m.shadingOverrideRaw = null
        }
        m.clearAnnotationCache()
      })
    } else if (!editor.scene.raytracedShadingAvailable()) {
      // SAM but not using raytraced shading
      // Diffuse shading will be calculated for module grids later, based on the average of panels
      system.getModules().forEach((m) => {
        // this will use system shading override if set, otherwise module grid shading if set.
        if (system.hasShadingOverride()) {
          m.shadingOverride = OsSystem.shadingOverrideTo288(system.shadingOverride)
          m.shadingOverrideRaw = null
        } else {
          m.shadingOverride = OsSystem.shadingOverrideTo288(m.getGrid().shadingOverride)
          m.shadingOverrideRaw = null
        }
        m.clearAnnotationCache()
      })

      // When raytraced shading not available calculateDiffuseShading() still works
      // but will only be non-zero if overrides are used
      //
      // Dual tilt just uses the same values for front and back
      system.moduleGrids().forEach((mg) => {
        let diffuseShadingResults = mg.calculateDiffuseShading()
        mg.diffuseShading = diffuseShadingResults[0]
        mg.horizonElevations = diffuseShadingResults[1]

        mg.beamAccess = mg.calculateMeanBeamAccess()

        if (mg.isDualTilt()) {
          mg.beamAccessBack = mg.beamAccess
          mg.diffuseShadingBack = mg.diffuseShading
        } else {
          mg.beamAccessBack = null
          mg.diffuseShadingBack = null
        }
      })
    } else {
      // @TODO: Is this required? Wouldn't this already be applied in ModuleGrid.onChange()?
      // inject manual overrides if set on the grid, instead of raytracing
      system.moduleGrids().forEach((mg) => {
        var shadingOverrideToApply

        if (system.hasShadingOverride()) {
          shadingOverrideToApply = OsSystem.shadingOverrideTo288(system.shadingOverride)
        } else if (mg.hasShadingOverride()) {
          shadingOverrideToApply = OsSystem.shadingOverrideTo288(mg.shadingOverride)
        } else {
          shadingOverrideToApply = null
        }

        // Only update modules if a shading override was found
        if (shadingOverrideToApply) {
          mg.getModules().forEach((m) => {
            m.shadingOverride = shadingOverrideToApply
            m.shadingOverrideRaw = null
            m.clearAnnotationCache()
          }, this)

          // Always recalculate diffuseShading here because it is not changed anywhere else
          if (mg.isDualTilt()) {
            mg.beamAccess = mg.calculateMeanBeamAccess({ subset: OsModuleGrid.AzimuthalSubsets.Front })
            mg.beamAccessBack = mg.calculateMeanBeamAccess({ subset: OsModuleGrid.AzimuthalSubsets.Back })

            let diffuseShadingResults = mg.calculateDiffuseShading(OsModuleGrid.AzimuthalSubsets.Front)
            mg.diffuseShading = diffuseShadingResults[0]
            mg.horizonElevations = diffuseShadingResults[1]

            mg.diffuseShadingBack = mg.calculateDiffuseShading(OsModuleGrid.AzimuthalSubsets.Back)[0]
          } else {
            mg.beamAccess = mg.calculateMeanBeamAccess({ subset: OsModuleGrid.AzimuthalSubsets.FrontAndBack })
            mg.beamAccessBack = null

            let diffuseShadingResults = mg.calculateDiffuseShading(OsModuleGrid.AzimuthalSubsets.FrontAndBack)
            mg.diffuseShading = diffuseShadingResults[0]
            mg.horizonElevations = diffuseShadingResults[1]

            mg.diffuseShadingBack = null
          }
        }
      })
    }
  },

  calculateShadingQueue: async function (system_uuid, useOldResultsIfAvailable) {
    if (editor.sceneIsLoading) {
      return false
    }

    var system = editor.objectByUuid(system_uuid)

    // If using PVWatts clear any raytraced shading calcs and do not raytrace

    this.populateShadingWhereOverridesAvailable(system)

    var requireRaytracedShading = system.raytracedShadingAvailable()

    // Check if any panels/panel groups are missing either diffuse or beam shading

    // Diffuse shading is only ever required when using raytraced shading
    var moduleGridsMissingDiffuseShading = requireRaytracedShading
      ? system.moduleGrids().filter((mg) => !mg.diffuseShadingIsReady())
      : []

    var panelsMissingShading = this.panelsMissingShading(system, requireRaytracedShading, false)

    if (
      useOldResultsIfAvailable &&
      panelsMissingShading.length === 0 &&
      moduleGridsMissingDiffuseShading.length === 0
    ) {
      console.log(
        'calculateShadingQueue(): Skip, all modules have shading data calculated and moduleGrids have diffuse shading, re-use it'
      )
      return Promise.resolve(true)
    }

    if (panelsMissingShading.length > 0) {
      var shadingOnReadyPromise = new Promise((resolve, reject) => {
        // save the promise directly on the system object itself so each system stores it's own promise
        // so they can be resolved separately
        system.shadingOnReady = { resolve: resolve, reject: reject }
      })

      const isFujiTourStarted = !!(window.reduxStore?.getState()?.tour.tour === 'fuji')

      // Never show Recalculate button for PVWatts, because it forces triggering raytraced shading
      // and it not necessary anyway because PVWatts runs fast enough with large systems anyway
      // because raytraced shading is not required
      //
      // Always enable auto-calc in SDK even for large systems > 50 panels because the SDK UI is simplified
      // and it is not necessarily possible/easy to trigger calcs manually. #13285
      if (
        panelsMissingShading.length > this.AUTO_TRIGGER_SHADING_MAX_PANELS &&
        !Designer.isNestedWindow() &&
        system.raytracedShadingAvailable() === true &&
        WorkspaceHelper.saveInProgress !== true &&
        !isFujiTourStarted
      ) {
        // promise was created but not resolved until shading calcs finish. They will only start when button pressed
        system.shadingCalcsAwaitingTrigger = true

        // use a dedicated signal instead of repurposing this one? It only needs to show/hide one button
        editor.signals.viewsChanged.dispatch()
      } else {
        this.calculateShadingOnPanels(panelsMissingShading)
      }

      await shadingOnReadyPromise
    }

    // Shading must have been completed for all panels in this system, clear the promise
    system.shadingOnReady = null

    // Only after raytraced shading has run should we calculate diffuse shading
    // so the synchronous step only happens after the async step has completed
    system.moduleGrids().forEach((mg) => {
      if (!mg.diffuseShadingIsReady()) {
        if (mg.isDualTilt()) {
          let diffuseShadingResults = mg.calculateDiffuseShading({ subset: OsModuleGrid.AzimuthalSubsets.Front })
          mg.diffuseShading = diffuseShadingResults[0]
          mg.horizonElevations = diffuseShadingResults[1]

          // Ignore horizonElevations for back because these are the same for both front and back
          mg.diffuseShadingBack = mg.calculateDiffuseShading({ subset: OsModuleGrid.AzimuthalSubsets.Back })[0]

          mg.beamAccess = mg.calculateMeanBeamAccess({ subset: OsModuleGrid.AzimuthalSubsets.Front })
          mg.beamAccessBack = mg.calculateMeanBeamAccess({ subset: OsModuleGrid.AzimuthalSubsets.Back })
        } else {
          let diffuseShadingResults = mg.calculateDiffuseShading()
          mg.diffuseShading = diffuseShadingResults[0]
          mg.horizonElevations = diffuseShadingResults[1]

          mg.diffuseShadingBack = null

          mg.beamAccess = mg.calculateMeanBeamAccess()
          mg.beamAccessBack = null
        }
      }
    })
    moduleGridsMissingDiffuseShading = []

    editor.signals.shadingUpdated.dispatch()
  },

  percentageSunFromBoolWithWeightings: function (values, weightings) {
    const { valuesValid, weightingsValid } = this.stripNullsAndWeightings(values, weightings)
    var cumulativeWeightedValues = 0
    for (var i = 0; i < valuesValid.length; i++) {
      cumulativeWeightedValues += valuesValid[i] * weightingsValid[i]
    }
    return 100 * (cumulativeWeightedValues / this.sumArray(weightingsValid))
  },

  percentageSun: function (values, weightings, abortOnNull) {
    var fraction = this.fractionSun(values, weightings, abortOnNull)
    if (fraction === null) {
      return null
    } else {
      return 100 * this.fractionSun(values, weightings, abortOnNull)
    }
  },

  fractionSun: function (values, weightings, abortOnNull) {
    /*
    Allow early exit if we want to skip calcs where any value is null
    */
    if (abortOnNull === true) {
      if (values[0] === null) {
        return null
      }
    }

    if (!weightings) {
      weightings = _.range(288).map((i) => 1.0)
    }
    const { valuesValid, weightingsValid } = this.stripNullsAndWeightings(values, weightings)
    var cumulativeWeightedValues = 0
    for (var i = 0; i < valuesValid.length; i++) {
      cumulativeWeightedValues += valuesValid[i] * weightingsValid[i]
    }

    return 1.0 - cumulativeWeightedValues / this.sumArray(weightingsValid)
  },

  percentageSunFromBool: function (values) {
    const valuesValid = window.stripNulls(values)
    return 100 * (valuesValid.filter((v) => Boolean(v)).length / valuesValid.length)
  },

  stripNullsAndWeightings: function (values, weightings) {
    var valuesValid = []
    var weightingsValid = []
    values.forEach((value, index) => {
      if (value !== null && weightings[index] > 0) {
        valuesValid.push(value)
        weightingsValid.push(weightings[index])
      }
    })
    return { valuesValid, weightingsValid }
  },
  // Duplicated in misc.ts and Designer.js
  sumArray: function (values) {
    return values.reduce((a, b) => a + b, 0)
  },
  generateShadeSummaryCsv: function () {
    var projectData = window.projectForm.getState().values

    var rows = []

    rows.push(['Project Id', projectData.id])
    rows.push(['Lead Id', projectData.identifier])
    rows.push(['Address', projectData.address || ''])
    rows.push(['Locality', projectData.locality || ''])
    rows.push(['State', projectData.state || ''])
    rows.push(['Zip', projectData.zip || ''])
    rows.push(['Country', projectData.country_iso2 || ''])
    rows.push(['Lat', projectData.lat || ''])
    rows.push(['Lon', projectData.lon || ''])
    rows.push([])

    editor.getSystems().forEach((s) => {
      var systemShadeMetrics = s?.output?.shade_metrics

      const moduleGrids = s.moduleGrids()
      const nonEmptyModuleGrids = moduleGrids.filter((mg) => mg.getModules().length > 0)

      var overallSolarAccess = systemShadeMetrics?.sun_access_factor
        ? Math.round(100 * systemShadeMetrics.sun_access_factor)
        : 'Not available'
      var overallTof = systemShadeMetrics?.tilt_orientation_factor
        ? Math.round(100 * systemShadeMetrics.tilt_orientation_factor)
        : 'Not available'
      var overallTsrf = systemShadeMetrics?.total_solar_resource_fraction
        ? Math.round(100 * systemShadeMetrics.total_solar_resource_fraction)
        : 'Not available'

      rows.push([])
      rows.push(['System', editor.selectedSystem.getName()])
      rows.push(['Arrays', nonEmptyModuleGrids.length])
      rows.push(['Total Panels', s.moduleQuantity()])
      rows.push(['Overall Solar Access', overallSolarAccess])
      rows.push(['Overall TOF', overallTof])
      rows.push(['Overall TSRF', overallTsrf])
      rows.push([])

      var moduleGridHeadings = ['Array', 'Panels', 'Tilt', 'Azimuth', 'Solar Access', 'TOF', 'TSRF']

      rows.push(moduleGridHeadings)

      var moduleGridsSorted = nonEmptyModuleGrids.slice().sort((a, b) => b.moduleQuantity() - a.moduleQuantity())

      var arraySummaryLines = []

      arraySummaryLines.push(`Lead Id: ${projectData.identifier}`)
      arraySummaryLines.push(`Lat/Lon: ${projectData.lat}/${projectData.lon}`)
      arraySummaryLines.push(`Arrays: ${nonEmptyModuleGrids.length}, Total Modules: ${s.moduleQuantity()}`)
      arraySummaryLines.push(
        `Overall Solar Access: ${overallSolarAccess}%, Overall TOF: ${overallTof}%, Overall TSRF: ${overallTsrf}%`
      )
      arraySummaryLines.push('')

      moduleGridsSorted.forEach((mg, mgIndex) => {
        var shade_metrics = s?.output?.shade_metrics?.items[mg.uuid]

        var item = {
          Array: mgIndex + 1,
          Panels: mg.moduleQuantity(),
          Tilt: Math.round(mg.getSlope()),
          Azimuth: Math.round(mg.getAzimuth()),
          'Solar Access': shade_metrics?.sun_access_factor
            ? Math.round(100 * shade_metrics.sun_access_factor)
            : 'Not available',
          TOF: shade_metrics?.tilt_orientation_factor
            ? Math.round(100 * shade_metrics.tilt_orientation_factor)
            : 'Not available',
          TSRF: shade_metrics?.total_solar_resource_fraction
            ? Math.round(100 * shade_metrics.total_solar_resource_fraction)
            : 'Not available',
        }

        rows.push(moduleGridHeadings.map((heading) => item[heading]))

        arraySummaryLines.push(
          `Array (${mgIndex + 1}): Panels ${item.Panels}, Azimuth ${item.Azimuth}, Tilt ${item.Tilt}, Solar Access ${
            item['Solar Access']
          }%`
        )
      })

      rows.push(['Summary', arraySummaryLines.join('\n')])
      rows.push([])
    })

    return rows.map((row) => row.map((cell) => `"${cell}"`).join(',')).join('\n')
  },
  downloadShadeSummaryCsv: function () {
    var projectData = window.projectForm.getState().values
    var projectId = projectData.id
    const content = ShadeHelper.generateShadeSummaryCsv()
    Utils.saveAs('data:text/csv;charset=utf-8,' + content, `ShadingSummary${projectId}.csv`)
  },
})

var ShadeHelper = new ShadeHelperClass()
