/**
 * @author adampryor
 */

var OsNodeCache = null

var SCALE_FACTOR_WHEN_SELECTED = 1.2

function materialWithColor(colorHex) {
  var key = 'material' + colorHex
  if (!OsNodeCache[key]) {
    OsNodeCache[key] = new THREE.MeshStandardMaterial({
      color: Number('0x' + colorHex),
      transparent: true,
      opacity: Utils.iOS() ? 0.3 : 0.5,
      depthTest: false,
    })
  }
  return OsNodeCache[key]
}

function OsNode(options) {
  THREE.Mesh.call(this)

  this.sizeGeometry = 1.0
  this.sizeScreenPixels = Utils.iOS() ? 12.0 : 10.0
  this.highlightScale = 2.0
  this.shadeAnnotation = options && options.shadeAnnotation ? options.shadeAnnotation : null
  this.selectable = options && options.hasOwnProperty('selectable') ? options.selectable : true
  this.visible = options && options.hasOwnProperty('visible') ? options.visible : true

  if (!OsNodeCache) {
    var geometryMain = new THREE.SphereBufferGeometry(this.sizeGeometry / 2)
    geometryMain.excludeFromExport = true

    var geometryHighlight = new THREE.SphereBufferGeometry((this.highlightScale * this.sizeGeometry) / 2)
    geometryHighlight.excludeFromExport = true

    OsNodeCache = {
      geometry: geometryMain,
      geometryHighlight: geometryHighlight,
      materialOnSelect: new THREE.MeshStandardMaterial({
        // Not sure why, but using real colors makes the node look very yellow
        // I tweaked the color and roughness/metalness to make more red, less yellow
        // I tried to fix with emissive but no luck so this workaround was required.
        color: 0xff9c00, //0xffda00,
        roughness: 0.1,
        metalness: 0.5,
        transparent: true,
        opacity: 0.99,
        depthTest: false,
      }),
      materialNotFloating: new THREE.MeshStandardMaterial({
        color: 0x0000ff,
        transparent: true,
        opacity: 0.75,
        depthTest: false,
      }),
      materialFloating: new THREE.MeshStandardMaterial({
        color: 0x999999,
        transparent: true,
        opacity: 0.5,
        depthTest: false,
      }),
      materialHighlight: new THREE.MeshStandardMaterial({
        color: 0xffffff,
        transparent: true,
        opacity: Utils.iOS() ? 0.3 : 0.5,
        depthTest: false,
      }),
      circleShapeMaterialFloating: new THREE.LineBasicMaterial({
        linewidth: 3,
        color: 0x999999,
        opacity: 0.5,
        transparent: true,
      }),
      circleShapeMaterialNotFloating: new THREE.LineBasicMaterial({
        linewidth: 3,
        color: 0x0000ff,
        transparent: true,
      }),
      circleShapeMaterialOnSelect: new THREE.LineBasicMaterial({
        linewidth: 3,
        color: 0xffda00,
        transparent: true,
      }),

      //Beware: They start invisible and are only shown when orbiting
      // vertical guides disabled
      // verticalGuideMaterial: new THREE.LineBasicMaterial({
      //   color: 0x0000ff,
      //   opacity: 1.0,
      //   visible: false,
      // }),
      // verticalGuideGeometry: function() {
      //   var g = new THREE.Geometry()
      //   g.vertices = [new THREE.Vector3(0, 0, -10), new THREE.Vector3(0, 0, 10)]
      //   return g
      // }.call(this),
      circleShapeGeometry: function () {
        var circleRadius = this.sizeGeometry / 2
        var circleShape = new THREE.Shape()
        circleShape.moveTo(0, circleRadius)
        circleShape.quadraticCurveTo(circleRadius, circleRadius, circleRadius, 0)
        circleShape.quadraticCurveTo(circleRadius, -circleRadius, 0, -circleRadius)
        circleShape.quadraticCurveTo(-circleRadius, -circleRadius, -circleRadius, 0)
        circleShape.quadraticCurveTo(-circleRadius, circleRadius, 0, circleRadius)

        var points = circleShape.getPoints(50)
        var geometry = new THREE.BufferGeometry().setFromPoints(points)
        geometry.excludeFromExport = true

        // var geometry = circleShape.createPointsGeometry()
        // geometry.vertices.push(geometry.vertices[0].clone())
        return geometry
      }.call(this),
      angleLineMaterial: new MeshLineMaterial({
        color: new THREE.Color(0xaaaaaa), //grey
        sizeAttenuation: 1,
        lineWidth: 0.1,
        resolution: new THREE.Vector3(600, 600),
        renderOrder: RENDER_ORDER.OsAngle,
      }),
    }
  }

  this.geometry = OsNodeCache.geometry
  this.material = OsNodeCache.material

  if (options?.position) {
    this.position.copy(options.position)
    this.updateMatrix()
  }

  if (options?.colorHex) {
    this.material = materialWithColor(options.colorHex)
  }

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsNode'
  this.name = 'OsNode ' + Math.ceil(Math.random() * 100)
  this.facets = []
  this.derivedFromFacet = (options && options.derivedFromFacet) || null
  this.edges = []
  this.nodeFuseDistance = 1.0
  this.floatingOnFacet = null
  this.floatAppliesOrientation = false
  this.cameraPosition = new THREE.Vector3() //used for billboards

  //Defer until parent is set, either in onAdd()
  this.lineVerticalGuide = null

  if (options && options.facet) {
    this.addToFacet(options.facet)
  }

  this.handle = new THREE.Line(OsNodeCache.circleShapeGeometry, null)
  this.handle.selectionDelegate = this
  this.handle.userData.excludeFromExport = true
  this.handle.selectable = options && options.hasOwnProperty('selectable') ? options.selectable : true

  this.handleMouseOverHighlight = new THREE.Mesh(OsNodeCache.geometryHighlight, OsNodeCache.materialHighlight)
  this.handleMouseOverHighlight.selectionDelegate = this
  this.handleMouseOverHighlight.userData.excludeFromExport = true
  this.add(this.handleMouseOverHighlight)

  this.handleMouse(false)

  this._ghostMode = false

  //Do not save hash until after calling onChange to ensure everything is wired up
  //this.saveHash()

  //Not saved, ephemeral onSelect
  this.hasMergableNodes = false

  this.osAngles = []
}

OsNode.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsNode,
  getName: function () {
    if (this.edges.find(e => e.isWire())) {
      return 'Wire Node'
    } else {
      return 'Roof Node'
    }
  },
  getSystem: ObjectBehaviors.getSystem,
  onAdd: function (editor) {
    // vertical guides disabled
    // this.refreshVerticalGuide()
  },
  toolsActive: function () {
    if (this.isManagedByParent()) {
      return {
        translateXY: false,
        translateZ: false,
        translateX: false,
        rotate: false,
        scaleXY: false,
        scaleZ: false,
        scale: false, //legacy
        delete: false,
        duplicate: false,
      }
    } else {
      return {
        translateXY: true, // also used to control: merge, float
        translateZ:
          this.floatingOnFacet ||
            this.isOnFlatEdgeWithFloatingNodeOnDifferentFacet() ||
            // do not allow elevating a node that is part of a non-triangular facet because it will break planarity
            // unless it has already been elevated, then adjustments may be necessary to fix an existing problem.
            (this.position.z !== 0 && this.facets.some((f) => f.vertices.length > 3))
            ? false
            : true,
        translateX: false,
        rotate: false,
        scaleXY: false,
        scaleZ: false,
        scale: false, //legacy
        delete: true,
        duplicate: false,
      }
    }
  },
  isManagedByParent: function () {
    // Also true if belonging to a locked facet
    return !!this.getFacets().find((f) => f.isManaged || f.isLocked)
  },
  _hash: null,
  belongsToGroup: ObjectBehaviors.belongsToGroup,
  getHash: ObjectBehaviors.changeDetection.getHash,
  saveHash: ObjectBehaviors.changeDetection.saveHash,
  hasChanged: ObjectBehaviors.changeDetection.hasChanged,

  stopFloating: function () {
    if (this.floatingOnFacet) {
      this.floatingOnFacet.removeFloatingObject(this)
      this.floatingOnFacet = null
    }
  },

  startFloating: function () {
    if (this.facets.length) {
      //find the first floating object with at least 3 non-floating points
      for (var i = 0; i < this.facets.length; i++) {
        if (this.facets[i].verticesNotFloating().length > 3) {
          this.facets[i].addFloatingObject(this)

          //@todo: Prevent node from floating on more than once facet

          //return as soon as the first candidate is found
          //@todo: should we allow floating on multiple at once? tricky
          return
        }
      }
    }
  },

  handleMouseBehavior: ObjectBehaviors.handleMouseBehavior,

  handleMouse: function (isOver) {
    this.refreshHandle(isOver)
    this.handleMouseOverHighlight.visible = Utils.iOS() ? true : isOver
  },

  refreshHandle: function (isOver) {
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    var material = this.floatingOnFacet ? OsNodeCache.materialFloating : OsNodeCache.materialNotFloating
    var newMaterial = isSelected ? OsNodeCache.materialOnSelect : material
    if (this.material != newMaterial) {
      this.material = newMaterial
    }

    var handleMaterial = this.floatingOnFacet
      ? OsNodeCache.circleShapeMaterialFloating
      : OsNodeCache.circleShapeMaterialNotFloating

    var newHandleMaterial = isSelected ? OsNodeCache.circleShapeMaterialOnSelect : handleMaterial
    if (this.handle.material != newHandleMaterial) {
      this.handle.material = newHandleMaterial
    }
    //Point the 2D handle circle towards the camera
    this.handle.lookAt(this.cameraPosition)

    this.add(this.handle)
  },

  confirmBeforeDelete: function () {
    return 'Are you sure you want to delete this node? This may break existing edges or facets.'
  },

  refreshVerticalGuide: function () {
    if (this.lineVerticalGuide && this.lineVerticalGuide.parent) {
      this.remove(this.lineVerticalGuide)
    }

    this.lineVerticalGuide = new THREE.Line(OsNodeCache.verticalGuideGeometry, OsNodeCache.verticalGuideMaterial)
    this.lineVerticalGuide.selectable = false
    this.lineVerticalGuide.userData.excludeFromExport = true
    this.add(this.lineVerticalGuide)
  },

  addToFacet: function (_facet, forceLast, allowEdgeAutoCreation) {
    var existsInFacet = false //OsNode.nodeForCoordinates(_facet.vertices, ...this.position.toArray())

    if (this.facets.indexOf(_facet) == -1) {
      //check no other node at this same location

      if (existsInFacet) {
        //skip because a node already exists at this location
        //@todo: shouldn't we do something smart here, instead of just ignoring it?
        return false
      } else {
        this.facets.push(_facet)
      }
    }

    var edgeToRemove = null

    if (_facet.vertices.indexOf(this) == -1) {
      //No need to check when less than 3 vertices (also slighly faster and more intuitive ordering)
      if (_facet.vertices.length < 3) {
        //we never need to remove an edge if there are less than 3 nodes in the facet
        edgeToRemove = null
        _facet.vertices.push(this)

        //Why??? >> }else if (_facet.vertices.length >= 3 || forceLast == true) {
      } else if (forceLast == true) {
        var edgeToRemoveNodes = [_facet.vertices[_facet.vertices.length - 1], _facet.vertices[0]]
        edgeToRemove = _facet.getEdgeWithNodes(edgeToRemoveNodes)

        _facet.vertices.push(this)
      } else {
        //Identify lines based on the starting vertex. Last line joins last vertex back to first
        var closestLineIndex = 0
        var closestDistance = 1000000

        for (var i = 0; i < _facet.vertices.length; i++) {
          var line = new THREE.Line3(
            _facet.vertices[i].position.clone(),
            _facet.vertices[(i + 1) % _facet.vertices.length].position.clone()
          )

          //Tricky: Shrink the line very slightly. This prevents an issue where closest line
          //is ambiguous when closest point on two lines is the end of the line and thus equidistant.
          //Shrinking the line prevents the lines having identical distance in this case.
          //Note: line.closestPointToPoint must be clamped to endpoints for shrinkage to have any effect
          var offset = line.delta(new THREE.Vector3()).multiplyScalar(0.01) //so it doesn't change during update
          line.start.add(offset)
          line.end.sub(offset)

          var distance = line
            .closestPointToPoint(this.position, true, new THREE.Vector3())
            .distanceToSquared(this.position)
          if (distance < closestDistance) {
            closestLineIndex = i
            closestDistance = distance
          }
        }

        //If closes line is {2,3} then we insert 3 (and push old 3 into position 4)
        //If between last and 0, then insert after last

        //Add split existing edge if clicking very close to the line, otherwise add at the end

        if (closestDistance < 0.5) {
          var edgeToRemoveNodes = [_facet.vertices[closestLineIndex], _facet.vertices[closestLineIndex + 1]]
          edgeToRemove = _facet.getEdgeWithNodes(edgeToRemoveNodes)

          _facet.vertices.splice(closestLineIndex + 1, 0, this)
        } else {
          var edgeToRemoveNodes = [_facet.vertices[_facet.vertices.length - 1], _facet.vertices[0]]
          edgeToRemove = _facet.getEdgeWithNodes(edgeToRemoveNodes)

          _facet.vertices.push(this)
        }
      }
    }

    return true
  },

  removeFromFacet: function (facet) {
    if (facet.vertices.indexOf(this) != -1) {
      facet.vertices.splice(facet.vertices.indexOf(this), 1)
    }

    if (this.facets.indexOf(facet) != -1) {
      this.facets.splice(this.facets.indexOf(facet), 1)
    }
  },

  getFacets: function () {
    return this.facets
  },

  addToEdge: function (_edge) {
    if (this.edges.indexOf(_edge) == -1) {
      //if(!OsNode.nodeForCoordinates(_edge.nodes, ...this.position.toArray())){
      this.edges.push(_edge)
      return true
      //}
    }

    return false
  },
  removeEdge: function (_edge) {
    this.edges.splice(this.edges.indexOf(_edge), 1)
  },

  getEdges: function (edgeToExclude) {
    if (typeof edgeToExclude === 'undefined') {
      return this.edges
    } else {
      return this.edges.filter(function (e) {
        return e != edgeToExclude
      })
    }
  },

  hasEdgeType: function (edgeType) {
    return (
      this.edges.filter(function (e) {
        return e.edgeType === edgeType
      }).length > 0
    )
  },

  isOnFlatEdgeWithFloatingNodeOnDifferentFacet: function () {
    //Check if part of a flat edge where other edge is floating on a different facet
    //That prevents manual Z translation because this Z is derived

    //Note: If floating on the same facet then allow elevation changes because this is required
    //to update the floating node

    //@todo: recursive
    //This is also recursive. If A is floating and AB, BC, CD are all flat
    //then B, C and D all used A.z as their elevation

    var nodesInFlatEdgeChain = []

    var edges = this.getEdges()

    for (var i = 0; i < edges.length; i++) {
      if (SetbacksHelper.edgeTypeIsFlat(edges[i].edgeType)) {
        var otherNode = edges[i].getOtherNode(this)
        if (nodesInFlatEdgeChain.indexOf(otherNode) == -1) {
          nodesInFlatEdgeChain.push(otherNode)
        }
      }
    }

    return (
      nodesInFlatEdgeChain.filter(function (n) {
        return n.floatingOnFacet && this.facets.indexOf(n.floatingOnFacet) == -1
      }, this).length > 0
    )
  },

  dispose: function () { },

  onRemove: function (editor) {
    /*
    Note: We currently need to temporarily disable signals if we want to remove a node without triggering facet detection
    */
    if (editor.changingHistory) {
      // if (!this.ghostMode()) {
      //   OsEdge.addMissingFacetsForEdgeNetwork(editor)
      // }
      return
    }

    var edgesInitial = this.getEdges().slice(0)

    edgesInitial.forEach(function (_edge) {
      if (_edge.parent) {
        if (_edge.ghostMode()) {
          //always remove an associated ghost edge without using a command
          editor.removeObject(_edge)
        } else if (_edge.RemoveWithoutCommand) {
          // Hack to prevent node removal when deactivating SequenceController from creating a command
          editor.removeObject(_edge)
        } else {
          editor.execute(new RemoveObjectCommand(_edge, false))
        }
      } else {
        //Dangerous??? What if the edge has child objects which are still in viewport.objects?
        //console.log("Warning: skipping removeObject(_edge) _edge.parent is null")

        // Attempted fix:
        // Even though this edge has already been removed, we may still need to cleanup references to/from nodes
        if (_edge.nodes[0] === this) {
          _edge.nodes[0] = null
        }

        if (_edge.nodes[1] === this) {
          _edge.nodes[1] = null
        }
      }
    }, this)

    // Do not iterate over this.facets directly while running this.removeFromFacet(_facet) because it will be
    // modified during iteration
    var facetsInitial = this.facets.slice(0)

    facetsInitial.forEach(function (_facet) {
      this.removeFromFacet(_facet)
    }, this)

    facetsInitial.forEach(function (_facet) {
      _facet.rebuildEdges(editor)
      _facet.onChange(editor)
    }, this)

    //Facets must rebuild themselves if possible
    //Ghosts don't need to check for auto-creating facets
    if (!this.ghostMode()) {
      OsEdge.addMissingFacetsForEdgeNetwork(editor)
    }
  },

  fuseAndDispose: function (survivingNode) {
    /*
        Transfer all references to this node to the other, then delete this node
        */

    //For all facets that use this node, replace reference to new node.
    var facets = this.facets
    var edges = this.edges

    //Copy facets into separate list so we don't modify the facets array that we are looping over!
    //Clone
    facets
      .map(function (f) {
        return f
      })
      .forEach(function (facet) {
        if (facet.vertices.indexOf(this) != -1) {
          facet.vertices[facet.vertices.indexOf(this)] = survivingNode

          //Already added in the correct position, but this also adds the facet to the node
          survivingNode.addToFacet(facet)
          this.removeFromFacet(facet)
        }
      }, this)

    //For all edges that use this node, replace reference to new node.

    //Copy edges into separate list so we don't modify the edges array that we are looping over!
    //Clone
    edges
      .map(function (e) {
        return e
      })
      .forEach(function (edge) {
        if (edge.hasNode(survivingNode) && edge.hasNode(this)) {
          // This edge is between deletedNode and survivingNode and should simply be deleted
          this.removeEdge(edge)
          survivingNode.removeEdge(edge)

          // Ensure nodes are not auto-deleted
          edge.nodes = []

          editor.execute(new RemoveObjectCommand(edge, false))
        } else if (edge.nodes.indexOf(this) != -1) {
          edge.nodes[edge.nodes.indexOf(this)] = survivingNode

          if (!survivingNode) {
            console.log('missing survivingNode')
          } else {
            //Already added in the correct position, but this also adds the edge to the node
            survivingNode.addToEdge(edge)
            this.removeEdge(edge)
          }
        } else {
          console.log('wtf?', edge)
        }
      }, this)

  },
  cleanDuplicateEdges: function () {

    // check for any duplicate edges, keep one of the duplicates and remove the others
    var duplicateEdgesToRemove = new Set()

    this.edges.forEach((edge) => {
      var duplicateEdges = this.edges.filter((edge2) => {
        return edge !== edge2 && edge2.hasNodes(edge.nodes)
      })
      if (duplicateEdges.length) {
        duplicateEdges.forEach((edge) => {
          duplicateEdgesToRemove.add(edge)
        })
      }
    })

    // retain one of each duplicate edge sets
    var duplicateEdgesToRetain = []
    duplicateEdgesToRemove.forEach((edge) => {
      // only add if no retained edges have the same nodes
      if (!duplicateEdgesToRetain.find(e => e.hasNodes(edge.nodes))) {
        duplicateEdgesToRetain.push(edge)
      }
    })

    Array.from(duplicateEdgesToRemove).filter(e => !duplicateEdgesToRetain.includes(e)).forEach(edgeToRemove => {
      editor.execute(new RemoveObjectCommand(edgeToRemove, false))
    })

  },

  deleteAndKeepEdges: function () {
    /**
     * Remove this node from all its edges and join the other edges together.
     * This is only useful for cleaning up an isolated point on an otherwise-straight edge.
     * 
     * for each edge
     * find the distal point
     * create new edge between each pair of distal points
     * remove the node and the old edges
     * 
     * Note: Currently some cases are not handled perfectly due to the way the edges are split
     * e.g. Consider a central point that joints to 3 other edges like a wheel with spokes.
     * Arguably the result could be a triangle with all outer points joined, but depending on
     * the order that edges are processed, the result could be a triangle with one edge missing
     * and another edge added twice, and hopefully cleaned up.
     */

    // Take a copy before iterating over edges to avoid modifying the array while iterating
    var edgesOriginal = this.edges.slice(0)

    edgesOriginal.forEach((edge) => {
      const otherNode = edge.getOtherNode(this)
      this.fuseAndDispose(otherNode)
    })
    editor.execute(new RemoveObjectCommand(this, false))
  },

  onDragEnd: function (allowInteractingWithExistingNodesAndEdges) {
    if (allowInteractingWithExistingNodesAndEdges === false) {
      return
    }

    // We assume that snapping would have already been applied
    // Not required: `ObjectBehaviors.snap.call(this, editor)`

    // Snap only updates the position but now will will merge the node(s) if the position is exactly equal to another node.
    // We will require an extremely high precision check of position to ensure we don't accidentally merge nodes.
    // var nodesToMerge = editor.filter('type', 'OsNode').filter(function (node) {
    //   return node != this && !node.ghostMode() && this.position.distanceTo(node.position) < 0.0001
    // }, this)
    var nodesToMerge = this.getNearbyNodes(0.0001)

    if (nodesToMerge.length) {
      // select the newly merged node if we had previously selected this node
      var mergedNode = this.mergeWithNearbyNodes(editor, nodesToMerge, editor.selected === this)
      if (mergedNode) {
        mergedNode.onChange(editor)
      }
      return
    }

    // Now check if we should intersect an existing line.
    // We may decide this is a bad idea because it prevents us from dragging nodes near to another edge
    // but for now we will enable and require the distance to be extremely close
    var edgeToSplit = editor.filter('type', 'OsEdge').find(function (edge) {
      return (
        !edge.ghostMode() &&
        edge.nodes[0] !== this &&
        edge.nodes[1] !== this &&
        edge.distanceTo(this.position) < 0.0001 &&
        //Omit any edges that belong to a locked facet
        !edge.getFacets().some((f) => f.isLocked)
      )
    }, this)
    if (edgeToSplit) {
      this.insertIntoEdge(edgeToSplit)
    }
  },

  insertIntoEdge: function (edgeToSplit, addWireForSystem, commandUUID) {
    // @TODO: Do we need to somehow handle wires/addWireForSystem?
    //   nodeClicked = new OsNode({
    //     position: clickedNodeAndPosition.position,
    //   })
    //   editor.execute(new AddObjectCommand(nodeClicked, addWireForSystem, true, commandUUID))

    //   var edgeToSplit = clickedNodeAndPosition.object
    if (!addWireForSystem) {
      edgeToSplit.getFacets().forEach(function (f) {
        var forceLast = false
        var allowEdgeAutoCreation = false
        editor.execute(new AddNodeToFacetCommand(this, f, forceLast, allowEdgeAutoCreation, commandUUID))
      }, this)
    }

    //Prevent auto-creating facets until all edges have been updated
    //otherwise intermediate steps could create invalid facets
    edgeToSplit.splitWithNode(this, commandUUID, addWireForSystem)
    //set argument isSplitEdge==true to indicate we didn't create this new facet, it is simply splitting another
    //@TODO: Not perfect. In some cases, splitting an edge can ALSO create a new facet (in addition to updating an existing facet)
    if (!addWireForSystem) {
      OsEdge.addMissingFacetsForEdgeNetwork(editor, Boolean(edgeToSplit), commandUUID)
    }
  },

  onChange: function (editor, permitSnappingOverride) {
    if (
      editor.controllers.CallbackStack &&
      editor.controllers.CallbackStack.active &&
      editor.controllers.CallbackStack.recordIfNewOrReturnFalse('OsNode.onChange.' + this.uuid) === false
    ) {
      return
    }

    /* Snap to parallel/perpendicular lines */
    if (permitSnappingOverride !== false && editor.selected == this && editor.snappingActive) {
      ObjectBehaviors.snap.call(this, editor)
    }

    if (this.hasChanged()) {
      this.refreshForCamera()

      // Beware, could this cause blowouts in combination with replanifying above??? Perhaps it's ok?
      // Disabled to prevent infinite loops. We now only propagate Z along flat edges in a single update to all flat nodes
      // in a chain, but not through onChange which causes can cause infinite loops and performance problems.
      // Floating-nodes previously relied on this, but these will no longer be supported in future due to excessive complexity.
      // Only propagate if this node is not floating on a facet which causes the touble/complexity.
      if (this.facets.length === 0) {
        this.propagateZAlongFlatEdges()
      }

      // If simple case where
      // a) no other facets are joined to this facet
      // b) one gutter edge only
      // c) no other edges assigned
      // d) no floating nodes
      // e) This node does not belong to a gutter
      //
      // Then
      //    Refresh facet only using gutter points and this point
      //    Refloat other points belonging to this facet directly
      var updatedNode = this
      var nodesRefloated = []
      var facetsAlreadyRebuilt = []

      // if (!this.hasEdgeType('gutter')) {
      // Update simple facets to enforce planarity

      var edgesInheritingNodeElevation = this.edges.filter(function (edge) {
        return edge.isFlat()
      })

      // This may contain duplicates of some nodes and that's ok
      var nodesInheritingNodeElevation = [].concat.apply(
        [],
        edgesInheritingNodeElevation.map(function (e) {
          return e.nodes
        })
      )

      this.facets
        .filter(function (f) {
          // Ignore any flat edge that this node belongs to

          var edgeTypesBeingMoved = edgesInheritingNodeElevation.map(function (edge) {
            return edge.edgeType
          })
          var edgesOnThisFacetWithSameTypesAsEdgeBeingMoved = f.getEdges().filter(function (edge) {
            return edgeTypesBeingMoved.indexOf(edge.edgeType) !== -1
          })
          var edgesToIgnore = edgesInheritingNodeElevation.concat(edgesOnThisFacetWithSameTypesAsEdgeBeingMoved)

          return f.isSimpleFacet(edgesToIgnore)
        }, this)
        .forEach(function (_facet) {
          var anchorEdge = _facet
            .getEdges()
            .filter(function (e) {
              return e.isFlat()
            })
            .filter(function (e) {
              return edgesInheritingNodeElevation.indexOf(e) === -1
            })[0]

          var pointsForPlane = _facet.vertices.filter(function (v) {
            return anchorEdge.hasNode(v) || v === updatedNode
          })

          var plane = OsFacet.planeFromPoints(
            pointsForPlane
              .filter(function (n) {
                return !n.floatingOnFacet
              })
              .map(function (n) {
                return n.position.toArray()
              })
          )

          // Float all nodes which are not used for creating the plane
          var nodesToFloat = _facet.vertices.filter(function (v) {
            return v.floatingOnFacet || pointsForPlane.indexOf(v) === -1
          })

          nodesToFloat.forEach(function (n) {
            var newFloatPosition = OsFacet.calculateFloatingPositionOnPlane(n.position.x, n.position.y, null, plane)

            n.position.z = newFloatPosition.z

            // Beware: n.onChange(editor) would create infinite loop
            n.refreshEdges()
            n.refreshUserData()
            n.saveHash()
          })

          nodesRefloated = nodesRefloated.concat(nodesToFloat)

          facetsAlreadyRebuilt.push(_facet)
        })
      // }

      this.refreshFacets()

      this.refreshEdges()

      this.refreshUserData()

      this.saveHash()

      // Refresh facets which are affected by nodes which were refloated BUT
      // which were not directly updated already
      // @TODO: Skip any facets already rebuilt above
      var facetsToRebuild = []
      nodesRefloated.forEach(function (n) {
        n.facets.forEach(function (f) {
          if (facetsToRebuild.indexOf(f) === -1 && facetsAlreadyRebuilt.indexOf(f) === -1) {
            facetsToRebuild.push(f)
          }
        })
      })

      var force = true
      facetsToRebuild.forEach(function (f) {
        f.onChange(editor, force)
      })
    }

    this.refreshMergableNodes()
  },

  refreshMergableNodes: function () {
    var hasMergableNodes = false

    editor.filter('type', 'OsNode').filter(survivingNode => this != survivingNode && !survivingNode.ghostMode(), this).forEach(function (survivingNode) {
      if (this.position.distanceTo(survivingNode.position) < this.nodeFuseDistance) {
        hasMergableNodes = true
      }
    }, this)

    this.hasMergableNodes = hasMergableNodes
  },

  getNearbyNodes: function (distance, nodes) {
    return OsNode.getNearbyNodes(this.position, distance, nodes, [this])
  },

  mergeWithNearbyNodes: function (editor, nodes, doSelect) {
    //@TODO: Fix hack for requiring editor reference
    if (!editor) {
      editor = window.editor
    }

    if (typeof nodes === 'undefined') {
      nodes = editor.filter('type', 'OsNode')
    }

    var newSelection = null

    //This node will be consumed, survivingNode will be the closest node to this node
    var survivingNode = this.getNearbyNodes(this.nodeFuseDistance, nodes)[0]

    if (!survivingNode) {
      console.warn('No survivingNode, node was probably already deleted/merged. Ignore')
      return
    }

    var nodesToMerge = this.getNearbyNodes(this.nodeFuseDistance, nodes).slice(1).concat([this])

    editor.execute(new NodeMergeCommand(survivingNode, nodesToMerge))

    if (doSelect !== false) {
      editor.select(survivingNode)
    }

    survivingNode.cleanDuplicateEdges()

    return survivingNode
  },

  mergeWithNodes: function (nodes) {
    editor.execute(new NodeMergeCommand(this, nodes))
  },

  sliceNearbyEdge: function () {
    var intersections = ObjectBehaviors.snap.call(this, editor, { returnAllSnapPoints: true, includeUnlinkedEdges: true })['intersection']

    var allLinkedEdges = []
    var allLinkedNodes = []
    this.edges.forEach(_edge => {
      OsEdge.getAllLinkedEdges(_edge).forEach(linkedEdge => {
        if (!allLinkedEdges.includes(linkedEdge)) {
          // allLinkedEdges.push(linkedEdge)

          if (!allLinkedNodes.includes(linkedEdge.nodes[0])) {
            allLinkedNodes.push(linkedEdge.nodes[0])
          }
          if (!allLinkedNodes.includes(linkedEdge.nodes[1])) {
            allLinkedNodes.push(linkedEdge.nodes[1])
          }

        }
      })
    })

    var commandUUID = undefined
    var allUnlinkedNodes = editor.filter('type', 'OsNode').filter((node) => !allLinkedNodes.includes(node))

    var edgesWhichHaveBeenSplit = []
    var newNodes = []

    for (var i = 0; i < intersections.length; i++) {
      var intersection = intersections[i]

      var edge = intersection.objects.edge
      if (edge && !allLinkedEdges.includes(edge) && !edgesWhichHaveBeenSplit.includes(edge)) {

        // if node can merge to existing node then do that, otherwise bisect the edge with a new node
        var nodesForIntersection = OsNode.getNearbyNodes(intersection, 0.5, allUnlinkedNodes, [])

        var newNode

        if (nodesForIntersection.length === 0) {
          newNode = new OsNode({ position: intersection })
          editor.execute(new AddObjectCommand(newNode, undefined, true, commandUUID))
          newNode.insertIntoEdge(edge, undefined, commandUUID)
        } else {
          newNode = nodesForIntersection[0]
        }
        newNodes.push(newNode)

        edgesWhichHaveBeenSplit.push(edge)

      }
    }

    // now that the first node has been processed, snap all the other nearby notes onto the new node

    // this node will snap onto the other node, but if multiple nodes are created in this process then
    // we arbitrarily chose the first node for this and the other new nodes to snap to.
    newNodes[0].mergeWithNodes(newNodes.slice(1).concat([this]))

  },

  onSelect: function () {
    this.refreshForCamera()
    this.refreshMergableNodes()
  },

  onDeselect: function () {
    this.refreshForCamera()
  },

  propagateZAlongFlatEdges: function () {
    /**
     * Avoid the recursive onChange chaos of the previous implementation. Determine all nodes to update then updated them together
     * then call onChange once for each. Ensure cycles are handled to avoid infinite an loop.
     */
    var nodesToUpdate = this.getNodesAlongFlatEdgeChain()
    nodesToUpdate.forEach(nodeToUpdate => {
      if (nodeToUpdate.position.z != this.position.z) {
        nodeToUpdate.position.z = this.position.z
        nodeToUpdate.onChange(editor)
      }
    }, this)

  },

  getNodesAlongFlatEdgeChain: function (nodes = []) {

    var newNodes = []

    this.edges.forEach(function (edge) {
      if (SetbacksHelper.edgeTypeIsFlat(edge.edgeType)) {
        var distalNode = edge.nodes[0] != this ? edge.nodes[0] : edge.nodes[1]

        // Never propagate flat edge elevation from floating node to a distal node which is on the same facet
        // This means we can still propagate z along a dormer ridge
        if (nodes.includes(distalNode)) {
          // node already in list
        } else if (Boolean(this.floatingOnFacet) && distalNode.facets.indexOf(this.floatingOnFacet) !== -1) {
          // skip z propatation
        } else if (distalNode.floatingOnFacet) {
          //distal node is floating on facet which takes priority
          //over gutter/ridge settings... no update
        } else {
          newNodes.push(distalNode)
        }
      }
    }, this)

    nodes = nodes.concat(newNodes)

    newNodes.forEach(function (newNode) {
      newNodesFromRecursion = newNode.getNodesAlongFlatEdgeChain(nodes)

      newNodesFromRecursion.forEach(newNodeFromRecursion => {
        if (!nodes.includes(newNodeFromRecursion)) {
          nodes.push(newNodeFromRecursion)
        }
      })
    })

    return nodes

  },
  refreshFacets: function () {
    if (
      editor.controllers.CallbackStack &&
      editor.controllers.CallbackStack.active &&
      editor.controllers.CallbackStack.recordIfNewOrReturnFalse('OsNode.refreshFacets.' + this.uuid) === false
    ) {
      return
    }

    this.facets.forEach(function (facet) {
      facet.onChange(editor)
    })
  },

  refreshEdges: function (_editor) {
    if (typeof _editor === 'undefined') {
      _editor = editor
    }

    if (
      _editor.controllers.CallbackStack &&
      _editor.controllers.CallbackStack.active &&
      _editor.controllers.CallbackStack.recordIfNewOrReturnFalse('OsNode.refreshEdges.' + this.uuid) === false
    ) {
      return
    }

    this.edges.forEach(function (edge) {
      edge.onChange(_editor)

      // Do not send signal for ghost edges
      if (edge.ghostMode() !== true) {
        _editor.signals.objectChanged.dispatch(edge)
      }
    })
  },

  refreshForCamera: function (position, metersPerPixel) {
    if (typeof position === 'undefined') {
      position = editor.camera.position
    }

    if (typeof metersPerPixel === 'undefined') {
      metersPerPixel = editor.metersPerPixel()
    }

    this.cameraPosition.copy(position)

    //set scale in order to achieve target size in screen pixels
    //@todo: Why do we need to multiply by 100?
    var scale = this.sizeScreenPixels * this.sizeGeometry * metersPerPixel

    // Increase handle size slightly when selected
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    if (isSelected) {
      scale = scale * SCALE_FACTOR_WHEN_SELECTED
    }

    this.scale.copy(new THREE.Vector3(scale, scale, scale))

    this.refreshHandle()
  },

  asObject: function () {
    return {
      position: this.position.toArray(),
      uuid: this.uuid,
      floatingOnFacet: this.floatingOnFacet ? this.floatingOnFacet.uuid : null,
      facets: this.facets.map(function (f) {
        return [
          f.uuid,
          f.vertices.map(function (v) {
            return v.position.toArray()
          }),
        ]
      }),
      edges: this.edges.map(function (e) {
        return e.uuid
      }),
    }
  },

  refreshUserData: function () {
    this.userData.facets = this.facets.map(function (o) {
      return o.uuid
    })
    this.userData.shadeAnnotation = this.shadeAnnotation
  },
  applyUserData: function () {
    this.shadeAnnotation = this.userData.shadeAnnotation
  },

  applyGhostMode: function (value) {
    // if(value){
    //     this.material = OsNodeCache.materialGhostMode;
    // }else{
    //     this.material = OsNodeCache.material;
    // }
  },
  handleGhostModeBehavior: ObjectBehaviors.handleGhostModeBehavior,
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,

  matchesCoordinates: function (x, y, z) {
    if (typeof z === 'undefined') {
      return this.position.x == x && this.position.y == y
    } else {
      return this.position.x == x && this.position.y == y && this.position.z == z
    }
  },

  getContextMenuItems: function (position, _editor) {
    var _this = this
    if (!_editor) {
      _editor = editor
    }

    // Ignore ghost nodes because these are not selectable
    if (this.ghostMode()) {
      return []
    }

    return [
      {
        label: window.translate('Select Node'),
        useHTML: false,
        selected: false,
        onClick: function () {
          if (_editor) {
            _editor.select(_this)
          }
        },
      },
    ]
  },
  floatOnTerrain: function () {
    this.position.copy(SceneHelper.pointOnObj(this.position))
    editor.signals.objectChanged.dispatch(this)
  },
  getShadeAnnotations: function () {
    var annotations = {}
    this.edges.forEach((edge) => {
      if (edge.nodes[1].shadeAnnotation) {
        var shadeAnnotation = edge.nodes[1].shadeAnnotation
        if (!shadeAnnotation) {
          return
        }
        var [annotationType, annotationIdentifier] = shadeAnnotation.split('_')
        if (!annotations[annotationIdentifier]) {
          annotations[annotationIdentifier] = {}
        }
        annotations[annotationIdentifier].identifier = annotationIdentifier
        annotations[annotationIdentifier].source = this.position.toArray()
        annotations[annotationIdentifier][annotationType] = edge.nodes[1].position.toArray()
      }
    })

    // Populare error values
    Object.values(annotations).forEach((annotation) => {
      if (annotation.benchmark && annotation.actual) {
        // calculate error as 1 - dot product of actual vs benchmark rays
        var actual = new THREE.Vector3()
          .subVectors(
            new THREE.Vector3().fromArray(annotation.actual),
            new THREE.Vector3().fromArray(annotation.source)
          )
          .normalize()
        var benchmark = new THREE.Vector3()
          .subVectors(
            new THREE.Vector3().fromArray(annotation.benchmark),
            new THREE.Vector3().fromArray(annotation.source)
          )
          .normalize()

        annotation.error = benchmark.dot(actual)
      }
    })

    return Object.values(annotations).filter((annotation) => annotation.benchmark && annotation.actual)
  },
  getEdgePairsByBearingExcludingReflexAngles: function () {
    try {
      // Ensure bearing is calculated with this nodes as the starting point
      // Sort by bearing
      var _this = this
      var edgesWithBearings = this.edges
        .map((e) => [e, e.bearing(undefined, _this)])
        .sort((a, b) => (a[1] > b[1] ? 1 : -1))

      // Return pairs where gap with next clockwise edge is less than 180 degrees
      return edgesWithBearings
        .map((edgeWithBearing, index) => {
          var nextEdgeWithBearing = edgesWithBearings[(index + 1) % edgesWithBearings.length]
          // ignore this pair if the angle between them is greater than 180
          if ((nextEdgeWithBearing[1] + 360 - edgeWithBearing[1]) % 360 < 180) {
            return [edgeWithBearing[0], nextEdgeWithBearing[0]]
          }
        })
        .filter(Boolean) // Strip any groups which failed the reflex angle test
    } catch (err) {
      console.warn(err)
      return []
    }
  },

  setVisible: function (visible) {
    if (this.visible === visible) return
    this.visible = visible
  },

  getVisible: function () {
    return this.visible
  },
})

OsNode.setGuidesVisibility = function (visibility) {
  if (OsNodeCache) {
    OsNodeCache.verticalGuideMaterial.visible = visibility
  }
}

//If z is undefined then only x and y will be searched
OsNode.nodeForCoordinates = function (nodes, x, y, z) {
  for (var i = 0; i < nodes.length; i++) {
    if (nodes[i].matchesCoordinates(x, y, z)) {
      return nodes[i]
    }
  }
  return null
}

OsNode.closestToPosition = function (nodes, position) {
  var closestIndex = 0
  var closestDistance = 100000

  _.range(nodes.length).forEach(function (index) {
    var distanceToEdge = nodes[index].position.distanceTo(position)

    if (distanceToEdge < closestDistance) {
      closestDistance = distanceToEdge
      closestIndex = index
    }
  })

  return [nodes[closestIndex], closestDistance]
}

OsNode.nodesForPolygon = function (polygon, nodesAll) {
  var nodes = []

  //all except last (wrapped) coordinate
  polygon.geometry.coordinates[0].slice(0, -1).forEach(function (coordinate) {
    var node = OsNode.nodeForCoordinates(nodesAll, coordinate[0], coordinate[1])
    if (node) {
      nodes.push(node)
    } else {
      console.log("OsNode not found for polygon coordinate! Perhaps it's in the process of being added?")
      return null
    }
  })
  return nodes
}

function OsAngle(options) {
  THREE.Mesh.call(this)
  this.type = 'OsAngle'
  this.userData.excludeFromExport = true

  this.edge0 = options.edge0
  this.edge1 = options.edge1

  this.angleLineMesh = null
  try {
    this.refresh()
  } catch (e) {
    console.warn('error refreshing OsAngle', e)
  }
}

OsAngle.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsAngle,
  onChange: function () {
    refresh()
  },
  refresh: function () {
    this.angleLineVertices = this.calculateAngleLineVertices()
    this.drawAngleLine(this.angleLineVertices)

    // calculate angle in 2D
    var force2D = true
    var { angle } = OsEdge.angleBetweenLines(
      this.edge0.asLine3(),
      this.edge1.asLine3(),
      1, //distance meters,
      force2D
    )
    this.angle = angle
  },
  calculateAngleLineVertices: function () {
    // Calculate whether it's a right angle using 2D method, but calculate positions using 3D angle measurement

    // @TODO: Add pairs of adjacent lines by moving around the compass to fine the next line
    var { angle, position, directionLine0, directionLine1 } = OsEdge.angleBetweenLines(
      this.edge0.asLine3(),
      this.edge1.asLine3(),
      1 //distance meters
    )

    var angle2D = OsEdge.angleBetweenLines(
      this.edge0.asLine3(),
      this.edge1.asLine3(),
      1, //distance meters
      true
    )['angle']

    // assumes length of radius is 1m (this is purely coincidence right now)
    var angleLineVertices
    var radiusScreenPixels = 4
    if (angle2D > 89.9 && angle2D < 90.1) {
      angleLineVertices = [0, 0.5, 1.0].map((alpha, index) =>
        new THREE.Vector3()
          .lerpVectors(directionLine0, directionLine1, alpha)
          .normalize()
          .multiplyScalar(radiusScreenPixels * (index === 1 ? 1.4142135623730951 : 1))
      )
    } else {
      var numberOfVertices = 17
      angleLineVertices = Utils.getRange(numberOfVertices)
        .map((i) => i / (numberOfVertices - 1))
        .map((alpha) =>
          new THREE.Vector3()
            .lerpVectors(directionLine0, directionLine1, alpha)
            .normalize()
            .multiplyScalar(radiusScreenPixels)
        )
    }
    var bisectorDirection = new THREE.Vector3().lerpVectors(directionLine0, directionLine1, 0.5).normalize()

    var annotationDistanceScreenPixels = 60
    var annotationDistanceMeters = annotationDistanceScreenPixels * editor.metersPerPixel()
    this.annotationPositionLocal = bisectorDirection.multiplyScalar(annotationDistanceMeters)

    return angleLineVertices
  },

  drawAngleLine: function (angleLineVertices) {
    if (this.angleLineMesh) {
      this.remove(this.angleLineMesh)
    }
    var meshLine = new MeshLine()
    var geometry = new THREE.Geometry()
    geometry.vertices = angleLineVertices
    geometry.excludeFromExport = true
    meshLine.setGeometry(geometry)
    var material = OsNodeCache.angleLineMaterial
    var angleLineMesh = new THREE.Mesh(meshLine, material)
    this.add(angleLineMesh)
    this.angleLineMesh = angleLineMesh
  },
  getAnnotation: function () {
    try {
      if (this.parent && !isNaN(this.angle)) {
        return {
          position: new THREE.Vector3().addVectors(this.parent.position, this.annotationPositionLocal),
          content: Math.round(this.angle) + '°',
        }
      }
    } catch (e) {
      // silently omit annotations when angle is invalid
      console.warn(e)
    }
  },
})

OsNode.debugPointGroupsAsNodes = function (pointGroups) {
  pointGroups.forEach((pointGroup) => {
    var rc = ((Math.random() * 0xffffff) << 0).toString(16).padStart(6, '0')
    pointGroup.forEach((p) => {
      var n = new OsNode({ colorHex: rc, position: new THREE.Vector3().fromArray([p[0], p[1], p[2] - 350]) })
      editor.addObject(n)
    })
  })
}

OsNode.getNearbyNodes = function (position, distance, nodes, excludeNodes) {
  if (!nodes) {
    nodes = editor.filter('type', 'OsNode')
  }

  return nodes.filter(function (node) {
    return (
      (!excludeNodes || !excludeNodes.includes(node)) &&
      !node.ghostMode() &&
      position.distanceTo(node.position) < distance &&
      //Omit any nodes that belong to a locked facet
      !node.isManagedByParent()
    )
  }, this)
}

OsNode.getIntersectsIncludingInvisibleNodes = (nodesSelectable, viewport, screenFraction, precision) => {
  var nodesNotVisible = nodesSelectable.filter((n) => !n.visible)
  nodesNotVisible.forEach(function (node) {
    node.visible = true
  })

  var intersectNodes
  try {
    intersectNodes = viewport.getIntersects(screenFraction, nodesSelectable, INTERSECTION_LINE_PRECISION)
  } catch (e) {
    console.error('Catch error in OsNode.getIntersectsIncludingInvisibleNodes to ensure node visibility cleanup', e)
    intersectNodes = []
  }

  nodesNotVisible.forEach(function (node) {
    node.visible = false
  })

  return intersectNodes
}
