/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newSlopes THREE.Euler
 * @param optionalOldSlopes THREE.Euler
 * @constructor
 */

var SetSlopesCommand = function (object, newSlopes, oldSlopes = null, commandUUID = null) {
  Command.call(this)
  this.timeStamp = Date.now()
  this.type = 'SetSlopesCommand'
  this.name = 'Set Slopes'
  this.updatable = true

  this.PROPERTIES = ['newSlopes', 'oldSlopes']
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.object = object
    this.newSlopes = newSlopes.slice()
    this.oldSlopes = oldSlopes
    this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()
  }
}

SetSlopesCommand.prototype = {
  execute: function () {
    if (!this.oldSlopes) {
      this.oldSlopes = this.object.slopes.slice()
    }

    this.object.slopes = this.newSlopes
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  undo: function () {
    this.object.slopes = this.oldSlopes.slice()
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  update: function (command) {
    this.newSlopes = command.newSlopes.slice()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
