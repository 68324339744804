/**
 * @author adampryor
 */

const HEIGHT_PER_STOREY = 4

var getUniqueItems = function (items) {
  return items.filter(function (v, i) {
    return i === items.lastIndexOf(v)
  })
}

var getOrCreateEdge = function (nodesForPointsAsReference, edgesWithPointsAsReference, edgesContainer, point1, point2, edgeType) {
  var existingEdgeRef = edgesWithPointsAsReference.filter(
    (ref) => ref.indexOf(point1) !== -1 && ref.indexOf(point2) !== -1
  )[0]

  if (existingEdgeRef) {
    return { edge: existingEdgeRef[0], created: false }
  } else {
    var edge = new OsEdge({
      nodes: [nodesForPointsAsReference.get(point1), nodesForPointsAsReference.get(point2)],
      visible: true,
      selectable: false,
      edgeType: edgeType,
    })
    edge.ghostMode(true)
    edge.userData.excludeFromExport = true
    edge.clickable = false

    edgesWithPointsAsReference.push([edge, point1, point2])

    edgesContainer.push(edge)

    return { edge, created: true }
  }
}

// Populate lazily
var OsStructureGeometryCache = {
  structure: null,
  dormer: null,
}

const MODE_TO_STRUCTURE_NAME = {
  aframe: 'A-Frame Roof',
  hip: 'Hip Roof',
  shed: 'Shed Roof',
  aframe_dormer: 'A-Frame Dormer',
  hip_dormer: 'Hip Dormer',
  shed_dormer: 'Shed Dormer',
  custom: 'Custom Roof',
  custom_dormer: 'Custom Dormer',
  null: 'Unspecified Structure Type',
}

// Point indexes
// Points 4 and 5 only apply to aframe/hip dormer, not used for shed dormers
// Aframe and Hip dormers both have the same number of points, the key difference is that
// the Y position of end-points are identical for aframe dormers but different for hip dormers.
//
// 1-----2
// | \ / |
// |  5  |
// |  |  |
// 0--4--3
const NODE_INDEXES_BY_STRUCTURE = {
  origin: 4,
  ridge_end: 5,
  left_edge_start: 0,
  left_edge_end: 1,
  right_edge_start: 3,
  right_edge_end: 2,
}

function OsStructure(options) {
  THREE.Mesh.call(this)

  var isFromJSON = Boolean(options.fromJSON)

  if (isFromJSON && options.userData) {
    var userData = options.userData
    // start with all keys/values from options.userData so none are overlooked
    // e.g. previously we overlooked options.userData.facetUuids
    options = {
      ...userData,
      // mode: userData.mode,
      // slopes: userData.slopes,
      // objectsFloatingOnFacets: userData.objectsFloatingOnFacets,
    }
    this.userData = userData
  }

  this.setMode(options && options.hasOwnProperty('mode') ? options.mode : 'hip')
  this.material = new THREE.MeshStandardMaterial({
    color: 0xff0000,
  })

  //@TODO: Consider changing this to Object3D instead of Mesh so we can avoid needing to create & render
  // redundant geometry & material
  this.material.visible = false

  this.castShadow = false

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsStructure'
  this.name = 'OsStructure'

  // Sometimes we are passing an instance of OsStructure as the value of "options".
  // Utils.castToArray ensures vectors get converted to arrays.
  this.position.fromArray(options && options.hasOwnProperty('position') ? Utils.castToArray(options.position) : [0, 0, 0])
  this.scale.fromArray(options && options.hasOwnProperty('scale') ? Utils.castToArray(options.scale) : [1, 1, 1])

  this._ghostMode = options && options.hasOwnProperty('ghostMode') ? options.ghostMode : false

  this.facets = []
  this.nodes = []
  this.edges = []

  if (options && options.hasOwnProperty('azimuth')) {
    this.setAzimuth(options.azimuth)
  }

  this.slopes = options && options.hasOwnProperty('slopes') ? options.slopes : [20, 20, 20, 20]

  this.stories = null
  this.setStories(options && options.hasOwnProperty('stories') ? options.stories : 1)

  this.lastPosition = this.position.clone()

  // this will be ignored if no parent it set
  this.rebuildSuccess = false
  this.rebuild()

  if (!isFromJSON) {
    // Do not refresh userData yet if loading from JSON because other related objects may not exist yet,
    // e.g. facets/edges/nodes
    this.refreshUserData()
  }

  editor.signals.objectChanged.add(this.onEdgeLengthChanged, this)

  this.MAX_HEIGHT = 100
  this.MAX_STORIES = 25

  // Workaround for when terrain may be set below the z=0 level
  this.MIN_STORIES = -5
}

OsStructure.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsStructure,
  updateLastPositionAndGetDelta: function (initialize) {
    if (initialize === true) {
      this.lastPosition.copy(this.position)
      return new THREE.Vector3()
    } else {
      var delta = new THREE.Vector3().subVectors(this.position, this.lastPosition)
      this.lastPosition.copy(this.position)
      return delta
    }
  },

  getName: function () {
    return MODE_TO_STRUCTURE_NAME[this.mode]
  },

  modeForConvertToCustom: function () {
    if (this.isDormer()) {
      return 'custom_dormer'
    } else {
      return 'custom'
    }
  },

  isDormer: function (value) {
    if (typeof value === 'undefined') {
      value = this.mode
    }
    return value && value.indexOf('dormer') !== -1
  },

  setMode: function (value) {
    if (this.isDormer(value)) {
      if (!OsStructureGeometryCache.dormer) {
        OsStructureGeometryCache.dormer = new THREE.PlaneGeometry(1, 1, 1, 1).translate(0, 0.5, 0)
      }
      this.geometry = OsStructureGeometryCache.dormer
      this.floatAppliesOrientation = true
      this.azimuthAuto = true
      this.slopeAuto = false
      this.elevationAuto = true
    } else {
      if (!OsStructureGeometryCache.structure) {
        OsStructureGeometryCache.structure = new THREE.PlaneGeometry(1, 1, 1, 1)
      }
      this.geometry = OsStructureGeometryCache.structure
      this.floatAppliesOrientation = false
      this.azimuthAuto = false
      this.slopeAuto = false
      this.elevationAuto = false
    }
    this.mode = value
  },

  setStories: function (value) {
    this.stories = value
    this.position.z = this.stories * HEIGHT_PER_STOREY
  },

  setSlopes: function (slopes) {
    this.slopes = slopes
    window.editor && ObjectBehaviors.floatingOnFacetOnChange.call(this, editor)
    this.rebuild()
  },

  ztoAzimuth: function (z) {
    return (-z * window.THREE.Math.RAD2DEG + 360) % 360
  },
  fromAzimuthToZ: function (azimuth) {
    return (-value * window.THREE.Math.DEG2RAD) % 360
  },

  setAzimuth: function (value) {
    this.rotation.z = (-value * window.THREE.Math.DEG2RAD) % 360
    this.updateMatrix()
    this.updateMatrixWorld()
  },

  getAzimuth: function () {
    // required for floatAppliesOrientation
    return (-this.rotation.z * window.THREE.Math.RAD2DEG + 360) % 360
  },

  getSlope: function () {
    // required for floatAppliesOrientation
    return 0
  },

  toolsActive: function () {
    return {
      translateXY: true,
      translateZ: this.isDormer() && Boolean(this.floatingOnFacet) ? false : true,
      translateX: false,
      scaleXY: true,
      scaleZ: false,
      rotate: this.isDormer() && Boolean(this.floatingOnFacet) ? false : true,
    }
  },
  transformWithLocalCoordinates: function () {
    return true
  },
  boundingBoxOverrride: function () {
    if (this.isDormer()) {
      return new THREE.Box3(new THREE.Vector3(-0.5, 0, 0), new THREE.Vector3(0.5, 1, 1))
    } else {
      return new THREE.Box3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(1, 1, 1))
    }
  },
  onChange: function (editor) {
    ObjectBehaviors.floatingOnFacetOnChange.call(this, editor)

    var delta = this.updateLastPositionAndGetDelta(editor.sceneIsLoading)

    this.rebuild(delta)
  },
  // generateObjects: function () {
  rebuild: function (delta) {
    /*
    Also apply movement to floating facets inside here so we do not need an extra render
    */

    editor.uiPauseUntilComplete(
      function () {
        editor.uiPauseUntilComplete(
          function () {
            try {
              this.rebuildRaw()
              this.rebuildSuccess = true
            } catch (e) {
              console.error('Error completing OsStructure.rebuild(): ', e)
              this.rebuildSuccess = false
            }

            // Apply the same delta to any module grids floating on any of its facets
            if (delta && delta.length() > 0) {
              var objectsToDrag = editor.filterObjects(
                (o) => o.type === 'OsModuleGrid' && o.facet && this.facets.includes(o.facet)
              )
              objectsToDrag.forEach((o) => o.position.add(delta))
            }
          },
          this,
          'ui',
          'uiPauseLock::OsStructure.rebuild()'
        )
      },
      this,
      'render',
      'renderPauseLock::OsStructure.rebuild()'
    )
  },

  lastRebuildSuccessful: function () {
    return this.rebuildSuccess
  },

  clearChildren: function () {
    var objectsToRemove = [...this.nodes, ...this.edges, ...this.facets]
    objectsToRemove.forEach((o) => {
      editor.removeObject(o)
    })
  },

  rebuildRawOld: function () {
    if (!this.parent || isNaN(this.position.x) || isNaN(this.scale.x)) {
      // abort if no parent because we require this.localToWorld(p)
      console.log('Unable to rebuild OsStructure until parent, scale and position is set')
      return
    }

    var objectsFloatingOnFacetsBefore = this.facets.map((f) => f.objectsFloating)

    // Point indexes
    // 1-----2
    // |     |
    // |     |
    // |     |
    // 0-----3
    //
    // Slope indexes
    //  --1--
    // |     |
    // 0     2
    // |     |
    //  --3--

    // @TODO: remove edges, facets, nodes, etc
    this.clearChildren()

    this.nodes = []
    this.edges = []
    this.facets = []

    // Calculate everything in local coordinates, no need to worry about world transforms

    // this.slopes = [45, 89, 89, 89]
    // this.slopes = [45, 45, 45, 45]

    var slopesAdjusted
    var slopesEnabled = [true, true, true, true]
    var pointsUnique = []
    var pointsReferences = [null, null, null, null, null, null, null, null]
    var isFlat = false
    var isDormer = this.isDormer()
    // We will set edgeTypes based on the slopes
    var planes = []

    if (this.slopes.filter((s) => s === 0).length === 4) {
      // fully-flat
      isFlat = true
      var nodePositionWorld
      pointsUnique.push(new THREE.Vector3(0, 0, 0))
      pointsUnique.push(new THREE.Vector3(0, 1, 0))
      pointsUnique.push(new THREE.Vector3(1, 1, 0))
      pointsUnique.push(new THREE.Vector3(1, 0, 0))
      for (var i = 0; i < 4; i++) {
        pointsReferences[i] = pointsUnique[i]
        pointsReferences[i + 4] = pointsUnique[i]
      }
      var planeForAll = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), pointsUnique[0])
      planes = [
        planeForAll, planeForAll, planeForAll, planeForAll
      ]
    } else {
      // @TODO: Change the vertical slope back to 89.9 once facets don't break as a result
      slopesAdjusted = this.slopes.map((s) => (s > 80.0 || s < 0.1 ? 89.9999 : s))

      slopesEnabled = slopesAdjusted.map((s, slopeIndex) => s < 89)

      // We generate objects in a standard 1x1 rectangle so we actually adjust the slope to be proportional
      var slopesScaled = [
        OsStructure.normalizeSlope(slopesAdjusted[0], this.scale.x),
        OsStructure.normalizeSlope(slopesAdjusted[1], this.scale.y),
        OsStructure.normalizeSlope(slopesAdjusted[2], this.scale.x),
        OsStructure.normalizeSlope(slopesAdjusted[3], this.scale.y),
      ]

      var normals = [
        new THREE.Vector3(Math.tan(-slopesScaled[0] * THREE.Math.DEG2RAD), 0, 1).normalize(),
        new THREE.Vector3(0, Math.tan(slopesScaled[1] * THREE.Math.DEG2RAD), 1).normalize(),
        new THREE.Vector3(Math.tan(slopesScaled[2] * THREE.Math.DEG2RAD), 0, 1).normalize(),
        new THREE.Vector3(0, Math.tan(-slopesScaled[3] * THREE.Math.DEG2RAD), 1).normalize(),
      ]

      var points = [
        new THREE.Vector3(0, 0, 0),
        new THREE.Vector3(0, 1, 0),
        new THREE.Vector3(1, 1, 0),
        new THREE.Vector3(1, 0, 0),
      ]

      // prepare roof planes to intersect
      planes = [
        new THREE.Plane().setFromNormalAndCoplanarPoint(normals[0], points[0]),
        new THREE.Plane().setFromNormalAndCoplanarPoint(normals[1], points[1]),
        new THREE.Plane().setFromNormalAndCoplanarPoint(normals[2], points[2]),
        new THREE.Plane().setFromNormalAndCoplanarPoint(normals[3], points[3]),
      ]

      // returns a line which may be initially facing up or down
      var rayDirections = [
        planes[0].intersectPlane(planes[3]).delta(new THREE.Vector3()),
        planes[0].intersectPlane(planes[1]).delta(new THREE.Vector3()),
        planes[1].intersectPlane(planes[2]).delta(new THREE.Vector3()),
        planes[3].intersectPlane(planes[2]).delta(new THREE.Vector3()),
      ]

      // point all rays up
      rayDirections.forEach((rd) => {
        if (rd.z < 0) {
          rd.negate()
        }
        // originally 10 units long
        // changed to 100 units to ensure long enough to intersect with a very steep plane
        // link to ticket: https://github.com/open-solar/opensolar-todo/issues/5883
        rd.normalize().multiplyScalar(100)
      })

      var cornerLines = [0, 1, 2, 3].map((i) => new THREE.Line3(points[i], points[i].clone().add(rayDirections[i])))

      var closestValidPoint = function (startPoint, point1, point2) {
        var d1 = point1 ? startPoint.clone().sub(point1).length() : null
        var d2 = point2 ? startPoint.clone().sub(point2).length() : null
        if (!d1 && !d2) {
          return null
        } else if (d1 && !d2) {
          return point1
        } else if (!d1 && d2) {
          return point2
        } else if (d1 <= d2) {
          return point1
        } else if (d2 < d1) {
          return point2
        } else {
          throw new Error('Unexpected case')
        }
      }

      var closestIntersections = [
        closestValidPoint(
          points[0],
          planes[1].intersectLine(cornerLines[0], new THREE.Vector3()),
          planes[2].intersectLine(cornerLines[0], new THREE.Vector3())
        ),
        closestValidPoint(
          points[1],
          planes[2].intersectLine(cornerLines[1], new THREE.Vector3()),
          planes[3].intersectLine(cornerLines[1], new THREE.Vector3())
        ),
        closestValidPoint(
          points[2],
          planes[3].intersectLine(cornerLines[2], new THREE.Vector3()),
          planes[0].intersectLine(cornerLines[2], new THREE.Vector3())
        ),
        closestValidPoint(
          points[3],
          planes[0].intersectLine(cornerLines[3], new THREE.Vector3()),
          planes[1].intersectLine(cornerLines[3], new THREE.Vector3())
        ),
      ]

      // Build facets from remaining points

      // Array of unique points. If we merge points we will save a reference to the existing point instead of adding
      // a new point
      var pointsAll = points.concat(closestIntersections) // exactly 8 points
      // first four are the original points, second four are the rayPlane intersection points

      var distanceThreshold = 0.1

      var getNearbyPointsWithIndexes = function (targetPoint, _points) {
        return _points
          .map(function (p, pointIndex) {
            return { point: p, index: pointIndex, isNearby: targetPoint.distanceTo(p) < distanceThreshold }
          })
          .filter((pointWithIndex) => pointWithIndex.isNearby)
      }

      var counter = 0

      while (pointsReferences.some((pr) => pr === null)) {
        if (counter++ > 10) {
          break
        }

        // get next unprocessed point
        var nextPointIndex = pointsReferences.indexOf(null)

        var nearbyPointsWithIndexes = getNearbyPointsWithIndexes(pointsAll[nextPointIndex], pointsAll)

        if (nearbyPointsWithIndexes.length === 1) {
          pointsUnique.push(nearbyPointsWithIndexes[0].point)
          pointsReferences[nextPointIndex] = nearbyPointsWithIndexes[0].point
        } else {
          // create a new point from centroid of all nearby points then use that as the reference
          // for all these nearby points
          var newUniquePoint = Utils.getCentroid(nearbyPointsWithIndexes.map((pwi) => pwi.point))
          pointsUnique.push(newUniquePoint)

          nearbyPointsWithIndexes.forEach((pwi) => {
            pointsReferences[pwi.index] = newUniquePoint
          })
        }
      }

      // Create facets based on how many unique points are included in the facets maximum of 4 theoretical points

      // Check how many unique nodes are available for the facet.
      // If < 3 then no facet.
    }

    function RefMap() {
      var keys = [],
        values = []

      return {
        put: function (key, value) {
          var index = keys.indexOf(key)
          if (index == -1) {
            keys.push(key)
            values.push(value)
          } else {
            values[index] = value
          }
        },
        get: function (key) {
          return values[keys.indexOf(key)]
        },
        dump: function () {
          return {
            keys: keys,
            values: values,
          }
        },
      }
    }

    var nodesForPointsAsReference = new RefMap()
    var nodePositionWorld

    // Dormers move all point Z values down so the highest point is at local z=0
    // so apex perfectly aligns with roof
    if (isDormer) {
      var highestPointZ = Math.max.apply(
        null,
        pointsUnique.map((p) => p.z)
      )
      pointsUnique.forEach((p) => {
        p.z -= highestPointZ
      })

      // CURRENT STATUS: Almost working perfectly except at some scales dormer shape gets distorted.
      // This can be fixed by fixing the calculation of valleys and dormerTopCornerPoints. Alternatively
      // as a workaround, we we can't solve that then we could use replace Utils.findMidpointBetweenLines
      // which results in  midpoints drifting too wide if the dormer-edge and valley-lines do not intersect
      // perfectly.
      //
      // Shift out the top-outer point of the dormer to prevent the dormer from continuing under the roof facet.
      //
      // The plane-to-plane intersection line between the a) parent facet and b) dormer facet gives the
      // "valley", which is the sloped intersection line. But we need to determine how far along that valley to move.
      // How far along the valley? Find where the vertical plane along the dormer edge intersects the valley line.
      // To avoid the complexity of a plane-line intersection, we can simply find the closest point (or intersection)
      // between the valley line and the dormer edge which is theoretically an intersection point but we just use the
      // closest-point-between-lines which is robust and avoids floating point errors that a true solution might have.
      var parentFacet = this.floatingOnFacet

      var DORMER_LOCAL_ORIGIN = new THREE.Vector3(0.5, 0, 0)

      if (parentFacet) {
        var parentFacetPlaneLocalNormal = parentFacet.plane.normal
          .clone()
          .applyAxisAngle(new THREE.Vector3(0, 0, 1), -this.rotation.z)

        // @TODO: Fix this scaling factor to allow us to compare the dormer to the parent facet
        // since the dormer is scaled down to 1:1:1. rescale the parentFacetPlane to use the same units
        // but this needs some thought on how to do it correctly.
        // Current implementation is not quite correct.
        var parentFacetPlaneLocalNormalScaled = new THREE.Vector3(
          parentFacetPlaneLocalNormal.x * this.scale.x,
          parentFacetPlaneLocalNormal.y * this.scale.y,
          parentFacetPlaneLocalNormal.z * this.scale.z
        ).normalize()

        var parentFacetPlaneLocalScaled = new THREE.Plane().setFromNormalAndCoplanarPoint(
          parentFacetPlaneLocalNormalScaled,
          // This is not from the "origin" (ridge start) of the dormer, it is from the bottom-left of the dormer
          // where position = 0,0,0
          new THREE.Vector3(0, 0, 0)
        )

        var valleys = [
          planes[0].intersectPlane(parentFacetPlaneLocalScaled, new THREE.Vector3()),
          planes[2].intersectPlane(parentFacetPlaneLocalScaled, new THREE.Vector3()),
        ]

        // Ensure valleys starts from 0,0,0 but has same direction
        // Not strictly necessary but very helpful during debugging
        // delta from start [0.5,0,0]

        if (valleys[0].end.z > valleys[0].start.z) {
          valleys[0].start.negate()
          valleys[0].end.negate()
        }

        var offset = new THREE.Vector3().subVectors(valleys[0].start, DORMER_LOCAL_ORIGIN)
        valleys[0].start.sub(offset)
        valleys[0].end.sub(offset)

        if (valleys[1].end.z > valleys[1].start.z) {
          valleys[1].start.negate()
          valleys[1].end.negate()
        }

        var offset = new THREE.Vector3().subVectors(valleys[1].start, DORMER_LOCAL_ORIGIN)
        valleys[1].start.sub(offset)
        valleys[1].end.sub(offset)

        var dormerEdges = [
          new THREE.Line3(pointsUnique[0], pointsUnique[1]),
          new THREE.Line3(pointsUnique[3], pointsUnique[2]),
        ]

        // second point is wrong! Not sure why, maybe bad implementation of findMidpointBetweenLines?
        // in any case, we can copy the value from the first point and just flip it. Actually we should
        // probably do this anyway for speed anyway.
        var dormerTopCornerPoints = [
          Utils.findMidpointBetweenLines(valleys[0], dormerEdges[0]),
          Utils.findMidpointBetweenLines(valleys[1], dormerEdges[1]), // wrong, see below
        ]

        // if dormerTopCornerPoints extend beyond the length of the dormer
        // then scale their length back so they do not go past the end of the dormer
        var lengthY = dormerTopCornerPoints[0].y
        var lengthScaleFactor = lengthY < 1 ? 1 : 1 / lengthY
        var delta = dormerTopCornerPoints[0].clone().sub(DORMER_LOCAL_ORIGIN).multiplyScalar(lengthScaleFactor)
        var dormerTopCornerPointsScaled = [DORMER_LOCAL_ORIGIN.clone().add(delta), null]

        dormerTopCornerPointsScaled[1] = new THREE.Vector3(
          1 - dormerTopCornerPointsScaled[0].x,
          dormerTopCornerPointsScaled[0].y,
          dormerTopCornerPointsScaled[0].z
        )

        pointsUnique[0].copy(dormerTopCornerPointsScaled[0])
        pointsUnique[3].copy(dormerTopCornerPointsScaled[1])
      }
    }

    pointsUnique.forEach((p) => {
      var pointCentered
      if (isDormer) {
        // dormer
        pointCentered = p.clone().sub(new THREE.Vector3(0.5, 0, 0))
      } else {
        // structure
        pointCentered = p.clone().sub(new THREE.Vector3(0.5, 0.5, 0))
      }
      nodePositionWorld = this.localToWorld(pointCentered)
      var node = new OsNode({ position: nodePositionWorld })
      // node.ghostMode(true)
      node.userData.excludeFromExport = true
      this.nodes.push(node)

      // editor.addObject(node)
      // editor.execute(new AddObjectCommand(node, null, false, commandUUID))

      nodesForPointsAsReference.put(p, node)
    }, this)

    var edgesWithPointsAsReference = [] //format each item is [edge, point1, point2]

    var _this_edges = this.edges

    var indexes = [0, 1, 2, 3]
    var facetPoints

    indexes
      .filter(
        // skip disabled facets (because they are vertical)
        (i) => slopesEnabled[i]
      )
      .forEach((facetIndex, facetIterationIndex) => {
        if (isFlat) {
          facetPoints = [pointsReferences[0], pointsReferences[1], pointsReferences[2], pointsReferences[3]]
        } else {
          facetPoints = getUniqueItems([
            // points
            pointsReferences[facetIndex],
            pointsReferences[(facetIndex + 1) % 4],

            // corner plane intersections
            // tricky note: we reverse the order here to avoid tangling the order
            // e.g. For face 0 we use point 0, point 1 then jump to rayFromPoint 1 then rayFromPoint 0
            pointsReferences[4 + ((facetIndex + 1) % 4)], //wrap index 7 back to index 4
            pointsReferences[4 + facetIndex],
          ])
        }

        if (facetPoints.length >= 3) {
          //add edges

          var edge, edgeType
          for (var i = 0; i < facetPoints.length; i++) {

            if (isFlat) {
              edgeType = 'flat_gutter'
            } else {

              var edgeTypeForFacetIndexAndSlopesEnabled = function (facetIndex, slopesEnabled, facetSlopeIndex, isDormer) {

                // Special case for dormer valleys which join the roof
                if (isDormer) {
                  if (facetIndex == 0 && facetSlopeIndex == 3) {
                    // right edge of first dormer facet
                    return 'valley'
                  } else if (facetIndex == 2 && facetSlopeIndex == 1) {
                    // left edge of third dormer facet
                    return 'valley'
                  }
                }

                if (facetSlopeIndex == 0) {
                  return 'gutter'
                } else if (facetSlopeIndex == 1) {
                  // check left edge, if it's slope is not enabled then it's an aframe, so edge type is rake
                  // otherwise it's a hip
                  var facetIndexLeft = (facetIndex + 1) % 4
                  if (slopesEnabled[facetIndexLeft]) {
                    return 'hip'
                  } else {
                    return 'rake'
                  }
                } else if (facetSlopeIndex == 3) {
                  // check right edge, if it's slope is not enabled then it's an aframe, so edge type is rake
                  // otherwise it's a hip
                  var facetIndexRight = (facetIndex + 3) % 4
                  if (slopesEnabled[facetIndexRight]) {
                    return 'hip'
                  } else {
                    return 'rake'
                  }
                } else if (facetSlopeIndex == 2) {
                  if (facetPoints.length == 3) {
                    // triangular facet, this is guaranteed to be a hip
                    // pointIndex:2 is actually the ridge because there are fewer pointIndexes for triangular facets.
                    return 'hip'
                  } else {
                    return 'ridge'
                  }
                } else {
                  return null
                }
              }

              edgeType = edgeTypeForFacetIndexAndSlopesEnabled(facetIndex, slopesEnabled, i, isDormer)

            }

            var { edge, created } = getOrCreateEdge(
              nodesForPointsAsReference,
              edgesWithPointsAsReference,
              _this_edges,
              facetPoints[i],
              facetPoints[(i + 1) % facetPoints.length],
              edgeType
            )

            if (edge) {
              // Create with correct visibility for current mode
              // Perhaps it would be better to set visibility after rebuilding
              // but for now we set it here
              if (ViewHelper && ViewHelper.facetEdgesDisplayMode() === 'none') {
                edge.visible = false
              } else {
                edge.visible = true
              }

              // if (created) {
              editor.addObject(edge)
              // editor.execute(new AddObjectCommand(edge, editor.scene, false, commandUUID))
              // }
            } else {
              console.warn('No edge created by getOrCreateEdge')
            }
          }

          var facet
          if (this.facets[facetIndex]) {
            facet = this.facets[facetIndex]
            facet.vertices = facetPoints.map((fp) => nodesForPointsAsReference.get(fp))
          } else {
            facet = new OsFacet({
              nodes: facetPoints.map((fp) => nodesForPointsAsReference.get(fp)),
              isManaged: true,
              roofTypeId: window.editor?.scene?.roofTypeId() || null,
              wallTypeId: window.editor?.scene?.wallTypeId() || null,
            })
            facet.userData.excludeFromExport = true
            facet.selectionDelegate = (editor) => {
              if (editor.selected === this || editor.selected === facet) return facet
              return this
            }

            // Beware: facet indexes get confused when we have less than 4 facets (e.g. A-frame)
            // This should probably be improved so the indexes do not get confused (e.g. consider the scenario where
            // a change to individual slopes results in more facets being created
            //
            // For now we simply record the facetIterationIndex - when the number of facets is not changing
            // facetIterationIndex will match the position of this facet in `this.facets`

            if (objectsFloatingOnFacetsBefore[facetIterationIndex]) {
              objectsFloatingOnFacetsBefore[facetIterationIndex].forEach((o) => {
                if (facet) {
                  facet.addFloatingObject(o)
                } else {
                  console.log('facet not found, structure probably not yet rebuilt (2)')
                }
              })
            }
            this.facets.push(facet)

            console.warn('Remove the need for setTimeout hack to refloat objects for the structure facets')
            // Do we need to somehow build or refresh something to make this work?
            // facet.refreshPosition()
            // facet.refreshPlane()
            // facet.refreshMesh(editor)

            if (window.TESTING) {
              facet.refloatObjects(editor)
            } else {
              setTimeout(function () {
                facet.refloatObjects(editor)
              }, 100)
            }

            // Do not use command because we do not want this to appear in undo-redo
            // editor.execute(new AddObjectCommand(facet, undefined, false, commandUUID))

            // Alternative to AddObjectCommand
            facet.applyUserData() // critical step to ensure textures render correctly
            editor.addObject(facet)
          }

          var force = false
          facet.onChange(editor, force, false)
        } else {
          console.log('insufficient points, skip facet')
        }
      })

    // console.log('this.nodes', this.nodes)
    // console.log('this.edges', this.edges)
    // console.log('this.facets', this.facets)
  },
  onRemove: function (editor) {
    editor.uiPauseUntilComplete(
      function () {
        editor.uiPauseUntilComplete(
          function () {
            if (this.facet) {
              this.facet.removeFloatingObject(this)
            }
            //remove associated facets, edges, etc
            this.clearChildren()
          },
          this,
          'ui',
          'uiPauseLock::OsStructure.onRemove()'
        )
      },
      this,
      'render',
      'renderPauseLock::OsStructure.onRemove()'
    )
  },
  DISABLEDtoGeoJSONFeature: function (sceneOrigin4326, toEpsg) { },
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,
  applyGhostMode: function (value) { },

  belongsToGroup: function (group) {
    return group.type === 'OsGroup' && group.objects.some((object) => object.uuid === this.uuid)
  },

  onEdgeLengthChanged: function (obj, prop) {
    if (!(obj.name == 'OsEdge' && prop == 'length') || this.isDormer() || this.edges.indexOf(obj) == -1) {
      return
    }

    var current = obj.getLength()

    var edge = obj
    var edgeIndex = this.edges.indexOf(edge)
    var edgeFacets = edge.getFacets()

    if (edgeFacets.length == 1) {
      // the edge changed is a gutter
      // adjust gutter
      this.adjustGutterLength(this.facets.indexOf(edgeFacets[0]), current)
      editor.select(this.edges[edgeIndex])
      return
    }

    var vertexCountDiff = edgeFacets[0].vertices.length - edgeFacets[1].vertices.length
    if (edgeFacets.length == 2 && vertexCountDiff == 0) {
      // the edge changed is a ridge
      this.adjustRidgeLength(edge, current)
      editor.select(this.edges[edgeIndex])
      return
    }

    if (edgeFacets.length == 2 && vertexCountDiff != 0) {
      // the edge changed is a hip in a hip roof
      this.adjustHipLength(edge, current)
      editor.select(this.edges[edgeIndex])
      return
    }
  },

  adjustGutterLength: function (gutterIndex, targetLength) {
    // change the x or y scaling of the structure to match
    var newScale = this.scale.clone()
    newScale[['y', 'x'][gutterIndex % 2]] = targetLength
    editor.execute(new SetScaleCommand(this, newScale, this.scale))
  },

  adjustRidgeLength: function (ridgeEdgeRef, targetLength) {
    // the triangular facets in a hip roof
    var triFacets = this.facets.filter((f) => {
      return f.vertices.length == 3
    })
    var lengthDiffHalf = (targetLength - ridgeEdgeRef.getLength()) / 2
    // the height difference of the ridge and the base of the roof
    var ridgeZHeight = ridgeEdgeRef.nodes[0].position.z - this.stories * HEIGHT_PER_STOREY
    var x, fIndex
    var newSlopes = this.slopes.slice()

    //      Reference Triangle
    //                 ___
    //              / |   |
    //           /    |   |  ridgeZHeight
    //        /       |   |
    //   A /__________|___|
    //          x     lengthDiffHalf

    triFacets.forEach((f) => {
      fIndex = this.facets.indexOf(f)
      // find the horizontal span of the triangular facet of the roof
      // given its slope (angle A) and the height of the ridge from the roof base
      x = ridgeZHeight / Math.tan(this.slopes[fIndex] * THREE.Math.DEG2RAD)
      // extend the horizontal span by HALF the difference of the new and old ridge length
      // since the ridge is lengthened or shortened equally from its midpoint
      lengthDiffHalf <= 0 ? (x += Math.abs(lengthDiffHalf)) : (x -= Math.abs(lengthDiffHalf))
      // calculate the new slope (angle A) given the new horizontal span
      newSlopes[fIndex] = Math.atan(ridgeZHeight / x) * THREE.Math.RAD2DEG
    })
    editor.execute(new SetSlopesCommand(this, newSlopes, this.slopes))
  },

  adjustHipLength: function (hipEdgeRef, targetLength) {
    var targetFacet = hipEdgeRef.getFacets().filter((f) => {
      return f.vertices.length == 3
    })[0]
    var ridgeZHeight = Math.abs(hipEdgeRef.nodes[0].position.z - hipEdgeRef.nodes[1].position.z)
    var sideA, sideB, sideC, angleC, distDC, newBPosY, newAngle
    var newSlopes = this.slopes.slice()

    //        B         // this is a reference triangle
    //       /|\        // side a is the hip that's changed
    //   c /  |  \  a   // side c is the other hip
    //   /    |    \    // side b is the gutter
    // A _____|_____ C  // line B-D is an extension of the ridge line
    //      b D

    // set the dimensions of the reference triangle
    targetFacet.getEdges().forEach((e) => {
      if (e.getFacets().length == 1) {
        sideB = e.getLength()
      } else {
        if (e.uuid != hipEdgeRef.uuid) {
          sideC = e.getLength()
        } else {
          sideA = e.getLength()
        }
      }
    })

    // compute angle at point C using law of cosines
    angleC = Math.acos((Math.pow(sideC, 2) - Math.pow(sideA, 2) - Math.pow(sideB, 2)) / -(2 * sideA * sideB))
    // compute distance of D->C
    distDC = Math.cos(angleC) * sideA
    // compute y coordinate for new position of point B such that:
    // - distance C->B is equal to target length
    // - point B z = ridgeZHeight
    // - point B x = 0  (point D is treated as the origin)
    // use distance formula in 3D
    newBPosY = Math.sqrt(Math.pow(targetLength, 2) - Math.pow(0 - distDC, 2) - Math.pow(ridgeZHeight, 2))
    //                |\
    //  ridgeZHeight  |   \
    //                |_____ \ D
    //               newBPosY
    // calculate the angle of line rising from point D to ridgeZHeight
    newAngle = Math.atan(ridgeZHeight / newBPosY)
    // update the slope of the target facet and the structure's corresponding slope record
    targetFacet.slope = newAngle * THREE.Math.RAD2DEG
    newSlopes[this.facets.indexOf(targetFacet)] = newAngle * THREE.Math.RAD2DEG
    editor.execute(new SetSlopesCommand(this, newSlopes, this.slopes))
  },

  onSelect: function () { },

  onDeselect: function () { },

  floatObjectsOnFacets: function (objectsFloatingOnFacets) {
    objectsFloatingOnFacets.forEach((objectUuidsForFacet, facetIndex) =>
      objectUuidsForFacet.forEach((objectUuid) => {
        var o = editor.objectByUuid(objectUuid)
        if (this.facets[facetIndex]) {
          this.facets[facetIndex].addFloatingObject(o)
        } else {
          console.log('facet not found, structure probably not yet rebuilt (1)')
        }
      })
    )
  },
  worldPositionForFindFacetUnderneath: function () {
    if (this.isDormer()) {
      // Dormers float 0.5m in from the local origin. This allows them to be positioned right up against the roof ridge
      // without accidentally slipping onto a different facet which can completely change the orientation unexpectedly.
      return this.localToWorld(new THREE.Vector3(0, 0.5 / this.scale.y, 0))
    } else {
      return this.position
    }
  },
  applyUserData: function () {
    // Bind with existing facets if they are found
    // This ensures that we do not keep creating new facets after each
    // save-and-load cycle.

    // Only perform this when first loaded before any facets are rebuilt
    if (this.facets.length === 0) {
      if (this.userData.facetUuids && this.userData.facetUuids.length) {
        this.userData.facetUuids.forEach((facetUuid) => {
          var facetFromScene = editor.objectByUuid(facetUuid)
          if (facetFromScene) {
            this.facets.push(facetFromScene)
          } else {
            console.warn('Trying to reconnect facet to structure but facet not found in scene')
          }
        })
      }
    }

    this.setMode(this.userData && this.userData.mode ? this.userData.mode : 'hip')

    // @TODO: Try to remove this hack, which is required when loading scene because rebuild() needs the matrixWorld
    // to be updated in order to position nodes/facets correctly but this is not standard for all objects.
    this.updateMatrixWorld()

    if (this.userData && this.userData.slopes) {
      this.slopes = this.userData.slopes
    }

    if (!editor.sceneIsLoading) {
      // Do not rebuild while scene is loading because it messes with scene children
      // Call it after scene is loaded in scene applyUserData
      this.rebuild()
    }

    if (this.userData.objectsFloatingOnFacets) {
      this.floatObjectsOnFacets(this.userData.objectsFloatingOnFacets)
    }
  },

  refreshUserData: function () {
    this.userData.mode = this.mode
    this.userData.slopes = this.slopes
    this.userData.objectsFloatingOnFacets = this.facets.map((f) => f.objectsFloating.map((o) => o.uuid))
    this.userData.facetUuids = this.facets.map((f) => f.uuid)
  },

  duplicate: function (options) {
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    const newStructure = new OsStructure(this)
    newStructure.position.copy(this.position).add(positionOffset)
    newStructure.rotation.copy(this.rotation)
    newStructure.children = []
    editor.execute(new AddObjectCommand(newStructure, null, true))
  },
  separate: function () {
    // Convert facets to be stand-alone and delete the parent structure
    // While it is tempting to try and keep the existing facets, this is really complicated to do with commands
    // Instead, we will delete the existing OsStructure and all it's child facets, and re-create new facets.

    // Create nodes, edges, facets

    const commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

    // Beware: RemoveObjectCommand also sets and resets editor.commandInProgress which will automatically = false
    // Luckily removing the object is the last step, but if the order changes this may need a rethink, and
    // RemoveObjectCommand may need to reset the value of editor.commandInProgress back to the original value when
    // finished rather than assuming it should be reset to false at the end.
    editor.commandInProgress = true

    this.facets.forEach((f) => {
      let newFacet = f.duplicate({ keepPosition: true })
      newFacet.isManaged = false
      f.objectsFloating.forEach((o) => {
        newFacet.addFloatingObject(o)
      })
    })

    editor.execute(new RemoveObjectCommand(this, true, false, commandUUID))

  },
  snapToTerrainNow: function (options = {}) {

    if (!editor.getTerrain()) {
      if (!options?.silent) {
        throw new Error('No terrain found to snap to')
      } else {
        return
      }
    }

    const commandUUID = window.Utils.generateCommandUUIDOrUseGlobal()

    const {
      slopes,
      elevationOffset
    } = OsStructure.slopeAndElevationFromTerrain(this)

    if (slopes) {
      editor.execute(new SetSlopesCommand(this, slopes, this.slopes, commandUUID))

    }

    // Only update elevation for non-dormer structures
    // Note: if we do want to update dormer elevation we need to consider that the dormer elevation
    // anchor point for a dormer (connection-point to parent facet) is different to a structure (gutter level)
    if (!this.isDormer() && elevationOffset) {
      var newPosition = new THREE.Vector3(this.position.x, this.position.y, elevationOffset)
      editor.execute(new SetPositionCommand(this, newPosition, this.position, commandUUID))
    }

    this.rebuildRaw()

  },

  rebuildRaw: function () {

    // Ensure we store floating objects before clearing facets
    var objectsFloatingOnFacetsBefore = this.facets.map((f) => f.objectsFloating)

    this.clearChildren()
    this.nodes = []
    this.edges = []
    this.facets = []

    var isDormer = this.isDormer()

    var { pointsUnique, pointsReferences, slopesEnabled, isFlat, facetPointsByFacetIndex, facetPointsByFacetIndexAdjusted } = OsStructure.generatePointsUnique(this.slopes, this.scale, this.rotation, this.isDormer(), this.floatingOnFacet ? this.floatingOnFacet.plane : null)

    function RefMap() {
      var keys = [],
        values = []

      return {
        put: function (key, value) {
          var index = keys.indexOf(key)
          if (index == -1) {
            keys.push(key)
            values.push(value)
          } else {
            values[index] = value
          }
        },
        get: function (key) {
          return values[keys.indexOf(key)]
        },
        dump: function () {
          return {
            keys: keys,
            values: values,
          }
        },
      }
    }

    var nodesForPointsAsReference = new RefMap()

    pointsUnique.forEach((p) => {
      var pointCentered
      if (isDormer) {
        // dormer
        pointCentered = p.clone().sub(new THREE.Vector3(0.5, 0, 0))
      } else {
        // structure
        pointCentered = p.clone().sub(new THREE.Vector3(0.5, 0.5, 0))
      }
      var nodePositionWorld = this.localToWorld(pointCentered)
      var node = new OsNode({ position: nodePositionWorld })
      // node.ghostMode(true)
      node.userData.excludeFromExport = true
      this.nodes.push(node)

      // editor.addObject(node)
      // editor.execute(new AddObjectCommand(node, null, false, commandUUID))

      nodesForPointsAsReference.put(p, node)
    }, this)

    var edgesWithPointsAsReference = [] //format each item is [edge, point1, point2]

    var _this_edges = this.edges

    var indexes = [0, 1, 2, 3]
    var facetPoints

    indexes
      .filter(
        // skip disabled facets (because they are vertical)
        (i) => slopesEnabled[i] || isFlat
      )
      .forEach((facetIndex, facetIterationIndex) => {

        facetPoints = facetPointsByFacetIndex[facetIndex]

        if (facetPoints.length >= 3) {
          //add edges

          var edge, edgeType
          for (var i = 0; i < facetPoints.length; i++) {

            edgeType = OsStructure.edgeTypeForFacetIndexAndSlopesEnabled(facetPoints, facetIndex, slopesEnabled, i, isDormer, isFlat, this.slopes)

            var { edge, created } = getOrCreateEdge(
              nodesForPointsAsReference,
              edgesWithPointsAsReference,
              _this_edges,
              facetPoints[i],
              facetPoints[(i + 1) % facetPoints.length],
              edgeType
            )

            if (edge) {
              // Create with correct visibility for current mode
              // Perhaps it would be better to set visibility after rebuilding
              // but for now we set it here
              if (ViewHelper && ViewHelper.facetEdgesDisplayMode() === 'none') {
                edge.visible = false
              } else {
                edge.visible = true
              }

              // if (created) {
              editor.addObject(edge)
              // editor.execute(new AddObjectCommand(edge, editor.scene, false, commandUUID))
              // }
            } else {
              console.warn('No edge created by getOrCreateEdge')
            }
          }

          var facet
          if (this.facets[facetIndex]) {
            facet = this.facets[facetIndex]
            facet.vertices = facetPoints.map((fp) => nodesForPointsAsReference.get(fp))
          } else {
            facet = new OsFacet({
              nodes: facetPoints.map((fp) => nodesForPointsAsReference.get(fp)),
              isManaged: true,
              roofTypeId: window.editor?.scene?.roofTypeId() || null,
              wallTypeId: window.editor?.scene?.wallTypeId() || null,
            })
            var existingFacetUuid = this.userData.facetUuids?.[facetIndex]
            if (existingFacetUuid) {
              facet.uuid = this.userData.facetUuids[facetIndex]
            }
            facet.userData.excludeFromExport = true
            facet.selectionDelegate = (editor) => {
              if (editor.selected === this || editor.selected === facet) return facet
              return this
            }

            // Beware: facet indexes get confused when we have less than 4 facets (e.g. A-frame)
            // This should probably be improved so the indexes do not get confused (e.g. consider the scenario where
            // a change to individual slopes results in more facets being created
            //
            // For now we simply record the facetIterationIndex - when the number of facets is not changing
            // facetIterationIndex will match the position of this facet in `this.facets`

            if (objectsFloatingOnFacetsBefore[facetIterationIndex]) {
              objectsFloatingOnFacetsBefore[facetIterationIndex].forEach((o) => {
                if (facet) {
                  facet.addFloatingObject(o)
                } else {
                  console.log('facet not found, structure probably not yet rebuilt (2)')
                }
              })
            }
            this.facets.push(facet)

            console.warn('Remove the need for setTimeout hack to refloat objects for the structure facets')
            // Do we need to somehow build or refresh something to make this work?
            // facet.refreshPosition()
            // facet.refreshPlane()
            // facet.refreshMesh(editor)

            if (window.TESTING) {
              facet.refloatObjects(editor)
            } else {
              setTimeout(function () {
                facet.refloatObjects(editor)
              }, 100)
            }

            // Do not use command because we do not want this to appear in undo-redo
            // editor.execute(new AddObjectCommand(facet, undefined, false, commandUUID))

            // Alternative to AddObjectCommand
            facet.applyUserData() // critical step to ensure textures render correctly
            editor.addObject(facet)
          }

          var force = false
          facet.onChange(editor, force, false)
        } else {
          console.log('insufficient points, skip facet')
        }
      })

  },

})

OsStructure.normalizeSlope = function (slopeReal, scaleFactor) {
  var dxScaled = scaleFactor
  var dyScaled = Math.tan(slopeReal * THREE.Math.DEG2RAD) * dxScaled
  var dxNormalized = 1
  var dyNormalized = dyScaled
  return Math.atan(dyNormalized / dxNormalized) * THREE.Math.RAD2DEG
}

OsStructure.rectifyRawSlopes = (slopesEnabled, slopesRaw, slope_rectification_method) => {

  if (slope_rectification_method === 'all_same') {
    // If forcing all slopes to be identical
    var slopesRawAndValid = slopesRaw.filter(s => !!s && s !== 0)
    var slopeMean = slopesRawAndValid.reduce((a, b) => a + b, 0) / slopesRawAndValid.length
    slopes = slopesEnabled.map(enabled => enabled ? slopeMean : 0)
  } else if (slope_rectification_method === 'opposites_same') {
    var slopesRawAndValid = [
      ([slopesRaw[0], slopesRaw[2]]).filter(s => !!s && s !== 0),
      ([slopesRaw[1], slopesRaw[3]]).filter(s => !!s && s !== 0),
    ]
    var slopeMean = [
      slopesRawAndValid[0].reduce((a, b) => a + b, 0) / slopesRawAndValid[0].length,
      slopesRawAndValid[1].reduce((a, b) => a + b, 0) / slopesRawAndValid[1].length,
    ]

    // If requiring opposites to be the same
    // Map slopes 2 and 3 back to mean calculated for 0 and 1
    slopes = slopesEnabled.map((enabled, index) => enabled ? slopeMean[index % 2] : 0)
  } else {
    // all_unique
    // If allowing each slope to be unique
    slopes = slopesEnabled.map((enabled, index) => enabled ? slopesRaw[index] : 0)
  }

  // Special case where we are using opposites_same and the slopes are very close, in which case
  // we will actually force them all to be the same which will generally improve the accuracy
  // and avoid unnecessary minor differences in the slopes.
  if (slope_rectification_method === 'opposites_same') {
    var SNAP_OPPOSITE_SLOPES_THRESHOLD = 1
    var slopeDiff = Math.abs(slopes[0] - slopes[1])
    if (slopeDiff < SNAP_OPPOSITE_SLOPES_THRESHOLD) {
      return OsStructure.rectifyRawSlopes(slopesEnabled, slopesRaw, 'all_same')
    }
  }

  return slopes
}

OsStructure.slopeAndElevationFromTerrain = (osStructure) => {
  /**
   * Estimate the shape of each facet then sample the terrain within that shape.
   * 
   * osStructure is an OsStructure object that just needs position/scale/rotation set correctly
   * Slope & elevation (position.z) values can be derived and auto-applied.
   * 
   */

  // Use a dummy slope value which is generally close enough to give accurate sample of points, assuming the slope is not too steep
  // and don't differ too much between the x and y axes. We also inset the area to make it more robust to x and y slope differences too.
  var DUMMY_SLOPE = 22.5
  var dummySlopes = osStructure.slopes.map(s => !!s && s !== 0 ? DUMMY_SLOPE : 0)

  var { pointsUnique, pointsReferences, slopesEnabled, isFlat, facetPointsByFacetIndex, facetPointsByFacetIndexAdjusted } = OsStructure.generatePointsUnique(dummySlopes, osStructure.scale, osStructure.rotation, osStructure.isDormer(), osStructure.floatingOnFacet ? osStructure.floatingOnFacet.plane : null)

  var slopesAndElevationResiduals = facetPointsByFacetIndexAdjusted.map((facetPointsLocal, facetIndex) => {

    if (facetPointsLocal.length === 0) {
      return {
        slope: null, worldElevationAtGutter: null
      }
    }

    var facetPointsWorld = facetPointsLocal.map(p => osStructure.localToWorld(p.clone()))

    var shape = Utils.polygon2DFromCoordinates(
      facetPointsWorld.map(p => p.toArray())
    )

    // Do not subtract any facets which already exist and belong to this structure or this will break immediately
    // after it is first built. Unfortunately we cannot do this using a GeometryCollection due to lack of support in JSTS so we
    // create a union first then subtract it in one step.
    // var otherFacetsShapes = editor.filter('type', 'OsFacet').filter(f => !osStructure.facets.includes(f)).map(f => f.shapesWithSetbacksJSTS(true).facetShape)
    // 
    // if (otherFacetsShapes.length > 0) {
    //   var otherFacetsShapesCombined = otherFacetsShapes.reduce((a, b) => !a ? b : a.union(b))
    //   shape = shape.difference(otherFacetsShapesCombined)
    // }

    // Inset after subtracting other facets
    var BUFFER_STRUCTURE_TERRAIN_SAMPLING_SHAPE = -0.5
    var MIN_SHAPE_AREA_AFTER_BUFFER = 2
    var shapeWithInset = shape.buffer(BUFFER_STRUCTURE_TERRAIN_SAMPLING_SHAPE)

    // Only use the inset shape if it's area is large enough
    if (shapeWithInset.getArea() > MIN_SHAPE_AREA_AFTER_BUFFER) {
      shape = shapeWithInset
    }


    var samplePointsWorld = SceneHelper.terrainPointsWithinShape(shape)

    var planeFunc = OsFacet.planeFromPointsRANSAC

    var planeResult = planeFunc(
      samplePointsWorld.map(function (p) {
        return p.toArray()
      }),
      {
        iterations: undefined,
        numPoints: undefined,
        distanceThresholdForInliers: undefined,
        calculateMeanAbsoluteError: true,
        bestPlaneMethod: 'meanAbsoluteError',
      }
    )

    var planeFromTerrain = planeResult.plane

    // calculate the elevation offset
    // we could probably avoid calculating the elevation difference for every pair of points and just compare some kind of mean elevation
    // but for now we just keep it simple

    var slope = OsFacet.slopeForNormal(planeFromTerrain.normal)

    // We could try to calculate at osStructure.position but that would require calculating the height of the osStructure which relies
    // on slopes, but we are calculating slopes here, so we avoid using that value here.
    //
    // If this slope is slightly incorrect, will it skew the results due to calculating the elevation offset at the gutter instead of
    // in the center of the face? Probably not, but it's worth considering.

    var worldElevationAtGutter = Utils.elevationAt(planeFromTerrain, new THREE.Vector3(
      // Take average of 1st and 2nd points only beacuse we conveniently sorted this points to ensure the gutter is
      // joins the first two points
      (facetPointsWorld[0].x + facetPointsWorld[1].x) / 2,
      (facetPointsWorld[0].y + facetPointsWorld[1].y) / 2,
      0))

    return {
      slope, worldElevationAtGutter, meanAbsoluteError: planeResult.meanAbsoluteError
    }
  })

  var slopesRaw = slopesAndElevationResiduals.map(s => s.slope)

  // Check any unreliable slopes and if the opposite slope is reliable then use it instead.
  var MAE_GOOD_THRESHOLD = 0.1
  var MAE_OK_THRESHOLD = 0.2

  for (var i = 0; i < 4; i++) {
    var thisMAE = slopesAndElevationResiduals[i].meanAbsoluteError
    var oppositeMAE = slopesAndElevationResiduals[(i + 2) % 4].meanAbsoluteError
    if (thisMAE > MAE_OK_THRESHOLD && oppositeMAE < MAE_GOOD_THRESHOLD) {
      console.debug(`Replacing unreliable slope with opposite slope for facet ${i}`)
      slopesRaw[i] = slopesRaw[(i + 2) % 4]
    }
  }


  // all_same, opposites_same, all_unique
  var SLOPE_RECTIFICATION_METHOD = 'opposites_same'

  var slopes = OsStructure.rectifyRawSlopes(slopesEnabled, slopesRaw, SLOPE_RECTIFICATION_METHOD)

  // calculate the mean elevation offset across all active planes, this will be applied to the structure.
  var slopesForElevationCalculation = slopesAndElevationResiduals.filter(s => s.meanAbsoluteError < MAE_OK_THRESHOLD)
  // If no slopes are "good", then just use the best slope we have so we can at least get some kind of elevation offset
  // instead of just failing.
  if (slopesForElevationCalculation.length === 0) {
    slopesForElevationCalculation = slopesAndElevationResiduals.sort((a, b) => a.meanAbsoluteError - b.meanAbsoluteError).slice(0, 1)
  }

  var worldElevationsAtGutter = slopesForElevationCalculation.map(s => s.worldElevationAtGutter)
  var elevationOffsetMean = worldElevationsAtGutter.reduce((a, b) => a + b, 0) / worldElevationsAtGutter.length

  var ELEVATE_STRUCTURE_ABOVE_TERRAIN = 0.2
  var elevationOffset = elevationOffsetMean + ELEVATE_STRUCTURE_ABOVE_TERRAIN

  return {
    slopes,
    elevationOffset: elevationOffset
  }

}

OsStructure.slopesToMode = function (slopes) {
  var numSlopesPopulated = this.slopes.filter((s) => (s > 0 && s < 8)).length
  if (numSlopesPopulated === 4) {
    return 'hip'
  } else if (numSlopesPopulated === 2) {
    return 'aframe'
  } else {
    return 'flat'
  }
}


OsStructure.generatePointsUnique = (slopes, scale, rotation, isDormer, parentFacetPlane) => {
  const pointsUnique = [];
  const slopesEnabled = [true, true, true, true];
  const pointsReferences = [null, null, null, null, null, null, null, null];
  let planes = [];
  let slopesAdjusted = [];
  let isFlat = false;

  if (slopes.filter((s) => s === 0).length === 4) {
    isFlat = true;
    slopesEnabled.forEach((_, i) => slopesEnabled[i] = false);
    pointsUnique.push(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 1, 0), new THREE.Vector3(1, 1, 0), new THREE.Vector3(1, 0, 0));
    pointsReferences.forEach((_, i) => pointsReferences[i] = pointsUnique[i % 4]);
    const planeForAll = new THREE.Plane().setFromNormalAndCoplanarPoint(new THREE.Vector3(0, 0, 1), pointsUnique[0]);
    planes = [planeForAll, planeForAll, planeForAll, planeForAll];
  } else {
    slopesAdjusted = slopes.map((s) => (s > 80.0 || s < 0.1 ? 89.9999 : s));
    slopesEnabled.forEach((_, i) => slopesEnabled[i] = slopesAdjusted[i] < 89);
    const slopesScaled = [
      OsStructure.normalizeSlope(slopesAdjusted[0], scale.x),
      OsStructure.normalizeSlope(slopesAdjusted[1], scale.y),
      OsStructure.normalizeSlope(slopesAdjusted[2], scale.x),
      OsStructure.normalizeSlope(slopesAdjusted[3], scale.y),
    ];

    const normals = [
      new THREE.Vector3(Math.tan(-slopesScaled[0] * THREE.Math.DEG2RAD), 0, 1).normalize(),
      new THREE.Vector3(0, Math.tan(slopesScaled[1] * THREE.Math.DEG2RAD), 1).normalize(),
      new THREE.Vector3(Math.tan(slopesScaled[2] * THREE.Math.DEG2RAD), 0, 1).normalize(),
      new THREE.Vector3(0, Math.tan(-slopesScaled[3] * THREE.Math.DEG2RAD), 1).normalize(),
    ];

    const points = [
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 1, 0),
      new THREE.Vector3(1, 1, 0),
      new THREE.Vector3(1, 0, 0),
    ];

    planes = [
      new THREE.Plane().setFromNormalAndCoplanarPoint(normals[0], points[0]),
      new THREE.Plane().setFromNormalAndCoplanarPoint(normals[1], points[1]),
      new THREE.Plane().setFromNormalAndCoplanarPoint(normals[2], points[2]),
      new THREE.Plane().setFromNormalAndCoplanarPoint(normals[3], points[3]),
    ];

    // returns a line which may be initially facing up or down
    var rayDirections = [
      planes[0].intersectPlane(planes[3]).delta(new THREE.Vector3()),
      planes[0].intersectPlane(planes[1]).delta(new THREE.Vector3()),
      planes[1].intersectPlane(planes[2]).delta(new THREE.Vector3()),
      planes[3].intersectPlane(planes[2]).delta(new THREE.Vector3()),
    ]

    // point all rays up
    rayDirections.forEach((rd) => {
      if (rd.z < 0) {
        rd.negate()
      }
      // originally 10 units long
      // changed to 100 units to ensure long enough to intersect with a very steep plane
      // link to ticket: https://github.com/open-solar/opensolar-todo/issues/5883
      rd.normalize().multiplyScalar(100)
    })

    const cornerLines = [0, 1, 2, 3].map((i) => new THREE.Line3(points[i], points[i].clone().add(rayDirections[i])));

    const closestValidPoint = (startPoint, point1, point2) => {
      const d1 = point1 ? startPoint.distanceTo(point1) : null;
      const d2 = point2 ? startPoint.distanceTo(point2) : null;
      if (!d1 && !d2) return null;
      return !d2 || (d1 && d1 <= d2) ? point1 : point2;
    };

    const closestIntersections = [
      closestValidPoint(points[0], planes[1].intersectLine(cornerLines[0], new THREE.Vector3()), planes[2].intersectLine(cornerLines[0], new THREE.Vector3())),
      closestValidPoint(points[1], planes[2].intersectLine(cornerLines[1], new THREE.Vector3()), planes[3].intersectLine(cornerLines[1], new THREE.Vector3())),
      closestValidPoint(points[2], planes[3].intersectLine(cornerLines[2], new THREE.Vector3()), planes[0].intersectLine(cornerLines[2], new THREE.Vector3())),
      closestValidPoint(points[3], planes[0].intersectLine(cornerLines[3], new THREE.Vector3()), planes[1].intersectLine(cornerLines[3], new THREE.Vector3())),
    ];

    const pointsAll = points.concat(closestIntersections);
    const distanceThreshold = 0.1;

    const getNearbyPointsWithIndexes = (targetPoint) =>
      pointsAll
        .map((p, pointIndex) => ({ point: p, index: pointIndex, isNearby: targetPoint.distanceTo(p) < distanceThreshold }))
        .filter((pointWithIndex) => pointWithIndex.isNearby);

    let counter = 0;

    while (pointsReferences.some((pr) => pr === null)) {
      if (counter++ > 10) break;

      const nextPointIndex = pointsReferences.indexOf(null);

      const nearbyPointsWithIndexes = getNearbyPointsWithIndexes(pointsAll[nextPointIndex]);

      if (nearbyPointsWithIndexes.length === 1) {
        pointsUnique.push(nearbyPointsWithIndexes[0].point);
        pointsReferences[nextPointIndex] = nearbyPointsWithIndexes[0].point;
      } else {
        const newUniquePoint = Utils.getCentroid(nearbyPointsWithIndexes.map((pwi) => pwi.point));
        pointsUnique.push(newUniquePoint);

        nearbyPointsWithIndexes.forEach((pwi) => {
          pointsReferences[pwi.index] = newUniquePoint;
        });
      }
    }
  }

  // Dormers move all point Z values down so the highest point is at local z=0
  // so apex perfectly aligns with roof
  if (isDormer) {
    var highestPointZ = Math.max.apply(
      null,
      pointsUnique.map((p) => p.z)
    )
    pointsUnique.forEach((p) => {
      p.z -= highestPointZ
    })

    // CURRENT STATUS: Almost working perfectly except at some scales dormer shape gets distorted.
    // This can be fixed by fixing the calculation of valleys and dormerTopCornerPoints. Alternatively
    // as a workaround, we we can't solve that then we could use replace Utils.findMidpointBetweenLines
    // which results in  midpoints drifting too wide if the dormer-edge and valley-lines do not intersect
    // perfectly.
    //
    // Shift out the top-outer point of the dormer to prevent the dormer from continuing under the roof facet.
    //
    // The plane-to-plane intersection line between the a) parent facet and b) dormer facet gives the
    // "valley", which is the sloped intersection line. But we need to determine how far along that valley to move.
    // How far along the valley? Find where the vertical plane along the dormer edge intersects the valley line.
    // To avoid the complexity of a plane-line intersection, we can simply find the closest point (or intersection)
    // between the valley line and the dormer edge which is theoretically an intersection point but we just use the
    // closest-point-between-lines which is robust and avoids floating point errors that a true solution might have.
    // var parentFacetPlane = this.floatingOnFacet ? this.floatingOnFacet.plane : null

    var DORMER_LOCAL_ORIGIN = new THREE.Vector3(0.5, 0, 0)

    if (parentFacetPlane) {
      var parentFacetPlaneLocalNormal = parentFacetPlane.normal
        .clone()
        .applyAxisAngle(new THREE.Vector3(0, 0, 1), -rotation.z)

      // @TODO: Fix this scaling factor to allow us to compare the dormer to the parent facet
      // since the dormer is scaled down to 1:1:1. rescale the parentFacetPlane to use the same units
      // but this needs some thought on how to do it correctly.
      // Current implementation is not quite correct.
      var parentFacetPlaneLocalNormalScaled = new THREE.Vector3(
        parentFacetPlaneLocalNormal.x * scale.x,
        parentFacetPlaneLocalNormal.y * scale.y,
        parentFacetPlaneLocalNormal.z * scale.z
      ).normalize()

      var parentFacetPlaneLocalScaled = new THREE.Plane().setFromNormalAndCoplanarPoint(
        parentFacetPlaneLocalNormalScaled,
        // This is not from the "origin" (ridge start) of the dormer, it is from the bottom-left of the dormer
        // where position = 0,0,0
        new THREE.Vector3(0, 0, 0)
      )

      var valleys = [
        planes[0].intersectPlane(parentFacetPlaneLocalScaled, new THREE.Vector3()),
        planes[2].intersectPlane(parentFacetPlaneLocalScaled, new THREE.Vector3()),
      ]

      // Ensure valleys starts from 0,0,0 but has same direction
      // Not strictly necessary but very helpful during debugging
      // delta from start [0.5,0,0]

      if (valleys[0].end.z > valleys[0].start.z) {
        valleys[0].start.negate()
        valleys[0].end.negate()
      }

      var offset = new THREE.Vector3().subVectors(valleys[0].start, DORMER_LOCAL_ORIGIN)
      valleys[0].start.sub(offset)
      valleys[0].end.sub(offset)

      if (valleys[1].end.z > valleys[1].start.z) {
        valleys[1].start.negate()
        valleys[1].end.negate()
      }

      var offset = new THREE.Vector3().subVectors(valleys[1].start, DORMER_LOCAL_ORIGIN)
      valleys[1].start.sub(offset)
      valleys[1].end.sub(offset)

      var dormerEdges = [
        new THREE.Line3(pointsUnique[0], pointsUnique[1]),
        new THREE.Line3(pointsUnique[3], pointsUnique[2]),
      ]

      // second point is wrong! Not sure why, maybe bad implementation of findMidpointBetweenLines?
      // in any case, we can copy the value from the first point and just flip it. Actually we should
      // probably do this anyway for speed anyway.
      var dormerTopCornerPoints = [
        Utils.findMidpointBetweenLines(valleys[0], dormerEdges[0]),
        Utils.findMidpointBetweenLines(valleys[1], dormerEdges[1]), // wrong, see below
      ]

      // if dormerTopCornerPoints extend beyond the length of the dormer
      // then scale their length back so they do not go past the end of the dormer
      var lengthY = dormerTopCornerPoints[0].y
      var lengthScaleFactor = lengthY < 1 ? 1 : 1 / lengthY
      var delta = dormerTopCornerPoints[0].clone().sub(DORMER_LOCAL_ORIGIN).multiplyScalar(lengthScaleFactor)
      var dormerTopCornerPointsScaled = [DORMER_LOCAL_ORIGIN.clone().add(delta), null]

      dormerTopCornerPointsScaled[1] = new THREE.Vector3(
        1 - dormerTopCornerPointsScaled[0].x,
        dormerTopCornerPointsScaled[0].y,
        dormerTopCornerPointsScaled[0].z
      )

      pointsUnique[0].copy(dormerTopCornerPointsScaled[0])
      pointsUnique[3].copy(dormerTopCornerPointsScaled[1])
    }
  }

  var indexes = [0, 1, 2, 3]
  var facetPointsByFacetIndex = indexes.map((facetIndex, facetIterationIndex) => {

    if (isFlat) {
      if (facetIndex === 0) {
        // continue just for the first facet in a flat structure
      } else {
        // all other facet indexes for flat roof will return empty
        return []
      }
    } else if (!slopesEnabled[facetIndex]) {
      return []
    }

    var facetPoints

    if (isFlat) {
      facetPoints = [pointsReferences[0], pointsReferences[1], pointsReferences[2], pointsReferences[3]]
      return facetPoints
    } else {
      facetPoints = getUniqueItems([
        // points
        pointsReferences[facetIndex],
        pointsReferences[(facetIndex + 1) % 4],

        // corner plane intersections
        // tricky note: we reverse the order here to avoid tangling the order
        // e.g. For face 0 we use point 0, point 1 then jump to rayFromPoint 1 then rayFromPoint 0
        pointsReferences[4 + ((facetIndex + 1) % 4)], //wrap index 7 back to index 4
        pointsReferences[4 + facetIndex],
      ])
      return facetPoints
    }
  })

  // @TODO: Untangle this so we do not need different values in:
  // - pointsUnique
  // - facetPointsByFacetIndex
  // - facetPointsByFacetIndexAdjusted
  var facetPointsByFacetIndexAdjusted = facetPointsByFacetIndex.map((facetPoints) => {
    return facetPoints.map((p) => {
      if (isDormer) {
        // dormer
        return p.clone().sub(new THREE.Vector3(0.5, 0, 0))
      } else {
        // structure
        return p.clone().sub(new THREE.Vector3(0.5, 0.5, 0))
      }
    })
  })

  return { pointsUnique, pointsReferences, slopesEnabled, isFlat, facetPointsByFacetIndex, facetPointsByFacetIndexAdjusted };
}


OsStructure.edgeTypeForFacetIndexAndSlopesEnabled = function (facetPoints, facetIndex, slopesEnabled, facetSlopeIndex, isDormer, isFlat, slopes) {

  if (isFlat) {
    return 'flat_gutter'
  }

  var SLOPE_MAX_FOR_FLAT_ROOF = 6
  var maxValidSlope = Math.max(...slopes.filter(s => !!s || s === 0))
  if (maxValidSlope < SLOPE_MAX_FOR_FLAT_ROOF) {
    return 'flat_gutter'
  }

  // Special case for dormer valleys which join the roof
  if (isDormer) {
    if (facetIndex == 0 && facetSlopeIndex == 3) {
      // right edge of first dormer facet
      return 'valley'
    } else if (facetIndex == 2 && facetSlopeIndex == 1) {
      // left edge of third dormer facet
      return 'valley'
    }
  }

  if (facetSlopeIndex == 0) {
    return 'gutter'
  } else if (facetSlopeIndex == 1) {
    // check left edge, if it's slope is not enabled then it's an aframe, so edge type is rake
    // otherwise it's a hip
    var facetIndexLeft = (facetIndex + 1) % 4
    if (slopesEnabled[facetIndexLeft]) {
      return 'hip'
    } else {
      return 'rake'
    }
  } else if (facetSlopeIndex == 3) {
    // check right edge, if it's slope is not enabled then it's an aframe, so edge type is rake
    // otherwise it's a hip
    var facetIndexRight = (facetIndex + 3) % 4
    if (slopesEnabled[facetIndexRight]) {
      return 'hip'
    } else {
      return 'rake'
    }
  } else if (facetSlopeIndex == 2) {
    if (facetPoints.length == 3) {
      // triangular facet, this is guaranteed to be a hip
      // pointIndex:2 is actually the ridge because there are fewer pointIndexes for triangular facets.
      return 'hip'
    } else {
      return 'ridge'
    }
  } else {
    return null
  }
}