/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newParent THREE.Object3D
 * @param newBefore THREE.Object3D
 * @constructor
 */

var MoveObjectCommand = function (object, newParent, newBefore) {
  Command.call(this)

  this.type = 'MoveObjectCommand'
  this.name = 'Move Object'
  this.timeStamp = Date.now()

  this.PROPERTIES = ['newIndex', 'oldIndex']
  this.OBJECT_REFERENCES = ['object', 'newParent', 'oldParent']

  if (arguments.length) {
    this.object = object
    this.oldParent = object !== undefined ? object.parent : undefined
    this.oldIndex = this.oldParent !== undefined ? this.oldParent.children.indexOf(this.object) : undefined
    this.newParent = newParent

    if (newBefore !== undefined) {
      this.newIndex = newParent !== undefined ? newParent.children.indexOf(newBefore) : undefined
    } else {
      this.newIndex = newParent !== undefined ? newParent.children.length : undefined
    }

    if (this.oldParent === this.newParent && this.newIndex > this.oldIndex) {
      this.newIndex--
    }

    this.newBefore = newBefore
  }
}

MoveObjectCommand.prototype = {
  execute: function () {
    this.oldParent.remove(this.object)

    var children = this.newParent.children
    children.splice(this.newIndex, 0, this.object)
    this.object.parent = this.newParent

    this.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    this.newParent.remove(this.object)

    var children = this.oldParent.children
    children.splice(this.oldIndex, 0, this.object)
    this.object.parent = this.oldParent

    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
