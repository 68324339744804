class Google2DMapAdapter {
  constructor(map) {
    this.map = map
  }

  getZoom() {
    return this.map.getZoom()
  }

  setZoom(zoom) {
    this.map.setZoom(zoom)
  }
}
