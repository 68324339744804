function rotateToSmallest(path) {
  var positionOfSmallest = path.indexOf(Math.min.apply(null, path))
  return path.slice(positionOfSmallest).concat(path.slice(0, positionOfSmallest))
}

function invert(path) {
  return rotateToSmallest([].concat(path).reverse())
}

function sortCycles(graph) {
  var allCycles = findAllCycles(graph)

  var chordlessCycles = []
  var chordedCycles = []

  allCycles.forEach(function (c) {
    if (!hasSubcycle(c, allCycles)) {
      chordlessCycles.push(c)
    } else {
      chordedCycles.push(c)
    }
  })
  return [chordlessCycles, chordedCycles]
}

function hasSubcycle(cycle, allCycles) {
  for (var i = 0; i < allCycles.length; i++) {
    var otherCycle = allCycles[i]
    if (cycle.length > otherCycle.length) {
      //optimization: cull based on size
      if (_.difference(otherCycle, cycle).length === 0) {
        return true
      }
    }
  }
  return false
}

function findAllCycles(graph) {
  //console.log('findAllCycles()')
  var cycles = []

  var i, j, len

  var st1 = new Date().getTime()

  for (i = 0; i < graph.length; i++) {
    var edge = graph[i]
    for (j = 0; j < 2; j++) {
      findNewCycles([edge[j]], graph, cycles)
    }
  }

  var st2 = new Date().getTime()
  console.log('time: ' + (st2 - st1))
  return cycles
}

function findNewCycles(path, graph, cycles) {
  //console.log('--findNewCycles')
  var startNode = path[0],
    nextNode

  // visit each edge and each node of each edge
  for (var i = 0; i < graph.length; i++) {
    var edge = graph[i]
    for (var j = 0; j < 2; j++) {
      var node = edge[j]
      if (node === startNode) {
        //  edge refers to our current node
        nextNode = edge[(j + 1) % 2]
        if (!visited(nextNode, path)) {
          //  neighbor node not on path yet
          //  explore extended path
          findNewCycles([nextNode].concat(path), graph, cycles)
        } else if (path.length > 2 && nextNode === path[path.length - 1]) {
          //  cycle found

          var pathNormalized = rotateToSmallest(path)
          var pathReversed = invert(pathNormalized)

          if (isNew(pathNormalized, cycles) && isNew(pathReversed, cycles)) {
            cycles.push(pathNormalized)
          }
        }
      }
    }
  }
}

// check if vertex n is contained in path
function visited(node, path) {
  return path.indexOf(node) !== -1
}

function isNew(path, cycles) {
  for (var i = 0; i < cycles.length; i++) {
    if (pathIsEqual(path, cycles[i])) {
      //console.log('not new', path, 'exists at:', cycles[i])
      return false
    }
  }

  return true
}

function pathIsEqual(path1, path2) {
  if (path1.length !== path2.length) {
    return false
  }

  for (var i = path1.length; i--; ) {
    if (path1[i] !== path2[i]) {
      return false
    }
  }

  return true
}
