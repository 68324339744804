/**
 * @author adampryor
 * Adapted from THREE.EditorControls
 */

var debug = false

var FACET_MESH_INTERSECTION_LINE_PRECISION = 0.1
var SNAP_DISTANCE_TO_EXISTING_NODE = 0.2

var AddObjectController = function (editor, viewport) {
  this.name = 'AddObject'

  const inputs = {
    activation: (activationState) => {
      if (activationState === this.active) return
      if (activationState === true) {
        this.activate()
      } else {
        this.deactivate()
      }
    },
  }
  this.inputs = inputs

  const outputs = {
    activation: new window.Signal(),
  }
  this.outputs = outputs

  const hotkeys = new Hotkeys(this)
  hotkeys.on(Hotkeys.ESCAPE).do(abort)

  var camera = editor.camera
  var domElement = viewport.container.dom

  var scope = this

  var helperObjects = []
  var newObject = null
  var mousePosition = new THREE.Vector2()

  var onFinishCallback = null
  var onPlaceCallback = null
  var onMoveCallback = null

  var continueUntilCancelled = false

  var objectType = null

  var addWireForSystem = undefined

  // Generic placeholder used to store temporary info about this interaction
  var data = {}

  // Temporary store for linking a raw position with a snap target position
  // Required because snapping of nodes occurs on mouseMove/touchMove and we want the last
  // result to be available when calling onPlacement without needing to recalculate it
  var snapPositionTargetForPosition = {
    rawPosition: null,
    snappedPosition: null,
  }

  function onPlaceCallbackDefault(event) {
    if (newObject.ghostMode && newObject.ghostMode()) {
      newObject.ghostMode(false)
      if (helperObjects.indexOf(newObject) !== -1) {
        // remove from helper objects so it does not get cleared
        // because we now want to keep this as our real object
        helperObjects.splice(helperObjects.indexOf(newObject), 1)
      }
    }

    if (
      newObject &&
      (newObject.type === 'OsObstruction' || newObject.type === 'OsClipper' || newObject.type === 'OsTree')
    ) {
      editor.execute(new AddObjectCommand(newObject, newObject.parent, true)) // auto-select
    } else if (newObject) {
      console.warn('Warning: Object added without a command!')
      editor.signals.objectChanged.dispatch(newObject)
      editor.select(newObject)
    }
  }
  function onMoveCallbackDefault(event) {
    return
  }

  function clearGhostObjects() {
    // Leave newObject if not registered in helperObjects

    helperObjects.forEach(function (o) {
      if (o.parent) {
        editor.removeObject(o)
      }
    }, this)
    helperObjects = []
  }

  function start(type, _continueUntilCancelled, options) {
    if (this.active) {
      Designer.showNotification(window.translate('Already placing objects. Press ESC to finish.'), 'danger')
      return
    }

    if (window.objectTypeBlacklist && window.objectTypeBlacklist[type]) {
      console.debug('AddObject call ignored, due to blacklisted type: ', type)
      return
    }

    var addWireForSystem = options?.addWireForSystem || undefined

    this.objectType = type

    continueUntilCancelled = _continueUntilCancelled

    //create new object as mouse follower

    //override later if desired
    onPlaceCallback = onPlaceCallbackDefault
    onMoveCallback = onMoveCallbackDefault

    if (type == 'OsObstruction') {
      newObject = new OsObstruction({ ghostMode: true, ...(options || {}) })
      helperObjects.push(newObject)
    } else if (type == 'OsClipper') {
      newObject = new OsClipper({ ghostMode: true })
      newObject.scale.set(4, 4, 1)
      helperObjects.push(newObject)
    } else if (type == 'OsStructure') {
      newObject = new OsStructure({ ghostMode: true })
    } else if (type == 'OsAnnotation') {
      if (!editor.selectedSystem) {
        Designer.showNotification(window.translate('Create or select a system before placing annotations'), 'danger')
        return
      }

      newObject = new OsAnnotation({ ...options, ghostMode: true })
      newObject.refreshUserData()
      // helperObjects.push(newObject)

      onPlaceCallback = function (event) {
        //if a system is selected, add to that system
        if (editor.selectedSystem) {
          //Don't remove the object compete just change the parent
          Utils.SceneUtils.detach(newObject, newObject.parent, editor.scene)
          // Utils.SceneUtils.attach(newObject, editor.scene, editor.selectedSystem)
          newObject.applyMatrix(new THREE.Matrix4().getInverse(editor.selectedSystem.matrixWorld))
          editor.scene.remove(newObject)
          newObject._ghostMode = false
          editor.execute(new AddObjectCommand(newObject, editor.selectedSystem, true))
        }

        if (newObject) {
          editor.signals.objectChanged.dispatch(newObject)
          editor.select(newObject)
        }
      }

      onMoveCallback = function (event) {
        // Force annotation to update by passing argument for extraHoverObject
        editor.signals.objectAnnotationChanged.dispatch(null, newObject)
      }
    } else if (type == 'OsTree') {
      newObject = new OsTree({ ghostMode: true })
      helperObjects.push(newObject)
    } else if (type == 'OsModuleGrid') {
      if (!editor.selectedSystem) {
        Designer.showNotification(window.translate('Create or select a system before placing modules'), 'danger')
        return
      }

      newObject = new OsModuleGrid({
        size: editor.selectedSystem?.moduleType().size,
        azimuth: 180,
        slope: 20,
      })

      onPlaceCallback = function (event) {
        //if a system is selected, add to that system
        if (editor.selectedSystem) {
          //Don't remove the object compete just change the parent
          Utils.SceneUtils.detach(newObject, newObject.parent, editor.scene)
          Utils.SceneUtils.attach(newObject, editor.scene, editor.selectedSystem)

          // This attempts to fix a depth sorting issue where inactive panels are rendering below facetmesh
          // Removed, we no longer do rollovers on grids
          // newObject.handleMouse(true)
        }

        if (newObject) {
          editor.signals.objectChanged.dispatch(newObject)
          editor.select(newObject)
        }
      }

      onMoveCallback = function (event) {
        //If floating on facet, remove the overrides
        //Apply overrides if no facet underneath

        var facetUnderneath = newObject.findFacetUnderneath()
        if (facetUnderneath) {
          facetUnderneath.floatObject(newObject)
          newObject.azimuthAuto = true
          newObject.slopeAuto = true
        } else {
          newObject.setAzimuth(180)
          newObject.setSlope(20)
        }
      }
    } else if (type == 'OsNode') {
      throw 'AddObjectController.start(OsNode) not mplemented'
    } else if (type == 'OsEdge') {
      //Two variations when creating an edge:

      //1. First node in an edge:
      //  Place node and save a reference to it
      //  After placement switch to second mode for next placement...
      //
      //2. Second node in an edge:
      //  Add node as second node in previous edge
      //  Create edge with from both nodes
      //  Update reference to the send node placed
      //  Start new placement session

      newObject = new OsNode()
      helperObjects.push(newObject)
      newObject.ghostMode(true)
      newObject.refreshForCamera(editor.camera.position, editor.metersPerPixel())

      // Create the edge which will update dynamically
      if (data['previousNodeInEdge']) {
        if (debug) console.log('start: previousNodeInEdge is found', data['previousNodeInEdge'])

        //we don't yet have a mouse position so cannot position the node.
        //instead simply copy the previous node position
        newObject.position.copy(data['previousNodeInEdge'].position)
        // editor.execute(new SetPositionCommand(newObject, data['previousNodeInEdge'].position))

        //create edge
        var nodesForNewEdge = [data['previousNodeInEdge'], newObject]

        if (
          editor.filterObjects(function (o) {
            return o.type == 'OsEdge' && o.hasNodes(nodesForNewEdge)
          }).length == 0
        ) {
          var lastEdgeType = data.previousNodeInEdge.edgeType || 'blue'
          var newEdge = new OsEdge({ nodes: nodesForNewEdge, edgeType: addWireForSystem ? lastEdgeType : undefined })
          helperObjects.push(newEdge)
          newEdge.ghostMode(true)
          if (debug) console.log(' add edge in AddObjectController', nodesForNewEdge[0].name, nodesForNewEdge[1].name)

          newEdge.onChange(editor)
          editor.addObject(newEdge, addWireForSystem)
          // editor.execute(new AddObjectCommand(newEdge))
        }
      } else {
        if (debug) console.log('start: previousNodeInEdge not found')
      }

      let setPreviousNode = function (node) {
        data['previousNodeInEdge'] = node
      }

      let getPreviousNode = function () {
        return data['previousNodeInEdge']
      }
      let getClickedNode = function (screenFraction) {
        //Exclude newObject from intersection test
        var nodesSelectable = editor.filterObjects(function (o) {
          return (
            o.type == 'OsNode' &&
            o.ghostMode() != true &&
            (addWireForSystem ? o.getSystem() === addWireForSystem : !o.getSystem()) &&
            //Omit any nodes that are either managed or belong to a locked facet
            !o.isManagedByParent()
          )
        })

        // Include invisible nodes in the intersection test
        var intersectNodes = OsNode.getIntersectsIncludingInvisibleNodes(
          nodesSelectable,
          viewport,
          screenFraction,
          INTERSECTION_LINE_PRECISION
        )

        return intersectNodes.length > 0 ? intersectNodes[0].object : null
      }
      onPlaceCallback = function (event) {
        // @TODO: Refactor this into the hotkeys code
        var allowInteractingWithExistingNodesAndEdges = !event.ctrlKey

        //First establish the clicked Node (either existing or create new)
        //Second, using the clicked Node, draw a line if a previous node was selected

        if (debug) console.log('onPlaceCallback: start')

        var abortAfterPlacement = false
        OsEdge.facetCreatedFlag = false

        var _event = Utils.normalizeClientPositionForEvent(event)

        //If we clicked an existing node then use the existing node and delete newObject
        var screenFraction = new THREE.Vector2().fromArray(viewport.getMousePosition(_event.clientX, _event.clientY))
        var nodeClicked = allowInteractingWithExistingNodesAndEdges ? getClickedNode(screenFraction) : null

        // This alternative approach treats the node as "clicked" when within 20cm but beware this does not adjust for zoom level
        // so it does not necessarily behave intuitively. It also requires the elevation to be close too which can cause problems
        // when the click is very close on the screen but the resulting click is far away in 3D space due to an elevation difference,
        // like accidentally clicking off the edge of a roof facet, onto the ground.
        // BUT this is important in some cases where we want to snap even if the click was slightly off the node, especially when
        // the node has already snapped onto the node and the user clicks while their mouse is not actually over the node.
        // This method uses the final "snapped" position.

        if (!nodeClicked && allowInteractingWithExistingNodesAndEdges) {
          var nodeClickedWithinDistance = newObject.getNearbyNodes(SNAP_DISTANCE_TO_EXISTING_NODE)[0]
          if (nodeClickedWithinDistance) {
            nodeClicked = nodeClickedWithinDistance
          }
        }

        var commandUUID = Utils.generateCommandUUIDOrUseGlobal()

        if (!nodeClicked) {
          nodeClicked = new OsNode({
            position: newObject.position,
          })
          editor.execute(new AddObjectCommand(nodeClicked, addWireForSystem, true, commandUUID))
          nodeClicked.onDragEnd(allowInteractingWithExistingNodesAndEdges)
        }

        /////////////////////////////////////////
        // Draw Edge if previous node is set
        /////////////////////////////////////////
        var previousNode = getPreviousNode()

        // If we have re-drawn an edge that already exists, select that edge instead of creating a new edge
        var existingEdge =
          previousNode && editor.filter('type', 'OsEdge').find((e) => e.hasNodes([previousNode, nodeClicked]))

        if (existingEdge) {
          // do nothing because the edge already exists
        } else if (previousNode) {
          var lastEdgeType = previousNode.getEdges()[0]?.edgeType

          // Only re-use edge type for wires, not for other edge types otherwise it gives unexpected edge types.
          // e.g. If you start drawing from a ridge, then the next edge would also be ridge which is rarely what you want
          // and very confusing.
          var newEdgeType = SetbacksHelper.edgeTypesWire.includes(lastEdgeType) ? lastEdgeType : 'default'

          var edge = new OsEdge({
            nodes: [previousNode, nodeClicked],
            edgeType: newEdgeType,
          })
          editor.execute(new AddObjectCommand(edge, addWireForSystem, true, commandUUID))
          edge.onChange(editor)
        }

        //Regardless of the type of click and whether we drew a line, the node that
        //was used will become the previous node
        setPreviousNode(nodeClicked)

        ////////////////////////////////////////////////
        //Cleanup by clearing ghosts/temporary objects
        ////////////////////////////////////////////////

        //Handle when we clicked on the start point of a polygon

        if (!data['firstNodeInEdgeDrawingSession']) {
          data['firstNodeInEdgeDrawingSession'] = nodeClicked
          if (debug)
            console.log('onPlaceCallback: firstNodeInEdgeDrawingSession not found, setting to newObject', nodeClicked)
        } else if (newObject == data['firstNodeInEdgeDrawingSession']) {
          //we returned to the first node
          data['firstNodeInEdgeDrawingSession'] = null
          data['previousNodeInEdge'] = null
          Designer.showNotification(window.translate('New line started'))
          if (debug)
            console.log(
              'onPlaceCallback: firstNodeInEdgeDrawingSession is found, starting new line, clear previousNodeInEdge and firstNodeInEdgeDrawingSession'
            )
        }

        clearGhostObjects()

        if (!addWireForSystem) {
          OsEdge.addMissingFacetsForEdgeNetwork(editor, undefined, commandUUID)
        }

        if (OsEdge.facetCreatedFlag == true) {
          editor.controllers.AddObject.abort()
        }

        return
      }

      onMoveCallback = function (event) {
        // newObject.getEdges().forEach(function(e){
        //     e.onChange(editor)
        // })
        //
        if (editor.snappingActive && newObject.type == 'OsNode') {
          ObjectBehaviors.snap.call(newObject, editor)
        }

        newObject.refreshEdges(editor)

        // Force annotation to update
        editor.signals.objectAnnotationChanged.dispatch(newObject)
      }
    } else {
      console.log('Warning: AddObject type invalid: ' + type)
      return
    }

    // select ghost object, show drawing message in sidebar
    editor.select(newObject)

    editor.saveControllerState()

    editor.controllers.General.deactivate()

    // Disable annotation controller for all other types, but not edges because we want to see the
    // edge length as we draw it
    // However, not when terrain is present because we use a hologram which cannot give accurate edge measurements
    if (type === 'OsEdge' && !editor.getTerrain()) {
      //show annotations
    } else if (type === 'OsAnnotation') {
      //show annotations
    } else {
      //hide annotations
      editor.controllers.Annotation.deactivate()
    }

    onFinishCallback = function () {
      editor.revertControllerState()

      if (continueUntilCancelled) {
        scope.start(type, true, options)
      } else {
        // window.Designer.callUi('ToolbarDrawingTools', 'close', [true])
        // window.Designer.callUi('PanelCancelButton', 'close', [true])
      }
    }

    //Make hidden until mouse dragger picks it up, otherwise it flashes for one frame an arbitrary location
    //Dangerous for unit tests if they don't use mouse dragging because intersection tests fail when not visible!

    if (!window.TESTING) {
      newObject.visible = false
    }

    // Temporary Ghost objects during placement are always Temporary, never recorded in history
    // and always replaced when actually applied.
    // editor.execute(new AddObjectCommand(newObject))
    editor.addObject(newObject)

    scope.activate()

    //Return a cancel function, if appropriate
    return abort
  }
  this.start = start

  function finish(event) {
    if (onPlaceCallback) {
      editor.uiPauseUntilComplete(
        function () {
          editor.uiPauseUntilComplete(
            function () {
              if (Utils.iOS() && onMoveCallback) {
                onMoveCallback(event)
              }

              onPlaceCallback(event)

              onPlaceCallback = null

              if (onFinishCallback) onFinishCallback(event)
            },
            this,
            'ui',
            'ui::onPlaceCallback'
          )
        },
        this,
        'render',
        'render::onPlaceCallback'
      )
    }
  }
  this.finish = finish

  function abort() {
    data = {}

    onPlaceCallback = null
    onFinishCallback = null

    editor.revertControllerState()
    scope.deactivate()

    // window.Designer.callUi('ToolbarDrawingTools', 'close', [true])
    // if (Designer.uiRefs?.ToolbarDrawingTools?.close) {
    //   Designer.uiRefs['ToolbarDrawingTools'].close(true)
    // }
  }
  this.abort = abort

  function onMouseDown(event) {
    if (event.button == 2) {
      //cancel the action with right click
      scope.abort()
      return
    }

    if (event.button != 0) {
      return
    }

    event.preventDefault()

    updateObjectPosition(event, false)

    editor.render()

    let oldObjectType = scope.objectType
    scope.deactivate()

    // TODO: This is a hack to preserve the existing behaviour
    // If this is removed then the facet will not be selected
    // after a all corners of a facet have been placed (in Viewport).
    // The tests do catch this issue, but a better solution
    // isn't clear right now.
    if (continueUntilCancelled) {
      scope.objectType = oldObjectType
      scope.active = true
    }

    finish(event)
  }
  this.onMouseDown = onMouseDown

  function onMouseMove(event) {
    if (!newObject) return

    event.preventDefault()

    if (!newObject.visible) {
      newObject.visible = true
    }

    if (newObject.type == 'OsNode' && editor.getTerrain()) {
      // use hologram which should be super fast when terrain is present
      // but only if terrain is present because holograms break the edge length annotation
      // since the actual edge length is much longer since hologram is high above the ground
      // They also prevent detecting intersections with FacetMeshes too so avoid using them unless
      // we must when terrain is present
      updateObjectPosition(event, false, true)
    } else {
      if (window.TESTING) {
        // do not throttle during testing
        updateObjectPosition(event, false, false)
      } else {
        updateObjectPositionThrottled(event, false)
      }
    }

    if (onMoveCallback) onMoveCallback(event)

    renderViewportThrottled()
  }
  this.onMouseMove = onMouseMove

  function updateObjectPosition(event, useOverlay, useHologram) {
    // Place into the world.
    // Intersection with a facet places it on the facet.
    // No intesection places it on the ground.

    var _event = Utils.normalizeClientPositionForEvent(event)

    mousePosition.fromArray(viewport.getMousePosition(_event.clientX, _event.clientY))

    var position

    if (useHologram) {
      position = viewport.getPositionInFrontOfCamera(mousePosition, 10)
    }

    // For Hologram, always check for facetMesh intersections because they are more precise than
    // the terrain and we should always snap to a facet if possible.
    // (Also, this is currently required during testing because it calls this with useHologram=true)

    var facetMeshIntersections = viewport.getIntersects(
      mousePosition,
      editor.filterObjects((o) => o.type === 'OsFacetMesh' || (o.type === 'OsEdge' && !o.ghostMode())),
      FACET_MESH_INTERSECTION_LINE_PRECISION
    )

    if (facetMeshIntersections.length > 0) {
      // Greatly prefer an existing note to a point on an edge or a facet mesh
      // We use the FacetMesh or Edge to determine if an intersection has occurred, but then we will try to
      // upgrade that to a directly node click if possible
      var nodesToCheck = []

      facetMeshIntersections.forEach((_facetMeshIntersections) => {
        if (_facetMeshIntersections.object.vertices) {
          _facetMeshIntersections.object.vertices.forEach((n) => {
            if (!n.ghostMode()) {
              nodesToCheck.push(n)
            }
          })
        } else if (_facetMeshIntersections.object.nodes) {
          _facetMeshIntersections.object.nodes.forEach((n) => {
            if (!n.ghostMode()) {
              nodesToCheck.push(n)
            }
          })
        }
      })

      var nodeIntersections = OsNode.getIntersectsIncludingInvisibleNodes(
        nodesToCheck,
        viewport,
        mousePosition,
        INTERSECTION_LINE_PRECISION
      )

      // If we intersected a node then use its position, otherwise use the closest facet mesh intersection
      if (nodeIntersections.length !== 0) {
        position = nodeIntersections[0].object.position
      } else {
        position = facetMeshIntersections[0].point
      }
    }

    if (!position && editor.getTerrain() && editor.getTerrain().visible) {
      // Only update position if we found an intersection
      var positionFromTerrain = viewport.getIntersectionWithTerrain(mousePosition)
      if (positionFromTerrain) {
        position = positionFromTerrain
      }
    }

    if (!position && useOverlay) {
      //use overlay so it show on top of everything
      position = viewport.getIntersectionWithOverlay(mousePosition)
    }

    if (!position) {
      //place on ground
      position = viewport.getIntersectionWithGround(mousePosition)
    }

    if (!position) {
      console.log('Warning: position null in updateObjectPosition')
      position = new THREE.Vector3()
    }

    newObject.position.copy(position)
    if (editor.snappingActive && newObject.type == 'OsNode') {
      var rawPosition = newObject.position
      var positionWasSnapped = ObjectBehaviors.snap.call(newObject, editor)

      if (positionWasSnapped === true) {
        snapPositionTargetForPosition.rawPosition = rawPosition
        snapPositionTargetForPosition.snappedPosition = newObject.position
      } else {
        snapPositionTargetForPosition.rawPosition = null
        snapPositionTargetForPosition.snappedPosition = null
      }
    }
  }

  var updateObjectPositionThrottled = window.Utils.throttle(function (event) {
    updateObjectPosition.bind(scope)(event)
  })

  var renderViewportThrottled = window.Utils.throttle(function (forceClear, forceRenderEvenWhenNotActive) {
    editor.render(forceClear, forceRenderEvenWhenNotActive)
  })

  function touchstart(event) {
    event.preventDefault()

    if (!newObject) {
      console.log('touchstart: newObject not set, exit')
      return
    }

    if (newObject.refreshEdges) {
      newObject.refreshEdges(editor)
    }

    updateObjectPosition(event, false)

    if (!newObject.visible) {
      newObject.visible = true
    }

    editor.render()

    deactivate()

    finish(event)
  }

  function activate() {
    domElement.addEventListener('mousedown', onMouseDown, false)
    domElement.addEventListener('mousemove', onMouseMove, false)

    domElement.addEventListener('touchstart', touchstart, false)
    // domElement.addEventListener( 'touchend', touchend, false );
    // domElement.addEventListener( 'touchmove', touchmove, false );

    this.active = true
    outputs.activation.dispatch(this.active)
    hotkeys.attach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }
  this.activate = activate

  function deactivate() {
    domElement.removeEventListener('mousedown', onMouseDown, false)
    domElement.removeEventListener('mousemove', onMouseMove, false)

    domElement.removeEventListener('touchstart', touchstart, false)
    //domElement.removeEventListener( 'touchend', touchend, false );
    //domElement.removeEventListener( 'touchmove', touchmove, false );

    this.active = false
    this.objectType = null
    outputs.activation.dispatch(this.active)
    hotkeys.detach()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)

    clearGhostObjects()
  }
  this.deactivate = deactivate

  function getNewObject() {
    return newObject
  }
  this.getNewObject = getNewObject

  function overrides(eventKeyNormalized) {
    if (this.active && [Hotkeys.ESCAPE, Hotkeys.BACKSPACE, Hotkeys.DELETE].includes(eventKeyNormalized)) {
      return true
    } else {
      return false
    }
  }
  this.overrides = overrides

  return this
}

AddObjectController.prototype.constructor = AddObjectController
