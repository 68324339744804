/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param view
 * @param newValue number, string, boolean or object
 * @constructor
 */

var ReorderViewsCommand = function (ViewHelper, view, direction) {
  Command.call(this)

  this.type = 'ReorderViewsCommand'
  this.name = 'Reorder Views Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['viewUuid', 'direction']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.viewUuid = view.uuid
    this.direction = direction
  }
}

ReorderViewsCommand.prototype = {
  execute: function () {
    var view = this.ViewHelper.getViewByUuid(this.viewUuid)
    this.ViewHelper.moveView(view, this.direction)
  },

  undo: function () {
    var oppositeDirection = this.direction === 'down' ? 'up' : 'down'
    var view = this.ViewHelper.getViewByUuid(this.viewUuid)
    this.ViewHelper.moveView(view, oppositeDirection)
  },

  /*
  We cannot "update" because we currently only allow moving one place forward or back We would need to convert
  to storing the old/new index to allow updating.
  */
  // update: function(cmd) {
  // },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
