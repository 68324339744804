window.ModuleGridLayoutSingleTilt = [
  {
    version: '1.0',
    changelogs: ['Initial version'],
    formula: (params) => {
      const {
        isOnFacet,
        isOnTiltRacks,

        moduleWidth,
        moduleHeight,
        moduleCoordX,
        moduleCoordY,

        modulesPerCol,
        modulesPerRow,
        moduleSpacingX,
        moduleSpacingY,
        groupSpacingX,
        groupSpacingY,
        colSpacingOffset,
        rowSpacingOffset,
        computeColSpacingFunc,
        computeRowSpacingFunc,
        totalInterModuleSpaceX,
        totalInterModuleSpaceY,

        gridOriginOffset,
        groundClearance,
        xOffset,
        extraSlope,
        rotationX,
        forshortenedDistancePerPanelY,

        applyToObject,
      } = params

      let xOffsetToApply = moduleCoordY % 2 !== 0 ? xOffset : 0
      let colSpacingsToApply = computeColSpacingFunc(moduleCoordX)
      let rowSpacingsToApply = computeRowSpacingFunc(moduleCoordY)

      let xCoordRelative = colSpacingOffset - moduleCoordX
      let xAdjustment = xCoordRelative < 0 ? totalInterModuleSpaceX : 0
      if (xCoordRelative < 0) xCoordRelative += 1
      let indexWithinGroupX = xCoordRelative % modulesPerCol

      let yCoordRelative = rowSpacingOffset - moduleCoordY
      let yAdjustment = yCoordRelative < 0 ? totalInterModuleSpaceY : 0
      if (yCoordRelative < 0) yCoordRelative += 1
      let indexWithinGroupY = yCoordRelative % modulesPerRow

      let positionZ = 0
      if ((isOnFacet || isOnTiltRacks) && extraSlope > 0) {
        positionZ = Math.sin(extraSlope * THREE.Math.DEG2RAD) * (moduleHeight / 2 + indexWithinGroupY * moduleHeight)
      }
      positionZ += groundClearance

      let positionX =
        -moduleCoordX * moduleWidth +
        indexWithinGroupX * moduleSpacingX +
        -colSpacingsToApply * totalInterModuleSpaceX +
        groupSpacingX * -colSpacingsToApply +
        xOffsetToApply +
        xAdjustment

      let positionY =
        -moduleCoordY * moduleHeight +
        indexWithinGroupY * moduleSpacingY +
        -rowSpacingsToApply * totalInterModuleSpaceY +
        groupSpacingY * -rowSpacingsToApply -
        forshortenedDistancePerPanelY * indexWithinGroupY +
        yAdjustment

      const position = new THREE.Vector3(positionX, positionY, positionZ).add(gridOriginOffset)
      const rotation = new THREE.Euler(rotationX, 0, 0)

      if (applyToObject) {
        applyToObject.position.copy(position)
        applyToObject.rotation.copy(rotation)
      }

      return {
        position: position,
        rotation: rotation,
      }
    },
  },
  {
    version: '1.1',
    changelogs: ['Fix panels half-buried when applying negative tilt'],
    formula: (params) => {
      const {
        isOnFacet,
        isOnTiltRacks,

        moduleWidth,
        moduleHeight,
        moduleCoordX,
        moduleCoordY,

        modulesPerCol,
        modulesPerRow,
        moduleSpacingX,
        moduleSpacingY,
        groupSpacingX,
        groupSpacingY,
        colSpacingOffset,
        rowSpacingOffset,
        computeColSpacingFunc,
        computeRowSpacingFunc,
        totalInterModuleSpaceX,
        totalInterModuleSpaceY,

        gridOriginOffset,
        groundClearance,
        xOffset,
        extraSlope,
        rotationX,
        forshortenedDistancePerPanelY,

        applyToObject,
      } = params

      let xOffsetToApply = moduleCoordY % 2 !== 0 ? xOffset : 0
      let colSpacingsToApply = computeColSpacingFunc(moduleCoordX)
      let rowSpacingsToApply = computeRowSpacingFunc(moduleCoordY)

      let xCoordRelative = colSpacingOffset - moduleCoordX
      let xAdjustment = xCoordRelative < 0 ? totalInterModuleSpaceX : 0
      if (xCoordRelative < 0) xCoordRelative += 1
      let indexWithinGroupX = xCoordRelative % modulesPerCol

      let yCoordRelative = rowSpacingOffset - moduleCoordY
      let yAdjustment = yCoordRelative < 0 ? totalInterModuleSpaceY : 0
      if (yCoordRelative < 0) yCoordRelative += 1
      let indexWithinGroupY = yCoordRelative % modulesPerRow

      let positionZ = 0
      if ((isOnFacet || isOnTiltRacks) && Math.abs(extraSlope) > 0) {
        positionZ =
          Math.sin(Math.abs(extraSlope) * THREE.Math.DEG2RAD) * (moduleHeight / 2 + indexWithinGroupY * moduleHeight)
      }
      positionZ += groundClearance

      let positionX =
        -moduleCoordX * moduleWidth +
        indexWithinGroupX * moduleSpacingX +
        -colSpacingsToApply * totalInterModuleSpaceX +
        groupSpacingX * -colSpacingsToApply +
        xOffsetToApply +
        xAdjustment

      let positionY =
        -moduleCoordY * moduleHeight +
        indexWithinGroupY * moduleSpacingY +
        -rowSpacingsToApply * totalInterModuleSpaceY +
        groupSpacingY * -rowSpacingsToApply -
        forshortenedDistancePerPanelY * indexWithinGroupY +
        yAdjustment

      const position = new THREE.Vector3(positionX, positionY, positionZ).add(gridOriginOffset)
      const rotation = new THREE.Euler(rotationX, 0, 0)

      if (applyToObject) {
        applyToObject.position.copy(position)
        applyToObject.rotation.copy(rotation)
      }

      return {
        position: position,
        rotation: rotation,
      }
    },
  },
]
