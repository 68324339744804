/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var SetMapRotationCommand = function (newValue, oldValue, MapHelper) {
  Command.call(this)

  this.type = 'SetMapRotationCommand'
  this.name = 'Set Map Rotation Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['newValue', 'oldValue']

  this.MapHelper = MapHelper || windoow.MapHelper

  if (arguments.length) {
    this.newValue = newValue
    this.oldValue = oldValue
  }
}

SetMapRotationCommand.prototype = {
  execute: function () {
    this.MapHelper.setHeading(this.newValue)
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    this.MapHelper.setHeading(this.oldValue)
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
