class AjaxSessionToken {
  constructor({ url, nameSpace, skipNameSpaceCheck, globalSession, nameSpaceSession }) {
    this.skipNameSpaceCheck = skipNameSpaceCheck
    this.nameSpace = nameSpace
    this.url = url
    this.recordedGlobalSession = globalSession
    this.recordedNameSpaceSession = this.skipNameSpaceCheck ? undefined : nameSpaceSession
  }
}

/**
 * This module addresses two main use cases:
 *
 * 1. Cancel all in-flight pending requests.
 * 2. Cancel a specific group of in-flight pending requests.
 *
 * To achieve this, we introduce an Ajax Session that:
 *
 * - Intercepts all workspace requests to centralize control, allowing us to cancel all requests. This is implemented via ajax middleware.
 * - Introduces a two-level session counter:
 *   1. Global counter for overall control.
 *   2. Namespace counter for control within specific groups.
 * - Stores a "Session Token" with counter information for each request, leveraging the centralized control from the first point.
 */
class AjaxSession {
  SESSION_COUNTERS = {
    global: 1,
  }

  constructor() {}

  initNameSpaceCounter(nameSpace) {
    if (!nameSpace) {
      throw new Error('nameSpace is required')
    }
    if (!this.SESSION_COUNTERS[nameSpace]) {
      this.SESSION_COUNTERS[nameSpace] = 1
    }
  }

  /**
   * @param {string} url identify the ajax request
   * @param {string} nameSpace identify the namespace
   * @param {boolean} skipNameSpaceCheck - Skip namespace check
   * @param {Object} overrideOptions - Override auto populated session token values. Since middleware creates the token right before sending the request,
   * there is a chance that the session counter values are changed in between due to even loop. This is useful to prevent unexpected counter changes.
   */
  createAjaxSessionToken({ url, nameSpace, skipNameSpaceCheck }, overrideOptions = {}) {
    const { globalSession, nameSpaceSession } = overrideOptions

    if (skipNameSpaceCheck === undefined) {
      skipNameSpaceCheck = nameSpace !== undefined ? false : true
    }

    if (skipNameSpaceCheck === false && !nameSpace) {
      throw new Error('NameSpaceCheck is required but nameSpace is not provided')
    }

    if (skipNameSpaceCheck === false && !this.SESSION_COUNTERS[nameSpace]) {
      this.initNameSpaceCounter(nameSpace)
    }

    return new AjaxSessionToken({
      url,
      nameSpace,
      skipNameSpaceCheck,
      globalSession: globalSession === undefined ? this.SESSION_COUNTERS.global : globalSession,
      nameSpaceSession: nameSpaceSession === undefined ? this.SESSION_COUNTERS[nameSpace] : nameSpaceSession,
    })
  }

  resetAjaxSession() {
    this.SESSION_COUNTERS.global++
  }

  resetAjaxSessionForNameSpace(nameSpace) {
    if (!nameSpace) {
      throw new Error('nameSpace is required')
    }
    if (!this.SESSION_COUNTERS[nameSpace]) {
      this.SESSION_COUNTERS[nameSpace] = 1
    }
    this.SESSION_COUNTERS[nameSpace]++
  }

  invalidateAllPendingRequests() {
    this.resetAjaxSession()
  }

  invalidatePendingRequestsForNameSpace(nameSpace) {
    if (!nameSpace) {
      throw new Error('nameSpace is required')
    }
    if (!this.SESSION_COUNTERS[nameSpace]) {
      return
    }
    this.resetAjaxSessionForNameSpace(nameSpace)
  }

  validateAjaxSessionToken(ajaxSessionToken) {
    if (!ajaxSessionToken) {
      // this should never happen
      throw new Error('AjaxSessionToken is required')
    }
    if (ajaxSessionToken.recordedGlobalSession !== this.SESSION_COUNTERS.global) {
      return false
    }

    if (ajaxSessionToken.skipNameSpaceCheck) {
      return true
    }

    if (ajaxSessionToken.recordedNameSpaceSession !== this.SESSION_COUNTERS[ajaxSessionToken.nameSpace]) {
      return false
    }

    return true
  }
}

class Ajax {
  constructor({ ajaxSession }) {
    this.session = ajaxSession
    this.fetchRequestMiddleware = []
    this.#registerAjaxMiddleware()
    return this.fetch.bind(this)
  }

  #registerAjaxMiddleware = () => {
    this.fetchRequestMiddleware.push(this.#jqueryAjaxSessionMiddleware)
  }

  #jqueryAjaxSessionMiddleware = (jQueryAjaxParams, options) => {
    const sessionTokenConfig = options.sessionTokenConfig || {}
    const ajaxSessionToken = this.session.createAjaxSessionToken({ ...sessionTokenConfig, url: jQueryAjaxParams.url })
    const checkSessionTokenBound = this.session.validateAjaxSessionToken.bind(this.session)
    function onAjaxSuccess() {
      const callerContext = this
      if (!checkSessionTokenBound(ajaxSessionToken)) {
        if (window.studioDebug) {
          console.log('******** request aborted token: ', ajaxSessionToken)
        }
        return
      }
      jQueryAjaxParams.success?.apply(callerContext, arguments)
    }

    function onAjaxFailed() {
      const callerContext = this
      if (!checkSessionTokenBound(ajaxSessionToken)) {
        if (window.studioDebug) {
          console.log('******** request aborted token: ', ajaxSessionToken)
        }
        return
      }
      jQueryAjaxParams.error?.apply(callerContext, arguments)
    }

    return { ...jQueryAjaxParams, success: onAjaxSuccess, error: onAjaxFailed }
  }

  /**
   * @param jQueryAjaxParams normal jquery ajax parameters
   * @param options options pass to middleware
   * @param {Object} [options.sessionTokenConfig=undefined] - AjaxSession.createAjaxSessionToken arguments
   */
  fetch = async (jQueryAjaxParams, options = {}) => {
    let requestParams = jQueryAjaxParams
    for (const middleware of this.fetchRequestMiddleware) {
      requestParams = await middleware(requestParams, options)
    }
    // Caution: DO NOT return jQuery ajax object. It will throw error when ajax on error is called.
    // There are some known issue with error handling and unexpected execution order within jQuery.ajax 2.X version.
    // It is not Promises/A+ compatible. To solve the issue, upgrade to jQuery 3.X.
    $.ajax(requestParams)
  }
}
