/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newValue number, string, boolean or object
 * @constructor
 */

//could we refactor this
var RedrawModulesCommand = function (object, funcName, newValue, oldValue) {
  Command.call(this)

  this.type = 'RedrawModulesCommand'
  this.name = 'Redraw Modules Command'
  this.timeStamp = Date.now()
  this.commandUUID = window.globalCommandUUID

  this.PROPERTIES = ['newValue', 'oldValue', 'funcName']
  this.OBJECT_REFERENCES = ['object']

  this.stringModuleReference = {}

  if (arguments.length) {
    this.object = object
    this.funcName = funcName
    this.newValue = newValue
    this.oldValue = oldValue
  }
}

RedrawModulesCommand.prototype = {
  execute: function () {
    for (var cell in this.object.moduleObjects) {
      var module = this.object.moduleObjects[cell]
      if (module.assignedOsString) {
        if (!this.stringModuleReference[module.assignedOsString.uuid]) {
          this.stringModuleReference[module.assignedOsString.uuid] = [module.uuid]
        } else if (this.stringModuleReference[module.assignedOsString.uuid].indexOf(module.uuid) === -1) {
          this.stringModuleReference[module.assignedOsString.uuid].push(module.uuid)
        }
      }
    }
    this.object[this.funcName](this.newValue)
    this.object.children
      .filter(function (a) {
        return a.children.length === 0 && a.type === 'OsModule'
      })
      .forEach(function (a) {
        a.setGeometryForSize()
      })
    this.object.refreshSetbacks()
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  undo: function () {
    this.object[this.funcName](this.oldValue)
    for (var stringUUID in this.stringModuleReference) {
      var string = this.editor.objectByUuid(stringUUID)
      this.stringModuleReference[stringUUID].forEach(function (moduleUUID) {
        var module = this.editor.objectByUuid(moduleUUID)
        string.addModule(module)
      })
    }
    this.stringModuleReference = {}
    this.object.children
      .filter(function (a) {
        return a.type === 'OsModule'
      })
      .forEach(function (a) {
        a.setGeometryForSize()
      })
    this.object.refreshSetbacks()
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
