window.pluginSolarEdge = {
  id: 4000,
  countryCodes: [], // All
  name: 'pluginSolarEdge',
  componentCodes: [
    //Optimisers
    'P300',
    'P320',
    'P340',
    'P370',
    'P400',
    'P401',
    'P404',
    'P405',
    'S440',
    'P485',
    'S500',
    'P500',
    'P505',
    'P600',
    'P605',
    'P605-4RM4MBY',
    'P650',
    'P701',
    'P730',
    'P730-4RM4MBY',
    'P800P',
    'P800P-4RM4MBY',
    'P801',
    'P801-4RM4MBY',
    'P850',
    'P850-4RM4MBY',
    'P860',
    'P950',
    'P950-4RM4MBY',
    'P960',
    'P1100',
    'P1100-4RM4MBT',
    //Inverters
    'EV SE3000H-AU000BNV4',
    'EV SE5000H-AU000BNV4',
    'ENERGY HUB SE3000H-AUS3MBX14',
    'ENERGY HUB SE5000H-AUS3MBX14',
    'ENERGY HUB SE6000H-AUS3MBX14',
    'ENERGY HUB SE8250H-AUS3MBX14',
    'ENERGY HUB SE10000H-AUS3MBX14',
    'GENESIS SE3000H-AUL00BNU4',
    'GENESIS SE5000H-AUL00BNU4',
    'GENESIS SE6000H-AUL00BNU4',
    'GENESIS SE8250H-AUL00BNU4',
    'GENESIS SE10000H-AUL00BNU4',
    'SE5K-AUBTEBEU4',
    'SE7K-AUBTEBEU4',
    'SE8.25K-AUBTEBEU4',
    'SE10K-AUBTEBEU4',
    'SE5K-AU00ENNU2',
    'SE7K-AU00ENNU2',
    'SE8K-AU00ENNU2',
    'SE15K-AU0T0BNU4',
    'SE17K-AU0T0BNU4',
    'SE30K-AU00IBNV4',
    'SE33.3K-AU00IBNV4',
    'SE82.8K-AU0P0BNU4',
    'SESU-AU0S0BNN4',
    'SE5000-AUS20NNB2(HYBRID)',
    'SE6000-AUS20NNB2 (HYBRID)',
    'SE3000-AUS20NNB2',
    'SE5000-AUS20NNB2',
    'SE6000-AUS20NNB2',
    'SE2500H-AU000BWU4',
    'SE3000H-AU000BWU4',
    'SE4000H-AU000BWU4',
    'SE5000H-AU000BWU4',
    'SE6000H-AU000BWU4',
    'SE8000H-AU000BWU4',
    'SE10000H-AU000BWU4',
    'SPV370-R60JWMG',
    'SPV375-R60JWMG',
    'SPV370-R60DWMG',
    'SPV375-R60DWMG',
    'SPV355-R60LWMG',
    'S1000',
    'S1500',
    'S2000',
    'S2500',
    'S3000',
    'S3300',
    'S700',
    'SE10000A-US [208V]',
    'SE10000A-US [240V]',
    'SE10000A-US [277V]',
    'SE10000H',
    'SE10000H [220/230V]',
    'SE10000H-AU',
    'SE10000H-US [208V]',
    'SE10000H-US [240V]',
    'SE1000M',
    'SE100K',
    'SE100KUS [480V]',
    'SE10K',
    'SE10K [480V]',
    'SE10K-RSW',
    'SE10K-RWB48',
    'SE10K-RWS',
    'SE11400A-US [240V]',
    'SE11400A-US [277V]',
    'SE11400H-US [208V]',
    'SE11400H-US [240V]',
    'SE12.5K',
    'SE120K',
    'SE14.4KUS [208V]',
    'SE1500M',
    'SE15K',
    'SE15K-AU',
    'SE16K',
    'SE17K',
    'SE17K-AU',
    'SE2000M',
    'SE20K [480V]',
    'SE2200',
    'SE2200H',
    'SE2200H-RWS',
    'SE2500H',
    'SE2500H-AU',
    'SE25K',
    'SE25K-AU',
    'SE27.6K',
    'SE27.6K-AU',
    'SE3000',
    'SE3000 [208V]',
    'SE3000 [240V]',
    'SE3000-AUS',
    'SE3000-AUS20NNB2 (Hybrid)',
    'SE3000H',
    'SE3000H (EV)',
    'SE3000H-AU',
    'SE3000H-AUL00BNU4',
    'SE3000H-RWBMNBF54',
    'SE3000H-RWS',
    'SE3000H-US [240V]',
    'SE30K',
    'SE30KUS',
    'SE30KUS [480V]',
    'SE33.3K',
    'SE33.3KUS',
    'SE33.3KUS [480V]',
    'SE3300 [208V]',
    'SE3300 [240V]',
    'SE3500',
    'SE3500H',
    'SE3500H-RWS',
    'SE3680H',
    'SE3680H (EV)',
    'SE3680H-AC-S4',
    'SE3680H-APP-S4',
    'SE3680H-RWBMNBF54',
    'SE3680H-RWS',
    'SE3800 [208V]',
    'SE3800 [240V]',
    'SE3800A-US [208V]',
    'SE3800A-US [240V] ',
    'SE3800H-US [208V]',
    'SE3800H-US [240V]',
    'SE3K',
    'SE3K-RWBTEBEN4',
    'SE4000',
    'SE4000 [208V]',
    'SE4000 [240V]',
    'SE4000H',
    'SE4000H (EV)',
    'SE4000H-APP-S4',
    'SE4000H-AU',
    'SE4000H-RWBMNBF54',
    'SE4000H-RWS',
    'SE43.2KUS [208V]',
    'SE4600',
    'SE4600H',
    'SE4K',
    'SE4K-RWBTEBEN4',
    'SE5000',
    'SE5000 [208V]',
    'SE5000 [240V]',
    'SE5000 [277V]',
    'SE5000-AUS',
    'SE5000-AUS20NNB2 (Hybrid)',
    'SE5000H',
    'SE5000H (EV)',
    'SE5000H-APP-S4',
    'SE5000H-AU',
    'SE5000H-RWBMNBF54',
    'SE5000H-RWS',
    'SE5000H-US [208V]',
    'SE5000H-US [240V]',
    'SE50K',
    'SE55K',
    'SE5K',
    'SE5K-AU',
    'SE5K-RSW',
    'SE5K-RWB48',
    'SE5K-RWBTEBEN4',
    'SE5K-RWS',
    'SE6000',
    'SE6000 [208V]',
    'SE6000 [240V]',
    'SE6000 [277V]',
    'SE6000-AUS',
    'SE6000H',
    'SE6000H (EV)',
    'SE6000H-APP-S4',
    'SE6000H-AU',
    'SE6000H-RWBMNBF54',
    'SE6000H-RWS',
    'SE6000H-US [208V]',
    'SE6000H-US [240V]',
    'SE66.6K',
    'SE66.6KUS [480V]',
    'SE6K',
    'SE7000 [277V]',
    'SE7600 [240V]',
    'SE7600A-US [208V]',
    'SE7600A-US [240V]',
    'SE7600A-US [277V]',
    'SE7600H-US [240V]',
    'SE7K',
    'SE7K-AU',
    'SE7K-RWS',
    'SE8000H',
    'SE8000H [220/230V]',
    'SE8000H-AU',
    'SE82.8K',
    'SE8250H',
    'SE8K',
    'SE8K-AU',
    'SE8K-RSW',
    'SE8K-RWB48',
    'SE8K-RWS',
    'SE90K',
    'SE9K',
    'SE9K [208V]',
  ],
  plugin: function () {
    var panelCodeToOptimiser = {
      'SPV370-R60JWMG': 'S440',
      'SPV375-R60JWMG': 'S440',
      'SPV370-R60DWMG': 'SPV370-R60DWMG Optimiser',
      'SPV375-R60DWMG': 'SPV375-R60DWMG Optimiser',
      'SPV355-R60LWMG': 'SPV355-R60LWMG Optimiser',
    }

    function upperCaseSafe(value) {
      return value && value.toUpperCase ? value.toUpperCase() : value
    }

    // var refreshDebounced = (function () {
    //   const wait = 1000
    //   const debounceQueue = {}
    //   //closure
    //   return function () {
    //     var context = this,
    //       args = arguments

    //     const systemUuid = args[0] && args[0].uuid
    //     //system is required
    //     if (!systemUuid) return
    //     var later = function () {
    //       delete debounceQueue[systemUuid]
    //       refresh.apply(context, args)
    //     }
    //     clearTimeout(debounceQueue[systemUuid])
    //     debounceQueue[systemUuid] = setTimeout(later, wait)
    //   }
    // })()
    var refreshDebouncedInner = Utils.debounce((system) => refresh(system), 1000)
    var refreshDebounced = function (system) {
      //system is required
      if (!system?.uuid) return
      refreshDebouncedInner(system)
    }

    function objectWithUpperCaseKeys(object) {
      var newObject = {}
      for (var key in object) {
        newObject[upperCaseSafe(key)] = newObject[key]
      }
      return newObject
    }

    function indexOfCaseInsensitive(valuesArray, value) {
      var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))
      return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
    }

    // // First, do a check to see if there is a SolarEdge inverter
    // //
    // //   If yes, then check and see if there is a solaredge power optimiser added
    // //     If yes, then check the following values:
    // //     (1) total panel number (2) array of string length(s) (3) inverter code
    // //       If inverter code = [insert codes for Single phase inverters SE5000 or lower, Single phase HD-Wave inverter SE5000H or lower, Single phase HD-Wave Genesis inverter SE5000H or lower, Three phase Hybrid inverter SE5K-AUB and lower here] then
    // //         If there is a string which is < 8 then show message in red: “Minimum string length of 8 for [inverter code] not met for at least one string in this system”
    // //         Else if there is a string that is > 25 then give red warning message: “Maximum string length of 25 for [inverter code] exceeded for at least one string in this system”
    // //         If there is between 8 and 25 panels, then show message that it can be designed in one string
    // //       If no, then skip
    // //
    // //     If no, then show the following warning message in red:
    // //       “A SolarEdge optimiser must be selected for this system as it is using SolarEdge inverters.”
    // //   If no, then skip plugin
    //

    //Check for whether there is a solaredge inverter used with a battery (not LGES so there isn't a double up)
    //If yes, then ensure that a meter is added (unless it is Energy Hub) and ensure that there is a SESTI-S4 added.

    var exhibitInverterCodes = {
      singleString: [
        // insert codes for Single phase inverters SE5000 or lower, Single phase HD-Wave
        //inverter SE5000H or lower, Single phase HD-Wave Genesis inverter SE5000H or lower,
        //Three phase Hybrid inverter SE5K-AUB and lower
        'EV SE3000H-AU000BNV4',
        'EV SE5000H-AU000BNV4',
        'Energy Hub SE3000H-AUS3MBX14',
        'Energy Hub SE5000H-AUS3MBX14',
        'Genesis SE3000H-AUL00BNU4',
        'Genesis SE5000H-AUL00BNU4',
        'SE5K-AUBTEBEU4',
        'SE5000-AUS20NNB2(Hybrid)',
        'SE3000-AUS20NNB2',
        'SE5000-AUS20NNB2',
        'SE2500H-AU000BWU4',
        'SE3000H-AU000BWU4',
        'SE4000H-AU000BWU4',
        'SE5000H-AU000BWU4',
      ],

      multiString: [
        'Energy Hub SE6000H-AUS3MBX14',
        'Energy Hub SE8250H-AUS3MBX14',
        'Energy Hub SE10000H-AUS3MBX14',
        'Genesis SE6000H-AUL00BNU4',
        'Genesis SE8250H-AUL00BNU4',
        'Genesis SE10000H-AUL00BNU4',
        'SE7K-AUBTEBEU4',
        'SE8.25K-AUBTEBEU4',
        'SE10K-AUBTEBEU4',
        'SE7K-AU00ENNU2',
        'SE8K-AU00ENNU2',
        'SE15K-AU0T0BNU4',
        'SE17K-AU0T0BNU4',
        'SE30K-AU00IBNV4',
        'SE33.3K-AU00IBNV4',
        'SE82.8K-AU0P0BNU4',
        'SESU-AU0S0BNN4',
        'SE6000-AUS20NNB2 (Hybrid)',
        'SE6000-AUS20NNB2',
        'SE6000H-AU000BWU4',
        'SE8000H-AU000BWU4',
        'SE10000H-AU000BWU4',
      ],
      otherInverters: [
        'S1000',
        'S1500',
        'S2000',
        'S2500',
        'S3000',
        'S3300',
        'S700',
        'SE10000A-US [208V]',
        'SE10000A-US [240V]',
        'SE10000A-US [277V]',
        'SE10000H',
        'SE10000H [220/230V]',
        'SE10000H-AU',
        'SE10000H-US [208V]',
        'SE10000H-US [240V]',
        'SE1000M',
        'SE100K',
        'SE100KUS [480V]',
        'SE10K',
        'SE10K [480V]',
        'SE10K-RSW',
        'SE10K-RWB48',
        'SE10K-RWS',
        'SE11400A-US [240V]',
        'SE11400A-US [277V]',
        'SE11400H-US [208V]',
        'SE11400H-US [240V]',
        'SE12.5K',
        'SE120K',
        'SE14.4KUS [208V]',
        'SE1500M',
        'SE15K',
        'SE15K-AU',
        'SE16K',
        'SE17K',
        'SE17K-AU',
        'SE2000M',
        'SE20K [480V]',
        'SE2200',
        'SE2200H',
        'SE2200H-RWS',
        'SE2500H',
        'SE2500H-AU',
        'SE25K',
        'SE25K-AU',
        'SE27.6K',
        'SE27.6K-AU',
        'SE3000',
        'SE3000 [208V]',
        'SE3000 [240V]',
        'SE3000-AUS',
        'SE3000-AUS20NNB2 (Hybrid)',
        'SE3000H',
        'SE3000H (EV)',
        'SE3000H-AU',
        'SE3000H-AUL00BNU4',
        'SE3000H-RWBMNBF54',
        'SE3000H-RWS',
        'SE3000H-US [240V]',
        'SE30K',
        'SE30KUS',
        'SE30KUS [480V]',
        'SE33.3K',
        'SE33.3KUS',
        'SE33.3KUS [480V]',
        'SE3300 [208V]',
        'SE3300 [240V]',
        'SE3500',
        'SE3500H',
        'SE3500H-RWS',
        'SE3680H',
        'SE3680H (EV)',
        'SE3680H-AC-S4',
        'SE3680H-APP-S4',
        'SE3680H-RWBMNBF54',
        'SE3680H-RWS',
        'SE3800 [208V]',
        'SE3800 [240V]',
        'SE3800A-US [208V]',
        'SE3800A-US [240V] ',
        'SE3800H-US [208V]',
        'SE3800H-US [240V]',
        'SE3K',
        'SE3K-RWBTEBEN4',
        'SE4000',
        'SE4000 [208V]',
        'SE4000 [240V]',
        'SE4000H',
        'SE4000H (EV)',
        'SE4000H-APP-S4',
        'SE4000H-AU',
        'SE4000H-RWBMNBF54',
        'SE4000H-RWS',
        'SE43.2KUS [208V]',
        'SE4600',
        'SE4600H',
        'SE4K',
        'SE4K-RWBTEBEN4',
        'SE5000',
        'SE5000 [208V]',
        'SE5000 [240V]',
        'SE5000 [277V]',
        'SE5000-AUS',
        'SE5000-AUS20NNB2 (Hybrid)',
        'SE5000H',
        'SE5000H (EV)',
        'SE5000H-APP-S4',
        'SE5000H-AU',
        'SE5000H-RWBMNBF54',
        'SE5000H-RWS',
        'SE5000H-US [208V]',
        'SE5000H-US [240V]',
        'SE50K',
        'SE55K',
        'SE5K',
        'SE5K-AU',
        'SE5K-RSW',
        'SE5K-RWB48',
        'SE5K-RWBTEBEN4',
        'SE5K-RWS',
        'SE6000',
        'SE6000 [208V]',
        'SE6000 [240V]',
        'SE6000 [277V]',
        'SE6000-AUS',
        'SE6000H',
        'SE6000H (EV)',
        'SE6000H-APP-S4',
        'SE6000H-AU',
        'SE6000H-RWBMNBF54',
        'SE6000H-RWS',
        'SE6000H-US [208V]',
        'SE6000H-US [240V]',
        'SE66.6K',
        'SE66.6KUS [480V]',
        'SE6K',
        'SE7000 [277V]',
        'SE7600 [240V]',
        'SE7600A-US [208V]',
        'SE7600A-US [240V]',
        'SE7600A-US [277V]',
        'SE7600H-US [240V]',
        'SE7K',
        'SE7K-AU',
        'SE7K-RWS',
        'SE8000H',
        'SE8000H [220/230V]',
        'SE8000H-AU',
        'SE82.8K',
        'SE8250H',
        'SE8K',
        'SE8K-AU',
        'SE8K-RSW',
        'SE8K-RWB48',
        'SE8K-RWS',
        'SE90K',
        'SE9K',
        'SE9K [208V]',
      ],
    }

    exhibitInverterCodes.all = [
      ...exhibitInverterCodes.singleString,
      ...exhibitInverterCodes.multiString,
      ...exhibitInverterCodes.otherInverters,
    ]

    var exhibitOptimiserCodes = [
      'P300',
      'P320',
      'P340',
      'P370',
      'P400',
      'P401',
      'P404',
      'P405',
      'S440',
      'P485',
      'S500',
      'S500B',
      'P500',
      'P505',
      'P600',
      'P605',
      'P605-4RM4MBY',
      'P650',
      'P701',
      'P730',
      'P730-4RM4MBY',
      'P800p',
      'P800p-4RM4MBY',
      'P801',
      'P801-4RM4MBY',
      'P850',
      'P850-4RM4MBY',
      'P860',
      'P950',
      'P950-4RM4MBY',
      'P960',
      'P1100',
      'P1100-4RM4MBT',
    ]

    var integratedOptimiserCodes = [
      'SE-P370-i',
      'SE-S440-i',
      'SPV345-R60LBMG',
      'SPV350-R60LBMG',
      'SPV355-R60DBMG',
      'SPV355-R60LBMG',
      'SPV355-R60LWMG',
      'SPV360-R60DBMG',
      'SPV360-R60LWMG',
      'SPV365-R60LWMG',
      'SPV370-R60DWMG',
      'SPV375-R60DWMG',
    ]

    // function updateErrorsBatterySystem(system, isActive) {
    //   var message
    //   var energyHubInverters = ['Energy Hub SE3000H-AUS3MBX14',
    //   'Energy Hub SE5000H-AUS3MBX14', 'Energy Hub SE6000H-AUS3MBX14',
    //   'Energy Hub SE8250H-AUS3MBX14',
    //   'Energy Hub SE10000H-AUS3MBX14']
    //   if (system.batteries().length > 0 && isActive) {
    //     if (systemHasComponent(system, exhibitInverterCodes.all)) {
    //       if (!systemHasComponent(system, 'SESTI-S4' )) {
    //         message = 'A SolarEdge system with a battery must have a combiner box (SESTI-S4).'
    //           if (!systemHasComponent(system, energyHubInverters)) {
    //             if (){}
    //         }
    //       }
    //     }
    //   }
    //   var messageKey = 'SOLAREDGE-BATTERY-CHECK'
    //   return setSystemMessage(system, message, messageKey, 'warning')
    // }
    function updateErrorsOptimiser(system, isActive) {
      var message

      if (system.inverters().length > 0 && isActive) {
        if (systemHasComponent(system, exhibitInverterCodes.all)) {
          if (
            !systemHasComponent(system, exhibitOptimiserCodes) &&
            !systemHasComponent(system, integratedOptimiserCodes)
          ) {
            message = 'A SolarEdge optimiser must be selected for this system as it is using SolarEdge inverters.'
          }
        }
      }
      var messageKey = 'SOLAREDGE-OPTIMISER-CHECK'
      return setSystemMessage(system, message, messageKey, 'warning', ['module', 'inverter'], {
        suppresses: [
          'STRING-INVERTER-MAX-VOC-EXCEED',
          'STRING-INVERTER-MIN-VMP-TOO-LOW',
          'STRING-INVERTER-MAX-VMP-EXCEED',
        ],
      })
    }

    function updateErrorsOptimiserSizing(system, isActive) {
      var message

      if (systemHasComponent(system, exhibitOptimiserCodes)) {
        if (system.maxPanelsPerOptimizer() == 0) {
          message =
            'The panel specifications exceed the limits of the the optimiser, please consider choosing a different panel, or using a larger optimiser.'
        }
      }

      var messageKey = 'SOLAREDGE-OPTIMISER-SIZING-CHECK'
      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function updateErrorsMinStringSize(system, isActive) {
      var message
      var minStringLength = 8
      if (system.inverters().length > 0 && isActive) {
        if (
          systemHasComponent(system, exhibitInverterCodes.singleString) &&
          systemHasComponent(system, exhibitOptimiserCodes)
        ) {
          system.userData.inverters.forEach(function (inverter) {
            if (upperCaseSafe(inverter.manufacturer_name).indexOf('SOLAREDGE') !== -1) {
              inverter.mppts.forEach(function (mppt) {
                mppt.strings.forEach(function (string) {
                  if (string.modules.length < minStringLength) {
                    message =
                      'Minimum string length of 8 for SolarEdge inverter not met for at least one string in this system. Please increase the length of this string.'
                  } //else if
                  //if (//system.userData.moduleQuantity < 8 OR there is a string which is < 8 then show message) {
                  //  messageError = 'Minimum string length of 8 for SolarEdge inverter not met for at least one string in this system'
                  //} else if (there is a string that is >25) {
                  //  messageError = 'Maximum string length of 25 for SolarEdge inverter exceed for at least one string in this system'
                  //}
                })
              })
            }
          })
        }
      }
      var messageKey = 'SOLAREDGE-MIN-STRING-LENGTH-CHECK'
      return setSystemMessage(system, message, messageKey, 'warning', 'inverter')
    }

    function updateErrorsMaxStringSize(system, isActive) {
      var message
      var maxStringLength = 25
      if (system.inverters().length > 0 && isActive) {
        if (
          systemHasComponent(system, exhibitInverterCodes.singleString) &&
          systemHasComponent(system, exhibitOptimiserCodes)
        ) {
          system.userData.inverters.forEach(function (inverter) {
            if (upperCaseSafe(inverter.manufacturer_name).indexOf('SOLAREDGE') !== -1) {
              inverter.mppts.forEach(function (mppt) {
                mppt.strings.forEach(function (string) {
                  if (string.modules.length > maxStringLength) {
                    message =
                      'Maximum string length of 25 for SolarEdge inverter exceeded for at least one string in this system. Please reduce the length of this string.'
                  } //else if
                  //if (//system.userData.moduleQuantity < 8 OR there is a string which is < 8 then show message) {
                  //  messageError = 'Minimum string length of 8 for SolarEdge inverter not met for at least one string in this system'
                  //} else if (there is a string that is >25) {
                  //  messageError = 'Maximum string length of 25 for SolarEdge inverter exceed for at least one string in this system'
                  //}
                })
              })
            }
          })
        }
      }
      var messageKey = 'SOLAREDGE-MAX-STRING-LENGTH-CHECK'
      return setSystemMessage(system, message, messageKey, 'warning', 'inverter')
    }

    function updateMessageSingleString(system, isActive) {
      var message
      if (system.inverters().length > 0 && isActive) {
        if (
          systemHasComponent(system, exhibitInverterCodes.singleString) &&
          systemHasComponent(system, exhibitOptimiserCodes)
        ) {
          if (system.userData.moduleQuantity <= 25 && system.userData.moduleQuantity >= 8) {
            message = 'This SolarEdge system is capable of being designed with a single string.'
          }
        }
        //} else if (//system.userData.moduleQuantity > 25 total number panels is > 25) {
        //  messageWarning = 'System must be designed with more than one string'
      }
      var messageKey = 'SOLAREDGE-SINGLE-STRING-MESSAGE'
      return setSystemMessage(system, message, messageKey, 'warning', 'inverter')
    }

    function refresh(system) {
      if (window.WorkspaceHelper.getIsProjectLite()) {
        // Don't run on Lite projects
        return
      }

      for (var panelCode in panelCodeToOptimiser) {
        // Add micro if not enabled (quantity updates automatically so we don't need to set quantity in plugin)
        if (system.moduleQuantity() > 0 && systemHasComponent(system, panelCode)) {
          var optimiserCode = panelCodeToOptimiser[panelCode]
          syncOptimisers(system, optimiserCode)
          break
        }
      }
      if (system.isDuplicating) {
        // skip refresh during duplication because we do not want to trigger signals
        // for each child added to the system
        return
      }

      if (system) {
        // If no SolarEdge inverters are applied then forcefully clear system messages
        // This is necessary because a system may be refreshed on pluginUnload which no longer has any SolarEdge
        // inverters
        var isActive = systemHasComponent(system, exhibitInverterCodes.all)

        updateErrorsOptimiser(system, isActive)
        updateErrorsOptimiserSizing(system, isActive)
        updateErrorsMinStringSize(system, isActive)
        updateErrorsMaxStringSize(system, isActive)
        updateMessageSingleString(system, isActive)
      }
    }

    function getOptimiserComponentForCode(code) {
      var codeUpperCase = upperCaseSafe(code)
      return window.AccountHelper.getComponentDcOptimizerSpecsAvailable().filter(
        (component) => upperCaseSafe(component.code) === codeUpperCase
      )[0]
    }

    function syncOptimisers(system, optimiserCode) {
      let optimiserComponent = [optimiserCode]
      if (!systemHasComponent(system, optimiserCode)) {
        let componentType = getOptimiserComponentForCode(optimiserCode)
        if (componentType) {
          window.editor.execute(
            new window.AddObjectCommand(
              new window.OsOther({
                other_id: componentType.id,
                quantity: system.moduleQuantity(),
              }),
              system,
              false
            )
          )
          Designer.showNotification('Integrated optimiser added: ' + optimiserCode)

          console.log(system, system.moduleType(), system.moduleType()?.cost)
          console.log(componentType, componentType?.cost)

          var acPanelHasCogs = system.moduleType()?.cost > 0
          var optimiserHasCogs = componentType?.cost > 0
          if (acPanelHasCogs && optimiserHasCogs) {
            system.setComponentCostOverride(componentType, 0)
            Designer.showNotification(
              'COGS override of $0 has been applied for integrated optimisers to avoid double-counting.'
            )
          }
        } else {
          console.warn('Matching optimiser not enabled on org. Prompt to enable...')

          if (
            !window.AccountHelper.activateComponentsRequests.some(
              (componentRequest) => componentRequest.message && componentRequest.message.indexOf('SOLAREDGE') !== -1
            )
          ) {
            var callback = () => {
              //Automatically add component to selected system
              let componentType = getOptimiserComponentForCode(optimiserCode)
              if (!systemHasComponent(system, optimiserCode)) {
                //@TODO: Handle undo/redo?
                window.editor.execute(
                  new window.AddObjectCommand(
                    new window.OsOther({
                      other_id: componentType.id,
                      quantity: system.moduleQuantity(),
                    }),
                    system,
                    false
                  )
                )
                Designer.showNotification('Integrated optimiser added: ' + optimiserCode)
              }
            }
            //Automatically activate the components when panel is added
            window.AccountHelper.debouncedActivateComponents(optimiserComponent, callback)
          }

          // Force System panel to refresh
          window.editor.signals.sceneGraphChanged.dispatch()
        }
      }
    }

    function systemHasComponent(system, codes) {
      return countComponentsForSystem(system, codes.constructor === Array ? codes : [codes]) > 0
    }
    function countComponentsForSystem(system, codes) {
      return codesForSystem(system).filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
    }
    function codesForSystem(system) {
      return [system.moduleType().code].concat(
        system.inverters().map((i) => i.code),
        system.batteries().map((b) => b.code),
        system.others().map((o) => o.code)
      )
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refreshDebounced(system)
        }
      }
    }

    function objectAdded(object) {
      if (window.studioDebug) {
        console.log('SolarEdge objectAdded', object)
      }
      refreshSystemFromObject(object)
    }

    function objectChanged(object) {
      if (window.studioDebug) {
        console.log('SolarEdge objectChanged', object)
      }
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (window.studioDebug) {
        console.log('SolarEdge objectRemoved', object)
      }
      if (object?.type === 'OsSystem') {
        const filter = (error) => error.systemId === object.uuid
        window.WorkspaceHelper?.clearProjectError(filter)
      } else {
        refreshSystemFromObject(object)
      }
    }

    function sceneGraphChanged(system) {
      if (window.studioDebug) {
        console.log('SolarEdge sceneGraphChanged')
      }
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      if (window.studioDebug) {
        console.log('SolarEdge pluginLoaded')
      }
      refreshAllSystems()
    }

    function pluginUnloaded() {
      if (window.studioDebug) {
        console.log('SolarEdge pluginUnloaded')
      }
      refreshAllSystems()
    }

    function setSystemMessage(system, message, messageKey, messageType, category = 'system') {
      const severity = messageType || 'warning'
      if (message) {
        window.WorkspaceHelper?.addProjectErrorToReduxStore({
          message,
          key: messageKey,
          severity,
          systemId: system.uuid,
          source: 'plugin',
          category,
        })
      } else {
        window.WorkspaceHelper?.removeProjectErrorFromReduxStore(messageKey, system.uuid, 'plugin')
      }
    }

    return {
      objectAdded,
      objectChanged,
      objectRemoved,
      sceneGraphChanged,
      liteProjectUpgraded,
      pluginLoaded,
      pluginUnloaded,
    }
  },
}
