/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var SetTransformModeCommand = function(newValue, oldValue) {
  Command.call(this)
  this.timeStamp = Date.now()
  this.type = 'SetTransformModeCommand'
  this.name = 'Set Transform Mode Command'
  this.newValue = newValue
  this.oldValue = oldValue
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()
}

SetTransformModeCommand.prototype = {
  execute: function() {
    window.editor.signals.transformModeChanged.dispatch(this.newValue)
    this.editor.signals.objectChanged.dispatch(this.editor.selected)
  },

  undo: function() {
    window.editor.signals.transformModeChanged.dispatch(this.oldValue)
    this.editor.signals.objectChanged.dispatch(this.editor.selected)
  },

  toJSON: function() {},

  fromJSON: function(json) {},
}
