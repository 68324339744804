/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var RemoveViewCommand = function (viewUuid, ViewHelper, commandUUID, overrideAutoSelectNewView) {
  Command.call(this)

  this.type = 'RemoveViewCommand'
  this.name = 'Remove View Command'
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['deleteViewIndex']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.viewUuid = viewUuid
    this.overrideAutoSelectNewView = overrideAutoSelectNewView
  }
}

RemoveViewCommand.prototype = {
  execute: function () {
    var selectedView = undefined
    this.ViewHelper.views.forEach(
      function (view, index) {
        if ((this.viewUuid || this.deletedViewInstance?.uuid) === view.uuid) {
          selectedView = view
          this.deleteViewIndex = index
        }
      }.bind(this)
    )

    if (!selectedView) {
      throw new Error('No view found to remove')
    }

    this.deletedViewData = selectedView.toObject()

    // Reference which can be resued in undo if populated, otherwise it must be recreated from deletedViewData
    this.deletedViewInstance = this.ViewHelper.deleteView(
      selectedView,
      this.editor,
      true,
      this.overrideAutoSelectNewView
    )
  },

  undo: function () {
    if (!this.deletedViewInstance) {
      this.deletedViewInstance = new ViewInstance(json.deletedViewData)
    }

    this.ViewHelper.addView(this.deletedViewInstance, true, this.deleteViewIndex)
    if (this.overrideAutoSelectNewView !== false) {
      this.ViewHelper.selectViewByUUID(this.deletedViewInstance.uuid)
    }
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    if (this.deletedViewInstance) output.deletedViewData = this.deletedViewInstance.toObject()
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.deletedViewInstance = new ViewInstance(json.deletedViewData)
  },
}
