/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param cmdArray array containing command objects
 * @constructor
 */

var RemoveNodeCommand = function (object, deletedByUserAction, commandUUID) {
  Command.call(this)

  this.type = 'RemoveNodeCommand'
  this.name = 'Remove Node Command'
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  if (arguments.length) {
    this.object = object
    this.deletedByUserAction = deletedByUserAction
    this.cmdArray = [new RemoveObjectCommand(object, false, undefined, undefined, true)]
  }
}

RemoveNodeCommand.prototype = {
  execute: function () {
    this.editor.signals.sceneGraphChanged.active = false
    if (!editor.changingHistory) {
      this.object.edges.forEach(
        function (edge) {
          this.cmdArray.push(new RemoveObjectCommand(edge, false))
        }.bind(this)
      )
      this.object.facets.forEach(
        function (facet) {
          this.cmdArray.push(new RemoveObjectCommand(facet, false))
        }.bind(this)
      )
    }
    for (var i = 0; i < this.cmdArray.length; i++) {
      this.cmdArray[i].execute()
    }

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    this.editor.signals.sceneGraphChanged.active = false

    for (var i = 0; i < this.cmdArray.length; i++) {
      this.cmdArray[i].undo()
    }

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)

    var cmds = []
    for (var i = 0; i < this.cmdArray.length; i++) {
      cmds.push(this.cmdArray[i].toJSON())
    }
    output.cmds = cmds
    output.deletedByUserAction = this.deletedByUserAction

    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)

    for (var i = 0; i < json.cmds.length; i++) {
      var cmd = new window[json.cmds[i].type]() // creates a new object of type "json.type"
      cmd.fromJSON(json.cmds[i])
      this.cmdArray.push(cmd)
    }
    this.deletedByUserAction = json.deletedByUserAction
  },
}
