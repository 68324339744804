/**
 * @author mrdoob / http://mrdoob.com/
 */

var Loader = function (editor) {
  var scope = this
  var signals = editor.signals

  this.texturePath = ''

  this.loadFile = function (file, showNotification) {
    var filename = file.name
    var extension = filename.split('.').pop().toLowerCase()

    var reader = new FileReader()
    reader.addEventListener('progress', function (event) {
      var size = '(' + Math.floor(event.total / 1000).format() + ' KB)'
      var progress = Math.floor((event.loaded / event.total) * 100) + '%'
      console.log('Loading', filename, size, progress)
    })

    switch (extension) {
      case 'hor':
        reader.addEventListener(
          'load',
          function (event) {
            var { horizon, loadingIssues } = SceneHelper.loadHorizonDataFromFileContents(event.target.result)

            if (loadingIssues === 'error') {
              showNotification(
                'Horizon file format not recognised. Please see HelpCenter article for more information.',
                'danger'
              )
            } else if (loadingIssues === 'missingValues') {
              editor.scene.horizon = horizon
              SceneHelper.setupHorizon(filename)
              showNotification('Horizon file uploaded successfully: missing values were assumed to be 0.', 'info')
            } else {
              editor.scene.horizon = horizon
              SceneHelper.setupHorizon(filename)
              showNotification('Horizon file uploaded successfully', 'info')
            }
          },
          false
        )
        reader.readAsText(file)
        break
      case 'jpg':
      case 'jpeg':
      case 'png':
      case 'gif':
        reader.addEventListener(
          'load',
          function (event) {
            var dataURL = event.target.result

            var imageToDataUriAtQuality = function (image, quality) {
              var qualitySettings = {
                high: {
                  maxSize: 10000, //effectively no size limit
                  jpegQuality: 1.0, //no compression
                },
                medium: {
                  maxSize: 1000,
                  jpegQuality: 0.95, //minimal compression
                },
                low: {
                  maxSize: 600, //significantly smaller
                  jpegQuality: 0.8, //significant compression
                },
                tiny: {
                  maxSize: 500, //very small
                  jpegQuality: 0.7, //heavy compression
                },
              }

              var qualitySetting = qualitySettings[quality]

              var canvas = document.createElement('canvas')
              var targetScale
              if (image.naturalWidth > image.naturalHeight && image.naturalWidth > qualitySetting.maxSize) {
                //landscape
                targetScale = Math.max(1.0, qualitySetting.maxSize / image.naturalWidth)
              } else if (image.naturalHeight > image.naturalWidth && image.naturalHeight > qualitySetting.maxSize) {
                //portrait
                targetScale = Math.max(1.0, qualitySetting.maxSize / image.naturalHeight)
              } else {
                targetScale = 1.0
              }
              canvas.width = targetScale * image.naturalWidth
              canvas.height = targetScale * image.naturalHeight
              var ctx = canvas.getContext('2d')
              ctx.imageSmoothingQuality = 'high'
              ctx.drawImage(image, 0, 0, image.naturalWidth, image.naturalHeight, 0, 0, canvas.width, canvas.height)

              // Get compressed image as a Data URI

              // Alternative which tries to use PNG if filesize is lower but will require significant CPU
              // e.g. plansets may have lower filesize and higher quality in PNG.
              // var imageDataUriAsJpeg = canvas.toDataURL('image/jpeg', qualitySetting.jpegQuality)
              // var imageDataUriAsPng = canvas.toDataURL('image/png')
              // console.log('png', imageDataUriAsPng.length, 'jpg', imageDataUriAsJpeg.length)
              //
              // //Use PNG if smaller than JPEG, otherwise use JPEG
              // var imageDataUri =
              //   imageDataUriAsPng.length < imageDataUriAsJpeg.length ? imageDataUriAsPng : imageDataUriAsJpeg

              var imageDataUri = canvas.toDataURL('image/jpeg', qualitySetting.jpegQuality)

              window.studioDebug &&
                console.log('Image quality: ' + quality + ', ' + imageDataUri.length + ' bytes', qualitySetting)

              return imageDataUri
            }

            var img = new Image()
            img.onload = function () {
              var maxFileSize = 1024 * 1000

              var tmpImageDataUri = this.src

              //Reduce quality if raw image is too large
              if (tmpImageDataUri.length > maxFileSize) {
                tmpImageDataUri = imageToDataUriAtQuality(this, 'high')
              }
              //Reduce quality if too large
              if (tmpImageDataUri.length > maxFileSize) {
                tmpImageDataUri = imageToDataUriAtQuality(this, 'medium')
              }
              //Reduce quality if still too large
              if (tmpImageDataUri.length > maxFileSize) {
                tmpImageDataUri = imageToDataUriAtQuality(this, 'low')
              }
              //Reduce quality if still too large
              if (tmpImageDataUri.length > maxFileSize) {
                tmpImageDataUri = imageToDataUriAtQuality(this, 'tiny')
              }
              //Fail if still too large with smallest setting
              if (tmpImageDataUri.length > maxFileSize) {
                var message =
                  'Image too large, compression was unsuccessful (' +
                  tmpImageDataUri.length +
                  ' bytes after compression). Please try cropping, compressing or reformatting the image.'

                if (showNotification) {
                  showNotification(message, 'danger')
                  return
                } else {
                  throw new Error(message)
                }
              }

              window.editor.signals.customImageryUploaded.dispatch(
                img,
                tmpImageDataUri,
                window.MapData?.isManual(window.MapHelper?.activeMapInstance?.mapData)
              )
            }
            img.src = dataURL
          },
          false
        )

        reader.readAsDataURL(file)

        break

      case 'tif':
        reader.addEventListener(
          'load',
          function (event) {
            Utils.handleGeotiffContents(event.target.result, ViewHelper.addGeotiffView)
          },
          false
        )

        reader.readAsArrayBuffer(file)

        break

      case 'amf':
        reader.addEventListener(
          'load',
          function (event) {
            var loader = new THREE.AMFLoader()
            var amfobject = loader.parse(event.target.result)

            editor.execute(new AddObjectCommand(amfobject))
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'awd':
        reader.addEventListener(
          'load',
          function (event) {
            var loader = new THREE.AWDLoader()
            var scene = loader.parse(event.target.result)

            editor.execute(new SetSceneCommand(scene))
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'babylon':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result
            var json = JSON.parse(contents)

            var loader = new THREE.BabylonLoader()
            var scene = loader.parse(json)

            editor.execute(new SetSceneCommand(scene))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'babylonmeshdata':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result
            var json = JSON.parse(contents)

            var loader = new THREE.BabylonLoader()

            var geometry = loader.parseGeometry(json)
            var material = new THREE.MeshStandardMaterial()

            var mesh = new THREE.Mesh(geometry, material)
            mesh.name = filename

            editor.execute(new AddObjectCommand(mesh))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'ctm':
        reader.addEventListener(
          'load',
          function (event) {
            var data = new Uint8Array(event.target.result)

            var stream = new CTM.Stream(data)
            stream.offset = 0

            var loader = new THREE.CTMLoader()
            loader.createModel(new CTM.File(stream), function (geometry) {
              geometry.sourceType = 'ctm'
              geometry.sourceFile = file.name

              var material = new THREE.MeshStandardMaterial()

              var mesh = new THREE.Mesh(geometry, material)
              mesh.name = filename

              editor.execute(new AddObjectCommand(mesh))
            })
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'dae':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var loader = new THREE.ColladaLoader()
            var collada = loader.parse(contents)

            collada.scene.name = filename

            editor.execute(new AddObjectCommand(collada.scene))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'fbx':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var loader = new THREE.FBXLoader()
            var object = loader.parse(contents)

            editor.execute(new AddObjectCommand(object))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'glb':
      case 'gltf':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var loader = new THREE.GLTFLoader()
            var path = ''
            loader.parse(
              contents,
              path,
              function (result) {
                result.scene.name = filename
                editor.execute(new AddObjectCommand(result.scene))
              },
              function (error) {
                console.error(error)
              }
            )
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'js':
      case 'json':

      case '3geo':
      case '3mat':
      case '3obj':
      case '3scn':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            // 2.0

            if (contents.indexOf('postMessage') !== -1) {
              var blob = new Blob([contents], { type: 'text/javascript' })
              var url = URL.createObjectURL(blob)

              var worker = new Worker(url)

              worker.onmessage = function (event) {
                event.data.metadata = { version: 2 }
                handleJSON(event.data, file, filename)
              }

              worker.postMessage(Date.now())

              return
            }

            // >= 3.0

            var data

            try {
              data = JSON.parse(contents)
            } catch (error) {
              alert(error)
              return
            }

            handleJSON(data, file, filename)
          },
          false
        )
        reader.readAsText(file)

        break

      case 'kmz':
        reader.addEventListener(
          'load',
          function (event) {
            var loader = new THREE.KMZLoader()
            var collada = loader.parse(event.target.result)

            collada.scene.name = filename

            editor.execute(new AddObjectCommand(collada.scene))
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'md2':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var geometry = new THREE.MD2Loader().parse(contents)
            var material = new THREE.MeshStandardMaterial({
              morphTargets: true,
              morphNormals: true,
            })

            var mesh = new THREE.Mesh(geometry, material)
            mesh.mixer = new THREE.AnimationMixer(mesh)
            mesh.name = filename

            editor.execute(new AddObjectCommand(mesh))
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'obj':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var object = new OBJLoader().parse(contents)
            object.name = filename

            editor.execute(new AddObjectCommand(object))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'playcanvas':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result
            var json = JSON.parse(contents)

            var loader = new THREE.PlayCanvasLoader()
            var object = loader.parse(json)

            editor.execute(new AddObjectCommand(object))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'ply':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var geometry = new THREE.PLYLoader().parse(contents)
            geometry.sourceType = 'ply'
            geometry.sourceFile = file.name

            var material = new THREE.MeshStandardMaterial()

            var mesh = new THREE.Mesh(geometry, material)
            mesh.name = filename

            editor.execute(new AddObjectCommand(mesh))
          },
          false
        )
        reader.readAsArrayBuffer(file)

        break

      case 'stl':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var geometry = new THREE.STLLoader().parse(contents)
            geometry.sourceType = 'stl'
            geometry.sourceFile = file.name

            var material = new THREE.MeshStandardMaterial()

            var mesh = new THREE.Mesh(geometry, material)
            mesh.name = filename

            editor.execute(new AddObjectCommand(mesh))
          },
          false
        )

        if (reader.readAsBinaryString !== undefined) {
          reader.readAsBinaryString(file)
        } else {
          reader.readAsArrayBuffer(file)
        }

        break

      /*
			case 'utf8':

				reader.addEventListener( 'load', function ( event ) {

					var contents = event.target.result;

					var geometry = new THREE.UTF8Loader().parse( contents );
					var material = new THREE.MeshLambertMaterial();

					var mesh = new THREE.Mesh( geometry, material );

					editor.execute( new AddObjectCommand( mesh ) );

				}, false );
				reader.readAsBinaryString( file );

				break;
			*/

      case 'vtk':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var geometry = new THREE.VTKLoader().parse(contents)
            geometry.sourceType = 'vtk'
            geometry.sourceFile = file.name

            var material = new THREE.MeshStandardMaterial()

            var mesh = new THREE.Mesh(geometry, material)
            mesh.name = filename

            editor.execute(new AddObjectCommand(mesh))
          },
          false
        )
        reader.readAsText(file)

        break

      case 'wrl':
        reader.addEventListener(
          'load',
          function (event) {
            var contents = event.target.result

            var result = new THREE.VRMLLoader().parse(contents)

            editor.execute(new SetSceneCommand(result))
          },
          false
        )
        reader.readAsText(file)

        break

      default:
        alert('Unsupported file format (' + extension + ').')

        break
    }
  }

  function handleJSON(data, file, filename) {
    if (data.metadata === undefined) {
      // 2.0

      data.metadata = { type: 'Geometry' }
    }

    if (data.metadata.type === undefined) {
      // 3.0

      data.metadata.type = 'Geometry'
    }

    if (data.metadata.formatVersion !== undefined) {
      data.metadata.version = data.metadata.formatVersion
    }

    switch (data.metadata.type.toLowerCase()) {
      case 'buffergeometry':
        var loader = new THREE.BufferGeometryLoader()
        var result = loader.parse(data)

        var mesh = new THREE.Mesh(result)

        editor.execute(new AddObjectCommand(mesh))

        break

      case 'geometry':
        var loader = new THREE.JSONLoader()
        loader.setResourcePath(scope.texturePath)

        var result = loader.parse(data)

        var geometry = result.geometry
        var material

        if (result.materials !== undefined) {
          if (result.materials.length > 1) {
            material = new THREE.MultiMaterial(result.materials)
          } else {
            material = result.materials[0]
          }
        } else {
          material = new THREE.MeshStandardMaterial()
        }

        geometry.sourceType = 'ascii'
        geometry.sourceFile = file.name

        var mesh

        if (geometry.animation && geometry.animation.hierarchy) {
          mesh = new THREE.SkinnedMesh(geometry, material)
        } else {
          mesh = new THREE.Mesh(geometry, material)
        }

        mesh.name = filename

        editor.execute(new AddObjectCommand(mesh))

        break

      case 'object':
        var loader = new THREE.ObjectLoader()
        loader.setResourcePath(scope.texturePath)

        var result = loader.parse(data)

        if (result instanceof THREE.Scene) {
          editor.execute(new SetSceneCommand(result))
        } else {
          editor.execute(new AddObjectCommand(result))
        }

        break

      case 'app':
        editor.fromJSON(data)

        break
    }
  }
  this.handleJSON = handleJSON
}
