const CLUSTER_DISTANCE_THRESHOLD = 5

function OsModuleCluster(modules, plane) {
  if (modules) {
    this.modules = modules
  } else {
    this.modules = []
  }

  if (plane) {
    this.plane = plane
  }

  this.populateFromModule()

  this.getDistanceToClosestModule = function(candidateModule){
    /*
    Note this does not check individual modules, it checks the closest module grid for each module
    */
    var candidateModulePositions = candidateModule.getGrid().getModules().map(m => m.getPositionWorld())
    var clusterModulePositions = this.modules.map(m => m.getPositionWorld())
    var distances = []
    candidateModulePositions.forEach(p1 => {
      clusterModulePositions.forEach(p2 => {
        distances.push(p1.clone().sub(p2).length())
      })
    })
    return Math.min(...distances)
  }
}

OsModuleCluster.planesAlmostEqual = function (plane1, plane2, checkConstant) {
  // Not working: Orientation must be within 2 degrees (since dot product of angles must be > 0.9994)
  // var dotThreshold = 0.9994

  // Actual implementation: Which requires azmiuth to be within 3 degrees (when slope is the same)
  var dotThreshold = 0.9998396860201743

  return (
    Math.abs(plane1.normal.dot(plane2.normal)) > dotThreshold &&
    (checkConstant === false || Math.abs(plane1.constant - plane2.constant) < 0.2)
  )
}

OsModuleCluster.findMatchingCluster = function (module, clusters) {
  if (!module.getPlane2()) {
    return null
  }

  for (var i = 0; i < clusters.length; i++) {
    // Old: For tilt racks, only check orientation not the full plane equation
    // var checkConstant = module.hasTiltRack() === false

    // New: Only check orientation, never check 3D plane constant
    var checkConstant = false

    if (clusters[i].plane && OsModuleCluster.planesAlmostEqual(clusters[i].plane, module.getPlane2(), checkConstant)) {
      // match has a similar orientation/3D plane

      // check proximity
      var closestDistance = clusters[i].getDistanceToClosestModule(module)

      if(closestDistance < CLUSTER_DISTANCE_THRESHOLD){
        return clusters[i]
      }
    } else {
      // also match if orientation is the same AND the underlying scope
    }
  }

  return null
}

OsModuleCluster.addToNewOrExistingCluster = function (module, clusters) {
  //Updates clusters in-place, modifying input array
  var matchingCluster = OsModuleCluster.findMatchingCluster(module, clusters)
  if (matchingCluster) {
    matchingCluster.add(module)
  } else {
    clusters.push(new OsModuleCluster([module]))
  }
  return clusters
}

OsModuleCluster.clustersFromModules = function (modules) {
  var clusters = []

  modules.forEach(function (m) {
    OsModuleCluster.addToNewOrExistingCluster(m, clusters)
  })
  return clusters
}

OsModuleCluster.clusterFromModule = function (module) {
  return new OsModuleCluster(module)
}

OsModuleCluster.clustersToObject = function (clusters) {
  return clusters.map(function (c) {
    return c.toObject()
  })
}

OsModuleCluster.prototype = Object.assign(Object.create({}), {
  constructor: OsModuleCluster,
  populateFromModule: function () {
    if (this.plane) {
      return
    } else if (this.modules.length > 0 && this.modules[0].getPlane2()) {
      this.plane = this.modules[0].getPlane2()
    } else {
      this.plane = null
    }
  },
  add: function (module) {
    this.modules.push(module)
  },
  toObject: function () {
    return this.modules.map(function (m) {
      return { uuid: m.uuid }
    })
  },
  getChildren: function () {
    return this.modules
  },
})
