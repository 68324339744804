/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var RemoveModulesFromStringCommand = function (moduleObjects, commandUUID) {
  Command.call(this)

  this.type = 'RemoveModulesFromStringCommand'
  this.name = 'Remove Modules From String Command'
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['referenceMap']

  this.referenceMap = {}

  if (arguments.length) {
    this.moduleObjects = moduleObjects
  } else {
    this.moduleObjects = {}
  }
}

RemoveModulesFromStringCommand.prototype = {
  execute: function () {
    editor.uiPauseUntilComplete(
      function () {
        editor.uiPause('render', 'RemoveModulesFromStringCommand')

        for (var cell in this.moduleObjects) {
          var module = this.moduleObjects[cell]
          if (module.assignedOsString) {
            this.referenceMap[module.uuid] = module.assignedOsString.uuid
            module.assignedOsString.removeModule(module)
          }
        }

        editor.uiResume('render', 'RemoveModulesFromStringCommand')
      },
      this,
      'ui',
      'uiPauseLock::RemoveModulesFromStringCommand'
    )
  },

  undo: function () {
    editor.uiPauseUntilComplete(
      function () {
        editor.uiPause('render', 'RemoveModulesFromStringCommandUndo')

        for (var moduleUUID in Object.assign(this.referenceMap)) {
          var string = editor.objectByUuid(this.referenceMap[moduleUUID])
          var module = editor.objectByUuid(moduleUUID)
          string.addModule(module)
        }

        editor.uiResume('render', 'RemoveModulesFromStringCommandUndo')
      },
      this,
      'ui',
      'uiPauseLock::RemoveModulesFromStringCommandUndo'
    )
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)

    output.moduleObjectsUuids = {}
    for (var cell in this.moduleObjects) {
      output.moduleObjectsUuids[cell] = this.moduleObjects[cell].uuid
    }

    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)

    for (var cell in json.moduleObjectsUuids) {
      this.moduleObjects[cell] = this.editor.objectByUuid(json.moduleObjectsUuids[cell])
    }
  },
}
