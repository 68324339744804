function SnapshotHelperClass() {}

SnapshotHelperClass.prototype = Object.assign({
  constructor: SnapshotHelperClass,
  active: true,
  container: null,
  element: null,
  _visibility: false,
  insert: function (parent) {
    var snapshotElement = document.createElement('div')
    snapshotElement.setAttribute('id', 'snapshot-crop-marks')

    var style =
      'height: ' +
      this.size[1] +
      'px; width: ' +
      this.size[0] +
      'px; position: absolute; pointer-events: none; border: 2px dashed white; visibility:' +
      (this.visibility() ? 'visible' : 'hidden') +
      ';'
    snapshotElement.style.cssText = style

    parent.appendChild(snapshotElement)
    this.element = snapshotElement
    SnapshotHelper.resize()
    SnapshotHelper.container = parent
  },

  remove: function () {
    SnapshotHelper.container.removeChild(document.getElementById('snapshot-crop-marks'))
  },

  //@TODO: Ideally pull this from constants.js when integrated with react
  //Ideal dimensions for MyE is 704x330
  //but it can be as small as 400x330 so we use 550x300 to be somewhat conservative
  size: [550, 330],
  leftMarginPixels: 0,
  resize: function () {
    $('#snapshot-crop-marks').css({
      left: ($('#viewport').width() - this.leftMarginPixels - SnapshotHelper.size[0]) / 2 + this.leftMarginPixels,
      top: ($('#viewport').height() - SnapshotHelper.size[1]) / 2,
      width: SnapshotHelper.size[0],
      height: SnapshotHelper.size[1],
    })
  },

  html: function () {
    return (
      '<div id="snapshot-crop-marks" style="height: ' +
      this.size[1] +
      'px; width: ' +
      this.size[0] +
      'px; position: absolute; pointer-events: none; border: 2px dashed white; visibility:' +
      (this.visibility() ? 'visible' : 'hidden') +
      '"></div>'
    )
  },

  visibility: function (value, force) {
    if (typeof value === 'undefined') {
      return this._visibility
    }

    if (SnapshotHelper.active === false && force !== true) {
      //inactive. Only update visibilty if force===true
      return
    }

    if (SnapshotHelper.fadeOutIntervalIdHide) {
      clearTimeout(SnapshotHelper.fadeOutIntervalIdHide)
    }

    //Show/hide using visibility, not display:none because the element must exists
    //so we can use it's size/position when taking the snapshots.
    $('#snapshot-crop-marks').css({
      transition: '',
      opacity: 1,
      visibility: value ? 'visible' : 'hidden',
    })

    this._visibility = value
  },

  showDurationBeforeFadeOut: 1000,

  fadeOutIntervalId: null,
  fadeOutIntervalIdHide: null,

  fadeOut: function () {
    //cleanup after fade

    SnapshotHelper.fadeOutIntervalIdHide = setTimeout(function () {
      SnapshotHelper.visibility(false)
    }, SnapshotHelper.showDurationBeforeFadeOut + 1000)

    $('#snapshot-crop-marks').css({
      visibility: 'hidden',
      opacity: 0.5,
      transition: 'visibility 0s 0.5s, opacity 0.5s linear',
    })
  },
  showAndFadeOutIgnoreUntil: null,
  showAndFadeOutMinumumDelay: 1000,
  showAndFadeOut: function () {
    /*
    Super simple way to prevent unnecessary calls to showAndFadeOut.
    */
    if (!this.active) {
      return
    }

    var ts = Date.now()
    if (ts > this.showAndFadeOutIgnoreUntil) {
      this.showAndFadeOutIgnoreUntil = ts + this.showAndFadeOutMinumumDelay

      SnapshotHelper.visibility(true)

      if (SnapshotHelper.fadeOutIntervalId) {
        clearTimeout(SnapshotHelper.fadeOutIntervalId)
      }

      SnapshotHelper.fadeOutIntervalId = setTimeout(function () {
        SnapshotHelper.fadeOut()
      }, SnapshotHelper.showDurationBeforeFadeOut)
    }
  },

  cropDataUrl: function (imageDataUrl, cropX, cropY, cropWidth, cropHeight, callback) {
    var canvas1 = document.createElement('canvas')
    canvas1.width = cropWidth
    canvas1.height = cropHeight
    var ctx1 = canvas1.getContext('2d')

    var img = new Image()
    img.onload = function () {
      ctx1.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, cropWidth, cropHeight)

      var fxcanvas

      if (SnapshotHelper.useFilters) {
        try {
          fxcanvas = fx.canvas()

          // convert the image to a texture
          var texture = fxcanvas.texture(canvas1)

          // apply filters
          var tmp = fxcanvas.draw(texture)
          tmp = tmp.denoise(SnapshotHelper.denoiseParams)
          tmp = tmp.tiltShift.apply(tmp, SnapshotHelper.tiltShiftParams)
          tmp = tmp.vignette.apply(tmp, SnapshotHelper.vignetteParams)
          tmp.update()
        } catch (e) {
          console.warn('Skipping filters ' + e)

          fxcanvas = canvas1
        }
      } else {
        fxcanvas = canvas1
      }

      if (callback) {
        callback(fxcanvas.toDataURL())
      }
    }
    img.src = imageDataUrl
  },

  useFilters: false,
  tiltShiftParams: [0, 200, 300, 150, 10, 200],
  vignetteParams: [0.5, 0.5],
  denoiseParams: 80,

  snapshot: function (_callback) {
    //Step 1: Capture whole body

    var cropElementSelector = '#DesignerContainer' // #DesignerContainer or #snapshot-crop-marks

    var cropX = $(cropElementSelector).offset().left
    var cropY = $(cropElementSelector).offset().top
    var cropWidth = $(cropElementSelector).width()
    var cropHeight = $(cropElementSelector).height()

    // @TODO: Remove hack to trim top toolbar which overlaps shapshot
    if (cropElementSelector === '#DesignerContainer') {
      cropY += 7
      cropHeight -= 7
    }

    // Force snapshots to hide in case they are still visible from a previous map drag/zoom
    SnapshotHelper.visibility(false, true)

    //Solution for Google maps found here:
    //https://stackoverflow.com/questions/24046778/html2canvas-does-not-work-with-google-maps-pan

    //get transform value
    // var transform = $(".gm-style>div:first>div").css("transform");
    // if (transform) {
    //     //Only when Google Maps is found
    //     var comp = transform.split(",") //split up the transform matrix
    //     var mapleft = parseFloat(comp[4]) //get left value
    //     var maptop = parseFloat(comp[5]) //get top value
    //     $(".gm-style>div:first>div").css({ //get the map container. not sure if stable
    //         "transform": "none",
    //         "left": mapleft,
    //         "top": maptop,
    //     })
    // }

    html2canvas($('body'), {
      useCORS: true,
      onrendered: function (canvas) {
        var dataUrl = canvas.toDataURL('image/jpeg', 0.98)

        var callback = function (croppedImageDataUrl) {
          if (_callback) {
            _callback(croppedImageDataUrl)
          }

          //SnapshotHelper.visibility(true);
        }

        SnapshotHelper.cropDataUrl(dataUrl, cropX, cropY, cropWidth, cropHeight, callback)

        // $(".gm-style>div:first>div").css({
        //     left: 0,
        //     top: 0,
        //     "transform": transform
        // })
      },
    })
  },

  callbackPostToServer: function (croppedImageDataUrl) {
    console.log('Post to server...')
  },

  prepare: function (editor) {
    editor.setMode('presentation')
    Designer.createRenderer('WebGLRenderer', true, true, false, false, true)

    // Beware: If there are no renderable objects, we need an extra call to render
    // Make an extra call to render() just in case. Performance hit is minimal.
    editor.render()
  },

  finish: function (editor) {
    editor.setMode('interactive')
    Designer.createRenderer('WebGLRenderer', true, true, false, false, false)
  },
})

var SnapshotHelper = new SnapshotHelperClass()
