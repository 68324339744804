// Ensure the order of execution does not matter when loading multiple map type classes
window.MAP_TYPES = Object.assign(window.MAP_TYPES || {}, {
  Google: {
    mapType: 'Google',
    className: 'OsGoogle',
    provider: 'google',
    designMode: '2D',
    designModePriority: 9,
    library: 'google',
    isTopDown: false,
    imageryType: function (mapData, scene) {
      // We add heading to ensure that different Google oblique views from different orientations
      // are considered distinct
      return 'oblique_heading_' + String(mapData.heading)
    },
  },
})

var OsGoogle = {
  minMetersPerPixel: function () {
    return OsGoogleTop.minMetersPerPixel.bind(this)()
  },
  screenPositionToLatLon: function (x, y, screenScaleX, screenScaleY) {
    return OsGoogleTop.screenPositionToLatLon.call(this, x, y, screenScaleX, screenScaleY)
  },
  toMapData: function () {
    return new MapData({
      mapType: this.mapData.mapType,
      center: OsGoogleTop.getCenterFromMapUnderCameraRay4326.call(this),
      zoomTarget: this.mapData.zoomTarget,
      maxZoom: this.mapData.maxZoom,
      sceneOrigin: this.mapData._sceneOrigin(),
      scale: this.mapData.scale,
      heading: this.dom.getHeading(),
      oblique: null,
      pano: null,
    })
  },
  drawMap: function (mapData) {
    /*
    Static method! `this` is not a map instance
    */

    var map = new google.maps.Map(document.getElementById('mapGoogle'), {
      isFractionalZoomEnabled: true,
      draggable: this.interactive(),
      fullscreenControl: false,
      keyboardShortcuts: false,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      rotateControl: false,
      center: {
        lat: mapData.center[1],
        lng: mapData.center[0],
      },
      mapTypeId: 'satellite',
      zoom: Math.floor(mapData.zoomTarget),
      tilt: 45,
      heading: mapData.heading,
    })
    map.mapType = 'Google'

    MapHelper.manageMapIsAnimatingGoogle(map)
    return new MapInstance(mapData, makeMapProxy(new Google2DMapAdapter(map)))
  },
  forceZoomTargetOnDragEndHandler: function () {
    var zoomTargetToApply = this.mapData.zoomTarget

    // Unfortunately we need to hack this using setTimout(...,1) to ensure it happens after the bug which
    // forces the zoom level to an integer  value
    setTimeout(() => {
      if (window.studioDebug) {
        console.log('forceZoomTargetOnDragEndHandler call setZoom hack', zoomTargetToApply)
      }
      this.applyZoomAndScale(zoomTargetToApply)
    }, 1)
  },
  forceZoomTargetOnDragEndHandlerBound: null,
  forceZoomTargetOnDragEndListenerReference: null,
  forceZoomTargetOnDragEnd: function (enable) {
    // Workaround bug where dragging a Google map when zoom is non integer (e.g. 15.5) automatically snaps back
    // to an integer zoom level after drag. We handle this by manually re-applying the fractional zoom level after
    // the drag completes.
    //
    // Beware: What if we change maps somehow without removing this?
    if (!this.forceZoomTargetOnDragEndHandlerBound) {
      this.forceZoomTargetOnDragEndHandlerBound = this.forceZoomTargetOnDragEndHandler.bind(this)
    }
    if (enable) {
      this.forceZoomTargetOnDragEndListenerReference = google.maps.event.addListener(
        this.dom,
        'dragend',
        this.forceZoomTargetOnDragEndHandlerBound
      )
    } else {
      if (this.forceZoomTargetOnDragEndListenerReference) {
        google.maps.event.removeListener(this.forceZoomTargetOnDragEndListenerReference)
      }
    }
  },
  setZoom: function (value) {
    return OsGoogleTop.setZoom.bind(this)(value)
  },
  applyZoomAndScale: function (zoomTarget) {
    return OsGoogleTop.applyZoomAndScale.bind(this)(zoomTarget)
  },
  interactive: function (value) {
    if (typeof value === 'undefined') {
      return this._interactive
    }

    if (this._interactive === value) {
      return
    }

    this.dom.setOptions({
      draggable: value,
      zoomControl: false,
      scrollwheel: false,
      rotateControl: false,
    })

    this.forceZoomTargetOnDragEnd(value)
  },
  // setPrimary: "not required",
  updateSize: function () {
    // Note: This includes a hack to fix problems with size not refreshing when hidden/offscreen/etc.
    OsGoogleTop.updateSize.call(this)
  },
  setView: async function (mapData, rebuildLayers, isGoogleObliqueDetection, latLonAtScreenCenterForMap4326) {
    // If detection is in progress, do not mess with this view or the detection will be broken unless this has been
    // called as part of the detection process itself

    // Not implemented beacuse one failed oblique detection would break this viewport foreaver, instead we
    // allow any call to proceed if maxZoom is set.
    // Show warning if proceeding while detection is in progress but that should not actually happen.
    // if (this.detectGoogleObliqueMaxZoomPromise && !isGoogleObliqueDetection) {
    //   // detection is in progress but this call is not part of that detection, ignore it
    //   console.warn('Ignorning call to setView for mapType==Google, oblique detection is in progress')
    //   return
    // }

    var maxZoom
    if (isGoogleObliqueDetection) {
      maxZoom = 19
    } else if (mapData.maxZoom) {
      maxZoom = mapData.maxZoom
    } else {
      var requireCallCount

      if (!window.lastCallCount) {
        window.lastCallCount = 1
      } else {
        window.lastCallCount += 1
      }

      // console.warn(
      //   'Ignorning call to setView for mapType==Google which would require maxZoom detection because detection is already in progress, avoid queuing up many setView calls awaiting detection.'
      // )
      // return
      requireCallCount = window.lastCallCount

      if (!this.detectGoogleObliqueMaxZoomPromise) {
        // Detect maxZoom with new promise

        var location4326AsASrray = latLonAtScreenCenterForMap4326[0]
          ? latLonAtScreenCenterForMap4326
          : [latLonAtScreenCenterForMap4326.x, latLonAtScreenCenterForMap4326.y]

        this.detectGoogleObliqueMaxZoomPromise = this.detectGoogleObliqueMaxZoom(location4326AsASrray)
      }

      var maxZoomResult = await this.detectGoogleObliqueMaxZoomPromise

      mapData.maxZoom = maxZoomResult.maxZoom
      this.mapData._maxZoom(maxZoomResult.maxZoom)

      this.detectGoogleObliqueMaxZoomPromise = null

      if (requireCallCount && requireCallCount !== window.lastCallCount) {
        console.warn(
          'This (' + requireCallCount + ') is not the latest queued call (' + window.lastCallCount + '), ignore it!!'
        )
        return
      }

      if (!maxZoomResult.hasObliques) {
        var message = 'No Oblique imagery found at this location. Removing View.'
        // Check this is the selected view or just a temporary map instance being used by the code
        if (ViewHelper.views.length > 1 && ViewHelper.selectedView().mapData.mapType === 'Google') {
          ViewHelper.deleteView(null, editor)
          Designer.showNotification('No Oblique imagery found at this location. Removing View.', 'danger')
        } else {
          Designer.showNotification('No Oblique imagery found at this location.', 'info')
        }

        // Remove Google obliques from availableMapTypes at this location so the option is no longer offered
        // at this location
        window.reduxStore?.dispatch({
          type: 'DETECT_IMAGERY_REMOVE',
          payload: {
            map_type: 'Google',
            variation_name: '*',
          },
        })

        return
      }
      maxZoom = mapData.maxZoom
    }

    // By this point we are guaranteed to have maxZoom populated

    this.dom.setCenter(new google.maps.LatLng(latLonAtScreenCenterForMap4326.y, latLonAtScreenCenterForMap4326.x))

    // Note: Must set heading BEFORE setting a fractional zoom, otherwise setHeading will snap zoom to a whole number
    if (mapData.heading != null) {
      // also store this variable on the dom as a variable to avoid unneccessary calls when it is already set
      if (this.dom.__heading !== mapData.heading) {
        this.dom.setHeading(mapData.heading)
        this.dom.__heading !== mapData.heading
      }
    }

    this.applyZoomAndScale(mapData.zoomTarget)

    MapHelper.updateGoogleCopyright(this)
  },
  scaleDomElement: function (targetMapScale) {
    if (this.dom.__scale !== targetMapScale) {
      $('#mapGoogle').css('-moz-transform', 'scale(' + targetMapScale + ',' + targetMapScale + ')')
      $('#mapGoogle').css('transform', 'scale(' + targetMapScale + ',' + targetMapScale + ')')
      this.dom.__scale = targetMapScale

      // Ensure map control refreshes its size to account for scaling
      // @TODO: Debounce this?
      this.updateSize()
    }
  },
  getZoomForResolution: function (mapType, targetMetersPerPixel, lat) {
    return OsGoogleTop.getZoomForResolution.bind(this)(mapType, targetMetersPerPixel, lat)
  },
  matchScene: function (editor, targetMetersPerPixel, sceneOrigin4326, mapData, force, viewportSize) {
    return OsGoogleTop.matchScene.bind(this)(
      editor,
      targetMetersPerPixel,
      sceneOrigin4326,
      mapData,
      force,
      viewportSize
    )
  },

  ///////////// Non-Standard Instance Methods Below Here ////////////
  detectAndApplyMaxZoomInProgress: false,
  detectGoogleObliqueMaxZoom: async function (location4326) {
    var _this = this

    if (window.QUnit) {
      if (window.Mocks && window.Mocks.MOCK_detectGoogleObliqueMaxZoom) {
        return window.Mocks.MOCK_detectGoogleObliqueMaxZoom()
      } else {
        throw new Error(
          'Note: For unit tests, override this by calling Mocks.mockMapHeper() with optional maxDepth argument (defaults to 19) which will fire the callback immediately'
        )
      }
    }

    if (this.detectGoogleObliqueMaxZoomPromise) {
      console.warn(
        'Calling detectGoogleObliqueMaxZoom but detectGoogleObliqueMaxZoomPromise is set, return the original promise!'
      )
      return this.detectGoogleObliqueMaxZoomPromise
    }

    this.detectAndApplyMaxZoomInProgress = true

    // Store original mapData so we can recover after oblique detection is complete
    // Be careful to retain this.dom.maxZoom when we reset the map
    var mapDataOriginal = new MapData(JSON.parse(JSON.stringify(ViewHelper.selectedView().mapData)))

    // Beware: If detection of top-downs and obliques happens at the same time we may accidentally persist the
    // temporary dom.maxZoom value used by oblique detection into mapData which results in panels drifting off imagery
    // during dragging.
    // We now set and clear this.dom.maxZoomOverridenForObliqueDetection flag
    // to indicate that maxZoom should not be trusted because it is temporarily hard-coded for oblique detection
    if (!this.dom.maxZoomOverridenForObliqueDetection) {
      mapDataOriginal.maxZoom = this.dom.maxZoom
    }

    var mapType = 'Google'

    await loadMapsLibrary(mapType)

    var mapData = new MapData({
      mapType: 'Google',
      zoomTarget: 20,
      center: location4326,
      sceneOrigin: location4326,
      scale: 1,
      heading: 0,
      oblique: null,
      pitch: 45,
    })

    var isGoogleObliqueDetection = true

    //Must show map to load, will automatically be switched back to correct map when finished
    await MapHelper.setActiveMap(mapData, undefined, isGoogleObliqueDetection)

    this.dom.hidden = true
    $('#mapGoogle').css({ opacity: 0.2 })

    // Start hack to fix stale bounds/obliques
    // Extra delay and force resize of map element to ensure obliques are loading at the correct location
    MapHelper.refreshMapContainers('Google', 10)
    await new Promise((resolve) =>
      setTimeout(() => {
        resolve()
      }, 1000)
    )

    MapHelper.refreshMapContainers('Google', 0)
    // End hack to fix stale bounds/obliques

    this.setZoom(23)

    return new Promise(function (resolve, reject) {
      var intervalId = setInterval(function () {
        // Recover from unexpected situation by clearing the interval so we don't keep pinging the map forever
        // when it does not exist
        if (!_this || !_this.dom || !_this.dom.getZoom) {
          console.warn('Map polling could not find expected map functionality... ignoring')
          return
        }

        var zoomInteger = _this.dom.getZoom()
        var hasObliques = _this.dom.getTilt() === 45

        window.studioDebug && console.log('check if google obliques zoom has refreshed, zoom: ' + zoomInteger)

        Designer.onWindowResize()

        if (zoomInteger > 0 && zoomInteger < 23) {
          clearInterval(intervalId)
          delete _this.dom.hidden
          $('#mapGoogle').css({ opacity: '' })

          //Reinstate original map stored in current ViewHelper.views[n]
          MapHelper.setActiveMap(mapDataOriginal, undefined, isGoogleObliqueDetection).then(function () {
            resolve({ maxZoom: zoomInteger, hasObliques: hasObliques })
            _this.detectAndApplyMaxZoomInProgress = false
          })
        }
      }, 1000)
    })
  },
  getOrCreateInstance: function (startLocation4326) {
    // static method, does not require an existing mapInstance

    if (!MapHelper.mapInstances.Google) {
      MapHelper.mapInstances.Google = OsGoogle.drawMap(
        MapData.createMapData({ mapType: 'Google', center: startLocation4326 })
      )
    }

    return MapHelper.mapInstances.Google
  },

  addGoogleObliquesAfterDetection: async function (startLocation4326, showNotifications) {
    // static method, does not require an existing mapInstance

    if (showNotifications) {
      Designer.showNotification('Detecting available imagery at this location...', 'info')
    }

    var mapInstance = OsGoogle.getOrCreateInstance(startLocation4326)

    var detectedProperties = await mapInstance.detectGoogleObliqueMaxZoom(startLocation4326)

    if (detectedProperties.hasObliques) {
      console.log('Oblique imagery found, adding oblique viewports')

      window.editor.execute(
        new window.AddGoogleObliquesCommand(startLocation4326, detectedProperties.maxZoom, window.ViewHelper)
      )
    } else {
      console.log('Oblique imagery not available, not adding oblique viewports')
    }

    if (showNotifications) {
      if (detectedProperties.hasObliques) {
        Designer.showNotification('Oblique imagery added', 'info', { id: 'oblique_imagery_success' })
      } else {
        Designer.showNotification('Oblique imagery not found', 'info', { id: 'oblique_imagery_fail' })
      }
    }

    window.editor.waiting.views = false
    window.editor.signals.viewsChanged.dispatch()
    return detectedProperties.hasObliques
  },
}
