// Global Dependencies: THREE, THREE MeshLine, Viewport

// Extension Description:
// Updates MeshLines in the scene when one of the following happens:
// - A new MeshLine is created
// - The viewport size changes
// It is required to monkey-patch to detect the first case.
// We can change this later by updating the usage sites.

let hasMonkeyPatched = false

class MeshLineUpdater {
  static name = 'MeshLineUpdater'

  isActive = false

  knownMeshLines = new WeakArray()

  editorInstance

  //   #cleanup = []

  constructor(editorInstance) {
    if (!(editorInstance instanceof Editor)) {
      throw new Error('Cannot instantiate MeshLineUpdater extension: invalid Editor instance.')
    }
    this.editorInstance = editorInstance

    this.checkRendererDebounced = window.Utils.debounce(this.checkRenderer, 100)
  }

  activate = () => {
    if (this.isActive) return

    if (!hasMonkeyPatched) {
      this.hasMonkeyPatched = true
      var oldMeshLineMaterial = window.MeshLineMaterial

      const scope = this

      // Intercept MeshLineMaterial constructor so that we can correct it's scale
      window.MeshLineMaterial = function () {
        oldMeshLineMaterial.apply(this, arguments)
        scope.onMeshLineMaterialCreated(this)
      }
      window.MeshLineMaterial.prototype = oldMeshLineMaterial.prototype
    }

    this.editorInstance.signals.windowResize.add(this.onWindowResize)

    // initial call will normally come through windowResize, once renderer is ready
    // This will have no effect now, but in future use cases it will be useful
    if (this.editorInstance.viewport?.getRenderer) this.onWindowResize()

    this.isActive = true
  }

  deactivate = () => {
    if (!this.isActive) return

    this.editorInstance.signals.windowResize.remove(this.onWindowResize)

    this.isActive = false
  }

  onMeshLineMaterialCreated = (material) => {
    this.knownMeshLines.add(material)
    if (!this.rendererSize || !this.isActive) return
    material.uniforms.resolution.value.copy(this.rendererSize)
    material.uniformsNeedUpdate = true
  }
  onWindowResize = () => {
    this.checkRendererDebounced(this.editorInstance.viewport.getRenderer())
  }

  checkRenderer = (renderer) => {
    this.rendererSize = renderer.getSize(new THREE.Vector2())

    const meshLineMaterials = this.editorInstance.filter().reduce((accumulator, current) => {
      if (!current.material) return accumulator // skip
      if (Array.isArray(current.material)) {
        current.material.forEach((m) => {
          if (m.type === 'MeshLineMaterial') {
            accumulator.push(m)
          }
        })
      } else {
        if (current.material?.type === 'MeshLineMaterial') {
          accumulator.push(current.material)
        }
      }
      return accumulator
    }, [])

    meshLineMaterials.forEach((mat) => {
      mat.uniforms.resolution.value.copy(this.rendererSize)
      mat.uniformsNeedUpdate = true
    })

    this.knownMeshLines.forEach((mat) => {
      mat.uniforms.resolution.value.copy(this.rendererSize)
      mat.uniformsNeedUpdate = true
    })
  }
}

class WeakArray {
  constructor() {
    this.array = []
  }

  add = (item) => {
    this.array.push(new WeakRef(item))
  }

  forEach = (callback, skip_prune = false) => {
    let i = 0
    while (i < this.array.length) {
      const item = this.array[i].deref()
      if (item) {
        callback(item)
        i++
      } else if (skip_prune) {
        i++
      } else {
        this.array.splice(i, 1)
      }
    }
  }
}

// Safari does not support WeakRef, so we need to polyfill it
// taken from: https://github.com/jaenster/weakref-pollyfill/
// we can remove this and use that library directly once we replace gulp

/**
 * @description Simple polyfill for WeakRef
 * @author Jaenster
 */
;(function (global) {
  if (typeof global === 'object' && global && typeof global['WeakRef'] === 'undefined') {
    global.WeakRef = (function (wm) {
      function WeakRef(target) {
        wm.set(this, target)
      }

      WeakRef.prototype.deref = function () {
        return wm.get(this)
      }

      return WeakRef
    })(new WeakMap())
  }
})(
  (function () {
    switch (true) {
      case typeof globalThis === 'object' && !!globalThis:
        return globalThis
      case typeof self === 'object' && !!self:
        return self
      case typeof window === 'object' && !!window:
        return window
      case typeof global === 'object' && !!global:
        return global
      case typeof Function === 'function':
        return Function('return this')()
    }
    return null
  })()
)
