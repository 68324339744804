/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var RemoveObjectCommand = function (object, deletedByUserAction, select, commandUUID, stopOnRemove) {
  Command.call(this)

  this.type = 'RemoveObjectCommand'
  this.name = 'Remove Object Command'
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()

  if (arguments.length) {
    this.stopOnRemove = stopOnRemove
    this.timeStamp = Date.now()
    this.deletedStrings = []
    this.object = object
    this.deletedByUserAction = deletedByUserAction
    this.parent = object !== undefined ? object.parent : undefined
    if (this.parent) {
      this.index = this.parent.children.indexOf(this.object)
    }

    this.select = !(select === false) //false disables auto-selection, anything else (including empty) enables auto-selection
  }
}

RemoveObjectCommand.prototype = {
  execute: function () {
    // Object removal can result in many nested objects being removed.
    // Pausing react UI redraws is nice and safe here. Pausing renders is a little more tricky.
    // We assume there are no cases where synchronous re-renders must be allowed (e.g. to support re-floating an object after a deletion)
    this.editor.uiPause('render', 'RemoveObjectCommand')
    this.editor.uiPause('ui', 'RemoveObjectCommand')
    editor.commandInProgress = true

    //Allows cleanup of nodes/edges if they don't belong to any other facets
    this.object.deletedByUserAction = this.deletedByUserAction

    var scope = this.editor
    this.object.traverse(function (child) {
      scope.removeHelper(child)
    })

    //Store temporary reference so we can update the parent system in objectRemoved signal
    var systemForParent = this.parent && this.parent.getSystem ? this.parent.getSystem() : null
    if (systemForParent) {
      this.object.parentBeforeRemoval = systemForParent
    }

    if (this.object.refreshUserData) {
      this.object.refreshUserData()
    }

    if (this.object.clearStringModuleReference) {
      this.object.clearStringModuleReference(this.editor, this.deletedStrings)
    }

    try {
      // catch error if object has no parent
      // NOTE: This operation also nullifies the parent reference of the object to be deleted
      this.parent.remove(this.object)
    } catch (e) {
      console.warn(e)
    }

    if (this.object.type === 'OsModuleGrid' && !this.editor.changingHistory) {
      this.parent.requireClusters = true
    }

    if (
      this.select &&
      this.editor.selected &&
      !this.editor.scene.getObjectByProperty('uuid', this.editor.selected.uuid)
    ) {
      this.editor.select(null)
    }
    if (this.object.clearAssociatedObject && !editor.changingHistory && this.deletedByUserAction) {
      this.object.clearAssociatedObject(this.editor)
    }

    this.editor.signals.objectRemoved.dispatch(this.object, this.stopOnRemove)
    this.editor.signals.sceneGraphChanged.dispatch()

    editor.commandInProgress = false
    this.editor.uiResume('ui', 'RemoveObjectCommand')
    this.editor.uiResume('render', 'RemoveObjectCommand')
  },

  undo: function () {
    // Object removal can result in many nested objects being removed.
    // Pausing react UI redraws is nice and safe here. Pausing renders is a little more tricky.
    // We assume there are no cases where synchronous re-renders must be allowed (e.g. to support re-floating an object after a deletion)
    this.editor.uiPause('render', 'RemoveObjectCommand')
    this.editor.uiPause('ui', 'RemoveObjectCommand')

    var scope = this.editor

    this.parent.children.splice(this.index, 0, this.object)
    this.object.parent = this.parent

    this.object.traverse(function (child) {
      if (child.geometry !== undefined) scope.addGeometry(child.geometry)
      if (child.material !== undefined) scope.addMaterial(child.material)
      scope.addHelper(child)
      if (child.applyUserData) child.applyUserData()
    })

    if (this.object.applyUserData) {
      this.object.applyUserData()
    }

    if (this.deletedStrings.length > 0) {
      window.Designer.setStringVisibility(false)
      this.deletedStrings.forEach(function (string) {
        //TO Do: refractor deletedString, use uuid only
        var osString = this.editor.scene.getObjectByProperty('uuid', string.uuid)
        osString.applyUserData()
      })
      window.Designer.setStringVisibility(true)
    }

    //Clear hash to ensure it is redrawn on redo
    if (this.object._hash) {
      this.object._hash = null
    }

    if (this.object.onChange) {
      var permitSnappingOverride = false
      this.object.onChange(editor, permitSnappingOverride)
    }

    if (this.object.type === 'OsModuleGrid') {
      this.object.children
        .filter(function (a) {
          return a.children.length === 0 && a.type === 'OsModule'
        })
        .forEach(function (a) {
          a.setGeometryForSize()
        })
    }
    if (this.object.type === 'OsSystem') {
      this.editor.select(this.object)
      this.editor.selectSystem(this.object)
    } else {
      this.editor.select(null)
    }
    this.editor.signals.objectAdded.dispatch(this.object)
    this.editor.signals.sceneGraphChanged.dispatch()

    this.editor.uiResume('ui', 'RemoveObjectCommand')
    this.editor.uiResume('render', 'RemoveObjectCommand')
  },

  toJSON: function () {
    if (this.json) {
      return this.json
    }
    var output = Command.prototype.toJSON.call(this)
    output.deletedStrings = this.deletedStrings.map((string) => string.toJSON())
    output.parentUuid = this.parent && this.parent.uuid
    output.object = this.object && this.object.toJSON()
    output.deletedByUserAction = this.deletedByUserAction
    output.index = this.index
    output.select = this.select

    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.deletedByUserAction = json.deletedByUserAction
    this.index = json.index
    this.object = json.object
    this.parent = this.editor.objectByUuid(json.parentUuid)
    this.select = json.select

    var loader = new THREE.ObjectLoader()
    if (json.object.object.type === 'OsSystem') {
      this.object =
        (json.object && this.editor.scene.getObjectByProperty('uuid', json.object.object.uuid)) ||
        loader.parse(json.object)

      this.object.moduleGrids().forEach(function (moduleGrid) {
        var modules =
          moduleGrid.children &&
          moduleGrid.children.filter(function (child) {
            return child.type === 'OsModule'
          })
        modules.forEach(function (module) {
          if (module.userData.cell) {
            module.cell = module.userData.cell
          }
          module.active = module.userData.active
          if (module.active) {
            moduleGrid.moduleObjects[module.userData.cell] &&
              editor.removeObject(moduleGrid.moduleObjects[module.userData.cell])
            moduleGrid.moduleObjects[module.userData.cell] = module
            moduleGrid.activateCell(module.userData.cell, true, false)
          }
        })
        moduleGrid.draw(true)
      })

      if (this.object.applyUserData) {
        this.object.applyUserData()
      }
      this.object.traverse(function (child) {
        if (child.applyUserData) {
          child.applyUserData()
        }
      })
    } else if (json.object.object.type === 'OsModuleGrid') {
      this.object =
        (json.object && this.editor.scene.getObjectByProperty('uuid', json.object.object.uuid)) ||
        loader.parse(json.object)
      var modules =
        this.object.children &&
        this.object.children.filter(function (child) {
          return child.type === 'OsModule'
        })
      modules.forEach(
        function (module) {
          if (module.userData.cell) {
            module.cell = module.userData.cell
          }
          module.active = module.userData.active
          if (module.active) {
            this.object.moduleObjects[module.userData.cell] &&
              editor.removeObject(this.object.moduleObjects[module.userData.cell])
            this.object.moduleObjects[module.userData.cell] = module
            this.object.activateCell(module.userData.cell, true, false)
          }
        }.bind(this)
      )
      this.object.draw(true)
    } else {
      this.object =
        (json.object && this.editor.scene.getObjectByProperty('uuid', json.object.object.uuid)) ||
        loader.parse(json.object)
      if (this.object.applyUserData) {
        this.object.applyUserData()
      }
      this.object.traverse(function (child) {
        if (child.applyUserData) {
          child.applyUserData()
        }
      })
    }

    this.deletedStrings = json.deletedStrings.map(function (string) {
      return loader.parse(string)
    })

    this.parent = this.editor.objectByUuid(json.parentUuid)
  },
}
