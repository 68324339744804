/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var UpdateElectricalsCommand = function (object, funcName, newValue, oldValue) {
  Command.call(this)

  this.type = 'UpdateElectricalsCommand'
  this.name = 'Update Electricals Command'
  this.object = object
  this.timeStamp = Date.now()
  this.funcName = funcName
  this.newValue = newValue
  this.oldValue = oldValue
  this.commandUUID = window.globalCommandUUID
}

UpdateElectricalsCommand.prototype = {
  execute: function () {
    if (this.object.type === 'OsSystem' && !this.editor.changingHistory) {
      var system = this.object.getSystem()
      system.requireClusters = true
    }
    this.object[this.funcName](this.newValue, this.editor.changingHistory)
    window.editor.signals.objectChanged.dispatch(this.object, this.attributeName, {
      oldValue: this.oldValue,
      newValue: this.newValue,
    })

    // Enabling/disabling studio plugins based on component type changes
    window.editor.signals.sceneGraphChanged.dispatch()
  },

  undo: function () {
    if (this.loadFromJSON) {
      this.object = this.object && this.editor.scene.getObjectByProperty('uuid', this.object)
      this.loadFromJSON = false
    }
    this.object[this.funcName](this.oldValue, this.editor.changingHistory)
    window.editor.signals.objectChanged.dispatch(this.object, this.attributeName, {
      newValue: this.oldValue,
      oldValue: this.newValue,
    })

    // Enabling/disabling studio plugins based on component type changes
    window.editor.signals.sceneGraphChanged.dispatch()

    this.object.refreshUserData()
  },

  toJSON: function () {
    if (this.loadFromJSON) {
      return Command.prototype.toObject.call(this)
    }
    var output = Command.prototype.toJSON.call(this)

    output.object = this.object.uuid
    output.funcName = this.funcName
    output.newValue = this.newValue
    output.oldValue = this.oldValue

    return output
  },

  fromJSON: function (parsedCmd) {
    Command.prototype.fromJSON.call(this, parsedCmd)

    this.object = parsedCmd.object
    this.funcName = parsedCmd.funcName
    this.newValue = parsedCmd.newValue
    this.oldValue = parsedCmd.oldValue
  },
}
