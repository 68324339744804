/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param view
 * @param newValue number, string, boolean or object
 * @constructor
 */

var ReorderChildrenCommand = function (parent, oldOrder, newOrder) {
  Command.call(this)
  this.type = 'ReorderChildrenCommand'
  this.name = 'Reorder Children Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.OBJECT_REFERENCES = ['parent']
  this.PROPERTIES = ['oldOrder', 'newOrder']

  if (arguments.length) {
    this.parent = parent
    this.newOrder = newOrder
    this.oldOrder = oldOrder
  }
}

ReorderChildrenCommand.prototype = {
  execute: function () {
    this.parent.children.sort((a, b) => this.newOrder.indexOf(a.uuid) - this.newOrder.indexOf(b.uuid))
    this.parent.refreshUserData()
    this.editor.signals.objectChanged.dispatch(this.parent, 'skipRecalc')
  },

  undo: function () {
    this.parent.children.sort((a, b) => this.oldOrder.indexOf(a.uuid) - this.oldOrder.indexOf(b.uuid))
    this.parent.refreshUserData()
    this.editor.signals.objectChanged.dispatch(this.parent, 'skipRecalc')
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
