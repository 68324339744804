/**
 * @author adampryor
 */

var OsViewWidgetLetterCache = null

function OsViewWidget(options) {
  THREE.Mesh.call(this)
  this.userData.excludeFromExport = true

  this.positionSettings = {
    screenBottom: 100,
    screenRight: 50,
    getScreenPoint: function (positionSettings, rect) {
      if (rect.width && rect.width < 680) {
        return new THREE.Vector2(
          (rect.width - positionSettings.screenRight) / rect.width,
          (rect.height - 120 - positionSettings.screenBottom) / rect.height
        )
      } else if (rect.width && rect.width < 850) {
        return new THREE.Vector2(
          (rect.width - positionSettings.screenRight) / rect.width,
          (rect.height - 50 - positionSettings.screenBottom) / rect.height
        )
      } else {
        return new THREE.Vector2(
          (rect.width - positionSettings.screenRight) / rect.width,
          (rect.height - positionSettings.screenBottom) / rect.height
        )
      }
    },
  }

  this.width = 6
  this.height = 2

  if (!OsViewWidgetLetterCache) {
    OsViewWidgetLetterCache = {
      // standard: new THREE.MeshStandardMaterial({
      //   color: 0x333333,
      //   depthTest: false,
      // }),
      // hover: new THREE.MeshStandardMaterial({
      //   color: 0x000000,
      //   depthTest: false,
      // }),
      letterHitAreaMaterialStandard: new THREE.MeshStandardMaterial({
        color: 0x333333,
        depthTest: false,
        transparent: true,
        opacity: 0.0,
      }),
      letterHitAreaMaterialHover: new THREE.MeshStandardMaterial({
        color: 0x000000,
        depthTest: false,
        transparent: true,
        opacity: 0.1,
      }),
      letterHitAreaGeometry: new THREE.CircleBufferGeometry(1.6, 12),
    }
  }

  this.geometry = new THREE.CylinderBufferGeometry(this.width, this.width, this.height, 24, 1, false, 0, Math.PI * 2)
  this.material = new THREE.MeshStandardMaterial({
    color: 0xeeeeee,
    metalness: 0.9,
    roughness: 0.9,
    emissive: 0xeeeeee,
    emissiveIntensity: 0.5,
  })

  this.castShadow = false
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsViewWidget'
  this.name = 'OsViewWidget'

  this.lookAt(new THREE.Vector3(0, -1000, 0))
  this.rotation.y = Math.PI / 4

  // Now move the transform into the geometry itself and remove the transform on the object
  // this makes face normals match the world
  this.updateMatrix()
  this.geometry.applyMatrix(this.matrix)
  this.position.set(0, 0, 0)
  this.rotation.set(0, 0, 0)
  this.updateMatrix()

  // Render above everything else
  this.renderOrder = 100000
  this.material.depthTest = false
  this.labelMeshes = this.drawLabels()

  this.compassTop = this.drawCompassTop()

  this.visible = options && options.hasOwnProperty('visible') ? options.visible : true
}


OsViewWidget.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsViewWidget,
  sizeScreenPixels: 100,
  sizeGeometry: 6,
  allowTiltEnabled: function () {
    // Only allow interactions if current view has allowTilt
    var selectedView = ViewHelper && ViewHelper.selectedView()
    return selectedView && selectedView.allowTilt
  },
  activate: function () {
    this.labelMeshes.forEach((l) => (l.forceRolloverUpdate = true))
  },
  deactivate: function () {
    this.labelMeshes.forEach((l) => (l.forceRolloverUpdate = false))
  },
  drawCompassTop: function () {
    var imageUrl = Designer.prepareFilePathForLoad(Designer.FILE_PATHS.COMPASS_TEXTURE)
    var texture = THREE.ImageUtils.loadTexture(imageUrl, null, () => {
      editor.render()
    })

    // I don't know why this is required to fix rotation but this works
    // Perhaps because the geometry has some rotation applied?
    texture.center.set(0.5, 0.5)
    texture.rotation = Math.PI / 4

    // compass face
    // re-use geometry from main compass body for the face (just scale and position it)
    var material = new THREE.MeshStandardMaterial({
      map: texture,
      metalness: 0.7, // warning this is EXTREMELY sensitive. Wrong value will cause extreme contrast/visibility problems.
      roughness: 0.9,
      depthTest: false,
    })
    var mesh = new THREE.Mesh(this.geometry, material)
    mesh.scale.x = 0.95
    mesh.scale.y = 0.95
    mesh.scale.z = 0.1
    mesh.position.z += this.height * 0.5 + this.height * 0.05
    mesh.renderOrder = this.renderOrder + 1
    this.add(mesh)
  },
  drawLabels: function () {
    var _this = this
    var labelMeshes = []

    var letterDistanceFromCenter = this.width * 0.75
    var letters = {
      T: {
        direction: 'top',
        x: 0,
        y: 0,
        z: this.height / 2 + this.height * 0.1, // elevate above compass center grey ring
      },
      N: {
        direction: 'north',
        x: 0,
        y: letterDistanceFromCenter, // up
        z: this.height / 2,
      },
      S: {
        direction: 'south',
        x: 0,
        y: -letterDistanceFromCenter, // down
        z: this.height / 2,
      },
      E: {
        direction: 'east',
        x: letterDistanceFromCenter, // left towards center
        y: 0,
        z: this.height / 2,
      },
      W: {
        direction: 'west',
        x: -letterDistanceFromCenter, // left towards center
        y: 0,
        z: this.height / 2,
      },
    }

    for (var letter in letters) {
      var settings = letters[letter]

      var label = new THREE.Mesh(
        OsViewWidgetLetterCache.letterHitAreaGeometry,
        OsViewWidgetLetterCache.letterHitAreaMaterialStandard
      )
      label.clickable = true
      label.onClick = function (face) {
        if (!_this.allowTiltEnabled()) {
          return
        }
        _this.deactivate()
        window.ViewHelper.animateToDirection(this.direction, function () {
          _this.activate()
        })
      }
      label.selectable = false
      label.handleMouseBehavior = ObjectBehaviors.handleMouseBehavior

      // forceRolloverUpdate is required to ensure we can roll between objects when they are
      // overlapping. Otherwise when rolling off and directly onto another it will not trigger rollover
      // Beware: this significantly slows down rendering when interacting with compass
      // In particular, clicking on compass results in many extra render calls as the compass moves under the mouse.
      // We mitigate this by disabling interactions with the compass letters during animations
      // Be sure to re-enabled them on end of animation
      label.forceRolloverUpdate = true
      label.handleMouse = function (isOver, intersection) {
        if (!_this.allowTiltEnabled()) {
          return
        }

        var direction
        if (isOver) {
          direction = this.direction
        } else {
          direction = null
        }

        _this.labelMeshes.forEach((labelMesh) => {
          if (labelMesh.direction === direction) {
            labelMesh.scale.z = 2
            labelMesh.material = OsViewWidgetLetterCache.letterHitAreaMaterialHover
          } else {
            labelMesh.scale.z = 1
            labelMesh.material = OsViewWidgetLetterCache.letterHitAreaMaterialStandard
          }
        })
      }

      label.position.set(settings.x, settings.y, settings.z)
      label.letter = letter
      label.direction = settings.direction
      label.userData.excludeFromExport = true

      editor.addObject(label, this)
      labelMeshes.push(label)
    }

    return labelMeshes
  },
  refreshForCamera: function (position, metersPerPixel) {
    var rect = editor.viewport.rect()
    // abort for invalid viewport size (width or height equals 0)
    // this avoids computation crashes due to dividing by zero
    if (rect.width * rect.height === 0) return

    var screenPoint = this.positionSettings.getScreenPoint.call(this, this.positionSettings, rect)
    var position = editor.viewport.getIntersectionWithGround(screenPoint)
    if (position) {
      this.position.copy(position)
    }

    //set scale in order to achieve target size in screen pixels
    //@todo: Why do we need to multiply by 100?
    var scale = this.sizeScreenPixels * this.sizeGeometry * metersPerPixel * 0.01

    this.scale.copy(new THREE.Vector3(scale, scale, scale))
  },
})
