const ComponentUtils = {
  HYBRID_INVERTER_DC_CONNECTION_WARNING: 'HYBRID_INVERTER_DC_CONNECTION_WARNING',
  ERROR_SOURCE: 'extension',

  removeInverterDCWarning: function (system) {
    window.WorkspaceHelper.removeProjectErrorFromReduxStore(
      this.HYBRID_INVERTER_DC_CONNECTION_WARNING,
      system.uuid,
      this.ERROR_SOURCE
    )
  },

  handlecheckHybridClearWarning(system) {
    if (system.batteries().length < 1) {
      this.removeInverterDCWarning(system)
      return
    }
  },

  handleHybridBatteryConnectionState(editorInstance, system) {
    if (system.batteries().length < 1) {
      this.removeInverterDCWarning(system)
      return
    }

    const shouldBeDCConnected = system.systemContainsHybridInverter()
    const isDCConnected = system.is_battery_dc_connected
    const hasNoInverters = system.inverters().length < 1

    if (hasNoInverters) {
      if (system.is_battery_dc_connected_auto) {
        if (isDCConnected) editorInstance.execute(new SetValueCommand(system, 'is_battery_dc_connected', false))
        return
      }

      const warning = this.createBatteryConnectionWarning(shouldBeDCConnected, isDCConnected, system)
      if (warning) {
        window.WorkspaceHelper.addProjectErrorToReduxStore(warning)
      }
      return
    }

    if (system.is_battery_dc_connected_auto) {
      if (isDCConnected !== shouldBeDCConnected) {
        this.handleAutoDCConnectionChange(editorInstance, system, shouldBeDCConnected)
      }
    } else {
      this.handleManualConnectionWarning(shouldBeDCConnected, isDCConnected, system)
    }
  },

  handleAutoDCConnectionChange(editorInstance, system, shouldBeDCConnected) {
    const command = new SetValueCommand(system, 'is_battery_dc_connected', shouldBeDCConnected)
    editorInstance.execute(command)

    const undoCommand = new SetValueCommand(system, 'is_battery_dc_connected', !shouldBeDCConnected)
    const trackSideEffects = [system, ['is_battery_dc_connected']]
    const message = shouldBeDCConnected
      ? 'Hybrid Inverter Detected, Automatically Setting Battery as DC Connected'
      : 'No Hybrid Inverter Detected, Automatically Setting Battery as AC Connected'

    this.showUndoableNotification(editorInstance, message, undoCommand, 8000, 'info', trackSideEffects)
  },

  handleManualConnectionWarning(shouldBeDCConnected, isDCConnected, system) {
    const warning = this.createBatteryConnectionWarning(shouldBeDCConnected, isDCConnected, system)
    if (warning) {
      window.WorkspaceHelper.addProjectErrorToReduxStore(warning)
    } else {
      this.removeInverterDCWarning(system)
    }
  },

  showUndoableNotification(
    editor,
    message,
    undoCommand,
    duration = 5000,
    type = 'info',
    trackSideEffects = [],
    options = {}
  ) {
    const defaultOptions = {
      type,
      autoHideDuration: duration,
      buttons: [
        {
          label: 'Undo',
          action: () => {
            editor.extensions.SystemSideEffects.setUndoInProgress(true)
            editor.execute(undoCommand)
            setTimeout(() => {
              editor.extensions.SystemSideEffects.setUndoInProgress(false)
            }, 0)
          },
        },
      ],
    }
    window.Designer.showNotification(translate(message), type, { ...defaultOptions, ...options })
  },

  showNotification(message, duration = 5000, type = 'info', options = {}) {
    const defaultOptions = { type, autoHideDuration: duration }
    window.Designer.showNotification(translate(message), type, { ...defaultOptions, ...options })
  },

  handleHybridInverterWarningOnLoad(system) {
    const shouldBeDCConnected = system.systemContainsHybridInverter()
    const isDCConnected = system.is_battery_dc_connected
    const warning = this.createBatteryConnectionWarning(shouldBeDCConnected, isDCConnected, system)
    this.removeInverterDCWarning(system)
    if (warning) {
      window.WorkspaceHelper.addProjectErrorToReduxStore(warning)
    }
  },

  createBatteryConnectionWarning(isHybrid, isDCConnected, system) {
    const hasMismatch = (isHybrid && !isDCConnected) || (!isHybrid && isDCConnected)
    if (!hasMismatch) return null

    const warningMessage =
      system.inverters().length < 1
        ? 'You dont have a hybrid inverter in your design but your battery is set as DC coupled'
        : isHybrid
        ? 'You have selected a Hybrid inverter but your battery is set as AC coupled'
        : 'You dont have a hybrid inverter in your design but your battery is set as DC coupled'

    const warning = {
      key: this.HYBRID_INVERTER_DC_CONNECTION_WARNING,
      severity: 'warning',
      category: 'battery',
      systemId: system.uuid,
      field: 'batteries',
      source: this.ERROR_SOURCE,
      message: warningMessage,
    }

    return system.is_battery_dc_connected_auto
      ? { warning }
      : {
          ...warning,
          options: {
            actions: [
              {
                label: 'Enable Auto',
                callback: () => {
                  this.removeInverterDCWarning(system)
                  window.editor.execute(new SetValueCommand(system, 'is_battery_dc_connected_auto', true))
                  window.editor.execute(new SetValueCommand(system, 'is_battery_dc_connected', !isDCConnected))
                },
              },
            ],
          },
        }
  },
}
