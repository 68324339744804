/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newRotation THREE.Euler
 * @param optionalOldRotation THREE.Euler
 * @constructor
 */

var SetRotationCommand = function (object, newRotation, optionalOldRotation) {
  Command.call(this)
  this.timeStamp = Date.now()
  this.type = 'SetRotationCommand'
  this.name = 'Set Rotation'
  this.updatable = true
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = []
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.object = object

    this.oldRotation = object.rotation.clone()
    this.newRotation = newRotation.clone()

    if (optionalOldRotation) {
      this.oldRotation = optionalOldRotation.clone()
    }
  }
}

SetRotationCommand.prototype = {
  execute: function () {
    this.object.rotation.copy(this.newRotation)
    this.object.updateMatrixWorld(true)
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  undo: function () {
    this.object.rotation.copy(this.oldRotation)
    this.object.updateMatrixWorld(true)
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  update: function (command) {
    this.newRotation.copy(command.newRotation)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    output.oldRotation = this.oldRotation.toArray()
    output.newRotation = this.newRotation.toArray()
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
    this.oldRotation = new THREE.Euler().fromArray(json.oldRotation)
    this.newRotation = new THREE.Euler().fromArray(json.newRotation)
  },
}
