const SystemSideEffects = (editorInstance) => {
  const handleBatteryDCConnectedChanged = (system) => {
    ComponentUtils.handleHybridBatteryConnectionState(editorInstance, system)
  }

  const handleHybridInverterWarnings = (system) => {
    ComponentUtils.handleHybridInverterWarningOnLoad(system)
  }

  const effects = new Map([
    [
      'batteryDCConnectedChanged',
      {
        triggers: ['change', 'change.is_battery_dc_connected'],
        handle: handleBatteryDCConnectedChanged,
      },
    ],
    [
      'hybridInverterWarnings',
      {
        triggers: ['load'],
        handle: handleHybridInverterWarnings,
      },
    ],
  ])

  const systemSideEffects = SystemSideEffectsBase.create(editorInstance, 'System', {
    effects,
    isValidComponentType: (object) => object instanceof OsSystem,
    signals: [],
  })

  systemSideEffects.signals = [
    ['objectAdded', systemSideEffects.handleObjectAdded.bind(systemSideEffects)],
    ['objectChanged', systemSideEffects.handleObjectChanged.bind(systemSideEffects)],
    ['sceneLoaded', systemSideEffects.handleSceneLoaded.bind(systemSideEffects)],
  ]

  return systemSideEffects
}
