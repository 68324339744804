class ModuleGridAzimuthIndicators {
  static fillMaterial = new THREE.MeshBasicMaterial({ color: 0xffda00, depthTest: false, side: THREE.DoubleSide })
  static outlineMaterial = new THREE.LineBasicMaterial({
    color: 0xffda00,
    depthTest: false,
    lineWidth: 1,
    onBeforeCompile: (shader) => {
      shader.vertexShader = `
            attribute vec3 instT;
            attribute vec4 instR;
            vec3 applyInstTranslationRotation(inout vec3 position, vec3 instT, vec4 instR) {
                // only position and rotation are important, no need for scaling
                // apply rotation given quaternion
                position += 2.0 * cross(instR.xyz, cross(instR.xyz, position) + instR.w * position);
                // apply translation
                position += instT;
                return position;
            }
            ${shader.vertexShader}
          `.replace(
        `#include <begin_vertex>`,
        `#include <begin_vertex>
              transformed = applyInstTranslationRotation(transformed, instT, instR);`
      )
    },
  })

  moduleGrid
  container

  #INDICATOR_THICKNESS = 0.05
  #INDICATOR_ARM_LENGTH = 0.25

  constructor(moduleGrid) {
    if (!(moduleGrid instanceof OsModuleGrid)) {
      throw new Error('invalid OsModuleGrid instance!')
    }

    this.moduleGrid = moduleGrid
    this.container = new THREE.Group()
    this.container.visible = false
    this.container.userData.excludeFromExport = true
  }

  show = () => {
    if (!this.#hasGenerated()) {
      this.#generate()
    }
    this.container.visible = true
    if (!this.#isInScene()) {
      window.editor.addObject(this.container, window.editor.sceneHelpers)
    }
  }

  hide = (dispose = false) => {
    this.container.visible = false
    if (this.#isInScene()) {
      window.editor.removeObject(this.container)
    }
    if (dispose) {
      this.#clear()
    }
  }

  sync = () => {
    this.moduleGrid.updateMatrixWorld()

    if (!this.#hasGenerated()) {
      // sync() is called and module grid is currently selected
      // but indicators have not been generated yet
      // generate them now
      // they are synced on generation
      this.#generate()
      this.moduleGrid.selected() && this.show()
      return
    }

    const modules = this.moduleGrid.getModules()
    const moduleCountHasChanged = modules.length !== this.container.userData.moduleCount
    // the module grid's size already compensates for the panel orientation
    // that is, if the size of the panel is n x m, then the module grid size will be [n, m] if the orientation is portrait
    // if the orientation is landscape, then the module grid size will be [m, n]
    // doing it this way ensures that we re-generate even if the orientation hasn't changed,
    // but the panel make and model (which might have slightly different dimensions) has
    const moduleWidthHasChanged = this.moduleGrid.size[0] !== this.container.userData.moduleWidth
    const moduleHeightHasChanged = this.moduleGrid.size[1] !== this.container.userData.moduleHeight

    // a simple sync will not suffice when either the module count
    // or the module height or width has changed
    // we need to re-generate the azimuth indicators
    if (moduleCountHasChanged || moduleWidthHasChanged || moduleHeightHasChanged) {
      this.#clear()
      if (this.moduleGrid.selected()) {
        this.show()
      }
      return
    }

    // synchronize world transforms of azimuth indicators
    // so they align perfectly with the modules in the scene
    const indicatorFillingMesh = this.container.children[0]
    const indicatorOutlines = this.container.children[1]

    const instTranslations = []
    const instRotations = []

    modules.forEach((module, index) => {
      const position = new THREE.Vector3()
      const quaternion = new THREE.Quaternion()
      const scale = new THREE.Vector3()

      module.matrixWorld.decompose(position, quaternion, scale)
      position.z += 0.04 // raise the indicator slightly above the module's surface
      indicatorFillingMesh.setMatrixAt(index, new THREE.Matrix4().compose(position, quaternion, scale))

      // record the translation, rotation, and scaling values
      // applied to the instances
      // we'll use this later for the indicator outlines
      instTranslations.push(position.x, position.y, position.z)
      instRotations.push(quaternion.x, quaternion.y, quaternion.z, quaternion.w)
    })

    indicatorFillingMesh.instanceMatrix.needsUpdate = true

    indicatorOutlines.geometry.attributes['instT'].copyArray(instTranslations)
    indicatorOutlines.geometry.attributes['instT'].needsUpdate = true
    indicatorOutlines.geometry.attributes['instR'].copyArray(instRotations)
    indicatorOutlines.geometry.attributes['instR'].needsUpdate = true
  }

  getContainer = () => this.container

  getIndicatorCount = () => this.container.userData.moduleCount

  #clear = () => {
    if (!this.#hasGenerated()) return

    const indicatorFillingMesh = this.container.children[0]
    const indicatorOutlines = this.container.children[1]
    indicatorFillingMesh.geometry.dispose()
    indicatorOutlines.geometry.dispose()
    this.container.remove(indicatorFillingMesh)
    this.container.remove(indicatorOutlines)
  }

  #generate = () => {
    this.#clear()
    const modules = this.moduleGrid.getModules()
    if (modules.length === 0) return

    ///////////////////////////////////////////////////////////
    //  STEP 1: Initialize group that will hold the
    //          indicator objects
    ///////////////////////////////////////////////////////////
    this.container.userData.moduleCount = modules.length
    this.container.userData.moduleWidth = this.moduleGrid.size[0]
    this.container.userData.moduleHeight = this.moduleGrid.size[1]
    this.container.name = `azimuthIndicators-${this.uuid}`
    /////////////////////////////////////////////////////////
    // STEP 2: create the filling meshes as instanced mesh
    /////////////////////////////////////////////////////////
    const moduleHeight = this.moduleGrid.size?.[1] || 1
    const moduleVertMidpointY = moduleHeight / 2
    const targetIndicatorY = moduleHeight - 0.2
    const indicatorGeometry = this.#getIndicatorGeometry(
      this.#INDICATOR_THICKNESS,
      this.#INDICATOR_ARM_LENGTH,
      new THREE.Vector2(0, moduleVertMidpointY - targetIndicatorY)
    )
    const indicatorFillingMesh = new THREE.InstancedMesh(
      indicatorGeometry,
      ModuleGridAzimuthIndicators.fillMaterial,
      modules.length
    )
    indicatorFillingMesh.name = 'azimuthIndicatorFillingMeshes'
    indicatorFillingMesh.frustumCulled = false
    ////////////////////////////////////////////////////////
    // STEP 3: Set the instance matrices based on the world
    //         transformation matrices of the module meshes
    ////////////////////////////////////////////////////////
    const instTranslations = []
    const instRotations = []
    modules.forEach((module, index) => {
      const position = new THREE.Vector3()
      const quaternion = new THREE.Quaternion()
      const scale = new THREE.Vector3()
      module.matrixWorld.decompose(position, quaternion, scale)
      position.z += 0.04 // raise the indicator slightly above the module's surface
      indicatorFillingMesh.setMatrixAt(index, new THREE.Matrix4().compose(position, quaternion, scale))
      // record the translation, rotation, and scaling values
      // applied to the instances
      // we'll use this later for the indicator outlines
      instTranslations.push(position.x, position.y, position.z)
      instRotations.push(quaternion.x, quaternion.y, quaternion.z, quaternion.w)
    })
    ///////////////////////////////////////////////////////////
    // STEP 4: Create the indicator outlines, which is used
    //         to enhance the visibility of the indicators
    //        even when viewed from afar through the camera
    ///////////////////////////////////////////////////////////
    // Since instancing is not supported for lines (only meshes) in ThreeJS,
    // we just use InstancedBufferGeometry to optimize rendering performance
    const outlineGeometry = new THREE.EdgesGeometry(indicatorGeometry)
    const outlineGeometryInstanced = new THREE.InstancedBufferGeometry().copy(outlineGeometry)
    outlineGeometry.dispose() // no need for it anymore
    outlineGeometryInstanced.instanceCount = modules.length
    /////////////////////////////////////////////////////////////
    //  STEP 5: Add vertex buffer attributes that contains the
    //          transformation values to be applied to the
    //          instances of the instanced geometry
    //          this is intercepted in the modified vertex shader
    /////////////////////////////////////////////////////////////
    outlineGeometryInstanced.setAttribute(
      'instT',
      new THREE.InstancedBufferAttribute(new Float32Array(instTranslations), 3)
    )
    outlineGeometryInstanced.setAttribute(
      'instR',
      new THREE.InstancedBufferAttribute(new Float32Array(instRotations), 4)
    )
    ///////////////////////////////////////////////////////////
    // STEP 6: Create the line segments for the outlines
    ///////////////////////////////////////////////////////////
    const indicatorOutlines = new THREE.LineSegments(
      outlineGeometryInstanced,
      ModuleGridAzimuthIndicators.outlineMaterial
    )
    indicatorOutlines.name = 'azimuthIndicatorOutlines'
    indicatorOutlines.frustumCulled = false
    ///////////////////////////////////////////////////////////
    // STEP 7: Add the indicator mesh and outlines to the indicator group
    ///////////////////////////////////////////////////////////
    this.container.add(indicatorFillingMesh)
    this.container.add(indicatorOutlines)
  }

  #hasGenerated = () => this.container.children.length > 0

  #isInScene = () => !!this.container.parent

  #getIndicatorGeometry = (thickness, armLength, offsetFromModuleMidpoint) => {
    const points = [
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(armLength, 0, 0),
      new THREE.Vector3(armLength, thickness, 0),

      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(armLength, thickness, 0),
      new THREE.Vector3(thickness, thickness, 0),

      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(thickness, thickness, 0),
      new THREE.Vector3(thickness, armLength, 0),

      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(thickness, armLength, 0),
      new THREE.Vector3(0, armLength, 0),
    ]

    const zAxis = new THREE.Vector3(0, 0, 1)
    points.forEach((p) => {
      p.applyAxisAngle(zAxis, THREE.Math.DEG2RAD * 45)
      p.x += offsetFromModuleMidpoint.x
      p.y += offsetFromModuleMidpoint.y
    })
    return new THREE.BufferGeometry().setFromPoints(points)
  }
}

window.ModuleGridAzimuthIndicators = ModuleGridAzimuthIndicators
