/**
 * @author adampryor
 */

var OsClipperCache = {}

function OsClipper(options) {
  THREE.Mesh.call(this)

  if (!OsClipperCache.geometry) {
    OsClipperCache.geometry = {
      circle: new THREE.CylinderBufferGeometry(0.5, 0.5, 0.2, 12),
      rectangle: new THREE.BoxBufferGeometry(0.5, 0.5, 0.2),
    }
    OsClipperCache.geometry.rectangle.excludeFromExport = true
    OsClipperCache.geometry.circle.excludeFromExport = true

    OsClipperCache.geometry.circle.rotateX(-Math.PI * 0.5)

    // Set top of geometry on baseline so the height of the object is the exact height of the shading obstruction
    // Rise slightly above to avoid z-fighting of the terrain below
    Object.keys(OsClipperCache.geometry).forEach((type) => OsClipperCache.geometry[type].translate(0, 0, 0.1))
  }

  if (!OsClipperCache.material) {
    OsClipperCache.material = new THREE.MeshStandardMaterial({
      color: 0x6666cc,
      opacity: 0.5,
      transparent: true,
    })
    OsClipperCache.materialOnSelect = new THREE.MeshStandardMaterial({
      color: 0xff9c00,
      opacity: 0.5,
      transparent: true,
    })
    OsClipperCache.materialGhostMode = new THREE.MeshStandardMaterial({
      color: 0x6666cc,
      opacity: 0.3,
      transparent: true,
    })
  }
  this._shapeType = null
  this.shapeType('circle')
  //this.geometry = OsClipperCache.geometry;

  this.material = OsClipperCache.material

  this.castShadow = false
  this.receiveShadow = false

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsClipper'
  this.name = 'OsClipper'

  this._ghostMode = options && options.hasOwnProperty('ghostMode') ? options.ghostMode : false

  this.floatAppliesOrientation = false

  this.lookAt(new THREE.Vector3(0, 0, 1000))
}

OsClipper.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsClipper,
  getName: function () {
    return 'Tree Trimmer'
  },
  toolsActive: function () {
    return {
      translateXY: true,
      translateZ: true,
      translateX: false,
      rotate: true,
      scaleXY: true,
      scaleZ: false,
    }
  },
  confirmBeforeDelete: function () {
    return 'Delete Tree Trimmer?'
  },
  transformWithLocalCoordinates: function () {
    return true
  },
  belongsToGroup: ObjectBehaviors.belongsToGroup,
  onChange: function (editor) {
    SceneHelper.clipAndRefreshTerrainDebounced()
  },
  onRemove: function (editor) {
    SceneHelper.clipAndRefreshTerrainDebounced()
  },
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,
  applyGhostMode: function (value) {
    if (value) {
      this.material = OsClipperCache.materialGhostMode
    } else {
      this.material = OsClipperCache.material
    }
  },

  shapeType: function (value) {
    if (typeof value === 'undefined') {
      return this._shapeType
    }

    if (!OsClipperCache.geometry[value]) {
      // Invalid shapeType will fallback to first/arbitrary shapeType
      // This should never happen but at least it would result in a valid shape instead of an exception
      // which could break the whole scene.
      value = Object.keys(OsClipperCache.geometry)[0]
    }

    if (this._shapeType != value) {
      //update
      this.geometry = OsClipperCache.geometry[value]
      this._shapeType = value
      this.refreshUserData()
    }
  },

  getContextMenuItems: function (position) {
    var _this = this

    var menuItems = Object.keys(OsClipperCache.geometry).map(function (key) {
      return {
        label: window.translate('Shape') + ': ' + window.translate(key),
        onClick: function () {
          _this.shapeType(key)
        },
      }
    })

    menuItems.push({
      label: window.translate('Select Tree Trimmer'),
      useHTML: false,
      selected: false,
      onClick: function () {
        if (editor) {
          editor.select(_this)
        }
      },
    })

    return menuItems
  },

  onSelect: function () {
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    var newMaterial = isSelected ? OsClipperCache.materialOnSelect : OsClipperCache.material
    if (this.material != newMaterial) {
      this.material = newMaterial
    }
  },

  onDeselect: function () {
    var isSelected = editor.selected && (editor.selected.uuid === this.uuid || this.belongsToGroup(editor.selected))
    var newMaterial = isSelected ? OsClipperCache.materialOnSelect : OsClipperCache.material
    if (this.material != newMaterial) {
      this.material = newMaterial
    }
  },

  applyUserData: function () {
    this.shapeType(this.userData.shapeType)
  },

  refreshUserData: function () {
    this.userData.shapeType = this.shapeType()
  },

  duplicate: function (options) {
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    var newClipper = new OsClipper(this)
    newClipper.copy(this)
    newClipper.position.copy(this.position).add(positionOffset)
    newClipper.children = []
    editor.execute(new AddObjectCommand(newClipper, null, true))
  },

  setVisible: function (visible) {
    if (this.visible === visible) return
    this.visible = visible
  },

  getVisible: function (visible) {
    return this.visible
  },
})
