/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var AddNodeToFacetCommand = function (node, facet, forceLast, allowEdgeAutoCreation, commandUUID) {
  Command.call(this)

  this.type = 'AddNodeToFacetCommand'
  this.name = 'Add Node to Facet Command'
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID

  this.PROPERTIES = ['forceLast', 'allowEdgeAutoCreation']
  this.OBJECT_REFERENCES = ['node', 'facet']

  if (arguments.length) {
    this.node = node
    this.facet = facet
    this.forceLast = forceLast
    this.allowEdgeAutoCreation = allowEdgeAutoCreation
  }
}

AddNodeToFacetCommand.prototype = {
  execute: function () {
    this.node.addToFacet(this.facet, this.forceLast, this.allowEdgeAutoCreation)
  },

  undo: function () {
    this.node.removeFromFacet(this.facet)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
