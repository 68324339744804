/**
 * @author adampryor
 * Adapted from THREE.TrackballControls
 */

var CameraController = function (editor, viewport) {
  this.name = 'Camera'

  var _this = this
  var STATE = {
    NONE: -1,
    ROTATE: 2,
    ZOOM: 1,
    PAN: 0,
    TOUCH_ROTATE: 3,
    TOUCH_ZOOM: 4,
    TOUCH_PAN: 5,
  }

  this.inputs = {
    activation: (activationState) => {
      if (activationState === this.active) return
      if (activationState === true) {
        this.activate()
      } else {
        this.deactivate()
      }
    },
  }

  this.outputs = {
    activation: new window.Signal(),
  }

  this.useTrackballControlStyle = false

  this.domElement = viewport.container.dom

  // API

  this.enabled = true

  //Allow overriding the orientation check
  this.notTopDown = false

  this.keysInitial = {
    up: false,
    down: false,
    left: false,
    right: false,
    shift: false,
  }
  this.keys = JSON.parse(JSON.stringify(this.keysInitial))

  this.toggleControlStyle = function () {
    this.useTrackballControlStyle = Boolean(!this.useTrackballControlStyle)
    this.handleResize()
  }

  this.isAnimatedTurntable = function () {
    if (MapHelper.activeMapInstance && MapHelper.activeMapInstance.mapType == 'Turntable') {
      if (!editor.selected || editor.selected.type === 'OsSystem') {
        return true
      }
    }
    return false
  }

  editor.signals.sceneLoaded.add(function () {
    if (_this.isAnimatedTurntable()) {
      window.editor.signals?.animationStart.dispatch('turnTable', 'sceneLoadedAnimateTurnTable')
    }
  })

  //Populated using handleResize()
  this.screen = { width: 0, height: 0, offsetLeft: 0, offsetTop: 0 }
  this.circle = { width: 0, height: 0, offsetLeft: 0, offsetTop: 0 }
  this.radius = 100

  this.rotateOutsideRadius = null

  this.rotationScaling = 0.25
  this.rotateSpeed = 1.0
  this.orbitSpeed = 0.01
  this.orbitSpeedAutomated = 0.005
  this.zoomSpeed = 1.2
  this.panSpeed = 0.4

  this.CAMERA_ROTATION_RATE = 0.6

  this.noRotate = false
  this.noZoom = false
  this.noPan = false

  this.staticMoving = false

  this.rotateDynamicDampingFactor = 0.8
  this.zoomDynamicDampingFactor = 0.8
  this.panDynamicDampingFactor = 0.8

  this.pinchScaling = 0.001

  this.rotationSpeedNew = 0.5

  //initially true but updated on mapChanged signal
  this.allowFractionalZoomLevels = true
  this.allowZoom = true

  this.minDistance = 0
  this.maxDistance = Infinity

  //this.keys = [ 65 /*A*/, 83 /*S*/, 68 /*D*/ ];

  // internals

  //@todo: Should we clone this vector and save/load manually, or make a two-way reference?
  this.target = editor.cameraCenter

  this.spherical = new SphericalZup()

  var lastPosition = new THREE.Vector3()

  var _state = STATE.NONE,
    _prevState = STATE.NONE,
    _eye = new THREE.Vector3(),
    _cameraUpLast = new THREE.Vector3(),
    _rotateStart = new THREE.Vector3(),
    _rotateEnd = new THREE.Vector3(),
    _rotateStartMouse = new THREE.Vector3(),
    _rotateEndMouse = new THREE.Vector3(),
    _zoomStart = new THREE.Vector2(),
    _zoomEnd = new THREE.Vector2(),
    _touchZoomDistanceStart = 0,
    _touchZoomDistanceEnd = 0,
    _panStart = new THREE.Vector2(),
    _panEnd = new THREE.Vector2()

  // for reset

  this.target0 = this.target.clone()
  //this.target0.z = 0
  this.position0 = editor.camera.position.clone()
  this.up0 = editor.camera.up.clone()

  // events

  var changeEvent = { type: 'change' }
  var finishEvent = { type: 'finished' }

  // methods

  this.handleResize = function () {
    var viewportRect = viewport.rect()

    this.screen.width = viewportRect.width
    this.screen.height = viewportRect.height
    this.screen.offsetLeft = viewportRect.left
    this.screen.offsetTop = viewportRect.top

    //Limit size to 100% of smallest dimension and 70% of longest dimension
    //This allows a larger circle on non-square screens -e.g. phone.

    //Leave 30% around the outside for rotation around the radius
    var shorterDimension = Math.min(this.screen.width, this.screen.height)
    var largerDimension = Math.max(this.screen.width, this.screen.height)

    this.radius = Math.min(shorterDimension / 2, (0.7 * largerDimension) / 2)

    this.circle.width = this.radius * 2
    this.circle.height = this.circle.width
    this.circle.offsetTop = viewportRect.top + viewportRect.height / 2 - this.radius
    this.circle.offsetLeft = viewportRect.left + viewportRect.width / 2 - this.radius

    this.updateTrackballCircleGuide(false, this.circle)
  }

  this.handleEvent = function (event) {
    if (typeof this[event.type] == 'function') {
      this[event.type](event)
    }
  }

  this.getMouseOnScreen = function (clientX, clientY) {
    return new THREE.Vector2(
      ((clientX - _this.screen.offsetLeft) / _this.radius) * 0.5,
      ((clientY - _this.screen.offsetTop) / _this.radius) * 0.5
    )
  }

  this.getMouseProjectionOnBall = function (clientX, clientY) {
    var mouseOnBall = new THREE.Vector3(
      (clientX - _this.screen.width * 0.5 - _this.screen.offsetLeft) / _this.radius,
      (_this.screen.height * 0.5 + _this.screen.offsetTop - clientY) / _this.radius,
      0.0
    )

    mouseOnBall.multiplyScalar(this.rotationScaling)

    var length = mouseOnBall.length()

    if (this.rotateOutsideRadius === null) {
      if (length > 1.0 * this.rotationScaling) {
        this.rotateOutsideRadius = true
        mouseOnBall.z = 0.001 //avoid lockup when z is 0 which disables animation until it's been slightly changed
      } else {
        this.rotateOutsideRadius = false
      }
    }

    if (this.rotateOutsideRadius) {
      mouseOnBall.normalize()
    } else {
      mouseOnBall.z = Math.sqrt(1.0 - length * length)
    }

    _eye.copy(editor.camera.position).sub(_this.target)

    var projection = editor.camera.up.clone().setLength(mouseOnBall.y)
    projection.add(editor.camera.up.clone().cross(_eye).setLength(mouseOnBall.x))
    projection.add(_eye.setLength(mouseOnBall.z))

    return projection
  }

  this.updateTrackballCircleGuide = function (showHide, circle) {
    if (!this.useTrackballControlStyle) {
      // Only show when using track-ball control style, not orbit control style.
      return
    }

    var props = {}

    if (typeof showHide !== 'undefined') {
      props['display'] = showHide ? 'block' : 'none'
    }

    if (typeof circle !== 'undefined') {
      props['width'] = circle.width + 'px'
      props['height'] = circle.height + 'px'
      props['top'] = circle.offsetTop + 'px'
      props['left'] = circle.offsetLeft + 'px'
    }

    if (this.rotateOutsideRadius === true) {
      $('#camera-trackball-circle-guide').removeClass('innerGlow')
      $('#camera-trackball-circle-guide').addClass('outerGlow')
    } else if (this.rotateOutsideRadius === false) {
      $('#camera-trackball-circle-guide').removeClass('outerGlow')
      $('#camera-trackball-circle-guide').addClass('innerGlow')
    } else {
      $('#camera-trackball-circle-guide').removeClass('innerGlow')
      $('#camera-trackball-circle-guide').removeClass('outerGlow')
    }

    $('#camera-trackball-circle-guide').css(props)
  }

  this.rotateCameraTrackball = function () {
    var angle = Math.acos(_rotateStart.dot(_rotateEnd) / _rotateStart.length() / _rotateEnd.length())

    if (angle) {
      var axis = new THREE.Vector3().crossVectors(_rotateStart, _rotateEnd).normalize()
      quaternion = new THREE.Quaternion()

      if (isNaN(axis.x)) {
        console.log('axis.x isNaN, reset delta')
        _rotateEnd = _rotateStart
      } else {
        angle *= _this.rotateSpeed

        quaternion.setFromAxisAngle(axis, -angle)

        _eye.applyQuaternion(quaternion)

        editor.camera.up.applyQuaternion(quaternion)

        _rotateEnd.applyQuaternion(quaternion)

        if (_this.staticMoving) {
          _rotateStart.copy(_rotateEnd)
        } else {
          quaternion.setFromAxisAngle(axis, angle * (_this.rotateDynamicDampingFactor - 1.0))
          _rotateStart.applyQuaternion(quaternion)
        }

        return true
      }
    }

    return false
  }

  this.rotateCameraOldOrbit = function (dispatchChangeEvent) {
    //We are disabling changeEvent because this is already dispatched by the update() method, but we don't want
    //to remove it completely, just in case.
    var delta = new THREE.Vector2(
      (_rotateStartMouse.x - _rotateEndMouse.x) * 0.005,
      (_rotateStartMouse.y - _rotateEndMouse.y) * 0.005
    )

    if (!delta) delta = new THREE.Vector2()

    var vector = new THREE.Vector3()

    vector.copy(editor.camera.position).sub(_this.target)

    //Swap Y and Z coordinates, we use Z as up
    vector = new THREE.Vector3(vector.x, vector.y, vector.z)

    if (vector.x == 0 && vector.y == 0) {
      _this.spherical.theta = Math.PI - 0.0001
      _this.spherical.phi = 0.0001
      _this.spherical.radius = vector.length()
    } else {
      _this.spherical.setFromVector3(vector)
    }

    _this.spherical.theta -= delta.x
    _this.spherical.phi += delta.y

    _this.spherical.makeSafe()

    var vectorFromSpherical = new THREE.Vector3().setFromSpherical(_this.spherical)

    //Swap Y and Z coordinates, we use Z as up
    vector = new THREE.Vector3(vectorFromSpherical.x, vectorFromSpherical.z, vectorFromSpherical.y)

    editor.camera.position.copy(_this.target).add(vector)
    editor.camera.up.fromArray([0, 0, 1])
    Utils.lookAtSafe(editor.camera, _this.target)

    _eye.subVectors(editor.camera.position, _this.target)
    //_eye.copy( vector )

    if (this.useTrackballControlStyle) {
      _rotateStartMouse.copy(_rotateEndMouse)
      if (dispatchChangeEvent) {
        _this.dispatchEvent(changeEvent)
      }
    } else {
      _rotateStartMouse.lerp(_rotateEndMouse, this.CAMERA_ROTATION_RATE)

      //@TODO: This seems to be necessary when rotation from top-down position,
      //but seems to harm performance in other orientations
      if (vectorFromSpherical.y > 99) {
        if (dispatchChangeEvent) {
          _this.dispatchEvent(changeEvent)
        }
      }

      // if very close then set end and start to be equal so
      if (new THREE.Vector3().subVectors(_rotateEndMouse, _rotateStartMouse).length() < 0.1) {
        _rotateStartMouse.copy(_rotateEndMouse)
      }
    }
  }

  this.orbit = function (direction, automated, magnitude) {
    if (automated !== true) {
      if (!confirmBreakTopDownIfNecessary()) {
        return
      }
    }

    var delta

    var orbitSpeed

    if (magnitude) {
      orbitSpeed = magnitude
    } else if (automated) {
      orbitSpeed = _this.orbitSpeedAutomated
    } else {
      orbitSpeed = _this.orbitSpeed
    }

    if (direction == 'up') {
      delta = new THREE.Vector2(0, orbitSpeed)
    } else if (direction == 'down') {
      delta = new THREE.Vector2(0, -orbitSpeed)
    } else if (direction == 'left') {
      delta = new THREE.Vector2(-orbitSpeed, 0)
    } else if (direction == 'right') {
      delta = new THREE.Vector2(orbitSpeed, 0)
    } else {
      delta = new THREE.Vector2(0, 0)
    }

    var vector = new THREE.Vector3()

    vector.copy(editor.camera.position).sub(_this.target)

    //Swap Y and Z coordinates, we use Z as up
    vector = new THREE.Vector3(vector.x, vector.y, vector.z)

    if (vector.x == 0 && vector.y == 0) {
      _this.spherical.theta = Math.PI - 0.0001
      _this.spherical.phi = 0.0001
      _this.spherical.radius = vector.length()
    } else {
      _this.spherical.setFromVector3(vector)
    }

    _this.spherical.theta -= delta.x
    _this.spherical.phi += delta.y

    _this.spherical.makeSafe()

    var vectorFromSpherical = new THREE.Vector3().setFromSpherical(_this.spherical)

    //Swap Y and Z coordinates, we use Z as up
    vector = new THREE.Vector3(vectorFromSpherical.x, vectorFromSpherical.z, vectorFromSpherical.y)

    editor.camera.position.copy(_this.target).add(vector)

    //adjust camera up to make a smooth rotation
    //we want to rotate the up direction around the Z axis
    var axis = new THREE.Vector3(0, 0, 1)
    editor.camera.up.applyAxisAngle(axis, delta.x)

    //editor.camera.lookAt( _this.target );
    Utils.lookAtSafe(editor.camera, _this.target)

    // if (automated !== true) {
    //   editor.gridVisibility(true)
    // }

    _this.dispatchEvent(changeEvent)

    if (automated !== true) {
      if (!editor.interactive() || !MapHelper.interactive()) {
        $('#controlSelection').addClass('pulsate')
      }
    }
  }

  this.zoomCameraByFactor = function (factor) {
    var zoomBefore = MapHelper.activeMapInstance.mapData.zoomTarget

    this.applyScaleFactorToCamera(factor)
    _this.dispatchChangeEventThrottled()

    if (Math.floor(MapHelper.activeMapInstance.mapData.zoomTarget) - Math.floor(zoomBefore) != 0) {
      // @TODO: Remove hack to fix bug when clicking plus/minus zoom buttons when a zoom change is triggered.
      setTimeout(function () {
        window.studioDebug && console.log('Force extra matchScene() to fix issue when zoom changes')
        _this.dispatchEvent(changeEvent)
      }, 1)
    }
  }

  this.dispatchChangeEvent = function () {
    _this.dispatchEvent(changeEvent)
  }

  // 50ms =
  this.changeEventThrottleRateMaxFramesPerSecond = 30

  this.dispatchChangeEventThrottled = window.Utils.throttle(this.dispatchChangeEvent)

  this.applyScaleFactorToCamera = function (factor) {
    var newMetersPerPixelRaw = editor.metersPerPixel() * factor

    var limits = {
      min: MapHelper.activeMapInstance ? MapHelper.minMetersPerPixel(MapHelper.activeMapInstance) : 0.0001,
      max: MapHelper.activeMapInstance ? MapHelper.maxMetersPerPixel(MapHelper.activeMapInstance) : 100.0,
    }

    newMetersPerPixel = Math.max(Math.min(newMetersPerPixelRaw, limits.max), limits.min)

    // console.log('applyScaleFactorToCamera', MapHelper.minMetersPerPixel(MapHelper.activeMapInstance), newMetersPerPixel)

    editor.metersPerPixel(newMetersPerPixel)
    editor.refreshCamera()
  }

  this.zoomCamera = function () {
    if (_state === STATE.TOUCH_ZOOM) {
      var factor = _touchZoomDistanceEnd / _touchZoomDistanceStart

      _zoomStart.y += (factor - 1) * 0.1

      _touchZoomDistanceStart = _touchZoomDistanceEnd
    }

    if (true) {
      var factor = 1.0 + (_zoomEnd.y - _zoomStart.y) * _this.zoomSpeed

      if (!_this.zoomAllowed) {
        // console.log("Warning: _this.zoomAllowed is false")
        factor = 1.0
        _zoomStart.copy(_zoomEnd)
      }

      if (factor !== 1.0 && factor > 0.0) {
        //Old method for perspective camera
        //_eye.multiplyScalar( factor );

        if (_this.allowFractionalZoomLevels === false) {
          //disable animation and jump to next zoom level

          if (factor < 1) {
            factor = 0.5
          } else if (factor > 1) {
            factor = 2.0
          }
        }

        //this.applyScaleFactorToCamera(factor)
        var undoFactor = 1 / factor
        window.editor.execute(new window.ZoomCameraByFactorCommand(factor, undoFactor))

        if (_this.staticMoving || _this.allowFractionalZoomLevels === false) {
          //When fractional zoom levels are disabled disable animation automatically
          _zoomStart.copy(_zoomEnd)
        } else {
          _zoomStart.y += (_zoomEnd.y - _zoomStart.y) * this.zoomDynamicDampingFactor
        }

        if ((factor < 1.000001 && factor > 0.999999) || factor < 0.000001) {
          _zoomStart.copy(_zoomEnd)
          return false
        } else {
          return true
        }
      } else if (factor < 0) {
        // Without this catch, if we zoom in very fast the map scale gets too large and starts to be ignored,
        // but mouse wheel keeps increasing, resulting in factor < 0.
        // If the user rolls the mouse wheel out aggressively they will eventually return to factor >=0 and zoom will
        // start working again. But if we reset it here, it will immediately allow zooming back out and not getting
        // stuck with a large negative zoom factor.
        console.warn(
          'Camera zoom outside expected bounds, perhaps map scale has reached maximum. Reset zoom factor to prevent mouse zoom/wheel from being blocked'
        )
        _zoomStart.y = _zoomEnd.y
      }
    }
  }

  this.panCamera = function () {
    var mouseChange = _panEnd.clone().sub(_panStart)

    if (mouseChange.lengthSq() > 0.000001) {
      // if ( mouseChange.lengthSq() ) {

      if (!_this.panAllowed) {
        _panStart.copy(_panEnd)
        mouseChange.fromArray([0, 0])
      }

      // Scale relative to meters-per-pixel
      var panScaleFactor = editor.metersPerPixel() / 0.05715167639192264

      mouseChange.multiplyScalar(_eye.length() * _this.panSpeed * panScaleFactor)

      var pan

      // if (true) {
      // pan = _eye.clone().setLength(mouseChange.length())
      // var panLeft = new THREE.Vector3().setFromMatrixColumn(editor.camera.matrix, 0)
      // var panUp = new THREE.Vector3().setFromMatrixColumn(editor.camera.matrix, 1)
      // pan = new THREE.Vector3()
      var panLeft = _eye.clone().cross(editor.camera.up).setLength(mouseChange.x)

      // var panUp = editor.camera.up.clone().setLength(mouseChange.y)
      // panUp.z = 0
      var panUp = _eye
        .clone()
        .cross(editor.camera.up)
        .applyAxisAngle(new THREE.Vector3(0, 0, -1), Math.PI / 2)
        .setLength(mouseChange.y)

      pan = new THREE.Vector3().addVectors(panLeft, panUp)
      // } else {
      // }

      editor.camera.position.add(pan)
      _this.target.add(pan)
      //_this.target.z = 0

      if (_this.staticMoving) {
        _panStart = _panEnd
      } else {
        _panStart.add(mouseChange.subVectors(_panEnd, _panStart).multiplyScalar(_this.panDynamicDampingFactor))
      }

      // }else{
      //
      //     //console.log('mouseChange infintiesimal, copy start into end')
      //     _panEnd = _panStart
      //
    }
  }

  this.checkDistances = function () {
    if (!_this.noZoom || !_this.noPan) {
      if (editor.camera.position.lengthSq() > _this.maxDistance * _this.maxDistance) {
        editor.camera.position.setLength(_this.maxDistance)
      }

      if (_eye.lengthSq() < _this.minDistance * _this.minDistance) {
        editor.camera.position.addVectors(_this.target, _eye.setLength(_this.minDistance))
      }
    }
  }

  this.update = function () {
    _eye.subVectors(editor.camera.position, _this.target)

    if (window.ViewHelper.selectedView() && !window.ViewHelper.selectedView().allowTilt) {
      if (!Utils.isCoincident(_rotateEnd, _rotateStart) && _rotateEnd.length() > 0) {
        //We are about to apply a rotation.. last chance to cancel it before breaking top-down linkage
        //if(!confirmBreakTopDownIfNecessary()){
        //Disabled prompting to break top-down automatically when right-click-dragging.
        //Now moved to View menu to avoid accidentally triggering prompt all the time.

        _rotateStart = new THREE.Vector3()
        _rotateEnd = new THREE.Vector3()

        _rotateStartMouse = new THREE.Vector3()
        _rotateEndMouse = new THREE.Vector3()

        _state = STATE.NONE
      }
    }

    if (_this.keys.left) {
      _this.orbit('left')
      return
    } else if (_this.keys.right) {
      _this.orbit('right')
      return
    } else if (_this.keys.up) {
      _this.orbit('up')
      return
    } else if (_this.keys.down) {
      _this.orbit('down')
      return
    }

    var upChanged = false
    var rotationChanged

    if (!_this.noRotate) {
      if (this.useTrackballControlStyle === true) {
        upChanged = _this.rotateCameraTrackball()
        rotationChanged = !_rotateEndMouse.equals(_rotateStartMouse)
      } else {
        _this.rotateCameraOldOrbit()

        // What is this? Why weren't we using _rotateEndMouse/_rotateStartMouse... typo?
        //rotationChanged = !_rotateEnd.equals(_rotateStart)
        rotationChanged = !_rotateEndMouse.equals(_rotateStartMouse)
      }
    }

    var zoomChanged = false

    if (!_this.noZoom) {
      zoomChanged = _this.zoomCamera()
    }

    if (!_this.noPan) {
      _this.panCamera()
    }

    // Force distance from camera position to target to always be equal to 100 meters
    // to ensure near/far camera planes are suitable. Do this immediately before we apply _eye so we only
    // need to correct it once
    _eye.normalize().multiplyScalar(100)

    editor.camera.position.addVectors(_this.target, _eye)

    _this.checkDistances()

    //editor.camera.lookAt( _this.target );
    Utils.lookAtSafe(editor.camera, _this.target)

    // If auto-hover enabled, ensure we are above the terrain
    var minElevation = 10
    if (!_this.useTrackballControlStyle && editor.camera.position.z < minElevation) {
      _this.orbit('up', true, -0.05)
    }

    if (_this.isAnimatedTurntable()) {
      //If no other interaction, then check for animated turntable
      // Skip cameraAnimationFinished from firing or animation will be stopped
      _this.orbit('left', true)
      return
    } else if (
      lastPosition.distanceToSquared(editor.camera.position) > 0.000001 ||
      zoomChanged ||
      upChanged ||
      rotationChanged
    ) {
      if ((_state === STATE.ROTATE || _state === STATE.TOUCH_ROTATE) && !_this.noRotate) {
        //If rotation guide not shown display it now
        _this.updateTrackballCircleGuide(true)
      }

      // if (!_this.isAnimatedTurntable()) {
      //   editor.gridVisibility(true)
      // }

      // Critical performance consideration: We do NOT want to throttle the change events.
      // While throttling would reduce the load on the CPU the problem is that they can get doubled-up because they
      // use timeouts to trigger the change event instead of firing them as soon as we are ready for the next
      // animation frame. Throttling may not be too bad when things are runnning smoothly, but when they run a bit
      // slower it will get extremely choppy.
      _this.dispatchEvent(changeEvent) // fire every time but update() will only fire when animation frame is ready
      // _this.dispatchChangeEventThrottled() // only fire sporadically but the events can then hit all together

      lastPosition.copy(editor.camera.position)

      //Pulsate the control dropdown so the user is alerted to the fact they are not linked
      if (!_this.isAnimatedTurntable()) {
        if (!editor.interactive() || !MapHelper.interactive()) {
          $('#controlSelection').addClass('pulsate')
        }
      }
      if (viewport.animationRequests.camera?.size === 0) {
        editor.signals.cameraAnimationStarted.dispatch()
      }
    } else if (_state === STATE.NONE) {
      if (viewport.animationRequests.camera?.size > 0) {
        _this.updateTrackballCircleGuide(false)

        if (!_this.isAnimatedTurntable()) {
          $('#controlSelection').removeClass('pulsate')
        }

        editor.signals.cameraAnimationFinished.dispatch()

        if (window.ViewHelper.selectedView()?.mapData?.mapType === 'Image') {
          /*
          @TODO: Remove this nasty/heavy hack. To verify it can be removed:
            - create an uploaded "Image" view.
            - align a panel group with something in the image so you can tell if it is aligned
            - pan the camera and release the mouse while the mouse is still moving (flicking it away)
            - the imagery will probably be aligned with the panels now, but after save and reload it may not be aligned.
            - save the design then view the online proposal in another tab to see if the imagery stays aligned with the panels
            - repeat the process another few times to ensure it wasn't just luck.
            - you can also run this command to verify that the data we will save matches what we are seeing on the screen
            - console.log(editor.sceneAsJSON().object.userData.views[0].mapData.center, MapHelper.activeMapInstance.toMapData().center)

          delayed call to saveView to ensure this happens after Map (OpenLayers Image) animation has run so mapData.center will be up-to-date
          otherwise the final movement will not be saved into views[n].mapData.center and imagery may be misaligned on reload
          */

          var selectedViewIndex = window.ViewHelper.selectedViewIndex()
          setTimeout(function () {
            if (!editor.interactive()) {
              console.warn('Attempting call to delayed saveView() but editor is not interactive. Ignore')
              // This can happen if you set interactive==false soon after moving the map.
              // do nothing, unfortunately our fix will not be applied and we may lose the final position update
            } else {
              console.log(
                'delayed call to saveView to ensure this happens after Map (OpenLayers Image) animation has run so mapData.center will be up-to-date'
              )
              MapHelper.matchScene()
              ViewHelper.saveView(selectedViewIndex)
            }
          }, 200)
        }
      }
    }
  }

  this.reset = function (_target0, _position0, _up0, _triggerDispatchEvent) {
    if (_target0) {
      _this.target0.copy(_target0)
    }
    if (_position0) {
      _this.position0.copy(_position0)
    }
    if (_up0) {
      _this.up0.copy(_up0)
    }

    _state = STATE.NONE
    _prevState = STATE.NONE

    _this.target.copy(_this.target0)
    //_this.target.z = 0

    editor.camera.position.copy(_this.position0)
    editor.camera.up.copy(_this.up0)

    _eye.subVectors(editor.camera.position, _this.target)

    //editor.camera.lookAt( _this.target );
    Utils.lookAtSafe(editor.camera, _this.target)

    if (_triggerDispatchEvent !== false) {
      _this.dispatchEvent(changeEvent)
    }

    lastPosition.copy(editor.camera.position)

    _rotateStart = new THREE.Vector3()
    _rotateEnd = new THREE.Vector3()

    _rotateStartMouse = new THREE.Vector3()
    _rotateEndMouse = new THREE.Vector3()
  }

  // listeners

  function keydown(event) {
    if (_this.enabled === false) return

    if (Designer && Designer.listeningForEvents && !Designer.listeningForEvents('key')) {
      return
    }

    window.removeEventListener('keydown', keydown)

    _prevState = _state

    if (event.keyCode == 38) {
      _this.keys.up = true
    } else if (event.keyCode == 40) {
      _this.keys.down = true
    } else if (event.keyCode == 37) {
      _this.keys.left = true
    } else if (event.keyCode == 39) {
      _this.keys.right = true
    } else if (event.keyCode == 16) {
      _this.keys.shift = true
    }

    if (_state !== STATE.NONE) {
      return
    }
    // } else if ( event.keyCode === _this.keys[ STATE.ROTATE ] && !_this.noRotate ) {
    //
    // 	_state = STATE.ROTATE;
    //
    // } else if ( event.keyCode === _this.keys[ STATE.ZOOM ] && !_this.noZoom ) {
    //
    // 	_state = STATE.ZOOM;
    //
    // } else if ( event.keyCode === _this.keys[ STATE.PAN ] && !_this.noPan ) {
    //
    // 	_state = STATE.PAN;
    //
    // }
  }

  function keyup(event) {
    if (_this.enabled === false) return

    if (Designer && Designer.listeningForEvents && !Designer.listeningForEvents('key')) {
      return
    }

    _state = _prevState

    if (event.keyCode == 38) {
      _this.keys.up = false
    } else if (event.keyCode == 40) {
      _this.keys.down = false
    } else if (event.keyCode == 37) {
      _this.keys.left = false
    } else if (event.keyCode == 39) {
      _this.keys.right = false
    } else if (event.keyCode == 16) {
      _this.keys.shift = false
    }

    window.addEventListener('keydown', keydown, false)
  }

  function confirmBreakTopDownIfNecessary() {
    if (!_this.isTopDown()) {
      return true
    }

    //Confirm breaks keyup so clear pressed-keys now
    _this.keys = JSON.parse(JSON.stringify(_this.keysInitial))

    _rotateStart = new THREE.Vector3()
    _rotateEnd = new THREE.Vector3()

    _rotateStartMouse = new THREE.Vector3()
    _rotateEndMouse = new THREE.Vector3()

    _state = STATE.NONE

    if (
      confirm(
        'Tilting/rotating this view will cause it to no longer be top-down. Design and Imagery will need to be adjusted separately. Are you sure you want to tilt/rotate?'
      )
    ) {
      _this.breakTopDown()
      return true
    } else {
      return false
    }
  }

  function snapTargetToTerrain() {
    // Update target if it intersects with terrain
    // Unfortunately the center of the screen is no longer always the center ray of the camera
    // due to possible left margin being enabled
    var viewportRect = viewport.rect()

    var screenPixelForSample = new THREE.Vector3(
      editor.leftMarginPixels + (viewportRect.width - editor.leftMarginPixels) / 2,
      viewportRect.height / 2
    )

    var usableScreenCenterFraction = new THREE.Vector2(
      screenPixelForSample.x / viewportRect.width,
      screenPixelForSample.y / viewportRect.height
    )

    var terrainPoint = viewport.getIntersectionWithTerrain(usableScreenCenterFraction)
    if (terrainPoint) {
      _this.target.copy(terrainPoint)
    }
  }

  function mousedown(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    snapTargetToTerrain()

    if (_state === STATE.NONE) {
      _state = event.button
    }

    if (_state === STATE.ROTATE && !_this.noRotate) {
      _rotateStart = _rotateEnd = _this.getMouseProjectionOnBall(event.clientX, event.clientY)
      _rotateStartMouse = _rotateEndMouse = new THREE.Vector3(
        event.clientX * _this.rotationSpeedNew,
        event.clientY * _this.rotationSpeedNew,
        0
      )
    } else if (_state === STATE.ZOOM && !_this.noZoom) {
      _zoomStart = _zoomEnd = _this.getMouseOnScreen(event.clientX, event.clientY)
    } else if (_state === STATE.PAN && !_this.noPan) {
      // This is required for registering clicks on a glb loaded into another Object3D but will it cause
      // other problems like performance isuses or errors?
      var recursiveObjectUnderClick = true

      var objectUnderMouse = viewport.objectUnderClick(
        event,
        viewport.objectsSelectableAndModules(),
        false,
        undefined,
        recursiveObjectUnderClick
      )

      var recursive = true

      // Old transform controls
      // var helperControls =  viewport.transformControls._objects()

      // New Handles
      var helperControls = editor.controllers?.Handle
        ? Object.values(editor.controllers.Handle.handles).filter((o) => o.visible)
        : []

      var helperUnderMouse = viewport.objectUnderClick(event, helperControls, false, recursive)

      if (!objectUnderMouse && !helperUnderMouse) {
        _panStart = _panEnd = _this.getMouseOnScreen(event.clientX, event.clientY)
      } else {
        // Avoid adding mousemove and mouseup events because we started clicking on something else
        // Reset state to NONE because we are canceling pan mode
        _state = STATE.NONE
        return
      }
    }

    document.addEventListener('mousemove', mousemove, false)
    document.addEventListener('mouseup', mouseup, false)

    editor.signals.cameraAnimationStarted.dispatch()
  }

  function mousemove(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    if (_state === STATE.ROTATE && !_this.noRotate) {
      _rotateEnd = _this.getMouseProjectionOnBall(event.clientX, event.clientY)
      _rotateEndMouse = new THREE.Vector3(
        event.clientX * _this.rotationSpeedNew,
        event.clientY * _this.rotationSpeedNew,
        0
      )
    } else if (_state === STATE.ZOOM && !_this.noZoom) {
      _zoomEnd = _this.getMouseOnScreen(event.clientX, event.clientY)
    } else if (_state === STATE.PAN && !_this.noPan) {
      _panEnd = _this.getMouseOnScreen(event.clientX, event.clientY)
    }
  }

  function mouseup(event) {
    if (_this.enabled === false) return

    if (event) event.preventDefault()
    //event.stopPropagation()

    _state = STATE.NONE

    // Clear rotation type so next mousedown can set it based on original
    // mouse position inside/outside radius
    _this.rotateOutsideRadius = null

    _this.updateTrackballCircleGuide(false)

    document.removeEventListener('mousemove', mousemove)
    document.removeEventListener('mouseup', mouseup)
  }

  function mousewheel(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    var delta = 0

    if (event.wheelDelta) {
      // WebKit / Opera / Explorer 9

      delta = event.wheelDelta / 40
    } else if (event.detail) {
      // Firefox

      delta = -event.detail / 3
    }

    _zoomStart.y += delta * 0.01

    editor.signals.cameraAnimationStarted.dispatch()
  }

  function touchstart(event) {
    if (_this.enabled === false) return

    switch (event.touches.length) {
      case 2:
        snapTargetToTerrain()
        _state = STATE.TOUCH_ROTATE
        _rotateStart = _rotateEnd = _this.getMouseProjectionOnBall(event.clientX, event.clientY)
        _rotateStartMouse = _rotateEndMouse = new THREE.Vector3(
          event.touches[0].pageX * _this.rotationSpeedNew,
          event.touches[0].pageY * _this.rotationSpeedNew,
          0
        )

        // Also record pinch distance so we can handle zoom too
        var pinchDistance =
          Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY) *
          _this.pinchScaling

        _zoomStart = _zoomEnd = new THREE.Vector2(-pinchDistance, -pinchDistance)

        break

      case 3:
        if (!_this.zoomAllowed) {
          console.log('Warning: _this.zoomAllowed is false')
        } else {
          _state = STATE.TOUCH_ZOOM
          // var dx = event.touches[0].pageX - event.touches[1].pageX
          // var dy = event.touches[0].pageY - event.touches[1].pageY
          // _touchZoomDistanceEnd = _touchZoomDistanceStart = Math.sqrt(dx * dx + dy * dy)
          _zoomStart = _zoomEnd = _this.getMouseOnScreen(event.touches[0].pageX, event.touches[0].pageY)
        }
        break

      case 1:
        var panPermitted = true
        //do not pan if the styus is currently down (for any touches)
        for (var i = 0; i < event.touches.length; i++) {
          if (event.touches[i].touchType === 'stylus') {
            panPermitted = false
            break
          }
        }

        panPermitted =
          panPermitted && !viewport.objectUnderClick(event.touches[0], viewport.objectsSelectableAndModules(), false)

        var recursive = true
        panPermitted =
          panPermitted &&
          !viewport.objectUnderClick(event.touches[0], viewport.transformControls._objects(), false, recursive)

        if (panPermitted) {
          _state = STATE.TOUCH_PAN
          _panStart = _panEnd = _this.getMouseOnScreen(event.touches[0].pageX, event.touches[0].pageY)
        } else {
          _state = STATE.NONE
        }

        break

      default:
        _state = STATE.NONE
    }

    editor.signals.cameraAnimationStarted.dispatch()
  }

  function touchmove(event) {
    if (_this.enabled === false) return

    event.preventDefault()
    //event.stopPropagation();

    // Special case, detect whether this is a two-finter pinch and treat as zoom instead

    var touches = event.touches.length

    if (event.touches.length === 2) {
      var currentPinchDistance =
        Math.hypot(event.touches[0].pageX - event.touches[1].pageX, event.touches[0].pageY - event.touches[1].pageY) *
        _this.pinchScaling

      var _zoomEndTentative = new THREE.Vector2(currentPinchDistance, currentPinchDistance)
      var newScale = _zoomEndTentative.y / _zoomStart.y
      _zoomEnd = new THREE.Vector2(-_zoomEndTentative.x, -_zoomEndTentative.y)
    }

    switch (touches) {
      case 2:
        if (_state === STATE.TOUCH_ROTATE && !_this.noRotate) {
          // _rotateEnd = _this.getMouseProjectionOnBall(event.touches[0].pageX, event.touches[0].pageY)
          _rotateEnd = _this.getMouseProjectionOnBall(event.touches[0].pageX, event.touches[0].pageY)
          _rotateEndMouse = new THREE.Vector3(
            event.touches[0].pageX * _this.rotationSpeedNew,
            event.touches[0].pageY * _this.rotationSpeedNew,
            0
          )
        }
        break

      case 3:
        if (_state === STATE.TOUCH_ZOOM && !_this.noZoom) {
          // var dx = event.touches[0].pageX - event.touches[1].pageX
          // var dy = event.touches[0].pageY - event.touches[1].pageY
          // _touchZoomDistanceEnd = Math.sqrt(dx * dx + dy * dy)
          _zoomEnd = _this.getMouseOnScreen(event.touches[0].pageX, event.touches[0].pageY)
        }
        break

      case 1:
        if (_state === STATE.TOUCH_PAN && !_this.noPan) {
          _panEnd = _this.getMouseOnScreen(event.touches[0].pageX, event.touches[0].pageY)
          // _panEnd = _this.getMouseOnScreen(event.clientX, event.clientY)
        }
        break

      default:
        _state = STATE.NONE
    }
  }

  function touchend(event) {
    touchmove(event)

    _this.rotateOutsideRadius = null

    _state = STATE.NONE

    editor.signals.cameraAnimationStarted.dispatch()
  }

  function contextmenu(event) {
    event.preventDefault()
  }

  this.addEventListener('change', function () {
    viewport.transformControls.update()
    editor.signals.cameraChanged.dispatch(editor.camera)
  })

  editor.signals.editorCleared.add(function () {
    editor.cameraCenter.set(0, 0, 0)
    viewport.render(true)
  })

  this.activate = function () {
    this.active = true

    this.target = editor.cameraCenter

    this.domElement.addEventListener('contextmenu', contextmenu, false)
    this.domElement.addEventListener('mousedown', mousedown, false)
    this.domElement.addEventListener('mousewheel', mousewheel, false)
    this.domElement.addEventListener('DOMMouseScroll', mousewheel, false) // firefox
    this.domElement.addEventListener('touchstart', touchstart, false)
    this.domElement.addEventListener('touchend', touchend, false)
    this.domElement.addEventListener('touchmove', touchmove, false)
    window.addEventListener('keydown', keydown, false)
    window.addEventListener('keyup', keyup, false)
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
    this.outputs.activation.dispatch(this.active)
  }

  this.deactivate = function () {
    this.active = false

    this.domElement.removeEventListener('contextmenu', contextmenu, false)
    this.domElement.removeEventListener('mousedown', mousedown, false)
    this.domElement.removeEventListener('mousewheel', mousewheel, false)
    this.domElement.removeEventListener('DOMMouseScroll', mousewheel, false) // firefox
    this.domElement.removeEventListener('touchstart', touchstart, false)
    this.domElement.removeEventListener('touchend', touchend, false)
    this.domElement.removeEventListener('touchmove', touchmove, false)
    window.removeEventListener('keydown', keydown, false)
    window.removeEventListener('keyup', keyup, false)
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
    this.outputs.activation.dispatch(this.active)
  }

  this.handleResize()

  editor.signals.windowResize.add(function () {
    _this.handleResize()
  })

  this.checkZoomAllowed = function () {
    const mapData = MapHelper.activeMapInstance?.mapData
    if (mapData && MapHelper.zoomAllowedWhenBothLayersActive(mapData) === true) {
      return true
    } else if (editor.interactive() !== true) {
      return true
    } else if (MapHelper.interactive() !== true) {
      return true
    } else {
      return false
    }
  }

  this.checkPanAllowed = function () {
    const mapData = MapHelper.activeMapInstance?.mapData
    if (mapData && MapHelper.panAllowedWhenBothLayersActive(mapData) === true) {
      return true
    } else if (editor.interactive() !== true) {
      return true
    } else if (MapHelper.interactive() !== true) {
      return true
    } else {
      return false
    }
  }

  editor.signals.mapChanged.add(function () {
    if (!MapHelper.activeMapInstance) {
      console.log('Ignore signals.mapChanged because MapHelper.activeMapInstance not yet ready')
      return
    }

    _this.allowFractionalZoomLevels = MapHelper.fractionalZoomLevelsAllowed(MapHelper.activeMapInstance.mapData)
    _this.zoomAllowed = _this.checkZoomAllowed()
    _this.panAllowed = _this.checkPanAllowed()

    //if interactive and "none" map type then show grid
    if (editor.interactive() === true && MapHelper.activeMapInstance.mapType === 'None') {
      editor.gridVisibility(true)
    } else {
      editor.gridVisibility(false)
    }
  })

  editor.signals.controlModeChanged.add(function () {
    _this.zoomAllowed = _this.checkZoomAllowed()
    _this.panAllowed = _this.checkPanAllowed()
  })

  this.isTopDown = function () {
    if (this.notTopDown === true) {
      return false
    }
    return _eye.x == 0 && _eye.y == 0 && _eye.z > 0
  }

  this.orientationIsApproximatelyTopDown = function () {
    /*
    Assumes that distance from target is always 100 meters
    */
    return editor.camera.position.z - this.target.z > 99.9
  }

  this.breakTopDown = function () {
    if (this.isTopDown()) {
      _eye.x += 0.00001
      this.target.x += 0.00001
    }
  }

  this.fixTopDown = function () {
    if (!this.isTopDown()) {
      _eye.x -= 0.00001
      this.target.x -= 0.00001
    }
  }
}

CameraController.prototype = Object.create(THREE.EventDispatcher.prototype)
CameraController.prototype.constructor = CameraController
