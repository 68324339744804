/**
 * @author adampryor
 */

function OsGroup(options) {
  THREE.Object3D.call(this)
  this.type = 'OsGroup'
  this.objects = options && options.objects ? options.objects : []
  this.deletedByUserAction = false
  this.userData.excludeFromExport = true
  this.centroid = new THREE.Vector3()

  // Not used visually, only used for sizing the BoxHelper when selected
  // this.refreshPosition()
  // unable to highlight second moduleGrid with this method in constructor
}

OsGroup.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsGroup,
  toolsActive: function () {
    var hasChildWhoIsManaged = this.objects.find((o) => o.isManaged || (o.isManagedByParent && o.isManagedByParent()))
    return {
      translateXY: !hasChildWhoIsManaged,
      translateZ: !hasChildWhoIsManaged,
      translateX: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
      delete: !hasChildWhoIsManaged,
      duplicate: false,
    }
  },
  _hash: null,
  getHash: ObjectBehaviors.changeDetection.getHash,
  saveHash: ObjectBehaviors.changeDetection.saveHash,
  clearHash: ObjectBehaviors.changeDetection.clearHash,
  hasChanged: ObjectBehaviors.changeDetection.hasChanged,
  asObject: function () {
    return {
      position: this.position ? this.position.toArray() : null,
      centroid: this.centroid ? this.centroid.toArray() : null,
      object_uuids: this.objects.map((o) => o.uuid),
    }
  },
  getBoxHelperVisibility: function () {
    return true
  },
  refreshPosition: function (editor) {
    this.boundingBox = this.getBoundingBox()
    this.centroid = this.boundingBox.getCenter(new THREE.Vector3())
    this.position.copy(this.centroid)

    if (this.geometry) {
      this.geometry.dispose()
    }

    this.geometry = new THREE.Geometry()

    if (this.objects.length === 0) {
      this.geometry.vertices.push(new THREE.Vector3(10, 10, 10), new THREE.Vector3(-10, -10, -10))
    } else {
      this.objects.forEach((object) => object.onSelect && object.onSelect(editor))
      this.geometry.vertices.push(
        new THREE.Vector3().subVectors(this.boundingBox.min, this.centroid),
        new THREE.Vector3().subVectors(this.boundingBox.max, this.centroid)
      )
    }
  },

  handleDelete: function (editor, commandUUID) {
    if (!commandUUID) {
      commandUUID = Utils.generateCommandUUIDOrUseGlobal()
    }
    this.objects.forEach((o) => {
      if (!!editor.objectByUuid(o.uuid)) {
        editor.deleteObject(o, commandUUID)
      }
    })
    editor.deselect()
  },

  duplicate: function (options) {
    this.objects.forEach((o) => {
      if (o.duplicate) {
        o.duplicate(options)
      }
    })
  },

  onRemove: function (editor) { },
  onDeselect: function (editor) {
    this.objects.forEach((object) => object.onDeselect && object.onDeselect())
    editor.removeObject(this, false)
  },

  getBoundingBox: function () {
    var boundingBox = new THREE.Box3()
    this.objects.forEach(function (o) {
      if (o.type === 'OsFacet' && !o.isNonSpatial()) {
        boundingBox.union(o.getBoundingBox())

        // extend down to ground
        boundingBox.expandByPoint(new THREE.Vector3(o.vertices[0].position.x, o.vertices[0].position.y, 0))
      } else if (o.type === 'OsAnnotation') {
        boundingBox.expandByPoint(new THREE.Vector3(o.position.x, o.position.y, 0))
      } else {
        // boundingBox.expandByPoint(o.position)
        boundingBox.expandByObject(o)
      }
    })
    return boundingBox
  },

  onChange: function (editor, force) {
    if (force === true) {
      this.clearHash()
    }

    if (!OsGroup.onChangeEnabled) {
      console.log('Skip onChange: this.onChangeEnabled is false')
      return
    }

    if (
      editor.controllers.CallbackStack &&
      editor.controllers.CallbackStack.active &&
      editor.controllers.CallbackStack.recordIfNewOrReturnFalse('OsGroup.onChange.' + this.uuid) === false
    ) {
      return
    }

    if (!this.centroid) {
      this.refreshPosition()
    }

    /*
    Due to floating nodes, we must first refresh the plane, then refloat nodes, then finally build the mesh
    */

    if (this.hasChanged()) {
      editor.uiPauseUntilComplete(
        function () {
          if (
            this.position.x != this.centroid.x ||
            this.position.y != this.centroid.y ||
            this.position.z != this.centroid.z
          ) {
            //copy into points and reset position
            var delta = new THREE.Vector3().subVectors(this.position, this.centroid)

            //collect all nodes first to avoid double counting nodes which belong to more than one selected facet
            var objectsToDrag = this.objects.filter((o) => o.type !== 'OsFacet')

            this.objects.forEach((o) => {
              if (o.type == 'OsFacet') {
                o.vertices.forEach((v) => {
                  if (objectsToDrag.indexOf(v) === -1) {
                    objectsToDrag.push(v)
                  }
                })
              }
            })

            objectsToDrag.forEach((o) => o.position.add(delta))

            //Change to position/centroid will trigger hasChanged below...
            this.centroid.copy(this.position)

            // Refresh facets first so floating objects don't get broken
            this.objects
              .filter((o) => o.type === 'OsFacet')
              .forEach(function (o) {
                o.getEdges().forEach((e) => e.refreshPosition())

                o.refreshPosition()
              }, this)

            this.objects.forEach(function (o) {
              if (o.type === 'OsModuleGrid' || o.type === 'OsObstruction') {
                var skipRemoveFloatingObject = true
                o.onChange(editor, skipRemoveFloatingObject)
              } else {
                o.onChange(editor)
              }
            }, this)
          }
        },
        this,
        'ui'
      )

      this.saveHash()
    }

    if (editor.viewport && editor.selected == this) {
      // editor.viewport.refreshSelectionBox()
      editor.viewport.render()
    }
  },

  getPermissionCheck: function () {
    const allowEditDesign = Designer?.permissions.canEdit()
    const hasPermission = this.objects.every((object) =>
      object.getPermissionCheck ? object.getPermissionCheck() : allowEditDesign
    )
    return hasPermission
  },

  getContextMenuItems: function (position, _editor) {
    return []
  },
})

OsGroup.onChangeEnabled = true

// OsGroup is included because we its children will be merged into the new selection group

OsGroup.filterSelectableObjects = (objects) =>
  objects.filter((o) => {
    if (o.type === 'OsNode' && o.getEdges().length > 0) {
      //ignore nodes from edge
      return false
    } else if (o.type === 'OsEdge' && (o.getFacets().length > 0) || (!o.visible)) {
      //ignore edges from facet or invisible edges
      return false
    }
    return (
      [
        'OsNode',
        'OsTree',
        'OsModuleGrid',
        'OsObstruction',
        'OsClipper',
        'OsGroup',
        'OsEdge',
        'OsFacet',
        'OsAnnotation',
      ].indexOf(o.type) !== -1
    )
  })
