// Global Dependencies: THREE, jsts, Editor, OsModuleGrid

// Extension Description:
// Adds the ability to generate an outline of a module grid
// An outline is a 3D mesh that follows the shape of the module grid
// An outline is not fixed to the camera
// Outlines are generated in groups - which must be managed independently
class ModuleGridOutliner {
  static name = 'ModuleGridOutliner'

  isActive = false

  #editorInstance

  #jstsGeomFactory = new window.jsts.geom.GeometryFactory()
  #container = new window.THREE.Group()

  #defaultOutlineMaterial = new window.THREE.MeshBasicMaterial({
    color: 0xffac1c,
  })

  #cleanup = []

  constructor(editorInstance) {
    if (!(editorInstance instanceof Editor)) {
      throw new Error('Cannot instantiate ModuleGridOutliner extension: invalid Editor instance.')
    }
    this.#editorInstance = editorInstance
    this.#container.name = 'EditorExtension::ModuleGridOutliner'
    this.#container.userData.excludeFromExport = true
  }

  activate = () => {
    if (this.isActive) return

    this.#editorInstance.scene.add(this.#container)
    this.#cleanup.push(() => this.#editorInstance.scene.remove(this.#container))

    this.#editorInstance.signals.objectRemoved.add(this.#onEditorObjectRemoved)
    this.#cleanup.push(() => this.#editorInstance.signals.objectRemoved.remove(this.#onEditorObjectRemoved))

    this.#editorInstance.signals.editorCleared.add(this.#onEditorCleared)
    this.#cleanup.push(() => this.#editorInstance.signals.editorCleared.remove(this.#onEditorCleared))

    this.isActive = true
  }

  deactivate = () => {
    if (!this.isActive) return

    this.#cleanup.forEach((f) => f())
    this.#cleanup = []

    this.isActive = false
  }

  createGroup = (groupName, moduleGrids, outlineMaterial) => {
    const existingGroup = this.#container.children.find((group) => group.name === groupName)
    if (!!existingGroup) return existingGroup

    if (moduleGrids.length === 0) return

    const group = new window.THREE.Group()
    group.name = groupName
    group.userData.excludeFromExport = true
    group.userData.moduleGrids = moduleGrids
    group.userData.material = outlineMaterial || this.#defaultOutlineMaterial
    group.userData.thickness = null
    group.generate = (thickness) => {
      this.generate(groupName, thickness || 0.4)
    }
    this.#container.add(group)

    return group
  }

  removeGroup = (groupName) => {
    const group = this.#container.children.find((group) => group.name === groupName)
    if (!group) return

    group.children.forEach((outlineMeshGroup) => {
      outlineMeshGroup.children.forEach((outlineMesh) => {
        outlineMesh.geometry.dispose()
      })
    })
    group.remove(...group.children)

    this.#container.remove(group)
  }

  refreshGroup = (groupName, render = false) => {
    const group = this.#container.children.find((group) => group.name === groupName)
    if (!group) return

    group.children.forEach((outlineMeshGroup, index) => {
      const moduleGrid = group.userData.moduleGrids[index]
      outlineMeshGroup.children.forEach((outlineMesh) => {
        outlineMesh.position.copy(moduleGrid.position)
        outlineMesh.position.z += 0.01
        outlineMesh.quaternion.copy(moduleGrid.quaternion)
      })
    })

    if (!this.#container.parent) {
      this.#editorInstance.scene.add(this.#container)
    }
    if (render) this.#editorInstance.render()
  }

  generate = (groupName, outlineWidth) => {
    const group = this.#container.children.find((group) => group.name === groupName)
    if (!group) return

    group.children.forEach((outlineMeshGroup) => {
      outlineMeshGroup.children.forEach((outlineMesh) => {
        outlineMesh.geometry.dispose()
      })
    })
    group.remove(...group.children)

    group.userData.moduleGrids.forEach((moduleGrid) => {
      if (moduleGrid.getBuildableCells().length === 0) return
      const outlinePolygon = this.#createOutlinePolygonFromModuleGrid(moduleGrid, outlineWidth)
      const outlineMeshes = this.#createOutlineMeshesFromOutlinePolygon(moduleGrid, outlinePolygon)
      const outlineMeshGroup = new window.THREE.Group()
      outlineMeshes.forEach((mesh) => {
        outlineMeshGroup.add(mesh)
      })
      outlineMeshGroup.name = moduleGrid.uuid
      group.add(outlineMeshGroup)
    })

    if (!this.#container.parent) {
      this.#editorInstance.scene.add(this.#container)
    }
    this.#editorInstance.render()
  }

  #onEditorObjectRemoved = (object) => {
    if (!(object instanceof OsModuleGrid)) return

    this.#container.children.forEach((group) => {
      const outlineMeshGroup = group.children.find((g) => g.name === object.uuid)
      if (outlineMeshGroup) {
        // remove the outline mesh group from scene
        group.remove(outlineMeshGroup)
        // dispose each of the outline mesh's geometry instances
        outlineMeshGroup.children.forEach((outlineMesh) => outlineMesh.geometry.dispose())
        // update the list of module grids in the outline group
        group.userData.moduleGrids = group.userData.moduleGrids.filter((mg) => mg !== object)
      }
    })
  }

  #onEditorCleared = () => {
    // remove all module grid outlines currently existing
    const groups = this.#container.children
    groups.forEach((g) => this.removeGroup(g.name))
  }

  #createOutlinePolygonFromModuleGrid = (moduleGrid, buffer = 0.4) => {
    const BufferOp = window.jsts.operation.buffer.BufferOp
    const BufferParams = window.jsts.operation.buffer.BufferParameters

    const tiltAngle = moduleGrid.panelTiltOverride || 0
    let allModulesPolygonShrunk = null

    moduleGrid.getBuildableCells().forEach((cellCoordStr) => {
      const module = moduleGrid.moduleObjects[cellCoordStr]
      if (!module) return

      let [moduleWidth, moduleHeight] = module.size // already accounts for landscape/portrait orientation
      moduleHeight = moduleHeight * Math.cos(tiltAngle * window.THREE.Math.DEG2RAD)

      const linearRing = this.#jstsGeomFactory.createLinearRing([
        new window.jsts.geom.Coordinate(module.position.x - moduleWidth / 2, module.position.y + moduleHeight / 2),
        new window.jsts.geom.Coordinate(module.position.x + moduleWidth / 2, module.position.y + moduleHeight / 2),
        new window.jsts.geom.Coordinate(module.position.x + moduleWidth / 2, module.position.y - moduleHeight / 2),
        new window.jsts.geom.Coordinate(module.position.x - moduleWidth / 2, module.position.y - moduleHeight / 2),
        new window.jsts.geom.Coordinate(module.position.x - moduleWidth / 2, module.position.y + moduleHeight / 2),
      ])

      let shrunkModulePolygon = this.#jstsGeomFactory.createPolygon(linearRing, [])
      let bufferOp = new BufferOp(
        shrunkModulePolygon,
        new window.jsts.operation.buffer.BufferParameters(
          10, // is this parameter even processed if we don't want rounded corners??
          BufferParams.CAP_FLAT,
          BufferParams.JOIN_MITRE,
          BufferParams.DEFAUT_MITRE_LIMIT
        )
      )

      shrunkModulePolygon = bufferOp.getResultGeometry(-0.1)

      if (!allModulesPolygonShrunk) {
        allModulesPolygonShrunk = shrunkModulePolygon
      } else {
        allModulesPolygonShrunk = allModulesPolygonShrunk.union(shrunkModulePolygon)
      }
    })

    let bufferOp = new BufferOp(
      allModulesPolygonShrunk,
      new window.jsts.operation.buffer.BufferParameters(
        10, // is this parameter even processed if we don't want rounded corners??
        BufferParams.CAP_FLAT,
        BufferParams.JOIN_MITRE,
        BufferParams.DEFAUT_MITRE_LIMIT
      )
    )

    const restoredModulesPolygon = bufferOp.getResultGeometry(0.11)
    const expandedModulesPolygon = bufferOp.getResultGeometry(0.11 + buffer)
    return expandedModulesPolygon.difference(restoredModulesPolygon)
  }

  #createOutlineMeshesFromOutlinePolygon = (moduleGrid, outlinePolygon) => {
    const copyPositionAndRotationOfModuleGrid = (mesh) => {
      mesh.position.copy(moduleGrid.position)
      mesh.position.z += 0.01
      mesh.quaternion.copy(moduleGrid.quaternion)
    }

    if (outlinePolygon.getNumGeometries() > 1) {
      // the outline polygon is a multipolygon
      const meshes = outlinePolygon.geometries.map((geom, index) => {
        const mesh = this.#createMeshFromPolygon(geom)
        mesh.name = `moduleGridOutline-${moduleGrid.uuid}-${index}`
        mesh.selectable = false
        copyPositionAndRotationOfModuleGrid(mesh)
        return mesh
      })
      return meshes
    } else {
      // the outline polygon is a single polygon
      const mesh = this.#createMeshFromPolygon(outlinePolygon)
      mesh.name = `moduleGridOutline-${moduleGrid.uuid}-0`
      mesh.selectable = false
      copyPositionAndRotationOfModuleGrid(mesh)
      return [mesh]
    }
  }

  #createMeshFromPolygon = (polygon) => {
    // STEP 1: Query the shell vertices
    const shellVertices = polygon.shell.points.coordinates.map((coord) => {
      return new THREE.Vector3(coord.x, coord.y, coord.z)
    })
    // STEP 2: Create a ThreeJS Shape from shell vertices
    const shape = new THREE.Shape(shellVertices)
    // STEP 3: Define the holes on the shape
    shape.holes = polygon.holes.map((hole) => {
      const holeToVectorList = hole.points.coordinates.map((coord) => new THREE.Vector2(coord.x, coord.y))
      return new THREE.Path(holeToVectorList)
    })
    // STEP 4: Create a ShapeBufferGeometry from shape
    const shapeGeometry = new THREE.ShapeBufferGeometry(shape)
    // STEP 5: Create mesh material
    const material = this.#defaultOutlineMaterial
    // STEP 6: Create the 3D mesh
    const mesh = new THREE.Mesh(shapeGeometry, material)
    return mesh
  }
}
