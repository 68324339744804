/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param script javascript object
 * @constructor
 */

var AddScriptCommand = function(object, script) {
  Command.call(this)

  this.type = 'AddScriptCommand'
  this.name = 'Add Script'
  this.timeStamp = Date.now()
  this.object = object
  this.script = script
}

AddScriptCommand.prototype = {
  execute: function() {
    if (this.editor.scripts[this.object.uuid] === undefined) {
      this.editor.scripts[this.object.uuid] = []
    }

    this.editor.scripts[this.object.uuid].push(this.script)

    this.editor.signals.scriptAdded.dispatch(this.script)
  },

  undo: function() {
    if (this.loadFromJSON) {
      this.object = this.object && this.editor.scene.getObjectByProperty('uuid', this.object)
      this.loadFromJSON = false
    }
    if (this.editor.scripts[this.object.uuid] === undefined) return

    var index = this.editor.scripts[this.object.uuid].indexOf(this.script)

    if (index !== -1) {
      this.editor.scripts[this.object.uuid].splice(index, 1)
    }

    this.editor.signals.scriptRemoved.dispatch(this.script)
  },

  toJSON: function() {
    if (this.loadFromJSON) {
      return Command.prototype.toObject.call(this)
    }
    var output = Command.prototype.toJSON.call(this)

    output.object = this.object.uuid
    output.script = this.script

    return output
  },

  fromJSON: function(parsedCmd) {
    Command.prototype.fromJSON.call(this, parsedCmd)

    this.object = parsedCmd.object
    this.script = parsedCmd.script
  },
}
