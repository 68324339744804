/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

History = function (editor) {
  this.editor = editor
  this.undos = []
  this.redos = []
  this.lastCmdTime = new Date()
  this.idCounter = 0

  this.historyDisabled = false
  this.config = editor.config

  /*
  User will be blocked from undo-ing any commands before this command. This ensures that scene initialization steps
  can be recorded as undo's (for historical/debugging purposes) but they will not be undo-able by the user and the undo
  button will not be enabled before this command.
  */
  this.firstCmdIdAfterInit = null

  //Set editor-reference in Command

  Command(editor)

  // signals

  var scope = this

  this.editor.signals.startPlayer.add(function () {
    scope.historyDisabled = true
  })

  this.editor.signals.stopPlayer.add(function () {
    scope.historyDisabled = false
  })
}

History.prototype = {
  execute: function (cmd, optionalName) {
    var lastCmd = this.undos[this.undos.length - 1]
    var timeDifference = new Date().getTime() - this.lastCmdTime.getTime()

    var isUpdatableCmd =
      lastCmd &&
      lastCmd.updatable &&
      cmd.updatable &&
      lastCmd.object === cmd.object &&
      lastCmd.type === cmd.type &&
      lastCmd.script === cmd.script &&
      lastCmd.attributeName === cmd.attributeName

    var maxTimeDifferenceForUpdate =
      lastCmd && lastCmd.maxTimeDifferenceForUpdate ? lastCmd.maxTimeDifferenceForUpdate : 500

    if (isUpdatableCmd && cmd.type === 'SetScriptValueCommand') {
      // When the cmd.type is "SetScriptValueCommand" the timeDifference is ignored

      lastCmd.update(cmd)
      cmd = lastCmd
    } else if (isUpdatableCmd && timeDifference < maxTimeDifferenceForUpdate) {
      lastCmd.update(cmd)
      cmd = lastCmd

      this.editor.signals.commandUpdated.dispatch(cmd)
    } else {
      // the command is not updatable and is added as a new part of the history
      this.undos.push(cmd)
      cmd.id = ++this.idCounter
    }
    cmd.name = optionalName !== undefined ? optionalName : cmd.name
    cmd.execute()
    cmd.inMemory = true

    // It is essential that we always store cmd.json because otherwise commands can get broken due to later updates
    if (true || this.config?.getKey('settings/history')) {
      /*
      serialize the cmd immediately after execution and append the json to the cmd
      we currently need to do this when saving a recording (beacuse we need to be able to play the sequence both
      forward and backwards so we can start from either the beginning state or the end state.
      But perhaps we can avoid this normally when not recording a replay because it is quite heavy on CPU and memory
      */
      cmd.json = cmd.toJSON()
    }

    this.lastCmdTime = new Date()

    // clearing all the redo-commands

    this.redos = []
    const cmds = [cmd]
    this.editor.signals.historyChanged.dispatch(cmds)
  },

  undo: function () {
    if (this.historyDisabled) {
      alert('Undo/Redo disabled while scene is playing.')
      return
    }

    editor.changingHistory = true

    var cmd = undefined

    if (this.undos.length > 0) {
      cmd = this.undos.pop()

      if (cmd.inMemory === false) {
        cmd.fromJSON(cmd.json)
      }
    }

    if (cmd !== undefined) {
      editor.uiPauseUntilComplete(
        function () {
          cmd.undo()
        },
        this,
        'ui',
        'uiPauseLock::history::undo'
      )
      this.redos.push(cmd)
      const cmds = [cmd]
      this.editor.signals.historyChanged.dispatch(cmds)
    }

    editor.changingHistory = false

    return cmd
  },

  redo: function () {
    if (this.historyDisabled) {
      alert('Undo/Redo disabled while scene is playing.')
      return
    }

    editor.changingHistory = true

    var cmd = undefined

    if (this.redos.length > 0) {
      cmd = this.redos.pop()

      if (cmd.inMemory === false) {
        cmd.fromJSON(cmd.json)
      }
    }

    if (cmd !== undefined) {
      editor.uiPauseUntilComplete(
        function () {
          cmd.execute()
        },
        this,
        'ui',
        'uiPauseLock::history::redo'
      )
      this.undos.push(cmd)
      const cmds = [cmd]
      this.editor.signals.historyChanged.dispatch(cmds)
    }

    editor.changingHistory = false

    return cmd
  },

  toJSON: function () {
    var history = {}
    history.undos = []
    history.redos = []

    // if (!this.config.getKey('settings/history')) {
    //   return history
    // }

    // Append Undos to History

    for (var i = 0; i < this.undos.length; i++) {
      var cmd = this.undos[i]

      // Do not mess with the command if it already has cmd.json because that is already serialized
      // Can we strip out other irrelevant data to simplify the serialized json command?
      // e.g. a) to avoid storing object properties twice, avoid storing irrelevant data relating to parent object
      if (!cmd.json && cmd.toJSON) {
        this.undos[i].toJSON()
      }

      history.undos.push(cmd)
    }

    // Append Redos to History

    // for (var i = 0; i < this.redos.length; i++) {
    //   if (this.redos[i].hasOwnProperty('json')) {
    //     history.redos.push(this.redos[i].json)
    //   }
    // }

    return history
  },

  fromJSON: function (data) {
    if (data === undefined) return

    for (var i = 0; i < data.undos.length; i++) {
      var json = data.undos[i]
      var cmd = new window[json.type]() // creates a new object of type "json.type"
      cmd.commandUUID = json.commandUUID
      cmd.id = json.id
      cmd.name = json.name
      cmd.updatable = json.updatable || false
      cmd.fromJSON(json) // includes inMemory=true
      this.undos.push(cmd)
      // this.idCounter = cmdJSON.id > this.idCounter ? cmdJSON.id : this.idCounter // set last used idCounter
    }

    // for (var i = 0; i < json.redos.length; i++) {
    //   var cmdJSON = json.redos[i]
    //   var cmd = new window[cmdJSON.type]() // creates a new object of type "json.type"
    //   cmd.json = cmdJSON
    //   cmd.id = cmdJSON.id
    //   cmd.name = cmdJSON.name
    //   this.redos.push(cmd)
    //   this.idCounter = cmdJSON.id > this.idCounter ? cmdJSON.id : this.idCounter // set last used idCounter
    // }

    // Select the last executed undo-command
    const lastCmd = this.undos[this.undos.length - 1]
    const cmds = [lastCmd]
    this.editor.signals.historyChanged.dispatch(cmds)
  },

  clear: function () {
    this.undos = []
    this.redos = []
    this.idCounter = 0
    this.firstCmdIdAfterInit = null

    this.editor.signals.historyChanged.dispatch()
  },

  storefirstCmdIdAfterInit: function () {
    var lastCmd = this.undos[this.undos.length - 1]
    if (lastCmd) {
      this.firstCmdIdAfterInit = lastCmd.id
    } else {
      this.firstCmdIdAfterInit = null
    }
  },

  getLastCmdId: function () {
    return this.undos[this.undos.length - 1]?.id || null
  },
  undoRedoBlockedByActiveController: function () {
    return this.editor.controllers.AddObject?.active === true
  },
  canUndo: function () {
    return (
      this.undoRedoBlockedByActiveController() !== true &&
      window.editor.history?.undos.length > 0 &&
      (!window.editor.history?.firstCmdIdAfterInit ||
        window.editor.history?.firstCmdIdAfterInit !== this.getLastCmdId())
    )
  },
  canRedo: function () {
    return this.undoRedoBlockedByActiveController() !== true && window.editor.history?.redos.length > 0
  },

  goToState: function (id) {
    if (this.historyDisabled) {
      alert('Undo/Redo disabled while scene is playing.')
      return
    }

    this.editor.signals.sceneGraphChanged.active = false
    this.editor.signals.historyChanged.active = false

    var cmd = this.undos.length > 0 ? this.undos[this.undos.length - 1] : undefined // next cmd to pop

    if (cmd === undefined || id > cmd.id) {
      cmd = this.redo()
      while (cmd !== undefined && id > cmd.id) {
        cmd = this.redo()
      }
    } else {
      while (true) {
        cmd = this.undos[this.undos.length - 1] // next cmd to pop

        if (cmd === undefined || id === cmd.id) break

        cmd = this.undo()
      }
    }

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.historyChanged.active = true

    this.editor.signals.sceneGraphChanged.dispatch()
    const cmds = [cmd]
    this.editor.signals.historyChanged.dispatch(cmds)
  },

  enableSerialization: function (id) {
    /**
     * because there might be commands in this.undos and this.redos
     * which have not been serialized with .toJSON() we go back
     * to the oldest command and redo one command after the other
     * while also calling .toJSON() on them.
     */

    this.goToState(-1)

    this.editor.signals.sceneGraphChanged.active = false
    this.editor.signals.historyChanged.active = false

    var cmd = this.redo()
    while (cmd !== undefined) {
      if (!cmd.hasOwnProperty('json')) {
        cmd.json = cmd.toJSON()
      }
      cmd = this.redo()
    }

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.historyChanged.active = true

    this.goToState(id)
  },
}
