class Permissions {
  #key // identifier for this permission set
  #canCreate
  #canDelete
  #canEdit
  #canView

  #subPermissions

  constructor(key, canCreate, canDelete, canEdit, canView) {
    if (typeof key !== 'string') {
      throw 'Permission constructor error: key must be a string.'
    }
    this.#key = key
    this.#canCreate = Boolean(canCreate)
    this.#canDelete = Boolean(canDelete)
    this.#canEdit = Boolean(canEdit)
    this.#canView = Boolean(canView)
    this.#subPermissions = []
  }

  // setters
  set canCreate(value) {
    this.#canCreate = Boolean(value)
    return this
  }

  set canDelete(value) {
    this.#canDelete = Boolean(value)
    return this
  }

  set canEdit(value) {
    this.#canEdit = Boolean(value)
    return this
  }

  set canView(value) {
    this.#canView = Boolean(value)
    return this
  }

  // getters
  get canCreate() {
    return this.#canCreate
  }

  get canDelete() {
    return this.#canDelete
  }

  get canEdit() {
    return this.#canEdit
  }

  get canView() {
    return this.#canView
  }

  get key() {
    return this.#key
  }

  get subPermissions() {
    return this.#subPermissions
  }

  addSubPermissions(sub) {
    if (sub instanceof Permissions) {
      this.#subPermissions.push(sub)
    } else {
      throw 'Error in Permissions.addSubPermissions: sub-permission must be a valid Permissions instance.'
    }
  }

  /**
   * Updates the current Permissions instance
   *
   * @param {Boolean} canCreate
   * @param {Boolean} canDelete
   * @param {Boolean} canEdit
   * @param {Boolean} canView
   * @returns {Permissions} - the updated Permissions instance
   */
  setPermissions(canCreate, canDelete, canEdit, canView) {
    this.#canCreate = Boolean(canCreate)
    this.#canDelete = Boolean(canDelete)
    this.#canEdit = Boolean(canEdit)
    this.#canView = Boolean(canView)
    return this
  }
}

class PermissionsTree {
  #root
  #name

  static TRAVERSAL_TYPES = {
    LEVEL_ORDER: 'level_order',
    PRE_ORDER: 'pre_order',
  }

  constructor(name) {
    this.#name = name
    this.#root = new Permissions('(root)', true, true, true, true)
  }

  get name() {
    return this.#name
  }

  /////////////////////////////////////
  //   API
  /////////////////////////////////////

  getRootPermissions() {
    return this.#root
  }

  setRootPermissions(canCreate, canDelete, canEdit, canView) {
    this.#root.setPermissions(canCreate, canDelete, canEdit, canView)
  }

  getAllPermissionKeys(traversal) {
    return traversal === PermissionsTree.TRAVERSAL_TYPES.PRE_ORDER
      ? this.#traverseTreePreOrder()
      : this.#traverseTreeLevelOrder()
  }

  /**
   * Adds a new Permissions instance assigned to the keystring
   *
   * @param {String} keyString - the keystring for the new Permissions instance
   * @param {Boolean} canCreate
   * @param {Boolean} canDelete
   * @param {Boolean} canEdit
   * @param {Boolean} canView
   * @returns {Permissios | null} - if keystring is valid, the new Permissions instance, otherwise null
   */
  addPermissionsByKeyString(keyString, canCreate, canDelete, canEdit, canView) {
    if (typeof keyString !== 'string') {
      console.error(`PermissionsTree.addPermissionsByKeyString requires param 'keyString' to be of type 'string'.`)
      return null
    }

    let keySequence = keyString.split('.')
    let childKey = keySequence.pop()
    let parentPerm = this.#getPermByKeySeqRecursive(keySequence)
    if (parentPerm) {
      // the parent permissions exist
      // check if parent already has a child with the same key
      let duplicate = !!parentPerm.subPermissions.find((p) => p.key === childKey)
      if (duplicate) {
        console.error(`Permissions with keystring '${keyString}' already exists.`)
        return null
      } else {
        let newInstance = new Permissions(childKey, canCreate, canDelete, canEdit, canView)
        parentPerm.addSubPermissions(newInstance)
        return newInstance
      }
    } else {
      // parent does not exist
      // cannot attach new permission as a child
      return null
    }
  }

  /**
   * Updates an existing Permissions instance corresponding to the keystring
   *
   * @param {String} keyString - the keystring of an existing Permissions instance
   * @param {Boolean} canCreate
   * @param {Boolean} canDelete
   * @param {Boolean} canEdit
   * @param {Boolean} canView
   * @returns {Permissions | null} - if keystring is valid, the updated Permissions instance, otherwise null
   */
  updatePermissionsByKeyString(keyString, canCreate, canDelete, canEdit, canView) {
    if (typeof keyString !== 'string') {
      console.error(`PermissionsTree.addPermissionsByKeyString requires param 'keyString' to be of type 'string'.`)
      return null
    }

    let perm = this.getPermissionsByKeyString(keyString)
    if (perm) {
      perm.setPermissions(canCreate, canDelete, canEdit, canView)
      return perm
    } else {
      console.error('Error: cannot find permission instance for keyString: ' + keyString)
      return null
    }
  }

  /**
   * Gets an existing Permissions instance corresponding to the keystring
   *
   * @param {String} keyString - keystring corresponding to an existing Permissions instance
   * @returns {Permissions | null} - if keystring is valid, the Permissions instance, otherwise null
   */
  getPermissionsByKeyString(keyString) {
    if (typeof keyString !== 'string') {
      console.error('Permission.getPermissionByKeyString requires a param of type string.')
      return null
    }
    return this.#getPermByKeySeqRecursive(keyString === '' ? [] : keyString.split('.'))
  }

  /////////////////////////////////////
  //     API: Short-hand
  /////////////////////////////////////
  canCreate(keyString) {
    return this.#getPermissionFlagByKeyString(keyString, 'canCreate')
  }

  canDelete(keyString) {
    return this.#getPermissionFlagByKeyString(keyString, 'canDelete')
  }

  canEdit(keyString) {
    return this.#getPermissionFlagByKeyString(keyString, 'canEdit')
  }

  canView(keyString) {
    return this.#getPermissionFlagByKeyString(keyString, 'canView')
  }

  /////////////////////////////////////
  ///       Private Methods
  /////////////////////////////////////
  #getPermissionFlagByKeyString(keyString, flag) {
    if (keyString === undefined) {
      return this.#root[flag]
    }
    if (typeof keyString !== 'string') {
      console.error('Permission.getPermissionByKeyString requires a param of type string.')
      return null
    }
    let perm = this.getPermissionsByKeyString(keyString)
    return perm ? perm[flag] : undefined
  }

  #getPermByKeySeqRecursive(keySeq, perm) {
    perm = perm ? perm : this.#root
    if (keySeq.length === 0) {
      return perm
    }
    let targetChild = perm.subPermissions.find((p) => p.key === keySeq[0])
    if (targetChild) {
      keySeq.shift()
      return this.#getPermByKeySeqRecursive(keySeq, targetChild)
    } else {
      return null
    }
  }

  /**
   * A function that traverses the permission tree via level-order traversal
   * useful when you want to list all permission keys in such a manner that all
   * keys at a particular depth are exhausted first before moving on to keys at deeper levels
   * Example:
   * (root)                     - level 0
   * systems                    - level 1
   * views                      - level 1
   * systems.panels             - level 2
   * systems.buildablePanels    - level 2
   * systems.inverters          - level 2
   * views.imagery              - level 2
   * views.viewBox              - level 2
   * systems.inverters.strings  - level 3
   * ...                          ...
   *
   * @returns {Array<string>} - list of all permission keys
   */
  #traverseTreeLevelOrder() {
    let queue = []
    let current = null
    let currentKeyString = ''

    let traversal = [this.#root.key]
    this.#root.subPermissions.forEach((sub) => {
      queue.push({ perm: sub, parentKeyString: '' })
    })

    while (queue.length > 0) {
      current = queue.shift()
      currentKeyString = current.parentKeyString + (current.parentKeyString.length > 0 ? '.' : '') + current.perm.key
      traversal.push(currentKeyString)
      current.perm.subPermissions.forEach((sub) => {
        queue.push({ perm: sub, parentKeyString: currentKeyString })
      })
    }

    return traversal
  }

  /**
   * A recursive function that traverses the permission tree via pre-order traversal
   * Useful when you want to list all the permission keys in such a manner that all keys under a branch
   * are exhausted first before moving on to other branches
   * Example:
   * (root)
   * systems
   * systems.panels
   * systems.buildablePanels
   * systems.inverters
   * systems.inverters.strings
   * views
   * views.imagery
   * views.viewBox
   * ...
   *
   * @param {string | undefined} ancestryKeyString - the key string of the current's ancestor
   * @param {Permissions | undefined} current - the current permission instance
   * @param {Array | undefined} list - the container for storing the key strings as the traversal progresses
   * @returns {Array<string>} - list of all permission keys under the current permission tree/subtree
   */
  #traverseTreePreOrder(ancestryKeyString, current, list) {
    current = current ? current : this.#root
    list = list ? list : []
    ancestryKeyString = ancestryKeyString ? ancestryKeyString : ''
    let isRoot = current === this.#root

    let keyString = isRoot
      ? this.#root.key
      : ancestryKeyString.concat(ancestryKeyString === '' ? current.key : '.'.concat(current.key))
    list.push(keyString)

    current.subPermissions.forEach((sub) => {
      this.#traverseTreePreOrder(current === this.#root ? '' : keyString, sub, list)
    })

    return list
  }
}
