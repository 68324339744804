/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var NodeRemoveEdgeCommand = function (node, edge, commandUUID) {
  Command.call(this)

  this.type = 'NodeRemoveEdgeCommand'
  this.name = 'Node Remove Edge Command'
  if (!window.globalCommandUUID) {
    window.globalCommandUUID = Utils.generateCommandUUIDOrUseGlobal()
  }
  this.commandUUID = commandUUID || window.globalCommandUUID
  this.timeStamp = Date.now()

  this.OBJECT_REFERENCES = ['node', 'edge']

  if (arguments.length) {
    this.node = node
    this.edge = edge
  }
}

NodeRemoveEdgeCommand.prototype = {
  execute: function () {
    this.node.removeEdge(this.edge)
  },

  undo: function () {
    this.node.addToEdge(this.edge)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
