function ReplayHelperClass() {}

var getEmptyReplayData = function () {
  return {
    user_id: null,
    org_id: null,
    project_id: null,
    start_timestamp: null,
    end_timestamp: null,
    design_start: null,
    design_end: null,
    commands: null,
    datetime_for_url: null,
    account_data: window.CompressionHelper.compress(
      JSON.stringify({
        account: {
          adders: [],
          batterySchemes: [],
          componentBatterySpecs: [],
          componentInverterSpecs: [],
          componentModuleSpecs: [],
          componentOtherSpecs: [],
          costings: [],
          incentives: [],
          org: {},
          paymentOptions: [],
          pricingSchemes: [],
          roofTypes: [],
          wallTypes: [],
        },
        project: {},
      })
    ),
    events: window.CompressionHelper.compress(JSON.stringify([])),
    has_unsaved_changes: false,
    last_saved_command_id: 0,
    last_saved_event_index: 0,
    clear_after_command_id: null,
  }
}

ReplayHelperClass.prototype = Object.assign({
  saveReplayIsEnabled: function () {
    if (window.saveReplays) {
      return true
    }

    try {
      return window.isFeatureEnabled('save_replay')
      // return window.reduxStore?.getState().split.enabled_features.includes('save_replay')
    } catch (e) {
      console.log('unable to use window.isFeatureEnabled, ignore')
    }
    return false
  },
  replayInProgress: false,
  recordingInProgress: false,
  tmpReplayData: {
    events: [],
  },
  temporaryReplayData: getEmptyReplayData(),

  clearTemporaryReplayData: function () {
    this.temporaryReplayData = getEmptyReplayData()
  },

  saveRecordingIntervalId: null,
  saveRecordingIntervalDelay: 10000,
  stopRecording: function (doSave) {
    if (!this.recordingInProgress) {
      console.error('Warning: Unable to stopRecording, not yet recording')
      return
    }

    if (doSave) {
      this.saveRecording()
    }

    this.recordingInProgress = false
    clearInterval(this.saveRecordingIntervalId)
    editor.signals.commandUpdated.remove(this.resendUpdatedCommand)
  },
  saveAndStartRecording: function () {
    var _this = this
    window.WorkspaceHelper.save(
      undefined,
      () => {
        _this.startRecording()
      },
      true
    )
  },
  mocks: {
    fetch: null,
  },
  fetch: function () {
    if (this.mocks.fetch) {
      return this.mocks.fetch.apply(this, arguments)
    } else {
      return fetch.apply(window, arguments)
    }
  },
  resendUpdatedCommand: function (cmd) {
    if (cmd.id === ReplayHelper.temporaryReplayData.last_saved_command_id - 1) {
      console.log('resendUpdatedCommand update')
      // Find the next command before this to mark as last saved command
      // @TODO: Optimize to iterate backwards over array
      ReplayHelper.temporaryReplayData.last_saved_command_id = editor.history.undos
        .filter((c) => c.id < cmd.id)
        .slice(-1)[0].id
    } else {
      console.log('resendUpdatedCommand no need to update')
    }
  },
  recordError: function (e) {
    ReplayHelper.tmpReplayData.events.push({
      type: 'error',
      timestamp: new Date(),
      data: {
        message: e.error.message,
      },
    })
  },
  recordNotification: function (message, type) {
    ReplayHelper.tmpReplayData.events.push({
      type: 'notification',
      timestamp: new Date().valueOf(),
      data: {
        message: message,
        type: type,
      },
    })
  },
  startRecording: async function (
    org_id,
    project_id,
    user_id,
    design_start_gzipped_base64,
    account_data_gzipped_base64,
    autoSave
  ) {
    if (this.recordingInProgress) {
      console.error('Warning: Unable to startRecording, already recording', 'error')
      return
    }
    if (!projectForm) {
      console.error('Warning: Unable to startRecording, no projectForm')
      return
    }

    console.log('startRecording')

    this.recordingInProgress = true
    this.hasSavedAccountData = false

    const projectFormState = projectForm.getState()

    window.addEventListener('error', this.recordError)

    if (!org_id) {
      org_id = projectFormState.values.org_id
    }
    if (!project_id) {
      project_id = projectFormState.values.id
    }
    if (!user_id) {
      try {
        user_id = JSON.parse(window.getStorage().getItem('auth')).user.id
      } catch (e) {
        user_id = null
      }
    }

    // await window.AccountHelper.getMapTypesForProject() before running editor.sceneAsJSON(), see notes below.
    if (!account_data_gzipped_base64) {
      var lonlat = [projectFormState.values.lon, projectFormState.values.lat]
      var [mapTypes, timezoneOffset] = await window.AccountHelper.getMapTypesForProject(lonlat)

      account_data_gzipped_base64 = window.CompressionHelper.compress(
        JSON.stringify({
          account: AccountHelper.cloneAndRedactedLoadedData(),
          project: projectFormState.values,
          imagery: {
            available_map_types: mapTypes,
            timezone_offset: timezoneOffset,
          },
        })
      )
    }

    // Tricky: We must await window.AccountHelper.getMapTypesForProject() first before generating sceneAsJSON() because
    // getMapTypesForProject() may update the scene. Since we will ignore any commands before starting the replay we
    // must ensure that all commands have been applied before we generate sceneAsJSON() otherwise there may be commands
    // which get ignored but they are also not yet part of the scene so they are lost.
    if (!design_start_gzipped_base64) {
      design_start_gzipped_base64 = window.CompressionHelper.compress(JSON.stringify(editor.sceneAsJSON()))
    }

    this.clearTemporaryReplayData()
    this.temporaryReplayData.org_id = org_id
    this.temporaryReplayData.project_id = project_id
    this.temporaryReplayData.user_id = user_id
    this.temporaryReplayData.account_data = account_data_gzipped_base64
    this.temporaryReplayData.design_start = design_start_gzipped_base64
    this.temporaryReplayData.datetime_for_url = this.datetimeForUrl(new Date().toISOString())
    ReplayHelper.tmpReplayData.events = []

    if (editor.history.undos.length > 0) {
      if (Designer && Designer.showNotification) {
        console.warn('Warning: undos were present, they will be ignored from the replay')
        this.temporaryReplayData.last_saved_command_id = editor.history.undos.slice(-1)[0].id
      }
    }

    // Listen for when a command is updated and ensure we overwrite the updated command in the recording on next save
    editor.signals.commandUpdated.add(this.resendUpdatedCommand)

    if (autoSave !== false) {
      var _this = this
      this.saveRecordingIntervalId = setInterval(function () {
        _this.saveRecording()
      }, _this.saveRecordingIntervalDelay)
    }
  },

  saveRecording: function () {
    // Ignore "end" state for now... just save start+commands
    // this.temporaryReplayData.end = design_end_encrypted_base64

    // Tricky: Store the recording start timestamp before firing the async save so we know if the same replay is being
    // recorded when the response is returned. If a new recording has started then we will just ignore the response
    var datetime_for_url_before = this.temporaryReplayData.datetime_for_url

    var { replayData, hasChanges } = this.refreshIncrementalReplayData(
      this.temporaryReplayData,
      this.hasSavedAccountData
    )

    this.temporaryReplayData = replayData

    if (hasChanges && replayData.project_id !== undefined) {
      var myHeaders = new Headers()
      myHeaders.append('Content-Type', 'application/javascript')
      myHeaders.append('Authorization', 'Bearer ' + window.getStorage().getItem('token'))

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(this.temporaryReplayData),
        redirect: 'follow',
      }

      var _this = this

      console.log('saveRecording')

      this.fetch(
        window.API_BASE_URL +
          'orgs/' +
          this.temporaryReplayData.org_id +
          '/projects/' +
          this.temporaryReplayData.project_id +
          '/replays/' +
          this.temporaryReplayData.datetime_for_url +
          '/',
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          if (datetime_for_url_before === this.temporaryReplayData.datetime_for_url) {
            window.getStorage().setItem(
              'lastSavedReplay',
              JSON.stringify({
                org_id: this.temporaryReplayData.org_id,
                project_id: this.temporaryReplayData.project_id,
                datetime_for_url: this.temporaryReplayData.datetime_for_url,
              })
            )

            if (this.temporaryReplayData.account_data) {
              _this.hasSavedAccountData = true
            }
          } else {
            console.log('Saved replay is different to current replay, ignore response')
          }
        })
        .catch((error) => console.log('error', error))
    } else {
      // console.log('Replay: No changes')
    }
  },
  launchReplay: function () {
    //localhost:3000/#/projects/replay/design?org_id=1&project_id=12949&datetime_for_url=2022-03-14-05-58-57-943Z
    http: window.open(
      window.location.href.replace('/' + this.temporaryReplayData.project_id + '/', '/replay/') +
        `?org_id=${this.temporaryReplayData.org_id}&project_id=${this.temporaryReplayData.project_id}&datetime_for_url=${this.temporaryReplayData.datetime_for_url}`
    )
  },
  restartRecording: function () {
    /*
    Save the latest changes in the original recording, then start recording in a new session so we do not need to retain
    the old commands. In future we may keep the old commands so we can view before and after the restart in a single
    replay but we create a new recording for now for simplicity.
    */
    if (!this.recordingInProgress) {
      return
    }

    // Ensure we do not fire another event other than our final manual recording
    this.stopRecording(true)

    this.startRecording()
  },

  buildDummyForm: function (values) {
    return {
      getState: function () {
        return {
          values: values,
        }
      },
      mutators: {
        getUnsavedChangeAffectSystemCalcs: function () {
          return {}
        },
        getFormDirtyFields: function () {
          return {}
        },
      },
    }
  },
  loadReplay: function (
    org_id,
    project_id,
    datetime_for_url,
    design_start_gzipped_base64,
    commands_gzipped_base64,
    account_data_gzipped_base64,
    events_gzipped_base64
  ) {
    this.replayInProgress = true

    var accountData = window.CompressionHelper.decompress(account_data_gzipped_base64, true)
    AccountHelper.applySavedLoadedData(accountData.account)

    // Create a dummy react-final-form
    projectForm = this.buildDummyForm(accountData.project)
    window.WorkspaceHelper.project = accountData.project

    // @TODO: Implement premium_img_available properly, otherwise this could lead to strange behaviors
    var premium_img_available = false

    AccountHelper.terrainUrlsCache.save(
      'cachedGetMapTypesAtLocationRequestPromise',
      [accountData.project.lon, accountData.project.lat],
      accountData.project.country_iso2,
      accountData.project.state,
      premium_img_available,
      Promise.resolve({
        clone: function () {
          return {
            json: function () {
              return Promise.resolve(accountData.imagery)
            },
          }
        },
      })
    )

    // @TODO: Bypass loading account helper etc.

    var designData = window.CompressionHelper.decompress(design_start_gzipped_base64, true)

    // Other params required?
    var params = { project_id: project_id }

    editor.clear()
    editor.loadScene(designData, params)

    // inject commands into the scene history as "redos"
    var commandsArray = window.CompressionHelper.decompress(commands_gzipped_base64, true)

    if (commandsArray?.length) {
      commandsArray = {
        redos: [],
        undos: commandsArray,
      }
    }

    // redos are consumed starting at the end of the list
    this.populateRedosFromUndos(commandsArray.undos)

    this.initPlayer(window.CompressionHelper.decompress(events_gzipped_base64, true))
  },

  stopReplay: function () {
    this.replayInProgress = false
    window.removeEventListener('error', this.recordError)
  },

  initPlayer: function (events) {
    this.player.start = editor.history.undos[0]?.json?.timeStamp || editor.history.redos.slice(-1)[0]?.json?.timeStamp
    this.player.end = editor.history.redos[0]?.json?.timeStamp
    this.player.duration = this.player.end - this.player.start
    this.player.current = this.player.start
    this.player.current_event_index = -1
    this.player.events = events
  },
  player: {
    playback_speed: 0.01,
    interval_id: null,
    replay_started: null,
    delta_seconds: null,
    duration: null,
    start: null,
    end: null,
    current_event_index: -1,
    events: [],
    playhead: null,
    progressPercentage: function () {
      return Math.floor(
        100.0 * ((ReplayHelper.player.current - ReplayHelper.player.start) / ReplayHelper.player.duration)
      )
    },
  },
  playNextCommandIfReady: function () {
    if (this.player.playback_speed > 0) {
      if (editor.history.redos.length > 0) {
        var nextCommandTimestamp = editor.history.redos.slice(-1)[0].json.timeStamp
        if (this.player.current > nextCommandTimestamp) {
          editor.history.redo()

          // Try again recursively until we have exhausted all commands which are ready to run at this point in the replay
          this.playNextCommandIfReady()
        } else {
          console.log('time until next command executes: ' + (nextCommandTimestamp - this.player.current))
        }
      } else {
        console.log('no more commands to play')
      }
    } else {
      if (editor.history.undos.length > 0) {
        var nextCommandTimestamp = editor.history.undos.slice(-1)[0].json.timeStamp
        if (this.player.current < nextCommandTimestamp) {
          editor.history.undo()

          // Try again recursively until we have exhausted all commands which are ready to run at this point in the replay
          this.playNextCommandIfReady()
        } else {
          console.log('time until next command executes: ' + (this.player.current - nextCommandTimestamp))
        }
      } else {
        console.log('no more commands to play')
      }
    }
  },
  playNextEventIfReady: function (silent) {
    if (this.player.playback_speed > 0) {
      var nextEventIndex = this.player.current_event_index + 1
      var nextEvent = this.player.events[nextEventIndex]
      if (nextEvent) {
        var nextEventTimestamp = nextEvent.timestamp
        if (this.player.current > nextEventTimestamp) {
          if (!silent) {
            Designer.showNotification(nextEvent.data.message, nextEvent.data.type)
          }
          this.player.current_event_index = nextEventIndex

          // Try again recursively until we have exhausted all commands which are ready to run at this point in the replay
          this.playNextEventIfReady(silent)
        } else {
          console.log('time until next event executes: ' + (nextEventTimestamp - this.player.current))
        }
      } else {
        console.log('no more events to play')
      }
    } else {
      var previousEventIndex = this.player.current_event_index
      var previousEvent = this.player.events[previousEventIndex]
      if (previousEvent) {
        var previousEventTimestamp = previousEvent.timestamp
        if (this.player.current < previousEventTimestamp) {
          if (!silent) {
            Designer.showNotification(previousEvent.data.message, previousEvent.data.type)
          }
          this.player.current_event_index = previousEventIndex - 1

          // Try again recursively until we have exhausted all commands which are ready to run at this point in the replay
          this.playNextEventIfReady(silent)
        } else {
          console.log('time until next event executes: ' + (this.player.current - previousEventTimestamp))
        }
      } else {
        console.log('no more events to play')
      }
    }
  },
  stop: function () {
    clearInterval(this.player.interval_id)
  },
  play: function (playback_speed) {
    if (!playback_speed) {
      playback_speed = 1.0
    }
    this.player.playback_speed = playback_speed

    var _this = this
    this.player.interval_id = setInterval(
      function () {
        this.player.current += 1000.0 * this.player.playback_speed

        var progressPercentage = this.player.progressPercentage()
        console.log('Player: ' + progressPercentage + '%')
        if (progressPercentage < 0 || progressPercentage > 100) {
          this.stop()
        } else {
          this.playNextCommandIfReady()
          this.playNextEventIfReady()
        }
      }.bind(_this),
      1000
    )
  },
  restart: function () {
    while (editor.history.undos.length > 0) {
      editor.history.undo()
    }
    this.player.current_event_index = -1
  },
  seek: function (fraction) {
    var targetTimestamp = this.player.start + this.player.duration * fraction

    // Hack to ensure we seek forwards or backwards
    if (targetTimestamp > this.player.current) {
      this.player.playback_speed = 1
    } else {
      this.player.playback_speed = -1
    }

    this.player.current = targetTimestamp

    this.playNextCommandIfReady()

    // Do not play all events which could spam the UI, just jump the current index to match the timestamp
    this.playNextEventIfReady(true)
  },
  downloadAndLoadReplay: function (org_id, project_id, datetime_for_url) {
    /*
    If called with no arguments it will load the last saved replay stored in localStorage for convenient testing
    Tip: Open two browser windows, save a replay in one window then call ReplayHelper.downloadAndLoadReplay() in other.
    */
    if (!org_id && !project_id && !datetime_for_url) {
      var { org_id, project_id, datetime_for_url } = JSON.parse(window.getStorage().getItem('lastSavedReplay'))
    }

    if (!org_id || !project_id || !datetime_for_url) {
      throw new Error('Missing required params for downloadAndLoadReplay')
    }

    var myHeaders = new Headers()
    myHeaders.append('Content-Type', 'application/javascript')
    myHeaders.append('Authorization', 'Bearer ' + window.getStorage().getItem('token'))

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow',
    }

    var _this = this

    this.fetch(
      window.API_BASE_URL +
        'orgs/' +
        org_id +
        '/projects/' +
        project_id +
        '/replays/' +
        datetime_for_url +
        '/download/',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result)

        _this.loadReplay(
          org_id,
          project_id,
          datetime_for_url,
          result.design_start,
          result.commands,
          result.account_data,
          result.events
        )
      })
      .catch((error) => console.log('error', error))
  },

  populateRedosFromUndos: function (commandsArray) {
    // Adapted from spa/studio/src/History.js

    for (var i = 0; i < commandsArray.length; i++) {
      /*
      Inject a command which has the necessary metadata but stores the actual command data in serialized json
      so we can process the command data at the last possible moment, because it might do things like try to access
      an object by uuid (which may not exist until immediately before the command is actually executed)
      */

      var json = commandsArray[i]
      var cmd = new window[json.type]() // creates a new object of type "json.type"
      cmd.commandUUID = json.commandUUID
      cmd.id = json.id
      cmd.name = json.name
      cmd.inMemory = false
      cmd.updatable = json.updatable || false
      cmd.json = json
      cmd.json.inMemory = false
      window.editor.history.redos.unshift(cmd)
    }

    // Select the last executed undo-command
    window.editor.signals.historyChanged.dispatch(window.editor.history.redos[window.editor.history.redos.length - 1])
  },

  undosToReplayRedos: function () {
    var history = window.editor.history.toJSON()
    return history.undos
  },

  refreshIncrementalReplayData: function (replayData, hasSavedAccountData) {
    /*
    We only save if hasChanges is true, which happens when either:
      - new commands have been added since last save, or
      - has unsaved changes has changed since previous save
    */
    var hasChanges = false

    // editor.scene.refreshUserData()

    // Cannot just use JSON.stringify() because it needs to be reformated, have circular references fixed
    var historyAsJson = window.editor.history.toJSON()

    replayData.clear_after_command_id = null

    if (replayData.last_saved_command_id > 0) {
      // Tricky: If last_saved_command_id is no longer found in the history, then it means we have undone some commands
      // Rewind last_saved_command_id to the last command in the list which has id < last_saved_command_id which will
      // ensure any later commands will get cleared out of the replay when we save the next incremental commands.
      if (!historyAsJson.undos.map((c) => c.id).includes(replayData.last_saved_command_id)) {
        // last saved command has been cleared
        var lastSavedCommandToRetain = historyAsJson.undos
          .filter((c) => c.id <= replayData.last_saved_command_id)
          .slice(-1)[0]
        if (lastSavedCommandToRetain) {
          replayData.clear_after_command_id = lastSavedCommandToRetain.id
        } else {
          // edge case where we have re-wound righ back to the beginning
          replayData.clear_after_command_id = 0
        }
      }

      //Only store commands saved since this id
      historyAsJson.undos = historyAsJson.undos.filter((command) => command.id > replayData.last_saved_command_id)
    }

    if (historyAsJson.undos.length > 0) {
      hasChanges = true

      // Update last_saved_command_id to strip already saved commands next time
      // @TODO: We should only update this once the replay has been successfully saved, otherwise on the next save
      // we will lose commands
      replayData.last_saved_command_id = historyAsJson.undos.slice(-1)[0]?.id
    }

    var historyAsJsonSerialized = JSON.stringify(historyAsJson)

    /*
    Beware updatable commands. If we record a command but then it gets updated afterwards the updated version will
    not get saved. Sample sequence:

    We have two choices for how to handle this:

    1) Flat when a command gets updated and ensure we replace the command in the replay with the updated command
    2) Mark the last command as not-updatable. This is safe, just a little inefficient.

    We will modify the replay instead of preventing the command from updating because we do not want replays
    to interfere in any way with the behavior of the tool. It should be totally passive.

    # Broken
    Add object (Command A)
    Move object (Command B)
    SaveReplay (Store last saved command B)
    Move object again (Command B updated)
    SaveReplay (Command B is already stored so we do not sent the updated version, replay is broken)

    # Fixed
    Add object (Command A)
    Move object (Command B)
    SaveReplay (Store last saved command B)
    Move object again (Command B* updated => set last saved Command as A)
    SaveReplay (Clear Command B, store updated version Command A*)

    */

    replayData.commands = window.CompressionHelper.compress(historyAsJsonSerialized)

    var eventsUnsaved = ReplayHelper.tmpReplayData.events.slice(replayData.last_saved_event_index)
    if (eventsUnsaved.length > 0) {
      replayData.events = window.CompressionHelper.compress(JSON.stringify(eventsUnsaved))
      hasChanges = true
      replayData.last_saved_event_index = ReplayHelper.tmpReplayData.events.length
    }

    if (hasSavedAccountData) {
      delete replayData.design_start
      delete replayData.account_data
    }

    // @TODO: Replace this with something that is handled purely inside Design without any dependency on the React app
    var newHasUnsavedChanges = Boolean(window.projectForm?.mutators?.getFormDirtyFields()?.length > 0)
    if (newHasUnsavedChanges !== replayData.has_unsaved_changes) {
      replayData.has_unsaved_changes = newHasUnsavedChanges
      hasChanges = true
    }

    return {
      replayData,
      hasChanges,
    }
  },

  testSerializeCommands: function () {
    /*
    Handy way to see the output after JSON.stingify() has been run, including transformations due to executing toJSON()
    on all objects in the hierarchy
    */
    return JSON.parse(JSON.stringify(window.editor.history.toJSON()))
  },

  datetimeForUrl: function (datetimeRaw) {
    return datetimeRaw.replace('T', '-').split(':').join('-').split('.').join('-')
  },
  datetimeFromUrl: function () {},
})

var ReplayHelper = new ReplayHelperClass()
