/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param cmdArray array containing command objects
 * @constructor
 */

var MultiCmdsCommand = function (cmdArray) {
  Command.call(this)

  this.type = 'MultiCmdsCommand'
  this.name = 'Multiple Changes'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  if (arguments.length) {
    this.cmdArray = cmdArray
  }
}

MultiCmdsCommand.prototype = {
  execute: function () {

    // Err on the side of caution and only skip dispatching sceneGraphChanged
    // if we are really sure that none of the commands require it.
    // This is only required to avoid firing sceneGraphChanged when we really
    // just need to fire UpdateSystemCalculationCommand. We can easily add other command types here
    // but for now we only need to add UpdateSystemCalculationCommand.
    var commandsNotRequiringSceneGraphChanged = ['UpdateSystemCalculationCommand']
    var dispatchSceneGraphChanged = !this.cmdArray.every((cmd) => commandsNotRequiringSceneGraphChanged.includes(cmd.type))

    this.editor.signals.sceneGraphChanged.active = false

    for (var i = 0; i < this.cmdArray.length; i++) {
      this.cmdArray[i].execute()
    }

    this.editor.signals.sceneGraphChanged.active = true

    if (dispatchSceneGraphChanged) {
      this.editor.signals.sceneGraphChanged.dispatch()
    }
  },

  undo: function () {
    this.editor.signals.sceneGraphChanged.active = false

    for (var i = this.cmdArray.length - 1; i >= 0; i--) {
      this.cmdArray[i].undo()
    }

    this.editor.signals.sceneGraphChanged.active = true
    this.editor.signals.sceneGraphChanged.dispatch()
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)

    var cmds = []
    for (var i = 0; i < this.cmdArray.length; i++) {
      cmds.push(this.cmdArray[i].toJSON())
    }
    output.cmds = cmds

    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)

    this.cmdArray = json.cmds.map((commandJson) => {
      var cmd = new window[commandJson.type]() // creates a new object of type "json.type"
      cmd.fromJSON(commandJson)
      return cmd
    })
  },
}
