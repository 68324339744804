/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var SetMapTypeCommand = function (newMapType, cameraCenter, MapHelper, ViewHelper) {
  Command.call(this)

  this.type = 'SetMapTypeCommand'
  this.name = 'Change Map Type Command'
  this.isAligned = ViewHelper.selectedView().isAligned
  this.newMapType = newMapType
  this.oldMapType = MapHelper.activeMapInstance.mapType
  this.timeStamp = Date.now()
  this.cameraCenter = cameraCenter
  this.MapHelper = MapHelper
  this.ViewHelper = ViewHelper
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  // This only works in UX1 so we will just block undo.
  this.UNDO_NOT_SUPPORTED = true
}

SetMapTypeCommand.prototype = {
  execute: function () {
    //Reset camera when switching to a top-down view
    //This also reset camera when re-selecting the view type which is already active
    this.MapHelper.setMapType(this.newMapType)
      .then(
        function () {
          if (this.MapHelper.isTopDown(this.newMapType)) {
            this.editor.loadCamera(
              null,
              this.ViewHelper.createCameraParams(
                'top',
                undefined,
                new window.THREE.Vector3().fromArray(this.cameraCenter),
                editor.metersPerPixel()
              )
            )
            this.editor.signals.cameraChanged.dispatch(this.editor.camera)
          }

          if (this.newMapType === 'None') {
            // Enable textures by default when changing to mapType=None
            this.ViewHelper.selectedView().showTextures = true
            this.ViewHelper.selectedView().showGround = true
            this.ViewHelper.selectedView().allowTilt = true
          } else {
            // Disabled textures by default when changing to map imagery
            this.ViewHelper.selectedView().showTextures = false
            this.ViewHelper.selectedView().showGround = false

            // Do not modify allowTilt on map change away from None
            // @TODO: If already top-down then we could lock it and disable tilt
            // but continue allowing tilt if not perfectly top-down, otherwise they will
            // be blocked from fixing it.
            // this.ViewHelper.selectedView().allowTilt = false
          }

          this.ViewHelper.saveView()

          // always turn isAligned to false when change Map type
          this.ViewHelper.selectedView().isAligned = false
        }.bind(this)
      )
      .catch(function (errorMessage) {
        if (!this.editor.changingHistory) {
          window.Designer.showNotification(errorMessage, 'danger')
        }
      })
  },

  undo: function () {
    this.MapHelper.setMapType(this.oldMapType)
      .then(
        function () {
          if (this.MapHelper.isTopDown(this.oldMapType)) {
            this.editor.loadCamera(
              null,
              this.ViewHelper.createCameraParams(
                'top',
                undefined,
                new window.THREE.Vector3().fromArray(this.cameraCenter)
              )
            )
            this.editor.signals.cameraChanged.dispatch(this.editor.camera)
          }
          this.ViewHelper.saveView()
          this.ViewHelper.selectedView().isAligned = this.isAligned
        }.bind(this)
      )
      .catch(function (errorMessage) {
        console.warn(errorMessage)
      })
  },

  toJSON: function () {
    //Todo
  },

  fromJSON: function (parsedCmd) {
    Command.prototype.fromJSON.call(this, parsedCmd)
    //Todo
  },
}
