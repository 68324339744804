/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var AddObjectCommand = function (object, parent, select, commandUUID) {
  Command.call(this)

  this.type = 'AddObjectCommand'
  this.parent = parent
  this.object = object
  this.timeStamp = Date.now()
  this.commandUUID = commandUUID || Utils.generateCommandUUIDOrUseGlobal()
  this.select = select === true //true enables auto-selection, anything else (including empty) disables auto-selection
  if (object !== undefined) {
    this.name = 'Add Object: ' + object.name
  }
}

AddObjectCommand.prototype = {
  execute: function () {
    // Special update to recover OsFacet properties, avoid calling on other object types to avoid regressions
    if (this.json?.object?.object?.userData) {
      if (this.json?.object?.object?.type === 'OsFacet') {
        this.object.userData = this.json.object.object.userData
        this.object.applyUserData()
      } else if (this.json?.object?.object?.type === 'OsEdge') {
        this.object.userData = this.json.object.object.userData
        this.object.applyUserData()
      }
    }

    this.editor.addObject(this.object, this.parent)

    if (this.select) {
      this.editor.select(this.object)
    }

    //Clear hash to ensure it is redrawn on redo
    // But do not clear hash if we are duplicating a system because it will already be correct
    if (this.object._hash) {
      if (this.object.getSystem && this.object.getSystem() && this.object.getSystem().isDuplicating) {
        // do not clear hash for a system component when duplicating a system
      } else {
        this.object._hash = null
      }
    }

    if (this.object.onChange) {
      var permitSnappingOverride = false
      this.object.onChange(editor, permitSnappingOverride)
    }

    var selectedView = window.ViewHelper.selectedView()

    if (this.object.alignViewOnChange && selectedView && selectedView.isAligned !== true) {
      this.isAlignedValueChanged = this.object.alignViewOnChange(true)
    }

    // Workaround tricky bug where we create an object then change it's position. If we wait until after the position
    // change to serialize the command then the object will be saved with its modified properties, not the properties
    // from the time it was created.
    // We addresst his by populating the serialized version of this command immediately after executing it so we
    // can store the object as it was immediately after executing the command.
    //
    // Only run this extra toJSON() call if we are recording
    if (!this.json && ReplayHelper && ReplayHelper.recordingInProgress) {
      this.json = this.toJSON()
    }
  },

  undo: function () {
    if (this.object.applyUserData) {
      this.object.applyUserData()
      // if (this.object.cellsActive) this.object.populate(this.object.cellsActive)
    }
    if (this.object.type === 'OsFacet') {
      this.object.deletedByUserAction = false
    }
    this.editor.removeObject(this.object)
    this.editor.deselect()

    if (this.object.alignViewOnChange && this.isAlignedValueChanged) {
      this.object.alignViewOnChange(false)
    }
    if (this.object.type === 'OsModuleGrid') {
      this.editor.signals.objectChanged.dispatch(this.object, undefined, { skipRemoveFloatingObject: true })
    }
  },

  toJSON: function () {
    // Important: toJSON() is a built-in override which changes how the object is serialized by JSON.stringify
    // If we have already prepared this.json do not let this function run again, just return this.json otherwise
    // it may re-evaluate properties that have already been calculated and should not be refreshed, otherwise original
    // values may get overwritten.
    if (this.json) {
      return this.json
    }

    var output = Command.prototype.toJSON.call(this)
    output.parentUuid = this.parent && this.parent.uuid

    /*
    Beware: During initial scene loading this can cause serious issues by clearing out userData before references are wired up
    Can we just avoid calling refreshUserData here?
    */
    if (!this.editor.sceneIsLoading) {
      if (this.object.refreshUserData) {
        var recursive = true
        this.object.refreshUserData(recursive)
      }
    }

    // clone to ensure changes to the object

    // faster but can be dangerous if referenced values change on the object which affect saved json
    // Known problems:
    //   - OsModuleGrid.cellsActive
    // output.object = this.object.toJSON()
    //
    // slower but safer
    output.object = JSON.parse(JSON.stringify(this.object.toJSON()))

    output.select = this.select
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)

    this.select = json.select

    // the parent must already exist, match it, never create it
    this.parent = this.editor.objectByUuid(json.parentUuid) || this.editor.scene

    this.object = this.editor.objectByUuid(json.object.object.uuid)

    if (!this.object) {
      // Replace object with either a) reference to existing object b) construct a new object
      var loader = new THREE.ObjectLoader()

      var newObject = loader.parseObject(json.object.object, editor.geometries, editor.materials)

      // @TODO: Untangle these extra hacks required for certain objects
      if (newObject && newObject.type === 'OsModuleGrid') {
        newObject.refreshFromChildren(this.editor, true)
      }
      this.object = newObject
    }
  },
}
