/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @constructor
 */

var SetViewValueCommand = function (viewObject, attributeName, newValue, ViewHelper) {
  Command.call(this)

  this.type = 'SetViewValueCommand'
  this.name = 'Set View Value Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['viewUuid', 'attributeName', 'newValue', 'oldValue']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.viewUuid = viewObject.uuid
    this.attributeName = attributeName
    this.oldValue = viewObject !== undefined ? viewObject[attributeName] : undefined
    this.newValue = newValue
  }
}

SetViewValueCommand.prototype = {
  execute: function () {
    var viewInstance = this.ViewHelper.getViewByUuid(this.viewUuid)
    viewInstance[this.attributeName] = this.newValue
    editor.signals.viewsChanged.dispatch(this.ViewHelper.views, this.viewUuid)
  },

  undo: function () {
    var viewInstance = this.ViewHelper.getViewByUuid(this.viewUuid)
    viewInstance[this.attributeName] = this.oldValue
    editor.signals.viewsChanged.dispatch(this.ViewHelper.views, this.viewUuid)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
