/**
 * @author adampryor
 */

function OsHorizon(radius, elevations, options) {
  THREE.Mesh.call(this)

  this.geometry = new HorizonGeometry(radius, elevations)
  this.geometry.excludeFromExport = true
  // Need double side because we need to see one side but cash shadows from the other side
  //
  // Update: We no longer cast shadows from OsHoziron because it is confusing
  // and not really compatible with near shadows
  this.material = new THREE.MeshBasicMaterial({ color: 0xcccccc, side: THREE.DoubleSide })
  this.fileName = options && options.fileName
  this.type = 'OsHorizon'
  this.receiveShadows = false
  this.castShadow = false
  this.userData.excludeFromExport = true
}

OsHorizon.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsHorizon,
  refreshUserData: function () {
    this.userData.fileName = this.fileName
  },
  applyUserData: function () {
    this.fileName = this.userData.fileName
  },
})

function HorizonGeometry(radius, elevations) {
  THREE.Geometry.call(this)

  this.parameters = {
    radius: radius,
    elevations: elevations,
  }

  this.fromBufferGeometry(new HorizonBufferGeometry(radius, elevations))
  this.mergeVertices()
}

HorizonGeometry.prototype = Object.create(THREE.Geometry.prototype)
HorizonGeometry.prototype.constructor = HorizonGeometry

// HorizonBufferGeometry

function HorizonBufferGeometry(radius, elevations) {
  BufferGeometry.call(this)

  this.type = 'HorizonBufferGeometry'

  this.parameters = {
    radius: radius,
    elevations: elevations,
  }

  var scope = this

  radius = radius || 10
  elevations = elevations || _.range(360).map((i) => Math.round(i / 10) + 5)

  // buffers

  var indices = []
  var vertices = []
  var normals = []
  var uvs = []

  // helper variables

  var index = 0
  var indexArray = []

  // generate geometry

  generateTorso()

  // build geometry

  this.setIndex(indices)
  this.setAttribute('position', new Float32BufferAttribute(vertices, 3))
  this.setAttribute('normal', new Float32BufferAttribute(normals, 3))
  this.setAttribute('uv', new Float32BufferAttribute(uvs, 2))

  function generateTorso() {
    var x, y
    var normal = new THREE.Vector3()
    var vertex = new THREE.Vector3()

    // generate vertices, normals and uvs

    for (y = 0; y <= 1; y++) {
      var indexRow = []

      for (x = 0; x <= elevations.length; x++) {
        // Ensure last elevation wraps back to first elevation
        var height = Math.tan(elevations[x % elevations.length] * THREE.Math.DEG2RAD) * radius

        var u = x / elevations.length

        //Start from North
        var theta = u * Math.PI * 2

        var sinTheta = Math.sin(theta)
        var cosTheta = Math.cos(theta)

        // vertex

        vertex.x = radius * sinTheta
        vertex.z = y * height
        vertex.y = radius * cosTheta
        vertices.push(vertex.x, vertex.y, vertex.z)

        // normal

        normal.set(sinTheta, 0, cosTheta).normalize()
        normals.push(normal.x, normal.y, normal.z)

        // uv

        uvs.push(u, 1 - y)

        // save index of vertex in respective row

        indexRow.push(index++)
      }

      // now save vertices of the row in our index array

      indexArray.push(indexRow)
    }

    // generate indices

    for (x = 0; x < elevations.length; x++) {
      for (y = 0; y < 1; y++) {
        // we use the index array to access the correct indices

        var a = indexArray[y][x]
        var b = indexArray[y + 1][x]
        var c = indexArray[y + 1][x + 1]
        var d = indexArray[y][x + 1]

        // faces

        indices.push(a, b, d)
        indices.push(b, c, d)
      }
    }
  }
}

HorizonBufferGeometry.prototype = Object.create(BufferGeometry.prototype)
HorizonBufferGeometry.prototype.constructor = HorizonBufferGeometry
