/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @param newPosition THREE.Vector3
 * @param optionalOldPosition THREE.Vector3
 * @constructor
 */

var SetEdgeTypeCommand = function (object, newEdgeType) {
  Command.call(this)

  this.type = 'SetEdgeTypeCommand'
  this.name = 'Set Edge Type'
  this.updatable = true
  this.maxTimeDifferenceForUpdate = 5000
  this.timeStamp = Date.now()

  this.PROPERTIES = ['oldEdgeType', 'newEdgeType']
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.object = object
    this.oldEdgeType = null
    this.newEdgeType = newEdgeType
  }
}
SetEdgeTypeCommand.prototype = {
  execute: function () {
    if (!this.oldEdgeType) {
      this.oldEdgeType = this.object.edgeType
    }
    this.object.edgeType = this.newEdgeType
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  undo: function () {
    this.object.edgeType = this.oldEdgeType
    this.editor.signals.objectChanged.dispatch(this.object)
  },

  update: function (command) {
    this.newEdgeType = command.newEdgeType
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
