/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 *
 * @constructor
 */

var SetViewCommand = function (newViewUuid, _ViewHelper) {
  Command.call(this)

  this.type = 'SetViewCommand'
  this.name = 'Set View Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['newViewUuid', 'oldViewUuid']

  // Indicates that this command does not modify the actual design data so it should not flag unsaved changes
  this.isEphemeral = true

  this.ViewHelper = _ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.newViewUuid = newViewUuid
    this.oldViewUuid = _ViewHelper?.selectedViewUuid()
  }
}

SetViewCommand.prototype = {
  execute: function () {
    this.ViewHelper.loadViewByUuid(this.newViewUuid, this.editor)
    this.ViewHelper.selectViewByUUID(this.newViewUuid)
  },

  undo: function () {
    this.ViewHelper.loadViewByUuid(this.oldViewUuid, this.editor)
    this.ViewHelper.selectViewByUUID(this.oldViewUuid)
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
