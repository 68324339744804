window.pluginViridian = {
  id: 4100,
  countryCodes: [], //all
  name: 'pluginViridian',
  componentCodes: ['ALL'],
  plugin: function () {
    ////////////////////////////////////////////////////////////////
    // CUSTOM RACKING LOGIC
    ////////////////////////////////////////////////////////////////

    /*
    @TODO:
    - [x] Add variations for G1, M10. Just add suffix to the component codes where applicable. There is no need to change the main logic.
    - [x] Add ArcBox
    - [x] Hide viridian components from the scene in Online Proposal and PDF Proposal

    @TODO Post MVP:
    - [ ] Add Velux Roof Window support (advanced, this could be post-MVP)
    - [ ] Show warnings for invalid configurations
    - [ ] Use jsonSchema to populate the form fields which read/write to system.integration_json.viridian, instead of coding them directly in react left panel in studio.
    */

    window.pluginDebug = false

    var VIRIDIAN_MANUFACTURER_NAME = 'Viridian Solar'

    var componentColors = {
      // portrait
      'F16-TC': 'FF0000',
      'F16-TR': 'ff3300',
      'F16-TL': 'FEFF00',
      'F16-TY': '00ff22',
      'F16-CLB': 'FEFF00',
      'F16-CLB-S': '808080',
      'F16-CRT': '1074bc',
      'F16-CLT': '00ff22',
      'F16-CRB': 'ff3300',
      'F16-J': '00BFFF',
      'VAT-16': '808080',

      // landscape
      'F16-LC': 'FF0000',
      'F16-LR': 'ff3300',
      'F16-LL': 'FEFF00',
      'F16-LY': '00ff22',
      'F16-LCLB': 'FEFF00',
      'F16-LCLB-S': '808080',
      'F16-LCRT': '1074bc',
      'F16-LCLT': '00ff22',
      'F16-LCRB': 'ff3300',
      'F16-LJ': '00BFFF',
      'VAL-16': '808080',
    }
    Object.entries(componentColors).forEach(([code, value]) => {
      componentColors[code + '-G1'] = value
      componentColors[code + '-M10'] = value
    })

    var flashingComponents = [
      // landscape
      { code: 'F16-LL', size: 230, name: 'Clearline Fusion M10 landscape roofing kit - Left' },
      { code: 'F16-LC', size: 230, name: 'Clearline Fusion M10 landscape roofing kit - Centre' },
      { code: 'F16-LR', size: 230, name: 'Clearline Fusion M10 landscape roofing kit - Right' },
      { code: 'F16-LY', size: 129, name: 'Clearline Fusion M10 landscape roofing kit - Row' },
      { code: 'F16-LJ', size: 111, name: 'Clearline Fusion M10 landscape roofing kit - Joining' },
      { code: 'VAL-16', size: 274, name: 'Clearline Fusion M10 landscape roofing kit - single panel' },
      { code: 'F16-LCLT', size: 128, name: 'Clearline Fusion M10 landscape corner conversion kit - left top corner' },
      { code: 'F16-LCRT', size: 128, name: 'Clearline Fusion M10 landscape corner conversion kit - right top corner' },
      {
        code: 'F16-LCRB',
        size: 110,
        name: 'Clearline Fusion M10 landscape corner conversion kit - right bottom corner',
      },
      {
        code: 'F16-LCLB',
        size: 208,
        name: 'Clearline Fusion M10 landscape corner conversion kit - left bottom corner (centre panel above)',
      },
      {
        code: 'F16-LCLB-S',
        size: 208,
        name: 'Clearline Fusion M10 landscape corner conversion kit - left bottom corner (left panel above)',
      },

      // portrait
      { code: 'F16-TL', size: 160, name: 'Clearline Fusion M10 portrait roofing kit - Left' },
      { code: 'F16-TC', size: 160, name: 'Clearline Fusion M10 portrait roofing kit - Centre' },
      { code: 'F16-TR', size: 160, name: 'Clearline Fusion M10 portrait roofing kit - Right' },
      { code: 'F16-TY', size: 122, name: 'Clearline Fusion M10 portrait roofing kit - Row' },
      { code: 'F16-J', size: 90, name: 'Clearline Fusion M10 portrait roofing kit - Joining' },
      { code: 'VAT-16', size: 200, name: 'Clearline Fusion M10 portrait roofing kit - single panel' },
      { code: 'F16-CLT', size: 119, name: 'Clearline Fusion M10 portrait corner conversion kit - left top corner' },
      { code: 'F16-CRT', size: 119, name: 'Clearline Fusion M10 portrait corner conversion kit - right top corner' },
      { code: 'F16-CRB', size: 89, name: 'Clearline Fusion M10 portrait corner conversion kit - right bottom corner' },
      {
        code: 'F16-CLB',
        size: 198,
        name: 'Clearline Fusion M10 portrait corner conversion kit - left bottom corner (centre panel above)',
      },
      {
        code: 'F16-CLB-S',
        size: 198,
        name: 'Clearline Fusion M10 portrait corner conversion kit - left bottom corner (left panel above)',
      },
      { code: 'F16-VC', size: 177, name: 'M10 Conversion kit for Velux roof window - centre position' },
      { code: 'F16-VB', size: 177, name: 'M10 Conversion kit for Velux roof window - bottom centre position' },
    ]

    // Created from JSON.stringify(this.state.packers.map(p => ({code: p[0], size: p[2], name: p[3]})))
    var packerComponents = [
      { code: 'SP-5', size: 5.81, name: 'Rafter bracket spacer 5mm - pack of 20' },
      { code: 'SP-5J', size: 9.27, name: 'Rafter joining bracket spacer 5mm - pack of 20' },
      { code: 'B22', size: 24.34, name: 'Rafter bracket for 22mm batten - pack of 20' },
      { code: 'B22J', size: 35.15, name: 'Rafter joining bracket for 22mm batten - pack of 20' },
      {
        code: 'SB16-L',
        size: 11.35,
        name: 'Fusion sarking bracket kit - for use with F16-TL on roofs without tile battens',
      },
      {
        code: 'SB16-C',
        size: 11.35,
        name: 'Fusion sarking bracket kit - for use with F16-TC on roofs without tile battens',
      },
      {
        code: 'SB16-R',
        size: 11.35,
        name: 'Fusion sarking bracket kit - for use with F16-TR on roofs without tile battens',
      },
      {
        code: 'SB16-Y',
        size: 11.35,
        name: 'Fusion sarking bracket kit - for use with F16-TY on roofs without tile battens',
      },
      {
        code: 'SB16-J',
        size: 11.35,
        name: 'Fusion sarking bracket kit - for use with F16-J on roofs without tile battens',
      },
      {
        code: 'SB16-AT',
        size: 11.35,
        name: 'Fusion sarking bracket kit - for use with VAT16 on roofs without tile battens',
      },
      {
        code: 'SB16-CLB',
        size: 5.68,
        name: 'Fusion sarking bracket kit - for use with F16-CLB and CLBS on roofs without tile battens',
      },
    ]

    // Created from JSON.stringify(this.state.arcBoxes)
    var arcBoxComponents = [
      {
        code: 'ARC-04',
        description:
          'ArcBox Solar Connector Enclosure - (sold in boxes of 12, price shown is per unit, number assumes one string per block of panels)',
      },
      {
        code: 'ARC-BM-25(1)',
        description:
          'ArcBox Batten Mount for tile battens 22-25mm thickness - (sold in boxes of 12, price shown is per unit)',
      },
      {
        code: 'ARC-BM-30(1)',
        description:
          'ArcBox Batten Mount for tile battens 30mm thickness - (sold in boxes of 12, price shown is per unit)',
      },
      {
        code: 'ARC-BM-38(1)',
        description:
          'ArcBox Batten Mount for tile battens 38mm thickness - (sold in boxes of 12, price shown is per unit)',
      },
    ]

    var componentCodesAllManaged = [
      ...flashingComponents.map((c) => c.code),
      ...flashingComponents.map((c) => c.code + '-G1'),
      ...flashingComponents.map((c) => c.code + '-M10'),
      ...packerComponents.map((c) => c.code),
      ...packerComponents.map((c) => c.code + '-G1'),
      ...packerComponents.map((c) => c.code + '-M10'),
      ...arcBoxComponents.map((c) => c.code),
    ]

    var cellTypes = {
      empty: 0,
      solar: 1,
      window: 2,
    }

    function initViridianData(system) {
      if (!system.integration_json) {
        system.integration_json = {}
      }
      if (!system.integration_json.viridian) system.integration_json.viridian = {}
      if (system.integration_json.viridian.enabled === undefined) system.integration_json.viridian.enabled = true
    }

    function clearViridianData(system) {
      if (system.integration_json?.viridian) {
        delete system.integration_json?.viridian
      }
    }

    function ViridianDesignerClass(panelCode, battenThickness, landscape, inputCells, offsetCells, arcBoxType) {
      this.size =
        inputCells.length > 0
          ? new THREE.Vector2(
              Math.max(...inputCells.map((cell) => cell.x)) + 1,
              Math.max(...inputCells.map((cell) => cell.y)) + 1
            )
          : new THREE.Vector2(0, 0)

      this.panelCode = panelCode
      this.landscape = landscape
      this.arcBoxType = arcBoxType
      this.battenThickness = battenThickness

      this.cells = new Array(this.size.y).fill(null).map((i) => new Array(this.size.x).fill(cellTypes.empty))

      if (inputCells) {
        inputCells.forEach((inputCell) => {
          this.cells[inputCell.y][inputCell.x] = inputCell.value
        })
      }
      this.numPanels = 0
      this.flashing = []
      this.corners = []
      this.packers = {}
      this.arcBoxes = {}
    }

    ViridianDesignerClass.prototype = Object.assign({
      generateBom: function () {
        // First calculate flashing and corners which do not depend on anything else
        for (var y = 0; y < this.size.y; y++) {
          for (var x = 0; x < this.size.x; x++) {
            var flashingForCell = this.detectFlashingForCell(x, y, this.landscape)
            if (flashingForCell) {
              this.flashing.push({
                code: flashingForCell,
                col: x,
                row: y,
              })
            }

            var cornersForCell = this.detectCornersForCell(x, y, this.landscape)
            if (cornersForCell) {
              cornersForCell.forEach((cornerForCell) => {
                this.corners.push(cornerForCell)
              })
            }
          }
        }

        // Next calculate packers which depends on flashing.
        var packersItems = this.calculatePackers(
          this.battenThickness,
          this.landscape,
          this.flashing.concat(this.corners)
        )
        packersItems.forEach((_packer) => {
          if (!this.packers[_packer.code]) {
            this.packers[_packer.code] = 0
          }
          this.packers[_packer.code] += _packer.quantity
        })

        var panelCount = 0
        for (let y = 0; y < this.size.y; y++) {
          for (let x = 0; x < this.size.x; x++) {
            if (this.cells[y][x] === cellTypes.solar) {
              panelCount++
            }
          }
        }

        this.arcBoxes = this.calculateArcBoxes(panelCount, this.arcBoxType, this.battenThickness)

        var componentBomSuffix = panels.find((panel) => panel.code === this.panelCode).componentBomSuffix

        // Round up packers to next whole unit
        // After all other logic has been applied so rounding does not affect any other calcs
        packersItems.forEach((_packer) => {
          this.packers[_packer.code] = Math.ceil(this.packers[_packer.code])
        })

        var compileBom = (flashing, corners, packers, arcBoxes) => {
          var bom = { ...packers }

          flashing.forEach((flash) => {
            if (!bom[flash.code]) {
              bom[flash.code] = 0
            }
            bom[flash.code] += 1
          })

          corners.forEach((_corner) => {
            if (!bom[_corner.code]) {
              bom[_corner.code] = 0
            }
            bom[_corner.code] += 1
          })

          arcBoxes.forEach((arcBox) => {
            if (!bom[arcBox.code]) {
              bom[arcBox.code] = 0
            }
            bom[arcBox.code] += arcBox.quantity
          })

          return bom
        }

        var bomWithSuffix = {
          flashing: [],
          corners: [],
          packers: {},
          arcBoxes: this.arcBoxes, //suffix not required
          bom: {},
        }

        // Add componentBomSuffix
        // Do this as a step right at the end so all the internal logic
        Object.entries(this.packers).forEach(([code, quantity]) => {
          bomWithSuffix.packers[code + componentBomSuffix] = quantity
        })

        this.flashing.forEach((flash) => {
          bomWithSuffix.flashing.push({
            code: flash.code + componentBomSuffix,
            row: flash.row,
            col: flash.col,
          })
        })

        this.corners.forEach((corner) => {
          bomWithSuffix.corners.push({
            code: corner.code + componentBomSuffix,
            row: corner.row,
            col: corner.col,
            x: corner.x,
            y: corner.y,
          })
        })

        bomWithSuffix.bom = compileBom(
          bomWithSuffix.flashing,
          bomWithSuffix.corners,
          bomWithSuffix.packers,
          bomWithSuffix.arcBoxes
        )

        return bomWithSuffix
      },

      getCell: function (x, y) {
        return this.cells[this.getCellIndex(x, y)]
      },

      getCellIndex: function (x, y) {
        return y * this.size + x
      },

      detectFlashingForCell: function (x, y, landscape) {
        if (this.cells[y][x] === cellTypes.solar) {
          if (this.cells[y][x - 1]) {
            // TR, TC, J
            if (this.cells[y + 1] && this.cells[y + 1][x]) {
              return landscape ? 'F16-LJ' : 'F16-J'
            } else if (this.cells[y][x + 1]) {
              return landscape ? 'F16-LC' : 'F16-TC'
            } else {
              return landscape ? 'F16-LR' : 'F16-TR'
            }
          } else {
            // TY, TL or VAT-16

            if (this.cells[y][x + 1]) {
              if (this.cells[y + 1] && this.cells[y + 1][x]) {
                return landscape ? 'F16-LY' : 'F16-TY'
              } else {
                return landscape ? 'F16-LL' : 'F16-TL'
              }
            } else {
              if (this.cells[y + 1] && this.cells[y + 1][x]) {
                return landscape ? 'F16-LY' : 'F16-TY'
              } else {
                return landscape ? 'VAL-16' : 'VAT-16'
              }
            }
          }
        }

        return null
      },

      detectCornersForCell: function (x, y, landscape) {
        // Beware, we can never specify test coordinates on the outer edge of the grid.
        // The first check for each case ensures we do not go outside the bounds of the grid

        // if empty, check for corners
        if (!this.cells[y][x]) {
          var corners = []
          if (
            x !== this.size.x - 1 &&
            y !== this.size.y - 1 &&
            this.cells[y][x + 1] &&
            this.cells[y + 1][x] &&
            this.cells[y + 1][x + 1]
          ) {
            // bottom right corner
            corners.push({ code: landscape ? 'F16-LCLT' : 'F16-CLT', col: x, row: y, x: x + 0.5, y: y + 0.5 })
          }
          if (
            x !== 0 &&
            y !== this.size.y - 1 &&
            this.cells[y][x - 1] &&
            this.cells[y + 1][x] &&
            this.cells[y + 1][x - 1]
          ) {
            // bottom left corner
            corners.push({ code: landscape ? 'F16-LCRT' : 'F16-CRT', col: x, row: y, x: x - 0.5, y: y + 0.5 })
          }
          if (
            x !== this.size.x - 1 &&
            y !== 0 &&
            this.cells[y][x + 1] &&
            this.cells[y - 1][x + 1] &&
            this.cells[y - 1][x] &&
            !this.cells[y - 1][x - 1]
          ) {
            // top right corner
            corners.push({ code: landscape ? 'F16-LCLB-S' : 'F16-CLB-S', col: x, row: y, x: x + 0.5, y: y - 0.5 })
          }
          if (x !== 0 && y !== 0 && this.cells[y][x - 1] && this.cells[y - 1][x] && this.cells[y - 1][x]) {
            // top left corner
            corners.push({ code: landscape ? 'F16-LCRB' : 'F16-CRB', col: x, row: y, x: x - 0.5, y: y - 0.5 })
          }
          if (
            x !== this.size.x - 1 &&
            y !== 0 &&
            this.cells[y - 1][x - 1] &&
            this.cells[y - 1][x] &&
            this.cells[y - 1][x + 1] &&
            this.cells[y][x + 1]
          ) {
            // top right corner
            corners.push({ code: landscape ? 'F16-LCLB' : 'F16-CLB', col: x, row: y, x: x + 0.5, y: y - 0.5 })
          }
          return corners
        } else {
          return null
        }
      },

      /* calculations for the packers */
      calculatePackers: function (width, landscape, flashingsAndCorners) {
        function getFlashingQuantityAppliedByCodes(codes) {
          // specify multiple codes to include the sum of all of them
          return flashingsAndCorners.filter((c) => codes.includes(c.code)).length
        }

        var packerItems = []

        if (width === 38) {
          width = 35
        }

        if (width > 25) {
          if (landscape === false) {
            packerItems.push({
              code: packerComponents[0].code,
              quantity:
                ((getFlashingQuantityAppliedByCodes(['F16-TL', 'F16-TC', 'F16-TR', 'VAT-16']) * 4 +
                  getFlashingQuantityAppliedByCodes(['F16-VC']) * 2) *
                  ((width - 25) / 5)) /
                20.0,
            })
            packerItems.push({
              code: packerComponents[1].code,
              quantity: (getFlashingQuantityAppliedByCodes(['F16-TY', 'F16-J']) * 2 * ((width - 25) / 5)) / 20.0,
            })
          } else {
            packerItems.push({
              code: packerComponents[0].code,
              quantity:
                (getFlashingQuantityAppliedByCodes(['F16-LL', 'F16-LC', 'F16-LR', 'VAL-16']) * 4 * ((width - 25) / 5)) /
                20.0,
            })
            packerItems.push({
              code: packerComponents[1].code,
              quantity: (getFlashingQuantityAppliedByCodes(['F16-LY', 'F16-LJ']) * 2 * ((width - 25) / 5)) / 20.0,
            })
          }
        } else {
          if (width === 0) {
            packerItems.push({
              code: packerComponents[4].code,
              quantity: getFlashingQuantityAppliedByCodes(landscape ? ['F16-LL'] : ['F16-TL']),
            })
            packerItems.push({
              code: packerComponents[5].code,
              quantity: getFlashingQuantityAppliedByCodes(landscape ? ['F16-LC'] : ['F16-TC']),
            })
            packerItems.push({
              code: packerComponents[6].code,
              quantity: getFlashingQuantityAppliedByCodes(landscape ? ['F16-LR'] : ['F16-TR']),
            })
            packerItems.push({
              code: packerComponents[7].code,
              quantity: getFlashingQuantityAppliedByCodes(landscape ? ['F16-LY'] : ['F16-TY']),
            })
            packerItems.push({
              code: packerComponents[8].code,
              quantity: getFlashingQuantityAppliedByCodes(landscape ? ['F16-LJ'] : ['F16-J']),
            })

            packerItems.push({
              code: packerComponents[9].code,
              quantity: getFlashingQuantityAppliedByCodes(landscape ? ['VAL-16'] : ['VAT-16', 'F16-VC']),
            })

            packerItems.push({
              code: packerComponents[10].code,
              quantity: getFlashingQuantityAppliedByCodes(
                landscape ? ['F16-LCLB', 'F16-LCLB-S'] : ['F16-CLB', 'F16-CLB-S']
              ),
            })
          } else {
            if (width === 22) {
              if (!landscape) {
                packerItems.push({
                  code: packerComponents[2].code,
                  quantity:
                    (getFlashingQuantityAppliedByCodes(['F16-TL', 'F16-TC', 'F16-TR', 'VAT-16']) * 4 +
                      getFlashingQuantityAppliedByCodes(['F16-VC']) * 2) /
                    20.0,
                })

                packerItems.push({
                  code: packerComponents[3].code,
                  quantity: (getFlashingQuantityAppliedByCodes(['F16-TY', 'F16-J']) * 2) / 20.0,
                })
              } else {
                packerItems.push({
                  code: packerComponents[2].code,
                  quantity: (getFlashingQuantityAppliedByCodes(['F16-LL', 'F16-LC', 'F16-LR', 'VAL-16']) * 6) / 20.0,
                })

                packerItems.push({
                  code: packerComponents[3].code,
                  quantity: (getFlashingQuantityAppliedByCodes(['F16-LY', 'F16-LJ']) * 3) / 20.0,
                })
              }
            }
          }
        }

        return packerItems
      },

      // How many 'blocks' of panels are in the grid
      calculatePanelBlocks: function (cells) {
        console.warn('@TODO: Copied from reference app. Not yet implemented.')
        var searchedSquares = new Set()
        var blocks = 0

        for (var i = 0; i < cells.length; i++) {
          for (var c = 0; c < cells[i].length; c++) {
            let cellKey = i.toString() + ':' + c.toString()
            if (searchedSquares.has(cellKey)) {
              continue
            }

            if (cells[i][c] > 0) {
              blocks++
              var queue = [[i, c]]
              searchedSquares.add(cellKey)

              while (queue.length > 0) {
                this.searchPanels(cells, searchedSquares, queue)
              }
            } else {
              searchedSquares.add(cellKey)
            }
          }
        }

        return blocks
      },

      // cells:          The 2d array representing the grid
      // searchedSquares: A set of the coordinates that have already been searched
      // queue:           The current queue of panels we need to look at
      searchPanels: function (cells, searchedSquares, queue) {
        console.warn('@TODO: Copied from reference app. Not yet implemented.')

        var leftRightUpDown = [
          [1, 0],
          [-1, 0],
          [0, 1],
          [0, -1],
        ]

        var i = queue[0][0]
        var c = queue[0][1]

        for (const direction of leftRightUpDown) {
          var y = i + direction[0]
          var x = c + direction[1]
          var cellKey = y.toString() + ':' + x.toString()
          if (y < 0 || x < 0 || y >= cells.length || x >= cells[0].length) {
            continue
          }

          if (searchedSquares.has(cellKey)) {
            continue
          }

          if (cells[y][x] === cellTypes.solar) {
            queue.push([y, x])
          }

          searchedSquares.add(cellKey)
        }

        queue.shift()
      },

      calculateArcBoxes: function (panelCount, arcBoxType, battenThickness) {
        var arcBoxes = []

        // If it is not enabled or set to 'none' then we set the quanity of all arcBoxes to 0
        if (!arcBoxType) {
          return []
        } else {
          // Get the number of blocks of panels
          var panelBlockCount = this.calculatePanelBlocks(this.cells)

          var quantity = 0
          var quantityIndex = -1
          if (arcBoxType === 1) {
            quantity = panelCount + panelBlockCount
          } else if (arcBoxType === 2) {
            quantity = panelBlockCount * 2
          }

          switch (battenThickness) {
            case 22:
            case 25:
              quantityIndex = 1
              break
            case 30:
              quantityIndex = 2
              break
            case 38:
              quantityIndex = 3
              break
            default:
              break
          }

          arcBoxes.push({
            code: arcBoxComponents[0].code,
            quantity: quantity,
          })

          if (quantityIndex > 0) {
            arcBoxes.push({
              code: arcBoxComponents[quantityIndex].code,
              quantity: quantity,
            })
          }
        }

        return arcBoxes
      },
    })

    // Relied on by the tests
    window.ViridianDesignerClass = ViridianDesignerClass

    ////////////////////////////////////////////////////////////////
    // PLUGIN BINDINGS
    ////////////////////////////////////////////////////////////////

    var panels = [
      {
        code: 'PV16-270P-B',
        name:
          '270 Wp Clearline PV High Performance Polycrystalline Silicon Solar Photovoltaic Panel, with black backsheet and specialised black frame for roof integration in combination with Clearline Fusion flashing kits. ',
        componentBomSuffix: '',
        aliases: [],
      },
      {
        code: 'PV16-270P-W',
        name:
          '270 Wp Clearline PV High Performance Polycrystalline Silicon Solar Photovoltaic Panel, with white backsheet and specialised black frame for roof integration in combination with Clearline Fusion flashing kits. ',
        componentBomSuffix: '',
        aliases: [],
      },
      {
        code: 'PV16-335-G1',
        name:
          '335 Wp Clearline PV High Performance Monocrystalline Silicon Solar Photovoltaic Panel.  G1 format half cut cells with black backsheet and specialised black frame for roof integration with G1 roofing kits.',
        componentBomSuffix: '-G1',
        aliases: ['Clearline PV16-335', 'PV16-335'],
      },
      {
        code: 'PV16-340-G1W',
        name:
          '340 Wp Clearline PV High Performance Monocrystalline Silicon Solar Photovoltaic Panel.  G1 format half cut cells with white backsheet and specialised black frame for roof integration with G1 roofing kits.',
        componentBomSuffix: '-G1',
        aliases: ['Clearline PV16-340', 'PV16-340'],
      },
      {
        code: 'PV16-405-M10',
        name:
          '405 Wp Clearline PV High Performance Monocrystalline Silicon Solar Photovoltaic Panel.  M10 format half cut cells with black backsheet and specialised black frame for roof integration with M10 roofing kits.',
        componentBomSuffix: '-M10',
        aliases: ['PV16-405'],
      },
      {
        code: 'PV16-400-M10',
        name:
          '400 Wp Clearline PV High Performance Monocrystalline Silicon Solar Photovoltaic Panel.  M10 format half cut cells with black backsheet and specialised black frame for roof integration with M10 roofing kits.',
        componentBomSuffix: '-M10',
        aliases: ['PV16-400'],
      },
    ]

    var getPanelCodeFromCodeOrAlias = function (panelCodeRaw) {
      var panel
      for (var i = 0; i < panels.length; i++) {
        panel = panels[i]
        if (panel.code === panelCodeRaw || panel.aliases?.includes(panelCodeRaw)) {
          return panel.code
        }
      }
    }

    var panelCodes = panels.map((panel) => panel.code)

    function getInputCellsAndOffset(cellsActive) {
      /*
  Module Grid cells can be negative but our viridian class cannot accept negative indexes.
  Therefore, we re-align the grid to 0,0 and record the offset
  */

      // flip X coordinates because the cells are in the opposite horizontal direction
      var flip = new THREE.Vector2(-1, 1)

      var inputCells = cellsActive.map((cell) => {
        var cellCoordinates = cell.split(',').map((v) => parseInt(v))
        // Add extra value which sets the cell type, i.e. 1=panel
        return {
          x: flip.x * cellCoordinates[0],
          y: flip.y * cellCoordinates[1],
          value: 1,
        }
      })
      var minX = Math.min(...inputCells.map((c) => c.x))
      var minY = Math.min(...inputCells.map((c) => c.y))

      var offsetCells = new THREE.Vector2(minX < 0 ? -minX : 0, minY < 0 ? -minY : 0)
      if (offsetCells.x || offsetCells.y) {
        inputCells = inputCells.map((c) => ({
          x: c.x + offsetCells.x,
          y: c.y + offsetCells.y,
          value: c.value,
        }))
      }
      return [inputCells, offsetCells, flip]
    }

    function getRoofSlopeCompatibility(system) {
      const roofSlopesAreCompatible = system.moduleGrids().some((moduleGrid) => {
        const slope = moduleGrid.getSlope()
        return slope <= 60 && slope >= 20
      })

      return roofSlopesAreCompatible
    }

    function getPanelCode(system) {
      const panelCodeRaw = system.moduleType().code
      const panelCode = getPanelCodeFromCodeOrAlias(panelCodeRaw)
      return panelCode
    }

    function getPanelCompatibility(system) {
      const panelCode = getPanelCode(system)
      const panelIsCompatible = panelCodes.includes(panelCode)
      return panelIsCompatible
    }

    function calculate(system) {
      // Check show_customer settings from activations if they are found, otherwise default to False
      // @TODO: Can we cache this and avoid looking it up every time we call sync()? This is not too urgent beacuse
      // we have already optimized this by prepareing a quick lookup dict once, but it could still be a lot faster.
      var otherComponentIdByCode = {}
      var otherComponentShowCustomer = {}
      AccountHelper.getComponentOtherSpecsAvailable().forEach((otherComponentType) => {
        otherComponentShowCustomer[otherComponentType.code] = otherComponentType.show_customer
        otherComponentIdByCode[otherComponentType.code] = otherComponentType.id
      })
      const panelCode = getPanelCode(system)
      const panelIsCompatible = getPanelCompatibility(system)
      const roofSlopesAreCompatible = getRoofSlopeCompatibility(system)

      const panelLayoutIsCompatible = (cells) => {
        var compatible = true
        cells.forEach((row, y) => {
          row.forEach((cell, x) => {
            if (
              cell &&
              cells[y + 1] &&
              cells[y + 1][x + 1] &&
              (!cells[y + 1] || !cells[y + 1][x]) &&
              !cells[y][x + 1]
            ) {
              compatible = false
            } else if (
              cell &&
              cells[y + 1] &&
              cells[y + 1][x - 1] &&
              (!cells[y + 1] || !cells[y + 1][x]) &&
              !cells[y][x - 1]
            ) {
              compatible = false
            }
          })
        })
        return compatible
      }

      if (panelIsCompatible && roofSlopesAreCompatible) {
        var componentsToAddToSystem = []

        editor.selectedSystem.moduleGrids().forEach((mg) => {
          var [inputCells, offsetCells, flip] = getInputCellsAndOffset(mg.cellsActive)

          var batten_thickness =
            typeof system.integration_json?.viridian?.batten_thickness !== 'undefined'
              ? system.integration_json?.viridian?.batten_thickness
              : 25
          var landscape = mg.moduleLayout() === 'landscape'
          var arc_box = system.integration_json?.viridian?.arc_box || 0

          var viridianDesigner = new ViridianDesignerClass(
            panelCode,
            batten_thickness,
            landscape,
            inputCells,
            offsetCells,
            arc_box
          )

          if (!panelLayoutIsCompatible(viridianDesigner.cells)) {
            window.WorkspaceHelper.addProjectErrorToReduxStore({
              message:
                'Panel layout is not compatible with Viridian mounting system. Please ensure there are no corners with no panel overlap.',
              key: 'VIRIDIAN_PLUGIN_WARNING',
              severity: 'warning',
              systemId: system.uuid,
              source: 'plugin',
              category: 'mounting',
              options: {},
            })
            clearViridianData(system)
            return
          }
          var result = viridianDesigner.generateBom()

          var moduleSize = editor.selectedSystem.moduleType().size

          const flashingComponents = []
          result.flashing.forEach((flash) => {
            let thickness = 0.1

            flashingComponents.push({
              code: flash.code,
              scale: new THREE.Vector3(1, 1, 1),
              userData: {
                model: JSON.stringify({
                  shape: 'cube',
                  size_x: landscape ? moduleSize[1] : moduleSize[0],
                  size_y: landscape ? moduleSize[0] : moduleSize[1],
                  size_z: thickness,
                  color: componentColors[flash.code],
                  opacity: 0.3,
                }),
                quantity: 1,
              },
            })
          })

          addComponentsToSystem(flashingComponents, system, (other) => {
            const flash = result.flashing[other.index]

            var offsetZ = 0
            var p = mg.pointOnCell(
              (flash.col - offsetCells.x) * flip.x,
              flash.row - offsetCells.y,
              [0, 0],
              true,
              false,
              offsetZ
            )

            other.position.copy(p)
            Utils.applyOrientation(other, mg.getAzimuth(), mg.getPanelTilt())
          })

          result.corners.forEach((corner) => {
            let thickness = 0.2
            let p = mg.pointOnCell((corner.x - offsetCells.x) * flip.x, corner.y - offsetCells.y, [0, 0], true, false)

            addComponentsToSystem(
              [
                {
                  code: corner.code,
                  scale: new THREE.Vector3(1, 1, 1),
                  userData: {
                    model: JSON.stringify({
                      shape: 'cube',
                      size_x: 0.2,
                      size_y: 0.2,
                      size_z: thickness,
                      color: componentColors[corner.code],
                      opacity: 0.5,
                    }),
                    quantity: 1,
                  },
                },
              ],
              system,
              (other) => {
                other.position.copy(p)
                Utils.applyOrientation(other, mg.getAzimuth(), mg.getPanelTilt())
              }
            )
          })

          Object.entries(result.packers).forEach(([code, quantity]) => {
            componentsToAddToSystem.push({
              code,
              userData: {
                quantity: quantity,
              },
            })
          })

          result.arcBoxes.forEach((arcBox) => {
            componentsToAddToSystem.push({
              code: arcBox.code,
              userData: {
                quantity: arcBox.quantity,
              },
            })
          })
        })

        addComponentsToSystem(componentsToAddToSystem, system)
        editor.render()
        window.editor.signals.sceneGraphChanged.dispatch()
      } else {
        clearViridianData(system)
      }
    }

    function addComponentsToSystem(componentsData, system, componentCallback) {
      var otherComponentActivationCodes = window.AccountHelper.getComponentOtherSpecsAvailable().map((c) => c.code)

      // First add any components which have quantity > 0 but are not yet activated
      var otherComponentCodesToActivate = [
        ...new Set(
          componentsData
            .filter((componentData) => {
              return !otherComponentActivationCodes.includes(componentData.code)
            })
            .map((componentData) => componentData.code)
        ),
      ]

      function addComponents() {
        var otherComponentIdByCode = {}
        var otherComponentShowCustomer = {}
        window.AccountHelper.getComponentOtherSpecsAvailable().forEach((otherComponentType) => {
          otherComponentShowCustomer[otherComponentType.code] = otherComponentType.show_customer
          otherComponentIdByCode[otherComponentType.code] = otherComponentType.id
        })

        const getComponentActivationId = (code) =>
          otherComponentIdByCode.hasOwnProperty(code) ? otherComponentIdByCode[code] : null

        componentsData.forEach((componentData, index) => {
          var otherData = {
            code: componentData.code,
            manufacturer_name: VIRIDIAN_MANUFACTURER_NAME,
            show_customer: false,
            other_id: getComponentActivationId(componentData.code),
            userData: componentData.userData,
          }
          if (componentData.scale) {
            otherData.scale = componentData.scale
          }
          var other = new window.OsOther(otherData)
          other.applyUserData() // load component specs and reapply user data
          other.refreshRenderableObject() // apply the model used to colour the components
          other.index = index // passing the index for the callback
          if (componentCallback) componentCallback(other)
          window.editor.addObject(other, system)
        })
      }

      if (otherComponentCodesToActivate.length) {
        window.AccountHelper.activateComponents(otherComponentCodesToActivate, addComponents)
      } else {
        addComponents()
      }
    }

    // function debounce(func, delay) {
    //   let timer = null

    //   return function (...args) {
    //     window.clearTimeout(timer)
    //     timer = window.setTimeout(() => {
    //       func.call(this, ...args)
    //       timer = null
    //     }, delay)
    //   }
    // }

    const calculateWithDebounce = Utils.debounce(function (system) {
      calculate(system)
    }, 350)

    function sync(system) {
      const panelIsCompatible = getPanelCompatibility(system)
      const roofSlopesAreCompatible = getRoofSlopeCompatibility(system)
      if (system?.mounting === 'viridian' && panelIsCompatible && roofSlopesAreCompatible) {
        const newIntegration = system?.integration_json?.viridian?.enabled === undefined
        if (newIntegration) {
          window.amplitude.getInstance().logEvent('Integrated Racking BOM Saved', { integration: 'Viridian' })
        }
      }
      if (system?.mounting !== 'viridian') {
        if (panelIsCompatible && system?.mounting) {
          window.WorkspaceHelper.addProjectErrorToReduxStore({
            message: 'Viridian panels are only compatible with the Viridian mounting system.',
            key: 'VIRIDIAN_PLUGIN_WARNING',
            severity: 'warning',
            systemId: system.uuid,
            source: 'plugin',
            category: 'mounting',
            options: {},
          })
        } else {
          window.WorkspaceHelper.removeProjectErrorFromReduxStore('VIRIDIAN_PLUGIN_WARNING', system.uuid, 'plugin')
        }
      } else {
        if (!panelIsCompatible) {
          window.WorkspaceHelper.addProjectErrorToReduxStore({
            message: 'Mounting system is incompatible with modules in the system',
            key: 'VIRIDIAN_PLUGIN_WARNING',
            severity: 'warning',
            systemId: system.uuid,
            source: 'plugin',
            category: 'mounting',
            options: {},
          })
        } else if (!roofSlopesAreCompatible) {
          window.WorkspaceHelper.addProjectErrorToReduxStore({
            message: 'Mounting system is incompatible with roof slope. Roof slopes must be between 20-60 degrees.',
            key: 'VIRIDIAN_PLUGIN_WARNING',
            severity: 'warning',
            systemId: system.uuid,
            source: 'plugin',
            category: 'mounting',
            options: {},
          })
        } else {
          window.WorkspaceHelper.removeProjectErrorFromReduxStore('VIRIDIAN_PLUGIN_WARNING', system.uuid, 'plugin')
        }
      }

      if (system?.mounting === 'viridian') {
        initViridianData(system)
      }

      if (system?.mounting === 'viridian' && system?.integration_json?.viridian?.enabled) {
        // clear and proceed
        clear(system)
        calculateWithDebounce(system)
      } else if (shouldBeCleared(system)) {
        // clear and exit
        clear(system)
      } else {
        // exit
        // Force System panel to refresh
        window.OsOther.refreshVisibility(system.others())
      }
      return
    }

    function clear(system) {
      /* Remove all viridian components from system */
      system
        .others()
        .filter((c) => componentCodesAllManaged.includes(c.code))
        .forEach((c) => {
          if (c.getSystem && c.getSystem()?.uuid === system.uuid) {
            editor.removeObject(c)
          }
        })
    }

    function refresh(system) {
      sync(system)
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function shouldBeCleared(system) {
      // If system has viridian components but mounting system is not viridian then we may want to call a refresh
      // just to ensure the components get cleared.
      return system.mounting !== 'viridian' && !!system.others().find((c) => componentCodesAllManaged.includes(c.code))
    }

    function refreshSystemFromObject(object, attributeName) {
      // Only refresh racking due to chages to a) OsModule b) OsModuleGrid
      if (['OsModuleGrid', 'OsModule'].includes(object.type)) {
        // proceed with refresh
      } else if (object.type === 'OsSystem' && (shouldBeCleared(object) || attributeName === 'mounting')) {
        // proceed with refresh
      } else {
        return
      }

      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system) {
          refresh(system)
        }
      }
    }

    function objectAdded(object) {
      if (window.pluginDebug) {
        console.log('Viridian objectAdded', object)
      }
      refreshSystemFromObject(object)
    }

    function objectChanged(object, attributeName) {
      if (window.pluginDebug) {
        console.log('Viridian objectChanged', object)
      }
      if (attributeName === 'output') {
        return
      }
      refreshSystemFromObject(object, attributeName)
    }

    function objectRemoved(object) {
      if (window.pluginDebug) {
        console.log('Viridian objectRemoved', object)
      }
      refreshSystemFromObject(object)
    }

    function sceneGraphChanged(system) {
      if (window.pluginDebug) {
        console.log('Viridian sceneGraphChanged')
      }
    }

    function pluginLoaded() {
      if (window.pluginDebug) {
        console.log('Viridian pluginLoaded')
        console.warn('Inspect Script Here for interactive debugging in javascript console...')
      }
      refreshAllSystems()
    }

    function pluginUnloaded() {
      if (window.pluginDebug) {
        console.log('Viridian pluginUnloaded')
      }
      try {
        refreshAllSystems()
      } catch (error) {
        console.error(error)
      }
    }

    return {
      objectAdded,
      objectChanged,
      objectRemoved,
      sceneGraphChanged,
      pluginLoaded,
      pluginUnloaded,
    }
  },
}
