window.pluginLGES = {
  id: 1000,
  countryCodes: ['AU'],
  name: 'pluginLGES',
  componentCodes: [
    'LGES-5048', // inverter
    'LG CHEM RESU 6.5',
    'LG CHEM RESU 10',
    'LG CHEM RESU 13',
    'LG CHEM RESU 7H',
    'LG CHEM RESU 10H',
    'LG CHEM RESU 10H (TYPE C)',
    'LG ENERGY SOLUTION RESU6.5 LV',
    'LG ENERGY SOLUTION RESU10 LV',
    'LG ENERGY SOLUTION RESU12 LV',
    'LG ENERGY SOLUTION RESU13 LV',
    'LG ENERGY SOLUTION RESU7 HV-TYPE R',
    'LG ENERGY SOLUTION RESU10 HV-TYPE R',
    'LG ENERGY SOLUTION RESU10 HV-TYPE C',
    'LG ENERGY SOLUTION RESU10H PRIME',
    'LG ENERGY SOLUTION RESU16H PRIME',
  ],
  plugin: function () {
    function upperCaseSafe(value) {
      return value && value.toUpperCase ? value.toUpperCase() : value
    }

    var refreshDebouncedInner = Utils.debounce((system) => refresh(system), 1000)
    var refreshDebounced = function (system) {
      //system is required
      if (!system?.uuid) return
      refreshDebouncedInner(system)
    }

    function objectWithUpperCaseKeys(object) {
      var newObject = {}
      for (var key in object) {
        newObject[upperCaseSafe(key)] = newObject[key]
      }
      return newObject
    }

    function indexOfCaseInsensitive(valuesArray, value) {
      var valuesArrayUpperCase = valuesArray.map((v) => upperCaseSafe(v))
      return valuesArrayUpperCase.indexOf(upperCaseSafe(value))
    }

    // @TODO: Replace lookup table by using new specs in component database
    // Codes must be uppercase here
    var codeToInverterType = {
      'SBS2.5-1VL-10': 'BATTERY',
      'SBS3.7-10': 'BATTERY',
      'SBS5.0-10': 'BATTERY',
      'SBS6.0-10': 'BATTERY',
      GOODWE: 'BATTERY',
      'GW3600S-BP': 'BATTERY',
      'GW5000S-BP': 'BATTERY',
      SPMC481: 'BATTERY',
      SPMC482: 'BATTERY',
      'SPMC481-AU': 'BATTERY',
      'SPMC482-AU': 'BATTERY',

      'SH5K-20': 'HYBRID',
      'SH5K-30': 'HYBRID',
      SH5K: 'HYBRID',
      'SH5K+': 'HYBRID',
      SH3K6: 'HYBRID',
      SH4K6: 'HYBRID',
      // 'SK-SU5000E': 'HYBRID',
      // 'SK-SU3700E': 'HYBRID',
      // 'SK-SU3000E': 'HYBRID',
      // 'SK-TL5000E': 'HYBRID',
      // 'SK-TL3700E': 'HYBRID',
      // 'SK-TL3000E': 'HYBRID',
      // 'INGECON SUN STORAGE 3TL': 'HYBRID',
      // 'GW3648D-ES': 'HYBRID',
      'GW5048D-ES': 'HYBRID',
      'GW3048-EM': 'HYBRID',
      // 'GW3648-EM': 'HYBRID',
      'GW5048-EM': 'HYBRID',
      'RHI-3K-48ES': 'HYBRID',
      'RHI-3.6K-48ES': 'HYBRID',
      'RHI-4.6K-48ES': 'HYBRID',
      'RHI-5K-48ES': 'HYBRID',
      // 'RHI-6K-48ES': 'HYBRID',
      'RHI-3.6K-48ES-5G': 'HYBRID',
      'RHI-4.6K-48ES-5G': 'HYBRID',
      'RHI-5K-48ES-5G': 'HYBRID',
      'RHI-6K-48ES-5G': 'HYBRID',
      'SE3000-AUS2': 'HYBRID',
      'SE5000-AUS2': 'HYBRID',
      'SE6000-AUS2': 'HYBRID',
      'SE3000-AUS20NNB2': 'HYBRID',
      'SE5000-AUS20NNB2': 'HYBRID',
      'SE6000-AUS20NNB2': 'HYBRID',
      'SE3000-AUS20NNB2 (HYBRID)': 'HYBRID',
      'SE5000-AUS20NNB2 (HYBRID)': 'HYBRID',
      'SE6000-AUS20NNB2 (HYBRID)': 'HYBRID',
      SE2500H: 'HYBRID',
      SE3000H: 'HYBRID',
      SE4000H: 'HYBRID',
      SE5000H: 'HYBRID',
      SE6000H: 'HYBRID',
      SE8000H: 'HYBRID',
      SE10000H: 'HYBRID',
      'SE2000H-AU': 'HYBRID',
      'SE2500H-AU': 'HYBRID',
      'SE3000H-AU': 'HYBRID',
      'SE4000H-AU': 'HYBRID',
      'SE5000H-AU': 'HYBRID',
      'SE6000H-AU': 'HYBRID',
      'SE8000H-AU': 'HYBRID',
      'SE10000H-AU': 'HYBRID',
      'ENERGY HUB SE3000H-AUS3MBX14': 'HYBRID',
      'ENERGY HUB SE5000H-AUS3MBX14': 'HYBRID',
      'ENERGY HUB SE6000H-AUS3MBX14': 'HYBRID',
      'ENERGY HUB SE8250H-AUS3MBX14': 'HYBRID',
      'ENERGY HUB SE10000H-AUS3MBX14': 'HYBRID',
      'GEH5.0-1U-10': 'HYBRID',
      'GEH8.6-1U-10': 'HYBRID',
      'GEH10-1U-10': 'HYBRID',
      'SUN2000L-2KTL': 'HYBRID',
      'SUN2000L-3KTL': 'HYBRID',
      'SUN2000L-3.68KTL': 'HYBRID',
      'SUN2000L-4KTL': 'HYBRID',
      'SUN2000L-4.6KTL': 'HYBRID',
      'SUN2000L-5KTL': 'HYBRID',
      'SYMO HYBRID 3.0-3-S': 'HYBRID',
      'SYMO HYBRID 4.0-3-S': 'HYBRID',
      'SYMO HYBRID 5.0-3-S': 'HYBRID',
      'RHI-1P5K-HVES-5G': 'HYBRID',
      'RHI-1P6K-HVES-5G': 'HYBRID',
      'RHI-1P7K-HVES-5G': 'HYBRID',
      'RHI-1P7.6K-HVES-5G': 'HYBRID',
      'RHI-1P8K-HVES-5G': 'HYBRID',
      'RHI-1P9K-HVES-5G': 'HYBRID',
      'RHI-1P10K-HVES-5G': 'HYBRID',
      'SH5.0RT': 'HYBRID',
      'SH6.0RT': 'HYBRID',
      'SH8.0RT': 'HYBRID',
      SH10RT: 'HYBRID',
      'LGES-5048': 'HYBRID',
    }

    // Only LG Energy Solution batteries included for now
    // @TODO: Replace lookup table by using new specs in component database
    var batteryCodeToType = {
      'LG CHEM RESU 6.5': 'DC',
      'LG CHEM RESU 10': 'DC',
      'LG CHEM RESU 13': 'DC',
      'LG CHEM RESU 7H': 'DC',
      'LG CHEM RESU 10H': 'DC',
      'LG CHEM RESU 10H (TYPE C)': 'DC',
      'LG ENERGY SOLUTION RESU6.5 LV': 'DC',
      'LG ENERGY SOLUTION RESU10 LV': 'DC',
      'LG ENERGY SOLUTION RESU12 LV': 'DC',
      'LG ENERGY SOLUTION RESU13 LV': 'DC',
      'LG ENERGY SOLUTION RESU7 HV-TYPE R': 'DC',
      'LG ENERGY SOLUTION RESU10 HV-TYPE R': 'DC',
      'LG ENERGY SOLUTION RESU10 HV-TYPE C': 'DC',
      'LG ENERGY SOLUTION RESU10H PRIME': 'DC',
      'LG ENERGY SOLUTION RESU16H PRIME': 'DC',
    }

    var activationCodes = {
      lowVoltage: [
        'LG Chem RESU 6.5',
        'LG Chem RESU 10',
        'LG Chem RESU 13',
        'LG Energy Solution RESU6.5 LV',
        'LG Energy Solution RESU10 LV',
        'LG Energy Solution RESU12 LV',
        'LG Energy Solution RESU13 LV',
      ],
      highVoltage: [
        'LG Chem RESU 7H',
        'LG Chem RESU 10H',
        'LG Chem RESU 10H (Type C)',
        'LG Energy Solution RESU7 HV-Type R',
        'LG Energy Solution RESU10 HV-Type R',
        'LG Energy Solution RESU10 HV-Type C',
        'LG Energy Solution RESU10H PRIME',
        'LG Energy Solution RESU16H PRIME',
      ],
      inverter: ['LGES-5048'],
    }

    activationCodes.all = [...activationCodes.lowVoltage, ...activationCodes.highVoltage, ...activationCodes.inverter]

    var capabilityContent = {
      BlackoutProtection: {
        key: 'BlackoutProtection',
        code: 'Blackout Protection',
        title: 'Blackout Protection',
        description: 'Blackout protection means that your system will power back-up load circuits with battery power.',
        manufacturer_name: null,
      },
      Islanding: {
        key: 'Islanding',
        code: 'Islanding',
        title: 'Islanding',
        description:
          'Islanding means that your solar keeps working during a blackout, powering backed-up loads in combination with battery, or charging the battery.',
        manufacturer_name: null,
      },
      DarkStart: {
        key: 'DarkStart',
        code: 'Dark Start',
        title: 'Dark Start',
        description:
          'Dark start means that your system will start in islanding mode during a blackout, using solar power to power loads and re-charge the battery.',
        manufacturer_name: null,
      },
      AutomaticOverloadRecovery: {
        key: 'AutomaticOverloadRecovery',
        code: 'Automatic Overload Recovery',
        title: 'Automatic Overload Recovery',
        description:
          'Automatic Overload Recovery means that if the load exceeds that of the solar in a blackout scenario, then the inverter can reset itself after being tripped.',
        manufacturer_name: null,
      },
    }

    // Keys are the combination of components which are present in the system, joined by double underscores
    // Order that each component is added to build the key is essential.
    var inverterCompatabilityMessages = {
      pv_inverter__panels: null,
      pv_inverter__panels__dc_battery: 'No inverter for the battery, please add a battery/hybrid inverter.',
      pv_inverter__panels__ac_battery: null,
      pv_inverter__dc_battery:
        'Click on the + to add panels for the PV inverter. Please change PV inverter to Hybrid or add a battery inverter.',
      pv_inverter__ac_battery: 'Click on the + to add panels for the PV inverter.',

      battery_inverter__panels:
        'You have a PV system but no PV inverter. You have selected a battery inverter but there is no battery',
      battery_inverter__panels__dc_battery: 'You have a PV system but no PV inverter.',
      battery_inverter__panels__ac_battery:
        'You have a PV system but no PV inverter. You have a battery with an integrated inverter so there is no need for a battery inverter.',
      battery_inverter__dc_battery: null,
      battery_inverter__ac_battery:
        'You have a battery with an integrated inverter so there is no need for a battery inverter.',

      // This will never show until we can detect hybrid inverters
      hybrid_inverter__panels: null,
      hybrid_inverter__panels__dc_battery: null,
      hybrid_inverter__panels__ac_battery: null,
      hybrid_inverter__dc_battery: null,
      hybrid_inverter__ac_battery:
        'You have a battery with an integrated inverter so there is no need for a hybrid inverter.',

      pv_inverter__battery_inverter__panels: 'You have selected a battery inverter but there is no battery.',
      pv_inverter__battery_inverter__panels__dc_battery: null,
      pv_inverter__battery_inverter__panels__ac_battery:
        'You have a battery with an integrated inverter so there is no need for a battery inverter.',
      pv_inverter__battery_inverter__dc_battery: 'Click on the + to add panels for the PV inverter.',
      pv_inverter__battery_inverter__ac_battery:
        'Click on the + to add panels for the PV inverter. You have a battery with an integrated inverter so there is no need for a battery inverter. ',

      // This will never show until we can detect hybrid inverters
      pv_inverter__hybrid_inverter__panels: null,
      pv_inverter__hybrid_inverter__panels__dc_battery: null,
      pv_inverter__hybrid_inverter__panels__ac_battery: null,
      pv_inverter__hybrid_inverter__dc_battery: 'Click on the + to add panels for the PV inverter.',
      pv_inverter__hybrid_inverter__ac_battery: 'Click on the + to add panels for the PV inverter.',

      // This will never show until we can detect hybrid inverters
      pv_inverter__hybrid_inverter__battery_inverter__panels:
        'You have selected a battery inverter but there is no battery.',
      pv_inverter__hybrid_inverter__battery_inverter__panels__dc_battery: null,
      pv_inverter__hybrid_inverter__battery_inverter__panels__ac_battery:
        'You have a battery with an integrated inverter so there is no need for a battery inverter.',
      pv_inverter__hybrid_inverter__battery_inverter__dc_battery: 'Click on the + to add panels for the PV inverter.',
      pv_inverter__hybrid_inverter__battery_inverter__ac_battery:
        'Click on the + to add panels for the PV inverter. You have a battery with an integrated inverter so there is no need for a battery inverter.',

      // This will never show until we can detect hybrid inverters
      hybrid_inverter__battery_inverter__panels: 'You have selected a battery inverter but there is no battery.',
      hybrid_inverter__battery_inverter__panels__dc_battery: null,
      hybrid_inverter__battery_inverter__panels__ac_battery:
        'You have a battery with an integrated inverter so there is no need for a battery inverter.',
      hybrid_inverter__battery_inverter__dc_battery: 'Click on the + to add panels for the hybrid inverter.',
      hybrid_inverter__battery_inverter__ac_battery:
        'Click on the + to add panels for the PV inverter. You have a battery with an integrated inverter so there is no need for a battery inverter.',

      // Scenarios with no inverter
      // Do not show these messages because we will see a more detailed message
      // when the battery is missing an expected inverter
      // panels: 'No inverter selected.',
      // panels__dc_battery: 'No inverter selected.',
      // panels__ac_battery: 'No inverter selected.',
      // dc_battery: 'No inverter selected.',
      // ac_battery: 'No inverter selected.',
    }

    // Logic for LGES inverter warning:
    // Warning message should show when the LGES-5048 inverter is added, but there is no LGES Low voltage battery

    function updateWarningsInverter(system, isActive) {
      var message

      if (system.inverters().length > 0 && isActive) {
        system.inverters().forEach(function (inverter) {
          if (upperCaseSafe(inverter.code) === 'LGES-5048') {
            if (!systemHasComponent(system, activationCodes.lowVoltage)) {
              message =
                'LGES-5048 Inverter only to be installed with compatible battery from the following list: LG Energy Solution RESU6.5 LV, LG Energy Solution RESU10 LV, LG Energy Solution RESU12 LV, LG Energy Solution RESU13 LV.'
            }
          }
        })
      }
      var messageKey = 'LGES-HYBRID-INVERTER-BATTERY-CHECK'
      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function updateWarningsInverterCompatibility(system, isActive) {
      var message

      if (isActive) {
        var inverterCompatibiltyKeyParts = []

        var componentCodes = system.componentCodes()

        // @TODO: Improve this. The current logic assumes any inverter not on the above list is a PV Inverter
        // but it could still be a non-PV inverter that we have not included in the list.
        if (
          system
            .inverters()
            .map((i) => i.code)
            .some((code) => !codeToInverterType[upperCaseSafe(code)])
        ) {
          inverterCompatibiltyKeyParts.push('pv_inverter')
        }

        if (componentCodes.some((code) => codeToInverterType[upperCaseSafe(code)] === 'HYBRID')) {
          inverterCompatibiltyKeyParts.push('hybrid_inverter')
        }

        // Beware: We currently store battery inverters in other components, not inverters
        if (componentCodes.some((code) => codeToInverterType[upperCaseSafe(code)] === 'BATTERY')) {
          inverterCompatibiltyKeyParts.push('battery_inverter')
        }

        if (system.moduleQuantity() > 0) {
          inverterCompatibiltyKeyParts.push('panels')
        }

        if (componentCodes.some((code) => batteryCodeToType[upperCaseSafe(code)] === 'DC')) {
          inverterCompatibiltyKeyParts.push('dc_battery')
        }
        if (componentCodes.some((code) => batteryCodeToType[upperCaseSafe(code)] === 'AC')) {
          inverterCompatibiltyKeyParts.push('ac_battery')
        }

        var inverterCompatibiltyKey = inverterCompatibiltyKeyParts.join('__')

        // If key is found with value not empty, we are showing a message
        // otherwise clear any message
        message = inverterCompatabilityMessages[inverterCompatibiltyKey]
      }

      var messageKey = 'LGES-INVERTER-COMPATIBILITY'

      return setSystemMessage(system, message, messageKey, 'error')
    }

    function updateWarningsLGChem(system, isActive) {
      var message
      if (system.batteries().length > 0 && isActive) {
        system.batteries().forEach(function (battery) {
          if (upperCaseSafe(battery.code).indexOf('LG CHEM') !== -1) {
            message =
              'LG Chem is now known as LG Energy Solution. Please find the updated battery components by looking up "LG Energy Solution" in our battery database.'
          }
        })
      }
      var messageKey = 'LEGACY-LGCHEM-BATTERY-CODE'
      return setSystemMessage(system, message, messageKey, 'info')
    }

    function updateWarningsBattery(system, isActive) {
      var message

      if (system.batteries().length > 0 && isActive) {
        // Only check first battery
        var batteryCode = system.batteries()[0].code

        // @TODO: Update codes to match component database
        var batteryCombinations = [
          {
            batteries: activationCodes.lowVoltage,

            inverters: [
              // Hybrid inverters
              'SH5K-20',
              'SH5K-30',
              'SH5K',
              'SH5K+',
              'SH3K6',
              'SH4K6',

              // 'SK-SU5000E',
              // 'SK-SU3700E',
              // 'SK-SU3000E',
              // 'SK-TL5000E',
              // 'SK-TL3700E',
              // 'SK-TL3000E',
              // 'INGECON SUN STORAGE 3TL',
              'GW3048D-ES', // Not yet in component db
              // 'GW3648D-ES',
              'GW5048D-ES',
              'GW3048-EM',
              // 'GW3648-EM',
              'GW5048-EM',

              'RHI-3K-48ES',
              'RHI-3.6K-48ES',
              'RHI-4.6K-48ES',
              'RHI-5K-48ES',
              'RHI-3.6K-48ES-5G',
              'RHI-4.6K-48ES-5G',
              'RHI-5K-48ES-5G',
              'RHI-6K-48ES-5G',
              // 'RHI-6K-48ES',
              'LGES-5048',

              // Battery inverters
              'GW3600S-BP', // Not yet in component db
              'GW5000S-BP', // Not yet in component db
              'SPMC481', // Not yet in component db
              'SPMC482', // Not yet in component db
              'SPMC481-AU',
              'SPMC482-AU',
            ],
          },
          {
            batteries: [
              'LG Chem RESU 7H',
              'LG Chem RESU 10H',
              'LG Energy Solution RESU7 HV-Type R',
              'LG Energy Solution RESU10 HV-Type R',
            ],

            inverters: [
              // Hybrid inverters
              'SE3000-AUS2',
              'SE5000-AUS2',
              'SE6000-AUS2',
              'SE3000-AUS20NNB2',
              'SE5000-AUS20NNB2',
              'SE6000-AUS20NNB2',
              'SE3000-AUS20NNB2 (Hybrid)',
              'SE5000-AUS20NNB2 (Hybrid)',
              'SE6000-AUS20NNB2 (Hybrid)',

              'SE2000H', // Also requires SESTI-S4, Not yet in component db
              'SE2500H', // Also requires SESTI-S4
              'SE3000H', // Also requires SESTI-S4
              'SE4000H', // Also requires SESTI-S4
              'SE5000H', // Also requires SESTI-S4
              'SE6000H', // Also requires SESTI-S4
              'SE8000H', // Also requires SESTI-S4
              'SE10000H', // Also requires SESTI-S4
              'SE2000H-AU', // Also requires SESTI-S4, Not yet in component db
              'SE2500H-AU', // Also requires SESTI-S4
              'SE3000H-AU', // Also requires SESTI-S4
              'SE4000H-AU', // Also requires SESTI-S4
              'SE5000H-AU', // Also requires SESTI-S4
              'SE6000H-AU', // Also requires SESTI-S4
              'SE8000H-AU', // Also requires SESTI-S4
              'SE10000H-AU', // Also requires SESTI-S4
              'SUN2000L-2KTL',
              'SUN2000L-3KTL',
              'SUN2000L-3.68KTL',
              'SUN2000L-4KTL',
              'SUN2000L-4.6KTL',
              'SUN2000L-5KTL',
              'Symo Hybrid 3.0-3-S',
              'Symo Hybrid 4.0-3-S',
              'Symo Hybrid 5.0-3-S',
              'RHI-1P5K-HVES-5G',
              'RHI-1P6K-HVES-5G',
              'RHI-1P7K-HVES-5G',
              'RHI-1P7.6K-HVES-5G',
              'RHI-1P8K-HVES-5G',
              'RHI-1P9K-HVES-5G',
              'RHI-1P10K-HVES-5G',
              'SH5.0RT',
              'SH6.0RT',
              'SH8.0RT',
              'SH10RT',

              // Battery inverters
              // none
            ],
          },
          {
            batteries: ['LG Chem RESU 10H (Type C)', 'LG Energy Solution RESU10 HV-Type C'],

            inverters: [
              // Hybrid inverters
              // none

              // Battery inverters
              'SBS2.5-1VL-10', // Not yet in component db
              'SBS3.7-10', // Not yet in component db
              'SBS5.0-10', // Not yet in component db
              'SBS6.0-10', // Not yet in component db
            ],
          },
          {
            batteries: ['LG Energy Solution RESU10H PRIME', 'LG Energy Solution RESU16H PRIME'],

            inverters: [
              'SE3000H', // Also requires SESTI-S4
              'SE4000H', // Also requires SESTI-S4
              'SE5000H', // Also requires SESTI-S4
              'SE6000H', // Also requires SESTI-S4
              'SE8000H', // Also requires SESTI-S4
              'SE10000H', // Also requires SESTI-S4
              'SE3000H-AU', // Also requires SESTI-S4
              'SE4000H-AU', // Also requires SESTI-S4
              'SE5000H-AU', // Also requires SESTI-S4
              'SE6000H-AU', // Also requires SESTI-S4
              'SE8000H-AU', // Also requires SESTI-S4
              'SE10000H-AU', // Also requires SESTI-S4
              'Energy Hub SE3000H-AUS3MBX14',
              'Energy Hub SE5000H-AUS3MBX14',
              'Energy Hub SE6000H-AUS3MBX14',
              'Energy Hub SE8250H-AUS3MBX14',
              'Energy Hub SE10000H-AUS3MBX14',
              'SBS5.0-10',
              'GEH5.0-1U-10',
              'GEH8.6-1U-10',
              'GEH10-1U-10',
            ],
          },
        ]

        var compatibleInverterCodes = null

        batteryCombinations.forEach((batteryCombination) => {
          if (indexOfCaseInsensitive(batteryCombination.batteries, batteryCode) !== -1) {
            compatibleInverterCodes = batteryCombination.inverters
          }
        })

        if (!compatibleInverterCodes) {
          // Note: To ensure we cleanup any old messages when removing/changing a battery, we must ensure
          // this resets the message, instead of returning early
          // We should never reach here because we will immediatly set message=null if no LG Energy Solution batteries were found
          if (window.studioDebug) {
            console.log('Warning: Compatible inverters not found. Exit')
          }
        } else {
          var hasFoundCompatibleInverter = false

          // Beware, we look for inverters across all system components because currently they are stored in
          // either inverters or other components, and this may change in future too, if battery inverters
          // move from other components to inverters.
          var componentCodes = system.componentCodes()
          componentCodes.forEach((componentCode) => {
            compatibleInverterCodes.forEach((compatibleInverterCode) => {
              if (upperCaseSafe(componentCode) === upperCaseSafe(compatibleInverterCode)) {
                hasFoundCompatibleInverter = true
              }
            })
          })

          if (!hasFoundCompatibleInverter) {
            message =
              'Compatible Inverter not found for battery (' +
              batteryCode +
              '). \\n\\nExpected one of: ' +
              compatibleInverterCodes.join(', ') +
              '.'
          } else {
            message = null
          }
        }
      } else {
        // No LG Energy Solution batteries, no error messages
        message = null
      }

      var messageKey = 'LGES-BATTERY-REQUIRES-COMPATIBLE-INVERTER'

      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function updateWarningsOtherComponents(system, isActive) {
      var message

      if (isActive) {
        // SH5K-20 requires OtherComponent STB5K
        // SE2000H-SE10000H require OtherComponent SESTI-S4
        // Various LG Battery combinations required RESU Plus Expansion Pack
        var messageParts = []

        if (system.batteries().length > 0) {
          var dependencies = [
            {
              codes: ['SH5K-20'],
              requires: ['STB5K'],
              message: 'SH5K-20 requires STB5K to be included.',
            },
            {
              codes: [
                'SE2000H',
                'SE2500H',
                'SE3000H',
                'SE4000H',
                'SE5000H',
                'SE6000H',
                'SE8000H',
                'SE10000H',
                'SE2000H-AU',
                'SE2500H-AU',
                'SE3000H-AU',
                'SE4000H-AU',
                'SE5000H-AU',
                'SE6000H-AU',
                'SE8000H-AU',
                'SE10000H-AU',
                'Energy Hub SE3000H-AUS3MBX14',
                'Energy Hub SE5000H-AUS3MBX14',
                'Energy Hub SE6000H-AUS3MBX14',
                'Energy Hub SE8250H-AUS3MBX14',
                'Energy Hub SE10000H-AUS3MBX14',
              ],
              requires: ['SESTI-S4'],
              message: 'SE2000H-SE10000H also require SESTI-S4 to be included.',
            },
          ]

          dependencies.forEach((dependencyRule) => {
            var ruleApplies = system
              .inverters()
              .some((inverter) => indexOfCaseInsensitive(dependencyRule.codes, inverter.code) !== -1)
            var showMessagePart =
              ruleApplies &&
              !system.others().some((other) => indexOfCaseInsensitive(dependencyRule.requires, other.code) !== -1)
            if (showMessagePart) {
              messageParts.push(dependencyRule.message)
            }
          })

          // Extra logic for battery combinations
          // If the system has multiple low-voltage "LV" batteries (2x LG Energy Solution RESU6.5 LV) OR (2xLG Energy Solution RESU10 LV) OR (2xLG Energy Solution RESU13 LV) OR (LG Energy Solution RESU6.5 LV AND LG Energy Solution RESU10 LV) THEN expect RESU Plus Expansion Pack
          // Does not apply to high-voltage "H" batteries.
          if (system.batteries().length >= 2 && countComponentsForSystem(system, activationCodes.lowVoltage) >= 2) {
            var expansionPackCodes = ['13011', 'LG RESU Plus']
            if (!system.others().some((other) => indexOfCaseInsensitive(expansionPackCodes, other.code) !== -1)) {
              messageParts.push(
                'LG Energy Solution Battery combinations require: RESU Plus Expansion Pack (Code: 13011 or LG RESU Plus)'
              )
            }
          }

          // Extra logic for 2 x LG Energy Solution RESU10 HV-Type R which may require conversion to RESU 10H (Type R) Secondary pack."
          if (
            (system.batteries().filter((b) => upperCaseSafe(b.code) === 'LG ENERGY SOLUTION RESU10 HV-TYPE R').length ==
              2 ||
              system.batteries().filter((b) => upperCaseSafe(b.code) === 'LG CHEM RESU 10H').length == 2) &&
            systemHasComponent(system, [
              'SE3000-AUS2',
              'SE5000-AUS2',
              'SE6000-AUS2',
              'SE3000-AUS20NNB2',
              'SE5000-AUS20NNB2',
              'SE6000-AUS20NNB2',
              'SE3000-AUS20NNB2 (Hybrid)',
              'SE5000-AUS20NNB2 (Hybrid)',
              'SE6000-AUS20NNB2 (Hybrid)',
            ])
          ) {
            messageParts.push(
              'If the second RESU10 HV-Type R battery is connected to the same SE3000/5000/6000 then it should be changed to a RESU10 HV-Type R (Secondary pack).'
            )
          }
        }

        message = messageParts.join(' ')
      }

      var messageKey = 'LGES-OTHER-COMPONENTS-REQUIRED'

      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function updateWarningsOversizing(system, isActive) {
      var message

      if (isActive) {
        var showMessage = false

        var componentCodes = system.componentCodes()

        if (
          systemHasComponent(system, activationCodes.lowVoltage) &&
          systemHasComponent(system, ['GW5048D-ES', 'GW5048-EM', 'LGES-5048'])
        ) {
          showMessage = true
        } else if (
          systemHasComponent(system, ['LG Chem RESU 10H', 'LG Energy Solution RESU10 HV-Type R']) &&
          // Plus SE5000*/SE6000*
          systemHasComponent(system, [
            'SE3000-AUS2',
            'SE5000-AUS2',
            'SE6000-AUS2',
            'SE3000-AUS20NNB2',
            'SE5000-AUS20NNB2',
            'SE6000-AUS20NNB2',
            'SE3000-AUS20NNB2 (Hybrid)',
            'SE5000-AUS20NNB2 (Hybrid)',
            'SE6000-AUS20NNB2 (Hybrid)',
          ])
        ) {
          showMessage = true
        } else if (
          systemHasComponent(system, ['LG Energy Solution RESU10H PRIME', 'LG Energy Solution RESU16H PRIME']) &&
          systemHasComponent(system, [
            'Energy Hub SE3000H-AUS3MBX14',
            'Energy Hub SE5000H-AUS3MBX14',
            'Energy Hub SE6000H-AUS3MBX14',
            'Energy Hub SE8250H-AUS3MBX14',
            'Energy Hub SE10000H-AUS3MBX14',
          ]) &&
          systemHasComponent(system, ['SESTI-S4'])
        ) {
          showMessage = true
        }
        message = showMessage
          ? 'This system is capable of PV oversizing. To model this please ensure the SAM calculator is enabled, and the DC-connected option is selected in the battery section.'
          : null
      }

      var messageKey = 'LGES-PV-OVERSIZING-MESSAGE'

      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function setSystemMessage(system, message, messageKey, messageType, category) {
      const severity = messageType || 'warning'
      if (message) {
        window.WorkspaceHelper?.addProjectErrorToReduxStore({
          message,
          key: messageKey,
          severity,
          systemId: system.uuid,
          source: 'plugin',
          category: 'system',
        })
      } else {
        window.WorkspaceHelper?.removeProjectErrorFromReduxStore(messageKey, system.uuid, 'plugin')
      }
    }

    function systemHasComponent(system, codes) {
      return countComponentsForSystem(system, codes) > 0
    }

    function countComponentsForSystem(system, codes) {
      return system.componentCodes().filter((code) => indexOfCaseInsensitive(codes, code) !== -1).length
    }

    function getComponentForCode(code) {
      var codeUpperCase = upperCaseSafe(code)
      return window.AccountHelper.getComponentOtherSpecsAvailable().filter(
        (component) => upperCaseSafe(component.code) === codeUpperCase
      )[0]
    }

    function manageOtherComponents(system) {
      // If system has RESU6.5 LV OR RESU10 LV OR RESU13 LV

      var capabilities = {
        BlackoutProtection: false,
        Islanding: false,
        DarkStart: false,
        AutomaticOverloadRecovery: false,
      }

      if (systemHasComponent(system, activationCodes.lowVoltage)) {
        if (
          systemHasComponent(system, [
            'SH5K-20',
            'SH5K-30',
            'SH5K',
            'SH5K+',
            'SH3K6',
            'SH4K6',
            // 'SK-SU5000E',
            // 'SK-SU3700E',
            // 'SK-SU3000E',
            // 'SK-TL5000E',
            // 'SK-TL3700E',
            // 'SK-TL3000E',
            // 'GW3648D-ES',
            'GW5048D-ES',
            'GW3048-EM',
            // 'GW3648-EM',
            'GW5048-EM',
            'GW3600S-BP',
            'GW5000S-BP',
            'LGES-5048',
            'SH5.0RT',
            'SH6.0RT',
            'SH8.0RT',
            'SH10RT',
          ])
        ) {
          capabilities.BlackoutProtection = true
          capabilities.Islanding = true
          capabilities.DarkStart = true
          capabilities.AutomaticOverloadRecovery = true
        } else if (systemHasComponent(system, ['SPMC481', 'SPMC482', 'SPMC481-AU', 'SPMC482-AU'])) {
          capabilities.BlackoutProtection = true
          capabilities.Islanding = true
          capabilities.DarkStart = true
          capabilities.AutomaticOverloadRecovery = true
        } else if (
          systemHasComponent(system, [
            'RHI-3K-48ES',
            'RHI-3.6K-48ES',
            'RHI-4.6K-48ES',
            'RHI-5K-48ES',
            'RHI-3.6K-48ES-5G',
            'RHI-4.6K-48ES-5G',
            'RHI-5K-48ES-5G',
            'RHI-6K-48ES-5G',
          ])
        ) {
          capabilities.BlackoutProtection = true
          capabilities.Islanding = true
          capabilities.DarkStart = true
          capabilities.AutomaticOverloadRecovery = false
        }
      } else if (systemHasComponent(system, ['LG Chem RESU 10H (Type C)', 'LG Energy Solution RESU10 HV-Type C'])) {
        if (systemHasComponent(system, ['SBS3.7-10', 'SBS5.0-10', 'SBS6.0-10'])) {
          if (systemHasComponent(system, ['SBS-ABU-63.1-AU-10'])) {
            capabilities.BlackoutProtection = true
            capabilities.Islanding = true
            capabilities.DarkStart = true
          } else {
            capabilities.BlackoutProtection = true
          }
        }
      } else if (systemHasComponent(system, ['LG Chem Resu 10H', 'LG Energy Solution RESU10 HV-Type R'])) {
        if (
          systemHasComponent(system, [
            'SE3000-AUS2',
            'SE5000-AUS2',
            'SE6000-AUS2',
            'SE3000-AUS20NNB2',
            'SE5000-AUS20NNB2',
            'SE6000-AUS20NNB2',
            'SE3000-AUS20NNB2 (Hybrid)',
            'SE5000-AUS20NNB2 (Hybrid)',
            'SE6000-AUS20NNB2 (Hybrid)',
          ])
        ) {
          capabilities.BlackoutProtection = true
          capabilities.Islanding = true
          capabilities.DarkStart = true
          capabilities.AutomaticOverloadRecovery = true
        } else if (
          systemHasComponent(system, [
            'RHI-1P5K-HVES-5G',
            'RHI-1P6K-HVES-5G',
            'RHI-1P7K-HVES-5G',
            'RHI-1P7.6K-HVES-5G',
            'RHI-1P8K-HVES-5G',
            'RHI-1P9K-HVES-5G',
            'RHI-1P10K-HVES-5G',
          ])
        ) {
          capabilities.BlackoutProtection = true
          capabilities.Islanding = true
          capabilities.DarkStart = true
          capabilities.AutomaticOverloadRecovery = false
        } else if (systemHasComponent(system, ['SH5.0RT', 'SH6.0RT', 'SH8.0RT', 'SH10RT'])) {
          capabilities.BlackoutProtection = true
          capabilities.Islanding = true
          capabilities.DarkStart = true
          capabilities.AutomaticOverloadRecovery = true
        }
      }

      var capabilityToComponentCode = {}
      Object.entries(capabilityContent).forEach(([key, cc]) => {
        capabilityToComponentCode[key] = cc.code
      })

      // Beware: We cannot pre-calculated these capabilities because the objectAdded callback on one component
      // may change the value of which capabilities are enabled on the system recursively
      // Therefore, we must detect these fresh on each iteration.

      for (var key in capabilities) {
        if (capabilities[key] !== systemHasComponent(system, [capabilityToComponentCode[key]])) {
          var componentCode = capabilityToComponentCode[key]

          if (capabilities[key]) {
            var otherComponentType = getComponentForCode(componentCode)

            if (otherComponentType) {
              otherComponent = new window.OsOther({ other_id: otherComponentType.id, quantity: 0 })
            } else {
              // New method: Create dynamically-created component using standard content.
              // This can be overriden by exhibit content, but at least it shows something useful/relevenat in the meantime.
              var otherComponentData = capabilityContent[key]

              otherComponent = new window.OsOther({ ...otherComponentData, quantity: 0 })
            }

            window.editor.execute(new window.AddObjectCommand(otherComponent, system, false))
            Designer.showNotification('Battery capability added: ' + componentCode)
          } else {
            system.others().forEach((other) => {
              if (other.code === capabilityToComponentCode[key]) {
                window.editor.execute(new window.RemoveObjectCommand(other))
                Designer.showNotification('Battery Capability Removed: ' + componentCode)
              }
            })
          }
        }
      }
    }

    function updateWarningsRecommendTimeOfUse(system, isActive) {
      var message

      if (isActive) {
        var messageParts = []

        var proposed = system.bills && system.bills.proposed ? Object.values(system.bills.proposed)[0] : null

        if (proposed) {
          if (!proposed.usage_is_tou) {
            messageParts.push(
              'Consider switching the proposed tariff to a time-of-use tariff because battery systems generally achieve optimal savings with time-of-use tariffs.'
            )
          }
        }

        message = messageParts.join(' ')
      }

      var messageKey = 'LGES-RECOMMEND-TOU-TARIFF'

      return setSystemMessage(system, message, messageKey, 'warning')
    }

    function refresh(system) {
      if (window.WorkspaceHelper.getIsProjectLite()) {
        // Don't run on Lite projects
        return
      }

      if (system.isDuplicating) {
        // skip refresh during duplication because we do not want to trigger signals
        // for each child added to the system
        return
      }
      manageOtherComponents(system)

      if (system) {
        // If no LG Energy Solution batteries are applied then forcefull clear system messages
        // This is necessary because a system may be refreshed on pluginUnload which no longer has any LG Energy Solution
        // batteries
        var isActive = systemHasComponent(system, activationCodes.all)

        updateWarningsBattery(system, isActive)
        updateWarningsOtherComponents(system, isActive)
        updateWarningsInverterCompatibility(system, isActive)
        updateWarningsRecommendTimeOfUse(system, isActive)
        updateWarningsOversizing(system, isActive)
        updateWarningsLGChem(system, isActive)
        updateWarningsInverter(system, isActive)
      }
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refreshDebounced(system)
        }
      }
    }

    function objectAdded(object) {
      if (window.studioDebug) {
        console.log('LGES objectAdded', object)
      }
      refreshSystemFromObject(object)
    }

    function objectChanged(object) {
      if (window.studioDebug) {
        console.log('LGES objectChanged', object)
      }
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (window.studioDebug) {
        console.log('LGES objectRemoved', object)
      }
      if (object?.type === 'OsSystem') {
        const filter = (error) => error.systemId === object.uuid
        window.WorkspaceHelper?.clearProjectError(filter)
      } else {
        refreshSystemFromObject(object)
      }
    }

    function sceneGraphChanged(system) {
      if (window.studioDebug) {
        console.log('LGES sceneGraphChanged')
      }
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      if (window.studioDebug) {
        console.log('LGES pluginLoaded')
      }
      refreshAllSystems()
    }

    function pluginUnloaded() {
      if (window.studioDebug) {
        console.log('LGES pluginUnloaded')
      }
      refreshAllSystems()
    }

    return {
      objectAdded,
      objectChanged,
      objectRemoved,
      sceneGraphChanged,
      liteProjectUpgraded,
      pluginLoaded,
      pluginUnloaded,
    }
  },
}
