/**
 * @author adampryor
 */

var OsTreeCache = {}

var treeTypes = [
  // Removing these for now since we do not have models for them.
  {
    type: 'pine_short',
    file_name_base: 'pine_short_lod2_512.glb',
  },
  {
    type: 'pine_tall',
    file_name_base: 'pine_tall_lod2_512.glb',
  },
  {
    type: 'small_tree',
    file_name_base: 'small_tree_lod2_512.glb',
  },
  {
    type: 'palm_short',
    file_name_base: 'palm_short_lod2_512.glb',
  },
  {
    type: 'palm_tall',
    file_name_base: 'palm_tall_lod2_512.glb',
  },
  {
    type: 'medium_tree',
    file_name_base: 'medium_tree_lod2_512.glb',
  },
  {
    type: 'oak',
    file_name_base: 'oak_lod2_512.glb',
  },
  {
    type: 'elm',
    file_name_base: 'elm_lod2_512.glb',
  },
  {
    type: 'bush',
    file_name_base: 'bush_lod2_512.glb',
  },
  // Keep old cone tree model for legacy projects
  {
    type: 'cone',
    file_name_base: '10447_Pine_Tree_v1_L3b',
  },
]

function getDataForTreeType(treeType) {
  var treeTypeData = treeTypes.filter(function (data) {
    return data.type === treeType
  })[0]

  if (treeTypeData) {
    return treeTypeData
  } else {
    return treeTypes.filter(function (data) {
      // Fallback to default tree type "cone" to ensure that projects that had their tree type set as something
      // other than cone do not break.
      return data.type === 'cone'
    })[0]
  }
}

function OsTree(options) {
  THREE.Mesh.call(this)

  if (!OsTreeCache.material) {
    OsTreeCache.material = new THREE.MeshStandardMaterial({
      color: 0xccffcc,
      opacity: 0.5,
      transparent: true,
      visible: false,
    })
    OsTreeCache.materialGhostMode = new THREE.MeshStandardMaterial({
      opacity: 0.3,
      transparent: true,
      visible: true,
    })
  }

  //Start _treeType=null so when we set treeTye(...) it detects a change and loads the tree model
  this._treeType = null
  this.treeType(
    options && options.userData && options.userData.treeType ? options.userData.treeType : treeTypes[0].type
  )

  if (!OsTreeCache.placeholder) {
    var geom = new THREE.CylinderBufferGeometry(1.0, 1.0, 3.0, 12)
    //Stand cyliner up, place base at 0 elevation.
    geom.rotateX(Math.PI / 2)
    geom.translate(0, 0, 1.5)
    geom.excludeFromExport = true
    OsTreeCache.placeholder = geom
  }
  this.geometry = OsTreeCache.placeholder

  this.material = OsTreeCache.material

  //Let the model cast the shadown, not the sphere geometry
  this.castShadow = false

  //export var TrianglesDrawMode = 0;
  //this.drawMode = TrianglesDrawMode;
  this.drawMode = 0

  this.updateMorphTargets()

  this.type = 'OsTree'
  this.name = 'OsTree'

  this._ghostMode = options && options.hasOwnProperty('ghostMode') ? options.ghostMode : false

  this.trunk = null
  this.treeModel = null

  var _this = this
}

OsTree.prototype = Object.assign(Object.create(THREE.Mesh.prototype), {
  constructor: OsTree,
  getName: function () {
    return 'Tree'
  },
  setGeometryForModel: function (treeModel) {
    var bbLocal = Utils.getBoundingBoxLocal(treeModel)
    var modelSize = bbLocal.getSize(new THREE.Vector3())
    var modelCentroid = bbLocal.getCenter(new THREE.Vector3())

    // use the size of the model bounding box as the cache key because that is the only difference between geometry
    var cacheKey = 'cylinder_' + modelSize.toArray().join('_')
    if (!OsTreeCache[cacheKey]) {
      var radius = Math.max(modelSize.x, modelSize.y) / 2

      var geom = new THREE.CylinderBufferGeometry(radius, radius, modelSize.z, 12)

      //Stand cyliner up, place base at 0 elevation.
      geom.rotateX(Math.PI / 2)
      geom.translate(modelCentroid.x, modelCentroid.y, -bbLocal.min.z + modelSize.z / 2)

      geom.excludeFromExport = true

      OsTreeCache[cacheKey] = geom
    }

    this.geometry = OsTreeCache[cacheKey]
  },
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,
  toolsActive: function () {
    return {
      translateXY: true,
      translateZ: true,
      translateX: false,
      rotate: true,
      scaleXY: true,
      scaleZ: true,
      scale: true, //legacy
    }
  },
  transformWithLocalCoordinates: function () {
    return true
  },

  onSelect: function () {
    if (this.children[0] && this.children[0].children[0]) {
      this.children[0].children[0].material.color.r = 1.0
    }
  },

  onDeselect: function () {
    if (this.children[0] && this.children[0].children[0]) {
      this.children[0].children[0].material.color.r = 0.588
    }
  },

  onChange: function (editor) { },
  getGroundHeight: function () {
    return -1 * editor.getGroundElevation()
  },

  applyGhostMode: function (value) {
    if (value) {
      this.material = OsTreeCache.materialGhostMode
    } else {
      this.material = OsTreeCache.material
    }
  },

  handleGhostModeBehavior: ObjectBehaviors.handleGhostModeBehavior,

  treeType: function (value) {
    if (typeof value === 'undefined') {
      return this._treeType
    }

    if (this._treeType != value) {
      this._treeType = value

      this.unloadModel()
      this.loadModel()

      this.refreshUserData()
    }
  },

  getContextMenuItems: function (position) {
    var _this = this

    var menuItems = treeTypes.map(function (treeTypeData) {
      return {
        label: window.translate('Set Tree Type') + ': ' + window.translate(Utils.properCaseWords(treeTypeData.type)),
        onClick: function () {
          editor.execute(new SetObjectTypeCommand(_this, 'treeType', treeTypeData.type))
        },
      }
    })

    menuItems.push({
      label: window.translate('Select Tree'),
      useHTML: false,
      selected: false,
      onClick: function () {
        if (editor) {
          editor.select(_this)
        }
      },
    })

    return menuItems
  },

  applyUserData: function () {
    this.treeType(this.userData.treeType)
    this.override_show_customer =
      typeof this.userData.override_show_customer === 'boolean' ? this.userData.override_show_customer : null
  },

  refreshUserData: function () {
    this.userData.treeType = this.treeType()
    this.userData.override_show_customer =
      typeof this.override_show_customer === 'boolean' ? this.override_show_customer : null
  },

  duplicate: function (options) {
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    var newTree = new OsTree(this)
    newTree.copy(this)
    newTree.position.copy(this.position).add(positionOffset)
    newTree.children = []
    editor.execute(new AddObjectCommand(newTree, null, true))
  },

  unloadModel: function () {
    this.remove(this.treeModel)
    this.treeModel = null
  },
  transparentLeaves(value) {
    /*
    Use to control whether rays can shine through alpha in textures (looks nice visually) or whether the alpha channel
    is disabled (better for shading simulations so the light does not shine through alpha channel in leaf textures)
    */

    if (typeof value === 'undefined') {
      return this._transparentLeaves
    } else if (this._transparentLeaves !== value) {
      this._transparentLeaves = value
    }

    // We always apply this on every call even if the value has not changed beacuse
    // a) this is a quick operation and
    // b) state can be confusing when we load/change tree models so we avoid scenarios where the state of the model
    // itself may get out of sync with the property
    if (this.treeModel) {
      this.treeModel.children.forEach((c) => {
        c.castShadow = true

        if (c.material) {
          if (value === true) {
            c.material.alphaTest = 0.7
            c.material.needsUpdate = true
          } else {
            c.material.alphaTest = 0
            c.material.needsUpdate = true
          }
        }
      })
    }
  },
  loadModel: function () {
    var _this = this

    var resourcePath

    if (window.RUNNING_AS_APP_DEVICE) {
      resourcePath = './build/tree_models/'
    } else if (window.RUNNING_AS_HTML_FOR_PDF_GENERATION) {
      // Note: window.PUBLIC_URL has an extra /. but URL should still resolve
      // e.g. https://opensolar-public-staging4.s3.amazonaws.com/./tree_models/10447_Pine_Tree_v1_L3b.obj
      // Which should resolve just like https://opensolar-public-staging4.s3.amazonaws.com/tree_models/10447_Pine_Tree_v1_L3b.obj
      resourcePath = window.PUBLIC_URL + '/tree_models/'
    } else {
      resourcePath = './tree_models/'
    }

    var file_name_base = getDataForTreeType(this.treeType()).file_name_base

    if (file_name_base.indexOf('.glb') !== -1) {
      SceneHelper.loadGlbIntoTarget(
        resourcePath + file_name_base,
        this,
        {},
        function (object) {
          object.userData.excludeFromExport = true
          object.selectionDelegate = _this
          object.castShadow = true
          _this.treeModel = object
          _this.transparentLeaves(true)
          _this.setGeometryForModel(object)

          window.editor.signals.objectChanged.dispatch(_this)
        },
        function () { }
      )
    } else {
      SceneHelper.loadObjIntoTarget(resourcePath, file_name_base, this, function (object) {
        // Ensure the model is unloaded in the callback to loading a new model
        // otherwise delayed calls may result in multiple tree models being loaded
        if (_this.treeModel) {
          _this.unloadModel()
        }

        // object is a group, object.children[1] is the mesh

        // Normalize position of loaded model

        // Set scale based on target scale

        // Set position of obj to be centered on 0,0 and aligned to z=0

        var treeMesh = object.children[0]

        treeMesh.geometry.computeBoundingBox()
        var bb = treeMesh.geometry.boundingBox

        var dx = bb.max.x - bb.min.x
        var dy = bb.max.y - bb.min.y
        var dz = bb.max.z - bb.min.z

        // Set model to standard size of 5m x 5m x 5m based on width so scaling is uniform for each dimension
        var scaleFactor = 5 / dx
        treeMesh.scale.fromArray([scaleFactor, scaleFactor, scaleFactor])

        treeMesh.position.fromArray([
          scaleFactor * (-bb.min.x - dx / 2),
          scaleFactor * (-bb.min.y - dy / 2),
          scaleFactor * -bb.min.z,
        ])

        object.userData.excludeFromExport = true
        object.selectionDelegate = _this

        treeMesh.userData.excludeFromExport = true
        treeMesh.selectionDelegate = _this

        object.castShadow = true
        treeMesh.castShadow = true

        _this.treeModel = object

        window.editor.signals.objectChanged.dispatch(_this)
      })
    }
  },
})
