/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @constructor
 */

var AddGoogleObliquesCommand = function (location4326, maxZoom, ViewHelper, viewParams) {
  Command.call(this)

  this.type = 'AddGoogleObliquesCommand'
  this.name = 'Add Google Obliques Command'
  this.timeStamp = Date.now()

  this.PROPERTIES = ['location4326', 'viewParams', 'maxZoom', 'selectedViewUUID', 'newViewUuids']

  this.ViewHelper = ViewHelper || window.ViewHelper

  if (arguments.length) {
    this.newViewUuids = Utils.getRange(4).map((i) => THREE.Math.generateUUID())
    this.location4326 = location4326 || editor.scene.sceneOrigin4326
    this.maxZoom = maxZoom
    this.viewParams = viewParams || [
      ['north', 0],
      ['east', 90],
      ['south', 180],
      ['west', 270],
    ]
    this.selectedViewUUID = this.ViewHelper.selectedView().uuid
    this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()
  }
}

AddGoogleObliquesCommand.prototype = {
  execute: function () {
    var views = this.ViewHelper.views

    for (var i = 0; i < this.viewParams.length; i++) {
      var label = this.viewParams[i][0].charAt(0).toUpperCase()
      var newViewInstance = this.ViewHelper.createDefaultView(
        this.viewParams[i][0],
        new MapData({
          mapType: this.viewParams[i][1] === null ? 'GoogleTop' : 'Google',
          center: this.location4326,
          zoomTarget: this.maxZoom,
          maxZoom: this.maxZoom,
          sceneOrigin: this.location4326,
          scale: 1,
          heading: this.viewParams[i][1],
          oblique: null,
        }),
        {
          label: label,
          metersPerPixel: window.SceneHelper.metersPerPixelForZoom(this.maxZoom),
          uuid: this.newViewUuids[i],
        }
      )
      //newViewInstance.isAligned = false
      views.push(newViewInstance)
    }
    this.ViewHelper.storeViews(views)

    if (window.SceneHelper.showObliqueFirstIfAvailable === true) {
      this.ViewHelper.loadViewByIndex(this.selectedViewUUID, this.editor)
    }
  },

  undo: function () {
    this.newViewUuids.forEach((viewUuid) => {
      var view = this.ViewHelper.getViewByUuid(viewUuid)
      this.ViewHelper.deleteView(view, this.editor)
    }, this)
  },

  toJSON: function () {
    return Command.prototype.toJSON.call(this)
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
