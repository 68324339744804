var proxyHandler = {
  get: (target, prop, receiver) => {
    const value = target[prop]
    if (value === undefined) {
      console.debug(`GET: ${target.constructor.name} Map adapter prop '${prop}' has not been defined/implemented yet!`)
      return target.map[prop]
    }
    return value
  },
  deleteProperty(target, prop) {
    if (!(prop in target)) {
      console.debug(
        `DELETE: ${target.constructor.name} Map adapter prop '${prop}' has not been defined/implemented yet!`
      )
    } else {
      delete target[prop]
    }
    if (prop in target.map) {
      delete target.map[prop]
    }
  },
  set(target, prop, value) {
    if (!(prop in target)) {
      console.debug(`SET: ${target.constructor.name} Map adapter prop '${prop}' has not been defined/implemented yet!`)
    } else {
      Reflect.set(target, prop, value)
    }
    return Reflect.set(target.map, prop, value)
  },
}

var makeMapProxy = (target) => {
  return new Proxy(target, proxyHandler)
}
