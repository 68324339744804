/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @param object THREE.Object3D
 * @constructor
 */

var SetPanelConfigurationCommand = function (object, attributeName, newValue, commandUUID, optionalOldValue) {
  Command.call(this)

  this.gettersAndSetters = {
    azimuth: {
      setter: 'setAzimuth',
      getter: 'getAzimuth',
    },
    slope: {
      setter: 'setSlope',
      getter: 'getSlope',
    },
    groundClearance: {
      setter: 'groundClearance',
      getter: 'groundClearance',
    },
    modulesPerCol: {
      setter: 'modulesPerCol',
      getter: 'modulesPerCol',
    },
    modulesPerRow: {
      setter: 'modulesPerRow',
      getter: 'modulesPerRow',
    },
    trackingMode: {
      setter: 'trackingMode',
      getter: 'trackingMode',
    },
    panelConfiguration: {
      setter: 'setPanelConfiguration',
      getter: 'getPanelConfiguration',
    },
  }

  this.type = 'SetPanelConfigurationCommand'
  this.name = 'Set Panel Configuration Command'
  this.timeStamp = Date.now()
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  this.PROPERTIES = ['attributeName', 'oldValue', 'newValue']
  this.OBJECT_REFERENCES = ['object']

  if (arguments.length) {
    this.object = object
    this.newValue = newValue
    this.updatable = true

    if (typeof optionalOldValue !== 'undefined') {
      this.oldValue = optionalOldValue
    } else if (this.gettersAndSetters.hasOwnProperty(attributeName)) {
      this.oldValue = this.object[this.gettersAndSetters[attributeName].getter]()
    } else {
      this.oldValue = object !== undefined ? object[attributeName] : undefined
    }
    this.attributeName = attributeName //Either 'panelConfiguration' or 'panelTiltOverride'
  }
}

SetPanelConfigurationCommand.prototype = {
  execute: function () {
    if (this.gettersAndSetters.hasOwnProperty(this.attributeName)) {
      this.object[this.gettersAndSetters[this.attributeName].setter](this.newValue)
    } else {
      this.object[this.attributeName] = this.newValue
    }
    this.object.draw(true)
    this.editor.signals.objectChanged.dispatch(this.object, this.attributeName)
    this.object.refreshGeometryForChildren()
    this.object.refreshSetbacks()
    this.object.azimuthIndicators.sync()
  },

  undo: function () {
    if (this.gettersAndSetters.hasOwnProperty(this.attributeName)) {
      this.object[this.gettersAndSetters[this.attributeName].setter](this.oldValue)
    } else {
      this.object[this.attributeName] = this.oldValue
    }
    this.object.draw(true)
    this.object.refreshGeometryForChildren()
    this.editor.signals.objectChanged.dispatch(this.object)
    this.object.refreshSetbacks()
    this.object.azimuthIndicators.sync()
  },

  update: function (command) {
    this.newValue = command.newValue
  },

  toJSON: function () {
    var output = Command.prototype.toJSON.call(this)
    return output
  },

  fromJSON: function (json) {
    Command.prototype.fromJSON.call(this, json)
  },
}
