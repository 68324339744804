/**
 * @author adampryor
 */
var SelectionBoxController = function (editor, viewport) {
  this.name = 'SelectionBox'
  this.active = false

  var getDomElement = function () {
    return viewport.container.dom
  }

  var scope = this

  this.inputs = {
    activation: (activationState) => {
      if (activationState === this.active) return
      if (activationState === true) {
        this.activate()
      } else {
        this.deactivate()
      }
    },
  }

  this.outputs = {
    activation: new window.Signal(),
  }

  this.selectionBox = null
  this.helper = null
  this.reactivateControllersOnDeactivate = []

  this.onMouseDown = function (event) {
    // for (var item of scope.selectionBox.collection) {
    //   if (item && item.material && item.material.emissive) {
    //     item.material.emissive.set(0x000000)
    //   }
    // }

    var screenSize = viewport.screenSize()
    scope.selectionBox.startPoint.set(
      (event.offsetX / screenSize.x) * 2 - 1,
      -(event.offsetY / screenSize.y) * 2 + 1,
      0.5
    )

    // viewport.getMousePosition(event.clientX, event.clientY)
    // viewport.worldToScreenFraction
  }

  this.onMouseMove = function (event) {
    if (scope.helper.isDown) {
      if (!(event.ctrlKey || event.isMetaKey)) {
        console.warn(
          'Warning: SelectionBoxController believed that mouse was down but this event does not report mouse down. Forcefully clear the selection and disable selection box mode'
        )
        scope.helper.isDown = false
        scope.deactivate()
        return
      }

      // for (var i = 0; i < scope.selectionBox.collection.length; i++) {
      //   if (
      //     scope.selectionBox.collection[i] &&
      //     scope.selectionBox.collection[i].material &&
      //     scope.selectionBox.collection[i].material.emissive
      //   ) {
      //     scope.selectionBox.collection[i].material.emissive.set(0x000000)
      //   }
      // }

      var screenSize = viewport.screenSize()

      scope.selectionBox.endPoint.set(
        (event.offsetX / screenSize.x) * 2 - 1,
        -(event.offsetY / screenSize.y) * 2 + 1,
        0.5
      )

      // var allSelected = OsGroup.filterSelectableObjects(scope.selectionBox.select())

      // for (var i = 0; i < allSelected.length; i++) {
      //   if (allSelected[i] && allSelected[i].material && allSelected[i].material.emissive) {
      //     allSelected[i].material.emissive.set(0xffffff)
      //   }
      // }
      // editor.render()
    }
  }

  this.onMouseUp = function (event) {
    var screenSize = viewport.screenSize()
    scope.selectionBox.endPoint.set(
      (event.offsetX / screenSize.x) * 2 - 1,
      -(event.offsetY / screenSize.y) * 2 + 1,
      0.5
    )

    var allSelected = OsGroup.filterSelectableObjects(scope.selectionBox.select())

    // for (var i = 0; i < allSelected.length; i++) {
    //   if (allSelected[i] && allSelected[i].material && allSelected[i].material.emissive) {
    //     allSelected[i].material.emissive.set(0xffffff)
    //   }
    // }
    var group

    if (allSelected.length === 0) {
      return
    } else if (editor.selected && OsGroup.filterSelectableObjects([editor.selected]).length === 1) {
      // If already selecting an item that can be multi-selected, then add these items to the selection
      if (editor.selected.type === 'OsGroup') {
        //add to existing group
        group = editor.selected
        allSelected.forEach((o) => group.objects.push(o))
        group.refreshPosition()
      } else {
        // avoid adding redundant selected object to group
        allSelected = editor.selected.uuid ? allSelected.filter((o) => o.uuid !== editor.selected.uuid) : allSelected
        group = new OsGroup({ objects: [editor.selected].concat(allSelected) })
        editor.addObject(group)
        editor.select(group)
        group.refreshPosition()
        //dispatch signal ensure OsAnnotation getting highlighted
        editor.signals.objectSelected.dispatch()
      }
    } else {
      // old selection not valid for multi-select, replace selection
      if (allSelected.length === 1) {
        //only one object, simply select it
        editor.select(allSelected[0])
      } else {
        group = new OsGroup({ objects: allSelected })
        editor.addObject(group)
        editor.select(group)
        group.refreshPosition()
        //dispatch signal ensure OsAnnotation getting highlighted
        editor.signals.objectSelected.dispatch()
      }
    }
  }

  this.activate = function () {
    if (this.active) {
      return
    }
    this.active = true

    // only create selectionBox and helper once
    if (!this.selectionBox) {
      this.selectionBox = new SelectionBox(editor.camera, editor.scene)
    }
    if (!this.helper) {
      this.helper = new SelectionHelper(this.selectionBox, viewport.getRenderer(), 'selectBox')
    }
    this.helper.activate()

    // No need to add touch events, this is not possible on phone/tablet
    getDomElement().addEventListener('mousedown', this.onMouseDown, false)
    getDomElement().addEventListener('mousemove', this.onMouseMove, false)
    getDomElement().addEventListener('mouseup', this.onMouseUp, false)
    this.outputs.activation.dispatch(this.active)
  }

  this.deactivate = function () {
    if (!this.active) {
      return
    }
    this.active = false
    this.helper.deactivate()
    // No need to add touch events, this is not possible on phone/tablet
    getDomElement().removeEventListener('mousedown', this.onMouseDown, false)
    getDomElement().removeEventListener('mousemove', this.onMouseMove, false)
    getDomElement().removeEventListener('mouseup', this.onMouseUp, false)

    if (editor && editor.controllers) {
      this.reactivateControllersOnDeactivate.forEach((controllerName) => editor.controllers[controllerName].activate())
      this.reactivateControllersOnDeactivate = []
    }
    this.outputs.activation.dispatch(this.active)
  }
}

SelectionBoxController.prototype = Object.create(THREE.EventDispatcher.prototype)
SelectionBoxController.prototype.constructor = SelectionBoxController

/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 * This is a class to check whether objects are in a selection area in 3D space
 */

SelectionBox = (function () {
  var frustum = new THREE.Frustum()
  var center = new THREE.Vector3()

  var tmpPoint = new THREE.Vector3()

  var vecNear = new THREE.Vector3()
  var vecTopLeft = new THREE.Vector3()
  var vecTopRight = new THREE.Vector3()
  var vecDownRight = new THREE.Vector3()
  var vecDownLeft = new THREE.Vector3()

  var vecFarTopLeft = new THREE.Vector3()
  var vecFarTopRight = new THREE.Vector3()
  var vecFarDownRight = new THREE.Vector3()
  var vecFarDownLeft = new THREE.Vector3()

  var vectemp1 = new THREE.Vector3()
  var vectemp2 = new THREE.Vector3()
  var vectemp3 = new THREE.Vector3()

  function SelectionBox(camera, scene, deep) {
    this.camera = camera
    this.scene = scene
    this.startPoint = new THREE.Vector3()
    this.endPoint = new THREE.Vector3()
    this.collection = []
    this.deep = deep || Number.MAX_VALUE
  }

  SelectionBox.prototype.select = function (startPoint, endPoint) {
    this.startPoint = startPoint || this.startPoint
    this.endPoint = endPoint || this.endPoint
    this.collection = []

    this.updateFrustum(this.startPoint, this.endPoint)
    this.searchChildInFrustum(frustum, this.scene)

    return this.collection
  }

  SelectionBox.prototype.updateFrustum = function (startPoint, endPoint) {
    startPoint = startPoint || this.startPoint
    endPoint = endPoint || this.endPoint

    // Avoid invalid frustum

    if (startPoint.x === endPoint.x) {
      endPoint.x += Number.EPSILON
    }

    if (startPoint.y === endPoint.y) {
      endPoint.y += Number.EPSILON
    }

    this.camera.updateProjectionMatrix()
    this.camera.updateMatrixWorld()

    if (this.camera.isPerspectiveCamera) {
      tmpPoint.copy(startPoint)
      tmpPoint.x = Math.min(startPoint.x, endPoint.x)
      tmpPoint.y = Math.max(startPoint.y, endPoint.y)
      endPoint.x = Math.max(startPoint.x, endPoint.x)
      endPoint.y = Math.min(startPoint.y, endPoint.y)

      vecNear.setFromMatrixPosition(this.camera.matrixWorld)
      vecTopLeft.copy(tmpPoint)
      vecTopRight.set(endPoint.x, tmpPoint.y, 0)
      vecDownRight.copy(endPoint)
      vecDownLeft.set(tmpPoint.x, endPoint.y, 0)

      vecTopLeft.unproject(this.camera)
      vecTopRight.unproject(this.camera)
      vecDownRight.unproject(this.camera)
      vecDownLeft.unproject(this.camera)

      vectemp1.copy(vecTopLeft).sub(vecNear)
      vectemp2.copy(vecTopRight).sub(vecNear)
      vectemp3.copy(vecDownRight).sub(vecNear)
      vectemp1.normalize()
      vectemp2.normalize()
      vectemp3.normalize()

      vectemp1.multiplyScalar(this.deep)
      vectemp2.multiplyScalar(this.deep)
      vectemp3.multiplyScalar(this.deep)
      vectemp1.add(vecNear)
      vectemp2.add(vecNear)
      vectemp3.add(vecNear)

      var planes = frustum.planes

      planes[0].setFromCoplanarPoints(vecNear, vecTopLeft, vecTopRight)
      planes[1].setFromCoplanarPoints(vecNear, vecTopRight, vecDownRight)
      planes[2].setFromCoplanarPoints(vecDownRight, vecDownLeft, vecNear)
      planes[3].setFromCoplanarPoints(vecDownLeft, vecTopLeft, vecNear)
      planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft)
      planes[5].setFromCoplanarPoints(vectemp3, vectemp2, vectemp1)
      planes[5].normal.multiplyScalar(-1)
    } else if (this.camera.isOrthographicCamera) {
      var left = Math.min(startPoint.x, endPoint.x)
      var top = Math.max(startPoint.y, endPoint.y)
      var right = Math.max(startPoint.x, endPoint.x)
      var down = Math.min(startPoint.y, endPoint.y)

      vecTopLeft.set(left, top, -1)
      vecTopRight.set(right, top, -1)
      vecDownRight.set(right, down, -1)
      vecDownLeft.set(left, down, -1)

      vecFarTopLeft.set(left, top, 1)
      vecFarTopRight.set(right, top, 1)
      vecFarDownRight.set(right, down, 1)
      vecFarDownLeft.set(left, down, 1)

      vecTopLeft.unproject(this.camera)
      vecTopRight.unproject(this.camera)
      vecDownRight.unproject(this.camera)
      vecDownLeft.unproject(this.camera)

      vecFarTopLeft.unproject(this.camera)
      vecFarTopRight.unproject(this.camera)
      vecFarDownRight.unproject(this.camera)
      vecFarDownLeft.unproject(this.camera)

      var planes = frustum.planes

      planes[0].setFromCoplanarPoints(vecTopLeft, vecFarTopLeft, vecFarTopRight)
      planes[1].setFromCoplanarPoints(vecTopRight, vecFarTopRight, vecFarDownRight)
      planes[2].setFromCoplanarPoints(vecFarDownRight, vecFarDownLeft, vecDownLeft)
      planes[3].setFromCoplanarPoints(vecFarDownLeft, vecFarTopLeft, vecTopLeft)
      planes[4].setFromCoplanarPoints(vecTopRight, vecDownRight, vecDownLeft)
      planes[5].setFromCoplanarPoints(vecFarDownRight, vecFarTopRight, vecFarTopLeft)
      planes[5].normal.multiplyScalar(-1)
    } else {
      console.error('SelectionBox: Unsupported camera type.')
    }
  }

  SelectionBox.prototype.searchChildInFrustum = function (frustum, object) {
    // Hack to make OsModuleGrid selectable
    if (object.isMesh || object.isLine || object.isPoints || object.type === 'OsModuleGrid') {
      if (object.material !== undefined) {
        if (object.geometry.boundingSphere === null) object.geometry.computeBoundingSphere()

        center.copy(object.geometry.boundingSphere.center)

        center.applyMatrix4(object.matrixWorld)

        if (frustum.containsPoint(center)) {
          if (object.type === 'OsFacetMesh') {
            this.collection.push(object.parent)
          } else {
            this.collection.push(object)
          }
        }
      }
    } else if (object.type === 'OsAnnotation') {
      if (frustum.containsPoint(object.position)) {
        this.collection.push(object)
      }
    }

    if (object.children.length > 0) {
      for (var x = 0; x < object.children.length; x++) {
        this.searchChildInFrustum(frustum, object.children[x])
      }
    }
  }

  return SelectionBox
})()

/**
 * @author HypnosNova / https://www.threejs.org.cn/gallery
 */

SelectionHelper = (function () {
  function SelectionHelper(selectionBox, renderer, cssClassName) {
    this.element = document.createElement('div')
    this.element.classList.add(cssClassName)
    this.element.style.pointerEvents = 'none'

    this.renderer = renderer

    this.startPoint = new THREE.Vector2()
    this.pointTopLeft = new THREE.Vector2()
    this.pointBottomRight = new THREE.Vector2()

    this.isDown = false

    this.onMouseDown = function (event) {
      this.isDown = true
      this.onSelectStart(event)
    }.bind(this)

    this.onMouseMove = function (event) {
      if (this.isDown) {
        this.onSelectMove(event)
      }
    }.bind(this)

    this.onMouseUp = function (event) {
      this.isDown = false
      this.onSelectOver(event)
    }.bind(this)

    this.onContextMenu = function (event) {
      // Prevent Ctrl-right-click menu from appearing and disrupting the selection box
      // Note this is no longer handled by the CameraController because CameraController gets disabled as soon as the
      // Ctrl key is pressed and held.
      event.preventDefault()
    }.bind(this)
  }

  SelectionHelper.prototype.onSelectStart = function (event) {
    this.renderer.domElement.parentElement.appendChild(this.element)

    this.element.style.left = event.clientX + 'px'
    this.element.style.top = event.clientY + 'px'
    this.element.style.width = '0px'
    this.element.style.height = '0px'

    this.startPoint.x = event.clientX
    this.startPoint.y = event.clientY
  }

  SelectionHelper.prototype.onSelectMove = function (event) {
    this.pointBottomRight.x = Math.max(this.startPoint.x, event.clientX)
    this.pointBottomRight.y = Math.max(this.startPoint.y, event.clientY)
    this.pointTopLeft.x = Math.min(this.startPoint.x, event.clientX)
    this.pointTopLeft.y = Math.min(this.startPoint.y, event.clientY)

    this.element.style.left = this.pointTopLeft.x + 'px'
    this.element.style.top = this.pointTopLeft.y + 'px'
    this.element.style.width = this.pointBottomRight.x - this.pointTopLeft.x + 'px'
    this.element.style.height = this.pointBottomRight.y - this.pointTopLeft.y + 'px'
  }

  SelectionHelper.prototype.onSelectOver = function () {
    if (this.element.parentElement) {
      this.element.parentElement.removeChild(this.element)
    } else {
      // console.warn('SelectionHelperonSelectOver(): this.element.parentElement empty')
    }
  }
  SelectionHelper.prototype.activate = function () {
    this.active = true
    this.renderer.domElement.addEventListener('mousedown', this.onMouseDown, false)
    this.renderer.domElement.addEventListener('mousemove', this.onMouseMove, false)
    this.renderer.domElement.addEventListener('mouseup', this.onMouseUp, false)
    this.renderer.domElement.addEventListener('contextmenu', this.onContextMenu, true)
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  SelectionHelper.prototype.deactivate = function () {
    this.active = false
    this.renderer.domElement.removeEventListener('mousedown', this.onMouseDown, false)
    this.renderer.domElement.removeEventListener('mousemove', this.onMouseMove, false)
    this.renderer.domElement.removeEventListener('mouseup', this.onMouseUp, false)
    this.renderer.domElement.removeEventListener('contextmenu', this.onContextMenu, true)
    this.onSelectOver()
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }
  return SelectionHelper
})()
