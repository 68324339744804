function OsBattery(options) {
  THREE.Object3D.call(this)
  this.type = 'OsBattery'

  this.unshiftNewObject = true
  this.transformable = false
  this.unshiftNewObject = true
  this.selectable = false

  // Allow fetching id from either options or options.userData
  this.battery_id = options?.battery_id || options?.userData?.battery_id || null

  this.code = options && options.code ? options.code : null
  this.manufacturer_name = options && options.manufacturer_name ? options.manufacturer_name : null
  this.kwh_optimal = options && options.kwh_optimal ? options.kwh_optimal : null
  this.depth_of_discharge_factor =
    options && options.depth_of_discharge_factor ? options.depth_of_discharge_factor : null
  this.power_max_continuous = options && options.power_max_continuous ? options.power_max_continuous : null
  this.efficiency_factor = options && options.efficiency_factor ? options.efficiency_factor : null
  this.aging_factor = options && options.aging_factor ? options.aging_factor : null
  this.warranty_kwh_1_cycle_per_day =
    options && options.warranty_kwh_1_cycle_per_day ? options.warranty_kwh_1_cycle_per_day : null
  this.voltage = options && options.voltage ? options.voltage : null
  this.end_of_life_capacity = options && options.end_of_life_capacity ? options.end_of_life_capacity : null
  this.quantity = options && options.quantity ? options.quantity : 1

  if (options?.userData) {
    this.userData = options.userData
    this.applyUserData()
  } else {
    this.reloadSpecs()
  }
}

OsBattery.prototype = Object.assign(Object.create(THREE.Object3D.prototype), {
  constructor: OsBattery,
  fields: [
    'battery_id',
    'code',
    'manufacturer_name',
    'kwh_optimal',
    'depth_of_discharge_factor',
    'power_max_continuous',
    'efficiency_factor',
    'aging_factor',
    'warranty_kwh_1_cycle_per_day',
    'voltage',
    'end_of_life_capacity',
    'org_id',
  ],
  componentType: function (_componentType) {
    if (typeof _componentType === 'undefined') {
      var result = {}
      this.fields.forEach((field) => {
        result[field] = this[field]
      })
      return result
    }

    this.fields.forEach((field) => {
      this[field] = _componentType[field]
    })

    // Non-standard, nasty, confusing field mapping. We store component_activation_id in battery_id
    // NOT the standard component battery_id
    // This should be renamed to this.battery_activation_id
    this.battery_id = _componentType.id
  },
  getComponentData: function () {
    return AccountHelper ? AccountHelper.getBatteryById(this.battery_id) : null
  },
  toolsActive: function () {
    return {
      translateXY: false,
      translateZ: false,
      rotate: false,
      scaleXY: false,
      scaleZ: false,
      scale: false, //legacy
    }
  },
  refreshUserData: function () {
    if (AccountHelper.loadedDataReady.componentBatterySpecs) {
      this.userData = {}
      this.fields.forEach((field) => {
        this.userData[field] = this[field]
      })
      this.userData.quantity = this.quantity
    } else if (window.studioDebug) {
      console.log('Skip OsBattery.refreshUserData... battery specs not loaded')
    }
    return this.userData
  },
  reloadSpecs: ObjectBehaviors.reloadSpecs,
  applyUserData: function () {
    ObjectBehaviors.applyUserData.call(this, this.fields, this.userData)
    this.quantity = this.userData.quantity
    this.reloadSpecs()
  },
  getSummary: function (showManufacturer) {
    //Format: Tesla XCD-SEW141 12.1 kWh Battery
    return (
      ('Quantity ' + this.quantity + ':  ' + showManufacturer ? this.manufacturer_name + ' ' : '') +
      this.code +
      ' ' +
      this.kwh_optimal +
      ' kWh Battery'
    )
  },
  getSystem: ObjectBehaviors.getSystem,
})
