/**
 * @author adampryor
 * Adapted from THREE.EditorControls
 */
var StringModuleAssignmentController = function (editor, viewport) {
  this.name = 'StringModuleAssignment'

  var camera = editor.camera
  var domElement = viewport.container.dom

  var scope = this

  var electricalString = null

  var mouseIsDown = false

  function getVisibleSystemModules() {
    return editor.filterObjects(function (o) {
      return o.type == 'OsModule' && o.parent.type == 'OsModuleGrid' && o.getSystem() && o.getSystem().visible == true
    })
  }

  function getString() {
    if (editor.selected && editor.selected.type == 'OsString') {
      return editor.selected
    } else {
      return
    }
  }

  function onMouseDown(event, moduleAffected) {
    //Clicking on a helper above the module will set cancelBubble so we know to ignore it here
    if (event.cancelBubble == true) {
      return
    }

    electricalString = getString()

    if (!electricalString) {
      return
    }

    //Do not exit early if moduleAffected is supplied
    if (!moduleAffected) {
      if (event.button != 0 && (!event.touches || event.touches.length === 0)) {
        return
      }
    }

    //moduleAffected can be passed directly for unit tests when DOM/clicks aren't available

    event.preventDefault()

    var arrayOfModulesUUID = electricalString.modules.map(function (module) {
      return module.uuid
    })

    if (!moduleAffected) {
      //Only consider SystemModules which belong to a grid, and which belong to a system that is visible
      //Only tests SystemModules. Efficient, but unable to verify if this is the first hit
      //moduleAffected = viewport.objectUnderClick(event, getVisibleSystemModules());

      var objectClicked = viewport.objectUnderClick(
        event.touches && event.touches.length > 0 ? event.touches[0] : event,
        viewport.objectsSelectableAndModules()
      )
      if (objectClicked && objectClicked.type == 'OsModule') {
        moduleAffected = objectClicked
      }
    }

    if (!moduleAffected || !moduleAffected.active) {
      return
    } else {
      electricalString.addModuleMultiple(moduleAffected)
    }

    mouseIsDown = true

    domElement.addEventListener('mousemove', onMouseMove, false)
    domElement.addEventListener(
      'mouseup',
      function (e) {
        onMouseUp(e, arrayOfModulesUUID)
      },
      { once: true }
    )
  }
  this.onMouseDown = onMouseDown

  function onMouseUp(event, oldAssignedModulesID) {
    event.preventDefault()

    //editor.execute(new StringModuleAssignmentCommand(getString(), oldAssignedModulesID))
    mouseIsDown = false
    //domElement.removeEventListener('mouseup', onMouseUp, false)
  }
  this.onMouseUp = onMouseUp

  function onMouseMove(event, moduleAffected) {
    event.preventDefault()

    if (!mouseIsDown) {
      return
    }

    if (!electricalString) {
      return
    }

    //handleRollovers(event);

    if (!moduleAffected) {
      //Only tests SystemModules. Efficient, but unable to verify if this is the first hit
      //moduleAffected = viewport.objectUnderClick(event, getVisibleSystemModules());

      var objectClicked = viewport.objectUnderClick(
        event.touches && event.touches.length > 0 ? event.touches[0] : event,
        viewport.objectsSelectableAndModules()
      )
      if (objectClicked && objectClicked.type == 'OsModule') {
        moduleAffected = objectClicked
      }
    }

    if (moduleAffected && moduleAffected.active) {
      electricalString.addModuleMultiple(moduleAffected)
    } else {
      console.log('Module not found for onMouseMove')
    }
  }
  this.onMouseMove = onMouseMove

  this.activate = function () {
    domElement.addEventListener('mousedown', onMouseDown, false)
    domElement.addEventListener('mousemove', onMouseMove, false)
    domElement.addEventListener('touchstart', onMouseDown, false)
    domElement.addEventListener('touchmove', onMouseMove, false)
    this.active = true
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  this.deactivate = function () {
    domElement.removeEventListener('mousedown', onMouseDown, false)
    domElement.removeEventListener('mouseup', onMouseUp, false)
    domElement.removeEventListener('mousemove', onMouseMove, false) //in case not disposed
    domElement.removeEventListener('touchstart', onMouseDown, false)
    domElement.removeEventListener('touchend', onMouseUp, false)
    domElement.removeEventListener('touchmove', onMouseMove, false)
    this.active = false
    editor.signals.controllerStatusChanged.dispatch(this.name, this.active)
  }

  return this
}

StringModuleAssignmentController.prototype = Object.create(THREE.EventDispatcher.prototype)
StringModuleAssignmentController.prototype.constructor = StringModuleAssignmentController
