window.pluginDesignAutoApply = {
  id: 3000,
  componentCodes: ['ALL'],
  name: 'pluginDesignAutoApply',
  plugin: function () {
    var refreshDebouncedInner = Utils.debounce((system) => refresh(system), 1000)
    var refreshDebounced = function (system) {
      //system is required
      if (!system?.uuid) return
      refreshDebouncedInner(system)
    }

    function refresh(system) {
      detectAutoApply(system)
    }

    function refreshAllSystems() {
      editor.getSystems().forEach((system) => {
        refresh(system)
      })
    }

    function refreshSystemFromObject(object) {
      if (object.getSystem) {
        var system = object.getSystem()

        if (!system) {
          // Deleted objects do not have getSystem()
          // but we can use object.parentBeforeRemoval.getSystem instead
          if (object.parentBeforeRemoval && object.parentBeforeRemoval.getSystem) {
            system = object.parentBeforeRemoval.getSystem()
          }
        }

        if (system && system.isDuplicating !== true) {
          refreshDebounced(system)
        }
      }
    }

    function updateWarnings(error, result) {
      if (!!error) {
        result.push(error)
      }
    }

    function checkPaymentOptions(system, changesToApply) {
      if (changesToApply.paymentOptions?.add.length || changesToApply.paymentOptions?.remove.length) {
        var applyUpdates = function () {
          var autoApplyHelper = new AutoApplyHelper(system, WorkspaceHelper.project)
          autoApplyHelper.apply(false, 'paymentOptions')
        }

        // enable override so changes can be applied
        const systemId = system.uuid
        return {
          category: 'payment_option',
          key: 'PAYMENT-OPTIONS-AUTO-APPLY',
          severity: 'warning',
          systemId: systemId,
          message: window.translate('Applied payment options can be updated based on auto-apply settings.'),
          options: {
            actions: [
              {
                label: window.translate('Enable auto-apply'),
                callback: () => {
                  applyUpdates()
                },
              },
            ],
          },
        }
      }
    }

    function checkPricingScheme(system, changesToApply) {
      if (changesToApply.pricingScheme) {
        const systemId = system.uuid

        var applyUpdates = function () {
          var autoApplyHelper = new AutoApplyHelper(system, WorkspaceHelper.project)
          autoApplyHelper.apply(false, 'pricingScheme')
        }

        if (system.autoSync.pricingScheme) {
          applyUpdates()
        } else {
          return {
            category: 'price',
            key: 'PRICING-SCHEME-AUTO-APPLY',
            severity: 'info',
            systemId,
            message: window.translate('Pricing scheme can be updated based on auto-apply settings.'),
            options: {
              actions: [
                {
                  label: window.translate('Enable auto-apply'),
                  callback: () => {
                    applyUpdates()
                  },
                },
              ],
            },
          }
        }
      }
    }

    function checkCosting(system, changesToApply) {
      if (changesToApply.costing) {
        var applyUpdates = function () {
          var autoApplyHelper = new AutoApplyHelper(system, WorkspaceHelper.project)
          autoApplyHelper.apply(false, 'costing')
        }

        if (system.autoSync.costing) {
          applyUpdates()
        } else {
          return {
            category: 'price',
            key: 'COSTING-AUTO-APPLY',
            severity: 'info',
            systemId: system.uuid,
            message: window.translate('Costing scheme can be updated based on auto-apply settings.'),
            options: {
              actions: [
                {
                  label: window.translate('Enable auto-apply'),
                  callback: () => {
                    applyUpdates()
                  },
                },
              ],
            },
          }
        }
      }
    }

    function checkAdders(system, changesToApply) {
      if (changesToApply.adders?.add.length || changesToApply.adders?.remove.length) {
        var applyUpdates = function () {
          var autoApplyHelper = new AutoApplyHelper(system, WorkspaceHelper.project)
          autoApplyHelper.apply(false, 'adders')
        }

        // If auto-sync is enabled then apply immediately, otherwise show a message and apply-button
        if (system.autoSync.adders) {
          applyUpdates()
        } else {
          return {
            category: 'price',
            key: 'ADDERS-AUTO-APPLY',
            severity: 'info',
            systemId: system.uuid,
            message: window.translate(
              'Changes have been made to your project or adder library. Would you like to update auto-applied adders?'
            ),
            options: {
              actions: [
                {
                  label: window.translate('Update Auto-Applied Adders'),
                  callback: () => {
                    applyUpdates()
                  },
                },
              ],
            },
          }
        }
      }
    }

    function checkCommissions(system, changesToApply) {
      if (changesToApply.commissions) {
        var applyUpdates = function () {
          var autoApplyHelper = new AutoApplyHelper(system, WorkspaceHelper.project)
          autoApplyHelper.apply(false, 'commissions')
        }
        // If commission is auto-apply then apply immediately, otherwise show a message and apply-button
        const isAutoApplyEnabled = system.commission_id === null && system.commission_override_manually === null
        if (isAutoApplyEnabled) {
          applyUpdates()
        } else {
          return {
            category: 'price',
            key: 'COMMISSION-AUTO-APPLY',
            severity: 'warning',
            systemId: system.uuid,
            message: window.translate('Commission can be updated based on auto-apply settings.'),
            options: {
              actions: [
                {
                  label: window.translate('Enable Auto-Apply'),
                  callback: () => {
                    applyUpdates()
                  },
                },
              ],
            },
          }
        }
      }
    }

    function refreshErrorMessage(system, problems) {
      //clear previous auto apply message
      const filter = (error) => error.systemId === system.uuid && error.source === 'autoApply'
      window.WorkspaceHelper?.clearProjectError(filter)

      if (!window.editor?.objectByUuid(system.uuid)) {
        //return if system not longer exist in the scene
        return
      }

      //add new autoApply error
      problems.forEach((error) => {
        window.WorkspaceHelper?.addProjectErrorToReduxStore({
          message: error.message,
          key: error.key,
          severity: error.severity,
          systemId: system.uuid,
          source: 'autoApply',
          category: error.category,
          options: error.options,
        })
      })
    }

    const detectAutoApply = function (system) {
      if (!system || !window.projectForm?.getState().values || !!window.WorkspaceHelper.getIsProjectLite()) {
        return
      }

      if (ReplayHelper.replayInProgress) {
        return
      }

      const autoApplyHelper = new window.AutoApplyHelper(system, window.projectForm.getState().values)
      const changesToApply = autoApplyHelper.delta()
      const autoApplyMessages = []
      updateWarnings(checkPaymentOptions(system, changesToApply), autoApplyMessages)
      updateWarnings(checkPricingScheme(system, changesToApply), autoApplyMessages)
      updateWarnings(checkCosting(system, changesToApply), autoApplyMessages)
      updateWarnings(checkAdders(system, changesToApply), autoApplyMessages)
      updateWarnings(checkCommissions(system, changesToApply), autoApplyMessages)

      refreshErrorMessage(system, autoApplyMessages)
    }

    function objectAdded(object) {
      refreshSystemFromObject(object)
    }

    function objectRemoved(object) {
      if (object?.type === 'OsSystem') {
        const filter = (error) => error.systemId === object.uuid
        window.WorkspaceHelper?.clearProjectError(filter)
      } else {
        refreshSystemFromObject(object)
      }
    }

    function objectChanged(object) {
      refreshSystemFromObject(object)
    }

    function liteProjectUpgraded() {
      refreshAllSystems()
    }

    function pluginLoaded() {
      console.log('pluginLoaded:pluginDesignAutoApply.js')
      refreshAllSystems()
    }

    function pluginUnloaded() {
      const filter = (error) => error.source === 'autoApply'
      window.WorkspaceHelper?.clearProjectError(filter)
    }

    return {
      objectAdded,
      objectChanged,
      objectRemoved,
      liteProjectUpgraded,
      pluginLoaded,
      pluginUnloaded,
    }
  },
}
