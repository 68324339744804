/**
 * @author adampryor
 */

var up = new THREE.Vector3(0, 0, 1)

var cacheEdges = {
  interactive: null,
  interactiveIncomplete: null,
  planset: null,
}

function metersToFeet(v) {
  if (v && v.substr && v.substr(-1) === '.') {
    return v
  }

  var x = v * 3.28084
  if (isNaN(x)) {
    return v
  } else {
    return x
  }
}

function trimDecimalPlaces(v, places) {
  if (isNaN(v)) {
    console.log('isNaN(v)', v)
    return v
  } else {
    return parseFloat(v.toFixed(places).toString())
  }
}

function OsEdge(options) {

  if (!OsEdge.gf) {
    OsEdge.gf = new jsts.geom.GeometryFactory()
  }

  THREE.Line.call(this)

  if (!cacheEdges.interactive || !cacheEdges.interactiveIncomplete || !cacheEdges.planset) {
    cacheEdges.interactive = {
      //populated below
      edgeMaterial: new THREE.LineBasicMaterial({
        color: new THREE.Color(0xffffff),
      }),
      meshLineEdgeMaterials: {},
    }
    cacheEdges.interactiveIncomplete = {
      //populated below
      edgeMaterial: new THREE.LineBasicMaterial({
        color: new THREE.Color(0x666666),
      }),
      meshLineEdgeMaterials: {},
    }
    cacheEdges.planset = {
      //populated below
      edgeMaterial: new THREE.LineBasicMaterial({
        color: new THREE.Color(0x666666),
        lineWidth: 5,
      }),
      meshLineEdgeMaterials: {},
    }

    cacheEdges.selected = new MeshLineMaterial({
      color: new THREE.Color(0xffda00),
      sizeAttenuation: 0,
      lineWidth: 12,
      resolution: new THREE.Vector3(600, 600),
      depthTest: false,
    })
    cacheEdges.interactive.spriteMaterialBackground = new THREE.SpriteMaterial({
      color: 0xffffff,
      depthTest: false,
    })
    cacheEdges.interactiveIncomplete.spriteMaterialBackground = new THREE.SpriteMaterial({
      color: 0xffffff,
      depthTest: false,
    })
    cacheEdges.planset.spriteMaterialBackground = new THREE.SpriteMaterial({
      color: 0x666666,
      depthTest: false,
    })

    if (window.TESTING) {
      cacheEdges.interactive.spriteMaterial = new THREE.SpriteMaterial({
        color: 0xffffff,
        depthTest: false,
      })

      cacheEdges.interactiveIncomplete.spriteMaterial = new THREE.SpriteMaterial({
        color: 0xffffff,
        depthTest: false,
      })

      cacheEdges.planset.spriteMaterial = new THREE.SpriteMaterial({
        color: 0x666666,
        depthTest: false,
      })
    } else {
      // this approach seems to fail with CORS errors
      // cacheEdges.interactive.spriteMap = new THREE.TextureLoader().load( RIGHT_ANGLE_IMAGE_SRC )
      // cacheEdges.interactive.spriteMaterial = new THREE.SpriteMaterial( { map: cacheEdges.interactive.spriteMap, color: 0xffffff, depthTest: false } )
      //
      cacheEdges.interactive.spriteMaterial = new THREE.SpriteMaterial({
        map: new THREE.TextureLoader().load(Designer.prepareFilePathForLoad(Designer.FILE_PATHS.RIGHT_ANGLE_IMAGE_SRC)),
        color: 0xff0000,
        depthTest: false,
      })

      cacheEdges.planset.spriteMaterial = new THREE.SpriteMaterial({
        color: 0xff0000,
        depthTest: false,
      })
    }
  }

  this.type = 'OsEdge'
  this.name = 'OsEdge'
  this.nodes = []
  this.edgeType = options && options.edgeType ? options.edgeType : OsEdge.EDGE_TYPE_DEFAULT
  this.centroid = null
  this._ghostMode = false
  this.derivedFromFacet = (options && options.derivedFromFacet) || null
  this.selectable = options && options.hasOwnProperty('selectable') ? options.selectable : true
  this.visible = options && options.hasOwnProperty('visible') ? options.visible : true

  if (options.fromJSON === true && !options.nodes) {
    // @TODO: Untanble this later, but for now we will just populate options.nodes from JSON
    // Perhaps this should only be applied when re-doing/un-doing and not during initial scene loading?
    var _nodes = options.userData.nodes.map((uuidNode) => editor.objectByUuid(uuidNode))
    if (!_nodes[0] || !_nodes[1]) {
      console.warn(
        'Nodes could not be found to attach to edge... perhaps they are not yet loaded in the scene? Do not overwrite userData.nodes or options.nodes. They should be re-linked later'
      )
    } else {
      options.nodes = options.userData.nodes.map((uuidNode) => editor.objectByUuid(uuidNode))
    }
  }

  if (options && options.nodes) {
    this.setNodes(options.nodes, options.linkNodes)

    //Don't call refreshLine() in constructor because has not yet been added to the stage
    //Instead call it in onAdd()
    //this.refreshLine()
  }

  /*
  Strange bug, not sure when it arose. When loading the scene, if we call refreshUserData here it will clear
  userData.nodes = [...] which we need to link the nodes later in the scene loading sequence.
  Try to fix this by skipping refresh userData when loading from JSON
  */
  if (options.fromJSON === true) {
    //
  } else {
    this.refreshUserData()
  }

  //Do not save hash until after calling onChange to ensure everything is wired up
  //this.saveHash()

  //Don't call refreshRightAngles() in constructor because has not yet been added to the stage
  //this.refreshRightAngles()

  // var geometry = new THREE.Geometry();
  // for( var j = 0; j < Math.PI; j += 2 * Math.PI / 100 ) {
  // 	var v = new THREE.Vector3( Math.cos( j ), Math.sin( j ), 0 );
  // 	geometry.vertices.push( v );
  // }
  //
  // var line = new MeshLine();
  // line.setGeometry( geometry );
  //
  // var material = new MeshLineMaterial({
  //     sizeAttenuation: 1,
  //     lineWidth: 0.01,
  //     resolution: new THREE.Vector3(600,600),
  // });
  //
  // var mesh = new THREE.Mesh( line.geometry, material ); // this syntax could definitely be improved!
  // this.add( mesh );
  // editor.addObject(mesh, this)
}

//Used to detect if a facet is created between two points in external code
//Set to false then check later to see if a facet was created in the meantime
OsEdge.facetCreatedFlag = false

OsEdge.prototype = Object.assign(Object.create(THREE.Line.prototype), {
  constructor: OsEdge,
  formatEdgeType: function (edgeType) {
    return Utils.capitalizeFirstLetter(edgeType.split('_').join(' '))
  },
  getName: function () {
    //@TODO: Allow injecting translate argument and using a translation with placeholders instead

    // Only use new labels for US users because translations will not be ready.
    if (!Utils.useEnglishLabelsWithoutTranslations()) {
      return 'Edge'
    }

    var lengthString

    if (
      window.AccountHelper &&
      window.AccountHelper.getMeasurementUnits &&
      window.AccountHelper.getMeasurementUnits() === 'imperial'
    ) {
      lengthString = trimDecimalPlaces(metersToFeet(this.delta().length()), 1) + 'ft'
    } else {
      lengthString = this.delta().length().toFixed(2) + 'm'
    }

    if (this.isWire()) {
      return `Wire (${lengthString})`
    } else if (this.edgeType && this.edgeType !== OsEdge.EDGE_TYPE_DEFAULT) {
      return `Edge - ${this.formatEdgeType(this.edgeType)} (${lengthString})`
    } else {
      return `Edge (${lengthString})`
    }
  },
  isWire: function () {
    return Boolean(this.getSystem())
  },
  getSystem: ObjectBehaviors.getSystem,
  refreshPosition: function () {
    this.centroid = this.getCentroid()
    if (this.centroid) {
      this.position.copy(this.centroid)
    }
  },
  toolsActive: function () {
    if (this.isManagedByParent()) {
      return {
        translateXY: false,
        translateZ: false,
        translateX: false,
        rotate: false,
        scaleXY: false,
        scaleZ: false,
        scale: false, //legacy
        delete: false,
        duplicate: false,
      }
    } else {
      return {
        translateX: true,
        translateXY: true,
        translateZ: true,
        rotate: false,
        scaleXY: false,
        scaleZ: false,
        scale: false, //legacy
        delete: true,
        duplicate: true,
      }
    }
  },
  isManagedByParent: function () {
    // Also true if belonging to a locked facet
    return !!this.getFacets().find((f) => f.isManaged || f.isLocked)
  },
  onAdd: function (editor) {
    this.refreshLine()
    this.refreshRightAngles()
  },
  _hash: null,
  getHash: ObjectBehaviors.changeDetection.getHash,
  saveHash: ObjectBehaviors.changeDetection.saveHash,
  hasChanged: ObjectBehaviors.changeDetection.hasChanged,

  splitWithNode: function (node, commandUUID, systemOrUndefined) {
    var edge1 = new OsEdge({
      edgeType: this.edgeType,
      nodes: [this.nodes[0], node],
    })
    var edge2 = new OsEdge({
      edgeType: this.edgeType,
      nodes: [node, this.nodes[1]],
    })

    editor.execute(new RemoveObjectCommand(this, false, undefined, commandUUID))

    editor.execute(new AddObjectCommand(edge1, systemOrUndefined, false, commandUUID))
    editor.execute(new AddObjectCommand(edge2, systemOrUndefined, false, commandUUID))
  },

  positionIsEndOfEdge: function (position, distanceMax) {
    //Never split an edge if we are already at an end, otherwise we will create an edge with zero length
    //Return the node at the end if on the edge, otherwise false
    if (position.distanceTo(this.nodes[0].position) < distanceMax) {
      return this.nodes[0]
    } else if (position.distanceTo(this.nodes[1].position) < distanceMax) {
      return this.nodes[1]
    } else {
      return false
    }
  },

  refreshRightAngles: function () {
    if (!this.parent) {
      if (!this.ghostMode()) {
        console.log(
          'Error: calling edge.refreshRightAngles() but it has no parent! Skip refreshRightAngles() it will be called onAdd()'
        )
      }
      return
    }

    if (this.rightAngles && this.rightAngles.length > 0) {
      this.rightAngles.forEach(function (ra) {
        editor.removeObject(ra)
      })
    }

    // Detect size required to equal specified screen pixels
    var metersPerPixel = editor.metersPerPixel()
    var spriteSize = 20.0 * metersPerPixel
    var offsetDistance = 20.0 * metersPerPixel

    // Do not show if facet isManaged
    if (this.isManagedByParent()) {
      return
    }

    // Only show right angles if edge is large enough on the screen
    var edgeLengthInPixels = this.delta().length() / metersPerPixel
    if (edgeLengthInPixels < 100) {
      return
    }

    this.rightAngles = []

    var _edge = this

    var addRightAngleForNode = function (nodeIndex) {
      var node = this.nodes[nodeIndex]

      if (!node) {
        return
      }

      // hide right-angle during drawing
      if (editor.controllers && editor.controllers['AddObject'] && editor.controllers['AddObject'].active) return

      //Check if node has another edge
      var otherEdges = node.getEdges(this)
      // console.log('otherEdges', otherEdges)

      otherEdges.forEach(function (otherEdge) {
        try {
          if (otherEdge._ghostMode) return
          var otherEdgeDirection = otherEdge.delta(node).normalize()
          var otherEdgeVector = otherEdgeDirection.multiplyScalar(offsetDistance)

          var rightAngleSprite = new THREE.Sprite(cacheEdges.interactive.spriteMaterialBackground)
          rightAngleSprite.scale.copy(new THREE.Vector3(spriteSize, spriteSize, spriteSize))

          rightAngleSprite.userData.excludeFromExport = true
          rightAngleSprite.clickable = true
          rightAngleSprite.transformable = false

          var rightAngleIcon = new THREE.Sprite(cacheEdges.interactive.spriteMaterial)
          rightAngleIcon.position.z = 0.01
          rightAngleSprite.add(rightAngleIcon)

          rightAngleSprite.onClick = function (_editor) {
            otherEdge.snapAlignment(_edge)
            //var nodeMoved = otherEdge.snapAlignment(_edge)
            // if (_editor && nodeMoved) {
            //   nodeMoved.onChange(_editor)
            // }
          }

          var pos = node.position.clone()

          //inset from end of edge
          var insetDirection = this.delta(node).normalize()
          var insetVector = insetDirection.multiplyScalar(offsetDistance)
          pos.add(insetVector)

          node.parent.localToWorld(pos)

          pos.add(otherEdgeVector) //offset from node in direction of other edge

          this.worldToLocal(pos)
          rightAngleSprite.position.copy(pos)
          editor.addObject(rightAngleSprite, this, false)

          this.rightAngles.push(rightAngleSprite)
        } catch (err) {
          // failed for some reason
          // there are many legitimate reasons why right angles may throw errors
          // skip and log the error quietly
          console.warn(err)
        }
      }, this)
    }

    if (editor.selected == this) {
      if (this.nodes[0]) {
        try {
          addRightAngleForNode.call(this, 0)
        } catch (err) {
          console.log(err)
        }
      }

      if (this.nodes[1]) {
        try {
          addRightAngleForNode.call(this, 1)
        } catch (err) {
          console.log(err)
        }
      }
    }
  },

  refreshLine: function () {
    if (!this.parent) {
      if (!this.ghostMode()) {
        console.log(
          'Error: calling edge.refreshLine() but it has no parent! Skip refreshLine() it will be called onAdd()'
        )
      }
      return
    }

    if (!this.nodes[0] || !this.nodes[1]) {
      console.log('Error: edge.refreshLine() because both nodes not supplied')
      return
    }
    var nodePositionsRelativeToEdge = [this.nodes[0].position, this.nodes[1].position].map(function (p) {
      return new THREE.Vector3().subVectors(p, this.centroid)
    }, this)

    this.geometry = new THREE.Geometry()
    this.geometry.excludeFromExport = true
    this.geometry.vertices = nodePositionsRelativeToEdge

    if (ViewHelper.facetEdgesDisplayMode() === OsEdge.EDGE_TYPE_DEFAULT && this.getFacets().length > 0) {
      this.material = cacheEdges.interactive.edgeMaterial
    } else if (ViewHelper.facetEdgesDisplayMode() === OsEdge.EDGE_TYPE_DEFAULT) {
      this.material = cacheEdges.interactiveIncomplete.edgeMaterial
    } else {
      this.material = cacheEdges.planset.edgeMaterial
    }

    if (!this.meshLine) {
      this.meshLine = new MeshLine()
    }

    var geometry = new THREE.Geometry()
    geometry.excludeFromExport = true
    geometry.vertices = nodePositionsRelativeToEdge

    this.meshLine.setGeometry(geometry)

    var currentMeshLineEdgeMaterial
    if (Designer.style === OsEdge.EDGE_TYPE_DEFAULT) {
      var edgeType

      if (this.getSystem()) {
        // Use real edge type
        edgeType = this.edgeType
      } else {
        // Show as grey if incomplete instead of edge type color

        // If edgeType is already set then we will never show as incomplete. This is also important when adding
        // edges for OsStructures, because they initially get added before the facet has been created, and they may
        // not be refreshed for a while, so they must be created with the correct visual style.
        edgeType = (!!this.edgeType && this.edgeType !== OsEdge.EDGE_TYPE_DEFAULT) || this.getFacets().length > 0 ? this.edgeType : 'incomplete'
      }

      if (!cacheEdges.interactive.meshLineEdgeMaterials[edgeType]) {
        cacheEdges.interactive.meshLineEdgeMaterials[edgeType] = new MeshLineMaterial({
          color: new THREE.Color(SetbacksHelper.edgeColors[edgeType]),
          sizeAttenuation: 1,
          lineWidth: 0.1,
          resolution: new THREE.Vector3(600, 600),
        })
      }
      currentMeshLineEdgeMaterial = cacheEdges.interactive.meshLineEdgeMaterials[edgeType]
    } else {
      if (!cacheEdges.planset.meshLineEdgeMaterials[this.edgeType]) {
        cacheEdges.planset.meshLineEdgeMaterials[this.edgeType] = new MeshLineMaterial({
          // color: new THREE.Color(SetbacksHelper.edgeColors[this.edgeType]),
          color: new THREE.Color(0x666666),
          sizeAttenuation: 0,
          lineWidth: 3,
          resolution: new THREE.Vector3(1000, 1000),
        })
        currentMeshLineEdgeMaterial = cacheEdges.planset.meshLineEdgeMaterials[this.edgeType]
      }
    }

    if (this.line) {
      if (!this.line.parent) {
        console.log('Warning: removing this.line but it has no parent!')
      } else {
        this.remove(this.line)
      }
    }

    this.line = new THREE.Mesh(this.meshLine.geometry, currentMeshLineEdgeMaterial)

    if (editor.selected) {
      if (editor.selected.uuid === this.uuid) {
        this.line.material = cacheEdges.selected
      } else if (editor.selected.type === 'OsFacet' && this.belongsToFacet(editor.selected)) {
        this.line.material = cacheEdges.selected
      } else if (this.belongsToGroup(editor.selected)) {
        this.line.material = cacheEdges.selected
      }
    }

    this.line.type = 'EdgeMeshLine'
    this.line.userData.excludeFromExport = true
    this.add(this.line)
    if (!this.line.parent.parent) {
      console.log('Warning:  this.line.parent.parent not set!')
    }
  },

  bearing: function (forceDirection, forceStartNode) {
    // forceDirection allows us to choose 'north' or 'south' to indeterminate direction
    // due to arbitrary ordering of edge.nodes

    //To avoid ambiguity, forceDirection='south' will never return exactly 270 or 90

    var rawBearing
    var forceStartNodeIndex, forceEndNodeIndex
    if (!forceStartNode) {
      forceStartNodeIndex = 0
      forceEndNodeIndex = 1
    } else {
      forceStartNodeIndex = this.nodes.indexOf(forceStartNode)
      forceEndNodeIndex = forceStartNodeIndex == 1 ? 0 : 1
    }

    try {
      rawBearing = Utils.bearing(this.nodes[forceStartNodeIndex].position, this.nodes[forceEndNodeIndex].position)
    } catch (e) {
      console.log('error calculating bearing', e)
      return null
    }

    // console.log(
    //   'bearing ... rawBearing',
    //   rawBearing,
    //   this.nodes[0].position,
    //   this.nodes[1].position
    // )

    var flip = false

    if (typeof forceDirection == 'undefined') {
      //
    } else if (forceDirection == 'north' && rawBearing < 270 && rawBearing > 90) {
      flip = true
    } else if (forceDirection == 'south' && (rawBearing >= 270 || rawBearing <= 90)) {
      flip = true
    }

    if (flip) {
      rawBearing = rawBearing + 180
    }

    return (rawBearing + 360) % 360
  },
  isFlat: function () {
    return SetbacksHelper.edgeTypeIsFlat(this.edgeType)
  },
  isAdjacent: function (edge) {
    //If adjacent, return the shared node, otherwise return false
    // console.log(this.nodes, edge.nodes)

    if (this == edge) {
      //same edge
      return false
    }

    if (edge.nodes.indexOf(this.nodes[0]) != -1) {
      return this.nodes[0]
    } else if (edge.nodes.indexOf(this.nodes[1]) != -1) {
      return this.nodes[1]
    }

    return false
  },

  snapAlignment: function (edgeToAlignWith) {
    // Move the distal node

    var sharedNode = this.isAdjacent(edgeToAlignWith)

    // console.log('sharedNode', sharedNode)

    // Find distal node of this edge (i.e. not the adjacent node)
    var nodeToMove = this.nodes[0] == sharedNode ? this.nodes[1] : this.nodes[0]
    // console.log('nodeToMove position', nodeToMove.position)

    var nodeToStay = edgeToAlignWith.nodes[0] == sharedNode ? edgeToAlignWith.nodes[1] : edgeToAlignWith.nodes[0]
    // console.log('nodeToStay position', nodeToStay.position)

    // If distal node not part of any other edge just rotate along the arc.
    var centerOfRotation = sharedNode.position
    // console.log('centerOfRotation', centerOfRotation)

    // Current bearing from sharedNode to distal node
    var rawBearing = Utils.bearing(
      new THREE.Vector2(centerOfRotation.x, centerOfRotation.y),
      new THREE.Vector2(nodeToMove.position.x, nodeToMove.position.y)
    )
    // console.log('rawBearing', rawBearing)

    var rawBearingReferenceEdge = Utils.bearing(
      new THREE.Vector2(nodeToStay.position.x, nodeToStay.position.y),
      new THREE.Vector2(centerOfRotation.x, centerOfRotation.y)
    )
    // console.log('rawBearingReferenceEdge', rawBearingReferenceEdge)

    var rawBearingRelativeToReferenceEdge = rawBearing - rawBearingReferenceEdge

    //snap to nearest 90 degrees
    var snapToNearest = 45
    var targetBearingRelative = Math.round(rawBearingRelativeToReferenceEdge / snapToNearest) * snapToNearest
    // console.log('targetBearingRelative', targetBearingRelative)

    //Otherwise find the most perpendicular edge and slide along that edge
    var bearingDiff = rawBearingRelativeToReferenceEdge - targetBearingRelative
    // console.log('bearingDiff', bearingDiff)

    //If distal point not on a line already (or on more than once other) then simply rotate around an arc
    //Otherwise set the distance by sliding along the other edge
    var newPositionWithFixedBearing = Utils.rotateAroundPoint(
      nodeToMove.position,
      new THREE.Vector3(0, 0, 1),
      bearingDiff * THREE.Math.DEG2RAD,
      centerOfRotation
    )

    var otherEdgesForNodeToMove = nodeToMove.getEdges(this)
    if (otherEdgesForNodeToMove.length == 1) {
      var otherEdgeForNodeToMoveAsLine3 = otherEdgesForNodeToMove[0].asLine3()

      //Find intersection of fixed bearing line and the other edge
      var fixedBearingLine = new THREE.Line3(centerOfRotation, newPositionWithFixedBearing)
      var intersectionOfFixedBearingLineAndOtherEdge = Utils.intersectLines(
        fixedBearingLine,
        otherEdgeForNodeToMoveAsLine3
      )
      editor.execute(new SetPositionCommand(nodeToMove, intersectionOfFixedBearingLineAndOtherEdge))
      //nodeToMove.position.copy(intersectionOfFixedBearingLineAndOtherEdge)
    } else {
      editor.execute(new SetPositionCommand(nodeToMove, newPositionWithFixedBearing))
      //nodeToMove.position.copy(newPositionWithFixedBearing)
    }

    return nodeToMove
  },

  // getContextMenuAction: function(_editor){
  //     _editor.select(this)
  //     Designer.uiRefs.PanelProperties.setState({panelActive:'EdgeType'})
  // },
  //
  getContextMenuItems: function (position, _editor) {
    var _this = this
    if (!_editor) {
      _editor = editor
    }

    var system = this.getSystem()

    if (system && system != _editor.selectedSystem) {
      // Belongs to a different/hidden system
      return []
    }

    // Ignore edges which are not editable directly
    if (this.isWallCorner || this.ghostMode()) {
      return []
    }

    var edgeTypesToDisplay = system
      ? OsEdge.wireTypes
      : Object.keys(SetbacksHelper.setbackDistances).filter((key) => key !== 'arrays')

    var menuItems = edgeTypesToDisplay.map(function (edgeType) {
      var starIfSelected = edgeType == this.edgeType ? ' *' : ''
      var boldIfSelected = edgeType == this.edgeType ? ' font-weight: bold; ' : ''
      var distanceInMeters = SetbacksHelper.getEdgeType(edgeType).setbackDistance

      var setbackDistanceForLabel
      if (distanceInMeters) {
        setbackDistanceForLabel = ' (' + distanceInMeters + 'm) '
        if (
          window.AccountHelper &&
          window.AccountHelper.getMeasurementUnits &&
          window.AccountHelper.getMeasurementUnits() === 'imperial'
        ) {
          setbackDistanceForLabel = ' (' + trimDecimalPlaces(metersToFeet(distanceInMeters), 1) + 'ft) '
        }
      } else {
        setbackDistanceForLabel = ''
      }

      var hotkeyIfAvailable = OsEdge.edgeTypeToHotkey[edgeType] ? '<span style="min-width: 18px; height: 18px; display: inline-block; line-height: 18px; border: 1px solid #929292; color: #929292; text-align: center;">⇧</span><span style="margin-left: 3px; min-width: 18px; height: 18px; display: inline-block; line-height: 18px; border: 1px solid #929292; color: #929292; text-align: center;">' + OsEdge.edgeTypeToHotkey[edgeType] + '</span>' : ''

      var edgeTypeLabel = (Utils.useEnglishLabelsWithoutTranslations()) ? Utils.capitalizeFirstLetter(edgeType.split('_').join(' ')) : window.translate(edgeType)

      return {
        label:
          '<div style="' +
          boldIfSelected +
          ' background-color: #' +
          SetbacksHelper.getEdgeType(edgeType).colorHex +
          '; width: 12px; height: 12px; float: left; margin: 1px 5px 0px 0px; border-radius: 6px; border: 1px solid #cdcdcd"></div> ' +
          window.translate('Edge type') +
          ': ' +
          edgeTypeLabel +
          setbackDistanceForLabel +
          starIfSelected + hotkeyIfAvailable
        ,
        useHTML: true,
        selected: edgeType == _this.edgeType,
        onClick: function () {
          if (_this.isManaged) {
            // do nothing
          } else {
            if (_this.isWire()) {
              OsEdge.setEdgeTypesForAllLinkedEdge(_this, edgeType)
            } else {
              _editor.execute(new window.SetEdgeTypeCommand(_this, edgeType))
            }
          }
        },
      }
    }, this)

    //@TODO: Allow injecting translate argument and using a translation with placeholders instead
    var label = window.translate(`Select ${this.getName()}`)

    menuItems.push({
      label: label,
      useHTML: false,
      selected: false,
      onClick: function () {
        if (_editor) {
          _editor.select(_this)
        }
      },
    })

    return menuItems
  },

  getLength: function () {
    try {
      const deltaFrom0 = this.delta(this.nodes[0])
      return deltaFrom0.length()
    } catch (e) {
      console.warn(e)
    }
    return 0
  },

  getLengthConstraint: function () {
    var edgeFacets = this.getFacets()
    if (
      edgeFacets.length == 2 &&
      edgeFacets[0].selectionDelegate.name == 'OsStructure' &&
      edgeFacets[0].vertices.length - edgeFacets[1].vertices.length == 0
    ) {
      var structure = edgeFacets[0].selectionDelegate // can also be index 1
      // handles the case when an edge is a ridge in a hip roof structure
      // in this case, the length of the edge should not exceed the length of the structure
      return structure.scale[['y', 'x'][structure.facets.indexOf(edgeFacets[0]) % 2]]
    }
    return Number.POSITIVE_INFINITY
  },

  setLength: function (targetSize, dispatchSignal = true) {
    currentSize = this.delta(this.nodes[0]).length()
    // if edge does NOT belong to a structure, handle the resize here
    // otherwise, just dispatch the signal and let the structure handle the resize
    if (!this.belongsToStructure()) {
      var deltaFrom0 = this.delta(this.nodes[0])
      var currentSize = deltaFrom0.length()
      var scale = targetSize / currentSize
      var deltaFrom0Half = deltaFrom0.clone().multiplyScalar(0.5 * scale)
      var centroid = this.getCentroid()

      var uuid = Utils.generateCommandUUIDOrUseGlobal()
      var node0Position = centroid.clone().sub(deltaFrom0Half)
      var node1Position = centroid.clone().add(deltaFrom0Half)
      editor.execute(new SetPositionCommand(this.nodes[0], node0Position, undefined, uuid))
      editor.execute(new SetPositionCommand(this.nodes[1], node1Position, undefined, uuid))
    }
    if (dispatchSignal) {
      editor.signals.objectChanged.dispatch(this, 'length')
    }
  },

  setVisible: function (visible) {
    if (this.visible === visible) return
    this.visible = visible
    this.nodes.forEach((node) => !!node && node.setVisible(visible))
  },

  getVisible: function () {
    return this.visible
  },

  setNode: function (node, index, linkNodes) {
    this.nodes[index] = node

    if (linkNodes !== false) {
      node.addToEdge(this)
    }
    this.refreshPosition()
  },

  setNodes: function (nodes, linkNodes) {
    this.setNode(nodes[0], 0, linkNodes)
    this.setNode(nodes[1], 1, linkNodes)
  },

  replaceNode: function (oldNode, newNode) {
    if (this.nodes[0] == oldNode) {
      oldNode.removeEdge(this)
      this.nodes[0] = newNode
      newNode.addToEdge(this)
    } else if (this.nodes[1] == oldNode) {
      this.nodes[1] = newNode
      oldNode.removeEdge(this)
      newNode.addToEdge(this)
    } else {
      console.log('Warning: replaceNode could not find oldNode')
    }
  },

  hasNode: function (node) {
    if (this.nodes.indexOf(node) != -1) {
      return true
    } else {
      return false
    }
  },

  hasNodes: function (nodes) {
    if (this.nodes.indexOf(nodes[0]) != -1 && this.nodes.indexOf(nodes[1]) != -1) {
      return true
    } else {
      return false
    }
  },

  getOtherNode: function (node) {
    var index = this.nodes.indexOf(node)
    if (index == -1) {
      return null
    } else if (index == 0) {
      return this.nodes[1]
    } else {
      //index==1
      return this.nodes[0]
    }
  },

  bothNodesValid: function () {
    return this.nodes.length == 2 && this.nodes[0] && this.nodes[1]
  },

  bothNodesFloating: function () {
    return (
      this.nodes.filter(function (n) {
        return n.floatingOnFacet
      }).length === 2
    )
  },

  vertexPositions: function () {
    return this.nodes.map(function (v) {
      return v.position
    })
  },

  getFacets: function () {
    if (!this.bothNodesValid()) {
      console.log('Warning: getFacets() called on edge where nodes.length !=2 or one node not set')
      return []
    }

    // Only return facets where both nodes on the edge belong to that facet
    // If only one end-node belongs to the facet then this edge does NOT belong to the facet, it's only a tangent
    //var allFacetsForNodes = [...new Set(this.nodes[0].getFacets().concat(this.nodes[1].getFacets()))]
    var allFacetsForNodes = Utils.getUnique(this.nodes[0].getFacets().concat(this.nodes[1].getFacets()))

    return allFacetsForNodes
      .map(function (f) {
        return this.belongsToFacet(f) ? f : null
      }, this)
      .filter(Boolean)
  },

  belongsToGroup: function (group) {
    return (
      group.type === 'OsGroup' &&
      group.objects.some((object) => {
        if (object.type === 'OsFacet') {
          return this.belongsToFacet(object)
        } else {
          return object.uuid === this.uuid
        }
      })
    )
  },

  belongsToFacet: function (facet) {
    if (!this.bothNodesValid()) {
      return false
    } else if (this.nodes[0].getFacets().indexOf(facet) != -1 && this.nodes[1].getFacets().indexOf(facet) != -1) {
      return true
    } else {
      return false
    }
  },

  belongsToStructure: function () {
    var edgeFacets = this.getFacets()
    if (edgeFacets.length > 0 && edgeFacets[0].selectionDelegate.name == 'OsStructure') {
      return true
    }
    return false
  },

  line2D: function () {
    return OsEdge.gf.createLineString(
      this.nodes.map(function (n) {
        return new jsts.geom.Coordinate(n.position.x, n.position.y)
      })
    )
  },

  asObject: function () {
    return {
      centroid: this.centroid ? this.centroid.toArray() : [],
      position: this.position ? this.position.toArray() : [],
      nodes: [
        this.nodes[0] ? [this.nodes[0].uuid, this.nodes[0].position.toArray()] : null,
        this.nodes[1] ? [this.nodes[1].uuid, this.nodes[1].position.toArray()] : null,
      ],
      edgeType: this.edgeType,
      hasFacets: this.getFacets().length > 0,
    }
  },

  refreshUserData: function () {
    this.userData.nodes = this.nodes.map(function (o) {
      if (o) {
        return o.uuid
      } else {
        // Ensure edges with nodes not set do not crash everything
        return null
      }
    })
    this.userData.edgeType = this.edgeType
  },

  applyUserData: function () {
    this.edgeType = this.userData.edgeType

    if (editor) {
      var uuidNode0 = this.userData.nodes[0]
      var node0 = editor.objectByUuid(uuidNode0)
      if (node0) {
        this.setNode(node0, 0, true)
      } else {
        console.log('#####Warning: Not not found with uuid: ' + uuidNode0)
      }

      var uuidNode1 = this.userData.nodes[1]
      var node1 = editor.objectByUuid(uuidNode1)
      if (node1) {
        this.setNode(node1, 1, true)
      } else {
        console.log('#####Warning: Not not found with uuid: ' + uuidNode1)
      }
    }
  },

  canDelete: function () {
    return true
  },

  confirmBeforeDelete: function () {
    return 'Are you sure you want to delete this edge? This may break existing facets.'
  },

  clearAssociatedObject: function (editor) {
    for (var i = this.nodes.length - 1; i >= 0; i--) {
      // Ensure this does not crash if either of the nodes are not populated using `this.nodes[i]?.`
      if (this.nodes[i]?.getEdges().length == 1) {
        if (this.nodes[i].parent) {
          editor.execute(new RemoveObjectCommand(this.nodes[i], true))
          //editor.removeObject(this.nodes[i])
        } else {
          console.log('Warning: Node not removed because parent is null, probably already removed')
        }
      }
    }
  },

  onRemove: function (editor) {
    if (editor.changingHistory) return
    // Always remove this edge from this.nodes[0].edges and this.nodes[1].edges, if present

    // Delete any nodes if they only belong to this edge
    // Reverse loop to avoid iteration & deletion at same time

    // Only do this if edge is explicitly being deleted, not if it's being deleted in before it is broken
    // We assume that if one of the nodes is already missing then it's broken (i.e. a node was deleted)

    // To explicity prevent deleting of nodes remove them first

    if (this.ghostMode()) {
      for (var i = this.nodes.length - 1; i >= 0; i--) {
        //remove edge from node regardless of whether node has parent
        var node = this.nodes[i]
        if (node) {
          node.removeEdge(this)
        }

        //remove associated ghost nodes
        if (node && this.nodes[i].parent && this.nodes[i].ghostMode()) {
          editor.removeObject(this.nodes[i], false)
        }
      }
      return
    }

    if (
      !this.nodes[0] ||
      !this.nodes[1] ||
      !this.nodes[0].parent ||
      !this.nodes[1].parent ||
      // Tricky! Need to also check if this edge is missing from nodes[n].edges which also indicates
      // a broken edge, required to allow removal of this from nodes[n].edges above
      this.nodes[0].edges.indexOf(this) == -1 ||
      this.nodes[1].edges.indexOf(this) == -1
    ) {
      console.log('Edge already broken, dont kill other node but remove this edge from it')

      var indexInNode0 = this.nodes[0] && this.nodes[0].edges && this.nodes[0].edges.indexOf(this)
      if (indexInNode0 !== null && indexInNode0 >= 0) {
        this.nodes[0].edges.splice(indexInNode0, 1)
      }

      var indexInNode1 = this.nodes[1] && this.nodes[1].edges && this.nodes[1].edges.indexOf(this)
      if (indexInNode1 !== null && indexInNode1 >= 0) {
        this.nodes[1].edges.splice(indexInNode1, 1)
      }

      //leave that node

      return
      // } else {
      //   console.log('Clear em out!!')
      //   console.log(this.nodes[0].parent)
      //   console.log(this.nodes[1].parent)
    }

    // If a node for this edge is no longer linked to any other edges then delete it
    // If linked to another edge, keep it
    // for (var i = this.nodes.length - 1; i >= 0; i--) {
    //   if (this.nodes[i].getEdges().length == 1) {
    //     if (this.nodes[i].parent) {
    //       //editor.execute(new RemoveObjectCommand(this.nodes[i], true))
    //       //editor.removeObject(this.nodes[i])
    //       // } else {
    //       //   console.log(
    //       //     'Warning: Node not removed because parent is null, probably already removed'
    //       //   )
    //     }
    //   }
    // }

    // Remove the deleted edge from nodes[n].edges
    this.nodes.forEach(function (n) {
      if (n) {
        editor.execute(new NodeRemoveEdgeCommand(n, this))
      }
    }, this)
    this.nodes = []

    ///@TODO: Skip during history undo/redo
    OsEdge.addMissingFacetsForEdgeNetwork(editor)
  },

  onChange: function (editor, isLoading) {
    if (!OsEdge.onChangeEnabled) {
      console.log('Skip onChange: this.onChangeEnabled is false')
      return
    }

    if (
      editor.controllers.CallbackStack &&
      editor.controllers.CallbackStack.active &&
      editor.controllers.CallbackStack.recordIfNewOrReturnFalse('OsEdge.onChange.' + this.uuid) === false
    ) {
      return
    }

    if (!this.centroid) {
      this.refreshPosition()
    }

    if (this.hasChanged()) {
      editor.uiPauseUntilComplete(
        function () {
          if (!this.centroid) {
            console.log('Warning: OsEdge.onChange() cannot use centroid')
          } else if (
            this.position.x != this.centroid.x ||
            this.position.y != this.centroid.y ||
            this.position.z != this.centroid.z
          ) {
            //copy into points and reset position
            this.nodes[0].position.add(this.position).sub(this.centroid)
            this.nodes[1].position.add(this.position).sub(this.centroid)
            this.centroid.copy(this.position)

            this.nodes[0].onChange(editor)
            this.nodes[1].onChange(editor)
            //change to position/centroid will trigger hasChanged below
            //this.onChange(editor)
          }

          this.refreshPosition()
          this.refreshLine()
          this.refreshRightAngles()

          // Edge type change can require an update to facet (e.g. for Azimuth detection)
          // This should not cause a blowout because we only trigger callback if edgeType has changed
          if (this.hasChanged(['edgeType'])) {
            this.getFacets().forEach(function (_facet) {
              _facet.onChange(editor)
            })
          }
        },
        this,
        'ui',
        'ui::OsEdge::onChange',
        false // do not fire ui refresh signal on resume
      )

      this.saveHash()
    }
  },

  onSelect: function (editor) {
    this.refreshLine()
    this.refreshRightAngles()
  },

  onDeselect: function (editor) {
    this.refreshLine()
    this.refreshRightAngles()
  },

  asLine3: function (startFromNode) {
    var n1, n2
    if (!this.bothNodesValid()) {
      console.log('Warning: both nodes not valid in edge.asLine3()')
      return
    } else if (typeof startFromNode === 'undefined') {
      n1 = this.nodes[0].position
      n2 = this.nodes[1].position
    } else if (startFromNode === 0 || startFromNode == this.nodes[0]) {
      n1 = this.nodes[0].position
      n2 = this.nodes[1].position
    } else if (startFromNode === 1 || startFromNode == this.nodes[1]) {
      n1 = this.nodes[1].position
      n2 = this.nodes[0].position
    } else {
      console.log('Warning: startFromNode not found in this.nodes in edge.asLine3()')
      return
    }
    return new THREE.Line3(n1, n2)
  },

  delta: function (startFromNode) {
    if (!this.bothNodesValid()) {
      return new THREE.Vector3()
    }
    return this.asLine3(startFromNode).delta(new THREE.Vector3())
  },

  duplicate: function (options) {
    var positionOffset = Utils.positionOffsetFromDuplicateOptions(options)
    var newEdge = new OsEdge(this)
    newEdge.copy(this)
    const newNode0 = new OsNode({
      ...this.nodes[0],
      position: new THREE.Vector3().copy(this.nodes[0].position).add(positionOffset),
    })
    const newNode1 = new OsNode({
      ...this.nodes[1],
      position: new THREE.Vector3().copy(this.nodes[1].position).add(positionOffset),
    })
    var uuid = Utils.generateCommandUUIDOrUseGlobal()
    var systemOrUndefined = this.getSystem()
    editor.execute(new AddObjectCommand(newNode0, systemOrUndefined, true, uuid))
    editor.execute(new AddObjectCommand(newNode1, systemOrUndefined, true, uuid))
    newEdge.replaceNode(this.nodes[0], newNode0)
    newEdge.replaceNode(this.nodes[1], newNode1)
    newEdge.refreshUserData()
    newEdge.children = []
    editor.execute(new AddObjectCommand(newEdge, systemOrUndefined, true, uuid))
  },

  getCentroid: function () {
    if (this.nodes.length != 2 || !this.nodes[0] || !this.nodes[1]) {
      return null
    }

    return new THREE.Vector3(
      (this.nodes[0].position.x + this.nodes[1].position.x) / 2,
      (this.nodes[0].position.y + this.nodes[1].position.y) / 2,
      (this.nodes[0].position.z + this.nodes[1].position.z) / 2
    )
  },
  getPlane: function (slope) {
    if (this.edgeSlope || this.edgeSlope === 0) {
      slope = this.edgeSlope
    }

    var vectorRelativeToOrigin = this.delta(0).normalize()
    var perpendicularRelativeToOrigin = new THREE.Vector3().crossVectors(up, vectorRelativeToOrigin).normalize()

    //Need to ensure we slope in the right direction. By convention, we slope away from the centroid of the polygon
    //@TODO: Do we need to handle multiple facets? We currently assume auto-facets can only belong to one facet
    var facet = this.getFacets()[0]
    var edgeCentroid = this.getCentroid()

    var perpendicularRelativeToOriginTiny = perpendicularRelativeToOrigin.clone().multiplyScalar(0.1)

    var polygon = facet.toTurfPolygon()
    var point = window.turf.point(new THREE.Vector3().addVectors(edgeCentroid, perpendicularRelativeToOriginTiny).toArray())
    if (window.turf.inside(point, polygon) === false) {
      var slopeMultiplier = 1
    } else {
      var slopeMultiplier = -1
    }

    var otherPointRelativeToOrigin = perpendicularRelativeToOrigin
      .clone()
      .applyAxisAngle(vectorRelativeToOrigin, -slope * THREE.Math.DEG2RAD * slopeMultiplier)
      .normalize()
    var otherPoint = otherPointRelativeToOrigin.clone().add(this.nodes[0].position) //either points is fine

    var plane = new THREE.Plane().setFromCoplanarPoints(this.nodes[0].position, this.nodes[1].position, otherPoint)

    //Plane direction is arbitrary, flip it if facing down
    if (plane.normal.dot(up) < 0) {
      plane.negate()
    }

    return plane
  },
  getSummary: function () {
    try {
      return 'Length: ' + Math.round(10 * this.delta().length()) / 10 + 'm'
    } catch (err) {
      return 'Summary not available'
    }
  },

  applyGhostMode: function (value) {
    // if(value){
    //     this.material = ...;
    // }else{
    //     this.material = ...;
    // }
  },
  ghostMode: ObjectBehaviors.handleGhostModeBehavior,
  forceAnnotationInMeters: null,
  getAnnotation: function () {
    try {
      var measurementUnits = 'metric'

      if (AccountHelper && AccountHelper.getMeasurementUnits && AccountHelper.getMeasurementUnits()) {
        measurementUnits = AccountHelper.getMeasurementUnits()
      } else if (AccountHelper && AccountHelper.getOrgCountryIso2() === 'US') {
        measurementUnits = 'imperial'
      }

      var length

      if (this.forceAnnotationInMeters) {
        length = this.forceAnnotationInMeters
      } else {
        length = this.delta().length()
      }

      if (!measurementUnits || measurementUnits === 'imperial') {
        label = (length * 3.28084).toFixed(1) + ' ft'
      } else {
        label = length.toFixed(2) + 'm'
      }

      var position = this.getCentroid()
      if (!position) {
        return null
      } else {
        return {
          position: this.getCentroid(),
          content: label,
        }
      }
    } catch (err) {
      console.warn(err)
      return null
    }
  },
  handleMouse: function (isOver) {
    if (isOver) {
      if (editor && editor.controllers && editor.controllers.Annotation) {
        editor.controllers.Annotation.handleObjectSelected(null, this)
      }
    }
  },
  handleMouseBehavior: ObjectBehaviors.handleMouseBehavior,
  changeAffectsModuleGrids: function (systemUuid) {
    var facets = this.getFacets()
    for (var i = 0; i < facets.length; i++) {
      if (facets[i].changeAffectsModuleGrids(systemUuid)) {
        return true
      }
    }
    return false
  },
  extrudeToFacet: function (target, structure, distance) {
    // if distance is specified, target is only used to determine direction
    // otherwise target also determines the distance
    var line = this.asLine3()

    var directionToOffset = this.delta()
      .applyAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI / 2)
      .normalize()

    if (target && !distance) {
      distance = Math.abs(line.closestPointToPoint(target, true, new THREE.Vector3()).distanceTo(target))
    }

    var offset = directionToOffset.multiplyScalar(distance)

    // Flip if pointing away from target
    if (
      Math.abs(line.closestPointToPoint(target, true, new THREE.Vector3()).add(offset).distanceTo(target)) > distance
    ) {
      offset.multiplyScalar(-1)
    }

    var rings, setEdgeTypesAtLocations

    if (structure === 'hip_1') {
      //move nodes up 1 story
      this.nodes[0].position.z = 3
      this.nodes[1].position.z = 3

      // regenerate line at new elevation
      line = this.asLine3()

      var offsetHalf = offset.clone().multiplyScalar(0.5)

      var hipInset = 3

      var hipInsets = [
        this.delta().normalize().multiplyScalar(hipInset),
        this.delta().normalize().negate().multiplyScalar(hipInset),
      ]

      var ridgePoints = [
        new THREE.Vector3().addVectors(line.start, offsetHalf).add(new THREE.Vector3(0, 0, 2)).add(hipInsets[0]),
        new THREE.Vector3().addVectors(line.end, offsetHalf).add(new THREE.Vector3(0, 0, 2)).add(hipInsets[1]),
      ]

      rings = [
        //main faces
        [line.start.toArray(), ridgePoints[0].toArray(), ridgePoints[1].toArray(), line.end.toArray()],
        [
          new THREE.Vector3().addVectors(line.start, offset).toArray(),
          ridgePoints[0].toArray(),
          ridgePoints[1].toArray(),
          new THREE.Vector3().addVectors(line.end, offset).toArray(),
        ],

        //hips
        [line.start.toArray(), ridgePoints[0].toArray(), new THREE.Vector3().addVectors(line.start, offset).toArray()],
        [line.end.toArray(), ridgePoints[1].toArray(), new THREE.Vector3().addVectors(line.end, offset).toArray()],
      ]

      setEdgeTypesAtLocations = []
    } else if (structure === 'aframe_1') {
      //move nodes up 1 story
      this.nodes[0].position.z = 3
      this.nodes[1].position.z = 3

      // regenerate line at new elevation
      line = this.asLine3()

      var offsetHalf = offset.clone().multiplyScalar(0.5)

      var ridgePoints = [
        new THREE.Vector3().addVectors(line.start, offsetHalf).add(new THREE.Vector3(0, 0, 2)),
        new THREE.Vector3().addVectors(line.end, offsetHalf).add(new THREE.Vector3(0, 0, 2)),
      ]

      rings = [
        [line.start.toArray(), ridgePoints[0].toArray(), ridgePoints[1].toArray(), line.end.toArray()],
        [
          new THREE.Vector3().addVectors(line.start, offset).toArray(),
          ridgePoints[0].toArray(),
          ridgePoints[1].toArray(),
          new THREE.Vector3().addVectors(line.end, offset).toArray(),
        ],
      ]

      setEdgeTypesAtLocations = []
    } else if (structure === 'flat_1') {
      //move nodes up 1 story
      this.nodes[0].position.z = 3
      this.nodes[1].position.z = 3

      // regenerate line at new elevation
      line = this.asLine3()

      rings = [
        [
          line.start.toArray(),
          new THREE.Vector3().addVectors(line.start, offset).toArray(),
          new THREE.Vector3().addVectors(line.end, offset).toArray(),
          line.end.toArray(),
        ],
      ]

      // var setEdgeTypesAtLocations = [{
      //   position: [15, 2.5, 5],
      //   edgeType: 'ridge'
      // }];

      setEdgeTypesAtLocations = []
    }

    return Utils.buildRoofFromRingsAndEdgeTypes(editor, 1, rings, setEdgeTypesAtLocations)
  },
  transformCoordinateSpace: function () {
    return new THREE.Euler(0, 0, -this.bearing() * THREE.Math.DEG2RAD, 'XYZ')
  },
  adjacentEdges: function () {
    var _this = this
    var edges = []
    this.nodes.filter(Boolean).forEach((node) => {
      node.getEdges().forEach((edge) => {
        if (edge !== _this) {
          edges.push(edge)
        }
      })
    })
    return edges
  },
  distanceTo: function (position) {
    return this.asLine3().closestPointToPoint(position, true, new THREE.Vector3()).distanceTo(position)
  },
})

OsEdge.onChangeEnabled = true

OsEdge.createFromNodes = function (nodes) {
  return new OsEdge({ nodes: nodes })
}

OsEdge.getEdgeWithNodes = function (edges, nodes) {
  for (var i = 0; i < edges.length; i++) {
    if (edges[i].hasNodes(nodes)) {
      return edges[i]
    }
  }
  return null
}

OsEdge.closestEdgeToPosition = function (edges, position) {
  var closestEdgeIndex = 0
  var closestDistance = 100000

  _.range(edges.length).forEach(function (edgeIndex) {
    var distanceToEdge = edges[edgeIndex]
      .asLine3()
      .closestPointToPoint(position, true, new THREE.Vector3())
      .distanceTo(position)

    if (distanceToEdge < closestDistance) {
      closestDistance = distanceToEdge
      closestEdgeIndex = edgeIndex
    }
  })

  return [edges[closestEdgeIndex], closestDistance]
}

OsEdge.longestEdge = function (edges) {
  return _.maxBy(edges, function (e) {
    return e.delta().length()
  })
}

OsEdge.hasFlatGutterEdge = function (edges) {
  for (var i = 0, l = edges.length; i < l; i++) {
    if (edges[i].edgeType == 'flat_gutter') {
      return true
    }
  }
  return false
}

OsEdge.addMissingFacetsForEdgeNetworkIsRunning = false

OsEdge.addMissingFacetsForEdgeNetwork = function (editor, isSplitEdge, commandUUID) {
  if (editor.changingHistory == true) {
    console.log(
      'Skipping OsEdge.addMissingFacetsForEdgeNetwork because editor.changingHistory==true. Other actions will handle these updates'
    )
    return
  }

  if (OsEdge.addMissingFacetsForEdgeNetworkIsRunning == true) {
    console.log('OsEdge.addMissingFacetsForEdgeNetworkIsRunning abort')
    return
  }

  OsEdge.addMissingFacetsForEdgeNetworkIsRunning = true

  var polygonsWithoutFacet

  try {
    var nodes = editor.filter('type', 'OsNode').filter(function (o) {
      return !o.ghostMode() && !o.isManagedByParent() && !o.edges.every(e => e.isWire())
    })

    var x = OsEdge.findMissingFacetsInEdgeNetwork(
      nodes,
      editor.filter('type', 'OsEdge').filter(function (o) {
        return !o.ghostMode() && !o.isManagedByParent() && !o.isWire()
      }),
      editor.filter('type', 'OsFacet').filter(function (o) {
        return !o.isManaged && !o.isLocked
      })
    )

    polygonsWithoutFacet = x[0]
    var facetsWithoutPolygons = x[1]

    var newFacet
    polygonsWithoutFacet.forEach(function (p) {
      // console.log('create polygonsWithoutFacet')

      //Don't update facetCreatedFlag if we are only splitting the edge of an existing facet
      if (isSplitEdge !== true) {
        OsEdge.facetCreatedFlag = true
      }
      newFacet = new OsFacet({
        nodes: OsNode.nodesForPolygon(p, nodes),
        roofTypeId: window.editor?.scene?.roofTypeId() || null,
        wallTypeId: window.editor?.scene?.wallTypeId() || null,
      })
      editor.execute(new AddObjectCommand(newFacet, undefined, false, commandUUID))

      newFacet.onChange(editor)

      // Refresh all edges now that they belong to a facet
      newFacet.getEdges().forEach(function (e) {
        e.refreshLine()
      })
    })

    //Don't delete chordedCycles first because that may also delete nodes before they belong to another facet
    facetsWithoutPolygons.forEach(function (facetWithoutPolygon) {
      //find corresponding facet if it exists
      //if found delete it because it's now redundant.
      //@todo: transfer the properties into the new facets which replace this so they are not lost
      // console.log('remove facetWithoutPolygon')
      //console.log('remove facetWithoutPolygon', facetWithoutPolygon)
      editor.execute(new RemoveObjectCommand(facetWithoutPolygon, false, undefined, commandUUID))
    })
  } catch (err) {
    console.log('OsEdge.addMissingFacetsForEdgeNetwork error', err)
  }

  OsEdge.addMissingFacetsForEdgeNetworkIsRunning = false

  //return whether any facets were created
  return polygonsWithoutFacet && polygonsWithoutFacet.length > 0
}

OsEdge.findMissingFacetsInEdgeNetwork = function (nodes, edges, facets) {
  var lines = []

  // [
  //     turf.lineString( [ [0,0], [0,10] ] ),
  //     turf.lineString( [ [0,10], [30,10] ] ),
  //     turf.lineString( [ [30,10], [30,0] ] ),
  //     turf.lineString( [ [30,0], [15,0] ] ),
  //     turf.lineString( [ [15,0], [10,0] ] ),
  //     turf.lineString( [ [10,0], [0,0] ] ),
  //
  //     turf.lineString( [ [10,0], [15,5] ] ),
  //     turf.lineString( [ [15,5], [15,0] ] ),
  // ]
  //
  // var linesCollection = turf.featureCollection(lines);
  // var polygons = turf.polygonize(linesCollection)
  // assert.equal(polygons.length, 2)

  var uniqueEdges = []
  edges.forEach(function (e) {
    if (e.nodes[0] == e.nodes[1]) {
      console.log('Really? Edge with both nodes the same... skip it')
      return //continue
    }

    var alreadyExistsInUniqueEdges =
      uniqueEdges
        .map(function (ue) {
          return OsEdge.getEdgeWithNodes(uniqueEdges, [e.nodes[0], e.nodes[1]])
        })
        .filter(Boolean).length > 1

    if (alreadyExistsInUniqueEdges) {
      console.log('Warning: duplicate Edge found... should we delete it?')
    } else {
      uniqueEdges.push(e)
    }
  }, this)

  uniqueEdges.forEach(function (edge) {
    //console.log('OsEdge', edge)
    if (edge.nodes.length == 2) {
      lines.push(
        window.turf.lineString([
          [edge.nodes[0].position.x, edge.nodes[0].position.y],
          [edge.nodes[1].position.x, edge.nodes[1].position.y],
        ])
      )
    }
  })

  //wrap back to first point
  // lines.push( turf.lineString( [
  //     [edges[edges.length-1].nodes[1].position.x,edges[edges.length-1].nodes[1].position.y],
  //     [edges[0].nodes[0].position.x,edges[0].nodes[0].position.y]
  //  ] ) )

  //console.log('lines', lines)

  var polygonsWithoutFacet = []
  var polygons = { features: [] }

  //if less than 3 lines we can't possibly have any polygons (with or without facets)
  if (lines.length >= 3) {
    polygons = window.turf.polygonize(window.turf.featureCollection(lines))

    // Check if any polygons don't have a corresponding facet
    polygons.features.forEach(function (p) {
      if (
        facets
          .map(function (facet) {
            return facet.matchesPolygonCoordinates(p.geometry.coordinates[0])
          })
          .filter(Boolean).length == 0
      ) {
        //no facet for this polygon
        polygonsWithoutFacet.push(p)
      }
    })
  }

  // @todo: Find any facets which don't have a corresponding polygon, they should be removed?
  // @todo: optimize this by culling polygons found above

  var facetsWithoutPolygons = []
  facets.forEach(function (facet) {
    if (
      polygons.features
        .map(function (p) {
          return facet.matchesPolygonCoordinates(p.geometry.coordinates[0])
        })
        .filter(Boolean).length == 0
    ) {
      facetsWithoutPolygons.push(facet)
    }
  })

  return [polygonsWithoutFacet, facetsWithoutPolygons]
}

OsEdge.trimWithPlanes = function (line3, trimAbovePlanes, trimBelowPlanes) {
  // Note: this modifies the original line3
  // Beware that some edges will be flat with same z value for both ends
  var intersection

  // Flip the start and end so it always starts at the lower end
  if (line3.start.z > line3.end.z) {
    var _start = line3.start.toArray()
    var _end = line3.end.toArray()
    line3.start.fromArray(_end)
    line3.end.fromArray(_start)
  }

  if (trimAbovePlanes) {
    trimAbovePlanes.forEach(function (plane) {
      if (!line3.start || !line3.end) {
        // skip iterating over the line if already killed
        return
      }
      intersection = plane.intersectLine(line3, new THREE.Vector3())
      if (intersection) {
        // We cannot compare z value for intersection.z with line3.end.z because edges can be flat.
        // Instead, check which side of the plane the end node falls on using signed distance from line3.end to plane
        // We must dynamically detect either line3.start or line3.end based on whichever is above the plane
        if (plane.distanceToPoint(line3.end) > 0) {
          line3.end.copy(intersection)
        } else if (plane.distanceToPoint(line3.start) > 0) {
          line3.start.copy(intersection)
        }
      } else if (plane.distanceToPoint(line3.start) > 0) {
        // No intersection with plane, if either point is above the plane then we completely skip/kill the line
        // Missing start or end indicates the line should be skipped

        line3.start = null
        line3.end = null
      }
    })
  }

  if (trimBelowPlanes) {
    trimBelowPlanes.forEach(function (plane) {
      if (!line3.start || !line3.end) {
        // skip iterating over the line if already killed
        return
      }
      intersection = plane.intersectLine(line3, new THREE.Vector3())
      // We cannot compare z value for intersection.z with line3.end.z because edges can be flat.
      // Instead, check which side of the plane the end node falls on using signed distance from line3.end to plane
      // We must dynamically detect either line3.start or line3.end based on whichever is above the plane
      if (intersection) {
        if (plane.distanceToPoint(line3.end) < 0) {
          line3.end.copy(intersection)
        } else if (plane.distanceToPoint(line3.start) < 0) {
          line3.start.copy(intersection)
        }
      } else if (plane.distanceToPoint(line3.start) < 0) {
        // No intersection with plane, if either point is above the plane then we completely skip/kill the line
        // Missing start or end indicates the line should be skipped

        line3.start = null
        line3.end = null
      }
    })
  }

  return line3
}

OsEdge.angleBetweenLines = function (line0, line1, distanceFromNode, force2D) {
  // Use law of cosines. See https://www.mathsisfun.com/algebra/trig-solving-sss-triangles.html
  if (force2D) {
    line0 = line0.clone()
    line0.start.z = 0
    line0.end.z = 0

    line1 = line1.clone()
    line1.start.z = 0
    line1.end.z = 0
  }

  var B = line0.distance()
  var C = line1.distance()

  var distalPoint0, anglePoint
  if (!line1.start.equals(line0.start) && !line1.end.equals(line0.start)) {
    distalPoint0 = line0.start
    anglePoint = line0.end
  } else {
    distalPoint0 = line0.end
    anglePoint = line0.start
  }
  var distalPoint1 = line1.start.equals(anglePoint) ? line1.end : line1.start

  var line2 = new THREE.Line3(distalPoint0, distalPoint1)
  var A = line2.distance()

  var cosAlpha = (Math.pow(B, 2) + Math.pow(C, 2) - Math.pow(A, 2)) / (2 * B * C)
  var alpha = Math.acos(cosAlpha)

  // angle to bisector
  var a0 = new THREE.Line3(anglePoint, distalPoint0).delta(new THREE.Vector3()).normalize()
  var a1 = new THREE.Line3(anglePoint, distalPoint1).delta(new THREE.Vector3()).normalize()
  var bisectorScaled = new THREE.Vector3().addVectors(a0, a1).multiplyScalar(0.5 * distanceFromNode)

  return {
    angle: alpha * THREE.Math.RAD2DEG,
    position: new THREE.Vector3().addVectors(anglePoint, bisectorScaled),
    directionLine0: a0,
    directionLine1: a1,
  }
}

OsEdge.getSharedNode = function (edge0, edge1) {
  if (edge0.nodes[0] === edge1.nodes[0]) {
    return edge0.nodes[0]
  } else if (edge0.nodes[0] === edge1.nodes[1]) {
    return edge0.nodes[0]
  } else if (edge0.nodes[1] === edge1.nodes[0]) {
    return edge0.nodes[1]
  } else if (edge0.nodes[1] === edge1.nodes[1]) {
    return edge0.nodes[1]
  } else {
    return null
  }
}

OsEdge.wireTypes = ['black', 'red', 'blue', 'white', 'green']

OsEdge.getAllLinkedEdges = (edgeOrNode) => {
  /*
  Not totally optimized but sufficient
  */
  var edges = []
  if (edgeOrNode.type == 'OsEdge') {
    edges = [edgeOrNode]
  } else if (edgeOrNode.getEdges()[0]) {
    edges = [edgeOrNode.getEdges()[0]]
  } else {
    return []
  }

  var newEdgeFound = true
  while (newEdgeFound) {
    newEdgeFound = false
    edges.forEach((edge) => {
      edge.adjacentEdges().forEach((adjacentEdge) => {
        if (!edges.includes(adjacentEdge)) {
          newEdgeFound = true
          edges.push(adjacentEdge)
        }
      })
    })
  }
  return edges
}

OsEdge.setEdgeTypesForAllLinkedEdge = function (edge, edgeType) {
  var edges = OsEdge.getAllLinkedEdges(edge)

  var cmds = []
  edges.forEach((edge) => {
    cmds.push(new window.SetEdgeTypeCommand(edge, edgeType))
  })
  editor.execute(new MultiCmdsCommand(cmds))
  window.globalCommandUUID = Utils.generateCommandUUIDOrUseGlobal()
}

OsEdge.edgeTypeToHotkey = {
  default: 'D',
  gutter: 'G',
  ridge: 'R',
  valley: 'V',
  hip: 'H',
  rake: 'A', // note we use A for rake because R is already taken by ridge and K is annoying to type
  shared: 'S',
  flat_gutter: 'F',
}

OsEdge.hotkeyToEdgeType = (hotkeyString) => {
  return Object.keys(OsEdge.edgeTypeToHotkey).find((edgeType) => OsEdge.edgeTypeToHotkey[edgeType] === hotkeyString)
}


/**
 * Recursively subtracts polygons from an initial list of LineStrings.
 *
 * @param {jsts.geom.LineString[]} lineStrings - List of LineStrings to be processed.
 * @param {jsts.geom.Polygon[]} polygons - List of polygons to subtract from the LineStrings.
 * @returns {jsts.geom.LineString[]} - Resulting list of LineStrings after all subtractions.
 */
OsEdge.subtractPolygonsFromLineStrings = (lineStrings, polygons) => {
  // Base case: if there are no polygons left to process, return the lineStrings as-is
  if (polygons.length === 0) {
    return lineStrings;
  }

  // Take the first polygon and prepare to apply it to each LineString
  const [currentPolygon, ...remainingPolygons] = polygons;
  const newLineStrings = [];

  // Iterate over each LineString, subtracting the current polygon
  for (const lineString of lineStrings) {
    const result = lineString.difference(currentPolygon);

    // Handle case where result is MultiLineString or a single LineString
    if (result instanceof jsts.geom.MultiLineString) {
      for (let i = 0; i < result.getNumGeometries(); i++) {
        const subLineString = result.getGeometryN(i);
        if (subLineString instanceof jsts.geom.LineString) {
          newLineStrings.push(subLineString);
        }
      }
    } else if (result instanceof jsts.geom.LineString) {
      newLineStrings.push(result);
    }
  }

  // Recurse with the updated list of LineStrings and remaining polygons
  return OsEdge.subtractPolygonsFromLineStrings(newLineStrings, remainingPolygons);
}

OsEdge.dormerFacetShapesAll = () => {
  return editor.filter('type', 'OsFacet')
    .filter(facet => !!facet.getStructure()?.isDormer())
    .map(facet => ({
      facet: facet,
      shape: facet.shapesWithSetbacksJSTS(false).facetShape
    }))
}

OsEdge.edgeSubtractDormersAsLineStrings = (edge, dormerFacetShapesAll) => {

  const facetUuidsForEdge = edge.getFacets().map(f => f.uuid)

  // Create a line string for this edge
  const lineString = OsEdge.gf.createLineString([
    new jsts.geom.Coordinate(edge.nodes[0].position.x, edge.nodes[0].position.y),
    new jsts.geom.Coordinate(edge.nodes[1].position.x, edge.nodes[1].position.y),
  ])

  // Subtract dormers from the line string which produces a list of linestrings depending on how/if they overlap
  // Exclude the edge's own facets from the list of facets to subtract otherwise all edges would be fully removed
  const dormerFacetShapes = dormerFacetShapesAll
    .filter(dormerFacetShape => !facetUuidsForEdge.includes(dormerFacetShape.facet.uuid))
    .map(dormerFacetShape => dormerFacetShape.shape)

  return OsEdge.subtractPolygonsFromLineStrings([lineString], dormerFacetShapes)

}

OsEdge.edgesSubtractDormersAndRun = (edges, func) => {
  /**
   * func will be run for all LineStrings that are the result of subtracting dormers from the edges
   */

  // Get all dormers once to avoid recalculating them for each edge, including building the shape which can
  // be expensive

  // Only subtract dormers that are floating "above" this edge, which can be determine by:
  // iterate up through the floating-parents of the target dormer. If we find one of the parents is a facet
  // and the edge belongs to that facet then we can subtract that dormer from this edge.
  const isFloatingAboveEdge = (dormerFacet, edge) => {
    var floatingOn = dormerFacet.getStructure()?.floatingOnFacet
    while (floatingOn) {
      if (floatingOn.type === 'OsFacet' && floatingOn.getEdges().includes(edge)) {
        return true
      }
      floatingOn = floatingOn.getStructure()?.floatingOnFacet
    }
    return false
  }

  const dormerFacetShapesAll = OsEdge.dormerFacetShapesAll()

  edges.filter(edge => edge.getFacets().length > 0).forEach((edge) => {

    var dormerFacetShapesFloatingOnThisEdge = dormerFacetShapesAll.filter(dormerFacetShape => isFloatingAboveEdge(dormerFacetShape.facet, edge))

    OsEdge.edgeSubtractDormersAsLineStrings(edge, dormerFacetShapesFloatingOnThisEdge).forEach((lineString) => {
      if (lineString.points.coordinates.length > 0) {
        func(edge, lineString)
      } else {
        console.log('OsEdge.edgesSubtractDormersAndRun: Invalid lineString')
      }
    })

  })

}

OsEdge.EDGE_TYPE_DEFAULT = 'default'

