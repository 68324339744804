/**
 * @author dforrer / https://github.com/dforrer
 * Developed as part of a project at University of Applied Sciences and Arts Northwestern Switzerland (www.fhnw.ch)
 */

/**
 * @constructor
 */

var SetSkewCommand = function (ViewHelper, bearing, magnitudeInDegrees, oldValue, fraction, switchVisible) {
  Command.call(this)
  this.type = 'SetSkewCommand'
  this.name = 'Set Skew Command'
  this.timeStamp = Date.now()
  this.ViewHelper = ViewHelper
  this.updatable = !Boolean(switchVisible)
  this.bearing = bearing
  this.fraction = fraction
  this.oldFraction = oldValue.fraction
  this.oldBearing = oldValue.bearing
  this.switchVisible = switchVisible
  this.oldMagnitudeInDegrees = oldValue.magnitudeInDegrees
  this.magnitudeInDegrees = magnitudeInDegrees
  this.commandUUID = Utils.generateCommandUUIDOrUseGlobal()

  // This only works in UX1 so we will just block undo.
  this.UNDO_NOT_SUPPORTED = true
}

SetSkewCommand.prototype = {
  execute: function () {
    if (this.switchVisible) {
      // window.Designer.callUi('PanelFixSkew', this.switchVisible)
      // window.Designer.uiRefs['PanelFixSkew'][this.switchVisible]()
    } else {
      this.ViewHelper.setSkew(this.bearing, this.magnitudeInDegrees)
      // if (editor.changingHistory) {
      // window.Designer?.setUiState('PanelFixSkew', {
      //   isOpen: true,
      // })
      // window.Designer.uiRefs['PanelFixSkew'].setState({
      //   bearing: this.bearing,
      //   magnitudeInDegrees: this.magnitudeInDegrees,
      // })
      // }
      // if (this.editor.changingHistory) {
      //   window.Designer?.setUiState('DirectionAndMagnitudeField', {
      //     fraction: this.fraction,
      //   })
      // window.Designer.uiRefs['DirectionAndMagnitudeField'].setState({
      //   fraction: this.fraction,
      // })
      // }
    }
  },

  undo: function () {
    if (this.switchVisible) {
      // var visible = this.switchVisible === 'open' ? 'close' : 'open'
      // window.Designer.callUi('PanelFixSkew', visible)
      // window.Designer.uiRefs['PanelFixSkew'][visible]()
    } else {
      this.ViewHelper.setSkew(this.oldBearing, this.oldMagnitudeInDegrees)

      // window.Designer?.setUiState('PanelFixSkew', {
      //   bearing: this.oldBearing,
      //   magnitudeInDegrees: this.oldMagnitudeInDegrees,
      // })
      // window.Designer?.setUiState('DirectionAndMagnitudeField', {
      //   fraction: this.oldFraction,
      // })

      // window.Designer.uiRefs['PanelFixSkew'].setState({
      //   bearing: this.oldBearing,
      //   magnitudeInDegrees: this.oldMagnitudeInDegrees,
      // })
      // window.Designer.uiRefs['DirectionAndMagnitudeField'].setState({
      //   fraction: this.oldFraction,
      // })
    }
  },

  update: function (cmd) {
    this.bearing = cmd.bearing
    this.magnitudeInDegrees = cmd.magnitudeInDegrees
  },

  toJSON: function () {},

  fromJSON: function (parsedCmd) {
    Command.prototype.fromJSON.call(this, parsedCmd)
  },
}
