window.ModuleGridLayoutDualTilt = [
  {
    version: '1.0',
    changelogs: ['Initial version'],
    formula: (params) => {
      const {
        moduleWidth,
        moduleHeight,
        moduleCoordX,
        moduleCoordY,

        modulesPerCol,
        modulesPerRow,
        moduleSpacingX,
        moduleSpacingY,
        groupSpacingX,
        groupSpacingY,
        colSpacingOffset,
        rowSpacingOffset,
        computeColSpacingFunc,
        computeRowSpacingFunc,
        totalInterModuleSpaceX,

        gridOriginOffset,
        groundClearance,
        xOffset,
        extraSlope,
        rotationX,
        forshortenedDistancePerPanelY,

        applyToObject,
      } = params

      let { totalInterModuleSpaceY } = params

      let xOffsetToApply = moduleCoordY % 2 !== 0 ? xOffset : 0
      let colSpacingsToApply = computeColSpacingFunc(moduleCoordX)
      let rowSpacingsToApply = computeRowSpacingFunc(moduleCoordY)

      totalInterModuleSpaceY = moduleSpacingY

      let xCoordRelative = colSpacingOffset - moduleCoordX
      let xAdjustment = xCoordRelative < 0 ? totalInterModuleSpaceX : 0

      if (xCoordRelative < 0) xCoordRelative += 1
      let indexWithinGroupX = xCoordRelative % modulesPerCol

      let yCoordRelative = rowSpacingOffset - moduleCoordY
      let yAdjustment = yCoordRelative < 0 ? totalInterModuleSpaceY : 0
      let isNegativeOffset = yCoordRelative < 0

      if (yCoordRelative < 0) yCoordRelative += 1
      let indexWithinGroupY = yCoordRelative % modulesPerRow

      let positionZ = 0
      if (extraSlope > 0) {
        positionZ += Math.sin(extraSlope * THREE.Math.DEG2RAD) * (moduleHeight / 2)
      }
      positionZ += groundClearance

      const indexWithinGroupYAbs = Math.abs(indexWithinGroupY)
      let ridgeSpacingOffset = indexWithinGroupYAbs >= modulesPerRow / 2 ? moduleSpacingY : 0
      // if the current module is on the negative side of the rowspacingoffset,
      // the spacing offset should go to the negative direction, otherwise
      // the panels will go closer, not further, from each other
      ridgeSpacingOffset = isNegativeOffset ? -ridgeSpacingOffset : ridgeSpacingOffset

      let positionX =
        -moduleCoordX * moduleWidth +
        indexWithinGroupX * moduleSpacingX +
        -colSpacingsToApply * totalInterModuleSpaceX +
        groupSpacingX * -colSpacingsToApply +
        xOffsetToApply +
        xAdjustment

      let positionY =
        -moduleCoordY * moduleHeight +
        ridgeSpacingOffset +
        -rowSpacingsToApply * totalInterModuleSpaceY +
        groupSpacingY * -rowSpacingsToApply -
        forshortenedDistancePerPanelY * (rowSpacingOffset - moduleCoordY) +
        yAdjustment

      const position = new THREE.Vector3(positionX, positionY, positionZ).add(gridOriginOffset)
      const rotation = new THREE.Euler(rotationX, 0, 0, 'ZYX')

      const flip = rowSpacingOffset - moduleCoordY < 0
      if (indexWithinGroupYAbs >= modulesPerRow / 2) {
        position.z +=
          (modulesPerRow - 1 - indexWithinGroupYAbs) * (Math.sin(extraSlope * THREE.Math.DEG2RAD) * moduleHeight)
        rotation.set(rotationX, 0, (flip ? 0 : 180) * THREE.Math.DEG2RAD, 'ZYX')
      } else {
        position.z += indexWithinGroupYAbs * (Math.sin(extraSlope * THREE.Math.DEG2RAD) * moduleHeight)
        rotation.set(rotationX, 0, (flip ? 180 : 0) * THREE.Math.DEG2RAD, 'ZYX')
      }

      if (applyToObject) {
        applyToObject.position.copy(position)
        applyToObject.rotation.copy(rotation)
      }

      return {
        position: position,
        rotation: rotation,
      }
    },
  },
]
