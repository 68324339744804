// Ensure the order of execution does not matter when loading multiple map type classes
window.MAP_TYPES = Object.assign(window.MAP_TYPES || {}, {
  GoogleTop: {
    mapType: 'GoogleTop',
    className: 'OsGoogleTop',
    provider: 'google',
    designMode: '2D',
    designModePriority: 8,
    library: 'google',
    isTopDown: true,
    imageryType: function (mapData, scene) {
      return 'top'
    },
  },
  GoogleRoadMap: {
    mapType: 'GoogleRoadMap',
    className: 'OsGoogleTop',
    provider: 'google',
    designMode: '2D',
    designModePriority: 8,
    library: 'google',
    isTopDown: true,
    imageryType: function imageryType(mapData, scene) {
      return 'none'
    },
  },
})

var OsGoogleTop = {
  minMetersPerPixel: function () {
    // return 0.00001

    // Danger: The combination of max zoom and html dom scaling can result in massive tile volumes
    // when changing between a scaled-out and a scaled-in viewport.
    // @TODO: Safely switch to zoomed-in google maps views (zoom>22)
    // For now we simply prevent zooming closer than level 22 on Google Maps
    // Switching to a zoom=23 view still lags and downloads around 1000 tiles but is not a show-stopper.

    var maxZoom = this.mapData.maxZoom || this.dom.maxZoom || 21
    var maxDigitalZoom = !isNaN(this.dom.maxDigitalZoom) ? this.dom.maxDigitalZoom : 2

    return MapHelper.getMetersPerPixelForZoomAndLat(Math.min(maxZoom + maxDigitalZoom, 23), this.mapData.center[1])
  },
  screenPositionToLatLon: function (x, y, screenScaleX, screenScaleY) {
    var viewportSize = MapHelper.viewportSize()
    var w = viewportSize[0]
    var h = viewportSize[1]

    //http://stackoverflow.com/questions/25219346/how-to-convert-from-x-y-screen-coordinates-to-latlng-google-maps
    function point2LatLng(point, map) {
      //Warning: for Oblique views not facing North, getNorthEast() is not the top right and getSouthWest() is not the bottom left
      try {
        // Old version which only works when zoom is an integer
        // var topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast())
        // var bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest())

        // Bounds are incorrect when fractionalZoom is enabled and zoom is non-integer!
        // But center is correct, so we can scale down the bounds to adjust for this inaccuracy.
        var neGoogle = map.getBounds().getNorthEast()
        var swGoogle = map.getBounds().getSouthWest()

        var neBad = { x: neGoogle.lng(), y: neGoogle.lat() }
        var swBad = { x: swGoogle.lng(), y: swGoogle.lat() }

        var center = { x: (neBad.x + swBad.x) / 2, y: (neBad.y + swBad.y) / 2 }

        var zoomFractional = map.zoomFractional || map.getZoom()

        // Perhaps this bug has now been fixed from Google Maps JavaScript API?
        // It seems to work now when we simply set scaleFactor=1
        // @TODO: Once verified in production at scale, we can remove this commented-out code.
        //
        // z      	    factor
        // 20.31472801	0.8
        // 20.48971625	0.71
        // 20.66837271	1.25
        // 20.84703461	1.11
        // 20.79407089	1.15
        // 20.51906495	1.4
        // var zoomDifference = Math.round(zoomFractional) - zoomFractional
        // var scaleFactor = Math.pow(2, zoomDifference)
        var scaleFactor = 1

        var ne = {
          x: center.x + (neBad.x - center.x) * scaleFactor * screenScaleX,
          y: center.y + (neBad.y - center.y) * scaleFactor * screenScaleY,
        }
        var sw = {
          x: center.x + (swBad.x - center.x) * scaleFactor * screenScaleX,
          y: center.y + (swBad.y - center.y) * scaleFactor * screenScaleY,
        }

        var topRight = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(ne.y, ne.x))
        var bottomLeft = map.getProjection().fromLatLngToPoint(new google.maps.LatLng(sw.y, sw.x))

        ////////////////////////////////////////

        var leftX = Math.min(topRight.x, bottomLeft.x)
        var topY = Math.min(topRight.y, bottomLeft.y)
        var scale = Math.pow(2, zoomFractional)
        var worldPoint = new google.maps.Point(point.x / scale + leftX, point.y / scale + topY)
        return map.getProjection().fromPointToLatLng(worldPoint)
      } catch (err) {
        //assume projection not yet ready
        throw new Error('Projection not ready')
      }
    }

    var screenPositionAdjustedForScale = MapScalingHelper.screenPositionToUnscaledPosition(
      w,
      h,
      x,
      y,
      screenScaleX,
      screenScaleY
    )
    // console.log('w, h, x, y', w, h, x, y)
    // console.log('screenPositionAdjustedForScale', screenPositionAdjustedForScale)

    //inflate the difference from screen center by scale factor?
    var latLng = point2LatLng(
      new google.maps.Point(
        screenPositionAdjustedForScale.x * screenScaleX,
        screenPositionAdjustedForScale.y * screenScaleY
      ),
      this.dom
    )
    if (latLng) {
      return [latLng.lng(), latLng.lat()]
    } else {
      return null
    }
  },
  getCenterFromMapUnderCameraRay4326: function () {
    var leftMarginPixels = editor && editor.leftMarginPixels ? editor.leftMarginPixels : 0

    var viewportRect = viewport.rect()

    // Do we need to fix for digital zoom / css scaling?
    var screenPositionUnderViewFinder = new THREE.Vector2(
      viewportRect.width / 2 + leftMarginPixels / 2,
      viewportRect.height / 2
    )

    try {
      return this.screenPositionToLatLon(
        screenPositionUnderViewFinder.x,
        screenPositionUnderViewFinder.y,
        this.mapData.scale ? this.mapData.scale : 1,
        this.mapData.scale ? this.mapData.scale : 1
      )
    } catch (err) {
      console.warn(err)
      console.warn('Use mapData.center instead of detecting using map control')
      return this.center
    }
  },
  getCenterSimplified: function () {
    var latLng = this.dom.getCenter()
    return [latLng.lng(), latLng.lat()]
  },
  toMapData: function (useSimplifiedGetCenter) {
    /*
    useSimplifiedGetCenter does a simple map.getCenter() instead of doing calculations for marginPixels and scale
    This is a critical bugfix for the explore page where bounds sometimes has not updated from a previous project
    but map.getCenter() thankfully is up to date. But beware because calling this when a margin is set will be nasty.
    */
    return new MapData({
      mapType: this.mapData.mapType,
      center: useSimplifiedGetCenter ? this.getCenterSimplified() : this.getCenterFromMapUnderCameraRay4326(),
      zoomTarget: this.mapData.zoomTarget,
      sceneOrigin: this.mapData._sceneOrigin(),
      scale: this.mapData.scale,
      heading: null,
      oblique: null,
      pano: null,
    })
  },
  drawMap: function (mapData) {
    /*
    Static method! `this` is not a map instance
    */

    // Create a map object and specify the DOM element for display.
    var map = new google.maps.Map(document.getElementById('map' + mapData.mapType), {
      isFractionalZoomEnabled: true,
      draggable: MapHelper.interactive(),
      fullscreenControl: false,
      keyboardShortcuts: false,
      zoomControl: false,
      mapTypeControl: false,
      streetViewControl: false,
      scrollwheel: false,
      rotateControl: false,
      center: {
        lat: mapData.center[1],
        lng: mapData.center[0],
      },
      // Set mapTypeId to SATELLITE in order
      // to activate satellite imagery.
      mapTypeId: mapData.mapType === 'GoogleTop' ? 'satellite' : 'roadmap',
      zoom: Math.floor(mapData.zoomTarget),
      tilt: 0,
    })
    map.mapType = mapData.mapType
    MapHelper.manageMapIsAnimatingGoogle(map)

    // var map = new google.maps.Map(document.getElementById('mapGoogleRoadMap'), {
    //   draggable: this.interactive(),
    //   fullscreenControl: false,
    //   keyboardShortcuts: false,
    //   zoomControl: false,
    //   mapTypeControl: false,
    //   streetViewControl: false,
    //   scrollwheel: false,
    //   rotateControl: false,
    //   center: {
    //     lat: mapData.center[1],
    //     lng: mapData.center[0],
    //   },
    //   mapTypeId: 'roadmap',
    //   zoom: Math.floor(mapData.zoomTarget),
    //   tilt: 0,
    // })
    // map.mapType = 'GoogleRoadMap'
    //
    // MapHelper.manageMapIsAnimatingGoogle(map)
    //
    // return new MapInstance(mapData, map)

    return new MapInstance(mapData, makeMapProxy(new Google2DMapAdapter(map)))
  },
  forceZoomTargetOnDragEndHandler: function () {
    var zoomTargetToApply = this.mapData.zoomTarget

    // Unfortunately we need to hack this using setTimout(...,1) to ensure it happens after the bug which
    // forces the zoom level to an integer  value
    setTimeout(() => {
      if (window.studioDebug) {
        console.log('forceZoomTargetOnDragEndHandler call setZoom hack', zoomTargetToApply)
      }
      this.applyZoomAndScale(zoomTargetToApply)
    }, 1)
  },
  forceZoomTargetOnDragEndHandlerBound: null,
  forceZoomTargetOnDragEndListenerReference: null,
  forceZoomTargetOnDragEnd: function (enable) {
    // Workaround bug where dragging a Google map when zoom is non integer (e.g. 15.5) automatically snaps back
    // to an integer zoom level after drag. We handle this by manually re-applying the fractional zoom level after
    // the drag completes.
    //
    // Beware: What if we change maps somehow without removing this?
    if (!this.forceZoomTargetOnDragEndHandlerBound) {
      this.forceZoomTargetOnDragEndHandlerBound = this.forceZoomTargetOnDragEndHandler.bind(this)
    }
    if (enable) {
      this.forceZoomTargetOnDragEndListenerReference = google.maps.event.addListener(
        this.dom,
        'dragend',
        this.forceZoomTargetOnDragEndHandlerBound
      )
    } else {
      if (this.forceZoomTargetOnDragEndListenerReference) {
        google.maps.event.removeListener(this.forceZoomTargetOnDragEndListenerReference)
      }
    }
  },
  setZoom: function (zoom) {
    this.dom.moveCamera({ zoom })
  },
  applyZoomAndScale: function (zoomTarget) {
    /*
    Receive zoomTarget and apply zoom (capped at max) and scale
    */
    var { zoomTargetCapped, targetMapScale } = MapHelper.activeMapInstance.zoomTargetToZoomTargetCappedAndScale(
      zoomTarget
    )

    this.setZoom(zoomTargetCapped)

    // We cannot retrieve fractional zoom level using getZoom() so we store directly on the instance
    this.dom.zoomFractional = zoomTargetCapped

    /*
      Include all CSS variations?

      -moz-transform: scale(1,2);
      -webkit-transform: scale(50%,50%);
      -o-transform: scale(2,2);
      -ms-transform: scale(2,2);
      transform: scale(2,2);
      */

    if (targetMapScale > MapHelper.getMaxSafeZoomFactorAfterFloor()) {
      console.warn(
        'GoogleMap/GoogleMapTop trying to set scale to unsafe level (' +
          targetMapScale +
          '). Ignorning scale. Hiding map until scale reduces to safe level'
      )

      // Quick optimization to avoid calling unnecessary CSS updates
      if (this.dom.__display !== 'none') {
        MapHelper.setDisplayCss(this.mapData.mapType, 'none')
      }
    } else {
      if (targetMapScale != this.mapData._scale()) {
        this.mapData._scale(targetMapScale)
      }

      this.scaleDomElement(targetMapScale)

      // Quick optimization to avoid calling unnecessary CSS updates
      if (this.dom.__display !== 'block') {
        MapHelper.setDisplayCss(this.mapData.mapType, 'block')
      }
    }
  },
  interactive: function (value) {
    if (typeof value === 'undefined') {
      return this._interactive
    }

    if (this._interactive === value) {
      return
    }

    this.dom.setOptions({
      draggable: value,
      zoomControl: false,
      scrollwheel: false,
    })

    this.forceZoomTargetOnDragEnd(value)
  },
  // setPrimary: "not required",
  updateSize: function () {
    google.maps.event.trigger(this.dom, 'resize')

    /*
    Hack: Standard browser/element resize is not enough to force a google map to resize itself if it was loaded when
    hidden also force a css size change because that seems to do the trick whereas a syntehtic browser resize event
    will not trigger it.
    */
    var mapDomElement = MapHelper.getDom(this.mapData.mapType)

    var getWidth = function () {
      if (mapDomElement.length === 0) {
        //Skip if mapDomElement not present (e.g. MapType is probably either Blank or Turntable
        return null
      }

      try {
        return parseInt(mapDomElement.css('width').replace('px', ''), 10)
      } catch (err) {
        console.warn(err)
        return null
      }
    }

    var width = getWidth()
    window.studioDebug && console.log('MapHelper: width detected:', width)

    setTimeout(function () {
      if (getWidth() === width) {
        window.studioDebug && console.log('MapHelper: width is unchanged, reset to width+1', width + 1)
        mapDomElement.css('width', width + 1)
      } else {
        window.studioDebug && console.log('MapHelper: width has changed, do not reset to width+1', width + 1)
      }
    }, 100)

    setTimeout(function () {
      // only reset if width is exactly equal to width+1
      if (getWidth() === width + 1) {
        window.studioDebug && console.log('MapHelper: reset to width+0', width)
        mapDomElement.css('width', width)
      } else {
        window.studioDebug &&
          console.warn('MapHelper: Width of mapElement has been changed from width+1, do not resize')
      }
    }, 200)
  },
  setView: async function (mapData, rebuildLayers, isGoogleObliqueDetection, latLonAtScreenCenterForMap4326) {
    // Only detect depth for GoogleTop, not GoogleRoadMap variation
    if (this.mapData.mapType === 'GoogleTop' && !this.dom.maxZoom) {
      //This will set this.maxZoom and call this function again
      //but since maxZoom is now set this callback not be called again.
      this.detectAndApplyGoogleTopDownMaxZoom(latLonAtScreenCenterForMap4326.toArray())
    }

    this.dom.setCenter(new google.maps.LatLng(latLonAtScreenCenterForMap4326.y, latLonAtScreenCenterForMap4326.x))

    this.applyZoomAndScale(mapData.zoomTarget)

    MapHelper.updateGoogleCopyright(this)
  },
  scaleDomElement: function (targetMapScale) {
    if (this.dom.__scale !== targetMapScale) {
      $('#mapGoogleTop').css('-moz-transform', 'scale(' + targetMapScale + ',' + targetMapScale + ')')
      $('#mapGoogleTop').css('transform', 'scale(' + targetMapScale + ',' + targetMapScale + ')')
      this.dom.__scale = targetMapScale

      // Ensure map control refreshes its size to account for scaling
      // @TODO: Debounce this?
      this.updateSize()
    }
  },
  getZoomForResolution: function (mapType, targetMetersPerPixel, lat) {
    var roundUpToNextZoomLevel = function (tmpZoom) {
      // Why was this not implemented?
      // return tmpZoom

      if (Math.ceil(tmpZoom) - tmpZoom < 0.001) {
        return Math.ceil(tmpZoom)
      } else {
        return tmpZoom
      }
    }

    //https://stackoverflow.com/questions/9356724/google-map-api-zoom-range
    //var lat = 50.8076955
    var scaleForLat = Math.cos((lat * Math.PI) / 180)
    var max = 156543.03392804097 * scaleForLat
    var fudgeFactor = Math.log(2)
    var zoom = Math.log(max / targetMetersPerPixel) / fudgeFactor
    return roundUpToNextZoomLevel(zoom)
  },
  matchScene: function (editor, targetMetersPerPixel, sceneOrigin4326, mapData, force, viewportSize) {
    if (!sceneOrigin4326) return

    //First pan to sceneOrigin4326
    //Then find the pixel offset for sceneOrigin4326 from screen center
    //and pan the map by that much

    var screenPositionOfOriginDiffFromCenter = editor.viewport.worldToScreenOffsetFromCenter(
      new THREE.Vector3(),
      editor.camera
    )
    // console.log('screenPositionOfOriginDiffFromCenter', screenPositionOfOriginDiffFromCenter)
    //Check all coordinates are valid, otherwise skip all updates
    if (isNaN(sceneOrigin4326.x) || isNaN(sceneOrigin4326.y)) {
      console.warn('matchScene(): Invalid sceneOrigin4326')
      return
    } else if (isNaN(screenPositionOfOriginDiffFromCenter.x) || isNaN(screenPositionOfOriginDiffFromCenter.y)) {
      console.warn('matchScene(): Invalid screenPositionOfOriginDiffFromCenter')
      return
    } else {
      //Original quick-and-dirty method.
      //Avoids the need to calculate the lat/lon offset due to screen offset
      if (false) {
        // MapHelper.activeMapInstance.dom.panTo({
        //   lat: sceneOrigin4326.y,
        //   lng: sceneOrigin4326.x,
        // })
        // MapHelper.activeMapInstance.dom.panBy(
        //   -screenPositionOfOriginDiffFromCenter.x / mapData.scale,
        //   -screenPositionOfOriginDiffFromCenter.y / mapData.scale
        // )
        //
        // var targetMapDepth = this.getZoomForResolution(mapData.mapType, targetMetersPerPixel, sceneOrigin4326.y)
        //
        // // @TODO: Write mapData._center directly, rather than letting it update when the map animation finishes
        // // mapData._center([targetMapCenter4326.x, targetMapCenter4326.y])
        // mapData.zoomTarget = targetMapDepth
        //
        // MapHelper.setView(MapHelper.activeMapInstance, mapData)
      } else {
        //Cannot calculate this directly, but we can estimate by finding the latlon delta from current map center to screen offset
        //Works for any map center, so long as current map center is reasonably close to destination (e.g. Within 100kms!)

        try {
          // Calculate latlon for map-screen-center based on target latlon for scene origin (0,0,0)
          // Method:
          //   1. Find screen position of scene origin (0,0,0)
          //   2. Find current map latlon at screen-position-of-scene-origin
          //   3. Calculate difference between current screen-position-of-scene-origin and target (sceneOrigin4326)
          //   4. Offset map-center by this amount
          //
          // Beware: We must update the scale FIRST before making any other calculations because
          // calcs should be based on the scale which will apply after the update
          //
          // @TODO: What if the map is not current loaded, do we need to initialize it another way?

          var targetMapDepth = this.getZoomForResolution(mapData.mapType, targetMetersPerPixel, sceneOrigin4326.y)
          var { zoomTargetCapped, targetMapScale } = MapHelper.activeMapInstance.zoomTargetToZoomTargetCappedAndScale(
            targetMapDepth
          )

          var coordinatesAtViewFinderCenterFromMap4326 = new THREE.Vector2().fromArray(
            this.screenPositionToLatLon(
              viewportSize.x / 2 + editor.leftMarginPixels / 2,
              viewportSize.y / 2,
              targetMapScale,
              targetMapScale
            )
          )

          var coordinatesAtSceneOriginFromMap4326 = new THREE.Vector2().fromArray(
            this.screenPositionToLatLon(
              // Do not adjust for editor.leftMarginPixels because this uses raw screen center
              viewportSize.x / 2 + screenPositionOfOriginDiffFromCenter.x,
              viewportSize.y / 2 + screenPositionOfOriginDiffFromCenter.y,
              targetMapScale,
              targetMapScale
            )
          )

          // Difference between current map center and
          var delta4326 = new THREE.Vector2().subVectors(sceneOrigin4326, coordinatesAtSceneOriginFromMap4326)

          var targetMapCenter4326 = coordinatesAtViewFinderCenterFromMap4326.add(delta4326)

          mapData._center([targetMapCenter4326.x, targetMapCenter4326.y])
          mapData._zoomTarget(targetMapDepth)

          // Do not update scale here because we update it in setView and we need to know if it has changed in there
          // mapData._scale(targetMapScale)

          MapHelper.setView(MapHelper.activeMapInstance, mapData)
        } catch (err) {
          window.studioDebug && console.warn(err)

          if (err === 'Projection not ready') {
            //Beware: Looks like if we spam map projections too aggressively
            //it applies some kind of rate-limiting which causes strange behavior
            //setTimeout to 1000, because 100 seems to hit rate limiting.
            setTimeout(function () {
              window.studioDebug && console.log('MapHelper.matchScene after timeout')
              window.studioDebug && console.log('Retrying MapHelper.matchScene() after error:' + err)
              var matchSceneResult = MapHelper.matchScene()
              if (matchSceneResult === true) {
                window.studioDebug && console.log('MapHelper.matchScene() retry: success')
              } else {
                window.studioDebug && console.log('MapHelper.matchScene() retry: failure')
              }
            }, 1000)
          } else {
            console.warn('Error: unexpected error: ' + err)
          }
          return false
        }
      }
    }
  },

  ///////////// Non-Standard Instance Methods Below Here ////////////
  detectAndApplyMaxZoomInProgress: false,
  detectAndApplyGoogleTopDownMaxZoom: async function (location4326, initialMaxZoom) {
    if (this.mapData.mapType !== 'GoogleTop') {
      throw new Error('detectAndApplyGoogleTopDownMaxZoom should only be run on GoogleType mapType')
    }
    var _this = this
    this.detectAndApplyMaxZoomInProgress = true

    var defaulMaxZoom = 19

    if (typeof initialMaxZoom !== 'undefined' && initialMaxZoom > defaulMaxZoom) {
      this.dom.maxZoom = initialMaxZoom
    } else {
      this.dom.maxZoom = defaulMaxZoom
    }

    this.dom.maxZoomOverridenForObliqueDetection = true

    //Disabled because Google not returning the correct max zoom level (temporary bug?)
    //https://stackoverflow.com/questions/48133173/google-maps-javascript-api-not-loading-closest-zoom-level

    var latlng = { lat: location4326[1], lng: location4326[0] }
    var mapZoomService = new google.maps.MaxZoomService()

    var maxZoomResult = await mapZoomService.getMaxZoomAtLatLng(latlng)

    // Note: There is no need to check status using maxZoomResult.status === google.maps.MaxZoomStatus.OK
    // because we can just check for the presence of the zoom level in the result
    if (maxZoomResult.zoom) {
      //FYI: MaxZoomResult only returns maxZoom for top-town imagery, not Oblique
      //And even that can be buggy
      this.dom.maxZoom = maxZoomResult.zoom

      if (this.dom.maxZoomOverridenForObliqueDetection) {
        delete this.dom.maxZoomOverridenForObliqueDetection
      }

      if (MapHelper.jumpToMaxZoomAfterNextDetection) {
        // Aim for at level 20 and use up to 1 level of digital zoom if below level 20
        var zoomTarget = maxZoomResult.zoom >= 20 ? maxZoomResult.zoom : maxZoomResult.zoom + 1
        var digitalZoom = zoomTarget - maxZoomResult.zoom
        var scale = 1 + digitalZoom

        this.mapData._scale(scale)
        this.mapData._zoomTarget(maxZoomResult.zoom)

        editor.metersPerPixel(MapHelper.getMetersPerPixelForZoomAndLat(zoomTarget, location4326[1]))
        MapHelper.matchScene()

        MapHelper.jumpToMaxZoomAfterNextDetection = false
      }

      return MapHelper.setView(this, this.mapData).then(() => {
        _this.detectAndApplyMaxZoomInProgress = false
      })
    } else {
      console.warn(
        'Error: in google.maps.MaxZoomService().getMaxZoomAtLatLng(). maxZoom will not be updated, currently: ' +
          this.dom.maxZoom
      )
      this.detectAndApplyMaxZoomInProgress = false

      throw new Error('maxZoom detection unsuccessful')
    }
  },
}
