var LoaderHelper = {
  active: false,
  animateIntervalId: null,
  show: function () {
    if (this.active === true) {
      window.editor.signals.loadHelperShowing.dispatch(true, 0)
    } else {
      console.info('Notice: LoaderHelper.show() called while this.active !== true, ignored')
    }
  },
  hide: function () {
    window.editor.signals.loadHelperShowing.dispatch(false, 100)
    this.endAnimation()
  },
  complete: function () {
    this.hide()
  },
  fraction: function (fraction, animate) {
    if (this.active !== true) {
      this.hide()
      return
    }
    //Fraction which is precisely 0 has zero width, otherwise apply minimum width
    window.editor.signals.loadHelperShowing.dispatch(true, Math.round(fraction * 100))
  },
  animate: function (iterations, delayBetweenIterations, startFraction, endFraction) {
    if (this.active !== true) {
      this.hide()
      return
    }

    if (this.animateIntervalId) {
      //avoid leaving infinity interval cause memory leak
      this.endAnimation()
    }

    var countIterations = 0
    var increment = (endFraction - startFraction) / iterations
    var _this = this
    this.animateIntervalId = setInterval(function () {
      LoaderHelper.fraction(startFraction + increment * countIterations)
      countIterations++
      if (countIterations >= iterations) {
        _this.endAnimation()
      }
    }, delayBetweenIterations)
  },
  endAnimation: function () {
    if (this.animateIntervalId) {
      clearInterval(this.animateIntervalId)
      this.animateIntervalId = null
    }
  },
}
